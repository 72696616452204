import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {FeedbackParams} from './common';

export type ExamplePost = {
  userId: number;
  id: number;
  title: string;
  body: string;
};

export type GetPostParams = {
  params?: {[key: string]: any};
} & FeedbackParams;

export const exampleApi = createApi({
  reducerPath: 'exampleApi',
  baseQuery: fetchBaseQuery({baseUrl: 'https://jsonplaceholder.typicode.com'}),
  endpoints: builder => ({
    getPosts: builder.query<any[], GetPostParams>({
      query: ({params}) => ({
        url: 'posts',
        params,
      }),
    }),
  }),
});

export const {useGetPostsQuery} = exampleApi;
