import {useState} from 'react';
import {Stack} from 'react-bootstrap';
import cx from 'clsx';
import {Col, Row, Container} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import {ReactComponent as ServiceIcon} from '../../../../assets/images/service.svg';
import {ReactComponent as TimeIcon} from '../../../../assets/images/time.svg';
import {ReactComponent as EditIcon} from '../../../../assets/images/editGreen.svg';
import {ReactComponent as TrashIcon} from '../../../../assets/images/trashRed.svg';
import {ReactComponent as ApplyIcon} from '../../../../assets/images/apply.svg';
import {ReactComponent as CancelIcon} from '../../../../assets/images/cancel.svg';
import IconButton from '../../../Common/IconButton';
import TextField from '../../../Form/Inputs/TextField';
import {useElementStyles} from './useStyles';
import {StoreScheduleItem} from '../../../../types/StoreScheduleItem';
import {getTimeWithoutSeconds} from '../../../../utils/functions';
import {useIntl} from 'react-intl';
import ErrorMessage from '../../../Common/ErrorMessage';

/**
 * Schedule title
 */

export type ScheduleItemTitleProps = {
  item: StoreScheduleItem;
  isExpanded: boolean;
  onDelete: () => void;
  onEdit: (value: StoreScheduleItem) => void;
};

const ScheduleItemTitle = ({
  item,
  isExpanded,
  onDelete,
  onEdit,
}: ScheduleItemTitleProps) => {
  const elementStyles = useElementStyles();
  const intl = useIntl();
  // Edit title is enabled
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(item.title || 'New schedule');
  const [error, setError] = useState('');

  const enableEditing = (e: any) => {
    e.stopPropagation();
    setName(item.title);
    setEditing(true);
  };

  const disableEditing = (e: any) => {
    e.stopPropagation();
    setEditing(false);
    setError('');
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    setError('');
    onDelete();
  };

  const onAccepted = () => {
    if (!name) {
      setError(intl.formatMessage({id: 'validation.error.required_field'}));
      return;
    }
    onEdit({...item, title: name});
    setEditing(false);
    setError('');
  };

  return (
    <Container>
      <Row className={cx('gy-2 align-items-center', 'gx-vtl-15')}>
        {!editing ? (
          <Col lg="auto">
            <Stack
              direction="horizontal"
              gap={2}
              className="schedule-title-container"
            >
              <Typography className="font-weight-bold">{item.title}</Typography>
              <Stack direction="horizontal" gap={2} className="actions-toolbar">
                <IconButton size="xs" variant="action" onClick={enableEditing}>
                  <EditIcon />
                </IconButton>
                <IconButton size="xs" variant="action" onClick={handleDelete}>
                  <TrashIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Col>
        ) : (
          <Col lg="auto">
            <div
              className={elementStyles.editable}
              onClick={e => e.stopPropagation()}
            >
              <TextField value={name} onChange={e => setName(e.target.value)} />
              <Stack gap={1} direction="horizontal" className="actions">
                <IconButton size="sm" variant="action" onClick={onAccepted}>
                  <ApplyIcon />
                </IconButton>
                <IconButton size="sm" variant="action" onClick={disableEditing}>
                  <CancelIcon />
                </IconButton>
              </Stack>
            </div>
          </Col>
        )}
        {!isExpanded && (
          <>
            <Col lg="auto">
              <div className="icon-text-wrapper">
                <TimeIcon />
                <Typography
                  component="span"
                  className="text-muted2"
                >{`${getTimeWithoutSeconds(
                  item.openingTime
                )} - ${getTimeWithoutSeconds(item.closingTime)}`}</Typography>
              </div>
            </Col>
            <Col>
              {Object.keys({...item.services}).filter(
                key => item.services![key]
              ).length > 0 && (
                <div className="icon-text-wrapper">
                  <ServiceIcon />
                  <Typography component="span" className="text-muted2">
                    {Object.keys({...item.services})
                      .filter(key => item.services![key])
                      .map(
                        value => value.charAt(0).toUpperCase() + value.slice(1)
                      )
                      .join(' and ')}
                  </Typography>
                </div>
              )}
            </Col>
          </>
        )}
      </Row>
      {error && <ErrorMessage error={error} />}
    </Container>
  );
};

export default ScheduleItemTitle;
