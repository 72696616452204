import {useIntl} from 'react-intl';
import moment from 'moment';
import {Col, Row, Stack} from 'react-bootstrap';
import KeywordSearch from '../Form/KeywordSearch';
import DynamicFilter from '../Form/DynamicFilter';
import DataTable from '../Widgets/DataTable';
import usePaymentList from '../../hooks/usePaymentList';
import {capitalizeFirstLetter} from '../../utils/functions';
import ArrowLink from '../Common/ArrowLink';
import Typography from '@material-ui/core/Typography';
import {ReactComponent as AccountIcon} from '../../assets/images/account.svg';
import {ReactComponent as StoreIcon} from '../../assets/images/store.svg';
import StatusIndicator from '../Common/StatusIndicator';
import {HeadCellType} from '../Widgets/DataTable/DataTableHead';

const PaymentList = () => {
  const intl = useIntl();
  const statuses = ['completed', 'pending', 'cancelled', 'warning'];

  const filterItems: any[] = [
    {
      name: 'date',
      label: intl.formatMessage({
        id: 'transaction_list.payment_list.transaction_date',
      }),
      type: 'date',
    },
    {
      name: 'order',
      label: intl.formatMessage({id: 'transaction_list.payment_list.order_id'}),
      type: 'text',
    },
    {
      name: 'store',
      label: intl.formatMessage({
        id: 'transaction_list.payment_list.filters.store',
      }),
      type: 'text',
    },
    {
      name: 'buyer',
      label: intl.formatMessage({
        id: 'transaction_list.payment_list.filters.buyer',
      }),
      type: 'text',
    },
    {
      name: 'stripe_id',
      label: intl.formatMessage({
        id: 'transaction_list.payment_list.filters.stripe_id',
      }),
      type: 'text',
    },
    {
      name: 'status',
      label: intl.formatMessage({id: 'transaction_list.status'}),
      type: 'checkbox-group',
      checkboxSize: 'default',
      formControlLabelClass: 'checkbox-filter-row',
      options: statuses.map(status => ({
        label: capitalizeFirstLetter(status),
        value: status,
      })),
    },
  ];

  const {data, handleRowClick, tableState} = usePaymentList();

  const renderDate = (value: any) => {
    return <Typography>{moment(value).format('DD MMM YYYY')}</Typography>;
  };

  const renderOrderLink = (val: any) => {
    return <ArrowLink title={val} url="#order_id" />;
  };

  const renderBuyerStore = ({buyer, store}: any) => {
    return (
      <div className="pb-2 pb-lg-0">
        <Stack gap={1}>
          <Stack direction="horizontal" gap={2}>
            <span>
              <AccountIcon />
            </span>
            <ArrowLink title={buyer.name} url="#buyer" />
          </Stack>
          <Stack direction="horizontal" gap={2}>
            <span>
              <StoreIcon className="text-muted2" />
            </span>
            <ArrowLink title={store.name} url="#store" />
          </Stack>
        </Stack>
      </div>
    );
  };

  const renderAmountStripeId = ({amount, stripeid}: any) => {
    return (
      <Stack gap={1}>
        <Typography>{amount}</Typography>
        <ArrowLink title={stripeid} url="#stripeid" />
      </Stack>
    );
  };

  const renderStatus = (val: any) => {
    const statusColor: any = {
      completed: 'green',
      pending: 'secondary',
      in_progress: 'orange',
      cancelled: 'error',
      warning: 'yellow',
    };

    return (
      <div>
        <StatusIndicator
          label={capitalizeFirstLetter(val.label)}
          color={statusColor[val.status]}
        />
      </div>
    );
  };

  const HeaderCells: HeadCellType[] = [
    {
      name: 'date',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'transaction_list.payment_list.transaction_date',
      }),
      renderValue: (value: any) => renderDate(value),
      collapse: false,
      sortable: true,
    },
    {
      name: 'order_id',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'transaction_list.payment_list.order_id',
      }),
      renderValue: (value: any) => renderOrderLink(value),
      collapse: true,
      sortable: true,
    },
    {
      name: 'type',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'transaction_list.payment_list.type',
      }),
      collapse: true,
      sortable: true,
      width: '15%',
    },
    {
      name: 'buyer_store',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'transaction_list.payment_list.buyer_store',
      }),
      renderValue: (value: any) => renderBuyerStore(value),
      collapse: true,
      sortable: true,
      width: '22%',
    },
    {
      name: 'amount_stripeid',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'transaction_list.payment_list.amount_stripeid',
      }),
      renderValue: (value: any) => renderAmountStripeId(value),
      collapse: true,
      sortable: true,
      width: '20%',
    },
    {
      name: 'status',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'transaction_list.payment_list.status'}),
      renderValue: (row: any) => renderStatus(row),
      collapse: false,
      sortable: true,
    },
  ];

  return (
    <div className="dashboard-content">
      <Row className="align-items-center justify-content-between search-row">
        <Col className="col-xl-5 col-lg-8">
          {/* Search */}
          <KeywordSearch
            placeholder={intl.formatMessage({id: 'actions.search'})}
            resetPageOnChange
          />
        </Col>
        {/* Button toolbar */}
        <Col className="col-auto d-lg-block ps-0 ps-lg-2">
          <DynamicFilter
            items={filterItems}
            bindQueryParams
            resetPageOnFilter
          />
        </Col>
      </Row>
      {/* Data table */}
      <DataTable
        headCells={HeaderCells}
        rows={data}
        onChange={() => {}}
        loading={false}
        onRowClick={handleRowClick}
        currentPage={0}
        total={data.length}
        handleParameterChange={tableState.handleDataTableParamChange}
      />
    </div>
  );
};

export default PaymentList;
