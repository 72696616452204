/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, {ReactNode} from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import clsx from 'clsx';
import IconButton from '../../Common/IconButton';

/**
 * Header of Data Table
 */

export type HeadCellType = {
  /**
   * column name
   */
  name: string;
  /**
   * Column is numeric (if TRUE it will be right aligned)
   */
  numeric: boolean;
  /**
   * If TRUE then left padding is not added
   */
  disablePadding: boolean;
  /**
   * Column text label
   */
  label: string;
  /**
   * Column is used for sorting
   */
  sortable?: boolean;
  /**
   * Column width
   */
  width?: string | undefined;
  /**
   * Cell rendering procedure
   */
  renderValue?: (arg0: any, arg1: any, arg2: any) => ReactNode | undefined;
  /**
   * Make this field collapsed in mobile view
   */
  collapse?: boolean;
  mobileFirst?: boolean;
  mobileVisibleOnly?: boolean;
  wrapped?: boolean;
  colspan?: number;
};

export type DataTableHeadType = {
  classes: any;
  /**
   * Selectable rows
   */
  canSelect: boolean;
  /**
   * Table head description array
   */
  headCells: HeadCellType[];
  /**
   * Selected rows number
   */
  numSelected: number;
  /**
   * Sort column callback
   */
  onRequestSort: (property: string) => void;
  /**
   * Header ceckbox handler
   */
  onSelectAllClick: (event: any) => void;
  /**
   * Order direction
   */
  order: 'asc' | 'desc';
  /**
   * Order column
   */
  orderBy: string;
  /**
   * Number of rows
   */
  rowCount: number;
  /**
   * Button is shown inside table
   */
  actionButton: ReactNode | any;
};

const DataTableHead = (props: DataTableHeadType) => {
  const {
    canSelect,
    headCells,
    actionButton,
    classes,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  /**
   * Sort table by column
   */
  const createSortHandler = (property: string) => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* Select All checkbox */}
        {canSelect && (
          <TableCell
            className={classes.headCell}
            padding="checkbox"
          ></TableCell>
        )}
        {/* Columns */}
        {headCells.map(
          ({
            name,
            collapse,
            disablePadding,
            label,
            numeric,
            sortable = true,
            width = undefined,
          }: HeadCellType) => (
            <TableCell
              key={name}
              className={clsx(
                collapse
                  ? 'd-none d-lg-table-cell'
                  : 'd-table-cell text-nowrap',
                classes.headCell,
                !canSelect && 'pb-lg-3'
              )}
              align={numeric ? 'right' : 'left'}
              padding={disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === name ? order : false}
              style={
                width
                  ? {
                      width,
                    }
                  : {}
              }
            >
              {sortable ? (
                <TableSortLabel
                  className={clsx(numSelected < rowCount && 'color-disabled')}
                  active={orderBy === name}
                  direction={orderBy === name ? order : 'asc'}
                  onClick={() => createSortHandler(name)}
                >
                  {label}
                  {/* Ordered by this column ? */}
                  {orderBy === name && (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  )}
                </TableSortLabel>
              ) : (
                <TableSortLabel
                  className={clsx({'color-disabled': numSelected < rowCount})}
                >
                  {label}
                </TableSortLabel>
              )}
            </TableCell>
          )
        )}
        {/* Show action button on header (mobile only) */}
        <TableCell
          align="right"
          className={clsx(
            classes.headCell,
            'd-table-cell d-lg-none',
            'action-wrapper'
          )}
          colSpan={2}
        >
          {actionButton ? (
            <IconButton
              variant={actionButton?.variant}
              size={actionButton?.size}
              className="ml-auto"
              onClick={actionButton?.onClick}
            >
              {actionButton?.children}
            </IconButton>
          ) : null}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default DataTableHead;
