export enum PlatformEnum {
  Android = 'android',
  Ios = 'ios',
  Common = 'common',
}

export enum PlatformNameEnum {
  Android = 'Android',
  Ios = 'iOS',
  Common = 'Common',
}

export enum BuildStatusEnum {
  InProgress = 'inProgress',
  Ready = 'ready',
  Failed = 'failed',
}

export enum BuildTypeEnum {
  Build = 'build',
  OTA = 'ota',
}

export enum BuildEnvEnum {
  Testing = 'testing',
  Production = 'production',
}

export interface Build {
  id: number;
  appId: number;
  appMetadata: any;
  platform: PlatformEnum;
  appVersion: string;
  versionCode: string;
  date: Date;
  status: BuildStatusEnum;
  buildLog: string;
  type: BuildTypeEnum;
  env: BuildEnvEnum;
  buildFilePath: string;
  createdAt: Date;
  updatedAt: Date;
}
