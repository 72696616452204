import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import {HTMLProps, ReactNode} from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useFormState,
} from 'react-hook-form';
import HintTooltip from '../../Common/HintTooltip';
import CustomRadio from '../../Common/Radio';

export type RadioGroupControllerProps<T extends FieldValues> =
  HTMLProps<HTMLDivElement> & {
    name: Path<T>;
    control: Control<T>;
    options: any[];
    formControlLabelClass?: string;
    groupClassName?: string;
    extraChild?: string | ReactNode;
    labelField?: string;
    valueField?: string;
    label?: string;
    excludeLabel?: boolean;
    formGroupClass?: string;
    showLabelHint?: boolean;
    labelHintText?: string;
    isRow?: boolean;
  };

const RadioGroupController = <T extends FieldValues>({
  name,
  label,
  excludeLabel,
  showLabelHint,
  formGroupClass,
  control,
  required,
  labelHintText,
  labelField = 'label',
  valueField = 'value',
  options,
  groupClassName,
  extraChild,
  formControlLabelClass,
  isRow = true,
  defaultValue = '',
  disabled,
}: RadioGroupControllerProps<T>) => {
  const {errors} = useFormState({control, name});
  const touched = !!errors[name];
  const error = errors[name];

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue as any}
      render={({field: {value, onChange}}) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          onChange((event.target as HTMLInputElement).value);
        };

        return (
          <div>
            <FormControl
              className={clsx(
                'form-group',
                formGroupClass ? formGroupClass : 'mb-3 mb-lg-fg'
              )}
              error={touched && !!error}
            >
              {showLabelHint ? (
                <div className="label-with-hint d-flex">
                  <span className="small-label pb-2 pe-1">
                    {label}
                    {required ? '*' : ''}
                  </span>
                  <HintTooltip id={`hint_${label}`} content={labelHintText} />
                </div>
              ) : (
                !excludeLabel && (
                  <span className="small-label pb-2">
                    {label}
                    {required ? '*' : ''}
                  </span>
                )
              )}
              {options && (
                <RadioGroup
                  row={!!isRow}
                  className={groupClassName}
                  value={value}
                  onChange={handleChange}
                >
                  {options.map((option: any, key: number) => {
                    return (
                      <FormControlLabel
                        key={key}
                        className={clsx(formControlLabelClass, {
                          'column-group-item': !isRow,
                        })}
                        label={
                          <Typography variant="body2" style={{minWidth: 40}}>
                            {option[labelField]}
                          </Typography>
                        }
                        control={
                          <CustomRadio
                            value={option[valueField]}
                            inputProps={{
                              name: name,
                            }}
                            disabled={disabled}
                          />
                        }
                      />
                    );
                  })}
                  {extraChild}
                </RadioGroup>
              )}
              {touched && !!error && (
                <FormHelperText>{String(error.message)}</FormHelperText>
              )}
            </FormControl>
          </div>
        );
      }}
    />
  );
};

RadioGroupController.defaultProps = {
  labelField: 'label',
  valueField: 'value',
  formControlLabelClass: 'radio',
};

export default RadioGroupController;
