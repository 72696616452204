import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Outlet} from 'react-router-dom';
import {selectUserAndToken} from '../../store/slices/authSlice';
import * as urls from '../../constants/urls';

type ProtectedRouteProps = {
  allowedRoles: Array<string>;
};

const ProtectedRoute = ({allowedRoles}: ProtectedRouteProps) => {
  const {user} = useSelector(selectUserAndToken);

  return user && allowedRoles.includes(user.profileType) ? (
    <Outlet />
  ) : (
    <Navigate to={urls.LOGIN_PATH} replace />
  );
};

export default ProtectedRoute;
