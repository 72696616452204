import DeliveryZoneWidget, {
  DeliveryZoneWidgetRef,
} from './Widgets/DeliveryZoneWidget';
import {Typography} from '@material-ui/core';
import {useEffect, useRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {Stack} from 'react-bootstrap';
import {DeliveryZone} from '../../../../types/DeliveryZone';
import {DeliveryZoneStatus} from '../../../../utils/status';

type DeliveryZoneListProps = {
  setSelectedZone: (zone: DeliveryZone) => void;
  selectedZone?: DeliveryZone;
  zones?: DeliveryZone[];
  editZonePopup: () => void;
  updateDeliveryZoneStatus: (
    zone: DeliveryZone,
    status: DeliveryZoneStatus
  ) => void;
};

const DeliveryZoneList = (props: DeliveryZoneListProps) => {
  const {
    setSelectedZone,
    zones,
    selectedZone,
    editZonePopup,
    updateDeliveryZoneStatus,
  } = props;

  const handleOnZoneClick = (zone: DeliveryZone) => {
    setSelectedZone(zone);
  };

  const handleZoneStatusChange = (
    zone: DeliveryZone,
    status: DeliveryZoneStatus
  ) => {
    updateDeliveryZoneStatus(zone, status);
  };

  const scrollRef = useRef<DeliveryZoneWidgetRef>(null);
  useEffect(() => {
    if (selectedZone && scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [selectedZone, scrollRef]);

  return (
    <div>
      {zones && zones?.length > 0 ? (
        <Stack gap={3}>
          {zones.map(zone => (
            <DeliveryZoneWidget
              selected={selectedZone}
              onClick={handleOnZoneClick}
              key={zone.id}
              zone={zone}
              onEditClick={editZonePopup}
              ref={selectedZone?.id === zone.id ? scrollRef : null}
              onStatusChange={handleZoneStatusChange}
            />
          ))}
        </Stack>
      ) : (
        <Typography>
          <FormattedMessage id="dashboard.store_details.delivery_tab.noDeliveryZonesAdded" />
        </Typography>
      )}
    </div>
  );
};

export default DeliveryZoneList;
