import React, {ReactNode, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {ClickAwayListener, makeStyles, Popover, Theme} from '@material-ui/core';
import IconButton from './IconButton';
import {ReactComponent as QuestionIcon} from '../../assets/images/question.svg';

export type HintTooltipProps = {
  /**
   * Unique Id
   */
  id: string;
  /**
   * Custom icon
   */
  icon?: ReactNode | undefined;
  /**
   * Hint text
   */
  content: string | ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    position: 'relative',
    overflow: 'unset',
    fontSize: theme.typography.body2.fontSize,
    color: '#606060',
    borderRadius: 10,
    backgroundColor: theme.palette.divider,
    padding: '.625rem 1.25rem',
    boxShadow: '0px 5px 30px rgba(0, 0, 0, 0.1)',
    margin: '14px 0 0 -17px',
    zIndex: 1300,
    maxWidth: 260,
    minWidth: '12rem',
    '&:before': {
      content: '""',
      position: 'absolute',
      marginLeft: 20,
      top: -10,
      left: 0,
      width: 0,
      height: 0,
      borderWidth: '0 5px 10px 5px',
      borderColor: 'transparent transparent #EDEDED transparent',
      borderStyle: 'solid',
    },
  },
}));

/**
 * Tip button with popover hint
 */
const HintTooltip = ({id, content, icon}: HintTooltipProps) => {
  const classes = useStyles();
  const [trigger, setTrigger] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<any>();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setTrigger('click');
  };

  const handleHover = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!open && !trigger) {
      setTrigger('mouse');
      setAnchorEl(event.currentTarget);
    }
  };

  const handleLeave = () => {
    if (open && trigger === 'mouse') {
      setAnchorEl(null);
      setTrigger(null);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setTrigger(null);
  };

  return (
    <ClickAwayListener onClickAway={handlePopoverClose}>
      <div className="d-inline-flex">
        <IconButton
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          variant="filled"
          size="xs"
          id={id}
          onHover={handleHover}
          onMouseLeave={handleLeave}
          onClick={handleClick}
        >
          {icon ?? <QuestionIcon />}
        </IconButton>
        <Popover
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography variant="body2">{content}</Typography>
        </Popover>
      </div>
    </ClickAwayListener>
  );
};

HintTooltip.defaultProps = {
  icon: undefined,
};

export default HintTooltip;
