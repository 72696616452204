import {useIntl} from 'react-intl';
import {useEffect, useMemo} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {
  formatErrorMessage,
  isApiValidationError,
  mapErrorToFormFields,
} from '../utils/functions';
import {CustomError} from '../types/CustomError';
import {AppBuildParams, useCreateAppBuildMutation} from '../services/appApi';
import {BuildEnvEnum, BuildTypeEnum, PlatformEnum} from '../types/Build';

type FormData = {
  type: BuildEnvEnum;
  platform: PlatformEnum;
  appVersion: string;
  versionCode: number;
};

export type UseCreateAppBuildProps = {
  appId: number;
};

const useCreateAppBuild = ({appId}: UseCreateAppBuildProps) => {
  // translations
  const intl = useIntl();

  const schema = useMemo(
    () =>
      yup.object().shape({
        type: yup
          .string()
          .oneOf(
            [BuildEnvEnum.Production, BuildEnvEnum.Testing],
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        platform: yup
          .string()
          .oneOf(
            [PlatformEnum.Android, PlatformEnum.Ios],
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        appVersion: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        versionCode: yup
          .number()
          .integer(
            intl.formatMessage({id: 'validation.error.only_positive_number'})
          )
          .positive(
            intl.formatMessage({id: 'validation.error.only_positive_number'})
          )
          // convert empty string to null
          .transform((value, originalValue) =>
            originalValue === '' ? null : value
          )
          .nullable()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .typeError(
            intl.formatMessage({id: 'validation.error.only_positive_number'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: {errors, submitCount},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  // disable submit button if theres error
  const disableButton = (): boolean => {
    let disable = false;
    if (Object.keys(errors).length > 0) {
      disable = true;
    }
    return disable;
  };

  const [createAppBuild, {isSuccess, isLoading, data, error: createAppError}] =
    useCreateAppBuildMutation();

  // submit request
  const onSubmit = async (formData: FormData) => {
    const data: AppBuildParams = {
      appId,
      body: {
        type: BuildTypeEnum.Build,
        env: formData.type,
        platform: formData.platform,
        appVersion: formData.appVersion,
        versionCode: formData.versionCode,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.app_build_created_success_message',
        });
      },
    };
    createAppBuild(data);
  };

  // Format api validation errors to input field
  useEffect(() => {
    if (createAppError) {
      const typedUpdateError = createAppError as CustomError;
      if (isApiValidationError(typedUpdateError)) {
        mapErrorToFormFields(typedUpdateError.data.message, setError);
      }
    }
  }, [createAppError]);

  return {
    onSubmit,
    isSuccess,
    isLoading,
    data,
    control,
    handleSubmit,
    submitCount,
    errors,
    disableButton,
    reset,
  };
};

export default useCreateAppBuild;
