import {useLocation, useParams} from 'react-router-dom';
import * as urls from '../../../constants/urls';
import {useState} from 'react';
import {TabType} from '../../Common/Tabs';
import {useIntl} from 'react-intl';
import GeneralTab from './General/GeneralTab';
import AppSettingsTab from './AppSettings/AppSettingsTab';
import ThemeTab from './Theme/Theme';
import {Typography} from '@material-ui/core';
import StatusIndicator from '../../Common/StatusIndicator';
import {getColor} from '../../../utils/functions';
import {COLOR_STATUS} from '../../../utils/color';
import TabWidget from '../../Widgets/TabWidget';
import useAppDetails from '../../../hooks/app/useAppDetails';
import useAppBuild from '../../../hooks/app/useAppBuild';
import Button from '../../Common/Button';
import AddPopup from '../../Widgets/AddPopup';
import CreateAppBuildForm from '../Form/CreateAppBuildForm';
import AddIcon from '@material-ui/icons/Add';

/**
 *
 * App Details
 *
 */
const AppDetails = () => {
  const intl = useIntl();
  const {appId} = useParams();
  const {pathname} = useLocation();
  const [addPopup, setAddPopup] = useState(false);

  //Current path
  const path = urls.APP_DETAILS_PATH.replace(':appId', appId as string);

  // Active tab
  const [activeTab, setActiveTab] = useState(pathname);

  // switch active tab
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const {data, control, count, cancelGeneralSave, handleSubmit, submit} =
    useAppDetails({
      id: Number(appId),
    });

  const {data: buildsData} = useAppBuild({
    appId: Number(appId),
  });

  // close Add App Popup after successful creation
  const closePopup = () => {
    setAddPopup(false);
  };

  const tabs: TabType[] = [
    {
      label: intl.formatMessage({
        id: 'dashboard.app_details.tabs.general',
      }),
      value: `${path}/general`,
      children: (
        <GeneralTab
          control={control}
          count={count}
          cancelSave={cancelGeneralSave}
          handleSubmit={handleSubmit}
          updateAppDetails={submit}
          data={data}
          buildsData={buildsData}
        />
      ),
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.app_details.tabs.app_settings',
      }),
      value: `${path}/settings`,
      children: <AppSettingsTab />,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.app_details.tabs.theme',
      }),
      value: `${path}/theme`,
      children: <ThemeTab data={data!} />,
    },
  ];

  // Header content
  const header = (
    <div className="container-fluid top-block bg-white border-0">
      <div className="details-header">
        <div className="row align-items-center title-block pb-lg-vtl-19">
          <div className="col-lg-6">
            <div className="details-header-part d-flex flex-column flex-lg-row align-items-center align-items-lg-start justify-content-center justify-content-lg-start">
              <div className="details-header-part text-center text-lg-start">
                <div className="d-flex flex-column align-items-center align-items-lg-start">
                  {data && (
                    <div className="d-flex align-items-center">
                      <Typography
                        variant="h3"
                        className="font-weight-bolder pe-2"
                      >
                        {data?.name}
                      </Typography>
                      <StatusIndicator
                        label={data?.status}
                        color={getColor(
                          data?.status.toLowerCase() as keyof typeof COLOR_STATUS
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex justify-content-center justify-content-lg-end pt-vtl-15 pt-lg-0">
              <Button
                primary
                title={intl.formatMessage({
                  id: 'dashboard.app_details.general_tab.create_a_build',
                })}
                className="dashboard-btn"
                icon={<AddIcon />}
                onClick={() => setAddPopup(true)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <AddPopup
        opened={addPopup}
        onClose={() => setAddPopup(false)}
        title={intl.formatMessage({
          id: 'dashboard.app_details.general_tab.create_a_build',
        })}
        activeTab="app"
      >
        <CreateAppBuildForm
          form="app-form"
          closePopup={closePopup}
          appId={Number(appId)}
        />
      </AddPopup>
      <div className={'details-page'}>
        {header}
        <TabWidget
          containerClass="container-fluid top-block bg-white"
          wrapperClass="details-header pt-0"
          tabs={tabs}
          value={activeTab}
          onChange={toggle}
        />
      </div>
    </>
  );
};

export default AppDetails;
