import {withStyles, Theme} from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper, {PaperProps} from '@material-ui/core/Paper';
import InputBase, {InputBaseProps} from '@material-ui/core/InputBase';
import IconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import {ReactComponent as SearchIcon} from '../../assets/images/search.svg';
import CloseIcon from '@material-ui/icons/Close';

export type SearchTextFieldProps = Omit<
  InputBaseProps,
  'placeholder' | 'classes'
> & {
  placeholder?: string;
  grayBackground?: boolean;
  buttonProps?: IconButtonProps;
  containerProps?: PaperProps;
  classes?: {
    root?: string;
    input?: string;
    icon?: string;
  };
  onClear?: () => void;
};

/**
 * Search Text Field
 */
const SearchTextField = withStyles((theme: Theme) => ({
  root: {
    padding: '7px 20px',
    height: 40,
    display: 'flex',
    flex: '1 1',
    alignItems: 'center',
    borderRadius: 40,

    '&.bgGray': {
      backgroundColor: theme.palette.background.default,
    },

    [theme.breakpoints.down('md')]: {
      height: 42,
      boxShadow: theme.shadows[2],
      padding: '7px 17px 7px 14px',
    },
  },
  input: {
    flex: 1,
    fontSize: 14,
  },
  icon: {
    padding: '0 0 0 12px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(
  ({
    placeholder,
    grayBackground,
    buttonProps,
    containerProps,
    classes,
    onClear,
    ...inputProps
  }: SearchTextFieldProps) => {
    return (
      <Paper
        className={clsx(classes?.root, grayBackground && 'bgGray')}
        elevation={0}
        {...containerProps}
      >
        <InputBase
          className={classes?.input}
          placeholder={placeholder}
          {...inputProps}
        />
        {onClear && inputProps?.value ? (
          <IconButton className={classes?.icon} type="button" onClick={onClear}>
            <CloseIcon className="text-muted" style={{width: 18}} />
          </IconButton>
        ) : (
          <IconButton className={classes?.icon} type="button" {...buttonProps}>
            <SearchIcon />
          </IconButton>
        )}
      </Paper>
    );
  }
);
export default SearchTextField;
