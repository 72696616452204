import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  useDeleteMutation,
  UserActionParam,
  useSuspendAndUnspendMutation,
} from '../services/userApi';
import {enqueueMessage} from '../store/slices/appSlice';
import * as urls from '../constants/urls';
import {formatErrorMessage} from '../utils/functions';
import {UserStatus} from '../types/User';

export type ActionParam = 'delete' | 'suspend' | 'unsuspend';

export type UserActionProps = {
  userId?: number | string;
};

type ConfirmPopupState = {
  suspend: boolean;
  unsuspend: boolean;
  delete: boolean;
};

const useUserAction = (props: UserActionProps) => {
  const {userId} = props;
  // translations
  const intl = useIntl();

  // react-router
  const navigate = useNavigate();

  // redux dispatch
  const dispatch = useDispatch();

  // suspend and unsuspend user
  const [suspendAndUnsuspendAccount] = useSuspendAndUnspendMutation();

  // delete user
  const [deleteUser, {isSuccess}] = useDeleteMutation();

  // manage confirm popup for each action
  const [open, setOpen] = useState<ConfirmPopupState>({
    suspend: false,
    unsuspend: false,
    delete: false,
  });

  // open/close confirm popup
  const openConfirmationPopup = (action: ActionParam, isOpen: boolean) => {
    setOpen(prevState => ({
      ...prevState,
      [action]: isOpen,
    }));
  };

  // suspension action
  const suspendUserAction = () => {
    // close confirmation popup
    setOpen({
      suspend: false,
      unsuspend: false,
      delete: false,
    });

    const status = UserStatus.Suspended;
    const data: UserActionParam = {
      id: userId,
      body: {
        status,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'dashboard.users.messages.suspend_user_message',
        });
        return message;
      },
    };
    suspendAndUnsuspendAccount(data);
  };

  // unsuspension action
  const unsuspendUserAction = () => {
    // close confirmation popup
    setOpen({
      suspend: false,
      unsuspend: false,
      delete: false,
    });

    const status = UserStatus.Active;
    const data: UserActionParam = {
      id: userId,
      body: {
        status,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'dashboard.users.messages.unsuspend_user_message',
        });
        return message;
      },
    };
    suspendAndUnsuspendAccount(data);
  };

  // delete user action
  const deleteUserAction = () => {
    // close confirmation popup
    setOpen({
      suspend: false,
      unsuspend: false,
      delete: false,
    });

    const data: UserActionParam = {
      id: userId,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
    };
    deleteUser(data);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        enqueueMessage({
          body: intl.formatMessage({
            id: 'dashboard.users.messages.delete_success_message',
          }),
          variant: 'success',
        })
      );
      navigate(urls.USERS_LIST_PATH);
    }
  }, [isSuccess]);

  return {
    open,
    openConfirmationPopup,
    suspendUserAction,
    unsuspendUserAction,
    deleteUserAction,
  };
};

export default useUserAction;
