import {baseApi} from './baseApi';

export type LocationQueryParam = 'buyer' | 'store';

export type LocationResponse = {
  id: number;
  city: string;
};

export const listLocationApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchLocation: builder.query<LocationResponse[], LocationQueryParam>({
      query: (query: LocationQueryParam) => ({
        url: '/admin/address/cities',
        params: {owner: query},
        method: 'GET',
      }),
    }),
  }),
});

export const {useFetchLocationQuery} = listLocationApi;
