import {TagDescription} from '@reduxjs/toolkit/dist/query';
import {FeedbackParams} from '../store/queries/common';
import {FeeType, Fee} from '../types/Fee';
import {baseApi} from './baseApi';

export type ListFeeResponse = {
  data: Fee[];
};

export type ListFeeQueryParameter = {
  query: {
    storeId?: number | string;
  };
} & FeedbackParams;

export type FeeRequestParameter = {
  id?: number;
  body: {
    storeId?: number | string;
    name: string;
    type: FeeType;
    buyerFeePercentage: number;
    buyerFeeFixed: number;
    sellerFeePercentage: number;
    sellerFeeFixed: number;
    description: string;
  };
} & FeedbackParams;

export type DeleteFeeParam = {
  id?: number | string;
} & FeedbackParams;

export type GetFeeResponse = {
  fee: Fee;
};

const onInvalidate = (result: Fee | undefined): TagDescription<'Fee'>[] =>
  result ? ['Fee'] : [];

export const feeApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    listFee: builder.query<ListFeeResponse, ListFeeQueryParameter>({
      query: ({query}: ListFeeQueryParameter) => ({
        url: `/admin/store/${query.storeId}/fee`,
        method: 'GET',
      }),
      providesTags: ['Fee'],
    }),
    createFee: builder.mutation<any, FeeRequestParameter>({
      query: ({body}: FeeRequestParameter) => ({
        url: '/admin/fee',
        method: 'POST',
        body,
      }),
      invalidatesTags: result => onInvalidate(result),
    }),
    deleteFee: builder.mutation<any, DeleteFeeParam>({
      query: ({id}: DeleteFeeParam) => ({
        url: `/admin/fee/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => onInvalidate(result),
    }),
    updateFee: builder.mutation<any, FeeRequestParameter>({
      query: ({body, id}: FeeRequestParameter) => ({
        url: `/admin/fee/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: result => onInvalidate(result),
    }),
  }),
});

export const {
  useListFeeQuery,
  useCreateFeeMutation,
  useDeleteFeeMutation,
  useUpdateFeeMutation,
} = feeApi;
