import {baseApiPublic} from './baseApi';

export const logoutApi = baseApiPublic.injectEndpoints({
  endpoints: builder => ({
    logoutUser: builder.query<void, void>({
      query: () => ({
        url: '/auth/logout',
        method: 'GET',
      }),
    }),
  }),
});

export const {useLazyLogoutUserQuery} = logoutApi;
