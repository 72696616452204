import {makeStyles, Theme} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage} from 'react-intl';
import {Stack} from 'react-bootstrap';
import IconButton from '../../../Common/IconButton';
import {ReactComponent as EditIcon} from '../../../../assets/images/editGreen.svg';
import {ReactComponent as ImportIcon} from '../../../../assets/images/import.svg';
import {ReactComponent as TrashIcon} from '../../../../assets/images/trashRed.svg';
import StatusIndicator from '../../../Common/StatusIndicator';
import {ProductCategory} from '../../../../types/ProductCategory';

/**
 * Category manager actions
 */

export type CategoryActionsProps = {
  category: ProductCategory;
  onEdit: () => void;
  onImport: () => void;
  onDelete: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    marginBottom: 0,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    listStyle: 'none',
    paddingLeft: 0,
    paddingTop: theme.spacing(0.2),
  },
  item: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-line',
    '&:before': {
      content: '"\u2022"',
      lineHeight: 1.1,
      marginLeft: -theme.spacing(1.5),
      padding: theme.spacing(0, 0.3),
    },
  },
}));

const CategoryActions = ({
  category,
  onEdit,
  onImport,
  onDelete,
}: CategoryActionsProps) => {
  const classes = useStyles();
  // status indicator
  const statusColor: any = {
    on: 'green',
    off: 'error',
    deleted: 'error',
    offForToday: 'orange',
  };

  const formattedStatus = {
    on: 'On',
    off: 'Off',
    offForToday: 'Off Today',
    deleted: 'Deleted',
  };

  return (
    <div className="mb-3">
      <Stack direction="horizontal" className="gap-vtl-15 mb-2">
        <Typography
          variant="h3"
          className="font-weight-bolder position-relative"
          style={{zIndex: 2}}
        >
          {category.name}
        </Typography>
        <IconButton variant="action" size="xs" onClick={onEdit}>
          <EditIcon />
        </IconButton>
        <IconButton
          variant="action"
          size="xs"
          className="text-success"
          onClick={onImport}
        >
          <ImportIcon />
        </IconButton>
        <IconButton variant="action" size="xs" onClick={onDelete}>
          <TrashIcon />
        </IconButton>
      </Stack>
      <div className="d-flex align-items-center flex-wrap">
        <StatusIndicator
          label={formattedStatus[category.status]}
          color={statusColor[category.status]}
          className="ps-vtl-3 ps-lg-0"
        />
        <ul className={classes.list}>
          <li className={classes.item}>
            <Typography>
              <FormattedMessage id="dashboard.store_details.product_categories_tab.category.id" />{' '}
              <span>{category.id}</span>
            </Typography>
          </li>
          <li className={classes.item}>
            <Typography>{category?.description}</Typography>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CategoryActions;
