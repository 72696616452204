import {FeedbackParams} from '../store/queries/common';
import {ProductOptionGroup} from '../types/ProductOptionGroup';
import {ProductVariation} from '../types/ProductVariation';
import {OrderParam} from './productCategoryApi';
import {DeleteParam} from './storeApi';
import {baseApi} from './baseApi';

export type FetchOptionGroupParam = {
  storeId?: string | number;
  query?: {
    pageSize?: number;
    page?: number;
    search?: string;
    searchType?: string;
    orderBy?: string;
    order?: 'ASC' | 'DESC';
    uniqueId?: string;
  };
} & FeedbackParams;

export type ProductOptionResponse = {
  data: ProductOptionGroup[];
};

export type CreateUpdateOptionGroupParam = {
  id?: string | number;
  body: {
    name: string;
    storeId?: string | number;
    description?: string;
  };
} & FeedbackParams;

export type ProductGroupOrderParam = {
  storeId?: string | number;
  body: {
    groups: OrderParam[];
  };
} & FeedbackParams;

export type ProductVariationResponse = {
  data: ProductVariation[];
};

export type CreateUpdateVariationParam = {
  id?: string | number;
  body: {
    name: string;
    optionGroupId?: string | number;
    description?: string;
    min?: number;
    max?: number | null;
  };
} & FeedbackParams;

export type CreateUpdateOptionParam = {
  id?: string | number;
  body: {
    name: string;
    price: number;
    status?: string;
    variationId?: string | number;
  };
} & FeedbackParams;

export type UpdateOptionStatusParam = {
  id?: string | number;
  body: {
    status?: string;
  };
} & FeedbackParams;

const onInvalidateOptionGroup = (result: any) =>
  result ? ['ProductOptionGroup'] : ['Nothing'];

export const productOptionGroupApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchProductOptionGroup: builder.query<
      ProductOptionResponse,
      FetchOptionGroupParam
    >({
      query: ({storeId, query}: FetchOptionGroupParam) => ({
        url: `/product/option-group/${storeId}`,
        method: 'GET',
        params: {
          ...query,
        },
      }),
      providesTags: ['ProductOptionGroup'],
    }),
    createProductOptionGroup: builder.mutation<
      any,
      CreateUpdateOptionGroupParam
    >({
      query: ({body}: CreateUpdateOptionGroupParam) => ({
        url: '/product/option-group',
        method: 'POST',
        body,
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateOptionGroup(result),
    }),
    updateProductOptionGroup: builder.mutation<
      any,
      CreateUpdateOptionGroupParam
    >({
      query: ({id, body}: CreateUpdateOptionGroupParam) => ({
        url: `/product/option-group/${id}`,
        method: 'PUT',
        body,
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateOptionGroup(result),
    }),
    deleteProductOptionGroup: builder.mutation<any, DeleteParam>({
      query: ({id}: DeleteParam) => ({
        url: `/product/option-group/${id}`,
        method: 'DELETE',
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateOptionGroup(result),
    }),
    orderProductOptionGroup: builder.mutation<any, ProductGroupOrderParam>({
      query: ({storeId, body}: ProductGroupOrderParam) => ({
        url: `/product/option-group/${storeId}/order`,
        method: 'PUT',
        body,
      }),
    }),
    createProductVariation: builder.mutation<any, CreateUpdateVariationParam>({
      query: ({body}: CreateUpdateVariationParam) => ({
        url: '/product/variation',
        method: 'POST',
        body,
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateOptionGroup(result),
    }),
    updateProductVariation: builder.mutation<any, CreateUpdateVariationParam>({
      query: ({id, body}: CreateUpdateVariationParam) => ({
        url: `/product/variation/${id}`,
        method: 'PUT',
        body,
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateOptionGroup(result),
    }),
    deleteProductVariation: builder.mutation<any, DeleteParam>({
      query: ({id}: DeleteParam) => ({
        url: `/product/variation/${id}`,
        method: 'DELETE',
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateOptionGroup(result),
    }),
    createProductOption: builder.mutation<any, CreateUpdateOptionParam>({
      query: ({body}: CreateUpdateOptionParam) => ({
        url: '/product/option',
        method: 'POST',
        body,
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateOptionGroup(result),
    }),
    updateProductOption: builder.mutation<any, CreateUpdateOptionParam>({
      query: ({id, body}: CreateUpdateOptionParam) => ({
        url: `/product/option/${id}`,
        method: 'PUT',
        body,
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateOptionGroup(result),
    }),
    updateProductOptionStatus: builder.mutation<any, UpdateOptionStatusParam>({
      query: ({id, body}: UpdateOptionStatusParam) => ({
        url: `/product/option/${id}/status`,
        method: 'PUT',
        body,
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateOptionGroup(result),
    }),
    deleteProductOption: builder.mutation<any, DeleteParam>({
      query: ({id}: DeleteParam) => ({
        url: `/product/option/${id}`,
        method: 'DELETE',
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateOptionGroup(result),
    }),
  }),
});

export const {
  useFetchProductOptionGroupQuery,
  useCreateProductOptionGroupMutation,
  useUpdateProductOptionGroupMutation,
  useDeleteProductOptionGroupMutation,
  useOrderProductOptionGroupMutation,
  useCreateProductVariationMutation,
  useUpdateProductVariationMutation,
  useDeleteProductVariationMutation,
  useCreateProductOptionMutation,
  useUpdateProductOptionMutation,
  useUpdateProductOptionStatusMutation,
  useDeleteProductOptionMutation,
} = productOptionGroupApi;
