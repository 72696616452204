import {withStyles, Theme} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

/**
 * Linear progress bar component
 */
const ProgressBar = withStyles((theme: Theme) => ({
  root: {
    height: 5,
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))(LinearProgress);

export default ProgressBar;
