import {FormattedMessage} from 'react-intl';
import {Typography} from '@material-ui/core';
import {Col, Image, Row} from 'react-bootstrap';
import {useMediaQuery} from 'react-responsive';
import {BREAKPOINTS} from '../../../constants/appConstants';
import FormattedCurrency from '../../Shared/FormattedCurrency';
import {OrderProduct} from '../../../types/OrderProduct';

type ProductItemProps = {
  product: OrderProduct;
  currency?: string;
};

const ProductItem = ({product, currency = 'GBP'}: ProductItemProps) => {
  const isMobile = useMediaQuery({maxWidth: BREAKPOINTS.LG});
  const imgSizes = {
    width: isMobile ? 56 : 50,
    height: isMobile ? 56 : 50,
  };

  const textVariant = isMobile ? 'subtitle2' : 'body1';

  return (
    <div className="pb-lg-1">
      <Row className="gx-vtl-16 gx-lg-3">
        <Col xs="auto">
          <Image
            className="rounded-sm"
            src={product.image ? product.image : '/images/defaultFood.svg'}
            style={imgSizes}
          />
        </Col>
        <Col>
          <Typography variant={textVariant} className="mb-1 mt-lg-vtl-7">{`${
            product.name
          } ${product.chineseName ? product.chineseName : ''}`}</Typography>
          <Row className="mb-1 mb-lg-2">
            <Typography
              variant={textVariant}
              color="textSecondary"
              className="mb-lg-1"
            >
              <FormattedMessage id="order_details.product.sku" />{' '}
              {product.sku.toUpperCase()}
            </Typography>
          </Row>
          {product.variations.length > 0 && (
            <Row className="pt-vtl-8 pt-lg-2 mb-1 mb-lg-2">
              <Col xs={6} lg={4}>
                <Typography color="textSecondary" variant={textVariant}>
                  <FormattedMessage id="order_details.product.options" />
                </Typography>
              </Col>
              <Col>
                {product.variations.map(variation => (
                  <Typography key={variation.id} variant={textVariant}>
                    {variation.name}:{' '}
                    {variation.options.map(option => option.name).join(', ')}
                  </Typography>
                ))}
              </Col>
            </Row>
          )}
          {product.specialInstructions && (
            <Row className="mb-1 mb-lg-2">
              <Col xs={6} lg={4}>
                <Typography color="textSecondary" variant={textVariant}>
                  <FormattedMessage id="order_details.product.special_instruction" />
                </Typography>
              </Col>
              <Col>
                <Typography variant={textVariant}>
                  {product.specialInstructions}
                </Typography>
              </Col>
            </Row>
          )}
          <Row className="mb-1 mb-lg-2">
            <Col xs={6} lg={4}>
              <Typography color="textSecondary" variant={textVariant}>
                <FormattedMessage id="order_details.product.qty_unit_price" />
              </Typography>
            </Col>
            <Col>
              <Typography variant={textVariant}>
                {product.quantity} x{' '}
                <FormattedCurrency value={product.price} currency={currency} />
              </Typography>
            </Col>
          </Row>
          <Row className="pt-1">
            <Col xs={6} lg={4}>
              <Typography className="font-weight-bold" variant={textVariant}>
                <FormattedMessage id="order_details.total" />
              </Typography>
            </Col>
            <Col>
              <Typography className="font-weight-bold" variant={textVariant}>
                <FormattedCurrency value={product.total} currency={currency} />
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ProductItem;
