export const pathPrefix = '/admin';

// Base URLS
export const IMG_BASE_URL = `${process.env.PUBLIC_URL}/images/`;
export const WS_AUTH_BASE_URL = process.env.REACT_APP_WS_AUTH_BASE_URL;
export const WS_USER_BASE_URL = process.env.REACT_APP_WS_USER_BASE_URL;
export const WS_PLATFORM_BASE_URL = process.env.REACT_APP_WS_PLATFORM_BASE_URL;
export const WS_PRODUCT_BASE_URL = process.env.REACT_APP_WS_PRODUCT_BASE_URL;
export const WS_CATEGORY_BASE_URL = process.env.REACT_APP_WS_CATEGORY_BASE_URL;

export const HOME_PAGE = '/';

// LOGIN
export const LOGIN_PATH = '/';

// USERS DASHBOARD
// User list
export const USERS_LIST_PATH = `${pathPrefix}/users`;
export const USERS_BUYER_LIST_PATH = `${pathPrefix}/users/buyer`;
export const USERS_SELLER_LIST_PATH = `${pathPrefix}/users/seller`;
export const USERS_LIST_TAB_PATH = `${pathPrefix}/users/:tab`;
// User details
export const BUYER_DETAILS_PATH = `${pathPrefix}/users/buyer/:id`;
export const BUYER_PROFILE_DETAILS_PATH = `${pathPrefix}/users/buyer/:id/profile`;
export const SELLER_DETAILS_PATH = `${pathPrefix}/users/seller/:id`;

// Checkout list
export const CHECKOUT_LIST_PATH = `${pathPrefix}/checkout`;

// JOBS DASHBOARD
// Job list
export const JOB_LIST_PATH = `${pathPrefix}/jobs`;
// Job details
export const JOB_DETAILS_PATH = `${pathPrefix}/jobs/:jobId`;

// Orders list
export const ORDERS_LIST_PATH = `${pathPrefix}/orders`;
export const ORDER_DETAILS_PATH = `${pathPrefix}/orders/:orderId`;

// Sales list
export const SALES_LIST_PATH = `${pathPrefix}/sales`;
export const SALES_DETAILS_PATH = `${pathPrefix}/sales/:saleId`;

// Product details
export const PRODUCT_DETAILS_PATH = `${pathPrefix}/stores/products/:productId`;

// Discount details
export const DISCOUNT_DETAILS_PATH = `${pathPrefix}/stores/:storeId/discount/:discountId`;

// Stores list
export const STORES_LIST_PATH = `${pathPrefix}/stores`;
// Store details
export const STORE_DETAILS_PATH = `${pathPrefix}/stores/:storeId`;
// Store details
export const STORE_DETAILS_TAB = `${pathPrefix}/stores/:storeId/:tab`;

// Categories
export const CATEGORIES_PATH = `${pathPrefix}/categories`;
export const SMART_CATEGORIES_PATH = `${pathPrefix}/categories/smart_categories`;
export const SMART_CATEGORY_ADD_PATH = `${pathPrefix}/categories/smart_categories/add`;
export const SMART_CATEGORY_DETAILS_PATH = `${pathPrefix}/categories/smart_categories/:id`;

// Promotions
export const PROMOTIONS_PATH = `${pathPrefix}/promotions`;
export const PROMOTIONS_DETAILS_PATH = `${pathPrefix}/promotions/:id`;

// Settings
export const SETTINGS_PATH = `${pathPrefix}/settings`;
export const USER_WORKFLOW_PATH = `${pathPrefix}/settings/user_workflow`;
export const FEES_PATH = `${pathPrefix}/settings/fees`;
export const NOTIFICATIONS_SETTINGS_PATH = `${pathPrefix}/settings/notifications`;
export const PLATFORM_SETTINGS_PATH = `${pathPrefix}/settings/platform`;
export const SHIPPING_SETTINGS = `${pathPrefix}/settings/shipping`;
export const TAX_SETTINGS = `${pathPrefix}/settings/tax`;
// Account settings
export const ACCOUNT_SETTINGS = `${pathPrefix}/account`;
export const BILLING_PATH = `${pathPrefix}/billing`;

// Admin Platforms
export const ADMIN_PLATFORMS_LIST_PATH = `${pathPrefix}/admin/platforms`;
export const ADMIN_PLATFORMS_DETAILS_PATH = `${pathPrefix}/admin/platforms/:id`;

// Admin User List
export const ADMIN_USER_LIST_PATH = `${pathPrefix}/admin/users`;

// Transactions
export const TRANSACTION_LIST_PATH = `${pathPrefix}/transactions`;

// Apps
export const APP_LIST_PATH = `${pathPrefix}/apps`;
export const APP_DETAILS_PATH = `${pathPrefix}/apps/:appId`;

export const API_LOGIN_URL = `${WS_AUTH_BASE_URL}auth/login/admin`;
export const API_USER_ME_URL = `${WS_USER_BASE_URL}users/me`;
export const API_USER_REGISTER_URL = `${WS_USER_BASE_URL}users/register`;
export const API_USER_SW_CLOUD_REGISTER_URL = `${WS_USER_BASE_URL}users/register/admin`;
export const API_USERS_URL = `${WS_USER_BASE_URL}users`;
export const API_USER_ITEM_URL = `${WS_USER_BASE_URL}users/:id`;
export const API_USER_CHANGE_PICTURE_URL = `${WS_USER_BASE_URL}users/:id/profile-image`;
export const API_USER_PICTURE_URL = `${WS_USER_BASE_URL}users/profile-image/:filename`;
export const API_USER_DELETE_PICTURE_URL = `${WS_USER_BASE_URL}users/:id/profile-image`;
export const API_USER_UPDATE_URL = `${WS_USER_BASE_URL}users/:id`;
export const API_USER_CA_IMAGE_URL = `${WS_USER_BASE_URL}users/:id/custom-attributes/image`;
export const API_USER_CUSTOM_ATTRIBUTE_IMAGE_URL = `${WS_USER_BASE_URL}users/:id/custom-attributes/images/:name`;
export const API_CW_USER_LIST_URL = `${WS_USER_BASE_URL}users/admin/list`;

// Platform
export const API_PLATFORMS_URL = `${WS_PLATFORM_BASE_URL}platforms`;
export const API_PLATFORM_ITEM_URL = `${WS_PLATFORM_BASE_URL}platforms/:id`;
export const API_PLATFORM_ROLE_MICROSERVICES_URL = `${WS_PLATFORM_BASE_URL}platforms/:id/microservices`;

// User address
export const API_USER_ADDRESS_URL = `${WS_USER_BASE_URL}user-address`;
export const API_USER_ADDRESS_EDIT_URL = `${WS_USER_BASE_URL}user-address/:id`;
export const API_USER_ADDRESS_LIST_URL = `${WS_USER_BASE_URL}user-address/list/:userId`;

// Custom attributes
export const API_USER_CUSTOM_ATTRIBUTE_LIST_URL = `${WS_USER_BASE_URL}custom-attributes`;
export const API_OBJECTS_DEFINITION_URL = `${WS_USER_BASE_URL}custom-objects`;
export const API_USER_CUSTOM_OBJECTS_URL = `${WS_USER_BASE_URL}custom-objects/:objectName`;
export const API_USER_CUSTOM_OBJECTS_ITEM_URL = `${WS_USER_BASE_URL}custom-objects/:objectName/:id`;

// User note
export const API_USER_NOTES_URL = `${WS_USER_BASE_URL}user-notes`;
export const API_USER_NOTES_ITEM_URL = `${WS_USER_BASE_URL}user-notes/:id`;

// User status
export const API_USER_STATUS_URL = `${WS_USER_BASE_URL}user-status`;

// User document
export const API_USER_DOCUMENTS_URL = `${WS_USER_BASE_URL}user-documents`;
export const API_USER_DOCUMENTS_ITEM_URL = `${WS_USER_BASE_URL}user-documents/:id`;
export const API_USER_DOCUMENT_DOWNLOAD_URL = `${WS_USER_BASE_URL}media/:filename`;

export const API_MEDIA_FILE_URL = `${WS_USER_BASE_URL}media/:filename`;
export const API_MEDIA_PRESIGNED_URL = `${WS_USER_BASE_URL}media/presigned`;

// Product
export const API_PRODUCTS_URL = `${WS_PRODUCT_BASE_URL}products`;
export const API_PRODUCT_URL = `${WS_PRODUCT_BASE_URL}products/:id`;

// Product Status
export const API_PRODUCT_STATUS_URL = `${WS_PRODUCT_BASE_URL}product-status`;

// Product Custom attributes
export const API_PRODUCT_CUSTOM_ATTRIBUTE_LIST_URL = `${WS_PRODUCT_BASE_URL}custom-attributes`;
export const API_PRODUCT_OBJECTS_DEFINITION_URL = `${WS_PRODUCT_BASE_URL}custom-objects`;

// Category
export const API_CATEGORIES_URL = `${WS_CATEGORY_BASE_URL}categories`;
export const API_CATEGORY_URL = `${WS_CATEGORY_BASE_URL}categories/:id`;

// Product Custom Objects
export const API_PRODUCT_CUSTOM_OBJECTS_URL = `${WS_PRODUCT_BASE_URL}custom-objects/:objectName`;
export const API_PRODUCT_CUSTOM_OBJECTS_ITEM_URL = `${WS_PRODUCT_BASE_URL}custom-objects/:objectName/:id`;
export const API_PRODUCT_CA_IMAGE_URL = `${WS_PRODUCT_BASE_URL}products/:id/custom-attributes/images`;
export const API_PRODUCT_MEDIA_PRESIGNED_URL = `${WS_PRODUCT_BASE_URL}media/presigned`;

// Product variant
export const API_PRODUCT_VARIANTS_URL = `${WS_PRODUCT_BASE_URL}product-variants`;
export const API_PRODUCT_VARIANT_URL = `${WS_PRODUCT_BASE_URL}product-variants/:id`;
export const API_PRODUCT_VARIANT_ITEMS_URL = `${WS_PRODUCT_BASE_URL}product-variant-items`;
export const API_PRODUCT_VARIANT_ITEM_URL = `${WS_PRODUCT_BASE_URL}product-variant-items/:id`;

// Product picture
export const API_PRODUCT_PICTURES_URL = `${WS_PRODUCT_BASE_URL}product-pictures`;
export const API_PRODUCT_REORDER_PICTURES_URL = `${WS_PRODUCT_BASE_URL}products/:id/reorder-pictures`;
export const API_PRODUCT_PICTURE_URL = `${WS_PRODUCT_BASE_URL}product-pictures/:id`;

// Product tags
export const API_PRODUCT_TAGS_URL = `${WS_PRODUCT_BASE_URL}product-tags`;
export const API_PRODUCT_TAG_URL = `${WS_PRODUCT_BASE_URL}product-tags/:id`;

// Product notes
export const API_PRODUCT_NOTES_URL = `${WS_PRODUCT_BASE_URL}product-notes`;
export const API_PRODUCT_NOTE_URL = `${WS_PRODUCT_BASE_URL}product-notes/:id`;

// Product endpoints action
export const API_PRODUCT_ENDPOINTS_ACTIONS_URL = `${WS_PRODUCT_BASE_URL}actions/:objectType/:id`;
export const API_PRODUCT_ENDPOINTS_ACTION_URL = `${WS_PRODUCT_BASE_URL}actions/:objectType/:id/:pathname`;

// Category picture
export const API_CATEGORY_PICTURES_URL = `${WS_CATEGORY_BASE_URL}category-pictures`;
export const API_CATEGORY_REORDER_PICTURES_URL = `${WS_CATEGORY_BASE_URL}categories/:id/reorder-pictures`;
export const API_CATEGORY_PICTURE_URL = `${WS_CATEGORY_BASE_URL}category-pictures/:id`;

// Smart categories
export const API_SMART_CATEGORIES_URL = `${WS_CATEGORY_BASE_URL}smart-categories`;
export const API_SMART_CATEGORY_URL = `${WS_CATEGORY_BASE_URL}smart-categories/:id`;
export const API_SMART_CATEGORY_REORDER_PICTURES_URL = `${WS_CATEGORY_BASE_URL}smart-categories/:id/reorder-pictures`;

// Category status
export const API_CATEGORY_STATUS_URL = `${WS_CATEGORY_BASE_URL}category-status`;

// Category tag
export const API_CATEGORY_TAGS_URL = `${WS_CATEGORY_BASE_URL}category-tags`;
export const API_CATEGORY_TAG_URL = `${WS_CATEGORY_BASE_URL}category-tags/:id`;
