import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useMemo} from 'react';
import {
  CreateProductParam,
  useCreateProductMutation,
} from '../services/productCategoryApi';
import {formatErrorMessage} from '../utils/functions';

type UseProductProps = {
  categoryId: number;
};

export type CreateProductValues = {
  image: any;
  name: string;
  chineseName: string;
  description: string;
  price: string;
};

export default function useCreateProduct({categoryId}: UseProductProps) {
  // Translations
  const intl = useIntl();

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        price: yup
          .number()
          .typeError(intl.formatMessage({id: 'validation.error.number'}))
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    watch,
    clearErrors,
    formState: {errors},
  } = useForm<CreateProductValues>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const [createProduct, {isSuccess: createSuccess, isLoading}] =
    useCreateProductMutation();

  const submit = (formValue: CreateProductValues) => {
    const formData = new FormData();
    formData.append('categoryId', String(categoryId));
    formData.append('name', formValue.name);
    formData.append('price', formValue.price);
    if (formValue.chineseName) {
      formData.append('chineseName', formValue.chineseName);
    }
    if (formValue.description) {
      formData.append('description', formValue.description);
    }
    if (formValue.image) {
      formData.append('image', formValue.image);
    }

    const data: CreateProductParam = {
      body: formData,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.create_product',
        });
      },
    };
    createProduct(data);
  };

  const resetFields = () => {
    reset({image: '', name: '', price: '', description: '', chineseName: ''});
  };

  return {
    control,
    handleSubmit,
    setValue,
    reset,
    submit,
    createSuccess,
    resetFields,
    setError,
    watch,
    clearErrors,
    errors,
    isLoading,
  };
}
