import React, {useState} from 'react';
import cx from 'clsx';
import {Box, makeStyles, Theme, Typography} from '@material-ui/core';
import {Stack} from 'react-bootstrap';
import TextField from '../Form/Inputs/TextField';
import IconButton from './IconButton';
import {ReactComponent as ApplyIcon} from '../../assets/images/apply.svg';
import {ReactComponent as CancelIcon} from '../../assets/images/cancel.svg';
import {useIntl} from 'react-intl';
import {isNumber} from '../../utils/functions';
import CurrencyInput from 'react-currency-input-field';
import useOnclickOutside from 'react-cool-onclickoutside';

export type ContentEditableProps = {
  /**
   * Text
   */
  content: string | number;
  /**
   * Crop lines in preview mode
   */
  maxLines?: number;
  /**
   * Typography props
   */
  typography?: any;
  /**
   * Preview className
   */
  className?: string;
  /**
   * Save callback
   */
  onChangeContent: (v: string | number, type: string) => void;
  name: string;
  inputType?: 'text' | 'number' | 'currency';
};

// Styles
const useStyles = makeStyles<Theme>(theme => ({
  root: {
    maxWidth: 250,
    '& + div, & + p': {
      marginTop: 5,
    },
    '&.edit': {
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    },
  },
  container: {
    minWidth: 130,
    backgroundColor: theme.palette.background.paper,
    alignItems: 'stretch',
    position: 'relative',
    zIndex: 1,
    '& .MuiFormControl-root': {
      minHeight: 37,
    },
    '& .MuiInputBase-root': {
      height: '100%',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: theme.spacing(0.4, 0, 0, 0.4),
    },
    '& .form-control': {
      borderRadius: theme.spacing(0.4, 0, 0, 0.4),
      borderColor: theme.palette.divider,
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5, 0.5),
    borderRadius: theme.spacing(0, 0.4, 0.4, 0),
    marginLeft: -2,
  },
}));

/**
 * Editable content component
 */
const ContentEditable = ({
  content,
  onChangeContent,
  name,
  maxLines,
  typography = {},
  className = '',
  inputType = 'text',
}: ContentEditableProps) => {
  // Translations
  const intl = useIntl();
  const [data, setData] = useState(content);
  const [error, setError] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  //styles
  const classes = useStyles();

  const ref = useOnclickOutside(() => {
    setIsEdit(false);
    if (content !== data) {
      onApplyClick();
    }
  });

  const enableEdit = () => {
    if (!isEdit) {
      setIsEdit(true);
    }
  };

  // save product data
  const onApplyClick = () => {
    if (!data) {
      setError(intl.formatMessage({id: 'validation.error.required_field'}));
      return;
    } else if (
      (inputType === 'currency' || inputType === 'number') &&
      !isNumber(data)
    ) {
      setError(intl.formatMessage({id: 'validation.error.number'}));
      return;
    } else if (inputType === 'currency' && Number(data) > 9999999) {
      setError(intl.formatMessage({id: 'validation.error.max_price_exceed'}));
      return;
    }
    setError('');
    onChangeContent(data, name);
    setIsEdit(false);
  };

  const onCancelClick = (e: any) => {
    e.stopPropagation();
    setError('');
    setData(content);
    setIsEdit(false);
  };

  const onValueChange = (value: string) => {
    if (value.length > 0) {
      setError('');
    }
    setData(value);
  };

  return (
    <div ref={ref}>
      <div
        className={cx(
          classes.root,
          {edit: isEdit},
          maxLines && `ellipsis-${maxLines}`
        )}
        onClick={enableEdit}
      >
        {!isEdit && (
          <Typography {...typography} className={className}>
            {inputType === 'currency' ? `£${data}` : data}
          </Typography>
        )}
        {isEdit && (
          <Stack className={classes.container} direction="horizontal" gap={0}>
            {inputType === 'currency' ? (
              <CurrencyInput
                className="form-control bg-white"
                value={data}
                onValueChange={value => onValueChange(value || '')}
                decimalsLimit={2}
                prefix="£"
              />
            ) : (
              <TextField
                multiline
                value={data}
                type={inputType}
                onChange={e => onValueChange(e.target.value)}
              />
            )}

            <Box className={classes.actions}>
              <Stack
                direction="horizontal"
                gap={1}
                className="align-self-start"
              >
                <IconButton size="sm" variant="action" onClick={onApplyClick}>
                  <ApplyIcon />
                </IconButton>
                <IconButton size="sm" variant="action" onClick={onCancelClick}>
                  <CancelIcon />
                </IconButton>
              </Stack>
            </Box>
          </Stack>
        )}
        {error && (
          <Typography style={{color: '#FCA800'}} variant="subtitle2">
            {String(error)}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ContentEditable;
