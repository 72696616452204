import {FormattedMessage, useIntl} from 'react-intl';
import {Typography} from '@material-ui/core';
import Tabs, {TabType} from '../Common/Tabs';
import {UseOrderListFilterSearchProps} from '../../hooks/order/useOrderList';
import useLazyOrderList from '../../hooks/order/useLazyOrderList';
import {OrderStatusRequestEnum} from '../../types/Order';

export type OrderListHeaderProps = {
  filterObject: UseOrderListFilterSearchProps;
  toggle: (tab: string) => void;
  defaultTab: string;
};

const OrderListHeader = ({
  filterObject,
  toggle,
  defaultTab,
}: OrderListHeaderProps) => {
  const intl = useIntl();

  const {searchParams} = filterObject;
  const toggleTab = (tab: string) => {
    toggle(tab);
  };

  const {meta: pendingMeta} = useLazyOrderList({
    searchParams,
    tabName: OrderStatusRequestEnum.Pending,
  });
  const {meta: inProgressMeta} = useLazyOrderList({
    searchParams,
    tabName: OrderStatusRequestEnum.InProgress,
  });
  const {meta: cancelledMeta} = useLazyOrderList({
    searchParams,
    tabName: OrderStatusRequestEnum.Cancelled,
  });
  const {meta: completedMeta} = useLazyOrderList({
    searchParams,
    tabName: OrderStatusRequestEnum.Completed,
  });

  const tabs: TabType[] = [
    {
      value: 'pending',
      label: intl.formatMessage(
        {
          id: 'order_list.tabs.pending',
        },
        {number: pendingMeta?.itemCount ? pendingMeta?.itemCount : 0}
      ),
    },
    {
      value: 'in_progress',
      label: intl.formatMessage(
        {
          id: 'order_list.tabs.in_progress',
        },
        {number: inProgressMeta?.itemCount ? inProgressMeta.itemCount : 0}
      ),
    },
    {
      value: 'cancelled',
      label: intl.formatMessage(
        {
          id: 'order_list.tabs.cancelled',
        },
        {number: cancelledMeta?.itemCount ? cancelledMeta.itemCount : 0}
      ),
    },
    {
      value: 'completed',
      label: intl.formatMessage(
        {
          id: 'order_list.tabs.completed',
        },
        {number: completedMeta?.itemCount ? completedMeta.itemCount : 0}
      ),
    },
  ];

  return (
    <>
      <div className="header-row">
        <div className="text-center text-lg-start">
          <Typography variant="h3">
            <FormattedMessage id="order_list.title" />
          </Typography>
        </div>
      </div>
      <Tabs
        tabs={tabs}
        className="mb-0"
        indicators={false}
        value={defaultTab}
        onChange={toggleTab}
      />
    </>
  );
};

export default OrderListHeader;
