import {Store} from './Store';

export enum FeeType {
  Card = 'card',
  Service = 'service',
}

export enum ServiceFeeType {
  buyer = 'Buyer fee',
  seller = 'Seller fee',
}

export interface Fee {
  id: number;
  store: Store;
  name: string;
  type: FeeType;
  buyerFeePercentage: number;
  buyerFeeFixed: number;
  sellerFeePercentage: number;
  sellerFeeFixed: number;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}
