import {TagDescription} from '@reduxjs/toolkit/dist/query';
import {FeedbackParams} from '../store/queries/common';
import {baseApi} from './baseApi';
import {GetStoreResponse} from './storeApi';
import {DeliveryZone} from '../types/DeliveryZone';
import {Address} from '../types/Address';
import {DeliveryZoneStatus} from '../utils/status';

export type UpsertDeliveryConfigTimeRequestParameter = {
  storeId?: number | string;
  body: {
    defaultCollectionTime: number;
  };
} & FeedbackParams;

export type DeleteDeliveryZoneParam = {
  id?: number | string;
} & FeedbackParams;

export type FetchDeliveryZonesRequestParameter = {
  storeId?: number | string;
} & FeedbackParams;

export type GetDeliveryZones = DeliveryZone[];

export type DeliveryZoneRequestParameter = {
  id?: string | number;
  body: {
    storeId?: number | string;
    address: Address;
    rangeMin: number;
    rangeMax: number;
    minimumOrderPrice: number;
    deliveryChargePrice: number;
    estimatedDeliveryTime: number;
    status?: DeliveryZoneStatus;
  };
} & FeedbackParams;

const onInvalidateStore = (
  result: GetStoreResponse | undefined
): TagDescription<'Store'>[] => (result ? ['Store'] : []);

const onInvalidateDeliveryZone = (
  result: GetDeliveryZones | undefined
): TagDescription<'DeliveryZone'>[] => (result ? ['DeliveryZone'] : []);

export const deliveryApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    upsertDeliveryConfigTime: builder.mutation<
      GetStoreResponse,
      UpsertDeliveryConfigTimeRequestParameter
    >({
      query: ({body, storeId}: UpsertDeliveryConfigTimeRequestParameter) => ({
        url: `/delivery/store/${storeId}/config`,
        method: 'POST',
        body,
      }),
      invalidatesTags: result => onInvalidateStore(result),
    }),
    fetchDeliveryZones: builder.query<
      DeliveryZone[],
      FetchDeliveryZonesRequestParameter
    >({
      query: ({storeId}: FetchDeliveryZonesRequestParameter) => ({
        url: `/delivery/zone/store/${storeId}`,
        method: 'GET',
      }),
      providesTags: ['DeliveryZone'],
    }),

    createDeliveryZone: builder.mutation<any, DeliveryZoneRequestParameter>({
      query: ({body}: DeliveryZoneRequestParameter) => ({
        url: '/delivery/zone',
        method: 'POST',
        body,
      }),
      invalidatesTags: result => onInvalidateDeliveryZone(result),
    }),
    updateDeliveryZone: builder.mutation<any, DeliveryZoneRequestParameter>({
      query: ({id, body}: DeliveryZoneRequestParameter) => ({
        url: `/delivery/zone/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: result => onInvalidateDeliveryZone(result),
    }),
    deleteDeliveryZone: builder.mutation<any, DeleteDeliveryZoneParam>({
      query: ({id}: DeleteDeliveryZoneParam) => ({
        url: `/delivery/zone/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => onInvalidateDeliveryZone(result),
    }),
  }),
});

export const {
  useUpsertDeliveryConfigTimeMutation,
  useFetchDeliveryZonesQuery,
  useCreateDeliveryZoneMutation,
  useUpdateDeliveryZoneMutation,
  useDeleteDeliveryZoneMutation,
} = deliveryApi;
