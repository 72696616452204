import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import clsx from 'clsx';
import {Typography} from '@material-ui/core';
import {useLocation, useParams} from 'react-router-dom';
import TabWidget from '../Widgets/TabWidget';
import {TabType} from '../Common/Tabs';
import Dropdown, {ItemType} from '../Common/Dropdown';
import StatusIndicator from '../Common/StatusIndicator';
import * as urls from '../../constants/urls';
import GeneralTab from './Details/GeneralTab';
import ProductCategoriesTab from './Details/ProductCategoriesTab';
import MenuTab from './Details/MenuTab';
import OptionsTab from './Details/OptionsTab';
import useStoreDetails from '../../hooks/useStoreDetails';
import {getColor} from '../../utils/functions';
import {COLOR_STATUS} from '../../utils/color';
import {Store} from '../../types/Store';
import SaveFeature from '../Shared/SaveFeature';
import {StoreType} from '../../types/StoreType';
import {SellerData} from '../../types/SellerData';
import {OptionProps} from '../Form/Inputs/AutocompleteMultiple';
import ConfirmationPopup from '../Shared/ConfirmationPopup';
import useStoreAction from '../../hooks/useStoreAction';
import {StoreStatus} from '../../utils/status';
import PermissionContainer from '../Common/Permission/PermissionContainer';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from '../../store/slices/authSlice';
import DiscountTab from './Details/DiscountTab';
import DeliveryTab from './Details/DeliveryTab';
import ReviewsTab from './Details/ReviewsTab';
import FeeTab from './Details/FeeTab';

/**
 * Store details
 */

const StoreDetails = () => {
  const intl = useIntl();
  const {pathname} = useLocation();
  const {storeId} = useParams();
  const {user} = useSelector(selectUserAndToken);

  //Current path
  const path = urls.STORE_DETAILS_PATH.replace(':storeId', storeId as string);

  // Active tab
  const [activeTab, setActiveTab] = useState(pathname);

  // switch active tab
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [showSave, setShowSave] = useState(false);
  const [linkedSellers, setLinkedSellers] = useState<SellerData[]>([]);
  const [linkedStoreTypes, setLinkedStoreTypes] = useState<OptionProps[]>([]);

  const {
    headerDetails,
    data,
    handleSubmit,
    submitStoreDetail,
    control,
    address,
    setEnteredValue,
    updateAddressValue,
    updateAddressError,
    addressError,
    isSuccess,
    count,
    clearFields,
    watch,
    resetImageField,
    setError,
    errors,
    clearErrors,
  } = useStoreDetails({id: storeId, linkedSellers, showSave});

  // delete store
  const {
    open: openDelete,
    openConfirmationPopup: openDeleteConfirmation,
    deleteStoreAction,
  } = useStoreAction({storeId, action: 'delete'});

  // suspend store
  const {
    open: openSuspend,
    openConfirmationPopup: openSuspendConfirmation,
    storeActionOperation: suspendStore,
  } = useStoreAction({storeId, action: 'suspend'});

  // unsuspend store
  const {
    open: openUnsuspend,
    openConfirmationPopup: openUnsuspendConfirmation,
    storeActionOperation: unsuspendStore,
  } = useStoreAction({storeId, action: 'unsuspend'});

  // close & hold store
  const {
    open: close,
    openConfirmationPopup: closeConfirmation,
    storeActionOperation: closeStore,
  } = useStoreAction({storeId, action: 'onHold'});

  // close store for today
  const {
    open: closeForToday,
    openConfirmationPopup: closeForTodayConfirmation,
    storeActionOperation: closeStoreForToday,
  } = useStoreAction({storeId, action: 'closeForToday'});

  // open store
  const {
    open,
    openConfirmationPopup,
    storeActionOperation: openStore,
  } = useStoreAction({storeId, action: 'open'});

  const setDefaultTypes = (types: StoreType[]) => {
    setLinkedStoreTypes(
      types.map(item => ({
        title: item.name,
        value: item.id,
      }))
    );
  };

  useEffect(() => {
    if (data) {
      setLinkedSellers(data.sellers);
      setDefaultTypes(data.storeTypes);
    }
  }, [data]);

  // show save when a linked seller is removed
  useEffect(() => {
    if (data) {
      if (data.sellers.length !== linkedSellers.length) {
        setShowSave(true);
      } else {
        setShowSave(false);
      }
    }
  }, [data, linkedSellers]);

  // List tabs
  const tabs: TabType[] = [
    {
      label: intl.formatMessage({
        id: 'dashboard.store_details.tabs.general',
      }),
      value: `${path}/general`,
      children: (
        <GeneralTab
          id={storeId}
          control={control}
          handleSubmit={handleSubmit}
          updateStoreDetail={submitStoreDetail}
          address={address}
          addressError={addressError}
          updateAddressError={updateAddressError}
          updateAddressValue={updateAddressValue}
          isSuccess={isSuccess}
          setEnteredValue={setEnteredValue}
          storeData={data as Store}
          form="update-store-form"
          watch={watch}
          linkedSellers={linkedSellers}
          setLinkedSellers={setLinkedSellers}
          defaultStoreTypes={linkedStoreTypes}
          setLinkedStoreTypes={setLinkedStoreTypes}
          resetImageField={resetImageField}
          setError={setError}
          clearError={clearErrors}
          errors={errors}
        />
      ),
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.store_details.tabs.product_categories',
      }),
      value: `${path}/categories`,
      children: <ProductCategoriesTab id={storeId} />,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.store_details.tabs.product_options',
      }),
      value: `${path}/options`,
      children: <OptionsTab />,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.store_details.tabs.menu',
      }),
      value: `${path}/menu`,
      children: <MenuTab />,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.store_details.tabs.delivery',
      }),
      value: `${path}/delivery`,
      children: <DeliveryTab />,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.store_details.tabs.discount',
      }),
      value: `${path}/discount`,
      children: <DiscountTab />,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.store_details.tabs.reviews',
      }),
      value: `${path}/reviews`,
      children: <ReviewsTab />,
    },
    ...(user?.permissions?.some(item => item.slug === 'list-fee')
      ? [
          {
            label: intl.formatMessage({id: 'dashboard.store_details.tabs.fee'}),
            value: `${path}/fee`,
            children: (
              <PermissionContainer permissions={['list-fee']}>
                <FeeTab storeData={data as Store} />
              </PermissionContainer>
            ),
          },
        ]
      : []),
  ];

  // labels
  const closeStoreLabel = intl.formatMessage({
    id: 'dashboard.store_details.actions.close_store_and_hold',
  });
  const closeStoreForTodayLabel = intl.formatMessage({
    id: 'dashboard.store_details.actions.close_store_today',
  });
  const openStoreLabel = intl.formatMessage({
    id: 'dashboard.store_details.actions.open_store',
  });

  const unsuspendLabel = intl.formatMessage({
    id: 'dashboard.store_details.actions.unsuspend_store',
  });

  const suspendLabel = intl.formatMessage({
    id: 'dashboard.store_details.actions.suspend_store',
  });

  const deleteLabel = intl.formatMessage({
    id: 'dashboard.store_details.actions.delete_store',
  });

  // confirmation modal popup action
  const onSuspendClick = () => openSuspendConfirmation('suspend', true);
  const onUnsuspendClick = () => openUnsuspendConfirmation('unsuspend', true);
  const onCloseStoreClick = () => closeConfirmation('onHold', true);
  const onCloseStoreForTodayClick = () =>
    closeForTodayConfirmation('closeForToday', true);
  const onOpenStoreClick = () => openConfirmationPopup('open', true);
  const onDeleteClick = () => openDeleteConfirmation('delete', true);

  let storeActions: ItemType[] = [];
  const isAdmin = user?.profileType === 'admin';
  const adminStoreActions: ItemType[] = [
    {
      label: suspendLabel,
      onClick: onSuspendClick,
    },
    {
      label: deleteLabel,
      onClick: onDeleteClick,
    },
  ];

  if (data) {
    const {status} = data;

    switch (status) {
      case StoreStatus.Suspended:
        storeActions = [
          {
            label: unsuspendLabel,
            onClick: onUnsuspendClick,
          },
          {
            label: deleteLabel,
            onClick: onDeleteClick,
          },
        ];
        break;
      case StoreStatus.Open:
      case StoreStatus.Closed:
        storeActions = [
          {
            label: closeStoreLabel,
            onClick: onCloseStoreClick,
          },
          {
            label: closeStoreForTodayLabel,
            onClick: onCloseStoreForTodayClick,
          },
        ];
        isAdmin && storeActions.push(...adminStoreActions);
        break;
      case StoreStatus.ClosedForToday:
        storeActions = [
          {
            label: openStoreLabel,
            onClick: onOpenStoreClick,
          },
          {
            label: closeStoreLabel,
            onClick: onCloseStoreClick,
          },
        ];
        isAdmin && storeActions.push(...adminStoreActions);
        break;
      case StoreStatus.PreOrderAvailable:
        storeActions = [
          {
            label: closeStoreForTodayLabel,
            onClick: onCloseStoreForTodayClick,
          },
          {
            label: closeStoreLabel,
            onClick: onCloseStoreClick,
          },
        ];
        isAdmin && storeActions.push(...adminStoreActions);
        break;
      case StoreStatus.OnHold:
        storeActions = [
          {
            label: openStoreLabel,
            onClick: onOpenStoreClick,
          },
        ];

        isAdmin && storeActions.push(...adminStoreActions);
        break;

      default:
        break;
    }
  }

  // Header content
  const header = (
    <div className="container-fluid top-block bg-white border-0">
      <div className="details-header">
        <div className="row align-items-center title-block pb-lg-vtl-19">
          <div className="col-lg-6">
            <div className="details-header-part d-flex flex-column flex-lg-row align-items-center align-items-lg-start justify-content-center justify-content-lg-start">
              <div className="details-header-part text-center text-lg-start">
                <div className="d-flex flex-column align-items-center align-items-lg-start">
                  <Typography variant="h3" className="font-weight-bolder">
                    {data && data.name}
                  </Typography>
                  <StatusIndicator
                    label={headerDetails.status}
                    color={
                      data
                        ? getColor(data.status as keyof typeof COLOR_STATUS)
                        : 'green'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex justify-content-center justify-content-lg-end pt-vtl-15 pt-lg-0">
              {storeActions && (
                <Dropdown
                  title={intl.formatMessage({id: 'actions.actions'})}
                  items={storeActions}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // cancel save
  const cancelSave = () => {
    clearFields();
    if (data) {
      setLinkedSellers(data.sellers);
      setDefaultTypes(data.storeTypes);
    }
  };

  const numCounter = count + (showSave ? 1 : 0);

  return (
    <>
      <PermissionContainer permissions={['update-store']}>
        {data && numCounter > 0 && (
          <SaveFeature
            onClose={cancelSave}
            open
            keyType="store"
            num={numCounter}
            type="submit"
            form="update-store-form"
          />
        )}
      </PermissionContainer>
      <ConfirmationPopup
        opened={openDelete.delete}
        handleClose={() => openDeleteConfirmation('delete', false)}
        onOk={deleteStoreAction}
        description={intl.formatMessage({
          id: 'dashboard.store_details.actions.messages.delete_store_message',
        })}
      />
      <ConfirmationPopup
        opened={openSuspend.suspend}
        handleClose={() => openSuspendConfirmation('suspend', false)}
        onOk={suspendStore}
        description={intl.formatMessage({
          id: 'dashboard.store_details.actions.messages.suspend_store_message',
        })}
      />
      <ConfirmationPopup
        opened={openUnsuspend.unsuspend}
        handleClose={() => openUnsuspendConfirmation('unsuspend', false)}
        onOk={unsuspendStore}
        description={intl.formatMessage({
          id: 'dashboard.store_details.actions.messages.unsuspend_store_message',
        })}
      />
      <ConfirmationPopup
        opened={open.open}
        handleClose={() => openConfirmationPopup('open', false)}
        onOk={openStore}
        description={intl.formatMessage({
          id: 'dashboard.store_details.actions.messages.open_store_message',
        })}
      />
      <ConfirmationPopup
        opened={close.onHold}
        handleClose={() => closeConfirmation('onHold', false)}
        onOk={closeStore}
        description={intl.formatMessage({
          id: 'dashboard.store_details.actions.messages.close_store_and_hold_message',
        })}
      />
      <ConfirmationPopup
        opened={closeForToday.closeForToday}
        handleClose={() => closeForTodayConfirmation('closeForToday', false)}
        onOk={closeStoreForToday}
        description={intl.formatMessage({
          id: 'dashboard.store_details.actions.messages.close_store_for_today_message',
        })}
      />
      <div
        className={clsx('details-page', {
          'pb-0': activeTab.includes('/delivery'),
        })}
      >
        {header}
        <TabWidget
          containerClass="container-fluid top-block bg-white"
          wrapperClass="details-header pt-0"
          tabs={tabs}
          value={activeTab}
          onChange={toggle}
        />
      </div>
    </>
  );
};

export default StoreDetails;
