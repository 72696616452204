import {Theme, makeStyles} from '@material-ui/core/styles';
import Radio, {RadioProps} from '@material-ui/core/Radio';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  checked: {},
  icon: {
    borderRadius: theme.spacing(1.5),
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.dark.main,
    backgroundColor: theme.palette.background.paper,
  },
  checkedIcon: {
    '&:before': {
      display: 'block',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='14' height='14' rx='7' fill='white' stroke='%23FF0000'/%3E%3Crect x='3' y='3' width='9' height='9' rx='4.5' fill='%23FF0000'/%3E%3C/svg%3E%0A\")",
      content: '""',
    },
  },
}));

/** Custom radio */
const CustomRadio = (props: RadioProps) => {
  const classes = useStyles();
  return (
    <Radio
      classes={{root: classes.root, checked: classes.checked}}
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={classes.checkedIcon} />}
      {...props}
    />
  );
};

export default CustomRadio;
