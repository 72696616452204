import {useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {
  CreateStoreTypeParam,
  DeleteParam,
  useCreateStoreTypeMutation,
  useDeleteStoreTypeMutation,
  useFetchStoreTypesQuery,
  useUpdateStoreTypeMutation,
} from '../services/storeApi';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {formatErrorMessage} from '../utils/functions';

export type StoreTypeFormValues = {
  name: string;
};

export default function useStoreType() {
  // Translations
  const intl = useIntl();

  const {data: storeTypeList, isSuccess} = useFetchStoreTypesQuery(
    {showProgressDialog: true},
    {refetchOnMountOrArgChange: true}
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: {errors, touchedFields},
  } = useForm<StoreTypeFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const [create, {isSuccess: createSuccess}] = useCreateStoreTypeMutation();
  const [update] = useUpdateStoreTypeMutation();
  const [deleteType] = useDeleteStoreTypeMutation();

  // create store type
  const createStoreType = (formValue: StoreTypeFormValues) => {
    const data: CreateStoreTypeParam = {
      body: {
        ...formValue,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'messages.store_type_created',
        });
        return message;
      },
    };
    create(data);
  };

  // reset input field
  useEffect(() => {
    if (createSuccess) {
      reset();
    }
  }, [createSuccess]);

  // update a store type
  const editStoreType = (formValue: string, id: number) => {
    const data: CreateStoreTypeParam = {
      id,
      body: {
        name: formValue,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'messages.store_type_updated',
        });
        return message;
      },
    };
    update(data);
  };

  // delete a store type
  const deleteStoreType = (id: number) => {
    const data: DeleteParam = {
      id,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'messages.store_type_deleted',
        });
        return message;
      },
    };
    deleteType(data);
  };

  return {
    storeTypeList,
    isSuccess,
    createStoreType,
    editStoreType,
    deleteStoreType,
    control,
    handleSubmit,
    errors,
    touchedFields,
  };
}
