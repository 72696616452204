import {yupResolver} from '@hookform/resolvers/yup';
import {useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import * as yup from 'yup';

export type ThemeFormValues = {
  [key: string]: any;
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
};

const useThemeFormRequest = () => {
  const intl = useIntl();

  const schema = useMemo(
    () =>
      yup.object().shape({
        primaryColor: yup
          .string()
          .trim()
          .matches(
            /^#([0-9A-F]{3}){1,2}$/i,
            intl.formatMessage({id: 'validation.error.invalid_color'})
          )
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        secondaryColor: yup
          .string()
          .trim()
          .matches(
            /^#([0-9A-F]{3}){1,2}$/i,
            intl.formatMessage({id: 'validation.error.invalid_color'})
          )
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        textColor: yup
          .string()
          .trim()
          .matches(
            /^#([0-9A-F]{3}){1,2}$/i,
            intl.formatMessage({id: 'validation.error.invalid_color'})
          )
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    formState,
  } = useForm<ThemeFormValues>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  return {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    formState,
  };
};

export default useThemeFormRequest;
