import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import SearchTextField, {
  SearchTextFieldProps,
} from '../../Common/SearchTextField';

export type SearchTextControllerProps<T extends FieldValues> = Omit<
  SearchTextFieldProps,
  'name' | 'control'
> & {
  name: Path<T>;
  control: Control<T>;
};

const SearchTextController = <T extends FieldValues>({
  name,
  control,
  ...searchTextFieldProps
}: SearchTextControllerProps<T>) => {
  return (
    <Controller
      name={name}
      //@ts-ignore
      defaultValue={''}
      control={control}
      render={({field}) => {
        return (
          <SearchTextField {...searchTextFieldProps} {...field} ref={null} />
        );
      }}
    />
  );
};

export default SearchTextController;
