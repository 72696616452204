import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {AnyObject} from 'yup/lib/types';

export type FeeForm = {
  cardFeePercentage: number;
  cardFeeFixed: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postCode: string;
  country: string;
  vatNumber: string;
  companyName: string;
};

export default function useFeeFormRequest() {
  const intl = useIntl();

  const validation = (
    value: string | undefined,
    validationContext: AnyObject
  ) => {
    const {
      createError,
      parent: {
        companyName,
        addressLine1,
        addressLine2,
        city,
        postCode,
        country,
        vatNumber,
      },
    } = validationContext;
    if (
      companyName ||
      addressLine1 ||
      addressLine2 ||
      city ||
      postCode ||
      country ||
      vatNumber
    ) {
      if (!value) {
        return createError({
          message: intl.formatMessage({
            id: 'validation.error.required',
          }),
        });
      }
    }
    return true;
  };
  // schema valiation
  const schema = useMemo(
    () =>
      yup.object().shape(
        {
          cardFeeFixed: yup
            .number()
            .typeError(intl.formatMessage({id: 'validation.error.required'}))
            .optional()
            .when(['cardFeePercentage'], {
              is: (cardFeePercentage: number | string) => !!cardFeePercentage,
              then: yup
                .number()
                .typeError(
                  intl.formatMessage({id: 'validation.error.required'})
                )
                .required(
                  intl.formatMessage({id: 'validation.error.required'})
                ),
            }),
          cardFeePercentage: yup
            .number()
            .typeError(intl.formatMessage({id: 'validation.error.required'}))
            .optional()
            .when(['cardFeeFixed'], {
              is: (cardFeeFixed: number | string) => !!cardFeeFixed,
              then: yup
                .number()
                .typeError(
                  intl.formatMessage({id: 'validation.error.required'})
                )
                .required(intl.formatMessage({id: 'validation.error.required'}))
                .max(
                  100,
                  intl.formatMessage({id: 'validation.error.max_percentage'})
                ),
            }),
          companyName: yup
            .string()
            .trim()
            .optional()
            .test('companyName-test', validation),
          addressLine1: yup
            .string()
            .trim()
            .test('addressLine1-test', validation),
          addressLine2: yup.string().trim().optional(),
          city: yup.string().trim().optional().test('city-test', validation),
          postCode: yup
            .string()
            .trim()
            .optional()
            .test('postCode-test', validation),
          country: yup
            .string()
            .trim()
            .optional()
            .test('country-test', validation),
          vatNumber: yup
            .string()
            .trim()
            .optional()
            .test('vatNumber-test', validation),
        },
        [['cardFeePercentage', 'cardFeeFixed']]
      ),
    [intl]
  );

  // form hook initialization
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    trigger,
    clearErrors,
    formState,
    reset,
    setError,
    resetField,
    register,
    unregister,
  } = useForm<FeeForm>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  return {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    trigger,
    clearErrors,
    formState,
    reset,
    setError,
    resetField,
    register,
    unregister,
  };
}
