import {FeedbackParams} from '../store/queries/common';
import {StoreSchedule} from '../types/StoreSchedule';
import {StoreScheduleItem} from '../types/StoreScheduleItem';
import {DeleteParam, GetStoreResponse} from './storeApi';
import {baseApi} from './baseApi';

export type UpdateScheduleStatusParam = {
  id?: number | string;
  body: {
    status: string;
  };
} & FeedbackParams;

export type FetchStoreScheduleParams = {
  storeId?: number | string;
} & FeedbackParams;

export type ScheduleItemsUpdateList = {
  items: StoreScheduleItem[];
};
export type ScheduleItemParams = {
  id?: number;
  body: ScheduleItemsUpdateList;
} & FeedbackParams;

const transformedData = (originalData: StoreSchedule[]) => {
  return originalData.map(item => ({
    ...item,
    items: item.items.map(subItem => ({
      ...subItem,
      products: subItem.products
        ? subItem.products.map(product =>
            typeof product === 'number' ? product : product.id
          )
        : [],
      categories: subItem.categories
        ? subItem.categories.map(category =>
            typeof category === 'number' ? category : category.id
          )
        : [],
    })),
  }));
};

export const scheduleApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchSchedules: builder.query<
      StoreSchedule[],
      FetchStoreScheduleParams | void
    >({
      query: ({storeId}: FetchStoreScheduleParams) => ({
        url: `/store-schedule/store/${storeId}`,
        method: 'GET',
      }),
      providesTags: ['StoreSchedule'],
      transformResponse: (response: StoreSchedule[]) =>
        transformedData(response),
    }),

    updateScheduleStatus: builder.mutation<
      GetStoreResponse,
      UpdateScheduleStatusParam
    >({
      query: ({id, body}: UpdateScheduleStatusParam) => ({
        url: `/store-schedule/${id}/status`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['StoreSchedule'],
    }),

    updateScheduleItems: builder.mutation<
      StoreScheduleItem[],
      ScheduleItemParams
    >({
      query: ({body, id}: ScheduleItemParams) => ({
        url: `/store-schedule/${id}/items`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['StoreSchedule'],
    }),

    deleteScheduleItem: builder.mutation<string, DeleteParam>({
      query: ({id}: DeleteParam) => ({
        url: `/store-schedule/item/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['StoreSchedule'],
    }),
  }),
});

export const {
  useFetchSchedulesQuery,
  useUpdateScheduleStatusMutation,
  useUpdateScheduleItemsMutation,
  useDeleteScheduleItemMutation,
} = scheduleApi;
