import {useIntl} from 'react-intl';
import useDataTableState from './table/useDataTableState';
import {formatErrorMessage, getUrlSearchParams} from '../utils/functions';
import {useEffect, useState} from 'react';
import {useListReviewQuery} from '../services/reviewApi';
import {useSearchParams} from 'react-router-dom';

export type UseReviewsListFilterSearchProps = {
  storeId: number;
};

export default function useReviewsList({
  storeId,
}: UseReviewsListFilterSearchProps) {
  const intl = useIntl();

  const [searchParams] = useSearchParams();
  // filter, sort and search values state
  const tableState = useDataTableState({
    defaultValues: {
      orderDirection: 'DESC',
      orderBy: 'createdAt',
    },
    bindQueryParams: true,
  });

  const [ready, setReady] = useState(false);
  const [q, setQ] = useState('');

  useEffect(() => {
    const id = setTimeout(() => {
      const search = getUrlSearchParams(searchParams);
      const nextQ = search.get('q') ?? '';
      if (q !== nextQ) {
        setQ(nextQ);
      }
      setReady(true);
    }, 500);

    return () => {
      clearTimeout(id);
    };
  }, [searchParams]);

  const {isSuccess, data, isFetching} = useListReviewQuery(
    {
      query: {
        page: tableState.page,
        itemsPerPage: tableState.pageSize,
        sortOrder: tableState.orderDirection,
        sortBy: tableState.orderBy,
        search: q,
        storeId,
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
      showProgressDialog: true,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !ready,
    }
  );

  const handleRowClick = () => {};

  return {
    isSuccess,
    data: data?.data,
    meta: data?.meta,
    tableState,
    handleRowClick,
    isLoading: isFetching,
  };
}
