import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {Form} from 'reactstrap';
import InputController from '../../Form/HookForm/InputController';
import RadioGroupController from '../../Form/HookForm/RadioGroupController';
import useCreateAppBuild from '../../../hooks/useCreateAppBuild';
import {BuildEnvEnum, PlatformEnum} from '../../../types/Build';
import {useDispatch} from 'react-redux';
import {appApi} from '../../../services/appApi';

type CreateAppBuildFormProps = {
  form: string;
  appId: number;
  closePopup: () => void;
};

const CreateAppBuildForm = ({
  form,
  appId,
  closePopup,
}: CreateAppBuildFormProps) => {
  // Translations
  const intl = useIntl();
  const dispatch = useDispatch();

  const {handleSubmit, onSubmit, control, isSuccess, reset} = useCreateAppBuild(
    {appId}
  );

  // close popup
  useEffect(() => {
    if (isSuccess) {
      reset();
      closePopup();

      // Invalidate the cache so that the new build is fetched
      dispatch(appApi.util.invalidateTags(['Build']));
    }
  }, [isSuccess]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id={form}>
      <RadioGroupController
        name="type"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.app_details.general_tab.type',
        })}
        options={[
          {
            label: intl.formatMessage({
              id: `dashboard.app_details.general_tab.${BuildEnvEnum.Testing}`,
            }),
            value: BuildEnvEnum.Testing,
          },
          {
            label: intl.formatMessage({
              id: `dashboard.app_details.general_tab.${BuildEnvEnum.Production}`,
            }),
            value: BuildEnvEnum.Production,
          },
        ]}
        isRow={false}
        formGroupClass="mb-3"
        required
      />
      <RadioGroupController
        name="platform"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.app_details.general_tab.platform',
        })}
        options={[
          {
            label: intl.formatMessage({
              id: `dashboard.app_details.general_tab.${PlatformEnum.Ios}`,
            }),
            value: PlatformEnum.Ios,
          },
          {
            label: intl.formatMessage({
              id: `dashboard.app_details.general_tab.${PlatformEnum.Android}`,
            }),
            value: PlatformEnum.Android,
          },
        ]}
        isRow={false}
        formGroupClass="mb-3"
        required
      />
      <InputController
        name="appVersion"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.app_details.general_tab.appVersion',
        })}
        required
      />
      <InputController
        name="versionCode"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.app_details.general_tab.versionCode',
        })}
        type="number"
        required
      />
    </Form>
  );
};

export default CreateAppBuildForm;
