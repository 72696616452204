import React from 'react';
import {Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import Modal from '../Widgets/Modal';

export type ConfirmationPopupProps = {
  opened: boolean;
  handleClose: () => void;
  onOk?: () => void;
  description?: string;
  title?: string;
};

const ConfirmationPopup = ({
  opened,
  handleClose,
  onOk,
  description,
  title,
}: ConfirmationPopupProps) => {
  const intl = useIntl();
  const content = description || intl.formatMessage({id: 'confirmation.text'});
  const titleContent = title || intl.formatMessage({id: 'confirmation.title'});
  return (
    <Modal
      opened={opened}
      handleClose={handleClose}
      title={titleContent}
      noSaveBtn={false}
      noMaxHeight
      cancelTitle={intl.formatMessage({id: 'actions.no'})}
      saveTitle={intl.formatMessage({id: 'actions.yes'})}
      onOk={onOk}
    >
      <Typography>{content}</Typography>
    </Modal>
  );
};

export default ConfirmationPopup;
