import {FormattedMessage, useIntl} from 'react-intl';
import {DevTool} from '@hookform/devtools';
import {Row, Col, Button, Container} from 'reactstrap';
import {Typography} from '@material-ui/core';
import ArrowLink from '../../Common/ArrowLink';
import Modal from '../../Widgets/Modal';
import ChangePasswordForm from '../Form/ChangePasswordForm';
import InputController from '../../Form/HookForm/InputController';
import PhoneController from '../../Form/HookForm/PhoneController';
import {ProfileDetailsFormValues} from '../../../hooks/useBuyer';
import {formatDate} from '../../../utils/functions';
import {useState} from 'react';
import {GetUserResponse} from '../../../services/userApi';
import {Control, UseFormHandleSubmit} from 'react-hook-form';

type ProfileDetailsTabProps = {
  id?: number | string;
  form: string;
  userData: GetUserResponse | undefined;
  control: Control<ProfileDetailsFormValues>;
  handleSubmit: UseFormHandleSubmit<ProfileDetailsFormValues>;
  updateBuyerAccount: (formValues: ProfileDetailsFormValues) => void;
};

/**
 * Buyer details - Profile tab
 */
const ProfileDetailsTab = (props: ProfileDetailsTabProps) => {
  const {userData, control, handleSubmit, updateBuyerAccount, form, id} = props;
  // Change password form
  const [changePassword, setChangePassword] = useState(false);
  const openChangePasswordModal = () => setChangePassword(true);
  const closeChangePasswordModal = () => setChangePassword(false);

  const intl = useIntl();

  return (
    <>
      {/* Change password */}
      <Modal
        opened={changePassword}
        handleClose={closeChangePasswordModal}
        title={intl.formatMessage({
          id: 'dashboard.admin.users.details.change_password',
        })}
        width={238}
        noSaveBtn={false}
        showCloseTop={false}
        cancelTitle={intl.formatMessage({id: 'actions.no'})}
        saveTitle={intl.formatMessage({id: 'actions.yes'})}
        form="buyer-password-form"
        type="submit"
      >
        <ChangePasswordForm
          id={id}
          form="buyer-password-form"
          closePasswordModal={closeChangePasswordModal}
        />
      </Modal>

      <Container>
        <div className="content-block">
          <Row className="g-3">
            <Col lg={4}>
              <div className="card details-card">
                <div className="card-body">
                  <form id={form} onSubmit={handleSubmit(updateBuyerAccount)}>
                    <InputController
                      name="firstName"
                      control={control}
                      label={intl.formatMessage({
                        id: 'dashboard.admin.users.details.first_name',
                      })}
                    />
                    <InputController
                      name="lastName"
                      control={control}
                      label={intl.formatMessage({
                        id: 'dashboard.admin.users.details.last_name',
                      })}
                    />
                    <InputController
                      name="email"
                      control={control}
                      label={intl.formatMessage({
                        id: 'dashboard.admin.users.details.email_address',
                      })}
                    />
                    <div className="form-group mb-3 mb-lg-fg">
                      <Typography
                        variant="subtitle2"
                        className="text-muted mb-1"
                      >
                        <FormattedMessage id="dashboard.admin.users.details.password" />
                      </Typography>
                      <Button
                        tag="a"
                        color="link"
                        className="text-danger p-0"
                        onClick={openChangePasswordModal}
                      >
                        <Typography
                          variant="subtitle2"
                          className="font-weight-bold"
                        >
                          <FormattedMessage id="dashboard.admin.users.details.change_password" />
                        </Typography>
                      </Button>
                    </div>
                    {/* Phone */}
                    <PhoneController
                      name="phone"
                      control={control}
                      label={intl.formatMessage({
                        id: 'dashboard.admin.users.details.phone',
                      })}
                      required
                      phoneInputProps={{international: true}}
                    />
                    <div className="form-group mb-3 mb-lg-fg">
                      <Typography
                        variant="subtitle2"
                        className="text-muted mb-1"
                      >
                        <FormattedMessage id="dashboard.admin.users.details.created_at" />
                      </Typography>
                      <Typography>
                        {userData && formatDate(userData?.user.createdAt)}
                      </Typography>
                    </div>
                    <div className="form-group mb-0">
                      <Typography
                        variant="subtitle2"
                        className="text-muted mb-1"
                      >
                        <FormattedMessage id="dashboard.admin.users.details.last_login" />
                      </Typography>
                      {userData && !userData.user.lastLogin ? (
                        <Typography>-</Typography>
                      ) : (
                        <Typography>
                          {userData && formatDate(userData?.user.lastLogin)}
                        </Typography>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="card details-card">
                <div className="card-body">
                  <Typography variant="subtitle2" className="text-muted mb-1">
                    <FormattedMessage id="dashboard.admin.users.details.total_orders" />
                  </Typography>
                  <div className="bg-light rounded px-vtl-15 py-2 static-field">
                    <ArrowLink
                      className="justify-content-between"
                      title={userData?.totalOrders?.toString() ?? '0'}
                      isExternalLink
                      url={`/admin/orders?filters={"buyer":"${userData?.user.firstName}+${userData?.user.lastName}","store":""}&page=1`}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <DevTool control={control} />
      </Container>
    </>
  );
};

export default ProfileDetailsTab;
