import {FormHelperText} from '@material-ui/core';
import moment from 'moment';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {DateFilter} from '../../../utils/date-filter';
import CustomSelect from '../../Common/Select';
import CustomTextField from '../Inputs/TextField';
import DatePicker from '../Inputs/DatePicker';

export type DateSelectControllerProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  dateFormat?: string;
  displayFormat?: string;
};

export type DateSelectControllerValue = {
  type?: DateFilter;
  from?: string;
  to?: string;
  period?: number | string;
  periodType?: 'days' | 'weeks' | 'months';
};

const DateSelectController = <T extends FieldValues>({
  name,
  control,
  dateFormat = 'YYYY-MM-DD',
  displayFormat,
}: DateSelectControllerProps<T>) => {
  const intl = useIntl();

  // Format date from iFormat to fFormat
  const formatDate = (
    date: string,
    iFormat?: string,
    fFormat?: string,
    defaultValue = null
  ) => {
    const m = moment(date, iFormat);

    return m.isValid() ? m.format(fFormat) : defaultValue;
  };

  const items = [
    {
      label: intl.formatMessage({
        id: 'dashboard.filters.is_between',
      }),
      value: DateFilter.isBetween,
    },
    {
      label: intl.formatMessage({id: 'dashboard.filters.is_greater'}),
      value: DateFilter.greaterThan,
    },
    {
      label: intl.formatMessage({id: 'dashboard.filters.is_less'}),
      value: DateFilter.lessThan,
    },
    {
      label: intl.formatMessage({id: 'dashboard.filters.is_equal'}),
      value: DateFilter.isEqual,
    },
  ];

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {value, onChange}, fieldState: {error}}) => {
        let valueFrom: string | null = null;
        let valueTo: string | null = null;
        let type: string | null | undefined = null;
        let period: string | null | undefined = null;
        let periodType: string | null | undefined = null;

        if (typeof value === 'object') {
          const {from, to, type: t, period: p, periodType: pt} = value;
          type = t;
          period = p;
          periodType = pt;
          valueFrom = formatDate(from, dateFormat, displayFormat);
          valueTo = formatDate(to, dateFormat, displayFormat);
        }

        const handleDateChange = (val: string, inputKey: string) => {
          const {type: t, from, to} = value;
          onChange({
            type: t,
            from,
            to,
            [inputKey]: formatDate(val, displayFormat, dateFormat),
          });
        };

        const handlePeriodTypeChange = (newPeriodType: string) => {
          const {type: t, period: p} = value;
          onChange({
            type: t,
            period: p,
            periodType: newPeriodType,
          });
        };

        const handlePeriodChange = (ev: any) => {
          const {type: t, periodType: pt} = value;
          const v = ev.target.value;
          onChange({
            type: t,
            periodType: pt,
            period: !Number.isNaN(+v) ? +v : v,
          });
        };

        return (
          <div className="date-selects-wrapper d-flex flex-column">
            <CustomSelect
              outlined
              variant="default"
              placeholder={intl.formatMessage({
                id: 'dashboard.filters.date_range',
              })}
              items={items}
              value={type ?? ''}
              onChange={newType => onChange({type: newType})}
            />
            {type && (
              <>
                {/* In between variant' inputs */}
                <div className="in-between-inputs mt-2">
                  {/* Date From */}
                  <DatePicker
                    value={valueFrom ?? new Date()}
                    format={displayFormat}
                    placeholder={intl.formatMessage({id: 'actions.from'})}
                    onChange={(value: string) =>
                      handleDateChange(value, 'from')
                    }
                    {...(valueTo ? {maxDate: valueTo} : {})}
                  />
                  {/* Date To */}
                  {type === DateFilter.isBetween && (
                    <DatePicker
                      value={valueTo ?? new Date()}
                      format={displayFormat}
                      placeholder={intl.formatMessage({id: 'actions.to'})}
                      onChange={(value: string) =>
                        handleDateChange(value, 'to')
                      }
                      {...(valueFrom ? {minDate: valueFrom} : {})}
                    />
                  )}
                </div>
              </>
            )}
            {type === 'last' && (
              <>
                {/* in the last variant' inputs */}
                <div className="last-days-inputs d-flex align-items-center mt-2">
                  <CustomTextField
                    className="days-input"
                    value={period ?? ''}
                    onChange={handlePeriodChange}
                  />
                  <div className="flex-grow-1">
                    <CustomSelect
                      outlined
                      variant="default"
                      placeholder={intl.formatMessage({
                        id: 'dashboard.filters.days',
                      })}
                      items={[
                        {
                          label: intl.formatMessage({
                            id: 'dashboard.filters.days',
                          }),
                          value: 'days',
                        },
                        {
                          label: intl.formatMessage({
                            id: 'dashboard.filters.weeks',
                          }),
                          value: 'weeks',
                        },
                        {
                          label: intl.formatMessage({
                            id: 'dashboard.filters.months',
                          }),
                          value: 'months',
                        },
                      ]}
                      value={periodType ?? ''}
                      onChange={handlePeriodTypeChange}
                    />
                  </div>
                </div>
              </>
            )}
            {error && <FormHelperText>{String(error.message)}</FormHelperText>}
          </div>
        );
      }}
    />
  );
};

export default DateSelectController;
