import {useIntl} from 'react-intl';
import {Col, Container, Row} from 'react-bootstrap';
import {Stack} from 'react-bootstrap';
import KeywordSearch from '../../Form/KeywordSearch';
// import DynamicFilter from '../../Form/DynamicFilter';
import DataTable from '../../Widgets/DataTable';
import Button from '../../Common/Button';
import useDiscountList from '../../../hooks/useDiscountList';
import useDiscountFilter from '../../../hooks/discount/useDiscountFilter';
import DynamicFilter from '../../Form/DynamicFilter';
import {Discount} from '../../../types/Discount';
import {
  capitalizeFirstLetter,
  formatDateInMonth,
} from '../../../utils/functions';
import StatusIndicator from '../../Common/StatusIndicator';
import {HeadCellType} from '../../Widgets/DataTable/DataTableHead';

const DiscountTab = () => {
  const intl = useIntl();

  const {isLoading, data, handleRowClick, meta, tableState, applyFilter} =
    useDiscountList();

  // status indicator
  const statusColor: any = {
    active: 'green',
    expired: 'secondary',
    inactive: 'error',
    upcoming: 'orange',
  };

  // discount type
  const discountType = {
    orderAmount: 'Order amount',
    loyaltyOffer: 'Loyalty offer',
  };

  // show discount name on table
  const renderDiscountName = (val: any, cn: any, rowData: any) => {
    const {row}: {row: Discount} = rowData;

    return (
      <div className="py-vtl-11">
        <Stack gap={1}>
          <p>{row.name}</p>
        </Stack>
      </div>
    );
  };

  // show discount start date on table
  const renderDiscountStartDate = (a: any, b: any, rowData: any) => {
    const {row}: {row: Discount} = rowData;

    return (
      <div className="py-vtl-11">
        <Stack gap={1}>
          <p>{formatDateInMonth(row.validity.validityStartDate)}</p>
        </Stack>
      </div>
    );
  };

  // show discount end date on table
  const renderDiscountEndDate = (a: any, b: any, rowData: any) => {
    const {row}: {row: Discount} = rowData;

    return (
      <div className="py-vtl-11">
        <Stack gap={1}>
          <p>
            {row.validity?.validityEndDate
              ? formatDateInMonth(row.validity.validityEndDate)
              : '-'}
          </p>
        </Stack>
      </div>
    );
  };

  // show discount code on table
  const renderDiscountCode = (a: any, b: any, rowData: any) => {
    const {row}: {row: Discount} = rowData;

    return (
      <div className="py-vtl-11">
        <Stack gap={1}>
          <p>{row?.code ? row?.code : '-'}</p>
        </Stack>
      </div>
    );
  };

  // show discount type on table
  const renderDiscountType = (a: any, b: any, rowData: any) => {
    const {row}: {row: Discount} = rowData;

    return (
      <div className="py-vtl-11">
        <Stack gap={1}>
          <p>{discountType[row.type]}</p>
        </Stack>
      </div>
    );
  };

  // show discount status on table
  const renderStatus = (a: any, b: any, rowData: any) => {
    const {row}: {row: Discount} = rowData;

    return (
      <Stack gap={1}>
        <StatusIndicator
          label={capitalizeFirstLetter(row.status?.toLocaleLowerCase())}
          color={statusColor[row.status]}
        />
      </Stack>
    );
  };

  // Header cells items
  const HeaderCells: HeadCellType[] = [
    {
      name: 'name',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.discountName',
      }),
      collapse: false,
      width: '25%',
      sortable: true,
      renderValue: (row: any, a, b) => renderDiscountName(row, a, b),
    },
    {
      name: 'startDate',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.startDate',
      }),
      collapse: true,
      sortable: true,
      renderValue: (row: any, a, b) => renderDiscountStartDate(row, a, b),
    },
    {
      name: 'endDate',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.endDate',
      }),
      collapse: true,
      sortable: true,
      renderValue: (row: any, a, b) => renderDiscountEndDate(row, a, b),
    },
    {
      name: 'code',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.discountCode',
      }),
      collapse: true,
      sortable: true,
      renderValue: (row: any, a, b) => renderDiscountCode(row, a, b),
    },
    {
      name: 'type',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.discountType',
      }),
      collapse: true,
      sortable: true,
      renderValue: (row: any, a, b) => renderDiscountType(row, a, b),
    },
    {
      name: 'status',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.status',
      }),
      collapse: true,
      sortable: true,
      width: '130px',
      renderValue: (row: any, a, b) => renderStatus(row, a, b),
    },
  ];

  // filter form list
  const {items} = useDiscountFilter();

  return (
    <Container fluid>
      <div className="content-block">
        <Row>
          <Col>
            {/* Filters */}
            <Row className="align-items-center justify-content-between search-row">
              <Col className="col-xl-5 col-lg-8 d-flex">
                {/* Search */}
                <KeywordSearch
                  placeholder={intl.formatMessage({
                    id: 'dashboard.store_details.discount_tab.searchNameCode',
                  })}
                  resetPageOnChange
                />
              </Col>
              {/* Button toolbar */}
              <Col className="col-auto d-lg-block ps-0 ps-lg-2">
                <Stack direction="horizontal" gap={2}>
                  <DynamicFilter
                    items={items}
                    bindQueryParams
                    resetPageOnFilter
                    onFilter={applyFilter}
                  />
                </Stack>
              </Col>
            </Row>
            <DataTable
              className="discount-list pt-lg-1"
              empty={false}
              loading={isLoading}
              onRowClick={handleRowClick}
              headCells={HeaderCells}
              actionBottom={
                <Button
                  primary
                  outlined
                  title={intl.formatMessage({
                    id: 'dashboard.store_details.discount_tab.addDiscount',
                  })}
                  mobileFullWidth
                  className="action-btn"
                  onClick={() => handleRowClick('new')}
                />
              }
              rows={data ?? []}
              onChange={() => {}}
              total={meta ? meta.itemCount : 100}
              handleParameterChange={tableState.handleDataTableParamChange}
              defaultOrder={tableState.orderDirection.toLowerCase() as any}
              defaultOrderBy={tableState.orderBy}
              defaultRowPerPage={tableState.pageSize}
              currentPage={tableState.page}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default DiscountTab;
