import {FormattedMessage, useIntl} from 'react-intl';
import cx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {Stack} from 'react-bootstrap';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {useSchedulesListStyles, useElementStyles} from './useStyles';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {ReactComponent as ExpandIcon} from '../../../../assets/images/expand.svg';
import StatusIndicator from '../../../Common/StatusIndicator';
import Dropdown, {ItemType} from '../../../Common/Dropdown';
import ScheduleItemsList from './ScheduleItemsList';
import useScheduleManagement from '../../../../hooks/useScheduleManagement';
import SaveFeature from '../../../Shared/SaveFeature';
import {useState} from 'react';
import {MenuStatus} from '../../../../types/StoreSchedule';

/**
 * Schedules list
 */

type SchedulesListProps = {
  form: string;
};

type ScheduleTitleProps = {
  label: string;
  isOff: boolean;
  id: number;
  scheduleItemsCount: number;
  toggleScheduleStatus: (id: number, status: string) => void;
};

export type CategoryCountState = {
  itemId: number;
  count: number;
};

const ScheduleTitle = ({
  label,
  isOff,
  id,
  scheduleItemsCount,
  toggleScheduleStatus,
}: ScheduleTitleProps) => {
  const intl = useIntl();
  const elementClasses = useElementStyles();

  const onClick = (status: MenuStatus) => {
    console.log('🚀 ~ onClick ~ status:', status);
    if (status) {
      toggleScheduleStatus(id, status.toLowerCase());
    }
  };

  const scheduleStatus: ItemType[] = [
    {
      id: 'on',
      label: intl.formatMessage({
        id: 'dashboard.store_details.menu_tab.day_status.on',
      }),
      onClick: () => onClick(MenuStatus.On),
    },
    {
      id: 'off',
      label: intl.formatMessage({
        id: 'dashboard.store_details.menu_tab.day_status.off',
      }),
      onClick: () => onClick(MenuStatus.Off),
    },
  ];

  return (
    <>
      <Stack direction="horizontal" gap={2}>
        <Typography variant="h5" className="schedule-day">
          {label}
        </Typography>
        <Dropdown
          items={scheduleStatus}
          value={isOff ? 'off' : 'on'}
          title={
            <StatusIndicator
              label={intl.formatMessage({
                id: `dashboard.store_details.menu_tab.day_status.${
                  isOff ? 'off' : 'on'
                }`,
              })}
              color={isOff ? 'danger' : 'green'}
            />
          }
        />
      </Stack>
      {scheduleItemsCount > 0 && (
        <div
          className={cx(
            isOff
              ? elementClasses.numSchedulesOff
              : elementClasses.numSchedules,
            'ms-auto'
          )}
        >
          <Typography>
            {scheduleItemsCount}{' '}
            <FormattedMessage
              id={
                scheduleItemsCount === 1
                  ? 'dashboard.store_details.menu_tab.schedule'
                  : 'dashboard.store_details.menu_tab.shedules'
              }
            />
          </Typography>
        </div>
      )}
    </>
  );
};

const SchedulesList = ({form}: SchedulesListProps) => {
  const intl = useIntl();
  // count changed categories
  const [categoryCount, setCategoryCount] = useState<CategoryCountState[]>([]);
  // reset categories checkbox
  const [isResetting, setIsResetting] = useState(false);

  const updateIsResetting = (reset: boolean) => setIsResetting(reset);
  const updateCount = (lists: CategoryCountState[]) => {
    setCategoryCount(lists);
  };
  const daysListClasses = useSchedulesListStyles();

  const {
    control,
    handleSubmit,
    submit,
    setValue,
    toggleScheduleStatus,
    deleteScheduleItem,
    data,
    count,
    resetForm,
    scheduleList,
    updateIsDeleting,
  } = useScheduleManagement({categoryCount, updateCount});

  const resetList = () => {
    resetForm();
    setIsResetting(true);
  };

  const getTotalCategoryCount = (lists: CategoryCountState[]) => {
    return lists.reduce((sum, item) => {
      return sum + item.count;
    }, 0);
  };

  const totalCount = count + getTotalCategoryCount(categoryCount);

  return (
    <div className="schedule-list pb-5">
      {totalCount > 0 && (
        <SaveFeature
          onClose={resetList}
          open
          keyType="menu"
          num={totalCount}
          type="submit"
          form="schedule-form"
        />
      )}
      <form id={form} onSubmit={handleSubmit(submit)}>
        {/* Schedule list */}
        <div className="pb-5">
          {data?.map(({id, day, status, items}, index) => {
            const isOff = status === 'off';
            const label = intl.formatMessage({
              id: `dashboard.store_details.menu_tab.schedule_days.${day.toLowerCase()}`,
            });
            return (
              <div key={id} className="position-relative">
                <span className={cx('left-border', {danger: isOff})} />
                <Accordion
                  square
                  className={cx(daysListClasses.root, {danger: isOff})}
                >
                  <AccordionSummary
                    id={`day-${id}`}
                    expandIcon={<ExpandIcon />}
                  >
                    <ScheduleTitle
                      label={label}
                      isOff={isOff}
                      id={id}
                      scheduleItemsCount={items.length}
                      toggleScheduleStatus={toggleScheduleStatus}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Schedules list */}
                    <ScheduleItemsList
                      control={control}
                      setValue={setValue}
                      deleteScheduleItem={deleteScheduleItem}
                      scheduleIndex={index}
                      updateCount={updateCount}
                      savedData={scheduleList}
                      countLists={categoryCount}
                      isResetting={isResetting}
                      updateIsResetting={updateIsResetting}
                      updateIsDeleting={updateIsDeleting}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </div>
      </form>
    </div>
  );
};

export default SchedulesList;
