import React from 'react';
import {Stack} from 'react-bootstrap';
import ArrowLink from './ArrowLink';
import IconButton from './IconButton';
import {ReactComponent as RemoveIcon} from '../../assets/images/remove.svg';

type ListWithDeleteProps = {
  /**
   * Unique Id
   */
  id: number;
  /**
   * Arrow link title
   */
  name: string;
  /**
   * Arrow link url
   */
  url?: string;
  /**
   * Arrow link follows external url
   */
  isExternalLink?: boolean;
  /**
   * Delete callback
   */
  onDelete: () => void;
};

/**
 * Item on list with arrow link and remove dunctionality
 */
const ListWithDelete = (props: ListWithDeleteProps) => {
  const {id, name, url, onDelete, isExternalLink} = props;
  return (
    <Stack key={`item_${id}`} direction="horizontal" gap={2}>
      <div className="bg-light rounded px-vtl-15 py-2 static-field w-100">
        <ArrowLink
          className="justify-content-between"
          title={name}
          url={url ? url : '#'}
          isExternalLink={isExternalLink}
        />
      </div>
      <IconButton size="sm" variant="action" onClick={onDelete}>
        <RemoveIcon />
      </IconButton>
    </Stack>
  );
};

export default ListWithDelete;
