import {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Typography} from '@material-ui/core';
import {Container, Row, Col} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import useDeliveryZone from '../../../hooks/useDeliveryZone';
import MapZone from './Delivery/MapZone';
import DeliveryZoneList from './Delivery/DeliveryZoneList';
import Button from '../../Common/Button';
import {ReactComponent as PlusRedIcon} from '../../../assets/images/plusRed.svg';
import EditZonePopup from './Delivery/EditZonePopup';
import ConfirmationPopup from '../../Shared/ConfirmationPopup';
import DeliveryConfigForm from './Delivery/DeliveryConfigForm';

export type Coord = {
  lat: number;
  lng: number;
};

const DeliveryTab = () => {
  const [editPopup, setEditPopup] = useState(false);
  const deliveryZoneListRef = useRef<HTMLDivElement>(null);
  const deliveryZoneMapRef = useRef<HTMLDivElement>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    selectedZone,
    setSelectedZone,
    deliveryZones,
    setDeliveryZones,
    storeAddress,
    updateDeliveryZoneStatus,
    deleteDeliveryZone,
    deleteSuccess,
    isSuccess,
    isDeliveryZonesSuccess,
  } = useDeliveryZone();

  useEffect(() => {
    if (isSuccess && isDeliveryZonesSuccess) {
      setIsLoading(false);
    }
  }, [isSuccess, isDeliveryZonesSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      setDeliveryZones(pre =>
        pre?.filter(zone => zone.id !== selectedZone?.id)
      );
      setSelectedZone(undefined);
      setDeleteConfirmation(false);
    }
  }, [deleteSuccess]);

  const intl = useIntl();

  const closeEditPopup = () => setEditPopup(false);
  const openEditPopup = () => setEditPopup(true);

  const handleDelete = () => {
    deleteDeliveryZone(selectedZone?.id as number);
    setDeleteConfirmation(false);
    closeEditPopup();
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        !selectedZone ||
        editPopup ||
        deliveryZoneListRef.current?.contains(target as Node) ||
        deliveryZoneMapRef.current?.contains(target as Node)
      ) {
        return;
      }

      setSelectedZone(undefined);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [selectedZone, editPopup]);

  return (
    <>
      {/* Delete Delivery Zone confirmation */}
      <ConfirmationPopup
        opened={deleteConfirmation}
        handleClose={() => setDeleteConfirmation(false)}
        onOk={handleDelete}
        description={intl.formatMessage({
          id: 'messages.delete_message',
        })}
      />
      <EditZonePopup
        opened={editPopup}
        selectedZone={selectedZone}
        handleClose={closeEditPopup}
        showDeleteConfirmation={setDeleteConfirmation}
        setSelectedZone={setSelectedZone}
      />
      <Container fluid>
        <div className="content-block delivery">
          <Row className="g-3">
            <Col lg={4} xxl={3}>
              <Row ref={deliveryZoneListRef}>
                <div className="delivery-zones-list">
                  <Row className="align-items-center mb-3">
                    <Col>
                      <Typography variant="h4">
                        <FormattedMessage
                          id="dashboard.store_details.delivery_tab.delivery_zones"
                          values={{number: deliveryZones?.length}}
                        />
                      </Typography>
                    </Col>
                    <Col xs="auto">
                      <Button
                        link
                        small
                        primary
                        title={intl.formatMessage({id: 'actions.add'})}
                        icon={<PlusRedIcon />}
                        onClick={() => {
                          setSelectedZone(undefined);
                          openEditPopup();
                        }}
                      />
                    </Col>
                  </Row>
                  <DeliveryZoneList
                    setSelectedZone={setSelectedZone}
                    zones={deliveryZones}
                    selectedZone={selectedZone}
                    editZonePopup={openEditPopup}
                    updateDeliveryZoneStatus={updateDeliveryZoneStatus}
                  />
                </div>
              </Row>
            </Col>
            <Col>
              <Row className="gy-2 mt-lg-4 justify-content-end">
                <Col lg="auto">
                  <Typography className="mt-2">
                    <FormattedMessage id="dashboard.store_details.delivery_tab.default_collection_time" />
                  </Typography>
                </Col>
                <Col lg={4}>
                  <DeliveryConfigForm />
                </Col>
              </Row>
              <div ref={deliveryZoneMapRef} className="mt-1">
                {!isLoading && (
                  <MapZone
                    selectedZone={selectedZone}
                    setSelectedZone={setSelectedZone}
                    deliveryZones={deliveryZones}
                    storeAddress={storeAddress}
                    setZonesData={setDeliveryZones}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default DeliveryTab;
