import {useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {ReactComponent as PlusRedIcon} from '../../../assets/images/plusRed.svg';
import Button from '../../Common/Button';
import ConditionsGroup from './ConditionsGroup';
import {Stack} from 'react-bootstrap';
import {DiscountEligibilityType} from '../../../types/DiscountEligibility';
import {
  DiscountAttributeType,
  discountAttributes,
} from '../../../types/DiscountEligibilityAttribute';

import {Control, useFieldArray} from 'react-hook-form';
import {DiscountForm} from '../../../hooks/discount/useDiscountFormRequest';

export type ConditionsSetupProps = {
  title: string;
  noConditionText: string;
  criteria: DiscountEligibilityType;
  control: Control<DiscountForm, any>;
};

const ConditionsSetup = (props: ConditionsSetupProps) => {
  const {title, noConditionText, criteria, control} = props;
  const intl = useIntl();

  const conditionAttributes: DiscountAttributeType[] =
    discountAttributes[criteria];

  //Groups list
  const {
    fields: groups,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'eligibilities',
  });

  const handelNewGroupClick = () => {
    append({
      eligibilityType: criteria,
      eligibilityConditions: [
        {
          condition: 'is_equal_to',
          entityName: '',
          items: [],
          propertyName: '',
        },
      ],
    });
  };

  const handleGroupDelete = (index: number) => {
    remove(index);
  };

  return (
    <div className="card details-card mb-3">
      <div className="card-body">
        <Typography variant="h4" className="mb-3">
          {title}
        </Typography>
        {!groups.filter(grp => grp.eligibilityType === criteria).length ? (
          <Typography variant="body2">{noConditionText}</Typography>
        ) : (
          groups && (
            <Stack gap={2}>
              {groups.map(
                (item, groupIndex: number) =>
                  item.eligibilityType === criteria && (
                    <div key={groupIndex}>
                      <ConditionsGroup
                        key={item.id}
                        control={control}
                        criteria={criteria}
                        groupIndex={groupIndex}
                        values={conditionAttributes}
                        handleGroupDelete={handleGroupDelete}
                      />
                    </div>
                  )
              )}
            </Stack>
          )
        )}
        <div className="pt-vtl-15">
          <Button
            link
            small
            primary
            title={intl.formatMessage({id: 'conditions.new_group'})}
            icon={<PlusRedIcon />}
            onClick={handelNewGroupClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ConditionsSetup;
