import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {StatementOrder} from '../../types/Statement';
import {Meta} from '../../types/Meta';

export type StatementSliceType = {
  orders: StatementOrder[];
  id: number;
  orderMeta: Meta;
};
/**
 * Default state object with initial values.
 */
type StatementOrderSliceState = {
  statements: StatementSliceType[];
};
const initialState: StatementOrderSliceState = {
  statements: [],
};

export const statementSlice = createSlice({
  name: 'statement',
  initialState,
  reducers: {
    setStatement: (state, action: PayloadAction<StatementSliceType>) => {
      if (action.payload) {
        const currentStatements = state.statements.filter(
          s => s.id !== action.payload.id
        );
        currentStatements.push(action.payload);
        state.statements = currentStatements;
      }
    },
    removeStatement: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.statements = state.statements.filter(
          s => s.id !== action.payload
        );
      }
    },
    clearStatements: () => {
      return initialState;
    },
  },
});

export const {setStatement, removeStatement, clearStatements} =
  statementSlice.actions;

export const selectStatements = (state: RootState) =>
  state.statements.statements;

export default statementSlice.reducer;
