import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from '../store/slices/authSlice';

const useUserInfo = () => {
  const {user, isAuthenticated} = useSelector(selectUserAndToken);
  const [name, setName] = useState('');
  const [initials, setInitials] = useState('');

  useEffect(() => {
    let fullName = '';
    let nameInitials = '';
    if (user) {
      fullName = `${user.firstName} ${user.lastName}`;
      // Get the initials of firstName and lastName
      nameInitials = `${user.firstName
        ?.substring(0, 1)
        .toUpperCase()}${user.lastName?.substring(0, 1).toUpperCase()}`;
    }
    setName(fullName);
    setInitials(nameInitials);
  }, [user]);
  return {
    user,
    isAuthenticated,
    name,
    initials,
  };
};

export default useUserInfo;
