import {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {FormattedMessage} from 'react-intl';
import cx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {ReactComponent as QRCodeIcon} from '../../../../assets/images/qrCode.svg';
import Preview from './Preview';
import {ValidFileOptions} from '../../../../types/valid-file';
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormSetError,
} from 'react-hook-form';

import {ReactComponent as CloseIcon} from '../../../../assets/images/closeButton.svg';

type ImageDropzoneProps = {
  qr: any;
  sizes: any;
  label?: string;
  name: string;
  fitImage?: boolean;
  isTallImage?: boolean;
  compact?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  canCancel?: boolean;
  hasProgress?: boolean;
  progress?: number;
  url?: string;
  onChange: any;
  uploadImage?: (imageData: File) => void;
  deleteImage?: () => void;
  cancelUpload?: () => void;
  uploadSuccess?: boolean;
  deleteSuccess?: boolean;
  cannotUpload?: boolean;
  validFileOptions?: ValidFileOptions;
  setError?: UseFormSetError<any>;
  clearError?: UseFormClearErrors<any>;
  errors?: FieldErrors<any>;
};

/**
 * Image dropzone component
 */
const ImageDropzone = (props: ImageDropzoneProps) => {
  const {
    qr,
    sizes,
    compact,
    canEdit,
    label,
    onChange,
    url,
    uploadImage,
    deleteImage,
    uploadSuccess,
    deleteSuccess,
    validFileOptions,
    setError,
    name,
    canDelete,
    clearError,
    cannotUpload = false,
    hasProgress = false,
    progress = 0,
    canCancel,
    cancelUpload,
    fitImage,
    isTallImage,
  } = props;

  // Files
  const [files, setFiles] = useState<any>([]);
  const onDrop = useCallback((acceptedFiles: any) => {
    if (cannotUpload) {
      return;
    }
    onChange(acceptedFiles);
    setFiles(
      acceptedFiles.map((file: Blob | MediaSource) =>
        Object.assign(file, {
          // eslint-disable-next-line node/no-unsupported-features/node-builtins
          preview: URL.createObjectURL(file),
        })
      )
    );
    if (uploadImage && acceptedFiles[0]) {
      uploadImage(acceptedFiles[0]);
    }
  }, []);

  function fileValidator(file: File) {
    if (validFileOptions) {
      if (file.size > validFileOptions?.maxSize) {
        if (setError) {
          const sizeInMb = validFileOptions?.maxSize / (1024 * 1024);
          setError(`${name}`, {
            type: 'custom',
            message: `The image is too large. It needs to be under ${sizeInMb}Mb.`,
          });
        }
        return {
          code: 'file-too-large',
          message: 'The image is too large. It needs to be under 3Mb.',
        };
      } else if (!validFileOptions?.type.includes(file.type)) {
        if (setError) {
          const acceptedFormats = validFileOptions?.type.map(type =>
            type.replace('image/', '').replace('+xml', '')
          );
          const stringFormatted = new Intl.ListFormat('en', {
            style: 'long',
            type: 'conjunction',
          }).format(acceptedFormats);
          setError(`${name}`, {
            type: 'custom',
            message: `Wrong format. Only ${stringFormatted} are accepted.`,
          });
        }
        return {
          code: 'wrong-format',
          message:
            'Wrong format. Only jpg, jpeg, png, gif and tiff are accepted.',
        };
      } else {
        if (clearError) {
          clearError(`${name}`);
        }
        return null;
      }
    }
    return null;
  }

  useEffect(() => {
    if (!uploadSuccess || deleteSuccess) {
      setFiles([]);
    }
  }, [uploadSuccess, deleteSuccess]);

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
    onDrop,
    maxSize: validFileOptions ? validFileOptions.maxSize : undefined,
    validator: fileValidator,
    disabled: cannotUpload,
  });

  return (
    <>
      {files.length === 0 && !url && (
        <div
          className={cx('dropzone-field', {compact})}
          {...getRootProps()}
          style={sizes ? sizes : {}}
        >
          <input {...getInputProps(onChange)} disabled={cannotUpload} />
          {qr && <QRCodeIcon />}
          {!compact && (
            <Typography className="text-muted text-center">
              <FormattedMessage id="actions.choose_file_drag_here" />
            </Typography>
          )}
        </div>
      )}
      {hasProgress && files && files.length === 1 && (
        <div
          className="card details-card my-2"
          style={{border: '1px solid lightgray'}}
        >
          <div className="card-body d-flex flex-column align-items-start justify-content-center">
            <div className="d-flex justify-content-between align-items-center w-100 mb-3">
              <p>{files[0].name}</p>
              {canCancel && (
                <CloseIcon style={{cursor: 'pointer'}} onClick={cancelUpload} />
              )}
            </div>
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '5px',
                backgroundColor: 'rgba(184, 184, 184, 0.2)',
                borderRadius: '5px',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  width: `${progress}%`,
                  height: '5px',
                  backgroundColor: 'red',
                  borderRadius: '5px',
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
      {((files && files.length === 1) || url) && !hasProgress && (
        <Preview
          compact={compact}
          src={files[0]?.preview || url}
          sizes={sizes ? sizes : {}}
          onLoad={() => {
            // eslint-disable-next-line node/no-unsupported-features/node-builtins
            URL.revokeObjectURL(files[0]?.preview);
          }}
          canEdit={canEdit}
          label={label}
          onDelete={deleteImage}
          uploadImage={uploadImage}
          validFileOptions={validFileOptions}
          canDelete={canDelete}
          shouldFitImage={fitImage}
          isTallImage={isTallImage}
        />
      )}
    </>
  );
};

export default ImageDropzone;
