import {Box, Typography} from '@material-ui/core';
import {Marker, InfoWindow, Polygon} from '@react-google-maps/api';
import {Fragment} from 'react';
import {FormattedMessage} from 'react-intl';
import {Container, Row, Col} from 'reactstrap';
import {DeliveryZone} from '../../../../../types/DeliveryZone';
import MarkerIcon from '../../../../../assets/images/mapMarker.svg';
import RestaurantIcon from '../../../../../assets/images/restaurant.svg';
import {drawCircle} from '../../../../../utils/draw-circle';

type MapZoneWidgetProps = {
  zone: DeliveryZone;
  selectedZone?: DeliveryZone;
  setSelectedZone: (zone: DeliveryZone) => void;
  index: number;
};

const MapZoneWidget = (props: MapZoneWidgetProps) => {
  const {zone, index, selectedZone, setSelectedZone} = props;

  const zoneIcon = MarkerIcon;

  const mainZoneIcon = RestaurantIcon;

  const handleClick = () => {
    setSelectedZone(zone);
  };

  return (
    <Fragment key={index}>
      <Marker
        position={{
          lat: zone.address.latitude,
          lng: zone.address.longitude,
        }}
        onClick={handleClick}
        draggable={false}
        icon={{
          url: zone.isMainZone ? mainZoneIcon : zoneIcon,
        }}
      >
        {selectedZone && selectedZone.id === zone.id && (
          <InfoWindow
            position={{
              lat: zone.address.latitude,
              lng: zone.address.longitude,
            }}
            options={{
              maxWidth: 200,
            }}
          >
            <Box py={1} className="vtl-info-window">
              <Container className="px-2">
                {zone.isMainZone && (
                  <Typography
                    variant="subtitle2"
                    className="font-weight-bold mb-vtl-3"
                  >
                    <FormattedMessage id="dashboard.store_details.delivery_tab.your_store" />
                  </Typography>
                )}
                <Typography
                  variant="subtitle2"
                  className="font-weight-bold mb-vtl-3"
                >
                  <FormattedMessage id="dashboard.store_details.delivery_tab.range" />
                </Typography>
                <Row>
                  <Col>
                    <Typography variant="subtitle2">
                      <FormattedMessage id="dashboard.store_details.delivery_tab.starting_point" />
                    </Typography>
                  </Col>
                  <Col xs="auto">
                    <Typography variant="subtitle2">
                      {zone.rangeMin} mile
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Typography variant="subtitle2">
                      <FormattedMessage id="dashboard.store_details.delivery_tab.ending_point" />
                    </Typography>
                  </Col>
                  <Col xs="auto">
                    <Typography variant="subtitle2">
                      {zone.rangeMax} miles
                    </Typography>
                  </Col>
                </Row>
              </Container>
            </Box>
          </InfoWindow>
        )}
      </Marker>
      <Polygon
        onClick={handleClick}
        paths={[
          drawCircle(
            new google.maps.LatLng(
              zone.address.latitude,
              zone.address.longitude
            ),
            zone.rangeMax,
            1
          ),

          drawCircle(
            new google.maps.LatLng(
              zone.address.latitude,
              zone.address.longitude
            ),
            zone.rangeMin,
            -1
          ),
        ]}
        options={{
          fillColor:
            selectedZone && selectedZone.id === zone.id ? '#ff0000' : '#ff9c00',
          fillOpacity: 0.2,
          strokeColor: '#ff0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
        }}
        editable={false}
        draggable={false}
      />
    </Fragment>
  );
};

export default MapZoneWidget;
