import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useIntl} from 'react-intl';
import {useMemo} from 'react';
import {OptionProps} from '../../components/Form/Inputs/AutocompleteMultiple';
import {
  allowedStoreUrlPatterns,
  phoneRegExp,
  urlRegexWithHttp,
} from '../../utils/functions';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from '../../store/slices/authSlice';

export type GeneralFormValues = {
  image: any;
  news: string;
  name: string;
  paymentMethod: string[];
  orderRejectionNote: string;
  sellers: OptionProps[];
  storeTypes: OptionProps[];
  email: string;
  phone: string;
  storeUrl: string;
  externalStoreUrl: string;
  description: string;
  city: string;
  postCode: string;
  googleAppUrl: string;
  appleAppUrl: string;
};

const useStoreDetailFormRequest = () => {
  const intl = useIntl();
  const {user} = useSelector(selectUserAndToken);

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .email(intl.formatMessage({id: 'validation.error.invalid_email'})),
        name: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        phone: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .matches(
            phoneRegExp,
            intl.formatMessage({id: 'validation.error.invalid_phone'})
          ),
        paymentMethod: yup.array().when([], {
          is: () => user?.profileType === 'seller',
          then: yup.array().nullable(),
          otherwise: yup
            .array()
            .min(
              1,
              intl.formatMessage({
                id: 'validation.error.payment_method_required',
              })
            )
            .required(
              intl.formatMessage({
                id: 'validation.error.payment_method_required',
              })
            ),
        }),
        storeTypes: yup.array().when([], {
          is: () => user?.profileType === 'seller',
          then: yup.array().nullable(),
          otherwise: yup
            .array()
            .min(1, intl.formatMessage({id: 'validation.error.required_field'}))
            .required(
              intl.formatMessage({id: 'validation.error.required_field'})
            ),
        }),
        storeUrl: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .matches(
            allowedStoreUrlPatterns,
            intl.formatMessage({id: 'validation.error.invalid_store_url'})
          ),
        externalStoreUrl: yup
          .string()
          .nullable()
          .notRequired()
          .matches(
            urlRegexWithHttp,
            intl.formatMessage({id: 'validation.error.invalid_url'})
          ),
        googleAppUrl: yup
          .string()
          .matches(
            urlRegexWithHttp,
            intl.formatMessage({id: 'validation.error.invalid_url'})
          ),
        appleAppUrl: yup
          .string()
          .matches(
            urlRegexWithHttp,
            intl.formatMessage({id: 'validation.error.invalid_url'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    formState,
  } = useForm<GeneralFormValues>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
    defaultValues: {
      storeTypes: [],
      sellers: [],
    },
  });

  return {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    formState,
  };
};
export default useStoreDetailFormRequest;
