import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as urls from '../constants/urls';
import {useFetchMenuQuery} from '../services/listMenuApi';
import {selectMenus, setMenus} from '../store/slices/menuSlice';
import useUserInfo from './useUserInfo';

export default function useSidebarMenu() {
  const dispatch = useDispatch();
  // fetch menu from api
  const {
    data,
    isSuccess,
    refetch: refetchMenu,
  } = useFetchMenuQuery(undefined, {
    // polling every 15 minutes
    pollingInterval: 1000 * 60 * 15,
    skipPollingIfUnfocused: true,
  });

  // fetch current loggedIn user info
  const {user} = useUserInfo();

  const menusOrder: {[key: string]: number} = {
    orders: 1,
    stores: 2,
    users: 3,
    transactions: 4,
    apps: 5,
    settings: 6,
  };

  // save menu in store
  useEffect(() => {
    if (data && isSuccess) {
      dispatch(setMenus(data));
    }
  }, [data, isSuccess]);

  // select the menus from store
  const menus = useSelector(selectMenus);

  // add base path to menu path
  // filter based on role
  const menuItems = menus
    .map(menu => {
      return {
        ...menu,
        path: `${urls.pathPrefix}${menu.path}`,
      };
    })
    .filter(item =>
      //@ts-ignore
      item.role.includes(user?.profileType)
    );

  menuItems.sort((m, n) => menusOrder[m.name] - menusOrder[n.name]);

  return {
    menuItems,
    refetchMenu,
  };
}
