import {useIntl} from 'react-intl';

/**
 * @typedef {Object} TitleHook
 * @property {Function} appTitle
 * @property {Function} userTitle
 * @property {Function} intlTitle   Function that accept title to be translated
 */

/**
 * React-hook for title to format title for different contexts
 * @return {TitleHook}
 */
export default function useTitle() {
  const intl = useIntl();

  /**
   * Returns a title with eventually prefix or suffixe
   */
  const appTitle = (title: string | string[], separator = ' - ') => {
    const res = Array.isArray(title) ? title : [title];
    res.unshift(intl.formatMessage({id: 'app_name'}));

    return res.join(separator);
  };

  /**
   * Translate and format title with `self.appTitle`
   */
  const intlTitle = (title: string | string[], separator = ' - ') => {
    const res = Array.isArray(title) ? title : [title];

    return appTitle(
      res.map((id: string) => intl.formatMessage({id})),
      separator
    );
  };

  return {
    appTitle,
    intlTitle,
  };
}
