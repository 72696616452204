import {useEffect, useState} from 'react';
import {OptionProps} from '../components/Form/Inputs/AutocompleteMultiple';
import {useFetchLocationQuery} from '../services/listLocationApi';

type UseLocationProp = {
  type: 'store' | 'buyer';
};

export default function useLocationList({type}: UseLocationProp) {
  const {isSuccess, data} = useFetchLocationQuery(type);
  const [locations, setLocations] = useState<OptionProps[]>([]);

  useEffect(() => {
    if (data && isSuccess) {
      // map data to drop down select options
      setLocations(
        data.map(item => ({
          title: item.city,
          value: item.city,
        }))
      );
    }
  }, [data, isSuccess]);

  return {
    locations,
  };
}
