import {useIntl} from 'react-intl';
import useDataTableState, {
  UseDataTableStateReturn,
} from './table/useDataTableState';
import {parseStatusTypeFilter} from '../utils/functions';
import {useEffect, useState} from 'react';
import {formatErrorMessage, getUrlSearchParams} from '../utils/functions';
import {parseFilterSearchParams} from '../components/Form/DynamicFilter';
import {isEqual} from 'lodash';
import {useFetchStatementsQuery} from '../services/statementApi';
import {useSearchParams} from 'react-router-dom';

export const getStatementListQuery = (
  tableState: UseDataTableStateReturn,
  filterValues: any,
  q: string
) => {
  return {
    page: tableState.page,
    pageSize: tableState.pageSize,
    order: tableState.orderDirection,
    orderBy: tableState.orderBy,
    storeName: filterValues?.storeName ?? undefined,
    dateFilter: filterValues?.period?.type ?? undefined,
    endDate: filterValues?.period?.to ?? undefined,
    startDate: filterValues?.period?.from ?? undefined,
    statementOrderDateFilter:
      filterValues?.statementOrderDateFilter?.type ?? undefined,
    statementOrderStartDate:
      filterValues?.statementOrderDateFilter?.from ?? undefined,
    statementOrderEndDate:
      filterValues?.statementOrderDateFilter?.to ?? undefined,
    totalCashAmount: filterValues?.totalCashAmount ?? undefined,
    totalCardAmount: filterValues?.totalCardAmount ?? undefined,
    totalCardFee: filterValues?.totalCardFee ?? undefined,
    totalSellerFee: filterValues?.totalSellerFee ?? undefined,
    totalAmount: filterValues?.totalAmount ?? undefined,
    status: filterValues?.status
      ? parseStatusTypeFilter(filterValues.status)
      : undefined,
    search: q,
  };
};

export default function useStatementList() {
  // Translations
  const intl = useIntl();

  const [searchParams] = useSearchParams();
  const [filterValues, setFilterValues] = useState<any>({});
  const [q, setQ] = useState('');
  const tableState = useDataTableState({
    defaultValues: {
      orderDirection: 'DESC',
      orderBy: 'period',
    },
    bindQueryParams: true,
  });
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      const search = getUrlSearchParams(searchParams);
      const newFilterValues = parseFilterSearchParams(search);
      if (!isEqual(filterValues, newFilterValues)) {
        setFilterValues(newFilterValues);
      }
      const nextQ = search.get('q') ?? '';
      if (q !== nextQ) {
        setQ(nextQ);
      }

      setReady(true);
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }, [searchParams]);

  // statement list data
  const {isSuccess, data, isError, isFetching, isLoading} =
    useFetchStatementsQuery(
      {
        query: getStatementListQuery(tableState, filterValues, q),
        formatErrorMessage: error => formatErrorMessage(error, intl),
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !ready,
      }
    );

  const handleRowClick = () => {};

  return {
    isLoading,
    isError,
    isFetching,
    isSuccess,
    data: data?.data,
    handleRowClick,
    meta: data?.meta,
    tableState,
  };
}
