import {ReactNode, useState} from 'react';
import cx from 'clsx';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {ReactComponent as ArrowRightIcon} from '../../assets/images/arrowRight.svg';
import Scrollbar from '../Widgets/Scrollbar';

export type ItemType = {
  id?: string | number;
  label: string;
  submenu?: ItemType[];
  onClick?: (data?: any) => void;
};

export type CustomDropdownProps = {
  /**
   * Dropdown toggle label
   */
  title?: string | ReactNode;
  /**
   * Current value
   */
  value?: string | number;
  /**
   * Dropdown toggle icon
   */
  menuIcon?: any;
  /**
   * Dropdown items list
   */
  items: ItemType[];
  className?: string;
  menuClassName?: string;
  hoverToggle?: boolean;
  topPosition?: number;
  data?: any;
};

// item with submenu
const Submenu = ({
  title,
  items,
  value,
  hoverToggle,
  topPosition = 0,
}: CustomDropdownProps) => {
  //one element height
  const itemHeight = 35;
  //dropdown state
  const [dropdownOpen, setDropdownOpen] = useState(false);

  //open/close dropdown handler
  const toggle = (e: any) => {
    e.stopPropagation();
    setDropdownOpen(() => !dropdownOpen);
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="custom-dropdown"
      direction="left"
    >
      <DropdownToggle
        tag="div"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        className="dropdown-item"
        onMouseEnter={e => (hoverToggle ? toggle(e) : undefined)}
      >
        <div className="d-flex align-items-center justify-content-between">
          <span>{title}</span>
          <ArrowRightIcon />
        </div>
      </DropdownToggle>
      <DropdownMenu
        modifiers={{
          computeStyle: {
            gpuAcceleration: true,
          },
        }}
        style={{
          //align top position to the parent menu opened
          marginTop: topPosition > 0 ? itemHeight * topPosition * -1 + 24 : 0,
        }}
      >
        <Scrollbar disabled={items.length < 5} height={207} gap={0}>
          <div>
            {items.map((item, key) => (
              <DropdownItem
                key={key}
                className={cx({current: value && item?.id === value})}
                onClick={toggle}
              >
                <div className="d-flex" onClick={item.onClick}>
                  <span>{item.label}</span>
                </div>
              </DropdownItem>
            ))}
          </div>
        </Scrollbar>
      </DropdownMenu>
    </Dropdown>
  );
};

/**
 * Dropdown (used on top menu)
 */
const CustomDropdown = ({
  title,
  menuIcon,
  value,
  items = [],
  className = '',
  menuClassName = '',
  hoverToggle = false,
  data,
}: CustomDropdownProps) => {
  //dropdown state
  const [dropdownOpen, setDropdownOpen] = useState(false);

  //open/close dropdown handler
  const toggle = (e: any) => {
    e.stopPropagation();
    setDropdownOpen(prevState => !prevState);
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className={cx('custom-dropdown', className)}
    >
      <DropdownToggle
        tag="div"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        onMouseEnter={e => (hoverToggle ? toggle(e) : undefined)}
        className={cx('dropdown-toggle', menuIcon && 'custom-menu-icon')}
      >
        {title ?? title}
        {menuIcon && menuIcon}
      </DropdownToggle>
      <DropdownMenu
        right
        className={cx(menuClassName, {'has-title': !!title})}
        modifiers={{
          computeStyle: {
            gpuAcceleration: true,
          },
        }}
      >
        {items.map((item, key) =>
          item?.submenu ? (
            <Submenu
              key={key}
              title={item.label}
              items={item.submenu}
              value={value}
              hoverToggle
              topPosition={key + 1}
            />
          ) : (
            <DropdownItem
              key={key}
              onClick={e => {
                e.preventDefault();
                setDropdownOpen(prevState => !prevState);
                if (data) {
                  item?.onClick?.(data);
                } else {
                  item?.onClick?.();
                }
              }}
              className={cx({current: value && item?.id === value})}
            >
              <div
                className="d-flex"
                onClick={e => {
                  e.preventDefault();
                  setDropdownOpen(prevState => !prevState);
                }}
              >
                <span>{item.label}</span>
              </div>
            </DropdownItem>
          )
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default CustomDropdown;
