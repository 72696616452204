import {useIntl} from 'react-intl';
import {formatErrorMessage} from '../utils/functions';
import {useFetchAuthProviderQuery} from '../services/appApi';

export type useGetAppAuthProviderProps = {
  appId: number;
};

export default function useGetAppAuthProvider(
  props: useGetAppAuthProviderProps
) {
  const {appId} = props;
  // Translations
  const intl = useIntl();

  // app integration data
  const {isSuccess, data} = useFetchAuthProviderQuery(
    {
      appId,
      formatErrorMessage: error => formatErrorMessage(error, intl),
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !appId,
    }
  );

  return {
    isSuccess,
    data: data,
  };
}
