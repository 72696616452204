export enum AuthProviderIdEnum {
  Google = 'google',
  Facebook = 'facebook',
  Apple = 'apple',
}

export enum AuthProviderNameEnum {
  Google = 'Google',
  Facebook = 'Facebook',
  Apple = 'Apple',
}

export interface AuthProviderSecrets {
  iosClientId: string;
  androidClientId: string;
  iosUrlScheme: string;
  appId: string;
  clientToken: string;
  clientSecret: string;
}

export interface AuthProvider {
  id: number;
  appId: number;
  providerId: AuthProviderIdEnum;
  providerName: AuthProviderNameEnum;
  secrets: AuthProviderSecrets;
  createdAt: Date;
  updatedAt: Date;
}
