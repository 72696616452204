import {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Col, Container, Row, Stack} from 'react-bootstrap';
import {isEmpty} from 'lodash';
import cx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import Group from './ProductCategoriesTab/Group';
import Button from '../../Common/Button';
import {ReactComponent as PlusIcon} from '../../../assets/images/plusRed.svg';
import ProductsList from './ProductCategoriesTab/ProductsList';
import CategoryActions from './ProductCategoriesTab/CategoryActions';
import AddPopup from '../../Widgets/AddPopup';
import CreateCategoryForm from './ProductCategoriesTab/Form/CreateCategoryForm';
import EditCategoryForm from './ProductCategoriesTab/Form/EditCategoryForm';
import CreateProductForm from './ProductCategoriesTab/Form/CreateProductForm';
import ProductsImport from './ProductCategoriesTab/ProductsImport';
import useDraggablePlaceholder from '../../Widgets/DragDrop/useDraggablePlaceholder';
import useProductCategory from '../../../hooks/useProductCategory';
import {ProductCategory} from '../../../types/ProductCategory';
import ConfirmationPopup from '../../Shared/ConfirmationPopup';
import useProduct from '../../../hooks/useProduct';
import {Product} from '../../../types/Product';
import {
  OrderParam,
  useEditProductMutation,
} from '../../../services/productCategoryApi';
import {onChange} from '../../../utils/functions';
import {useLocation, useNavigate} from 'react-router-dom';
import * as urls from '../../../constants/urls';

/**
 * Product Categories
 */
type ProductCategoriesTabProps = {
  id?: number | string;
};

const ProductCategoriesTab = ({id}: ProductCategoriesTabProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>();
  // Current category id
  const [selCategory, setSelCategory] = useState('');
  const [selectedCategory, setSelectedCategory] =
    useState<ProductCategory | null>();
  // Animation state
  const {placeholderProps, onDragUpdate} = useDraggablePlaceholder();
  // Add category popup state
  const [addPopup, setAddPopup] = useState(false);
  // Edit category
  const [editPopup, setEditPopup] = useState(false);
  // Add product popup state
  const [addProductPopup, setAddProductPopup] = useState(false);
  // Import file with products popup
  const [importProducts, setImportProducts] = useState(false);
  // check editing state
  const [isEditing, setIsEditing] = useState(false);
  // open delete modal
  const [openModal, setOpenModal] = useState(false);

  const updateSelectedCategory = (category: ProductCategory) => {
    setSelectedCategory(category);
    setSelCategory(category.id.toString());
  };

  const {
    handleSubmit,
    control,
    editCategory,
    addCategory,
    updateSuccess,
    createSuccess,
    reset,
    productCategoriesByStatus,
    deleteCategory,
    deleteSuccess,
    reorderCategory,
    updateCategoryGroup,
    productCategories,
    createLoading,
    updateLoading,
  } = useProductCategory({
    storeId: id,
    selectedCategory,
    editMode: isEditing,
    onUpdate: updateSelectedCategory,
  });

  const {
    products,
    meta,
    setProducts,
    control: productControl,
    isFetching,
    order,
    orderBy,
    totalProducts,
    orderProduct,
    handleSubmit: FilterActionsHandleSubmit,
    submit,
    clearSearchField,
    handleRequestSort,
    handlePageChange,
    handlePageSizeChange,
    deleteProduct,
  } = useProduct({selectedCategory});

  useEffect(() => {
    // Get the current query parameters
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('category');
    if (id && productCategories?.length) {
      const category = productCategories.find(cat => cat.id === Number(id));
      // set selected category
      category && updateSelectedCategory(category);
    }
  }, [productCategories]);

  function updateQueryInUrl(category: string) {
    // Get the current query parameters
    const searchParams = new URLSearchParams(location.search);

    if (category === '') {
      searchParams.delete('category');
    } else {
      searchParams.set('category', category);
    }

    // Replace the current URL with the updated URL
    navigate(
      {search: searchParams.toString()},
      {
        replace: true,
      }
    );
  }

  const deleteProductCategory = () => {
    setOpenModal(false);
    deleteCategory(selectedCategory?.id as number);
  };

  const closeAddProduct = () => setAddProductPopup(false);

  useEffect(() => {
    if (deleteSuccess) {
      setIsEditing(false);
      setSelectedCategory(null);
      setSelCategory('');
    }
  }, [deleteSuccess]);

  // close Addpopup
  useEffect(() => {
    if (createSuccess) {
      reset();
      setAddPopup(false);
      setIsEditing(false);
    }
  }, [createSuccess]);

  // close Editpopup
  useEffect(() => {
    if (updateSuccess) {
      reset();
      setEditPopup(false);
      setIsEditing(false);
    }
  }, [updateSuccess]);

  const onDragEnd = (result: DropResult) => {
    const {source, destination} = result;

    let draggableId = result.draggableId;
    if (draggableId && /^category-\d+$/.test(draggableId)) {
      draggableId = draggableId.split('-').pop() ?? '';
    }

    if (!destination) {
      return;
    }
    // if item was dropped back in same position
    if (onChange(source, destination)) {
      return;
    }
    if (result.type === 'PRODUCT') {
      const droppableId = result.destination
        ? result.destination.droppableId
        : null;
      if (droppableId && /^category-(\d+)$/.test(droppableId)) {
        const categoryId = +(droppableId.split('-').pop() ?? '0');
        return changeProductCategory(+draggableId, categoryId);
      } else {
        return onProductDragEnd(result);
      }
    }
    // When reordering only
    if (destination.droppableId === source.droppableId) {
      if (destination.index !== source.index) {
        reorderCategory(source.droppableId, source.index, destination.index);
      }
      return;
    }

    updateCategoryGroup(
      Number(draggableId),
      source.droppableId,
      destination.droppableId,
      destination.index
    );
  };

  const [updateProduct, {data: updatedData, isSuccess: updatedSuccess}] =
    useEditProductMutation();

  useEffect(() => {
    if (updatedData && updatedSuccess) {
      updateProductList(updatedData.data);
    }
  }, [updatedData, updatedSuccess]);

  // Move product between categories handler
  const changeProductCategory = (productId: number, categoryId: number) => {
    const product: undefined | Product = products.find(p => p.id === productId);
    if (product && product?.category?.id !== categoryId) {
      updateProduct({
        id: productId,
        body: {
          name: product.name,
          chineseName: product.chineseName ?? undefined,
          description: product?.description,
          price: product.price.price,
          status: product.status,
          categoryId,
        },
        successMessage: intl.formatMessage(
          {
            id: 'messages.custom_update_product',
          },
          {
            product_name: product.name,
            category_name: !productCategories
              ? ''
              : productCategories.find(item => item.id === categoryId)?.name,
          }
        ),
      });
    }
    return;
  };

  const onSelect = (category: ProductCategory) => {
    setSelCategory(category.id.toString());
    setSelectedCategory(category);
    updateQueryInUrl(category.id.toString());
  };

  // set selected product
  const onSelectProduct = (product: Product) => {
    setSelectedProduct(product);
  };

  // call product order api
  const onProductOrder = (items: Product[]) => {
    const data: OrderParam[] = items.map(item => ({
      id: item.id,
      rank: item.rank,
    }));
    orderProduct(data);
  };

  // reorder products
  const reorder = (
    list: Product[],
    startIndex: number,
    endIndex: number
  ): Product[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // products drag and drop
  const onProductDragEnd = (result: DropResult) => {
    const {destination, source} = result;
    if (!destination) {
      return;
    }

    // reorder item
    const items = reorder(products, source.index, destination.index);
    const newItems = items.map((item, index: number) => ({
      ...item,
      rank: index + 1,
    }));

    setProducts(newItems);
    setTimeout(() => {
      onProductOrder(newItems);
    }, 1000);
  };

  const handleDragUpdate = (update: any) => {
    const {type} = update;
    // do not handle product drag update
    if (type !== 'PRODUCT') {
      onDragUpdate(update);
    }
  };

  const updateProductList = (product: Product) => {
    const newItems = [...products];
    if (
      selectedCategory &&
      product.categoryId &&
      product?.categoryId !== selectedCategory.id
    ) {
      const newProductLists = newItems.filter(item => item.id !== product.id);
      setProducts(newProductLists);
    } else {
      const itemIndex = newItems.findIndex(item => item.id === product.id);
      newItems[itemIndex] = product;
      setProducts(newItems);
    }
  };

  const gotoProductDetailsPage = (id: string) => {
    navigate(urls.PRODUCT_DETAILS_PATH.replace(':productId', id), {
      state: {category: selectedCategory ? selectedCategory : null},
    });
  };

  return (
    <>
      {/* Delete category confirmation */}
      <ConfirmationPopup
        opened={openModal}
        handleClose={() => setOpenModal(false)}
        onOk={deleteProductCategory}
        description={intl.formatMessage({
          id: 'messages.delete_message',
        })}
      />
      {/* Add new category */}
      <AddPopup
        title={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.edit_category.add',
        })}
        opened={addPopup}
        onClose={() => setAddPopup(false)}
        activeTab={createLoading ? undefined : 'add-category'}
      >
        <CreateCategoryForm
          form="add-category-form"
          control={control}
          handleSubmit={handleSubmit}
          submit={addCategory}
        />
      </AddPopup>
      {/* Edit category popup */}
      <AddPopup
        edit
        title={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.edit_category.edit',
        })}
        opened={editPopup}
        onClose={() => setEditPopup(false)}
        activeTab={updateLoading ? undefined : 'edit-category'}
      >
        <EditCategoryForm
          form="edit-category-form"
          control={control}
          handleSubmit={handleSubmit}
          submit={editCategory}
        />
      </AddPopup>
      {/* Add product popup */}
      <AddPopup
        title={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.add_product',
        })}
        opened={addProductPopup}
        onClose={() => setAddProductPopup(false)}
        activeTab="add-product"
      >
        <CreateProductForm
          form="add-product-form"
          selectedCategory={selectedCategory as ProductCategory}
          closePopup={closeAddProduct}
        />
      </AddPopup>
      <Container fluid>
        <div className="content-block">
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragUpdate={handleDragUpdate}
          >
            <Row className="g-3 h-100 flex-fill">
              <Col xl={4} xxl={3}>
                <div className="card details-card min-cropped-screen">
                  <div className="card-body">
                    <div className="mb-4">
                      <Typography variant="h5" className="mb-2">
                        <FormattedMessage id="dashboard.store_details.product_categories_tab.products" />
                      </Typography>
                      <div
                        className={cx('product-category', {
                          active: isEmpty(selCategory),
                        })}
                        role="button"
                        tabIndex={-1}
                        onClick={() => {
                          setSelCategory('');
                          setSelectedCategory(null);
                          updateQueryInUrl('');
                        }}
                      >
                        <Typography>
                          <FormattedMessage
                            id="dashboard.store_details.product_categories_tab.show_all_products"
                            values={{count: totalProducts}}
                          />
                        </Typography>
                      </div>
                    </div>
                    <Row className="mb-2">
                      <Col>
                        {productCategories && (
                          <Typography variant="h5">
                            <FormattedMessage
                              id="dashboard.store_details.product_categories_tab.categories"
                              values={{
                                count: productCategories.length,
                              }}
                            />
                          </Typography>
                        )}
                      </Col>
                      <Col xs="auto">
                        <Button
                          link
                          primary
                          small
                          title={intl.formatMessage({id: 'actions.add'})}
                          icon={<PlusIcon />}
                          onClick={() => {
                            setIsEditing(false);
                            setAddPopup(true);
                          }}
                        />
                      </Col>
                    </Row>
                    {/* Categories list dropzone */}
                    {/* gap=2 instead of 3, the group will complete the spacing */}
                    <Stack gap={2} className="position-relative">
                      {productCategoriesByStatus.map(group => {
                        return (
                          <Group
                            key={group.id}
                            group={group}
                            categories={group.items}
                            selectedCategory={
                              selectedCategory as ProductCategory
                            }
                            onSelect={onSelect}
                            placeholderProps={placeholderProps}
                          />
                        );
                      })}
                    </Stack>
                  </div>
                </div>
              </Col>

              <Col xl>
                {selectedCategory && (
                  <CategoryActions
                    category={selectedCategory}
                    onImport={() => setImportProducts(true)}
                    onEdit={() => {
                      setIsEditing(true);
                      setEditPopup(true);
                    }}
                    onDelete={() => setOpenModal(true)}
                  />
                )}
                <ProductsImport
                  importFile={importProducts}
                  onClose={() => setImportProducts(false)}
                  category={selectedCategory}
                  storeId={id}
                />

                <ProductsList
                  isSelectedCategory={!!selectedCategory}
                  products={products}
                  meta={meta}
                  control={productControl}
                  loading={isFetching}
                  order={order}
                  orderBy={orderBy}
                  categories={productCategories!}
                  selectedProduct={selectedProduct as Product}
                  setSelectedProduct={onSelectProduct}
                  handleSubmit={FilterActionsHandleSubmit}
                  submit={submit}
                  onClear={clearSearchField}
                  onImport={() => setImportProducts(true)}
                  handleSort={handleRequestSort}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                  updateProductList={updateProductList}
                  deleteProduct={deleteProduct}
                  gotoProductDetailsPage={gotoProductDetailsPage}
                />
                {selCategory && (
                  <div
                    className={cx({'mt-3 mt-lg-vtl-40': !isEmpty(products)})}
                  >
                    <Button
                      primary
                      outlined
                      title={intl.formatMessage({
                        id: 'dashboard.store_details.product_categories_tab.add_product',
                      })}
                      className="mobile-full-width action-btn"
                      onClick={() => setAddProductPopup(true)}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </DragDropContext>
        </div>
      </Container>
    </>
  );
};

export default ProductCategoriesTab;
