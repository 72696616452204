import {FeedbackParams} from '../store/queries/common';
import {Meta} from '../types/Meta';
import {Review, ReviewStatus} from '../types/Review';
import {baseApi} from './baseApi';

export type ListReviewQueryParameter = {
  query: {
    storeId: number;
    itemsPerPage: number;
    page: number;
    sortBy?: string;
    sortOrder?: string;
    search?: string;
  };
} & FeedbackParams;

export type ListReviewResponse = {
  data: Review[];
  meta: Meta;
};

export type UpdateReviewStatusQueryParameter = {
  reviewId: number;
  body: {
    status: ReviewStatus;
  };
} & FeedbackParams;

export const reviewApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    listReview: builder.query<ListReviewResponse, ListReviewQueryParameter>({
      query: ({query}: ListReviewQueryParameter) => ({
        url: '/admin/review',
        method: 'GET',
        params: query,
      }),
      providesTags: ['Review'],
    }),
    updateReview: builder.mutation<
      Review,
      UpdateReviewStatusQueryParameter | void
    >({
      query: ({reviewId, body}: UpdateReviewStatusQueryParameter) => ({
        url: `/admin/review/${reviewId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Review'],
    }),
  }),
});

export const {useListReviewQuery, useUpdateReviewMutation} = reviewApi;
