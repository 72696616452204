import {createStyles, makeStyles, Theme} from '@material-ui/core';

export const useSchedulesListStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      margin: theme.spacing(0.8, 0),
      borderRadius: theme.spacing(0.8),
      borderTopLeftRadius: '0 !important',
      borderBottomLeftRadius: '0 !important',
      backgroundColor: theme.palette.green.light,
      '&:before': {
        display: 'none',
      },
      '&:first-child': {
        marginTop: 0,
      },
      '&.Mui-expanded': {
        margin: theme.spacing(0.8, 0),
        '&:before': {
          opacity: 1,
        },
      },
      '& .MuiAccordionSummary-root': {
        minHeight: 'unset',
        padding: theme.spacing(0, 1.5),
        margin: theme.spacing(0),
        [theme.breakpoints.down('md')]: {
          alignItems: 'flex-start',
        },
        '&.Mui-expanded': {
          minHeight: 'unset',
          margin: theme.spacing(0),
          borderRadius: theme.spacing(0.8, 0.8, 0, 0),
        },
        '& .MuiAccordionSummary-content': {
          margin: theme.spacing(1, 0),
          '&.Mui-expanded': {
            margin: theme.spacing(1, 0),
          },
        },
        '& .MuiAccordionSummary-expandIcon': {
          padding: theme.spacing(1.5),
          color: theme.palette.green.main,
        },
        '& .MuiIconButton-edgeEnd': {
          marginRight: theme.spacing(-1.5),
        },
      },
      '&.danger': {
        backgroundColor: theme.palette.danger.light,
        borderLeftColor: theme.palette.danger.main,
        '&:before': {
          backgroundColor: theme.palette.danger.main,
        },
        '& .MuiAccordionSummary-root': {
          '& .MuiAccordionSummary-expandIcon': {
            color: theme.palette.danger.main,
          },
        },
      },
      '& .MuiAccordionDetails-root': {
        display: 'block',
        padding: theme.spacing(0, 1.5, 1.5, 1.5),
        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(0, 1, 1.5, 1.5),
        },
      },
    },
  })
);

export const useScheduleItemsListStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      margin: theme.spacing(0.8, 0),
      borderRadius: `${theme.spacing(0.4)}px !important`,
      '&:first-child': {
        marginTop: 0,
      },
      '&.Mui-expanded': {
        margin: theme.spacing(0.8, 0),
        '&:before': {
          opacity: 1,
        },
      },
      '& .MuiAccordionSummary-root': {
        minHeight: 'unset',
        padding: theme.spacing(0, 1.5, 0, 0.5),
        margin: theme.spacing(0),
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          '& .MuiAccordionSummary-expandIcon': {
            alignSelf: 'start',
          },
        },
        '&.Mui-expanded': {
          minHeight: 'unset',
          margin: theme.spacing(0),
          borderRadius: theme.spacing(0.4),
          boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.05)',
        },
        '&.Mui-focused': {
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.spacing(0.4),
        },
        '& .MuiAccordionSummary-content': {
          margin: theme.spacing(0.8, 0),
          '&.Mui-expanded': {
            margin: theme.spacing(0.8, 0),
          },
        },
      },
      '& .MuiAccordionDetails-root': {
        padding: theme.spacing(2, 1.5, 1.5, 1.5),
      },
    },
  })
);

export const useElementStyles = makeStyles((theme: Theme) =>
  createStyles({
    numSchedules: {
      alignSelf: 'center',
      backgroundColor: theme.palette.green.light,
      color: theme.palette.green.main,
      padding: theme.spacing(0.75, 1),
      borderRadius: theme.spacing(0.4),
    },
    numSchedulesOff: {
      backgroundColor: theme.palette.danger.light,
      color: theme.palette.danger.main,
      padding: theme.spacing(0.75, 1),
      borderRadius: theme.spacing(0.4),
    },
    editable: {
      display: 'flex',
      alignItems: 'center',
      '& .actions': {
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(0.35, 0.6),
        borderRadius: theme.spacing(0, 0.4, 0.4, 0),
        marginLeft: -1,
      },
      '& .MuiInputBase-root': {
        borderRadius: theme.spacing(0.4, 0, 0, 0.4),
        [theme.breakpoints.up('lg')]: {
          width: 240,
        },
        '& .MuiInputBase-input': {
          padding: theme.spacing(0.9, 1),
          fontFamily: 'Averta Semibold',
        },
      },
      '& .MuiOutlinedInput-root:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.divider,
        },
      },
    },
  })
);
