import clsx from 'clsx';
import {Theme, makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

export type CustomCheckboxProps = {
  /**
   * If true, the component is checked.
   */
  checked: boolean;
  /**
   * If true, the component appears indeterminate.
   */
  indeterminate?: boolean;
  /**
   * The value of the component. The DOM API casts this to a string. The browser uses "on" as the default value.
   */
  value?: any;
  /**
   * Checkbox size
   */
  size?: 'default' | 'small';
  /**
   * Checkbox color variant
   */
  variant?: 'primary' | 'blue';
  /**
   * If true, the checkbox will be disabled.
   */
  disabled?: boolean;
  /**
   * Callback fired when the state is changed.
   */
  onChange?: (event: any) => void;
  inputProps?: object;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.5),
    '&.filter': {
      padding: theme.spacing(0.3),
      marginLeft: 3,
    },
  },
  checked: {},
  icon: {
    borderRadius: 2,
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#313131',
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(0.5),
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: '#EDEDED',
      margin: theme.spacing(0.5),
    },
    '&.small': {
      border: 'none',
      width: 12,
      height: 12,
      backgroundImage:
        "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 62 (101010) - https://sketch.com --%3E%3Ctitle%3E66913449-D980-46F1-B573-93626E9A54F2%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='SW_Admin_User_Recipient-View-Profile_Bottom-pop-up' transform='translate(-319.000000, -388.000000)' fill='%23FFFFFF' stroke='%23B8B8B8'%3E%3Cg id='Check/off-Copy-7' transform='translate(320.000000, 389.000000)'%3E%3Crect id='Rectangle-Copy-14' x='0' y='0' width='10' height='10' rx='1'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      margin: 0,
    },
  },
  checkedIcon: {
    borderRadius: 2,
    backgroundColor: theme.palette.primary.main,
    backgroundImage: 'none',
    margin: theme.spacing(0.5),
    '&:before': {
      display: 'block',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='14' height='14' rx='1.5' fill='%23FF0000' stroke='%23FF0000'/%3E%3Cg clip-path='url(%23clip0_3307_69764)'%3E%3Cpath d='M12.3185 3.75C12.524 3.816 12.6801 3.93 12.7336 4.148C12.7705 4.3 12.7356 4.438 12.6432 4.564C12.6139 4.60056 12.5823 4.63529 12.5486 4.668L5.98151 11.054C5.76575 11.264 5.52534 11.31 5.29932 11.176C5.23847 11.1388 5.18257 11.0945 5.13288 11.044C4.26438 10.1973 3.39452 9.352 2.52329 8.508C2.40616 8.394 2.29521 8.278 2.25 8.12V7.92C2.31575 7.822 2.36712 7.71 2.45342 7.632C2.62808 7.476 2.89726 7.48 3.0863 7.62C3.12914 7.65333 3.16964 7.68941 3.20753 7.728L5.47603 9.934C5.50274 9.96 5.52329 9.99 5.57466 10.048C5.60548 10 5.62192 9.96 5.65068 9.934L11.6795 4.068C11.8089 3.942 11.9342 3.812 12.113 3.75H12.3185Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3307_69764'%3E%3Crect width='10.5' height='7.5' fill='white' transform='translate(2.25 3.75)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A\")",
      content: '""',
    },
    '&.blue:before': {
      display: 'block',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage:
        "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 62 (91390) - https://sketch.com --%3E%3Ctitle%3EGroup 2 Copy%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='SW_Admin_User_Recipient-View-Profile_Recipient' transform='translate(-668.000000, -470.000000)'%3E%3Cg id='Group-2-Copy' transform='translate(668.000000, 470.000000)'%3E%3Crect id='Rectangle-Copy-11' fill='%23FFFFFF' x='0' y='0' width='10' height='10'%3E%3C/rect%3E%3Cpolygon id='Path' fill='%233385CC' fill-rule='nonzero' points='2 4.94144574 4.05466331 7.00003103 8 3.05859305 7.43358238 2.50000776 4.05466331 5.87500485 2.55857366 4.37892683'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
      content: '""',
    },
    '&.small': {
      margin: 0,
      '&:before': {
        width: 12,
        height: 12,
      },
    },
  },
  indeterminateIcon: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(0.5),
    '&:before': {
      display: 'block',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      backgroundImage:
        "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 62 (91390) - https://sketch.com --%3E%3Ctitle%3EGroup 2 Copy 2%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='SW_Admin_User_Recipient-View-Profile_Profile' transform='translate(-648.000000, -646.000000)'%3E%3Cg id='Group-2-Copy-2' transform='translate(648.000000, 646.000000)'%3E%3Crect id='Rectangle-Copy-11' fill='%2321B59B' x='0' y='0' width='10' height='10'%3E%3C/rect%3E%3Crect id='Rectangle-Copy-4' fill='%23FFFFFF' x='2' y='4.5' width='6' height='1'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      content: '""',
    },
  },
}));

/**
 * Checkbox Component
 */
const CustomCheckbox = ({
  checked,
  size,
  variant,
  inputProps,
  onChange,
  disabled,
}: CustomCheckboxProps) => {
  const classes = useStyles();
  return (
    <Checkbox
      classes={{
        root: classes.root,
      }}
      icon={<span className={clsx(classes.icon, {small: size === 'small'})} />}
      checkedIcon={
        <span
          className={clsx(
            classes.checkedIcon,
            {small: size === 'small'},
            variant
          )}
        />
      }
      indeterminateIcon={<span className={clsx(classes.indeterminateIcon)} />}
      checked={checked}
      inputProps={inputProps}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

CustomCheckbox.defaultProps = {
  size: 'default',
  variant: 'primary',
  disabled: false,
  inputProps: {},
  indeterminate: false,
  value: '',
};

export default CustomCheckbox;
