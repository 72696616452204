import {FormattedNumber} from 'react-intl';

export type FormattedCurrencyProps = {
  value: number;
  currency: string;
};

const FormattedCurrency = (props: FormattedCurrencyProps) => {
  const {currency} = props;

  return (
    <FormattedNumber
      maximumFractionDigits={2}
      {...props}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currency={currency}
    />
  );
};

export default FormattedCurrency;
