import {useIntl} from 'react-intl';
import {formatErrorMessage} from '../utils/functions';
import {useFetchAppKeyQuery} from '../services/appApi';

export type UseGetAppKeyProps = {
  appId: number;
};

export default function useGetAppKey(props: UseGetAppKeyProps) {
  const {appId} = props;
  // Translations
  const intl = useIntl();

  // app get app keystore data
  const {isSuccess, data, isFetching} = useFetchAppKeyQuery(
    {
      appId,
      formatErrorMessage: error => formatErrorMessage(error, intl),
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !appId,
    }
  );

  return {
    isLoading: isFetching,
    isSuccess,
    data: data,
  };
}
