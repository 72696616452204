import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ProductDetailsFormValues} from '../../hooks/useProductDetail';
import {RootState} from '../store';

/**
 * Default state object with initial values.
 */
type ProductSliceInitialState = {
  name: number;
  description: number;
  optionGroups: number;
  chineseName: number;
  status: number;
  externalId: number;
  image: number;
  price: number;
  categoryId: number;
  sku: number;
};

const initialState: ProductSliceInitialState = {
  name: 0,
  description: 0,
  optionGroups: 0,
  chineseName: 0,
  status: 0,
  externalId: 0,
  image: 0,
  price: 0,
  categoryId: 0,
  sku: 0,
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setField: (
      state,
      action: PayloadAction<{
        key: keyof ProductDetailsFormValues;
        value: number;
      }>
    ) => {
      const {key, value} = action.payload;
      state[key] = value;
    },
    clearInputFields: () => {
      return initialState;
    },
  },
});

export const {setField, clearInputFields} = productSlice.actions;

const countNonEmptyFields = (storeState: ProductSliceInitialState) => {
  let count = 0;
  for (const key in storeState) {
    if (typeof storeState[key as keyof ProductSliceInitialState] === 'number') {
      count += storeState[key as keyof ProductSliceInitialState];
    }
  }
  return count;
};

const productStateSelector = (state: RootState) => state.product;

export const getNonEmptyFields = createSelector(
  [productStateSelector],
  productState => ({
    count: countNonEmptyFields(productState),
  })
);

export default productSlice.reducer;
