import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {useMemo} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  UpdatePasswordParam,
  useUpdatePasswordMutation,
} from '../services/userApi';
import {formatErrorMessage} from '../utils/functions';

type ChangePasswordFormValues = {
  password: string;
  passwordConfirm: string;
};
type UseUpdatePasswordProps = {
  id?: number | string;
  closePasswordModal: () => void;
};

const useUpdatePassword = ({
  id,
  closePasswordModal,
}: UseUpdatePasswordProps) => {
  const intl = useIntl();

  const schema = useMemo(
    () =>
      yup.object().shape({
        password: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .min(
            4,
            intl.formatMessage({id: 'validation.error.invalid_password_length'})
          ),
        passwordConfirm: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .oneOf(
            [yup.ref('password')],
            intl.formatMessage({id: 'validation.error.match_password'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    formState: {errors, submitCount},
  } = useForm<ChangePasswordFormValues>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  const [updatePassword, {isSuccess, isLoading}] = useUpdatePasswordMutation();

  const submit = (formValues: ChangePasswordFormValues) => {
    closePasswordModal();
    const data: UpdatePasswordParam = {
      id,
      body: {
        ...formValues,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'dashboard.users.messages.update_user_success_message',
        });
        return message;
      },
    };
    updatePassword(data);
  };

  return {
    handleSubmit,
    control,
    errors,
    submitCount,
    submit,
    isLoading,
    isSuccess,
  };
};
export default useUpdatePassword;
