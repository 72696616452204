import Typography from '@material-ui/core/Typography';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {ReactNode} from 'react';
import clsx from 'clsx';
import {ReactComponent as ArrowForwardIcon} from '../../assets/images/arrowForward.svg';

export type ArrowLinkProps = {
  /**
   * Primary variant is enabled
   */
  primary?: boolean;
  /**
   * Muted color
   */
  muted?: boolean;
  /**
   * Alternative icon right
   */
  icon?: ReactNode | undefined;
  /**
   * Title
   */
  title: string;
  /**
   * Link address
   */
  url: string;
  className?: string;
  isExternalLink?: boolean;
};

const useStyles = makeStyles<Theme, ArrowLinkProps>(theme => ({
  arrowLink: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'underline',
    },
    '& .MuiTypography-body2': {
      marginRight: 5,
    },
  },
}));

/**
  This component renders a link with small arrow on the right
 */
const ArrowLink = (props: ArrowLinkProps) => {
  const {primary, muted, icon, title, url, className, isExternalLink} = props;
  const classes = useStyles(props);

  // Router
  const navigate = useNavigate();

  // Click handler
  const onClickHandler = (e: any) => {
    e.stopPropagation();
    if (isExternalLink) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  return (
    <Box
      tabIndex={0}
      role="button"
      className={clsx(
        classes.arrowLink,
        {'text-primary': primary},
        {'text-muted': muted},
        className
      )}
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
    >
      {icon ? <span className="d-inline-flex me-1">{icon}</span> : null}
      <Typography variant="body2" className="text-truncate">
        {title}
      </Typography>
      <ArrowForwardIcon />
    </Box>
  );
};

ArrowLink.defaultProps = {
  primary: false,
  icon: undefined,
  className: '',
};

export default ArrowLink;
