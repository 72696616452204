import {OrderDeliveryAddress} from './OrderDeliveryAddress';
import {OrderFee} from './OrderFee';
import {OrderProduct} from './OrderProduct';
import {OrderStatusHistory} from './OrderStatusHistory';
import {OrderUser} from './OrderUser';
import {Payment} from './Payment';
import {ReviewStatus} from './Review';
import {Store} from './Store';

export interface Order {
  id: number;
  status: OrderStatusEnum;
  serviceType: OrderServiceType;
  scheduledTime: string;
  scheduledTimeInMinutes?: number;
  currency: string;
  reference: string;
  additionalInstructions?: string;
  deliveryAddress?: OrderDeliveryAddress;
  vatAmount?: number;
  deliveryFee: number;
  subtotalAmount: number;
  totalAmount: number;
  totalDiscount?: number;
  orderPayments: Payment[];
  orderStatusHistory: OrderStatusHistory[];
  orderFees: OrderFee[];
  orderProducts: OrderProduct[];
  store: Partial<Store>;
  orderUser: OrderUser;
  review?: FormattedReview;
  isPreOrder: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export enum OrderStatusEnum {
  Pending = 'Pending',
  Declined = 'Declined',
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  OutForDelivery = 'OutForDelivery',
  ReadyForPickup = 'ReadyForPickup',
  Completed = 'Completed',
}

export enum OrderServiceType {
  delivery = 'delivery',
  collection = 'collection',
}

export enum OrderStatusRequestEnum {
  Pending = 'pending',
  InProgress = 'in_progress',
  Cancelled = 'cancelled',
  Completed = 'completed',
}

export interface FormattedReview {
  id: number;
  status: ReviewStatus;
  rate: number;
  description: string;
  createdAt: Date;
}
