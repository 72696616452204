import {FormGroup} from 'react-bootstrap';
import CustomSlider from '../../Common/Slider';

const RangeField = ({label, required, ...props}: any) => {
  return (
    <FormGroup className="form-group mb-2">
      <div className="label-with-hint d-flex">
        <span className="small-label pb-1">
          {label}
          {required ? '*' : ''}
        </span>
      </div>
      <CustomSlider {...props} />
    </FormGroup>
  );
};

export default RangeField;
