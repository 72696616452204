import {Typography} from '@material-ui/core';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {Control} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {Col, Row} from 'reactstrap';
import {
  DiscountForm,
  RepeatType,
} from '../../../../hooks/discount/useDiscountFormRequest';
import {DiscountType} from '../../../../types/Discount';
import AutocompleteMultipleController from '../../../Form/HookForm/AutocompleteMultipleController';
import CheckboxGroupController from '../../../Form/HookForm/CheckboxGroupController';
import CurrencyController from '../../../Form/HookForm/CurrencyController';
import DatePickerController from '../../../Form/HookForm/DatePickerController';
import TimeInputController from '../../../Form/HookForm/TimeInputController';
import {OptionProps} from '../../../Form/Inputs/AutocompleteMultiple';

type DiscountValidationsProps = {
  control: Control<DiscountForm>;
  setValue: (name: string, value: string) => void;
  discountRepeat: RepeatType;
  type: string;
  defaultDays: OptionProps[];
  isEdit: boolean;
};

const DiscountValidations = ({
  control,
  setValue,
  discountRepeat,
  type,
  defaultDays,
  isEdit,
}: DiscountValidationsProps) => {
  const intl = useIntl();
  // eslint-disable-next-line prefer-spread
  const weekdayNames: OptionProps[] = Array.apply(null, Array(7)).map(
    (_, i) => ({
      title: `Every ${moment(i, 'e').format('dddd')}`,
      value: i,
    })
  );

  // Repeat week days options
  const [weekDaysOptions, setWeekDaysOptions] =
    useState<OptionProps[]>(weekdayNames);

  // Remove default repeat weekdays options from list on edit
  useEffect(() => {
    if (isEdit && defaultDays.length > 0) {
      setWeekDaysOptions(
        weekdayNames.filter(
          item => !defaultDays.some(day => day.value === item.value)
        )
      );
    }
  }, [isEdit, defaultDays]);

  const items = [
    {
      label: intl.formatMessage({
        id: 'dashboard.discount_details.repeat',
      }),
      value: 'repeat',
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.discount_details.set_end_date',
      }),
      value: 'setEndDate',
    },
  ];

  return (
    <div className="card details-card mb-3">
      <div className="card-body">
        <Typography variant="h4" className="mb-3">
          <FormattedMessage id="dashboard.discount_details.validation" />
        </Typography>
        {/* Start date / time */}
        <Row className="gx-vtl-26">
          <Col lg={6}>
            <DatePickerController
              name="startDate"
              control={control}
              label={intl.formatMessage({
                id: 'dashboard.discount_details.start_date',
              })}
              placeholder={intl.formatMessage({
                id: 'dashboard.discount_details.start_date',
              })}
              formGroupClass="mb-3"
            />
          </Col>
          <Col lg={6}>
            <TimeInputController
              name="startTime"
              setValue={setValue}
              control={control}
              label={intl.formatMessage({
                id: 'dashboard.discount_details.start_time_gmt',
              })}
              formGroupClass="mb-3"
            />
          </Col>
        </Row>
        <CheckboxGroupController
          name="repeatable"
          control={control}
          options={type === DiscountType.OrderAmount ? items : [items[1]]}
          checkboxSize="default"
        />
        {/* Repeat */}
        {discountRepeat && discountRepeat.repeat && (
          <Row className="gx-vtl-26">
            <Col lg={6}>
              <AutocompleteMultipleController
                name="repeatableDays"
                control={control}
                options={weekDaysOptions}
                label={intl.formatMessage({
                  id: 'dashboard.discount_details.repeat',
                })}
                className="mb-3"
              />
            </Col>
          </Row>
        )}
        {/* End date / time */}
        {discountRepeat && discountRepeat.setEndDate && (
          <Row className="gx-vtl-26">
            <Col lg={6}>
              <DatePickerController
                name="endDate"
                control={control}
                label={intl.formatMessage({
                  id: 'dashboard.discount_details.end_date',
                })}
                placeholder={intl.formatMessage({
                  id: 'dashboard.discount_details.end_date',
                })}
              />
            </Col>
            <Col lg={6}>
              <TimeInputController
                name="endTime"
                setValue={setValue}
                control={control}
                label={intl.formatMessage({
                  id: 'dashboard.discount_details.end_time_gmt',
                })}
              />
            </Col>
          </Row>
        )}
        {/* Total amount */}
        <Row className="gx-vtl-26">
          <Col lg={6}>
            <CurrencyController
              name="totalMinOrderAmount"
              control={control}
              label={intl.formatMessage({
                id: 'dashboard.discount_details.total_minimum_order_amount',
              })}
              className="mb-0"
            />
          </Col>
          {type && type === DiscountType.LoyaltyOffer && (
            <Col lg={6}>
              <CurrencyController
                name="totalMinOrderTimesReached"
                control={control}
                label={intl.formatMessage({
                  id: 'dashboard.discount_details.number_times_order_amount_reached',
                })}
                className="mb-0"
                prefixLabel={null}
                allowDecimals={false}
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default DiscountValidations;
