import React from 'react';
import IndicatorDefault from '../../Common/IndicatorDefault';
import IconButton from '../../Common/IconButton';
import {ReactComponent as TrashIcon} from '../../../assets/images/trash.svg';
import {FormattedMessage} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Image, Stack} from 'react-bootstrap';
import {PaymentCard} from '../../../types/PaymentCard';

/**
 * Finance details item
 */
export type FinanceDetailsItemProps = {
  card: PaymentCard;
  onDelete?: () => void;
  onSetAsDefault?: () => void;
};

const FinanceDetailsItem = ({
  card,
  onDelete,
  onSetAsDefault,
}: FinanceDetailsItemProps) => {
  const {
    isDefault = false,
    last4,
    brand,
    expMonth,
    expYear,
    name,
    addressLine1,
    city,
    state,
    country,
    addressZip,
  } = card;
  const type = brand?.toLocaleLowerCase();
  const number = `**** ${last4}`;
  const expiry = `${expMonth}/${expYear}`;
  // Card types icons
  const masterCardSrc = '/images/mastercard.png';
  const visaSrc = '/images/visa.png';

  return (
    <div className="card details-card address-card">
      <div className="card-header">
        <IndicatorDefault isDefault={isDefault} onClick={onSetAsDefault} />
        <div className="btn-toolbar">
          {/* Delete button */}
          <IconButton size="xs" variant="action" onClick={onDelete}>
            <TrashIcon />
          </IconButton>
        </div>
      </div>
      <div className="card-body">
        {/* Card number */}
        <div className="item-row">
          <Typography variant="subtitle2" className="text-muted pb-1">
            <FormattedMessage id="dashboard.user_details.tab_finance.card_number" />
          </Typography>
          <Stack direction="horizontal" gap={2}>
            <Image
              src={type === 'visa' ? visaSrc : masterCardSrc}
              height={type === 'visa' ? 11 : 15}
              width={type === 'visa' ? 34 : 24}
            />
            <Typography>{number}</Typography>
          </Stack>
        </div>
        {/* Expiry date */}
        <div className="item-row">
          <Typography variant="subtitle2" className="text-muted pb-1">
            <FormattedMessage id="dashboard.user_details.tab_finance.expiry_date" />
          </Typography>
          <Typography>{expiry}</Typography>
        </div>
        {/* Card holder */}
        <div className="item-row">
          <Typography variant="subtitle2" className="text-muted pb-1">
            <FormattedMessage id="dashboard.user_details.tab_finance.card_holder" />
          </Typography>
          <Typography>{name || '-'}</Typography>
        </div>
        {/* Billing address */}
        <div className="item-row pb-0">
          <Typography variant="subtitle2" className="text-muted pb-1">
            <FormattedMessage id="dashboard.user_details.tab_finance.billing_address" />
          </Typography>
          <Typography>{addressLine1 || '-'}</Typography>
          {(city || addressZip) && (
            <Typography variant="body2">
              {city}, {addressZip}
            </Typography>
          )}
          {(state || country) && (
            <Typography variant="body2">
              {state ? `${state},` : null} {country}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinanceDetailsItem;
