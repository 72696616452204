import React, {ReactNode} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Tabs, {TabType} from '../Common/Tabs';

/**
 * Tab Widget components
 */

type TabPanelProps = {
  value: string;
  index: string;
  children: ReactNode;
};

const TabPanel = ({children, value, index}: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`nav-tabpanel-${index}`}
    aria-labelledby={`nav-tab-${index}`}
  >
    {value === index && children}
  </div>
);

export type TabWidgetProps = {
  /**
   * Tabs list with children
   */
  tabs: TabType[];
  /**
   * Current tab selected value
   */
  value: string;
  wrapperClass?: string;
  containerClass?: string;
  /**
   * Tab change handler
   */
  onChange?: (v: string) => void;
};

const TabWidget = ({
  tabs,
  value,
  wrapperClass,
  containerClass,
  onChange,
}: TabWidgetProps) => {
  // Router
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const handleChange = (val: string) => {
    navigate(val);
    if (typeof onChange === 'function') {
      onChange(val);
    }
  };

  return (
    <>
      <div className={containerClass}>
        <div className={wrapperClass}>
          <Tabs
            indicators={false}
            value={value || pathname}
            tabs={tabs}
            onChange={handleChange}
          />
        </div>
      </div>
      {tabs.map((tab: TabType, index: number) => {
        return (
          <TabPanel key={index} value={value || pathname} index={tab.value}>
            {tab.children}
          </TabPanel>
        );
      })}
    </>
  );
};

export default TabWidget;
