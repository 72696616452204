import React, {FC, ReactNode} from 'react';
import cx from 'clsx';
import {Draggable, DraggableProps} from 'react-beautiful-dnd';

interface IDrag extends Omit<DraggableProps, 'children'> {
  className?: string;
  children: ReactNode;
  dragAll?: boolean;
}

const Drag: FC<IDrag> = ({className, children, dragAll, ...props}) => {
  if (!React.isValidElement(children)) {
    return <div />;
  }
  return (
    <Draggable {...props}>
      {(provided, snapshot) => {
        const dragHandleProps = dragAll ? provided.dragHandleProps : {};
        return (
          <div
            className={cx(className, {'is-dragging': snapshot.isDragging})}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...dragHandleProps}
          >
            {React.cloneElement(children, {...provided})}
          </div>
        );
      }}
    </Draggable>
  );
};

Drag.defaultProps = {
  dragAll: true,
};

export default Drag;
