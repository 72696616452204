/* eslint-disable react/prop-types */
import React, {Fragment, ReactNode, useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles, Theme} from '@material-ui/core';
import {FormGroup} from 'reactstrap';
import SvgIcon from '@material-ui/core/SvgIcon';
import Select from '@material-ui/core/Select';
import Chip from '../Common/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import BaseInput from './BaseInput';

type OptionType = {
  label: string;
  value: any;
};

export type MultiSelectProps = {
  /**
   * Initial values list
   */
  values: any[];
  /**
   * Show values with tags
   */
  tags: boolean;
  /**
   * Show dropdown icon
   */
  iconDropdown: boolean;
  /**
   * Show only first selected option with number of rest
   */
  reducedList: boolean;
  /**
   * Options possible to select
   */
  options: OptionType[];
  /**
   * Parent change selection callback
   */
  onChange: () => void;

  label?: string;

  renderOption?: (
    option: OptionType,
    index: number,
    values: any[]
  ) => string | ReactNode;

  [key: string]: any;
};

/* Styles */
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  select: {
    '& .MuiSelect-root': {
      boxShadow: theme.shadows[0],
      borderRadius: 4,
      fontWeight: 'normal',
      fontSize: 14,
      paddingTop: 8,
      paddingBottom: 7,
      border: `1px solid ${theme.palette.divider}`,
      height: 'unset',
    },
    '& .MuiSelect-icon': {
      fontSize: 10,
      top: 13,
      right: 15,
    },
  },
  label: {
    color: theme.palette.secondary.light,
    fontSize: 14,
    marginBottom: 0,
    zIndex: 10,
    left: 10,
    top: -14,
    '&.Mui-focused': {
      color: theme.palette.secondary.light,
    },
  },
  menu: {
    marginTop: 8,
    marginLeft: 0,
  },
  paper: {
    boxShadow: theme.shadows[1],
    maxHeight: 321,
    '& > ul': {
      padding: 0,
    },
    '&.cropped': {
      borderRadius: '0 0 8px 8px',
      maxHeight: 'calc(50vh - 50px)',
    },
  },
  menuItem: {
    fontSize: 14,
    padding: '12px 20px',
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.default,
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: -5,
    '& .MuiChip-root + .MuiChip-root': {
      marginLeft: 5,
    },
  },
  list: {
    padding: '1px 0',
  },
  multiSelectItemsBelow: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 10,
    paddingBottom: 10,
    margin: -4,
    '& .MuiChip-root': {
      margin: 4,
    },
  },
  placeholder: {
    color: theme.palette.secondary.light,
  },
}));

//Dropdown icon
const DropdownIcon = (props: any) => (
  <SvgIcon
    viewBox="0 0 9 6"
    className={clsx('MuiSelect-icon', props.disabled && 'text-muted')}
  >
    <g
      id="Filter/Location"
      transform="translate(-191.000000, -246.000000)"
      fill="currentColor"
      stroke="currentColor"
    >
      <path
        d="M197.893917,248.776407 L193.609399,245.086317 C193.467558,244.968335 193.241532,244.971723 193.104545,245.093885 C192.970926,245.213056 192.970926,245.401975 193.104545,245.521128 L197.136658,248.993813 L193.104545,252.466497 C192.965152,252.586569 192.965152,252.781254 193.104545,252.901326 C193.24398,253.021362 193.470005,253.021362 193.609399,252.901326 L197.893917,249.211218 C198.033311,249.091128 198.033311,248.896479 197.893917,248.776407"
        id="Fill-1"
        transform="translate(195.499231, 248.995677) scale(-1, 1) rotate(-270.000000) translate(-195.499231, -248.995677) "
      ></path>
    </g>
  </SvgIcon>
);

/**
 * MultiSelect component with Chips
 */
const MultiSelect = ({
  values = [],
  tags = true,
  placeholder = '',
  className = '',
  options,
  iconDropdown = false,
  reducedList = false,
  label,
  onChange,
  renderOption = (option: OptionType) => option.label,
  ...others
}: MultiSelectProps & any) => {
  //values
  const [currentValues, setCurrentValues] = useState(values);

  const classes = useStyles();

  //change selected items handler
  const handleChange = (event: any) => {
    const {value} = event.target;
    setCurrentValues(value);
    onChange(value);
  };

  //remove selected items handler
  const handleDelete = (remove: any) => {
    //update current values list
    const newValues = currentValues.filter((item: any) => item !== remove);
    setCurrentValues(newValues);
    onChange(newValues);
  };

  // update state when `values` changes
  useEffect(() => {
    setCurrentValues(values);
  }, [values]);

  return (
    <FormGroup className={className}>
      {label && <span className="small-label pb-2">{label}</span>}
      <Select
        {...others}
        fullWidth
        displayEmpty
        variant="filled"
        className={classes.select}
        value={currentValues}
        multiple
        input={<BaseInput value="" />}
        IconComponent={() => (!iconDropdown ? null : <DropdownIcon />)}
        MenuProps={{
          anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
          getContentAnchorEl: null,
          autoFocus: false,
          classes: {
            paper: classes.paper,
          },
        }}
        renderValue={(selected: any[]) => (
          <div className={tags ? classes.chips : classes.list}>
            {(!selected || selected.length === 0) && (
              <span className={classes.placeholder}>{placeholder}</span>
            )}
            {selected &&
              selected.length > 0 &&
              selected.map((value, index: number) => {
                const item =
                  options.find((item: OptionType) => item.value === value) ||
                  {};
                return (
                  <Fragment key={value}>
                    {((reducedList && index === 0) || !reducedList) &&
                      (tags ? (
                        <Chip
                          key={value}
                          label={item.label}
                          onMouseDown={e => e.stopPropagation()}
                          // TODO: Please fix this onDelete issue
                          onDelete={() => handleDelete(item.value)}
                        />
                      ) : (
                        <span key={value}>
                          {index === selected.length - 1
                            ? item.label
                            : `${item.label}, `}
                        </span>
                      ))}
                  </Fragment>
                );
              })}
            {/* show number of rest */}
            {reducedList &&
              selected.length > 1 &&
              (tags ? (
                <Chip
                  label={`+${selected.length - 1}`}
                  onMouseDown={e => e.stopPropagation()}
                />
              ) : (
                <span>{`+${selected.length - 1}`}</span>
              ))}
          </div>
        )}
        onChange={handleChange}
      >
        {options.map((option: OptionType, key: number) => (
          <MenuItem key={key} value={option.value} className={classes.menuItem}>
            {renderOption(option, key, values)}
          </MenuItem>
        ))}
      </Select>
    </FormGroup>
  );
};

export default MultiSelect;
