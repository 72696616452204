import {useIntl} from 'react-intl';
import {useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  SellerParams,
  useCreateSellerAccountMutation,
} from '../services/sellerApi';
import {
  formatErrorMessage,
  isApiValidationError,
  mapErrorToFormFields,
  phoneRegExp,
} from '../utils/functions';
import {OptionProps} from '../components/Form/Inputs/AutocompleteMultiple';
import {CustomError} from '../types/CustomError';

type FormData = {
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  email: string;
  stores?: OptionProps[];
};

const useCreateSellerAccount = () => {
  // translations
  const intl = useIntl();

  // validate form fields
  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .email(intl.formatMessage({id: 'validation.error.invalid_email'})),
        firstName: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        lastName: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        phone: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .matches(
            phoneRegExp,
            intl.formatMessage({id: 'validation.error.invalid_phone'})
          ),
        password: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .min(
            4,
            intl.formatMessage({id: 'validation.error.invalid_password_length'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    setError,
    reset,
    resetField,
    formState: {errors, submitCount},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  // create seller api service
  const [
    createSellerAccount,
    {isSuccess, isLoading, data, error: createSellerError},
  ] = useCreateSellerAccountMutation();

  // create seller account
  const onSubmit = async (formData: FormData) => {
    let storeIds: (string | number)[] = [];
    if (formData.stores && formData.stores.length > 0) {
      storeIds = formData.stores.map((store: OptionProps) => store.value);
      delete formData.stores;
    }

    const data: SellerParams = {
      body: {
        ...formData,
        stores: storeIds,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'dashboard.users.messages.user_created',
        });
      },
    };
    createSellerAccount(data);
  };

  // Format api validation errors to input field
  useEffect(() => {
    if (createSellerError) {
      const typedUpdateError = createSellerError as CustomError;
      if (isApiValidationError(typedUpdateError)) {
        mapErrorToFormFields(typedUpdateError.data.message, setError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSellerError]);

  // reset form fields after saving
  useEffect(() => {
    if (isSuccess) {
      reset();
      resetField('stores');
    }
  }, [isSuccess]);

  return {
    onSubmit,
    isSuccess,
    isLoading,
    data,
    control,
    handleSubmit,
    submitCount,
    errors,
  };
};

export default useCreateSellerAccount;
