import {FormGroup, InputGroup} from 'react-bootstrap';
import cx from 'clsx';
import {Typography} from '@material-ui/core';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import CurrencyInput, {CurrencyInputProps} from 'react-currency-input-field';
import {InputGroupText} from 'reactstrap';
import {ReactNode} from 'react';

export type CurrencyControllerProps<T extends FieldValues> = Omit<
  CurrencyInputProps,
  'control' | 'value' | 'onChange' | 'onBlur'
> & {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  required?: boolean;
  prefixLabel?: string | ReactNode | null;
  prefixRight?: boolean;
  allowDecimals?: boolean;
  allowNegativeValue?: boolean;
  className?: string;
};

const CurrencyController = <T extends FieldValues>({
  name,
  control,
  label,
  required = false,
  className = '',
  prefixLabel = '£',
  prefixRight = false,
  allowDecimals = true,
  allowNegativeValue = false,
  maxLength,
  defaultValue,
  ...currencyFieldProps
}: CurrencyControllerProps<T>) => {
  return (
    <Controller
      name={name}
      defaultValue={
        defaultValue as PathValue<T, (string | undefined) & Path<T>>
      }
      control={control}
      render={({field: {value, onChange, onBlur}, fieldState: {error}}) => {
        const touched = !!error;
        const onInputChange = (val: any) => {
          if (Number(val) !== value) {
            onChange(val ?? null); // last value was not deleting
          }
        };

        return (
          <FormGroup
            className={cx('form-group', className, {
              'form-group-invalid': touched && error,
            })}
            style={{position: 'relative'}}
          >
            {label && (
              <span className="small-label pb-2">
                {label}
                {required ? '*' : ''}
              </span>
            )}
            <InputGroup className="currency-input-group">
              {!prefixRight && prefixLabel && (
                <InputGroupText className="border-end-0 me-auto">
                  {prefixLabel}
                </InputGroupText>
              )}
              <CurrencyInput
                {...currencyFieldProps}
                className={cx('form-control', {
                  'border-start-0 ms-auto': prefixLabel && !prefixRight,
                  'border-end-0': prefixLabel && prefixRight,
                  'prefix-right': prefixRight,
                })}
                value={value ?? ''}
                onValueChange={value => onInputChange(value)}
                onBlur={onBlur}
                allowDecimals={allowDecimals}
                allowNegativeValue={allowNegativeValue}
                decimalsLimit={2}
                decimalSeparator="."
                decimalScale={allowDecimals ? 2 : 0}
                maxLength={maxLength}
              />
              {prefixRight && prefixLabel && (
                <InputGroupText className="px-vtl-6 border-start-0 ms-auto">
                  {prefixLabel}
                </InputGroupText>
              )}
            </InputGroup>
            {touched && error && (
              <Typography variant="subtitle2" style={{lineHeight: 'normal'}}>
                {String(error?.message ?? 'Error text')}
              </Typography>
            )}
          </FormGroup>
        );
      }}
    />
  );
};

export default CurrencyController;
