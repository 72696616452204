import React from 'react';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from '../../../store/slices/authSlice';

type PermissionContainerProps = {
  permissions: string[];
} & React.PropsWithChildren;

const PermissionContainer = (props: PermissionContainerProps) => {
  const {user} = useSelector(selectUserAndToken);

  const hasPermission = (permission: string) => {
    return user?.permissions?.some(item => item.slug === permission);
  };

  const hasPermissions = (allowedPermissionss: string[]) => {
    for (const permission of allowedPermissionss) {
      if (!hasPermission(permission)) {
        return false;
      }
    }
    return true;
  };

  if (!hasPermissions(props.permissions)) {
    return null;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default PermissionContainer;
