import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import {Theme} from '@material-ui/core';

export type CustomChipProps = {
  key?: any;
  /**
   * Tag text
   */
  label: string;
  /**
   * Delete button click callback
   */
  onDelete?: () => void;
  /** */
  onMouseDown?: (event: any) => void;
  /**
   * TRUE if is full width
   */
  fullWidth?: boolean;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.green.light,
    color: theme.palette.green.main,
    fontSize: 12,
    fontFamily: 'Averta Semibold',
    height: 20,
    borderRadius: 15,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'space-between',
  },
  label: {
    paddingLeft: 0,
    paddingRight: 0,
    overflow: 'visible',
    textOverflow: 'clip',
  },
  deleteIcon: {
    marginRight: 0,
    marginLeft: 13,
    width: 14,
    height: 14,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='%2321B59B' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E\")",
  },
}));

/**
 * Tag chip component
 */
const CustomChip = ({
  label,
  onDelete,
  fullWidth = false,
  className,
  ...custom
}: CustomChipProps) => {
  const classes = useStyles();
  return (
    <Chip
      classes={classes}
      className={clsx(fullWidth && 'd-flex', className)}
      deleteIcon={<span className={classes.deleteIcon} />}
      label={label}
      onDelete={onDelete}
      {...custom}
    />
  );
};

export default CustomChip;
