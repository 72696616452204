import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {DynamicFilterProps} from '../../components/Form/DynamicFilter';
import {StoreStatus} from '../../utils/status';
import useLocationList from '../useLocationList';

export type UseStoreFilterReturn = {
  items: DynamicFilterProps<any>['items'];
};

export default function useStoreFilter(): UseStoreFilterReturn {
  const intl = useIntl();

  const {locations} = useLocationList({type: 'store'});

  const items = useMemo(() => {
    const res: UseStoreFilterReturn['items'] = [];

    res.push({
      label: intl.formatMessage({id: 'dashboard.users.location'}),
      name: 'location',
      options: locations,
      className: 'mb-0',
      type: 'autocomplete-multiple',
    });

    res.push({
      label: intl.formatMessage({id: 'dashboard.users.status'}),
      type: 'checkbox-group',
      name: 'status',
      checkboxSize: 'default',
      formControlLabelClass: 'checkbox-filter-row',
      options: [
        {
          label: intl.formatMessage({id: 'dashboard.status.open'}),
          value: StoreStatus.Open,
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.onhold'}),
          value: StoreStatus.OnHold,
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.closed'}),
          value: StoreStatus.Closed,
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.closed_today'}),
          value: StoreStatus.ClosedForToday,
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.suspended'}),
          value: StoreStatus.Suspended,
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.preorder'}),
          value: StoreStatus.PreOrderAvailable,
        },
      ],
    });

    return res;
  }, [locations]);

  return {items};
}
