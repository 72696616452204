import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import {HTMLProps, ReactNode} from 'react';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import CustomCheckbox, {CustomCheckboxProps} from '../../Common/Checkbox';

export type CheckboxGroupControllerProps<T extends FieldValues> =
  HTMLProps<HTMLDivElement> & {
    name: Path<T>;
    control: Control<T>;
    options: any[];
    formControlLabelClass?: string;
    checkboxSize?: CustomCheckboxProps['size'];
    groupClassName?: string;
    extraChild?: string | ReactNode;
    labelField?: string;
    valueField?: string;
    label?: string;
    excludeLabel?: boolean;
    formGroupClass?: string;
  };

const CheckboxGroupController = <T extends FieldValues>({
  name,
  label,
  excludeLabel,
  formGroupClass,
  control,
  labelField = 'label',
  valueField = 'value',
  options,
  groupClassName,
  extraChild,
  checkboxSize,
  formControlLabelClass,
  ...divProps
}: CheckboxGroupControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field: {value, onChange}, fieldState: {error}}) => {
        const touched = !!error;

        const onValueChange = (checkboxValue: any) => {
          // sometimes, the given value is a boolean
          const groupValue: any =
            value && Array.isArray(value) ? value : {...value};
          let newValue;
          if (groupValue && Array.isArray(groupValue)) {
            if (value.includes(checkboxValue)) {
              newValue = groupValue.filter(option => option !== checkboxValue);
            } else {
              newValue = [...groupValue, checkboxValue];
            }
          } else {
            groupValue[checkboxValue] = !groupValue[checkboxValue];
            newValue = groupValue;
          }

          onChange(newValue);
        };

        return (
          <div>
            <FormControl
              className={clsx(
                'form-group checkbox-group',
                formGroupClass ? formGroupClass : 'mb-3 mb-lg-fg'
              )}
              error={touched && !!error}
            >
              {label && !excludeLabel && (
                <span className="small-label pb-2">{label}</span>
              )}
              <div className={groupClassName} {...divProps}>
                {options &&
                  options.map((option: any, key: number) => {
                    // Test if option should be checked
                    const checked = !value
                      ? false
                      : Array.isArray(value)
                      ? value.includes(option.value)
                      : value[option.value];

                    return (
                      <FormControlLabel
                        key={key}
                        className={formControlLabelClass}
                        label={
                          <Typography variant="body2">
                            {option[labelField]}
                          </Typography>
                        }
                        control={
                          <CustomCheckbox
                            value={option[valueField]}
                            size={checkboxSize}
                            checked={checked}
                            onChange={() => onValueChange(option[valueField])}
                            inputProps={{
                              name: name,
                            }}
                          />
                        }
                      />
                    );
                  })}
                {extraChild}
              </div>
              {touched && error && (
                <FormHelperText>{String(error.message)}</FormHelperText>
              )}
            </FormControl>
          </div>
        );
      }}
    />
  );
};

CheckboxGroupController.defaultProps = {
  labelField: 'label',
  valueField: 'value',
  checkboxSize: 'small',
  formControlLabelClass: 'checkbox',
};

export default CheckboxGroupController;
