export type DiscountAttributeType = {
  value: string;
  propertyName: string;
  entityName: string;
  conditions: string[];
  conditionType: string;
};

const conditionTypes = {
  string: ['is_one_of', 'is_not_one_of'],
  numeric: [
    'is_equal_to',
    'is_not_equal_to',
    'is_less_than',
    'is_greater_than',
    'is_between',
    'is_not_between',
  ],
  date: [
    'is_equal_to',
    'is_not_equal_to',
    'is_after',
    'is_before',
    'is_between',
    'is_not_between',
  ],
  boolean: ['is_selected', 'is_unselected'],
};

const productAttributes: DiscountAttributeType[] = [
  {
    value: 'product_name_en',
    propertyName: 'name',
    entityName: 'product',
    conditionType: 'string',
    conditions: conditionTypes['string'],
  },
  {
    value: 'product_name_ch',
    propertyName: 'chineseName',
    entityName: 'product',
    conditionType: 'string',
    conditions: conditionTypes['string'],
  },
  {
    value: 'product_price',
    propertyName: 'price',
    entityName: 'product',
    conditionType: 'numeric',
    conditions: conditionTypes['numeric'],
  },
  {
    value: 'product_sku',
    propertyName: 'sku',
    entityName: 'product',
    conditionType: 'string',
    conditions: conditionTypes['string'],
  },
  {
    value: 'product_external_id',
    propertyName: 'externalId',
    entityName: 'product',
    conditionType: 'string',
    conditions: conditionTypes['string'],
  },
  {
    value: 'product_category',
    propertyName: 'name',
    entityName: 'product_category',
    conditionType: 'string',
    conditions: conditionTypes['string'],
  },
  {
    value: 'product_option',
    propertyName: 'name',
    entityName: 'product_option_group',
    conditionType: 'string',
    conditions: conditionTypes['string'],
  },
];

const buyerAttributes: DiscountAttributeType[] = [
  {
    value: 'buyer_email',
    propertyName: 'email',
    entityName: 'user',
    conditionType: 'string',
    conditions: conditionTypes['string'],
  },
  {
    value: 'account_creation_date',
    propertyName: 'createdAt',
    entityName: 'user',
    conditionType: 'date',
    conditions: conditionTypes['date'],
  },
  {
    value: 'total_buyer_orders',
    propertyName: 'name',
    entityName: 'order',
    conditionType: 'numeric',
    conditions: conditionTypes['numeric'],
  },
];

export const discountAttributes = {
  product: productAttributes,
  buyer: buyerAttributes,
};
