import {Typography} from '@material-ui/core';
import {FormattedMessage, useIntl} from 'react-intl';
import {DevTool} from '@hookform/devtools';
import {Form, Row, Col} from 'reactstrap';
import Switch from '../../Common/Switch';
import InputController from '../../Form/HookForm/InputController';
import PhoneController from '../../Form/HookForm/PhoneController';
import TextAreaController from '../../Form/HookForm/TextAreaController';
import useCreateBuyerAccount from '../../../hooks/useCreateBuyerAccount';
import AddressAutoComplete from '../../Form/AddressAutoComplete';
import {useEffect} from 'react';

type CreateSellerFormProps = {
  form: string;
  closePopup: () => void;
};

const CreateBuyerForm = ({form, closePopup}: CreateSellerFormProps) => {
  // Translations
  const intl = useIntl();

  const {
    handleSubmit,
    onSubmit,
    control,
    isSuccess,
    updateAddressValue,
    addressError,
    address,
    updateAddressError,
    setEnteredValue,
    handleNotificationChange,
    enableNotification,
  } = useCreateBuyerAccount();

  // close popup
  useEffect(() => {
    if (isSuccess) {
      closePopup();
    }
  }, [isSuccess]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id={form}>
      {/* First name */}
      <InputController
        name="firstName"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.user_details.first_name',
        })}
        required
      />
      {/* Last name */}
      <InputController
        name="lastName"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.user_details.last_name',
        })}
        required
      />
      {/* Email address */}
      <InputController
        name="email"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.users.email_address',
        })}
        required
      />
      {/* Password */}
      <InputController
        name="password"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.users.password',
        })}
        required
        isPassword
      />
      {/* Phone */}
      <PhoneController
        name="phone"
        control={control}
        label={intl.formatMessage({id: 'dashboard.users.phone'})}
        required
        phoneInputProps={{international: true}}
      />
      {/* Address line 1 */}
      {/* Autocomplete suggested address */}
      <AddressAutoComplete
        name="address"
        label={`${intl.formatMessage({id: 'dashboard.users.address'})}*`}
        setAddress={updateAddressValue}
        updateAddressError={updateAddressError}
        addressError={addressError}
        address={address}
        setEnteredValue={setEnteredValue}
        isSuccess={isSuccess}
      />

      <Row className="gx-2">
        <Col lg={6}>
          {/* City */}
          <InputController
            name="city"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.users.city',
            })}
            required
            disabled
          />
        </Col>
        <Col lg={6}>
          {/* Postcode */}
          <InputController
            name="postCode"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.users.postcode',
            })}
            required
            disabled
          />
        </Col>
      </Row>
      {/* Delivery Instruction */}
      <TextAreaController
        name="deliveryInstruction"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.users.delivery_instruction',
        })}
      />
      {/* Offer Notification */}
      <Row>
        <Col>
          <Typography variant="subtitle2" className="text-muted pb-5">
            <FormattedMessage id="dashboard.users.notify_offers" />
          </Typography>
        </Col>
        <Col xs="auto">
          <Switch
            name="enableNotification"
            id="custom-switch"
            onChange={handleNotificationChange}
            checked={enableNotification}
          />
        </Col>
      </Row>
      <DevTool control={control} placement="top-left" />
    </Form>
  );
};

export default CreateBuyerForm;
