import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Col, Container, Row, Stack} from 'react-bootstrap';
import Modal from '../Widgets/Modal';
import OrderDetail from './Details/OrderDetail';
import CostBreakdown from './Details/CostBreakdown';
import BuyerInformation from './Details/BuyerInformation';
import OrderSummary from './Details/OrderSummary';
import DeclineForm from './Details/DeclineForm';
import AcceptForm from './Details/AcceptForm';
import CancelForm from './Details/CancelForm';
import {useParams} from 'react-router-dom';
import useOrderDetails from '../../hooks/order/useOrder';
import OrderHeader from './Details/OrderHeader';
import {OrderServiceType, OrderStatusEnum} from '../../types/Order';
import ConfirmationPopup from '../Shared/ConfirmationPopup';
import useReviewDetails from '../../hooks/useReviewDetails';
import {ReviewStatus} from '../../types/Review';

/**
 * Order details
 */
const OrderDetails = () => {
  const intl = useIntl();
  const {orderId} = useParams();

  const [acceptPopup, setAcceptPopup] = useState(false);
  const [declinePopup, setDeclinePopup] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newStatus, setNewStatus] = useState<OrderStatusEnum>();

  const closePopup = () => {
    setAcceptPopup(false);
    setDeclinePopup(false);
    setCancelPopup(false);
  };

  const {
    orderDetails,
    updateOrderStatus,
    control,
    formState,
    addTenMins,
    subtractTenMins,
    handleSubmit,
    onAccept,
    onDecline,
    onCancel,
    refetch,
    setValue,
  } = useOrderDetails({
    id: Number(orderId),
    closePopup,
  });

  const setFormValue = (name: string, value: any) => {
    setValue(name as any, value, {shouldDirty: true});
  };
  const {updateReviewStatus, isSuccess: isReviewUpdateSuccess} =
    useReviewDetails();
  const handleReviewUpdateStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!orderDetails?.review) {
      return;
    }
    const newStatus = event.target.checked
      ? ReviewStatus.Public
      : ReviewStatus.Private;
    updateReviewStatus(orderDetails?.review.id, newStatus);
  };

  const openConfirmationModel = (status: OrderStatusEnum) => {
    setNewStatus(status);
    setOpenModal(true);
  };

  const confirmAction = () => {
    if (!newStatus) {
      return;
    }

    updateOrderStatus(newStatus);
    setOpenModal(false);
  };

  useEffect(() => {
    if (isReviewUpdateSuccess) {
      refetch();
    }
  }, [isReviewUpdateSuccess]);

  if (!orderDetails) {
    return <></>;
  }

  const isDelivery = orderDetails.serviceType === OrderServiceType.delivery;
  const isPreOrder = orderDetails.isPreOrder;

  const deliveryText = isPreOrder ? 'PRE-ORDER' : orderDetails.scheduledTime;

  const confirmationTitle = {
    [OrderStatusEnum.Cancelled]: intl.formatMessage({
      id: 'order_details.actions_confirm.cancel_order',
    }),
    [OrderStatusEnum.Completed]: intl.formatMessage({
      id: 'order_details.actions_confirm.set_completed',
    }),
    [OrderStatusEnum.OutForDelivery]: intl.formatMessage({
      id: 'order_details.actions_confirm.set_out_delivery',
    }),
    [OrderStatusEnum.Pending]: intl.formatMessage({
      id: 'order_details.actions_confirm.set_pending',
    }),
    [OrderStatusEnum.ReadyForPickup]: intl.formatMessage({
      id: 'order_details.actions_confirm.set_ready_pickup',
    }),
    [OrderStatusEnum.Declined]: intl.formatMessage({
      id: 'order_details.actions_confirm.decline_order',
    }),
    [OrderStatusEnum.Accepted]: intl.formatMessage({
      id: 'order_details.actions_confirm.accept_order',
    }),
  };

  return (
    <>
      {/* Confirmation popup */}
      {newStatus && (
        <ConfirmationPopup
          opened={openModal}
          handleClose={() => setOpenModal(false)}
          onOk={confirmAction}
          title={confirmationTitle[newStatus]}
          description={' '}
        />
      )}
      {/* Accept popup */}
      <Modal
        opened={acceptPopup}
        onOk={handleSubmit(onAccept)}
        handleClose={() => setAcceptPopup(false)}
        title={intl.formatMessage(
          {id: 'order_details.acceptOrder.title'},
          {delivery: deliveryText}
        )}
        saveTitle={intl.formatMessage({id: 'actions.confirm'})}
      >
        <AcceptForm
          isInMinutes={!isPreOrder}
          isDelivery={isDelivery}
          control={control}
          addTenMins={addTenMins}
          subtractTenMins={subtractTenMins}
          setValue={setFormValue}
          formState={formState}
        />
      </Modal>
      {/* Decline popup */}
      <Modal
        opened={declinePopup}
        onOk={handleSubmit(onDecline)}
        handleClose={() => setDeclinePopup(false)}
        title={intl.formatMessage({id: 'order_details.declineOrder.title'})}
        saveTitle={intl.formatMessage({id: 'actions.confirm'})}
      >
        <DeclineForm control={control} />
      </Modal>
      {/* Cancel popup */}
      <Modal
        opened={cancelPopup}
        onOk={handleSubmit(onCancel)}
        handleClose={() => setCancelPopup(false)}
        title={intl.formatMessage({id: 'order_details.cancelOrder.title'})}
        saveTitle={intl.formatMessage({id: 'actions.confirm'})}
      >
        <CancelForm control={control} />
      </Modal>
      <div className="details-page">
        <OrderHeader
          data={orderDetails}
          showAcceptPopup={() => setAcceptPopup(true)}
          showDeclinePopup={() => setDeclinePopup(true)}
          showCancelPopup={() => setCancelPopup(true)}
          openConfirmationModel={openConfirmationModel}
        />
        <div role="tabpanel">
          <Container fluid>
            <div className="content-block">
              <Row className="g-3">
                <Col lg={7} xxl={6}>
                  <OrderDetail
                    data={orderDetails}
                    isInMinutes={!isPreOrder}
                    isDelivery={isDelivery}
                    control={control}
                    addTenMins={addTenMins}
                    subtractTenMins={subtractTenMins}
                    handleReviewStatusUpdate={handleReviewUpdateStatusChange}
                    setValue={setFormValue}
                  />
                </Col>
                <Col lg={5} xxl={4}>
                  <Stack className="gap-vtl-16 gap-3">
                    <CostBreakdown data={orderDetails} />
                    <BuyerInformation data={orderDetails.orderUser} />
                    <OrderSummary data={orderDetails} />
                  </Stack>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
