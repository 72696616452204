import {Col, Row} from 'reactstrap';
import {Stack} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import {ReactComponent as IconAdd} from '../../assets/images/add.svg';
import DataTable from '../Widgets/DataTable';
import useSellerList from '../../hooks/useSellerList';
import useSellerFilter from '../../hooks/user/useSellerFilter';
import DynamicFilter from '../Form/DynamicFilter';
import KeywordSearch from '../Form/KeywordSearch';
import cx from 'clsx';
import {ReactComponent as StoreIcon} from '../../assets/images/store.svg';
import ArrowLink from '../../components/Common/ArrowLink';
import {HeadCellType} from '../../components/Widgets/DataTable/DataTableHead';
import StatusIndicator from '../../components/Common/StatusIndicator';
import * as urls from '../../constants/urls';
import {SellerData} from '../../types/SellerData';
import {capitalizeFirstLetter} from '../../utils/functions';

/**
 * Seller List
 */
const SellerList = ({onAddClick}: any) => {
  const intl = useIntl();
  const {
    // isSuccess,
    isLoading,
    data,
    handleRowClick,
    meta,
    tableState,
  } = useSellerList();

  const {items} = useSellerFilter();

  // status indicator
  const statusColor: any = {
    active: 'green',
    suspended: 'secondary',
    closed: 'error',
    delete: 'error',
    pending: 'orange',
    closedfortoday: 'orange',
  };

  const renderFullName = (val: any, cn: any, rowData: any) => {
    const {row}: {row: SellerData} = rowData;

    return (
      <div className={cx({'py-2': row?.stores?.length > 0})}>
        <Stack gap={1}>
          <p>
            {row?.firstName} {row?.lastName}
          </p>
          {row?.stores?.map(store => (
            <span key={store?.id} onClick={e => e.stopPropagation()}>
              <ArrowLink
                muted
                icon={<StoreIcon />}
                title={store?.name}
                url={urls.STORE_DETAILS_PATH.replace(
                  ':storeId',
                  `${store.id}/general`
                )}
              />
            </span>
          ))}
        </Stack>
      </div>
    );
  };

  const renderEmail = (a: any, b: any, rowData: any) => {
    const {row}: {row: SellerData} = rowData;

    return (
      <div className={cx({'pb-2': row?.stores?.length > 0})}>
        <Stack gap={1}>
          <p>{row?.email}</p>
          {row?.stores?.map(store => (
            <p key={store.id} className="text-muted">
              {store.email}
            </p>
          ))}
        </Stack>
      </div>
    );
  };

  const renderLocation = (a: any, b: any, rowData: any) => {
    const {row}: {row: SellerData} = rowData;
    return (
      <div className={cx({'pb-2': row?.stores?.length > 0})}>
        <Stack gap={1}>
          <p className="invisible d-none d-lg-block">fill</p>
          {row?.stores?.map(store => (
            <p key={store.id} className="text-muted">
              {store.address?.city}
            </p>
          ))}
        </Stack>
      </div>
    );
  };

  const renderStatus = (a: any, b: any, rowData: any) => {
    const {row}: {row: SellerData} = rowData;

    return (
      <Stack gap={1}>
        <StatusIndicator
          label={capitalizeFirstLetter(row.status?.toLocaleLowerCase())}
          color={statusColor[row.status]}
        />
        {row?.stores?.map(store => (
          <StatusIndicator
            key={store.id}
            label={capitalizeFirstLetter(store.status)}
            color={statusColor[store.status?.toLocaleLowerCase()]}
          />
        ))}
      </Stack>
    );
  };

  // Header cells items
  const HeaderCells: HeadCellType[] = [
    {
      name: 'fullName',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.users.full_name'}),
      collapse: false,
      width: '33%',
      renderValue: (row: any, a, b) => renderFullName(row, a, b),
      sortable: true,
    },
    {
      name: 'email',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.users.email_address'}),
      collapse: true,
      width: '30%',
      renderValue: (row: any, a, b) => renderEmail(row, a, b),
      sortable: true,
    },
    {
      name: 'location',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.users.location'}),
      collapse: true,
      width: '25%',
      renderValue: (row: any, a, b) => renderLocation(row, a, b),
    },
    {
      name: 'status',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.users.status'}),
      collapse: false,
      renderValue: (row: any, a, b) => renderStatus(row, a, b),
    },
  ];

  return (
    <div className="dashboard-content">
      <Row className="align-items-center justify-content-between search-row">
        <Col className="col-xl-5 col-lg-8 d-flex">
          {/* Search */}
          <KeywordSearch
            placeholder={intl.formatMessage({
              id: 'actions.searchNameEmail',
            })}
            resetPageOnChange
          />
        </Col>
        {/* Button toolbar */}
        <Col className="col-auto d-lg-block ps-0 ps-lg-2">
          <Stack direction="horizontal" gap={2}>
            <DynamicFilter items={items} bindQueryParams resetPageOnFilter />
          </Stack>
        </Col>
      </Row>
      {/* Data table */}
      <DataTable
        className="user-list buyer"
        empty={false}
        canSelect={false}
        loading={isLoading}
        onRowClick={handleRowClick}
        headCells={HeaderCells}
        actionButton={{
          variant: 'action',
          size: 'sm',
          children: <IconAdd />,
          onClick: onAddClick,
        }}
        rows={data ?? []}
        onChange={() => {}}
        total={meta ? meta.itemCount : 100}
        handleParameterChange={tableState.handleDataTableParamChange}
        defaultOrder={tableState.orderDirection.toLowerCase() as any}
        defaultOrderBy={tableState.orderBy}
        defaultRowPerPage={tableState.pageSize}
        currentPage={tableState.page}
      />
    </div>
  );
};

export default SellerList;
