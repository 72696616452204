import {Button} from 'react-bootstrap';
import {useAppDispatch} from '../../../store/hooks';
import {
  hideProgressDialog,
  showProgressDialog,
} from '../../../store/slices/appSlice';

const ProgressPlayground = () => {
  const times = [1, 2, 5, 10, 20, 50, 100];
  const dispatch = useAppDispatch();

  const handleClick = (time: number) => {
    dispatch(showProgressDialog());
    setTimeout(() => dispatch(hideProgressDialog()), time * 1000);
  };

  return (
    <div>
      <h2>Progress Dialog</h2>
      <div>
        {times.map(time => (
          <Button key={time} onClick={() => handleClick(time)} className="me-2">
            Show progress for {time}s
          </Button>
        ))}
      </div>
    </div>
  );
};

export default ProgressPlayground;
