import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {GeneralFormValues} from '../../hooks/store/useStoreDetailFormRequest';
import {RootState} from '../store';

/**
 * Default state object with initial values.
 */
type StoreSliceInitialState = {
  name: number;
  description: number;
  sellers: number;
  email: number;
  phone: number;
  storeUrl: number;
  externalStoreUrl: number;
  paymentMethod: number;
  orderRejectionNote: number;
  news: number;
  image: number;
  city: number;
  postCode: number;
  googleAppUrl: number;
  appleAppUrl: number;
  storeTypes: number;
};

const initialState: StoreSliceInitialState = {
  name: 0,
  description: 0,
  sellers: 0,
  email: 0,
  phone: 0,
  storeUrl: 0,
  externalStoreUrl: 0,
  paymentMethod: 0,
  orderRejectionNote: 0,
  news: 0,
  image: 0,
  city: 0,
  postCode: 0,
  googleAppUrl: 0,
  appleAppUrl: 0,
  storeTypes: 0,
};

export const storeSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    setField: (
      state,
      action: PayloadAction<{
        key: keyof GeneralFormValues;
        value: number;
      }>
    ) => {
      const {key, value} = action.payload;
      state[key] = value;
    },
    clearInputFields: () => {
      return initialState;
    },
  },
});

export const {setField, clearInputFields} = storeSlice.actions;

const countNonEmptyFields = (storeState: StoreSliceInitialState) => {
  let count = 0;
  for (const key in storeState) {
    if (typeof storeState[key as keyof StoreSliceInitialState] === 'number') {
      count += storeState[key as keyof StoreSliceInitialState];
    }
  }
  return count;
};

const storeStateSelector = (state: RootState) => state.store;

export const getNonEmptyFields = createSelector(
  [storeStateSelector],
  storeState => ({
    count: countNonEmptyFields(storeState),
  })
);

export default storeSlice.reducer;
