import {FormattedMessage, useIntl} from 'react-intl';
import {Control, useWatch} from 'react-hook-form';
import {Col, Row} from 'reactstrap';
import {Typography} from '@material-ui/core';
import CheckboxController from '../../../Form/HookForm/CheckboxController';
import {Stack} from 'react-bootstrap';
import CurrencyController from '../../../Form/HookForm/CurrencyController';
import {DiscountType} from '../../../../types/Discount';
import {DiscountForm} from '../../../../hooks/discount/useDiscountFormRequest';

type UsageLimitsProps = {
  control: Control<DiscountForm>;
};

const UsageLimits = ({control}: UsageLimitsProps) => {
  const intl = useIntl();
  const [limitDiscountUsage, type] = useWatch({
    control,
    name: ['limitDiscountUsage', 'type'],
  });

  return (
    <div className="card details-card mb-3">
      <div className="card-body">
        <Typography variant="h4" className="mb-3">
          <FormattedMessage id="dashboard.discount_details.usage_limits" />
        </Typography>
        <Stack className="gap-vtl-8">
          {type && type === DiscountType.OrderAmount && (
            <Row className="gx-0 gy-3 align-items-lg-center">
              <Col lg={7}>
                <CheckboxController
                  name="limitDiscountUsage"
                  control={control}
                  label={intl.formatMessage({
                    id: 'dashboard.discount_details.limit_number_times_discount',
                  })}
                  size="default"
                />
              </Col>
              {limitDiscountUsage && type === DiscountType.OrderAmount && (
                <Col lg={5}>
                  <CurrencyController
                    name="usageLimit"
                    control={control}
                    prefixLabel={null}
                    allowDecimals={false}
                    className="mb-0"
                  />
                </Col>
              )}
            </Row>
          )}
          <CheckboxController
            name="singleUse"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.discount_details.limit_one_use_buyer',
            })}
            size="default"
          />
          <CheckboxController
            name="blockPromotion"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.discount_details.block_other_promotions',
            })}
            size="default"
          />
        </Stack>
      </div>
    </div>
  );
};

export default UsageLimits;
