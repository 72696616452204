import {useListFeeQuery} from '../services/feeApi';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {formatErrorMessage} from '../utils/functions';

export default function useFeeList() {
  const {storeId} = useParams();

  // Translations
  const intl = useIntl();

  // fee list data
  // fee list query param
  const {isSuccess, data, isFetching} = useListFeeQuery(
    {
      query: {
        storeId,
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  return {
    isLoading: isFetching,
    isSuccess,
    data: data?.data,
  };
}
