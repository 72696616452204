import {useIntl} from 'react-intl';
import {useEffect, useMemo, useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {
  formatErrorMessage,
  isApiValidationError,
  mapErrorToFormFields,
  phoneRegExp,
} from '../utils/functions';
import {ZipCode} from 'use-places-autocomplete';
import {BuyerParams, useCreateBuyerAccountMutation} from '../services/buyerApi';
import {SearchAddressResult} from '../types/SearchAddressResult';
import {CustomError} from '../types/CustomError';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  city: string;
  postCode: ZipCode;
  deliveryInstruction?: string;
};

const useCreateBuyerAccount = () => {
  // translations
  const intl = useIntl();
  const [enableNotification, setEnableNotification] = useState<boolean>(true);
  const [isAddressEmpty, setIsAddressEmpty] = useState<boolean>(false);
  const [address, setAddress] = useState<SearchAddressResult>({
    address: '',
    city: '',
    postCode: '',
    latitude: 0,
    longitude: 0,
    country: '',
  });

  // handle validation for address
  const [addressError, setAddressError] = useState<string | null>(null);
  const [enteredValue, setEnteredValue] = useState<string>('');

  // update address state when user select an address suggestion
  const updateAddressValue = (values: SearchAddressResult) => {
    setAddress(values);
    setAddressError(null);
    if (!values.city || !values.postCode) {
      setIsAddressEmpty(true);
    } else {
      setIsAddressEmpty(false);
    }
  };

  // set error message for address
  const updateAddressError = (message: string | null) =>
    setAddressError(message);

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .email(intl.formatMessage({id: 'validation.error.invalid_email'})),
        firstName: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        lastName: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        phone: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .matches(
            phoneRegExp,
            intl.formatMessage({id: 'validation.error.invalid_phone'})
          ),
        password: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .min(
            4,
            intl.formatMessage({id: 'validation.error.invalid_password_length'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: {errors, submitCount},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  // validate phone number
  const validatePhoneNumber = (isValid: boolean) => {
    if (isValid) {
      clearErrors('phone');
    } else {
      setError('phone', {
        type: 'required',
        message: intl.formatMessage({id: 'validation.error.invalid_phone'}),
      });
    }
  };

  // disable submit button if theres error
  const disableButton = (): boolean => {
    let disable = false;
    if (Object.keys(errors).length > 0 || addressError) {
      disable = true;
    }
    return disable;
  };

  // auto fill city and postcode
  useEffect(() => {
    if (address.city || address.postCode) {
      setValue('city', address.city);
      setValue('postCode', address.postCode);
    }
  }, [address.city, address.postCode]);

  const handleNotificationChange = () =>
    setEnableNotification(!enableNotification);

  const [
    createBuyerAccount,
    {isSuccess, isLoading, data, error: createBuyerError},
  ] = useCreateBuyerAccountMutation();

  // submit request
  const onSubmit = async (formData: FormData) => {
    if (enteredValue.length > 0 && address.address.length === 0) {
      updateAddressError(
        intl.formatMessage({id: 'validation.error.required_city_postcode'})
      );
      return;
    }
    if (!enteredValue || address.address.length === 0) {
      updateAddressError(
        intl.formatMessage({id: 'validation.error.required_field'})
      );
      return;
    }

    const {deliveryInstruction} = formData;
    delete formData['deliveryInstruction'];

    const data: BuyerParams = {
      body: {
        ...formData,
        enableNotification,
        addresses: [
          {
            ...address,
            deliveryInstruction,
            isDefault: true,
          },
        ],
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'dashboard.users.messages.user_created',
        });
      },
    };
    createBuyerAccount(data);
  };

  // Format api validation errors to input field
  useEffect(() => {
    if (createBuyerError) {
      const typedUpdateError = createBuyerError as CustomError;
      if (isApiValidationError(typedUpdateError)) {
        mapErrorToFormFields(typedUpdateError.data.message, setError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBuyerError]);

  // clear form fields after successful creation of buyer
  useEffect(() => {
    if (isSuccess && data) {
      setEnableNotification(true);
      setAddress({
        address: '',
        city: '',
        postCode: '',
        latitude: 0,
        longitude: 0,
        country: '',
      });
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  return {
    onSubmit,
    isSuccess,
    isLoading,
    data,
    control,
    handleSubmit,
    submitCount,
    errors,
    enableNotification,
    handleNotificationChange,
    validatePhoneNumber,
    disableButton,
    updateAddressValue,
    updateAddressError,
    addressError,
    isAddressEmpty,
    address,
    setEnteredValue,
  };
};

export default useCreateBuyerAccount;
