import React, {useCallback, useEffect, useState} from 'react';
import {GoogleMap, GoogleMapProps, Marker} from '@react-google-maps/api';
import withGmaps from '../../../Gmaps/withGmaps';
import {Card} from '@material-ui/core';
import {DeliveryZone} from '../../../../types/DeliveryZone';
import {Address} from '../../../../types/Address';
import MapZoneWidget from './Widgets/MapZoneWidget';
import {milesToMeters} from '../../../../utils/functions';
import RestaurantIcon from '../../../../assets/images/restaurant.svg';

export type MapZoneProps = {
  storeAddress?: Address;
  deliveryZones?: DeliveryZone[];
  selectedZone?: DeliveryZone;
  setSelectedZone: (zone: DeliveryZone) => void;
} & Omit<GoogleMapProps, 'onClick' | 'onMouseMove' | 'onLoad' | 'onUnmount'>;

const MapZone = ({
  deliveryZones,
  selectedZone,
  setSelectedZone,
  storeAddress,
  ...googleMapProps
}: MapZoneProps) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [zones, setZones] = useState<DeliveryZone[]>([]);

  const mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap'],
    },
    disableDefaultUI: true,
  };

  const onLoad = useCallback((map: google.maps.Map) => {
    const bounds = new google.maps.LatLngBounds();
    if (deliveryZones && deliveryZones.length) {
      deliveryZones.forEach(zone => {
        const {latitude, longitude} = zone.address;
        const circle = new google.maps.Circle({
          center: {lat: latitude, lng: longitude},
          radius: milesToMeters(zone.rangeMax),
        });
        bounds.union(circle.getBounds() as google.maps.LatLngBounds);
      });
      map.fitBounds(bounds);
      setMap(map);
    } else {
      map.setCenter({
        lat: (storeAddress && storeAddress.latitude) || 51.498419,
        lng: (storeAddress && storeAddress.longitude) || -0.104686,
      });
      map.setZoom(12);
      setMap(map);
    }
  }, []);

  useEffect(() => {
    moveToSelectedZone();
  }, [selectedZone, map]);

  useEffect(() => {
    if (deliveryZones) {
      setZones(deliveryZones);
    }
  }, [deliveryZones]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  function moveToSelectedZone() {
    if (selectedZone && map) {
      const selectedCircle = new google.maps.Circle({
        center: {
          lat: selectedZone?.address.latitude || 0,
          lng: selectedZone?.address.longitude || 0,
        },
        radius: milesToMeters(selectedZone.rangeMax),
      });

      const target = new google.maps.LatLng(
        selectedZone.address.latitude,
        selectedZone.address.longitude
      );
      const duration = 500;
      const easing = (t: number) =>
        t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      const start =
        map.getCenter() || new google.maps.LatLng(51.498419, -0.104686);

      const zoneBounds = selectedCircle.getBounds() as google.maps.LatLngBounds;
      if (
        map.getBounds()?.contains(zoneBounds.getNorthEast()) &&
        map.getBounds()?.contains(zoneBounds.getSouthWest())
      ) {
        return;
      }

      const animateStep = (timestamp: number) => {
        const elapsed = timestamp - startTimestamp;
        const progress = easing(Math.min(1, elapsed / duration));
        const lat = start!.lat() + (target.lat() - start!.lat()) * progress;
        const lng = start!.lng() + (target.lng() - start!.lng()) * progress;
        map.panTo({lat, lng});

        if (elapsed < duration) {
          requestAnimationFrame(animateStep);
        }
      };

      const startTimestamp = performance.now();
      requestAnimationFrame(animateStep);
      const bounds = selectedCircle.getBounds();
      map.fitBounds(bounds as google.maps.LatLngBounds);
    }
  }

  return (
    <Card elevation={0} style={{borderRadius: 8}}>
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '100%',
          minHeight: 'calc(100vh - 295px)',
        }}
        options={mapOptions}
        {...googleMapProps}
        onLoad={map => onLoad(map)}
        onUnmount={onUnmount}
      >
        {!zones.length && storeAddress && (
          <Marker
            position={{
              lat: storeAddress.latitude,
              lng: storeAddress.longitude,
            }}
            draggable={false}
            icon={{
              url: RestaurantIcon,
              scaledSize: new google.maps.Size(30, 45),
            }}
            title={storeAddress.address}
          />
        )}
        {zones.length &&
          zones.map((zone, index) => (
            <MapZoneWidget
              key={index}
              index={index}
              zone={zone}
              selectedZone={selectedZone}
              setSelectedZone={setSelectedZone}
            />
          ))}
      </GoogleMap>
    </Card>
  );
};

export default React.memo(withGmaps(MapZone));
