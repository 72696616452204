import {FormControl} from '@material-ui/core';
import clsx from 'clsx';
import {HTMLProps} from 'react';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import HintTooltip from '../../Common/HintTooltip';
import ErrorMessage from '../../Common/ErrorMessage';

export type TextAreaControllerProps<T extends FieldValues> = Omit<
  HTMLProps<HTMLTextAreaElement>,
  'name' | 'value' | 'onChange' | 'onBlur'
> & {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  excludeLabel?: boolean;
  formGroupClass?: string;
  showLabelHint?: boolean;
  required?: boolean;
  labelHintText?: string;
};

const TextAreaController = <T extends FieldValues>({
  name,
  control,
  label,
  excludeLabel,
  formGroupClass,
  showLabelHint,
  required,
  labelHintText,
  ...textareaProps
}: TextAreaControllerProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({field: {value, onChange, onBlur}, fieldState: {error}}) => (
      <FormControl
        className={clsx(
          'form-group d-flex',
          formGroupClass ? formGroupClass : 'mb-3 mb-lg-fg'
        )}
        error={!!error}
      >
        {showLabelHint ? (
          <div className="label-with-hint d-flex">
            <span className="small-label pb-2 pe-1">
              {label}
              {required ? '*' : ''}
            </span>
            <HintTooltip id={`hint_${label}`} content={labelHintText} />
          </div>
        ) : (
          !excludeLabel && (
            <span className="small-label pb-2">
              {label}
              {required ? '*' : ''}
            </span>
          )
        )}
        <textarea
          className="form-control"
          {...textareaProps}
          name={name}
          value={value ?? ''}
          onChange={onChange}
          onBlur={onBlur}
        />
        {error ? <ErrorMessage error={String(error.message)} /> : null}
      </FormControl>
    )}
  />
);

export default TextAreaController;
