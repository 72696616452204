import {useIntl} from 'react-intl';
import {useEffect, useState} from 'react';
import {formatErrorMessage, countDirtyFields} from '../utils/functions';
import {useParams} from 'react-router-dom';
import {Fee, FeeType} from '../types/Fee';
import {
  FeeRequestParameter,
  useCreateFeeMutation,
  useDeleteFeeMutation,
  useUpdateFeeMutation,
} from '../services/feeApi';
import useServiceFeeFormRequest, {
  ServiceFeeForm,
} from './fee/useServiceFeeFormRequest';

type UseServiceFeeProps = {
  selectedFee?: Fee | null;
};

export default function useServiceFee({selectedFee}: UseServiceFeeProps) {
  // translations
  const intl = useIntl();
  const params = useParams();
  const [feeId, setFeeId] = useState<undefined | number>();
  const [storeId, setStoreId] = useState<undefined | number>();
  const [count, setCount] = useState(0);

  // create fee api
  const [createFee, {isSuccess: createSuccess, isLoading: createLoading}] =
    useCreateFeeMutation();

  // delete fee api
  const [deleteFee] = useDeleteFeeMutation();

  // update fee api
  const [updateFee, {isSuccess: updateSuccess, isLoading: updateLoading}] =
    useUpdateFeeMutation();

  // alert when user try to leave page when edited field has not been saved
  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent) => {
      if (count > 0 && !createSuccess) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unloadHandler);
    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [count]);

  // set storeId
  useEffect(() => {
    if (params.storeId) {
      setStoreId(Number(params.storeId) ?? undefined);
    }
  }, [params]);

  // fee form request hook
  const {
    watch,
    handleSubmit,
    control,
    reset,
    formState: {dirtyFields},
  } = useServiceFeeFormRequest();

  const formValuesWatch = watch();

  // Count changed fields
  useEffect(() => {
    if (formValuesWatch) {
      setCount(countDirtyFields(dirtyFields));
    }
  }, [formValuesWatch]);

  // load fields with user data
  useEffect(() => {
    if (selectedFee) {
      handleSetValue(selectedFee);
      setFeeId(selectedFee.id);
    }
  }, [selectedFee]);

  // reset form field
  const resetFormField = () => {
    reset();
  };

  // set default values on edit
  const handleSetValue = (fee: Fee) => {
    reset({
      name: fee.name,
      description: fee.description,
      buyerFeePercentage: Number(fee.buyerFeePercentage),
      buyerFeeFixed: Number(fee.buyerFeeFixed),
      sellerFeeFixed: Number(fee.sellerFeeFixed),
      sellerFeePercentage: Number(fee.sellerFeePercentage),
    });
  };

  // submit fee request
  const serviceFeeSubmit = (formValues: ServiceFeeForm) => {
    const data: FeeRequestParameter = {
      id: feeId,
      body: {
        ...formValues,
        storeId,
        name: formValues.name,
        description: formValues.description,
        buyerFeePercentage: Number(formValues.buyerFeePercentage),
        buyerFeeFixed: Number(formValues.buyerFeeFixed),
        sellerFeeFixed: Number(formValues.sellerFeeFixed),
        sellerFeePercentage: Number(formValues.sellerFeePercentage),
        type: formValues.type || FeeType.Service,
      },
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatErrorMessage(error, intl);
      },
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: selectedFee ? 'messages.update_fee' : 'messages.create_fee',
          },
          {name: selectedFee?.name}
        );
      },
    };

    if (feeId) {
      updateFee(data);
    } else {
      createFee(data);
    }
  };

  // delete fee request
  const deleteFeeRequest = () => {
    deleteFee({
      id: feeId,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.delete_fee',
          },
          {name: selectedFee?.name}
        );
      },
    });
  };

  return {
    control,
    handleSubmit,
    serviceFeeSubmit,
    feeId,
    createSuccess,
    createLoading,
    resetFormField,
    deleteFeeRequest,
    updateSuccess,
    updateLoading,
  };
}
