export enum Status {
  Active = 'active',
  Pending = 'pending',
  Suspended = 'suspended',
  Failed = 'failed',
  Deleted = 'deleted',
}

export enum StoreStatus {
  Open = 'open',
  Closed = 'closed',
  ClosedForToday = 'closedForToday',
  PreOrderAvailable = 'preOrderAvailable',
  OnHold = 'onHold',
  Suspended = 'suspended',
  Unsuspended = 'unsuspended',
  Deleted = 'deleted',
}

export const StoreStatusValue = {
  active: 'Active',
  open: 'Open',
  suspended: 'Suspended',
  unsuspended: 'Unsuspended',
  onHold: 'On hold',
  closed: 'Closed',
  deleted: 'Deleted',
  closedForToday: 'Closed for today',
  preOrderAvailable: 'Pre order available',
};

export enum DeliveryZoneStatus {
  on = 'on',
  off = 'off',
  offForToday = 'offForToday',
}

export enum OrderStatus {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Cancelled = 'Cancelled',
  OutForDelivery = 'OutForDelivery',
  ReadyForPickup = 'ReadyForPickup',
  Declined = 'Declined',
  Completed = 'Completed',
}

export enum OrderServiceTypeStatus {
  Collection = 'collection',
  Delivery = 'delivery',
}

export enum StatementStatus {
  Pending = 'pending',
  Completed = 'completed',
}
