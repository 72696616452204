import {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useParams} from 'react-router-dom';
import {useGetStoreQuery} from '../services/storeApi';
import {useUpsertDeliveryConfigTimeMutation} from '../services/deliveryApi';
import {formatErrorMessage} from '../utils/functions';

export type DeliveryConfigTimeForm = {
  defaultCollectionTime: number;
};

const defaultCollectionTime = 30;

export default function useDeliveryConfig() {
  // translations
  const intl = useIntl();
  const {storeId} = useParams();

  const {data, isSuccess} = useGetStoreQuery(
    {id: storeId},
    {refetchOnMountOrArgChange: true}
  );

  const [count, setCount] = useState(0);

  // config time validation
  const schema = useMemo(
    () =>
      yup.object().shape({
        defaultCollectionTime: yup
          .number()
          .moreThan(
            0,
            intl.formatMessage(
              {id: 'validation.error.limit'},
              {name: 'default estimated collection time'}
            )
          )
          .typeError(intl.formatMessage({id: 'validation.error.required'}))
          .required(intl.formatMessage({id: 'validation.error.required'})),
      }),
    [intl]
  );

  // form initialization
  const {
    setValue,
    control,
    handleSubmit,
    reset,
    watch,
    formState: {dirtyFields, touchedFields},
  } = useForm<DeliveryConfigTimeForm>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  const defaultCollectionTimeWatch = watch('defaultCollectionTime');

  // compare old data to new data and show save bar
  const handleFieldChange = (value: number) => {
    if (!data) {
      return;
    }

    if (value !== data.store?.deliveryConfig?.defaultCollectionTime) {
      setCount(1);
    } else {
      setCount(0);
    }
  };

  // check if defaultCollectionTime field is being edited
  useEffect(() => {
    if (
      touchedFields['defaultCollectionTime'] ||
      dirtyFields['defaultCollectionTime']
    ) {
      handleFieldChange(Number(defaultCollectionTimeWatch));
    }
  }, [touchedFields, dirtyFields, defaultCollectionTimeWatch]);

  // set value on page loads
  useEffect(() => {
    if (data && isSuccess) {
      setValue(
        'defaultCollectionTime',
        data.store?.deliveryConfig?.defaultCollectionTime ??
          defaultCollectionTime
      );
    }
  }, [data, isSuccess]);

  // Delivery config api mutation
  const [upsertDeliveryConfig, {isSuccess: createSuccess}] =
    useUpsertDeliveryConfigTimeMutation();

  // Submit a delivery config
  const submit = (formValue: DeliveryConfigTimeForm) => {
    upsertDeliveryConfig({
      storeId,
      body: {
        defaultCollectionTime: formValue.defaultCollectionTime,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.update_success_message',
        });
      },
    });
  };

  useEffect(() => {
    if (createSuccess) {
      setCount(0);
    }
  }, []);

  const cancelSave = () => {
    setCount(0);
    reset();
  };

  return {
    control,
    handleSubmit,
    submit,
    count,
    cancelSave,
  };
}
