import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Drop from '../../../Widgets/DragDrop/Drop';
import StatusIndicator from '../../../Common/StatusIndicator';
import {ProductCategory} from '../../../../types/ProductCategory';
import {ProductCategoryGroup} from '../../../../types/ProductCategoryGroup';
import GroupItem from './GroupItem';

/**
 * Categories group
 */

type GroupProps = {
  className?: string;
  group: ProductCategoryGroup;
  categories?: ProductCategory[];
  selectedCategory: ProductCategory;
  placeholderProps?: any;
  onSelect: (categoryId: any) => void;
};

const Group = ({
  group,
  categories,
  selectedCategory,
  placeholderProps,
  onSelect,
}: GroupProps) => (
  <div className="group-categories">
    {categories && categories.length > 0 && (
      <StatusIndicator label={group.title} color={group.color} />
    )}
    <Drop
      droppableId={group.id}
      type="GROUP"
      placeholderProps={placeholderProps}
    >
      {categories?.length === 0 && (
        <StatusIndicator label={group.title} color={group.color} />
      )}
      {/* do not use Stack here for dnd spacing issue */}
      <div
        className={clsx('droppable-inner', {
          'pt-2': categories && categories.length > 0,
          'is-empty': (categories ?? []).length === 0,
        })}
        // important attr used for selecting the destination
        data-droppable-id={group.id}
      >
        {/* Group items */}
        {categories?.map((category, index: number) => (
          <GroupItem
            key={category.id}
            category={category}
            selectedCategory={selectedCategory}
            onSelect={onSelect}
            index={index}
            droppableId={`category-${category.id}`}
            type="categoryProducts"
            // important for dnd placeholder
            className="mb-2"
          >
            <Typography>{`${category.name} (${category.products.length})`}</Typography>
          </GroupItem>
        ))}
      </div>
    </Drop>
  </div>
);

export default Group;
