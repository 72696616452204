import {makeStyles, Paper, Theme} from '@material-ui/core';
import {ReactComponent as IconUnbrandedApp} from '../../assets/images/unbrandedApp.svg';

/**
 * Blank image component
 */

export type BlankProductImageProps = {
  size?: number;
  clickable?: boolean;
  onClick?: () => void | undefined;
};

const useStyles = makeStyles<Theme, BlankProductImageProps>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ({size}) => size,
    minWidth: ({size}) => size,
    height: ({size}) => size,
    backgroundColor: theme.palette.dark.light,
    borderRadius: 4,
    cursor: ({clickable}) => (clickable ? 'pointer' : 'default'),
  },
}));

const BlankAppIconImage = (props: BlankProductImageProps) => {
  const classes = useStyles(props);
  return (
    <Paper
      elevation={0}
      className={classes.root}
      onClick={props.clickable ? props.onClick : undefined}
    >
      <IconUnbrandedApp />
    </Paper>
  );
};

BlankAppIconImage.defaultProps = {
  size: 35,
  clickable: false,
};

export default BlankAppIconImage;
