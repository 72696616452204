import React, {ForwardedRef, forwardRef} from 'react';
import {ReactNode} from 'react';
import cx from 'clsx';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import useTextFieldStyles from '../../Common/useTextFieldStyles';

/* Text Field filled styled */

export type CustomTextFieldProps = {
  className?: string;
  /**
   * The adornment content
   */
  addon?: ReactNode | undefined;
  /**
   * Input type
   */
  type?: 'password' | 'text' | 'number';
  /**
   * Position of the adornment
   */
  addonPosition?: 'start' | 'end';
  /**
   * Is disabled
   */
  disabled?: boolean;
  /**
   * Placeholder
   */
  placeholder?: string;
  /**
   * TRUE if field is masked input number
   */
  isNumber?: boolean;
  /**
   * Addon click action
   */
  onAddonClick?: () => void;
} & Omit<
  TextFieldProps,
  'className' | 'addon' | 'type' | 'addonPosition' | 'disabled' | 'onAddonClick'
>;

// eslint-disable-next-line react/display-name
const CustomTextField = forwardRef(
  (props: CustomTextFieldProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      className = '',
      type,
      addon,
      addonPosition,
      disabled,
      // isNumber,
      onAddonClick,
      // onChange,
      ...textFieldProps
    } = props;
    const classes = useTextFieldStyles(props);

    return (
      <TextField
        color="secondary"
        variant="outlined"
        fullWidth
        {...textFieldProps}
        disabled={disabled}
        classes={classes}
        className={cx(className, {disabled})}
        type={type}
        inputProps={textFieldProps.inputProps}
        InputProps={{
          ...textFieldProps.InputProps,
          startAdornment:
            addon && addonPosition === 'start' ? (
              <InputAdornment position="start">{addon}</InputAdornment>
            ) : null,
          endAdornment:
            addon && addonPosition === 'end' ? (
              <InputAdornment
                className={
                  typeof onAddonClick === 'function' ? 'cursor-pointer' : ''
                }
                onClick={onAddonClick}
                position="end"
              >
                {addon}
              </InputAdornment>
            ) : null,
        }}
        ref={ref}
      />
    );
  }
);

CustomTextField.defaultProps = {
  className: '',
  addon: undefined,
  type: 'text',
  addonPosition: 'end',
  onAddonClick: () => {},
};

export default CustomTextField;
