import {useIntl} from 'react-intl';
import {useEffect, useMemo, useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {
  countDirtyFields,
  formatErrorMessage,
  isApiValidationError,
  mapErrorToFormFields,
  validAppKeyOptions,
} from '../utils/functions';
import {CustomError} from '../types/CustomError';
import useGetAppKey from './useGetAppKey';
import {
  UpdateAppKeyParameter,
  useUpdateAppKeyMutation,
} from '../services/appApi';
import {PlatformEnum, PlatformNameEnum} from '../types/Build';

export type AppKeyFormData = {
  teamId: string;
  authKeyFileData: File;
  authKeyFile: string;
  authKeyId: string;
  authKeyIssuerId: string;
  keyAlias: string;
  storeFile: string;
  keyPassword: string;
  storePassword: string;
  storeFileData: File;
};

export type UseUpdateAppKeyProps = {
  appId: number;
};
const useUpdateAppKey = ({appId}: UseUpdateAppKeyProps) => {
  // translations
  const intl = useIntl();
  const [count, setCount] = useState(0);
  const [authKeyFileName, setAuthKeyFileName] = useState<string | undefined>(
    undefined
  );
  const [storeFileName, setStoreFileName] = useState<string | undefined>(
    undefined
  );
  const [isIosDropReset, setIsIosDropReset] = useState(false);
  const [isAndroidDropReset, setIsAndroidDropReset] = useState(false);

  const schema = useMemo(
    () =>
      yup.object().shape({
        teamId: yup.string().trim(),
        authKeyFile: yup.string().trim(),
        authKeyId: yup.string().trim(),
        authKeyIssuerId: yup.string().trim(),
        keyAlias: yup.string().trim(),
        storeFile: yup.string().trim(),
        keyPassword: yup.string().trim(),
        storePassword: yup.string().trim(),
      }),
    [intl]
  );

  const {data: keyData, isSuccess: isKeyDataSuccess} = useGetAppKey({
    appId: Number(appId),
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    watch,
    setValue,
    clearErrors,
    formState: {errors, submitCount, dirtyFields},
  } = useForm<AppKeyFormData>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });
  const formValuesWatch = watch();

  const [updateAppKey, {isSuccess, isLoading, data, error: createAppError}] =
    useUpdateAppKeyMutation();

  const createReqPayload = (
    appKeyId: number,
    body: FormData,
    platform: PlatformNameEnum
  ): UpdateAppKeyParameter => {
    return {
      appId,
      appKeyId,
      body,
      showProgressDialog: true,
      formatSuccessMessage: () => {
        const message = intl.formatMessage(
          {
            id: 'messages.app_key_updated_success_message',
          },
          {platform: `${platform}`}
        );
        return message;
      },
      formatErrorMessage: (error: any) => formatErrorMessage(error, intl),
    };
  };

  // submit request
  const onSubmit = async (formData: AppKeyFormData) => {
    if (
      formData.authKeyFileData &&
      formData.authKeyFileData.size > validAppKeyOptions.maxSize
    ) {
      return;
    }
    if (
      formData.storeFileData &&
      formData.storeFileData.size > validAppKeyOptions.maxSize
    ) {
      return;
    }

    const isIosDirtyFields =
      dirtyFields['teamId'] ||
      dirtyFields['authKeyFile'] ||
      dirtyFields['authKeyFileData'] ||
      dirtyFields['authKeyId'] ||
      dirtyFields['authKeyIssuerId'];
    const isAndroidDirtyFields =
      dirtyFields['keyAlias'] ||
      dirtyFields['storePassword'] ||
      dirtyFields['storeFileData'] ||
      dirtyFields['keyPassword'];

    let reqPlatform: PlatformNameEnum;
    keyData?.forEach(async key => {
      const fd = new FormData();

      if (key.platform === PlatformEnum.Ios) {
        fd.append('teamId', formData.teamId);
        fd.append('authKeyId', formData.authKeyId);
        fd.append('authKeyIssuerId', formData.authKeyIssuerId);
        if (formData.authKeyFileData) {
          fd.append('file', formData.authKeyFileData);
        }
        reqPlatform = PlatformNameEnum.Ios;
      }

      if (key.platform === PlatformEnum.Android) {
        fd.append('keyAlias', formData.keyAlias);
        fd.append('storePassword', formData.storePassword);
        fd.append('keyPassword', formData.keyPassword);
        if (formData.storeFileData) {
          fd.append('file', formData.storeFileData);
        }

        reqPlatform = PlatformNameEnum.Android;
      }

      fd.append('platform', key.platform);
      const data = createReqPayload(key.id, fd, reqPlatform);

      if (
        (key.platform === PlatformEnum.Ios && isIosDirtyFields) ||
        (key.platform === PlatformEnum.Android && isAndroidDirtyFields)
      ) {
        await updateAppKey(data);
      }
    });
  };

  const resetForm = () => {
    reset();
    setValue('authKeyFile', '');
    setValue('storeFile', '');
    setIsIosDropReset(true);
    setIsAndroidDropReset(true);
    keyData?.forEach(key => {
      if (key.platform === PlatformEnum.Ios && key.ios) {
        setValue('teamId', key.ios.teamId);
        setValue('authKeyFile', key.ios.authKeyFile);
        setValue('authKeyId', key.ios.authKeyId);
        setValue('authKeyIssuerId', key.ios.authKeyIssuerId);
        setAuthKeyFileName(key.ios.authKeyFileName);
      } else if (key.platform === PlatformEnum.Android && key.android) {
        setValue('keyAlias', key.android.keyAlias);
        setValue('storeFile', key.android.storeFile);
        setValue('keyPassword', key.android.keyPassword);
        setValue('storePassword', key.android.storePassword);
        setStoreFileName(key.android.storeFileName);
      }
    });
  };

  // Format api validation errors to input field
  useEffect(() => {
    if (createAppError) {
      const typedUpdateError = createAppError as CustomError;
      if (isApiValidationError(typedUpdateError)) {
        mapErrorToFormFields(typedUpdateError.data.message, setError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAppError]);

  // clear form fields after successful creation of store
  useEffect(() => {
    if (isSuccess && data) {
      reset();
      if (data.platform === PlatformEnum.Ios) {
        setIsIosDropReset(true);
      } else if (data.platform === PlatformEnum.Android) {
        setIsAndroidDropReset(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  useEffect(() => {
    if (formValuesWatch) {
      if (dirtyFields['authKeyFileData']) {
        setIsIosDropReset(false);
      }
      if (dirtyFields['storeFileData']) {
        setIsAndroidDropReset(false);
      }
      setCount(countDirtyFields(dirtyFields));
    }
  }, [formValuesWatch]);

  useEffect(() => {
    if (keyData && isKeyDataSuccess) {
      resetForm();
    }
  }, [keyData, isKeyDataSuccess]);

  return {
    onSubmit,
    isSuccess,
    isLoading,
    data,
    control,
    handleSubmit,
    submitCount,
    errors,
    count,
    reset,
    setError,
    clearErrors,
    resetForm,
    storeFileName,
    authKeyFileName,
    isIosDropReset,
    isAndroidDropReset,
  };
};

export default useUpdateAppKey;
