import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useIntl} from 'react-intl';
import {useEffect, useMemo, useState} from 'react';
import {
  formatErrorMessage,
  isApiValidationError,
  mapErrorToFormFields,
  phoneRegExp,
} from '../utils/functions';
import {ZipCode} from 'use-places-autocomplete';
import {Address} from '../types/Address';
import {
  AddressParam,
  useCreateAddressMutation,
  useDeleteAddressMutation,
  useUpdateAddressMutation,
} from '../services/userApi';
import {SearchAddressResult} from '../types/SearchAddressResult';
import {CustomError} from '../types/CustomError';

type AddressFormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  city: string;
  postCode: ZipCode;
  deliveryInstruction: string;
  isDefault: boolean;
};

type UseAddressProps = {
  selectedAddress?: Address | null;
  userId?: number | string;
  closeAddressModal: () => void;
};

const useAddress = ({
  selectedAddress,
  userId,
  closeAddressModal,
}: UseAddressProps) => {
  // translations
  const intl = useIntl();
  const [address, setAddress] = useState<SearchAddressResult>({
    address: '',
    city: '',
    postCode: '',
    latitude: 0,
    longitude: 0,
    country: '',
  });

  // handle validation for address
  const [addressError, setAddressError] = useState<string | null>(null);
  const [enteredValue, setEnteredValue] = useState<string>('');
  const [isAddressEmpty, setIsAddressEmpty] = useState<boolean>(false);

  // update address state when user select an address suggestion
  const updateAddressValue = (values: SearchAddressResult) => {
    setAddress(values);
    setAddressError(null);
    if (!values.city || !values.postCode) {
      setIsAddressEmpty(true);
    } else {
      setIsAddressEmpty(false);
    }
  };

  // set error message for address
  const updateAddressError = (message: string | null) =>
    setAddressError(message);

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        lastName: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        phone: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .matches(
            phoneRegExp,
            intl.formatMessage({id: 'validation.error.invalid_phone'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: {errors, submitCount},
  } = useForm<AddressFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      isDefault: false,
    },
  });

  const [createAddress, {error: createError, isSuccess: isCreateSuccess}] =
    useCreateAddressMutation();
  const [updateAddress, {error: updateError, isSuccess: isUpdateSuccess}] =
    useUpdateAddressMutation();
  const [deleteAddress, {isSuccess: isDeleteSuccess}] =
    useDeleteAddressMutation();

  // validate phone number
  const validatePhoneNumber = (isValid: boolean) => {
    if (isValid) {
      clearErrors('phone');
    } else {
      setError('phone', {
        type: 'required',
        message: intl.formatMessage({id: 'validation.error.invalid_phone'}),
      });
    }
  };

  // auto fill city and postcode
  useEffect(() => {
    if (address.city || address.postCode) {
      setValue('city', address.city);
      setValue('postCode', address.postCode);
    }
  }, [address.city, address.postCode]);

  // update fields in edit mode
  useEffect(() => {
    if (selectedAddress) {
      setValue('firstName', selectedAddress.firstName || '');
      setValue('lastName', selectedAddress.lastName || '');
      setValue('phone', selectedAddress.phone || '');
      setValue('city', selectedAddress.city || '');
      setValue('postCode', selectedAddress.postCode || '');
      setValue('isDefault', selectedAddress.isDefault || false);
      setValue(
        'deliveryInstruction',
        selectedAddress.deliveryInstruction || ''
      );
      setAddress({
        ...address,
        address: selectedAddress.address || '',
        longitude: selectedAddress.longitude,
        latitude: selectedAddress.latitude,
        country: selectedAddress.country || '',
        city: selectedAddress.city || '',
        postCode: selectedAddress.postCode || '',
      });
    }
  }, [selectedAddress]);

  // create/update address
  const submit = (formValues: AddressFormValues) => {
    if (enteredValue.length > 0 && address.address.length === 0) {
      updateAddressError(
        intl.formatMessage({id: 'validation.error.required_city_postcode'})
      );
      return;
    }
    if (!enteredValue || address.address.length === 0) {
      updateAddressError(
        intl.formatMessage({id: 'validation.error.required_field'})
      );
      return;
    }
    const data: AddressParam = {
      id: selectedAddress?.id,
      body: {
        userId,
        ...formValues,
        ...address,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: selectedAddress
            ? 'dashboard.users.messages.update_address_success_message'
            : 'dashboard.users.messages.create_address_success_message',
        });
        return message;
      },
    };
    if (selectedAddress) {
      updateAddress(data);
    } else {
      createAddress(data);
    }
  };

  // Format api validation errors to input field for create address
  useEffect(() => {
    if (createError) {
      //@ts-ignore.
      if (createError?.data?.error === 'Validation Error') {
        //@ts-ignore.
        mapErrorToFormFields(createError.data.message, setError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createError]);

  // Format api validation errors to input field for update address
  useEffect(() => {
    if (updateError) {
      const typedUpdateError = updateError as CustomError;
      if (isApiValidationError(typedUpdateError)) {
        mapErrorToFormFields(typedUpdateError.data.message, setError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  // close modal after create/update of address
  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      closeAddressModal();
    }
  }, [isCreateSuccess, isUpdateSuccess]);

  // update address as default
  const setAddressAsDefault = (userAddress: Address) => {
    const addressPayload: Address = {
      ...userAddress,
    };
    addressPayload['isDefault'] = true;
    const data: AddressParam = {
      id: userAddress?.id,
      body: {
        userId,
        ...addressPayload,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'dashboard.user_details.tab_address.default_address_success_message',
        });
        return message;
      },
    };
    updateAddress(data);
  };

  // delete address
  const deleteBuyerAddress = (addressId: number) => {
    const data: AddressParam = {
      id: addressId,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'dashboard.user_details.tab_address.delete_success_message',
        });
        return message;
      },
    };
    deleteAddress(data);
  };

  return {
    control,
    handleSubmit,
    errors,
    submit,
    submitCount,
    updateAddressError,
    updateAddressValue,
    isAddressEmpty,
    address,
    addressError,
    setEnteredValue,
    validatePhoneNumber,
    isSuccess: isCreateSuccess,
    setAddressAsDefault,
    deleteBuyerAddress,
    isDeleteSuccess,
  };
};

export default useAddress;
