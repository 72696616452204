import {yupResolver} from '@hookform/resolvers/yup';
import {useMemo, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {formatErrorMessage} from '../utils/functions';
import * as yup from 'yup';
import {ProductOption, ProductOptionStatus} from '../types/ProductOption';
import {
  CreateUpdateOptionParam,
  useCreateProductOptionMutation,
  useDeleteProductOptionMutation,
  useUpdateProductOptionMutation,
  useUpdateProductOptionStatusMutation,
} from '../services/productOptionGroupApi';
import {DeleteParam} from '../services/storeApi';

export type ProductOptionFormValues = {
  name: string;
  price: number;
  status?: string;
};

type UseProductOptionProps = {
  selectedOption?: ProductOption | null;
  variationId?: number | string;
  isEditMode: boolean;
};

export default function useProductOption({
  variationId,
  selectedOption,
  isEditMode,
}: UseProductOptionProps) {
  // Translations
  const intl = useIntl();
  const [optionsToUpdate, setOptionsToUpdate] = useState<any[]>([]);
  // yup validation
  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        price: yup
          .number()
          .typeError(
            intl.formatMessage({id: 'validation.error.required_field'})
          )
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
      }),
    [intl]
  );

  // form hook
  const {control, handleSubmit, reset, setValue} =
    useForm<ProductOptionFormValues>({
      mode: 'onTouched',
      resolver: yupResolver(schema),
    });

  // prefill fields on update
  useEffect(() => {
    if (selectedOption && isEditMode) {
      setValue('name', selectedOption.name);
      setValue('price', selectedOption.price.price ?? 0.0);
    } else {
      reset();
    }
  }, [selectedOption, isEditMode]);

  // create Product Option
  const [createOption, {isSuccess: createSuccess, isLoading: createdLoading}] =
    useCreateProductOptionMutation();

  // Edit Product Option
  const [updateOption, {isSuccess: updateSuccess, isLoading: updatedLoading}] =
    useUpdateProductOptionMutation();

  // Edit Product Option Status
  const [
    updateOptionStatus,
    {isSuccess: updateStatusSuccess, isLoading: updatedStatusLoading},
  ] = useUpdateProductOptionStatusMutation();

  // Delete Product Option
  const [deleteOption, {isSuccess: deleteSuccess}] =
    useDeleteProductOptionMutation();

  const submit = (formValues: ProductOptionFormValues) => {
    const data: CreateUpdateOptionParam = {
      body: {
        ...formValues,
        variationId,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = isEditMode
          ? intl.formatMessage(
              {
                id: 'messages.custom_update_option',
              },
              {
                name: selectedOption?.name,
              }
            )
          : intl.formatMessage(
              {
                id: 'messages.custom_create_option',
              },
              {
                name: formValues?.name,
              }
            );
        return message;
      },
    };
    if (isEditMode && selectedOption) {
      data.id = selectedOption.id;
      //   update option
      updateOption(data);
    } else {
      //create option
      createOption(data);
    }
  };

  // Update Product option status
  const updateProductOptionStatus = (item: ProductOption) => {
    const status = item.status;
    const nameValue = item.name;
    const priceValue = item.price.price;
    const newStatus =
      status === ProductOptionStatus.active
        ? ProductOptionStatus.inactive
        : ProductOptionStatus.active;
    const data: CreateUpdateOptionParam = {
      id: item.id,
      body: {
        name: nameValue,
        price: priceValue,
        status: newStatus,
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    };
    updateOption(data);
  };
  // Delete Product option
  const deleteProductOption = (id: number) => {
    const data: DeleteParam = {
      id,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.custom_delete_option',
          },
          {name: selectedOption?.name ?? ''}
        );
      },
    };
    deleteOption(data);
  };

  const addingNewOptionUpdate = (
    newStatus: string,
    editOption: ProductOption
  ) => {
    const {id} = editOption ?? {};

    const newOptionData: any = {
      id: editOption.id,
      status: newStatus,
    };

    const findOptionIndex = optionsToUpdate.findIndex(v => v.id === id);
    if (isUpdatedOptionDirty(newOptionData, editOption)) {
      if (findOptionIndex >= 0) {
        const newArrayOfOptions = [...optionsToUpdate];
        newArrayOfOptions[findOptionIndex] = newOptionData;
        setOptionsToUpdate(newArrayOfOptions);
      } else {
        setOptionsToUpdate([...optionsToUpdate, newOptionData]);
      }
    } else {
      setOptionsToUpdate(optionsToUpdate.filter(v => v.id !== id));
    }
  };

  const removeFromUpdatesIfExits = (items: ProductOption[]) => {
    setOptionsToUpdate(optionsToUpdate =>
      optionsToUpdate.filter(optionToUpdate => {
        return !items.some(item => item.id === optionToUpdate.id);
      })
    );
  };

  const resetUpdatedOptions = () => {
    setOptionsToUpdate([]);
  };

  const isUpdatedOptionDirty = (
    option: ProductOption,
    editOption: ProductOption
  ) => {
    return option.status !== editOption.status;
  };

  const updateEditedOptions = () => {
    optionsToUpdate.forEach(item => {
      updateOptionStatus({
        id: item.id,
        body: {
          ...item,
        },
        showProgressDialog: false,
        formatErrorMessage: error => formatErrorMessage(error, intl),
        formatSuccessMessage: () => {
          const message = intl.formatMessage(
            {
              id: 'messages.custom_update_option',
            },
            {
              name: item.name,
            }
          );
          return message;
        },
      });
    });
    resetUpdatedOptions();
  };

  /**
   * The code will receive the latest options data fetched and examine if any of it needs to be updated
   * due to a dirty state. If there are any updates to be made,
   *  the code will merge them with the current dirty state.
   */
  const updateFetchedOptionsWithEditDirtyState = (items: ProductOption[]) => {
    if (optionsToUpdate.length === 0) {
      return items;
    }
    const newOptions = items.map((item: ProductOption) => {
      const findOptionIndex = optionsToUpdate.findIndex(v => v.id === item.id);
      if (findOptionIndex < 0) {
        return item;
      }
      const newItem = {
        ...item,
        status: optionsToUpdate[findOptionIndex].status,
      };
      return newItem;
    });
    return newOptions;
  };

  return {
    handleSubmit,
    control,
    submit,
    createSuccess,
    resetOptionsFields: reset,
    updateSuccess,
    updateStatusSuccess,
    deleteProductOption,
    deleteSuccess,
    updateProductOptionStatus,
    createdLoading,
    updatedLoading,
    updatedStatusLoading,
    optionsToUpdate,
    resetUpdatedOptions,
    updateEditedOptions,
    addingNewOptionUpdate,
    removeFromUpdatesIfExits,
    updateFetchedOptionsWithEditDirtyState,
  };
}
