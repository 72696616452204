import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Container, Row, Col} from 'reactstrap';
import useAddress from '../../../hooks/useAddress';
import {GetUserResponse} from '../../../services/userApi';
import {Address} from '../../../types/Address';
import ConfirmationPopup from '../../Shared/ConfirmationPopup';
import Modal from '../../Widgets/Modal';
import AddressForm from '../Form/AddressForm';
import UserAddressItem, {UserNewAddressItem} from '../Items/UserAddressItem';

/**
 * Buyer details - Address tab
 */
type AddressDetailsTabProps = {
  id?: number | string;
  userData: GetUserResponse | undefined;
};

const AddressDetailsTab = ({id, userData}: AddressDetailsTabProps) => {
  const intl = useIntl();
  // address form show
  const [showAddressForm, setShowAddressForm] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);

  // close modal
  const closeAddressModal = () => {
    setSelectedAddress(null);
    setShowAddressForm(false);
    setOpenDelete(false);
    setIsEdit(false);
  };

  // open create address modal
  const openCreateModal = () => setShowAddressForm(true);

  // open edit address modal
  const onEdit = (address: Address) => {
    setSelectedAddress(address);
    setShowAddressForm(true);
    setIsEdit(true);
  };

  const {setAddressAsDefault, deleteBuyerAddress} = useAddress({
    selectedAddress,
    userId: id,
    closeAddressModal,
  });

  // update address as default action
  const onSetAsDefault = (address: Address) => {
    setAddressAsDefault(address);
  };

  // open confirmation popup
  const onOpenDeleteConfirm = (address: Address) => {
    setSelectedAddress(address);
    setOpenDelete(true);
  };

  // delete address action
  const onDeleteAddress = () => {
    setOpenDelete(false);
    deleteBuyerAddress(selectedAddress?.id ?? 0);
  };

  return (
    <>
      <ConfirmationPopup
        opened={openDelete}
        handleClose={() => setOpenDelete(false)}
        onOk={onDeleteAddress}
        description={intl.formatMessage({
          id: 'dashboard.users.messages.delete_user_message',
        })}
      />
      <Modal
        opened={showAddressForm}
        handleClose={closeAddressModal}
        title={intl.formatMessage({
          id: isEdit
            ? 'dashboard.user_details.tab_address.edit_address'
            : 'dashboard.user_details.tab_address.create_address',
        })}
        noSaveBtn={false}
        cancelTitle={intl.formatMessage({id: 'actions.cancel'})}
        saveTitle={intl.formatMessage({
          id: isEdit ? 'actions.save' : 'actions.add',
        })}
        type="submit"
        form="address-form"
      >
        <AddressForm
          form="address-form"
          selectedAddress={selectedAddress}
          userId={id}
          closeAddressModal={closeAddressModal}
        />
      </Modal>
      <Container>
        <div className="content-block cards-list">
          <Row className="g-3">
            {userData && userData?.user.addresses.length > 0
              ? userData?.user.addresses?.map(address => (
                  <Col lg={4} key={address.id}>
                    <UserAddressItem
                      userAddress={address}
                      user={userData.user}
                      onEdit={onEdit}
                      onSetAsDefault={onSetAsDefault}
                      onDelete={onOpenDeleteConfirm}
                    />
                  </Col>
                ))
              : null}

            <Col lg={4}>
              <UserNewAddressItem onClick={openCreateModal} />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default AddressDetailsTab;
