import React, {useState} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import clsx from 'clsx';
import Avatar from '../Common/Avatar';
// import Switch from '../Common/Switch';
import useLogout from '../../hooks/useLogout';
import useUserInfo from '../../hooks/useUserInfo';

// Notification indicator shown inside menu
// const NotificationIndicator = ({count}: any) =>
//   count > 0 ? (
//     <div className="notification ml-auto">
//       <span className="content">{count}</span>
//     </div>
//   ) : null;

/* Navbar Avatar menu */
const AvatarMenu = ({className}: any) => {
  // dropdown opened state
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // dropdown open state toggle
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const {logoutUser} = useLogout();
  const {name, initials, user} = useUserInfo();

  const logout = async () => {
    await logoutUser();
  };

  return (
    <Dropdown
      className={clsx('avatar-menu', className)}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle tag="div" caret>
        <Avatar alt={initials} src="" color="blue" size="xs" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem tag="div" className="auth-info">
          <p className="user-name">{name && name}</p>
          <p className="user-email">{user?.email}</p>
        </DropdownItem>
        {/* <DropdownItem>
          <div className="wrapper">
            <span className="label">
              <FormattedMessage id="dashboard.menu.dropdown.chat" />
            </span>
            <NotificationIndicator count={5} />
          </div>
        </DropdownItem>
        <DropdownItem>
          <div className="wrapper">
            <span className="label">
              <FormattedMessage id="dashboard.menu.dropdown.notifications" />
            </span>
            <NotificationIndicator count={2} />
          </div>
        </DropdownItem>
        <DropdownItem>
          <div className="wrapper">
            <Link to={urls.BILLING_PATH}>
              <span className="label">
                <FormattedMessage id="dashboard.menu.dropdown.billing" />
              </span>
            </Link>
          </div>
        </DropdownItem>
        <DropdownItem>
          <div className="wrapper">
            <Link to={urls.ACCOUNT_SETTINGS}>
              <span className="label">
                <FormattedMessage id="dashboard.menu.dropdown.account_settings" />
              </span>
            </Link>
          </div>
        </DropdownItem>
        <DropdownItem tag="div">
          <div className="wrapper">
            <span className="label">
              <FormattedMessage id="dashboard.menu.dropdown.view_test_data" />
            </span>
            <Switch small />
          </div>
        </DropdownItem> */}
        <DropdownItem onClick={logout}>
          <span className="label">
            <FormattedMessage id="actions.logout" />
          </span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AvatarMenu;
