import {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Typography} from '@material-ui/core';
import Avatar from '../Common/Avatar';
import Dropdown, {ItemType} from '../Common/Dropdown';
import StatusIndicator from '../Common/StatusIndicator';
import {Col, Container, Row, Button} from 'reactstrap';
import ChangePasswordForm from './Form/ChangePasswordForm';
import Modal from '../Widgets/Modal';
import ConfirmationPopup from '../Shared/ConfirmationPopup';
import SaveFeature from '../Shared/SaveFeature';
import InputController from '../Form/HookForm/InputController';
import PhoneController from '../Form/HookForm/PhoneController';
import {DevTool} from '@hookform/devtools';
import useUserAction from '../../hooks/useUserAction';
import {useParams} from 'react-router-dom';
import useBuyer from '../../hooks/useBuyer';
import {formatDate, getColor} from '../../utils/functions';
import AutocompleteMultipleController from '../Form/HookForm/AutocompleteMultipleController';
import {useFetchStoresQuery} from '../../services/storeApi';
import {OptionProps} from '../Form/Inputs/AutocompleteMultiple';
import {Store} from '../../types/Store';
import ListWithDelete from '../Common/ListWithDelete';
import * as urls from '../../constants/urls';
import {useDispatch} from 'react-redux';
import {enqueueErrorMessage} from '../../store/slices/appSlice';
import {UserStatus} from '../../types/User';

/**
 * Seller details
 */

const SellerDetails = () => {
  const intl = useIntl();
  const {id} = useParams();
  const dispatch = useDispatch();

  const {data, isSuccess} = useFetchStoresQuery({query: {pageSize: 100}});
  const [stores, setStores] = useState<OptionProps[]>([]);
  const [linkedStores, setLinkedStores] = useState<Store[]>([]);
  // Change password form
  const [changePassword, setChangePassword] = useState(false);
  const openChangePasswordModal = () => setChangePassword(true);
  const closeChangePasswordModal = () => setChangePassword(false);
  // Confirmation popup
  const [confirmation, setConfirmation] = useState(false);
  const [showSave, setShowSave] = useState(false);

  const {
    headerDetails,
    userData,
    resetFormFields,
    updateBuyerAccount: updateAccount,
    handleSubmit,
    control,
    count,
  } = useBuyer({id, linkedStores, showSave});

  useEffect(() => {
    if (data && isSuccess && userData) {
      // map data to drop down select options
      const filteredData = data?.data.filter(
        store => !userData.user.stores.some(item => item.id === store.id)
      );
      if (filteredData.length > 0) {
        setStores(
          filteredData.map(store => ({
            title: store.name,
            value: store.id,
            email: store.email,
          }))
        );
      }
    }
  }, [data, isSuccess, userData]);

  useEffect(() => {
    if (userData) {
      setLinkedStores(userData.user.stores);
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.user.stores.length !== linkedStores.length) {
      setShowSave(true);
    } else {
      setShowSave(false);
    }
  }, [userData, linkedStores]);

  // unlink store from user
  const unlinkUserStore = (id: number) => {
    if (!userData) {
      return;
    }
    const {stores} = userData.user;
    const store = stores.find(item => item.id === id);
    if (!store) {
      return;
    }
    const {sellers} = store;
    if (sellers.length > 1) {
      setLinkedStores(prevstate => prevstate.filter(item => item.id !== id));
    } else {
      const message = intl.formatMessage({
        id: 'dashboard.users.messages.unlink_store_error_message',
      });
      dispatch(enqueueErrorMessage(message));
    }
  };

  // cancel save
  const cancelSave = () => {
    resetFormFields();
    if (userData) {
      setLinkedStores(userData?.user.stores);
    }
  };
  const actionItems: ItemType[] = [];

  if (userData?.user.status === UserStatus.Suspended) {
    actionItems.push({
      label: intl.formatMessage({
        id: 'dashboard.admin.users.actions.unsuspend_account',
      }),
      onClick: () => openConfirmationPopup('unsuspend', true),
    });
  } else {
    actionItems.push({
      label: intl.formatMessage({
        id: 'dashboard.admin.users.actions.suspend_account',
      }),
      onClick: () => openConfirmationPopup('suspend', true),
    });
  }

  actionItems.push({
    label: intl.formatMessage({
      id: 'dashboard.admin.users.actions.delete_account',
    }),
    onClick: () => openConfirmationPopup('delete', true),
  });

  // delete user hook
  const {
    open: openPopup,
    openConfirmationPopup,
    deleteUserAction,
    suspendUserAction,
    unsuspendUserAction,
  } = useUserAction({
    userId: id,
  });

  // Header content
  const header = (
    <div className="container-fluid top-block bg-white border-0">
      <div className="details-header">
        <div className="row align-items-center title-block pb-lg-vtl-40">
          <div className="col-lg-6">
            <div className="details-header-part d-flex flex-column flex-lg-row align-items-center align-items-lg-start justify-content-center justify-content-lg-start">
              <Avatar size="md" alt={headerDetails.initials} color="neutral" />
              <div className="information-part text-center text-lg-start">
                <div className="d-flex flex-column align-items-center align-items-lg-start">
                  <Typography variant="h3" className="font-weight-bolder">
                    {headerDetails.fullName}
                  </Typography>
                  <StatusIndicator
                    label={headerDetails.status}
                    color={userData ? getColor(userData.user.status) : 'green'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex justify-content-center justify-content-lg-end action-buttons">
              <Dropdown
                title={intl.formatMessage({id: 'actions.actions'})}
                items={actionItems}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {(count > 0 || showSave) && (
        <SaveFeature
          onClose={cancelSave}
          open
          keyType="seller"
          num={showSave ? count + 1 : count}
          type="submit"
          form="update-seller-form"
        />
      )}
      <ConfirmationPopup
        opened={openPopup.delete}
        handleClose={() => openConfirmationPopup('delete', false)}
        onOk={deleteUserAction}
        description={intl.formatMessage({
          id: 'dashboard.users.messages.delete_user_message',
        })}
      />
      <ConfirmationPopup
        opened={openPopup.suspend}
        handleClose={() => openConfirmationPopup('suspend', false)}
        onOk={suspendUserAction}
        description={intl.formatMessage({
          id: 'dashboard.users.messages.suspend_user_message',
        })}
      />
      <ConfirmationPopup
        opened={openPopup.unsuspend}
        handleClose={() => openConfirmationPopup('unsuspend', false)}
        onOk={unsuspendUserAction}
        description={intl.formatMessage({
          id: 'dashboard.users.messages.unsuspend_user_message',
        })}
      />
      {/* Change password */}
      <Modal
        opened={changePassword}
        handleClose={closeChangePasswordModal}
        title={intl.formatMessage({
          id: 'dashboard.admin.users.details.change_password',
        })}
        width={238}
        noSaveBtn={false}
        showCloseTop={false}
        cancelTitle={intl.formatMessage({id: 'actions.no'})}
        saveTitle={intl.formatMessage({id: 'actions.yes'})}
        type="submit"
        form="seller-password-form"
      >
        <ChangePasswordForm
          id={id}
          form="seller-password-form"
          closePasswordModal={closeChangePasswordModal}
        />
      </Modal>
      {/* Confirmation */}
      <ConfirmationPopup
        opened={confirmation}
        handleClose={() => setConfirmation(false)}
      />
      <div className="details-page">
        {header}
        <Container className="ms-lg-0">
          <div className="content-block">
            <form
              id="update-seller-form"
              onSubmit={handleSubmit(updateAccount)}
            >
              <Row className="g-3">
                <Col lg={4}>
                  <div className="card details-card">
                    <div className="card-body">
                      <InputController
                        name="firstName"
                        control={control}
                        label={intl.formatMessage({
                          id: 'dashboard.admin.users.details.first_name',
                        })}
                      />
                      <InputController
                        name="lastName"
                        control={control}
                        label={intl.formatMessage({
                          id: 'dashboard.admin.users.details.last_name',
                        })}
                      />
                      <InputController
                        name="email"
                        control={control}
                        label={intl.formatMessage({
                          id: 'dashboard.admin.users.details.email_address',
                        })}
                      />
                      <div className="form-group mb-3 mb-lg-fg">
                        <Typography
                          variant="subtitle2"
                          className="text-muted mb-1"
                        >
                          <FormattedMessage id="dashboard.admin.users.details.password" />
                        </Typography>
                        <Button
                          tag="a"
                          color="link"
                          className="text-danger p-0"
                          onClick={openChangePasswordModal}
                        >
                          <Typography
                            variant="subtitle2"
                            className="font-weight-bold"
                          >
                            <FormattedMessage id="dashboard.admin.users.details.change_password" />
                          </Typography>
                        </Button>
                      </div>
                      {/* Phone */}
                      <PhoneController
                        name="phone"
                        control={control}
                        label={intl.formatMessage({
                          id: 'dashboard.admin.users.details.phone',
                        })}
                      />
                      <div className="form-group mb-3 mb-lg-fg">
                        <Typography
                          variant="subtitle2"
                          className="text-muted mb-1"
                        >
                          <FormattedMessage id="dashboard.admin.users.details.created_at" />
                        </Typography>
                        <Typography>
                          {userData && formatDate(userData?.user.createdAt)}
                        </Typography>
                      </div>
                      <div className="form-group mb-0">
                        <Typography
                          variant="subtitle2"
                          className="text-muted mb-1"
                        >
                          <FormattedMessage id="dashboard.admin.users.details.last_login" />
                        </Typography>
                        <Typography>
                          {userData?.user.lastLogin
                            ? formatDate(userData.user.lastLogin)
                            : '-'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="card details-card">
                    <div className="card-body">
                      <AutocompleteMultipleController
                        name="stores"
                        control={control}
                        options={stores}
                        label={intl.formatMessage({
                          id: 'dashboard.admin.users.details.linked_store',
                        })}
                        placeholder={intl.formatMessage(
                          {
                            id: 'dashboard.admin.users.details.linked_store_placeholder',
                          },
                          {name: 'store'}
                        )}
                      />
                      {linkedStores.length > 0 &&
                        linkedStores.map((store: Store) => (
                          <ListWithDelete
                            key={store.id}
                            name={store.name}
                            id={store.id}
                            onDelete={() => unlinkUserStore(store.id)}
                            url={urls.STORE_DETAILS_PATH.replace(
                              ':storeId',
                              `${store.id}/general`
                            )}
                            isExternalLink={true}
                          />
                        ))}
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
        <DevTool control={control} />
      </div>
    </>
  );
};

export default SellerDetails;
