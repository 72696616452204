import {useState} from 'react';

/**
 * Draggable animation
 */

const useDraggablePlaceholder = () => {
  // Animation state
  const [placeholderProps, setPlaceholderProps] = useState({});

  const onDragUpdate = (update: any) => {
    const queryAttr = 'data-rbd-drag-handle-draggable-id';
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;
    const droppableId = update.destination.droppableId;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }

    // the computation should happen on the destination not on the draggedDOM parent
    let destination: Element | null | ParentNode = document.querySelector(
      `[data-droppable-id=${droppableId}]`
    );

    // fallback to parentNode
    if (!destination) {
      destination = draggedDOM.parentNode;
    }

    const {clientHeight, clientWidth} = draggedDOM;

    const clientY =
      // @ts-ignore
      parseFloat(window.getComputedStyle(destination).paddingTop) +
      // @ts-ignore
      [...destination.children]
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          //@ts-ignore
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + (curr as HTMLElement).offsetHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        // @ts-ignore
        window.getComputedStyle(destination).paddingLeft
      ),
    });
  };

  return {
    placeholderProps,
    onDragUpdate,
  };
};

export default useDraggablePlaceholder;
