import {configureStore, combineReducers} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';

import listenerMiddlewares from './listenerMiddleware';
import appSlice from './slices/appSlice';
import authSlice from './slices/authSlice';
import menuSlice from './slices/menuSlice';
import buyerSlice from './slices/buyerSlice';
import storeSlice from './slices/storeSlice';
import settingSlice from './slices/settingSlice';
import productSlice from './slices/productSlice';
import statementSlice from './slices/statementSlice';
import discountSlice from './slices/discountSlice';
import {baseApi, baseApiPublic} from '../services/baseApi';

const persistConfig = {
  key: 'root:admin',
  storage,
  blacklist: [
    'paymentApi',
    'buyer',
    'store',
    'product',
    'statements',
    'discounts',
  ],
};

const rootReducer = combineReducers({
  app: appSlice,
  auth: authSlice,
  menu: menuSlice,
  buyer: buyerSlice,
  store: storeSlice,
  settings: settingSlice,
  product: productSlice,
  statements: statementSlice,
  discounts: discountSlice,
  [baseApi.reducerPath]: baseApi.reducer,
  [baseApiPublic.reducerPath]: baseApiPublic.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([
      ...listenerMiddlewares.map(lm => lm.middleware),
      baseApi.middleware,
      baseApiPublic.middleware,
    ]),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
