import {TagDescription} from '@reduxjs/toolkit/dist/query';
import {FeedbackParams} from '../store/queries/common';
import {Address} from '../types/Address';
import {Meta} from '../types/Meta';
import {AppUrl, Store} from '../types/Store';
import {StoreType} from '../types/StoreType';
import {DateFilter} from '../utils/date-filter';
import {baseApi} from './baseApi';

export type StoreParams = {
  body: {
    email: string;
    name: string;
    description?: string;
    phone: string;
    sellers: (string | number)[];
    address: Address;
    paymentMethod: string[];
  };
} & FeedbackParams;

export type ListStoreResponse = {
  data: Store[];
  meta: Meta;
};

export type StoreActionParam = {
  id?: number | string;
} & FeedbackParams;

export type GetStoreResponse = {
  store: Store;
};

export type GetStoreTypeResponse = StoreType[];

export type GetStoreTypeParam = {} & FeedbackParams;

export type CreateStoreTypeParam = {
  id?: number | string;
  body: {
    name: string;
  };
} & FeedbackParams;

export type UpdateBillingDetailsQueryParam = {
  id?: number | string;
  body: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    postCode: string;
    country: string;
    vatNumber: string;
    companyName: string;
  };
} & FeedbackParams;

export type ListStoreQueryParameter = {
  query?: {
    pageSize?: number;
    page?: number;
    order?: 'ASC' | 'DESC';
    orderBy?: string;
    search?: string;
    location?: string;
    status?: string;
    dateFilter?: DateFilter;
    startDate?: Date | string;
    endDate?: Date | string;
  };
} & FeedbackParams;

export type UpdateStoreParam = {
  id?: number | string;
  body: {
    name: string;
    email: string;
    phone: string;
    description?: string;
    paymentMethod?: string[];
    showReviewPublicly?: boolean;
    orderRejectionNote?: string;
    storeUrl?: string;
    externalStoreUrl?: string;
    news?: string;
    address?: Address;
    sellers?: (string | number)[];
    storeTypes?: (string | number)[];
    appUrl?: AppUrl[];
  };
} & FeedbackParams;

export type UploadStoreImageParam = {
  id?: number | string;
  body: FormData;
} & FeedbackParams;

export type DeleteParam = {
  id?: number | string;
} & FeedbackParams;

export type UpdateStoreStatusParam = {
  id?: number | string;
  body: {
    status: string;
  };
} & FeedbackParams;

const onInvalidate = (
  result: GetStoreResponse | undefined
): TagDescription<'Store'>[] => (result ? ['Store'] : []);

export const storeApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchStores: builder.query<
      ListStoreResponse,
      ListStoreQueryParameter | void
    >({
      query: ({query}: ListStoreQueryParameter) => ({
        url: '/admin/store',
        method: 'GET',
        params: {
          ...query,
        },
      }),
      providesTags: ['Store'],
    }),
    createStore: builder.mutation<any, StoreParams>({
      query: ({body}: StoreParams) => ({
        url: '/admin/store',
        method: 'POST',
        body,
      }),
      invalidatesTags: result => onInvalidate(result),
    }),
    getStore: builder.query<GetStoreResponse, StoreActionParam>({
      query: ({id}: StoreActionParam) => ({
        url: `/admin/store/${id}`,
        method: 'GET',
      }),
      providesTags: ['Store'],
    }),
    fetchStoreTypes: builder.query<
      GetStoreTypeResponse,
      GetStoreTypeParam | void
    >({
      query: () => ({
        url: '/admin/store/types',
        method: 'GET',
      }),
      providesTags: ['StoreType'],
    }),
    updateStore: builder.mutation<GetStoreResponse, UpdateStoreParam>({
      query: ({id, body}: UpdateStoreParam) => ({
        url: `/admin/store/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: result => onInvalidate(result),
    }),
    uploadImage: builder.mutation<GetStoreResponse, UploadStoreImageParam>({
      query: ({id, body}: UploadStoreImageParam) => ({
        url: `/admin/store/${id}/upload-image`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: result => onInvalidate(result),
    }),
    deleteStoreImage: builder.mutation<GetStoreResponse, DeleteParam>({
      query: ({id}: DeleteParam) => ({
        url: `/admin/store/${id}/delete-image`,
        method: 'DELETE',
      }),
      invalidatesTags: result => onInvalidate(result),
    }),
    createStoreType: builder.mutation<any, CreateStoreTypeParam>({
      query: ({body}: CreateStoreTypeParam) => ({
        url: '/admin/store/types',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['StoreType'],
    }),
    updateStoreType: builder.mutation<any, CreateStoreTypeParam>({
      query: ({body, id}: CreateStoreTypeParam) => ({
        url: `/admin/store/types/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['StoreType'],
    }),
    deleteStoreType: builder.mutation<any, DeleteParam>({
      query: ({id}: DeleteParam) => ({
        url: `/admin/store/types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['StoreType'],
    }),
    updateStoreStatus: builder.mutation<
      GetStoreResponse,
      UpdateStoreStatusParam
    >({
      query: ({id, body}: UpdateStoreStatusParam) => ({
        url: `/admin/store/${id}/status`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: result => onInvalidate(result),
    }),
    deleteStore: builder.mutation<any, DeleteParam>({
      query: ({id}: DeleteParam) => ({
        url: `/admin/store/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => onInvalidate(result),
    }),
    updateBillingDetails: builder.mutation<any, UpdateBillingDetailsQueryParam>(
      {
        query: ({body, id}: UpdateBillingDetailsQueryParam) => ({
          url: `/admin/store/${id}/billing-details`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: result => onInvalidate(result),
      }
    ),
  }),
});

export const {
  useFetchStoresQuery,
  useCreateStoreMutation,
  useGetStoreQuery,
  useFetchStoreTypesQuery,
  useUpdateStoreMutation,
  useDeleteStoreImageMutation,
  useUploadImageMutation,
  useCreateStoreTypeMutation,
  useUpdateStoreTypeMutation,
  useDeleteStoreTypeMutation,
  useUpdateStoreStatusMutation,
  useDeleteStoreMutation,
  useUpdateBillingDetailsMutation,
} = storeApi;
