import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery, baseQueryWithReauth} from './functions';

// Base api with token refresh for private endpoints
// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Menu',
    'Buyer',
    'Seller',
    'ProductCategory',
    'Product',
    'ProductOptionGroup',
    'StoreSchedule',
    'Setting',
    'Store',
    'StoreType',
    'Cards',
    'CurrentUser',
    'Discount',
    'DeliveryZone',
    'Fee',
    'Order',
    'Statement',
    'StatementOrder',
    'StatementInvoice',
    'Review',
    'App',
    'AppIntegration',
    'Build',
    'AppKeystore',
    'AppAuthProvider',
  ],
  endpoints: () => ({}),
});

// Base api without token/token refresh for public endpoints
export const baseApiPublic = createApi({
  reducerPath: 'publicApi',
  baseQuery,
  tagTypes: [],
  endpoints: () => ({}),
});
