import {useIntl} from 'react-intl';
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormSetError,
  UseFormWatch,
} from 'react-hook-form';
import {DevTool} from '@hookform/devtools';
import {Row, Col, Container, FormGroup, Form} from 'react-bootstrap';
import MapView from '../../Widgets/MapView';
import {Stack} from 'react-bootstrap';
import InputController from '../../Form/HookForm/InputController';
import TextAreaController from '../../Form/HookForm/TextAreaController';
import CheckboxGroupController from '../../Form/HookForm/CheckboxGroupController';
import DropzoneController from '../../Form/HookForm/DropzoneController';
import AutocompleteMultipleController from '../../Form/HookForm/AutocompleteMultipleController';
import PhoneController from '../../Form/HookForm/PhoneController';
import {GeneralFormValues} from '../../../hooks/store/useStoreDetailFormRequest';
import AddressAutoComplete from '../../Form/AddressAutoComplete';
import {Store} from '../../../types/Store';
import {useFetchSellerQuery} from '../../../services/sellerApi';
import {OptionProps} from '../../Form/Inputs/AutocompleteMultiple';
import {useEffect, useState} from 'react';
import {SellerData} from '../../../types/SellerData';
import ListWithDelete from '../../Common/ListWithDelete';
import * as urls from '../../../constants/urls';
import {enqueueErrorMessage} from '../../../store/slices/appSlice';
import {useDispatch} from 'react-redux';
import {
  DeleteParam,
  UploadStoreImageParam,
  useDeleteStoreImageMutation,
  useFetchStoreTypesQuery,
  useUploadImageMutation,
} from '../../../services/storeApi';
// @ts-ignore
import QRCode from 'react-qr-code';
import {
  formatErrorMessage,
  openInNewTab,
  validImageOptions,
} from '../../../utils/functions';
import ConfirmationPopup from '../../Shared/ConfirmationPopup';
import PermissionContainer from '../../Common/Permission/PermissionContainer';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from '../../../store/slices/authSlice';
import {SearchAddressResult} from '../../../types/SearchAddressResult';

/**
 * Store Details - General tab
 */

type AddressInputProps = {
  updateAddressValue: (values: SearchAddressResult) => void;
  updateAddressError: (message: string | null) => void;
  addressError: string | null;
  address?: SearchAddressResult;
  setEnteredValue: React.Dispatch<React.SetStateAction<string>>;
  isSuccess?: boolean;
  control: Control<GeneralFormValues>;
  required?: boolean;
};

type GeneralTabProps = {
  id?: number | string;
  handleSubmit: UseFormHandleSubmit<GeneralFormValues>;
  updateStoreDetail: (formValues: GeneralFormValues) => void;
  storeData: Store;
  form: string;
  watch: UseFormWatch<GeneralFormValues>;
  defaultStoreTypes: OptionProps[];
  linkedSellers: SellerData[];
  setLinkedStoreTypes: React.Dispatch<React.SetStateAction<OptionProps[]>>;
  setLinkedSellers: React.Dispatch<React.SetStateAction<SellerData[]>>;
  resetImageField: () => void;
  setError: UseFormSetError<GeneralFormValues>;
  clearError: UseFormClearErrors<GeneralFormValues>;
  errors: FieldErrors<GeneralFormValues>;
} & AddressInputProps;

//Mobile app store
const AppsLink = ({control, name, label, value, disabled}: any) => {
  // download QR Code
  const onImageDownload = () => {
    const svg = document.getElementById('QRCode');
    if (!svg) {
      return;
    }
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <Row className="gx-2">
      <Col xs="auto">
        <QRCode
          value={value ? value : ''}
          size={50}
          id="QRCode"
          onClick={value ? onImageDownload : undefined}
          style={{cursor: value ? 'pointer' : 'default'}}
        />
      </Col>
      <Col>
        <InputController
          name={name}
          control={control}
          label={label}
          arrowLink
          formGroupClass="mb-0"
          onArrowClick={() => openInNewTab(value)}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

// Address with city/postcode
const AddressInput = (props: AddressInputProps) => {
  const intl = useIntl();
  const {
    control,
    updateAddressError,
    updateAddressValue,
    addressError,
    address,
    isSuccess,
    setEnteredValue,
  } = props;
  const {user} = useSelector(selectUserAndToken);

  return (
    <>
      {/* Autocomplete suggested address */}
      <AddressAutoComplete
        name="address"
        label={intl.formatMessage({
          id: 'dashboard.store_details.general_tab.address_1',
        })}
        setAddress={updateAddressValue}
        updateAddressError={updateAddressError}
        addressError={addressError}
        address={address}
        setEnteredValue={setEnteredValue}
        isSuccess={isSuccess}
        required={props.required}
        canEdit={user?.profileType === 'seller' ? true : false}
      />
      <Row className="gx-2">
        <Col>
          <InputController
            name="city"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.store_details.general_tab.city',
            })}
            disabled
            formGroupClass="mb-0"
          />
        </Col>
        <Col>
          <InputController
            name="postCode"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.store_details.general_tab.postcode',
            })}
            disabled
            formGroupClass="mb-0"
          />
        </Col>
      </Row>
    </>
  );
};

/**
 * General
 */
const GeneralTab = (props: GeneralTabProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    id,
    control,
    handleSubmit,
    updateStoreDetail,
    updateAddressError,
    updateAddressValue,
    addressError,
    address,
    isSuccess,
    setEnteredValue,
    storeData,
    form,
    watch,
    linkedSellers,
    setLinkedSellers,
    defaultStoreTypes,
    resetImageField,
    setError,
    clearError,
    errors,
  } = props;
  const googleAppUrl = watch('googleAppUrl');
  const appleAppUrl = watch('appleAppUrl');
  const internalUrl = watch('storeUrl');
  const externalUrl = watch('externalStoreUrl');

  const {user} = useSelector(selectUserAndToken);

  // seller list
  const {data: sellerLists, isSuccess: fetchSellerSuccess} =
    useFetchSellerQuery({query: {pageSize: 100}});

  // store types list
  const {data: storeTypeList, isSuccess: storeTypeIsSuccess} =
    useFetchStoreTypesQuery();

  const [sellers, setSellers] = useState<OptionProps[]>([]);
  const [storeTypes, setStoreTypes] = useState<OptionProps[]>([]);
  const [open, setOpen] = useState(false);

  const openConfirmationPopup = () => setOpen(true);
  const closeConfirmationPopup = () => setOpen(false);

  // remove connected sellers from seller list
  useEffect(() => {
    if (sellerLists && fetchSellerSuccess && storeData) {
      // map data to drop down select options
      const filteredSellers = sellerLists.data.filter(
        seller => !storeData.sellers.some(item => item.id === seller.id)
      );
      if (filteredSellers.length > 0) {
        setSellers(
          filteredSellers.map(seller => ({
            title: `${seller.firstName} ${seller.lastName}`,
            value: seller.id,
            email: seller.email,
          }))
        );
      }
    }
  }, [sellerLists, isSuccess, storeData]);

  // remove connected storeType from storeType list
  useEffect(() => {
    if (storeTypeIsSuccess && storeTypeList && storeData) {
      const filteredStoreTypes = storeTypeList.filter(
        type => !storeData.storeTypes.some(item => item.id === type.id)
      );

      if (filteredStoreTypes.length > 0) {
        setStoreTypes(
          filteredStoreTypes.map(item => ({
            title: item.name,
            value: item.id,
          }))
        );
      }
    }
  }, [storeTypeIsSuccess, storeTypeList, storeData]);

  // unlink store from user
  const unlinkUserStore = (id: number) => {
    if (!storeData) {
      return;
    }

    if (storeData.sellers.length > 1) {
      setLinkedSellers(prevstate => prevstate.filter(item => item.id !== id));
    } else {
      const message = intl.formatMessage({
        id: 'error.unlink_seller_error_message',
      });
      dispatch(enqueueErrorMessage(message));
    }
  };

  const [uploadStoreImage, {isSuccess: uploadSuccess}] =
    useUploadImageMutation();
  const [deleteImage, {isSuccess: deleteSuccess}] =
    useDeleteStoreImageMutation();

  // upload Image to server
  const uploadImage = (imageData: File) => {
    if (
      !validImageOptions.type.includes(imageData.type) ||
      imageData.size > validImageOptions.maxSize
    ) {
      return;
    }
    const fd = new FormData();
    fd.append('image', imageData);
    const data: UploadStoreImageParam = {
      id,
      body: fd,
      showProgressDialog: true,
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'messages.image_upload',
        });
        return message;
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    };
    uploadStoreImage(data);
  };

  // delete Image from server
  const deleteStoreImage = () => {
    closeConfirmationPopup();
    const data: DeleteParam = {
      id,
      showProgressDialog: true,
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'messages.image_delete',
        });
        return message;
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    };
    deleteImage(data);
  };

  useEffect(() => {
    if (uploadSuccess || deleteSuccess) {
      resetImageField();
    }
  }, [uploadSuccess, deleteSuccess]);

  return (
    <Container fluid>
      <ConfirmationPopup
        opened={open}
        handleClose={closeConfirmationPopup}
        onOk={deleteStoreImage}
        description={intl.formatMessage({
          id: 'dashboard.store_details.actions.messages.delete_store_message',
        })}
      />
      <Form id={form} onSubmit={handleSubmit(updateStoreDetail)}>
        <div className="content-block">
          <Row className="g-3">
            <Col lg={4} xxl={3}>
              <div className="card details-card">
                <div className="card-body">
                  {/* Store Image */}
                  <DropzoneController
                    name="image"
                    control={control}
                    showLabelHint
                    className="mb-0"
                    label={intl.formatMessage({
                      id: 'dashboard.store_details.general_tab.store_image',
                    })}
                    labelHintText={intl.formatMessage({
                      id: 'dashboard.store_details.general_tab.store_image_hint',
                    })}
                    canEdit={user?.profileType === 'admin' ? true : false}
                    format="image"
                    url={storeData?.image}
                    uploadImage={uploadImage}
                    deleteImage={openConfirmationPopup}
                    uploadSuccess={uploadSuccess}
                    deleteSuccess={deleteSuccess}
                    validFileOptions={validImageOptions}
                    setError={setError}
                    clearError={clearError}
                    errors={errors}
                    cannotUpload={user?.profileType === 'seller' ? true : false}
                    canDelete={user?.profileType === 'admin' ? true : false}
                  />
                </div>
              </div>
              <div className="card details-card mt-3">
                <div className="card-body">
                  <TextAreaController
                    name="news"
                    control={control}
                    label={intl.formatMessage({
                      id: 'dashboard.store_details.general_tab.store_news',
                    })}
                    rows={3}
                  />
                  {user?.profileType === 'admin' && (
                    <>
                      <CheckboxGroupController
                        name="paymentMethod"
                        control={control}
                        checkboxSize="default"
                        label={intl.formatMessage({
                          id: 'dashboard.store_details.general_tab.payment_methods',
                        })}
                        options={[
                          {
                            label: intl.formatMessage({
                              id: 'dashboard.store_details.general_tab.cash',
                            }),
                            value: 'cash',
                          },
                          {
                            label: intl.formatMessage({
                              id: 'dashboard.store_details.general_tab.online',
                            }),
                            value: 'online',
                          },
                        ]}
                      />
                    </>
                  )}
                  <TextAreaController
                    name="orderRejectionNote"
                    control={control}
                    label={intl.formatMessage({
                      id: 'dashboard.store_details.general_tab.default_order_rejection',
                    })}
                    rows={3}
                    formGroupClass="mb-0"
                  />
                </div>
              </div>
              <PermissionContainer permissions={['link-seller']}>
                <div className="card details-card mt-3">
                  <div className="card-body">
                    {/* Link seller/s to store */}
                    <AutocompleteMultipleController
                      name="sellers"
                      control={control}
                      className="mb-2"
                      label={intl.formatMessage({
                        id: 'dashboard.store_details.general_tab.link_seller_store',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'dashboard.store_details.general_tab.link_seller_store_placeholder',
                      })}
                      options={sellers}
                    />
                    {linkedSellers.length > 0 && (
                      <Stack direction="vertical" gap={2}>
                        {linkedSellers.map(seller => (
                          <ListWithDelete
                            key={seller.id}
                            name={`${seller.firstName} ${seller.lastName}`}
                            id={seller.id}
                            onDelete={() => unlinkUserStore(seller.id)}
                            url={urls.SELLER_DETAILS_PATH.replace(
                              ':id',
                              `${seller.id}`
                            )}
                            isExternalLink={true}
                          />
                        ))}
                      </Stack>
                    )}
                  </div>
                </div>
              </PermissionContainer>
            </Col>
            <Col lg={8} xxl>
              <div className="card details-card">
                <div className="card-body">
                  <Row className="gx-vtl-26">
                    <Col lg={6}>
                      <InputController
                        name="name"
                        required
                        control={control}
                        label={intl.formatMessage({
                          id: 'dashboard.store_details.general_tab.store_name',
                        })}
                        disabled={user?.profileType === 'seller' ? true : false}
                      />
                    </Col>
                    <Col lg={6}>
                      <AutocompleteMultipleController
                        name="storeTypes"
                        control={control}
                        label={intl.formatMessage({
                          id: 'dashboard.store_details.general_tab.store_type',
                        })}
                        options={storeTypes}
                        defaultOptions={defaultStoreTypes}
                        required
                        canEdit={user?.profileType === 'admin' ? true : false}
                      />
                    </Col>
                  </Row>
                  <Row className="gx-vtl-26">
                    <Col lg={6}>
                      <InputController
                        name="email"
                        required
                        control={control}
                        label={intl.formatMessage({
                          id: 'dashboard.store_details.general_tab.store_email',
                        })}
                        disabled={user?.profileType === 'seller' ? true : false}
                      />
                    </Col>
                    <Col lg={6}>
                      <PhoneController
                        name="phone"
                        control={control}
                        label={intl.formatMessage({
                          id: 'dashboard.store_details.general_tab.phone',
                        })}
                        phoneInputProps={{international: true}}
                        required
                        canEdit={user?.profileType === 'seller' ? true : false}
                      />
                    </Col>
                  </Row>
                  {/* Store info page internal URL */}
                  <InputController
                    name="storeUrl"
                    control={control}
                    label={intl.formatMessage({
                      id: 'dashboard.store_details.general_tab.store_page_internal',
                    })}
                    arrowLink
                    prepend={`${
                      process.env.REACT_APP_PUBLIC_URL ??
                      'https://mealmap.co.uk'
                    }/restaurant/`}
                    onArrowClick={() =>
                      openInNewTab(
                        `${
                          process.env.REACT_APP_PUBLIC_URL ??
                          'https://mealmap.co.uk'
                        }/restaurant/${internalUrl}`
                      )
                    }
                    required
                    disabled={user?.profileType === 'seller' ? true : false}
                  />
                  {/* Store info page external URL */}
                  <InputController
                    name="externalStoreUrl"
                    control={control}
                    label={intl.formatMessage({
                      id: 'dashboard.store_details.general_tab.store_page_external',
                    })}
                    arrowLink
                    onArrowClick={() => openInNewTab(externalUrl)}
                    disabled={user?.profileType === 'seller' ? true : false}
                  />
                  {/* Store description */}
                  <InputController
                    name="description"
                    control={control}
                    label={intl.formatMessage({
                      id: 'dashboard.store_details.general_tab.store_descrption',
                    })}
                  />
                  <Row className="gx-vtl-26 gy-3">
                    <Col lg={6}>
                      <AddressInput
                        control={control}
                        updateAddressError={updateAddressError}
                        setEnteredValue={setEnteredValue}
                        addressError={addressError}
                        address={address}
                        updateAddressValue={updateAddressValue}
                        isSuccess={isSuccess}
                        required
                      />
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="mb-0 d-flex flex-column h-100">
                        <span className="small-label pb-2">
                          {intl.formatMessage({
                            id: 'dashboard.store_details.general_tab.store_location',
                          })}
                        </span>
                        <div className="flex-fill rounded-sm overflow-hidden">
                          {address && (
                            <MapView
                              lat={address.latitude}
                              lng={address.longitude}
                              title={address.address}
                            />
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* Stores links */}
              <div className="card details-card mt-3">
                <div className="card-body">
                  <Stack className="gap-3 gap-lg-fg">
                    <AppsLink
                      control={control}
                      name="appleAppUrl"
                      label={intl.formatMessage({
                        id: 'dashboard.store_details.general_tab.store_app_url_appstore',
                      })}
                      value={appleAppUrl}
                      disabled={user?.profileType === 'seller' ? true : false}
                    />
                    <AppsLink
                      control={control}
                      name="googleAppUrl"
                      label={intl.formatMessage({
                        id: 'dashboard.store_details.general_tab.store_app_url_google',
                      })}
                      value={googleAppUrl}
                      disabled={user?.profileType === 'seller' ? true : false}
                    />
                  </Stack>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
      <DevTool control={control} />
    </Container>
  );
};

export default GeneralTab;
