import {Col, Container, Row, Stack} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import {Helmet} from 'react-helmet';
import {HelmetProvider} from 'react-helmet-async';
import useTitle from '../../hooks/useTitle';
import TopPaper from '../Common/TopPaper';
import useOrderList, {
  UseOrderListFilterSearchProps,
} from '../../hooks/order/useOrderList';
import DataTable from '../Widgets/DataTable';
import KeywordSearch from '../Form/KeywordSearch';
import DynamicFilter from '../Form/DynamicFilter';
import useOrderFilter from '../../hooks/order/useOrderFilter';
import {useLocation, useNavigate} from 'react-router-dom';
import {Order} from '../../types/Order';
import {useState} from 'react';
import OrderListHeader from './OrderListHeader';
import FormattedCurrency from '../Shared/FormattedCurrency';
import moment from 'moment';
import {upperFirst} from 'lodash';
import {Typography, useTheme} from '@material-ui/core';
import ArrowLink from '../Common/ArrowLink';
import {selectUserAndToken} from '../../store/slices/authSlice';
import {useSelector} from 'react-redux';
import {UserProfileEnum} from '../../types/User';
import {ReactComponent as AccountIcon} from '../../assets/images/account.svg';
import {ReactComponent as StoreIcon} from '../../assets/images/store.svg';
import StatusIndicator from '../Common/StatusIndicator';
import {HeadCellType} from '../Widgets/DataTable/DataTableHead';

const OrderList = () => {
  const intl = useIntl();
  // Page title
  const {intlTitle} = useTitle();
  const navigate = useNavigate();
  const {user} = useSelector(selectUserAndToken);

  const {search} = useLocation();
  const searchParams = new URLSearchParams(search);
  const tab = searchParams.get('tab');

  const defaultTab = tab ?? 'pending';
  const {items, sellerStoresLength} = useOrderFilter();
  const theme = useTheme();
  const isAdmin = user?.profileType === UserProfileEnum.Admin;

  // Active tab
  const [activeTab, setActiveTab] = useState(defaultTab);

  const filterObject: UseOrderListFilterSearchProps = {
    searchParams: search,
    activeTab,
  };

  const getFormattedOrderData = (orders: Order[]) => {
    return orders.map(order => ({
      id: order.id,
      orderDate: new Date(order.createdAt),
      orderId: order.reference,
      buyerName: {
        buyer: {
          fullName: `${order.orderUser?.firstName} ${order.orderUser?.lastName}`,
          id: order.orderUser?.id,
          userId: order.orderUser?.userId,
        },
        store: {fullName: order.store?.name, id: order.store?.id},
      },
      totalAmount: (
        <FormattedCurrency
          value={order.totalAmount}
          currency={order.currency}
        />
      ),
      orderType: {
        type: upperFirst(order.serviceType),
        time:
          order.scheduledTime === 'ASAP'
            ? 'ASAP'
            : `At ${moment(order.scheduledTime, 'HH:mm').format('LT')}`,
      },
      status: {
        label: order.status.toLowerCase(),
        status: order.status.toLowerCase(),
      },
    }));
  };

  const {isLoading, data, handleRowClick, meta, tableState} =
    useOrderList(filterObject);

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      // take all the query params and update the tab for current tab
      navigateAndUpdateQuery('tab', tab);
    }
  };

  const navigateAndUpdateQuery = (key: string, newValue: string) => {
    const searchParams = new URLSearchParams(search);
    // Update the query parameter
    searchParams.set(key, newValue);
    // Navigate to the updated URL
    navigate({search: searchParams.toString()});
  };

  const renderNames = (val: any) => {
    const {buyer, store} = val;
    return (
      <Stack gap={1} className="pb-2 py-lg-2">
        {buyer.userId && isAdmin ? (
          <ArrowLink
            icon={<AccountIcon />}
            className="text-dark"
            title={buyer.fullName}
            url={`/admin/users/buyer/${buyer.userId}/profile`}
          />
        ) : (
          <Stack direction="horizontal" gap={1}>
            <AccountIcon />
            <Typography className="text-dark">{buyer.fullName}</Typography>
          </Stack>
        )}
        <ArrowLink
          className="text-dark"
          isExternalLink
          icon={<StoreIcon style={{color: theme.palette.text.secondary}} />}
          title={store.fullName}
          url={`stores/${store.id}/general`}
        />
      </Stack>
    );
  };

  const renderCell = (val: any) => (
    <div className="pb-1 pb-lg-3">
      <Typography>{val}</Typography>
    </div>
  );

  const renderOrderType = (val: any) => (
    <Stack gap={1}>
      <Typography>{val.type}</Typography>
      <Typography color="textSecondary">{val.time}</Typography>
    </Stack>
  );

  const renderDateTime = (val: any) => {
    const date = moment(val);
    return (
      <Stack gap={1} className="ps-vtl-14 ps-lg-0">
        <Typography>{date.format('DD MMM YYYY')}</Typography>
        <Typography color="textSecondary">{date.format('LT')}</Typography>
      </Stack>
    );
  };

  const renderStatus = (val: any) => {
    // status indicator
    const statusColor: any = {
      completed: 'green',
      pending: 'secondary',
      accepted: 'orange',
      outfordelivery: 'orange',
      readyforpickup: 'green',
      cancelled: 'error',
      warning: 'yellow',
      declined: 'secondary',
    };

    return (
      <div className="pb-1 pb-lg-vtl-15">
        <StatusIndicator
          label={intl.formatMessage({
            id: `order_list.order_status.${val.label.toLowerCase()}`,
          })}
          color={statusColor[val.status]}
        />
      </div>
    );
  };

  const HeaderCells: HeadCellType[] = [
    {
      name: 'orderDate',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'order_list.order_date_time'}),
      collapse: false,
      renderValue: (row: any) => renderDateTime(row),
      sortable: true,
    },
    {
      name: 'orderId',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'order_list.order_id'}),
      renderValue: (row: any) => renderCell(row),
      collapse: true,
      sortable: true,
    },
    {
      name: 'buyerName',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'order_list.buyer_store_name'}),
      collapse: true,
      width: '20%',
      renderValue: (row: any) => renderNames(row),
      sortable: true,
    },
    {
      name: 'totalAmount',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'order_list.total_amount'}),
      renderValue: (row: any) => renderCell(row),
      collapse: true,
      sortable: true,
    },
    {
      name: 'orderType',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'order_list.order_type'}),
      renderValue: (row: any) => renderOrderType(row),
      collapse: true,
      sortable: true,
    },
    {
      name: 'status',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'order_list.status'}),
      renderValue: (row: any) => renderStatus(row),
      collapse: false,
      sortable: true,
    },
  ];

  return (
    <HelmetProvider>
      <Helmet>
        <title>{intlTitle('order_list.header')}</title>
      </Helmet>
      <div className="dashboard-wrapper tabs-contained">
        <Container fluid>
          <TopPaper>
            <OrderListHeader
              filterObject={filterObject}
              toggle={toggle}
              defaultTab={defaultTab}
            />
          </TopPaper>
          <div className="dashboard-content">
            {sellerStoresLength > 0 && (
              <Row className="align-items-center justify-content-between search-row mb-lg-4">
                <Col className="col-xl-4 col-lg-8 d-flex">
                  {/* Search */}
                  <KeywordSearch
                    placeholder={intl.formatMessage({
                      id: `order_list.${
                        sellerStoresLength > 1
                          ? 'search_buyer_store'
                          : 'search_buyer'
                      }`,
                    })}
                    resetPageOnChange
                  />
                </Col>
                {/* Button toolbar */}
                <Col className="col-auto d-lg-block ps-0 ps-lg-2">
                  <Stack direction="horizontal" gap={2}>
                    <DynamicFilter
                      items={items}
                      bindQueryParams
                      resetPageOnFilter
                    />
                  </Stack>
                </Col>
              </Row>
            )}
            {/* Data table */}
            <DataTable
              headCells={HeaderCells}
              loading={isLoading}
              rows={data ? getFormattedOrderData(data) : []}
              onChange={() => {}}
              emptyDataMessage={intl.formatMessage({
                id: 'order_list.no_order_message',
              })}
              total={meta ? meta.itemCount : 100}
              onRowClick={handleRowClick}
              currentPage={tableState.page}
              defaultRowPerPage={tableState.pageSize}
              defaultOrder={tableState.orderDirection.toLowerCase() as any}
              defaultOrderBy={tableState.orderBy}
              handleParameterChange={tableState.handleDataTableParamChange}
            />
          </div>
        </Container>
      </div>
    </HelmetProvider>
  );
};

export default OrderList;
