import {BillingDetails} from './BillingDetails';
import {PaymentMethodType} from './Payment';

export interface StatementStore {
  storeName: string;
  storeBillingAddress: BillingDetails;
}

export enum StatementStatusEnum {
  Pending = 'Pending',
  Completed = 'Completed',
}

export interface Statement {
  id: number;
  store: StatementStore;
  startDate: Date;
  endDate: Date;
  totalCashAmount: number;
  totalCardAmount: number;
  totalCardFee: number;
  totalSellerFee: number;
  totalAmount: number;
  dueAmount: number;
  status: StatementStatusEnum;
  createdAt: Date;
  updatedAt: Date;
  currency: string;
}

export interface StatementOrder {
  id: number;
  reference: string;
  currency: string;
  sellerFee: number;
  cardFee: number;
  paymentMethod: PaymentMethodType;
  totalAmount: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface StatementOrder {
  id: number;
  reference: string;
  sellerFee: number;
  cardFee: number;
  paymentMethod: PaymentMethodType;
  totalAmount: number;
  createdAt: Date;
  updatedAt: Date;
}
