import {ReactNode, useEffect, useState} from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import BaseInput from './BaseInput';

export type SeletItemType = {
  label: string;
  value: any;
  color?: string;
};

export type CustomSelectType = {
  /**
   * Rendering view type
   */
  variant?: 'cropped' | 'default' | 'dropdown';
  /**
   * Select is filled with white color
   */
  filled?: boolean;
  /**
   * Input has a border
   */
  outlined?: boolean;
  /**
   * Is disabled
   */
  disabled?: boolean;
  /**
   * Class name
   */
  selectClassName?: string;
  /**
   * Menu options
   */
  items: SeletItemType[];
  /**
   * Selected option value
   */
  value: string;
  /**
   * Selector empty select placeholder
   */
  placeholder?: string;
  /**
   * Callback function fired when a menu item is selected
   */
  onChange: (value: any) => void;
  /**
   * Item rendering procedure
   */
  renderSelectItem?: (item: any) => any | undefined;
  /**
   * Extra content to render below the Select Tag
   */
  extraChild?: ReactNode | undefined;
  /**
   * Props to be provided to FormControl tag
   */
  formControlProps?: any;
};

export type CustomSelectProps = CustomSelectType;

// Dropdown icon
const DropdownIcon = ({className, disabled}: any) => (
  <SvgIcon
    viewBox="0 0 9 6"
    className={clsx(className, {'text-muted': disabled})}
  >
    <g
      id="Filter/Location"
      transform="translate(-191.000000, -246.000000)"
      fill="currentColor"
      stroke="currentColor"
    >
      <path
        d="M197.893917,248.776407 L193.609399,245.086317 C193.467558,244.968335 193.241532,244.971723 193.104545,245.093885 C192.970926,245.213056 192.970926,245.401975 193.104545,245.521128 L197.136658,248.993813 L193.104545,252.466497 C192.965152,252.586569 192.965152,252.781254 193.104545,252.901326 C193.24398,253.021362 193.470005,253.021362 193.609399,252.901326 L197.893917,249.211218 C198.033311,249.091128 198.033311,248.896479 197.893917,248.776407"
        id="Fill-1"
        transform="translate(195.499231, 248.995677) scale(-1, 1) rotate(-270.000000) translate(-195.499231, -248.995677) "
      />
    </g>
  </SvgIcon>
);

/* Styles */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    select: {
      lineHeight: 1.6,
      width: '100%',

      '& .MuiSelect-root': {
        display: 'flex',
        alignItems: 'center',
        boxShadow: theme.shadows[0],
        borderRadius: 4,
        fontWeight: 'normal',
        fontSize: 14,
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 22,
        '&.MuiSelect-outlined': {
          backgroundColor: 'inherit',
        },
      },
      '& .MuiSelect-icon': {
        fontSize: 10,
        top: 13,
        right: 15,
      },

      '&.cropped': {
        width: 'auto',
        display: 'inline-flex',
        padding: theme.spacing(1),
        textAlign: 'center',
        '& .MuiSelect-root': {
          boxShadow: theme.shadows[4],
          borderRadius: 10,
          fontSize: 12,
          fontFamily: 'Averta Semibold',
        },
        '& .MuiSelect-icon': {
          fontSize: 9,
          right: 19,
          top: 26,
        },
      },
      '&.outlined': {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,
        padding: 0,
        '& .MuiSelect-root': {
          boxShadow: 'none !important',
          paddingTop: 5.5,
          paddingBottom: 6,
        },
        '& .MuiSelect-icon': {
          top: '13px !important',
          right: '15px !important',
        },
      },
    },
    label: {
      color: theme.palette.secondary.light,
      fontSize: 14,
      marginBottom: 0,
      zIndex: 10,
      left: 10,
      top: -14,
      '&.Mui-focused': {
        color: theme.palette.secondary.light,
      },
      '&.Mui-error': {
        color: theme.palette.secondary.light,
      },
      cursor: 'pointer',
    },
    menu: {
      marginTop: 8,
      marginLeft: 0,
      '& .MuiPaper-root': {
        boxShadow: theme.shadows[1],
        maxHeight: 321,
        marginRight: -5,
        '& > ul': {
          padding: 0,
        },
      },
      '&.cropped': {
        '& .MuiPaper-root': {
          borderRadius: '0 0 8px 8px',
          maxHeight: 250,
          [theme.breakpoints.down('lg')]: {
            maxHeight: 'calc(50vh - 50px)',
          },
        },
      },
      '&.dropdown': {
        '& .MuiPaper-root': {
          borderRadius: theme.spacing(0.8),
          boxShadow: theme.shadows[7],
          maxHeight: 200,
          '& > ul': {
            '& li:first-of-type': {
              marginTop: theme.spacing(1.3),
            },
            '& li:last-of-type': {
              marginBottom: theme.spacing(1.3),
            },
          },
        },
      },
    },
    menuItem: {
      fontSize: 14,
      padding: '12px 16px',
      borderTop: '1px solid #EDEDED',
      '&:first-child': {
        borderTop: 'none',
      },
      '&.dropdown': {
        borderTop: 'none',
        padding: theme.spacing(0.7, 2),
        lineHeight: `${theme.spacing(2)}px`,
        margin: theme.spacing(0.3, 0),
        '&.Mui-selected': {
          backgroundColor: theme.palette.background.default,
        },
      },
    },
  })
);

/**
 * Select component
 */
const CustomSelect = ({
  value,
  filled,
  disabled,
  onChange,
  items,
  placeholder,
  variant,
  outlined,
  extraChild,
  formControlProps,
  selectClassName = '',
  renderSelectItem,
}: CustomSelectType) => {
  const classes = useStyles();
  // selected value
  const [currentValue, setCurrentValue] = useState(value);
  const [open, setOpen] = useState(false);

  // on select callback
  const onSelectChange = (e: any) => {
    setCurrentValue(e?.target?.value);
    onChange(e?.target?.value);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <FormControl className={classes.root} {...formControlProps}>
      {placeholder && !value && (
        <InputLabel
          onClick={() => setOpen(() => !open)}
          htmlFor="grouped-select"
          shrink={false}
          className={classes.label}
        >
          {placeholder}
        </InputLabel>
      )}
      <Select
        fullWidth
        open={open}
        variant={filled ? 'filled' : 'outlined'}
        className={clsx(
          clsx(classes.select, selectClassName, {
            'with-error': formControlProps && !!formControlProps?.error,
          }),
          variant,
          {outlined}
        )}
        value={currentValue}
        input={
          <BaseInput
            onClick={() => setOpen(() => !open)}
            value={currentValue}
          />
        }
        // eslint-disable-next-line react/no-unstable-nested-components
        IconComponent={({className}: any) => (
          <DropdownIcon className={className} disabled={disabled} />
        )}
        MenuProps={{
          elevation: 0,
          anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
          getContentAnchorEl: null,
          autoFocus: false,
          className: clsx(classes.menu, variant),
        }}
        onClose={() => setOpen(false)}
        onChange={onSelectChange}
        disabled={disabled}
      >
        {items.map((item, i) => (
          <MenuItem
            key={`select-item_${i}`}
            value={item.value}
            className={clsx(classes.menuItem, {
              dropdown: variant === 'dropdown',
            })}
          >
            {typeof renderSelectItem === 'function'
              ? renderSelectItem(item)
              : item.label}
          </MenuItem>
        ))}
      </Select>
      {extraChild}
    </FormControl>
  );
};

CustomSelect.defaultProps = {
  disabled: false,
  variant: 'cropped',
  filled: true,
  selectClassName: '',
  placeholder: '',
  outlined: false,
  formControlProps: {},
  extraChild: undefined,
  renderSelectItem: undefined,
};

export default CustomSelect;
