import {Container, Row, Col} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage} from 'react-intl';
import StoreList from './StoreList';
import useStoreType from '../../hooks/useStoreType';
import {StoreType} from '../../types/StoreType';

/**
 * App Settings
 */

const Settings = () => {
  const {
    control,
    handleSubmit,
    createStoreType,
    storeTypeList,
    deleteStoreType,
    editStoreType,
  } = useStoreType();

  return (
    <div className="dashboard-wrapper">
      <Container>
        <div className="row align-items-center header-row pb-lg-4 mb-2">
          <div className="col-lg-6 col-sm-12 text-center text-lg-start">
            <Typography variant="h3" className="font-weight-bolder">
              <FormattedMessage id="dashboard.app_settings.title" />
            </Typography>
          </div>
        </div>

        <Row>
          <Col xl={8}>
            <div className="card details-card">
              <div className="card-body py-3 px-0">
                <StoreList
                  storeTypes={storeTypeList as StoreType[]}
                  onDelete={deleteStoreType}
                  editStoreType={editStoreType}
                  handleSubmit={handleSubmit}
                  createStoreType={createStoreType}
                  control={control}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Settings;
