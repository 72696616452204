import {useForm} from 'react-hook-form';
import {useDeleteAppAssetMutation} from '../../services/appApi';
import {Asset, AssetType} from '../../types/Asset';
import {formatErrorMessage} from '../../utils/functions';
import {useIntl} from 'react-intl';
import axios, {
  AxiosProgressEvent,
  AxiosRequestConfig,
  CancelTokenSource,
} from 'axios';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from '../../store/slices/authSlice';
import {useDispatch} from 'react-redux';
import {enqueueErrorMessage} from '../../store/slices/appSlice';
import axiosInstance from '../../services/axiosFunctions';
import {baseApi} from '../../services/baseApi';

type UseAppAssetProps = {
  appId: number;
  asset: Asset;
  closeConfirmationPopup: () => void;
};
export type AssetFormValues = {
  [key: string]: any;
  image: any;
};

const useAppAsset = (props: UseAppAssetProps) => {
  const {appId, asset, closeConfirmationPopup} = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const {accessToken} = useSelector(selectUserAndToken);
  const acceptedSize =
    asset.type === AssetType.SplashScreen
      ? {width: 2732, height: 2732}
      : {width: 1024, height: 1024};

  const validAssetOptions = {
    maxSize: 5 * 1024 * 1024,
    type: ['image/png'],
    size: acceptedSize,
  };

  const validateLogoOptions = {
    maxSize: 5 * 1024 * 1024,
    type: ['image/png'],
  };

  const [progress, setProgress] = useState<number>(0);
  const [startUpload, setStartUpload] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<boolean>();
  const [cancelTokenSource, setCancelTokenSource] =
    useState<CancelTokenSource | null>(null);

  const [deleteAsset, {isSuccess: deleteSuccess}] = useDeleteAppAssetMutation();

  const {control, setError, clearErrors, handleSubmit} =
    useForm<AssetFormValues>({
      mode: 'onTouched',
    });

  const handleDeleteAsset = () => {
    deleteAsset({
      appId,
      id: asset.id,
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatErrorMessage(error, intl);
      },
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.delete_asset',
          },
          {}
        );
      },
    });
    closeConfirmationPopup();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submit = (data: AssetFormValues) => {};

  const handleImageUpload = async (imageData: File) => {
    setStartUpload(true);
    const file = imageData;
    const formData = new FormData();
    formData.append('file', file);

    // Setup the axios cancel token source
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    // Prepare the Axios request configuration
    const config: AxiosRequestConfig = {
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (!progressEvent?.total) {
          setProgress(0);
          return;
        }
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        setProgress(progress);
      },
      cancelToken: source.token,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `${accessToken}`,
      },
    };

    try {
      const result = await axiosInstance.put(
        `/admin/app/${appId}/asset/${asset.id}`,
        formData,
        config
      );
      setUploadStatus(true);
      setStartUpload(false);
      result && dispatch(baseApi.util.invalidateTags(['App']));
    } catch (error: any) {
      setUploadStatus(false);
      setStartUpload(false);
      if (error.response?.data?.message === 'INVALID_ICON_ASSET_PROVIDED') {
        dispatch(
          enqueueErrorMessage(
            intl.formatMessage({
              id: 'messages.invalid_icon_asset_provided',
            })
          )
        );
        return;
      } else if (
        error.response?.data?.message === 'INVALID_SPLASH_ASSET_PROVIDED'
      ) {
        dispatch(
          enqueueErrorMessage(
            intl.formatMessage({
              id: 'messages.invalid_splash_asset_provided',
            })
          )
        );
        return;
      } else if (error.response?.data?.message === 'INVALID_ASSET_DIMENSIONS') {
        dispatch(
          enqueueErrorMessage(
            intl.formatMessage({
              id: 'messages.invalid_asset_dimensions',
            })
          )
        );
        return;
      }
      if (axios.isCancel(error)) {
        return;
      }

      dispatch(enqueueErrorMessage(formatErrorMessage(error, intl)));
    }
  };

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('User canceled the upload');
    }
  };

  return {
    deleteSuccess,
    handleDeleteAsset,
    handleSubmit,
    submit,
    control,
    setError,
    clearErrors,
    validAssetOptions,
    progress,
    uploadStatus,
    cancelUpload,
    handleImageUpload,
    startUpload,
    validateLogoOptions,
  };
};

export default useAppAsset;
