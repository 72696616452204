import {Price} from './Price';
import {ProductCategory} from './ProductCategory';
import {ProductOptionGroup} from './ProductOptionGroup';
import {Store} from './Store';

export enum ProductStatus {
  On = 'on',
  Off = 'off',
  OffForToday = 'offForToday',
  deleted = 'deleted',
}

export interface Product {
  id: number;
  name: string;
  chineseName?: string;
  image: string;
  sku: string;
  price: Price;
  description: string;
  status: ProductStatus;
  rank: number;
  externalId?: string;
  optionGroups: ProductOptionGroup[];
  categoryId?: number;
  category: ProductCategory;
  store: Store;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}
