import {FormHelperText} from '@material-ui/core';
import {DatePickerProps} from '@material-ui/pickers';
import moment from 'moment';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import {useIntl} from 'react-intl';
import DatePicker from '../Inputs/DatePicker';

export type DateBetweenControllerProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  displayFormat?: string;
  dateFormat?: string;
  outlined?: boolean;
  InputProps?: DatePickerProps['InputProps'];
  InputPropsFrom?: DatePickerProps['InputProps'];
  InputPropsTo?: DatePickerProps['InputProps'];
};

const DateBetweenController = <T extends FieldValues>({
  name,
  control,
  displayFormat,
  dateFormat = 'YYYY-MM-DD',
  outlined,
  InputProps,
  InputPropsFrom,
  InputPropsTo,
}: DateBetweenControllerProps<T>) => {
  const intl = useIntl();

  // Format date from iFormat to fFormat
  const formatDate = (
    date: string,
    iFormat?: string,
    fFormat?: string,
    defaultValue = null
  ) => {
    const m = moment(date, iFormat);

    return m.isValid() ? m.format(fFormat) : defaultValue;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {value, onChange}, fieldState: {error}}) => {
        let valueFrom: string | null = null;
        let valueTo: string | null = null;

        if (typeof value === 'object') {
          const {from, to} = value;
          valueFrom = formatDate(from, dateFormat, displayFormat);
          valueTo = formatDate(to, dateFormat, displayFormat);
        }

        const handleDateChange = (val: string, inputKey: string) => {
          onChange({
            ...value,
            [inputKey]: formatDate(val, displayFormat, dateFormat),
          });
        };

        return (
          <div className="date-selects-wrapper">
            {/* In between variant' inputs */}
            <div className="in-between-inputs">
              {/* Date From */}
              <DatePicker
                value={valueFrom}
                format={displayFormat}
                {...{outlined}}
                placeholder={intl.formatMessage({id: 'actions.from'})}
                onChange={(value: string) => handleDateChange(value, 'from')}
                {...(valueTo ? {maxDate: valueTo} : {})}
                {...InputProps}
                {...InputPropsFrom}
              />
              {/* Date To */}
              <DatePicker
                value={valueTo}
                format={displayFormat}
                {...{outlined}}
                placeholder={intl.formatMessage({id: 'actions.to'})}
                onChange={(value: string) => handleDateChange(value, 'to')}
                {...(valueFrom ? {minDate: valueFrom} : {})}
                {...InputProps}
                {...InputPropsTo}
              />
            </div>
            {error && <FormHelperText>{String(error.message)}</FormHelperText>}
          </div>
        );
      }}
    />
  );
};

export default DateBetweenController;
