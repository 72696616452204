import {StoreScheduleItem} from './StoreScheduleItem';
export enum MenuStatus {
  On = 'on',
  Off = 'off',
}
export interface StoreSchedule {
  id: number;
  day: string;
  status: MenuStatus;
  items: StoreScheduleItem[];
}
