import {useIntl} from 'react-intl';
import {useEffect, useMemo, useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {
  countDirtyFields,
  formatErrorMessage,
  isApiValidationError,
  mapErrorToFormFields,
} from '../utils/functions';
import {CustomError} from '../types/CustomError';
import {
  UpdateAppThirdPartyIntegrationParameter,
  UpdateAuthProviderParameter,
  useUpdateAppThirdPartyIntegrationMutation,
  useUpdateAuthProviderMutation,
} from '../services/appApi';
import {
  ThirdPartyIntegration,
  ThirdPartyIntegrationSecrets,
  ThirdPartyIntegrationServiceId,
} from '../types/ThirdPartyIntegration';
import useGetAppThirdPartyIntegration from './useGetAppThirdPartyIntegration';
import useGetAppAuthProvider from './useGetAppAuthProvider';
import {
  AuthProvider,
  AuthProviderIdEnum,
  AuthProviderSecrets,
} from '../types/AuthProvider';

export type ThirdPartyIntegrationFormData = {
  googleMapsApiKey: string;
  googleAuthenticationClientAndroid: string;
  googleAuthenticationClientIos: string;
  googleAuthenticationIosUrlSchema: string;
  facebookAuthenticationClient: string;
  facebookAuthenticationToken: string;
  facebookAuthenticationClientSecret: string;
};

export type UseUpdateAppThirdPartyIntegrationProps = {
  appId: number;
};
const useUpdateAppThirdPartyIntegration = ({
  appId,
}: UseUpdateAppThirdPartyIntegrationProps) => {
  // translations
  const intl = useIntl();
  const [count, setCount] = useState(0);

  const schema = useMemo(
    () =>
      yup.object().shape({
        googleMapsApiKey: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
        googleAuthenticationClientAndroid: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
        googleAuthenticationIosUrlSchema: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
        googleAuthenticationClientIos: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
        facebookAuthenticationClient: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
        facebookAuthenticationToken: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
        facebookAuthenticationClientSecret: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
      }),
    [intl]
  );

  const {data: integrationData, isSuccess: isIntegrationDataSuccess} =
    useGetAppThirdPartyIntegration({
      appId: Number(appId),
    });

  const {data: authProviderData, isSuccess: isAuthProviderDataSuccess} =
    useGetAppAuthProvider({
      appId: Number(appId),
    });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    watch,
    setValue,
    getValues,
    formState: {errors, submitCount, dirtyFields},
  } = useForm<ThirdPartyIntegrationFormData>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });
  const formValuesWatch = watch();

  const [
    updateThirdPartyIntegration,
    {isSuccess, isLoading, data, error: createAppError},
  ] = useUpdateAppThirdPartyIntegrationMutation();

  const [
    updateAuthProvider,
    {
      isSuccess: isSuccessProvider,
      isLoading: isLoadingProvider,
      data: dataProvider,
      error: errorProvider,
    },
  ] = useUpdateAuthProviderMutation();

  const createReqIntegrationPayload = (
    integration: ThirdPartyIntegration,
    serviceId: ThirdPartyIntegrationServiceId,
    secrets: ThirdPartyIntegrationSecrets
  ) => {
    const data: UpdateAppThirdPartyIntegrationParameter = {
      appId,
      integrationId: integration.id,
      body: {
        appId,
        serviceId,
        secrets: secrets,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.app_integration_updated_success_message',
          },
          {
            integration_name: integration.serviceName,
          }
        );
      },
    };
    return data;
  };

  const createReqProviderPayload = (
    provider: AuthProvider,
    providerId: AuthProviderIdEnum,
    secrets: AuthProviderSecrets
  ) => {
    const data: UpdateAuthProviderParameter = {
      appId,
      authProviderId: provider.id,
      body: {
        appId,
        providerId,
        secrets: secrets,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.app_auth_provider_updated_success_message',
          },
          {
            provider_name: provider.providerName,
          }
        );
      },
    };
    return data;
  };

  // submit request
  const onSubmit = async (formData: ThirdPartyIntegrationFormData) => {
    const defaultIntegrationSecrets = {apiKey: ''};
    const defaultProviderSecrets = {
      iosClientId: '',
      androidClientId: '',
      iosUrlScheme: '',
      appId: '',
      clientToken: '',
      clientSecret: '',
    };
    integrationData?.forEach(async integration => {
      let data;
      if (
        integration.serviceId === ThirdPartyIntegrationServiceId.GoogleMap &&
        dirtyFields['googleMapsApiKey']
      ) {
        data = createReqIntegrationPayload(
          integration,
          ThirdPartyIntegrationServiceId.GoogleMap,
          {
            ...defaultIntegrationSecrets,
            apiKey: formData.googleMapsApiKey,
          }
        );
      }
      if (data) {
        await updateThirdPartyIntegration(data);
      }
    });
    authProviderData?.forEach(async provider => {
      let data;
      if (
        provider.providerId === AuthProviderIdEnum.Facebook &&
        (dirtyFields['facebookAuthenticationClient'] ||
          dirtyFields['facebookAuthenticationToken'] ||
          dirtyFields['facebookAuthenticationClientSecret'])
      ) {
        data = createReqProviderPayload(provider, AuthProviderIdEnum.Facebook, {
          ...defaultProviderSecrets,
          appId: formData.facebookAuthenticationClient,
          clientToken: formData.facebookAuthenticationToken,
          clientSecret: formData.facebookAuthenticationClientSecret,
        });
      } else if (
        provider.providerId === AuthProviderIdEnum.Google &&
        (dirtyFields['googleAuthenticationClientIos'] ||
          dirtyFields['googleAuthenticationClientAndroid'] ||
          dirtyFields['googleAuthenticationIosUrlSchema'])
      ) {
        data = createReqProviderPayload(provider, AuthProviderIdEnum.Google, {
          ...defaultProviderSecrets,
          iosClientId: formData.googleAuthenticationClientIos,
          androidClientId: formData.googleAuthenticationClientAndroid,
          iosUrlScheme: formData.googleAuthenticationIosUrlSchema,
        });
      }
      if (data) {
        await updateAuthProvider(data);
      }
    });
  };

  // Format api validation errors to input field
  useEffect(() => {
    if (createAppError) {
      const typedUpdateError = createAppError as CustomError;
      if (isApiValidationError(typedUpdateError)) {
        mapErrorToFormFields(typedUpdateError.data.message, setError);
      }
    }
  }, [createAppError]);

  // clear form fields after successful creation of store
  useEffect(() => {
    if (isSuccess && data) {
      reset();
    }
    if (isSuccessProvider && dataProvider) {
      reset();
    }
  }, [isSuccess, data, isSuccessProvider, dataProvider]);

  useEffect(() => {
    if (formValuesWatch) {
      setCount(countDirtyFields(dirtyFields));
    }
  }, [formValuesWatch]);

  useEffect(() => {
    if (integrationData && isIntegrationDataSuccess) {
      resetFormValues();
    }
  }, [integrationData, isIntegrationDataSuccess]);

  useEffect(() => {
    if (authProviderData && isAuthProviderDataSuccess) {
      resetFormValues();
    }
  }, [authProviderData, isAuthProviderDataSuccess]);

  const resetFormValues = () => {
    reset();
    integrationData?.forEach(integration => {
      if (integration.serviceId === ThirdPartyIntegrationServiceId.GoogleMap) {
        setValue('googleMapsApiKey', integration.secrets.apiKey);
      }
    });
    authProviderData?.forEach(provider => {
      if (provider.providerId === AuthProviderIdEnum.Facebook) {
        setValue('facebookAuthenticationClient', provider.secrets.appId);
        setValue('facebookAuthenticationToken', provider.secrets.clientToken);
        setValue(
          'facebookAuthenticationClientSecret',
          provider.secrets.clientSecret
        );
      } else if (provider.providerId === AuthProviderIdEnum.Google) {
        setValue('googleAuthenticationClientIos', provider.secrets.iosClientId);
        setValue(
          'googleAuthenticationClientAndroid',
          provider.secrets.androidClientId
        );
        setValue(
          'googleAuthenticationIosUrlSchema',
          provider.secrets.iosUrlScheme
        );
      }
    });
  };

  return {
    onSubmit,
    isSuccess,
    isLoading,
    data,
    control,
    handleSubmit,
    submitCount,
    errors,
    count,
    resetFormValues,
    getValues,
    isLoadingProvider,
    errorProvider,
  };
};

export default useUpdateAppThirdPartyIntegration;
