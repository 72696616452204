import React, {useMemo} from 'react';
import moment from 'moment';
import {useIntl} from 'react-intl';
import {Col, Row} from 'react-bootstrap';
import KeywordSearch from '../Form/KeywordSearch';
import DynamicFilter from '../Form/DynamicFilter';
import useStatementList from '../../hooks/useStatementList';
import DataTable from '../Widgets/DataTable';
import InvoicePopup from './Details/InvoicePopup';
import {Statement, StatementStatusEnum} from '../../types/Statement';
import useStatementFilter from '../../hooks/statement/userStatementFilter';
import useStatementRowActions from '../../hooks/statement/useStatementRowActions';
import StatementOrderList from './StatementOrderList';
import {UseStatementOrderListFilterSearchProps} from '../../hooks/useStatementOrderList';
import {capitalizeFirstLetter} from '../../utils/functions';
import StatusIndicator from '../Common/StatusIndicator';
import {Typography} from '@material-ui/core';
import ArrowLink from '../Common/ArrowLink';
import {HeadCellType} from '../Widgets/DataTable/DataTableHead';
import FormattedCurrency from '../Shared/FormattedCurrency';

export type StatementListItemType = {
  storeName: string;
  id: number;
  period: {
    from: Date;
    to: Date;
  };
  totalCashAmount: number;
  totalCardAmount: number;
  totalCardFee: number;
  totalSellerFee: number;
  totalAmount: number;
  status: StatementStatusEnum;
};

export type SendInvoiceStatementType = {
  statement?: StatementListItemType | undefined;
  statementId?: number;
};

const StatementList = () => {
  const intl = useIntl();

  const {items} = useStatementFilter();

  const {
    isLoading,
    data,
    handleRowClick,
    meta,
    tableState,
    isSuccess,
    isFetching,
    isError,
  } = useStatementList();

  const currency = data?.length ? data[0].currency : 'GBP';

  const {
    getRowActions,
    setInvoicePopupStatementId,
    invoicePopupStatementId,
    sendInvoiceStatement,
  } = useStatementRowActions();

  const getFormattedOrderData = (statements: Statement[]) => {
    return statements.map(statement => ({
      storeName: statement.store.storeName,
      id: statement.id,
      period: {
        from: new Date(statement.startDate),
        to: new Date(statement.endDate),
      },
      totalCashAmount: statement.totalCashAmount,
      totalCardAmount: statement.totalCardAmount,
      totalCardFee: statement.totalCardFee,
      totalSellerFee: statement.totalSellerFee,
      dueAmount: statement.dueAmount,
      status: statement.status,
    }));
  };

  const getSubRows = (props: UseStatementOrderListFilterSearchProps) => {
    return <StatementOrderList {...props} />;
  };

  const renderCell = (val: any) => (
    <div>
      <Typography>{val}</Typography>
    </div>
  );

  const renderNumberCell = (val: any) => (
    <div>
      <FormattedCurrency value={val.toFixed(2)} currency={currency} />
    </div>
  );

  const renderPeriod = ({from, to}: any) => {
    const dateFrom = moment(from);
    const dateTo = moment(to);
    const longFormat = 'DD MMM YY';
    const shortFormat = 'DD MMM';

    return (
      <Typography>
        <span>
          {dateFrom.format(
            dateFrom.isSame(dateTo, 'year') ? shortFormat : longFormat
          )}
        </span>
        -<span>{dateTo.format(longFormat)}</span>
      </Typography>
    );
  };

  const renderStatus = (val: any) => {
    // status indicator
    const statusColor: any = {
      completed: 'green',
      pending: 'secondary',
      in_progress: 'orange',
      cancelled: 'error',
      warning: 'yellow',
    };

    return (
      <div>
        <StatusIndicator
          label={capitalizeFirstLetter(val)}
          color={statusColor[val.toLowerCase()]}
        />
      </div>
    );
  };

  const renderDate = (val: any) => {
    const date = moment(val);
    return <Typography>{date.format('DD MMM YYYY')}</Typography>;
  };

  const renderOrderLink = (val: any) => {
    return <ArrowLink title={val} url="#order_id" />;
  };

  const HeaderCells: HeadCellType[] = useMemo(
    () => [
      {
        name: 'storeName',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({id: 'transaction_list.store_name'}),
        renderValue: (row: any) => renderCell(row),
        collapse: false,
        sortable: true,
        width: '12.1%',
      },
      {
        name: 'period',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({id: 'transaction_list.period'}),
        renderValue: (row: any) => renderPeriod(row),
        collapse: true,
        sortable: true,
        width: '14.52%',
      },
      {
        name: 'totalCashAmount',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({id: 'transaction_list.total_cash'}),
        renderValue: (row: any) => renderNumberCell(row),
        collapse: true,
        sortable: true,
        width: '10.15%',
      },
      {
        name: 'totalCardAmount',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({id: 'transaction_list.total_card'}),
        renderValue: (row: any) => renderNumberCell(row),
        collapse: true,
        sortable: true,
        width: '10.25%',
      },
      {
        name: 'totalCardFee',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({id: 'transaction_list.card_fee'}),
        renderValue: (row: any) => renderNumberCell(row),
        collapse: true,
        sortable: true,
        width: '10.28%',
      },
      {
        name: 'totalSellerFee',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({id: 'transaction_list.seller_fee'}),
        renderValue: (row: any) => renderNumberCell(row),
        collapse: true,
        sortable: true,
      },
      {
        name: 'dueAmount',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({id: 'transaction_list.payout'}),
        renderValue: (row: any) => renderNumberCell(row),
        collapse: true,
        sortable: true,
      },
      {
        name: 'status',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({id: 'transaction_list.status'}),
        renderValue: (row: any) => renderStatus(row),
        collapse: false,
        sortable: true,
      },
      {
        name: 'date',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({id: 'transaction_list.subtable.order_date'}),
        renderValue: (row: any) => renderDate(row),
        wrapped: true,
        width: '15%',
      },
      {
        name: 'id',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({id: 'transaction_list.subtable.order_id'}),
        renderValue: (row: any) => renderOrderLink(row),
        wrapped: true,
      },
      {
        name: 'total',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({
          id: 'transaction_list.subtable.order_total',
        }),
        wrapped: true,
      },
      {
        name: 'method',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({
          id: 'transaction_list.subtable.payment_method',
        }),
        wrapped: true,
      },
      {
        name: 'cardFee',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({
          id: 'transaction_list.card_fee',
        }),
        wrapped: true,
      },
      {
        name: 'sellerFee',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({
          id: 'transaction_list.seller_fee',
        }),
        wrapped: true,
        colspan: 4,
      },
    ],
    []
  );

  return (
    <>
      {!!invoicePopupStatementId && (
        <InvoicePopup
          opened={!!invoicePopupStatementId}
          statementId={invoicePopupStatementId}
          handleClose={() => setInvoicePopupStatementId(0)}
          onSendStatementInvoice={sendInvoiceStatement}
        />
      )}
      <div className="dashboard-content">
        <Row className="align-items-center justify-content-between search-row">
          <Col className="col-xl-5 col-lg-8">
            {/* Search */}
            <KeywordSearch
              placeholder={intl.formatMessage({
                id: 'transaction_list.search_buyer_store',
              })}
              resetPageOnChange
            />
          </Col>
          {/* Button toolbar */}
          <Col className="col-auto d-lg-block ps-0 ps-lg-2">
            <DynamicFilter items={items} bindQueryParams resetPageOnFilter />
          </Col>
        </Row>
        {/* Data table */}
        <DataTable
          headCells={HeaderCells}
          rows={data ? getFormattedOrderData(data) : []}
          loading={isLoading || (!isSuccess && !isError) || isFetching}
          empty={false}
          onChange={() => {}}
          onRowClick={handleRowClick}
          total={meta ? meta.itemCount : 100}
          handleParameterChange={tableState.handleDataTableParamChange}
          wrappedRow
          SubTableRows={getSubRows}
          getRowActions={getRowActions}
          defaultOrder={tableState.orderDirection.toLowerCase() as any}
          defaultOrderBy={tableState.orderBy}
          defaultRowPerPage={tableState.pageSize}
          currentPage={tableState.page}
          emptyDataMessage={intl.formatMessage({
            id: 'transaction_list.no_statement_message',
          })}
          rowClickToggle
        />
      </div>
    </>
  );
};

export default StatementList;
