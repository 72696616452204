import {useIntl} from 'react-intl';
import {Control} from 'react-hook-form';
import {DevTool} from '@hookform/devtools';
import {DevtoolUIProps} from '@hookform/devtools/dist/devToolUI';
import SearchTextController, {
  SearchTextControllerProps,
} from './HookForm/SearchTextController';

type SearchFormValues = {
  search?: string;
};

export type SearchFormProps = {
  devTool?: boolean;
  devToolProps?: Omit<DevtoolUIProps, 'control'>;
  searchProps?: Omit<
    SearchTextControllerProps<SearchFormValues>,
    'name' | 'control'
  >;
  control: Control;
  placeholder?: string;
  onClear?: () => void;
};

/**
 * Search form
 */
const SearchForm = ({
  searchProps,
  devTool,
  devToolProps,
  control,
  placeholder,
  onClear,
}: SearchFormProps) => {
  const intl = useIntl();

  return (
    <>
      <SearchTextController
        placeholder={
          placeholder ?? intl.formatMessage({id: 'actions.searchNameEmail'})
        }
        {...searchProps}
        name="search"
        control={control}
        onClear={onClear}
      />
      {devTool && <DevTool {...devToolProps} control={control} />}
    </>
  );
};

export default SearchForm;
