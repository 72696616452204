import {createSlice, createSelector, PayloadAction} from '@reduxjs/toolkit';
import {User} from '../../types/User';
import {RootState} from '../store';

type AuthState = {
  user?: User;
  isAuthenticated: boolean;
  hasError?: boolean;
  rememberMe?: boolean;
  token?: string;
  accessToken?: string;
  refreshToken?: string;
};

export type TokenArgs = {
  refreshToken: string;
  accessToken: string;
};

export type LoginArgs = {
  user: User;
  rememberMe?: boolean;
} & TokenArgs;

/**
 * Default state object with initial values.
 */
const initialState: AuthState = {
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<User>) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    setHasError: (state, action: PayloadAction<{hasError: boolean}>) => {
      state.hasError = action.payload.hasError;
      if (action.payload.hasError) {
        state.accessToken = undefined;
        state.user = undefined;
      }
    },
    login: (state, action: PayloadAction<LoginArgs>) => {
      state.isAuthenticated = true;
      state.refreshToken = action.payload.refreshToken;
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      if (action.payload.rememberMe !== undefined) {
        state.rememberMe = action.payload.rememberMe;
      }
    },
    logout: state => {
      state.isAuthenticated = false;
      state.user = undefined;
      state.accessToken = undefined;
      state.refreshToken = undefined;
      state.rememberMe = undefined;
    },
    refreshToken: (state, action: PayloadAction<TokenArgs>) => {
      state.refreshToken = action.payload.refreshToken;
      state.accessToken = action.payload.accessToken;
    },
  },
});

export const {login, logout, setHasError, setAuthUser, refreshToken} =
  authSlice.actions;

export const selectHasAuthError = (state: RootState) => state.auth.hasError;
export const selectRememberMe = (state: RootState) => state.auth.rememberMe;
export const selectUserAndToken = createSelector(
  (state: RootState) => state.auth.user,
  (state: RootState) => state.auth.accessToken,
  (state: RootState) => state.auth.isAuthenticated,
  (user, accessToken, isAuthenticated) => ({
    user,
    accessToken,
    isAuthenticated,
  })
);

export default authSlice.reducer;
