import {useIntl} from 'react-intl';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {Drawer, makeStyles, Theme} from '@material-ui/core';
import {Close as CloseIcon} from '@material-ui/icons';
import Button from '../../../Common/Button';
import ChooseProductsForm from './ChooseProductsForm';
import {ProductCategory} from '../../../../types/ProductCategory';
import {useForm, useWatch} from 'react-hook-form';
import {useEffect, useState} from 'react';
import {Product} from '../../../../types/Product';
import {isEmpty} from 'lodash';
import {CheckedProductCategoryState} from './ScheduleItemsList';
import {useDispatch} from 'react-redux';
import {enqueueMessage} from '../../../../store/slices/appSlice';

/**
 * Choose products popup component
 */
export type ChooseProductsPopupType = {
  width: any;
  opened: boolean;
  onClose: () => void;
  category?: ProductCategory;
  updateCheckedProductCategories: (
    items: CheckedProductCategoryState[]
  ) => void;
  checkedProductCategories: CheckedProductCategoryState[];
  title: string;
};

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[3],
    overflow: 'hidden',
    zIndex: 1000,

    '&.MuiDrawer-paperAnchorDockedRight': {
      width: '15%',
      minWidth: 380,
      borderRadius: '40px 0 0 40px',
      paddingTop: 48,
      paddingBottom: 50,
      overflowY: 'hidden',
    },

    '&.MuiDrawer-paperAnchorBottom': {
      height: '75%',
      borderRadius: '46px 46px 0 0',
      paddingTop: 28,
      paddingBottom: 50,
      [theme.breakpoints.down('lg')]: {
        paddingBottom: 15,
      },
    },
  },
}));

const ChooseProductsPopup = (props: ChooseProductsPopupType) => {
  const {
    width,
    opened,
    onClose,
    category,
    updateCheckedProductCategories,
    checkedProductCategories,
    title,
  } = props;

  const intl = useIntl();
  const classes = useStyles();
  const {control, handleSubmit, setValue, reset} = useForm();
  const [products, setProducts] = useState<Product[]>([]);
  const [checkedProducts, setCheckedProducts] = useState<string[]>([]);
  const formWatch = useWatch({control});

  const dispatch = useDispatch();

  // check and uncheck products
  const checkAllProducts = (productLists: Product[], isChecked: boolean) => {
    const result = productLists.map(item => {
      setValue(`product-${item.id}`, isChecked);
      return `product-${item.id}`;
    });
    if (isChecked) {
      setCheckedProducts(result);
    } else {
      setCheckedProducts([]);
    }
  };

  const closePopUp = () => {
    setProducts([]);
    onClose();
  };

  useEffect(() => {
    if (category) {
      reset();
      const productLists = [...category.products];
      productLists.sort((p1, p2) => p1.rank - p2.rank);
      setProducts(productLists);
      const categoryItem = checkedProductCategories.find(
        item => item.id === category?.id
      );
      if (!categoryItem) {
        return;
      }
      if (categoryItem.checkedProducts.length > 0) {
        productLists.map(item => {
          if (categoryItem.checkedProducts.includes(item.id)) {
            setValue(`product-${item.id}`, true);
          } else {
            setValue(`product-${item.id}`, false);
          }
        });
      } else {
        checkAllProducts(productLists, false);
      }
    }
  }, [opened]);

  // increase/decrease selected count
  useEffect(() => {
    if (formWatch && !isEmpty(formWatch)) {
      const checkedProductList = Object.keys(formWatch).filter(
        key => formWatch[key]
      );
      setCheckedProducts(checkedProductList);
    }
  }, [formWatch]);

  // check all products
  const selectAllProduct = () => {
    checkAllProducts(products, true);
  };

  //clear checked selection
  const clearAllSelection = () => {
    checkAllProducts(products, false);
  };

  // apply changes
  const onApply = (payload: any) => {
    const copiedCheckProductCategories = [...checkedProductCategories];
    const currentProductCategories = copiedCheckProductCategories.find(
      item => item.id === category?.id
    );
    const currentIndex = copiedCheckProductCategories.findIndex(
      item => item.id === category?.id
    );
    if (!currentProductCategories) {
      return;
    }
    const checkedProductIds: number[] = Object.entries(payload).reduce(
      (result: any, [key, value]) => {
        if (value === true) {
          const numericKey = parseInt(key.split('-')[1]);
          result.push(numericKey);
        }
        return result;
      },
      []
    );
    currentProductCategories.checkedProducts = checkedProductIds;
    copiedCheckProductCategories[currentIndex] = currentProductCategories;
    updateCheckedProductCategories(copiedCheckProductCategories);
    onClose();
    // show success message
    dispatch(
      enqueueMessage({
        body: intl.formatMessage(
          {
            id: 'messages.apply_product',
          },
          {
            title,
          }
        ),
        variant: 'warning',
      })
    );
  };

  return (
    <Drawer
      anchor={isWidthUp('md', width) ? 'right' : 'bottom'}
      variant="persistent"
      elevation={0}
      classes={{
        paper: classes.paper,
      }}
      open={opened}
      onClose={closePopUp}
    >
      <div className="d-flex flex-column flex-grow-1">
        {/* Block content */}
        <div className="filter-content flex-fill">
          {/* Popup header */}
          <div className="filter-header">
            {/* Close action button */}
            <Button
              link
              light
              title={intl.formatMessage({id: 'actions.close'})}
              onClick={closePopUp}
              icon={<CloseIcon style={{color: '#989898', fontSize: '24px'}} />}
            />
          </div>
          {/* Popup content */}
          <div className="form-content">
            <ChooseProductsForm
              category={category}
              control={control}
              products={products}
              checkedProducts={checkedProducts}
              selectAllProduct={selectAllProduct}
              clearAllSelection={clearAllSelection}
            />
          </div>
        </div>
        {/* Popup actions */}
        <div className="filter-bottom-actions d-flex align-items-center justify-content-center justify-content-lg-end">
          {/* Delete */}
          <Button
            link
            title={intl.formatMessage({id: 'actions.cancel'})}
            onClick={closePopUp}
          />
          {/* Save */}
          <Button
            primary
            title={intl.formatMessage({id: 'actions.apply'})}
            type="submit"
            onClick={handleSubmit(onApply)}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default withWidth()(ChooseProductsPopup);
