import {DevTool} from '@hookform/devtools';
import {useIntl} from 'react-intl';
import {Form} from 'reactstrap';
import useUpdatePassword from '../../../hooks/useUpdatePassword';
import InputController from '../../Form/HookForm/InputController';

type ChangePasswordFormProps = {
  id?: number | string;
  form: string;
  closePasswordModal: () => void;
};

/**
 * Change password form
 */
const ChangePasswordForm = ({
  id,
  form,
  closePasswordModal,
}: ChangePasswordFormProps) => {
  const intl = useIntl();
  const {handleSubmit, control, submit} = useUpdatePassword({
    id,
    closePasswordModal,
  });

  return (
    <Form onSubmit={handleSubmit(submit)} id={form}>
      <InputController
        name="password"
        isPassword
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.admin.users.details.new_password',
        })}
      />
      <InputController
        name="passwordConfirm"
        isPassword
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.admin.users.details.retype_new_password',
        })}
        formGroupClass="mb-2"
      />
      <DevTool control={control} />
    </Form>
  );
};

export default ChangePasswordForm;
