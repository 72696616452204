import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {DynamicFilterProps} from '../../components/Form/DynamicFilter';
import {Status} from '../../utils/status';
import useLocationList from '../useLocationList';

export type UseBuyerFilterReturn = {
  items: DynamicFilterProps<any>['items'];
};

export default function useBuyerFilter(): UseBuyerFilterReturn {
  const intl = useIntl();

  const {locations} = useLocationList({type: 'buyer'});

  const items = useMemo(() => {
    const res: UseBuyerFilterReturn['items'] = [];

    res.push({
      label: intl.formatMessage({id: 'dashboard.users.location'}),
      name: 'location',
      options: locations,
      className: 'mb-0',
      type: 'autocomplete-multiple',
    });

    res.push({
      name: 'createdAt',
      type: 'date',
      label: intl.formatMessage({id: 'dashboard.users.creation_date'}),
    });

    res.push({
      label: intl.formatMessage({id: 'dashboard.users.status'}),
      type: 'checkbox-group',
      name: 'status',
      checkboxSize: 'default',
      formControlLabelClass: 'checkbox-filter-row',
      options: [
        {
          label: intl.formatMessage({id: 'dashboard.status.active'}),
          value: Status.Active,
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.suspended'}),
          value: Status.Suspended,
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.pending'}),
          value: Status.Pending,
        },
        {
          label: intl.formatMessage({id: 'dashboard.status.deleted'}),
          value: Status.Deleted,
        },
      ],
    });

    return res;
  }, [locations]);

  return {items};
}
