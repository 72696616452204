import React from 'react';
import {Drawer, makeStyles, Theme, Typography} from '@material-ui/core';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from '../Common/Button';
import {Close as CloseIcon} from '@material-ui/icons';
import {ReactComponent as FilterIcon} from '../../assets/images/filter.svg';
import {ReactComponent as MinusIcon} from '../../assets/images/minus.svg';
import {ReactComponent as PlusIcon} from '../../assets/images/plus.svg';
import {Collapse} from 'reactstrap';
import clsx from 'clsx';

//Filter styles
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[3],
    minWidth: '380px !important',
    zIndex: 1000,

    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 100%',
    },

    '&.MuiDrawer-paperAnchorDockedRight': {
      width: '15%',
      minWidth: 250,
      borderRadius: '40px 0 0 40px',
      paddingTop: 47,
      paddingBottom: 50,
    },

    '&.MuiDrawer-paperAnchorBottom': {
      height: '75%',
      borderRadius: '46px 46px 0 0',
      paddingTop: 28,
      paddingBottom: 50,
    },
  },
}));

export type CollapsibleFilterBlockProps = {
  title: string;
  className?: string;
  active?: boolean;
  children: any;
  isOpen: boolean;
  onToggle: (v: boolean) => void;
};

/**
 * Collapsible filter component
 * @constructor
 */
export const CollapsibleFilterBlock = ({
  title,
  className,
  active,
  children,
  isOpen,
  onToggle,
}: CollapsibleFilterBlockProps) => {
  //Expand/collapse block
  const toggle = () => {
    onToggle && onToggle(!isOpen);
  };

  return (
    <div
      className={clsx(
        'card collapsable-block',
        isOpen && 'expanded',
        className ?? ''
      )}
    >
      <div className="card-header" onClick={toggle}>
        <Typography
          variant="h6"
          className={clsx('card-title', {
            'font-weight-bold': isOpen || active,
            'text-app-primary': active,
          })}
        >
          {title}
        </Typography>
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="card-body">{children}</div>
      </Collapse>
    </div>
  );
};

export type ListFilterProps = {
  width: any;
  /**
   * TRUE if filter block is shown
   */
  opened: boolean;

  onClose: () => void;

  children: any;
  form: string;
  count: number;
  handleClearFilter: () => void;
};

/**
 * Filter Form
 */
const FilterForm = ({
  width,
  opened,
  children,
  onClose,
  form,
  count,
  handleClearFilter,
}: ListFilterProps) => {
  // Translations
  const intl = useIntl();
  const classes = useStyles();

  // clear button click callback
  const clearFilters = () => {
    handleClearFilter();
  };

  // apply button click callback
  const applyFilters = () => {
    onClose();
  };

  return (
    <Drawer
      anchor={isWidthUp('lg', width) ? 'right' : 'bottom'}
      variant="persistent"
      elevation={0}
      classes={{
        paper: classes.paper,
      }}
      open={opened}
      onClose={onClose}
    >
      {/* Filter block content */}
      <div className="filter-content">
        {/* Filter header */}
        <div className="filter-header border-bottom">
          {/* Close action button */}
          <Button
            link
            light
            title={intl.formatMessage({id: 'actions.close'})}
            onClick={onClose}
            icon={<CloseIcon style={{color: '#989898', fontSize: '24px'}} />}
            form={form}
            type="submit"
          />
          {/* Filter title */}
          <div className="filter-header-title d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <FilterIcon />
              <Typography variant="h5">
                <FormattedMessage id="actions.filter" />
              </Typography>
            </div>
            <div className="filter-counter-indicator text-center">{count}</div>
            {/*  Use 'invisible' class if you need to hide this indicator */}
          </div>
        </div>
        {children}
      </div>
      {/* Filter actions */}
      <div className="filter-bottom-actions d-flex align-items-center justify-content-center justify-content-lg-end">
        {/*Clear*/}
        <Button
          link
          title={intl.formatMessage({id: 'actions.clear'})}
          onClick={clearFilters}
        />
        {/*Apply*/}
        <Button
          primary
          title={intl.formatMessage({id: 'actions.apply'})}
          onClick={applyFilters}
          form={form}
          type="submit"
        />
      </div>
    </Drawer>
  );
};

export default withWidth()(FilterForm);
