import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Stack} from 'react-bootstrap';
import CardNumber from '../../Common/CardNumber';
import ArrowLink from '../../Common/ArrowLink';
import {Order, OrderServiceType} from '../../../types/Order';
import SummaryItem from './SummaryItem';
import {upperFirst} from 'lodash';
import {PaymentMethodType} from '../../../types/Payment';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from '../../../store/slices/authSlice';
import {UserProfileEnum} from '../../../types/User';
import {getFullAddress} from '../../../utils/functions';

type OrderSummaryProps = {
  data: Order;
};

const OrderSummary = ({data}: OrderSummaryProps) => {
  const address = data.deliveryAddress;
  const payment = data.orderPayments[0];
  const paymentDetails = payment.details;
  const paymentBillingAddress = paymentDetails?.billingAddress;

  const {user} = useSelector(selectUserAndToken);

  const isAdmin = user?.profileType === UserProfileEnum.Admin;

  const intl = useIntl();
  return (
    <div className="card details-card">
      <div className="card-body">
        <Typography variant="h4" className="mb-vtl-16 mb-lg-3 card-title">
          <FormattedMessage id="order_details.orderSummary.order_type_and_payment" />
        </Typography>
        <Stack className="gap-vtl-16 gap-lg-3">
          <SummaryItem
            title={intl.formatMessage({
              id: 'order_details.orderSummary.delivery_collection_detail',
            })}
          >
            <>
              {`${upperFirst(data.serviceType)} | ${data.scheduledTime}`}
              <br />
              {data.serviceType === OrderServiceType.delivery &&
                address &&
                getFullAddress(
                  address.address,
                  address.postCode,
                  address.addressLine2
                )
                  .split(',')
                  .map(a => (
                    <>
                      {a}
                      <br />
                    </>
                  ))}
            </>
          </SummaryItem>
          {address?.deliveryInstruction && (
            <SummaryItem
              title={intl.formatMessage({
                id: 'order_details.orderSummary.delivery_instructions',
              })}
            >
              <>{address.deliveryInstruction}</>
            </SummaryItem>
          )}
          {data.additionalInstructions && (
            <SummaryItem
              title={intl.formatMessage({
                id: 'order_details.orderSummary.additional_instructions',
              })}
            >
              <>{data.additionalInstructions}</>
            </SummaryItem>
          )}
          <SummaryItem
            title={intl.formatMessage({
              id: 'order_details.orderSummary.payment_method',
            })}
          >
            <>
              {payment.paymentMethod !== PaymentMethodType.Cash && (
                <>
                  {/* BILLING DETAILS */}
                  <CardNumber
                    type={paymentDetails?.brand as string}
                    number={paymentDetails?.last4Digit as string}
                  />
                  {paymentBillingAddress &&
                    payment.paymentMethod === PaymentMethodType.Online &&
                    ['name', 'line1', 'line2', 'city', 'postCode'].map(
                      field =>
                        paymentBillingAddress[field] && (
                          <div key={field}> {paymentBillingAddress[field]}</div>
                        )
                    )}
                </>
              )}
              {payment.paymentMethod === PaymentMethodType.Cash && (
                <>
                  {intl.formatMessage({
                    id: 'order_details.paymentMethods.cash',
                  })}
                </>
              )}
            </>
          </SummaryItem>
          {isAdmin && payment.paymentMethod !== PaymentMethodType.Cash && (
            <SummaryItem
              title={intl.formatMessage({
                id: 'order_details.orderSummary.stripe_id',
              })}
            >
              <ArrowLink
                title={paymentDetails.paymentIntentId}
                url={
                  process.env.REACT_APP_STRIPE_DASHBOARD +
                  paymentDetails.paymentIntentId
                }
                className="text-blue"
                isExternalLink={true}
              />
            </SummaryItem>
          )}
        </Stack>
      </div>
    </div>
  );
};

export default OrderSummary;
