import {useIntl} from 'react-intl';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useGetStoreQuery} from '../services/storeApi';
import {
  useDeleteDeliveryZoneMutation,
  useFetchDeliveryZonesQuery,
  useUpdateDeliveryZoneMutation,
} from '../services/deliveryApi';
import {DeliveryZone} from '../types/DeliveryZone';
import {Address} from '../types/Address';
import {DeliveryZoneStatus} from '../utils/status';
import {formatErrorMessage} from '../utils/functions';

export default function useDeliveryZone() {
  const {storeId} = useParams();
  const intl = useIntl();

  const [storeAddress, setStoreAddress] = useState<Address>();

  const [selectedZone, setSelectedZone] = useState<DeliveryZone | undefined>(
    undefined
  );
  const [deliveryZones, setDeliveryZones] = useState<
    DeliveryZone[] | undefined
  >([]);

  const [showLoader, setShowLoader] = useState(true);

  const {data, isSuccess} = useGetStoreQuery(
    {
      id: storeId,
    },
    {refetchOnReconnect: true}
  );

  const {data: deliveryZonesData, isSuccess: isDeliveryZonesSuccess} =
    useFetchDeliveryZonesQuery(
      {
        storeId: storeId,
        showProgressDialog: showLoader,
      },
      {refetchOnReconnect: true, refetchOnMountOrArgChange: true}
    );

  const [updateDelivery, {isSuccess: updateSuccess}] =
    useUpdateDeliveryZoneMutation();

  const [deleteDelivery, {isSuccess: deleteSuccess}] =
    useDeleteDeliveryZoneMutation();

  useEffect(() => {
    if (data && isSuccess) {
      setStoreAddress(data.store.address);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (deliveryZonesData && isDeliveryZonesSuccess) {
      setShowLoader(false);
      if (storeAddress && deliveryZonesData.length) {
        const updatedZones = deliveryZonesData.map(zone => {
          if (
            zone.address.latitude === storeAddress.latitude &&
            zone.address.longitude === storeAddress.longitude
          ) {
            return {
              ...zone,
              isMainZone: true,
            };
          } else {
            return {
              ...zone,
              isMainZone: false,
            };
          }
        });
        setDeliveryZones(updatedZones);
      } else {
        setDeliveryZones(deliveryZonesData);
      }
    }
  }, [storeAddress, deliveryZonesData, isDeliveryZonesSuccess]);

  const updateDeliveryZoneStatus = (
    zone: DeliveryZone,
    status: DeliveryZoneStatus
  ): void => {
    const updatedZone = {
      ...zone,
      status: status,
    };
    updateDelivery({
      id: zone.id,
      body: {
        ...updatedZone,
        deliveryChargePrice: updatedZone.deliveryChargePrice,
        minimumOrderPrice: updatedZone.minimumOrderPrice,
      },
      showProgressDialog: false,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'messages.update_zone_status',
        });
        return message;
      },
    });
  };

  const deleteDeliveryZone = (zoneId: number): void => {
    deleteDelivery({
      id: zoneId,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'messages.delete_zone',
        });
        return message;
      },
    });
  };

  return {
    storeAddress,
    isSuccess,
    deliveryZones,
    isDeliveryZonesSuccess,
    setDeliveryZones,
    selectedZone,
    setSelectedZone,
    updateSuccess,
    updateDeliveryZoneStatus,
    deleteDeliveryZone,
    deleteSuccess,
  };
}
