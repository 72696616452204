import {Control, UseFormHandleSubmit} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Form} from 'reactstrap';
import InputController from '../../../../Form/HookForm/InputController';
import {ProductOptionFormValues} from '../../../../../hooks/useProductOption';
import CurrencyController from '../../../../Form/HookForm/CurrencyController';

/**
 * Option form
 */

type OptionFormValues = {
  form: string;
  control: Control<ProductOptionFormValues>;
  submit: (formData: ProductOptionFormValues) => void;
  handleSubmit: UseFormHandleSubmit<ProductOptionFormValues>;
};
const OptionForm = (props: OptionFormValues) => {
  // Translations
  const intl = useIntl();
  const {control, handleSubmit, submit, form} = props;

  return (
    <Form id={form} onSubmit={handleSubmit(submit)}>
      {/* Option name */}
      <InputController
        name="name"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.option.name',
        })}
        required
      />
      {/* Price */}
      <CurrencyController
        name="price"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.option.price',
        })}
        required
      />
    </Form>
  );
};

export default OptionForm;
