import {Col, Row} from 'react-bootstrap';
import {Container} from 'reactstrap';
import SchedulesList from './MenuTab/SchedulesList';

/**
 * Menu
 */
const MenuTab = () => {
  return (
    <>
      <Container fluid className="stores-menu">
        <div className="content-block">
          <Row>
            <Col>
              <SchedulesList form="schedule-form" />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default MenuTab;
