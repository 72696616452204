import {Typography} from '@material-ui/core';
import cx from 'clsx';
import {Col, Row, Stack} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {Order} from '../../../types/Order';
import FormattedCurrency from '../../Shared/FormattedCurrency';
import HintTooltip from '../../Common/HintTooltip';

type CostBreakdownProps = {
  data: Order;
};
const CostBreakdown = ({data}: CostBreakdownProps) => {
  const intl = useIntl();

  const items = [
    {
      label: intl.formatMessage({
        id: 'order_details.costBreakdwon.order_subtotal',
      }),
      value: data.subtotalAmount,
      tooltip: '',
      isTotal: false,
    },
    ...(data.deliveryFee
      ? [
          {
            label: intl.formatMessage({
              id: 'order_details.costBreakdwon.delivery_fee',
            }),
            value: data.deliveryFee,
            isTotal: false,
          },
        ]
      : []),
    ...(data.totalDiscount
      ? [
          {
            label: intl.formatMessage({
              id: 'order_details.costBreakdwon.total_discount',
            }),
            value: data.totalDiscount,
            isTotal: false,
          },
        ]
      : []),
    ...data.orderFees.map(fee => ({
      label: fee.name,
      value: fee.amount,
      tooltip: fee.description ?? '',
      isTotal: false,
    })),
    {
      label: intl.formatMessage({id: 'order_details.total'}),
      value: data.totalAmount,
      isTotal: true,
    },
  ];

  return (
    <div className="card details-card">
      <div className="card-body">
        <Typography variant="h4" className="mb-vtl-16 mb-lg-3 card-title">
          <FormattedMessage id="order_details.costBreakdwon.title" />
        </Typography>
        <Stack className="gap-2 gap-lg-vtl-15">
          {items.map(({label, value, isTotal, tooltip}, key) => (
            <Row key={key}>
              <Col>
                <Typography className={cx({'font-weight-bold': isTotal})}>
                  {label}
                  {tooltip && <HintTooltip id={label} content={tooltip} />}
                </Typography>
              </Col>
              <Col xs={3} lg="auto">
                <Typography className={cx({'font-weight-bold': isTotal})}>
                  <FormattedCurrency currency={data.currency} value={value} />
                </Typography>
              </Col>
            </Row>
          ))}
        </Stack>
      </div>
    </div>
  );
};

export default CostBreakdown;
