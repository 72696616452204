import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Form, Row, Col} from 'reactstrap';
import InputController from '../../Form/HookForm/InputController';
import PhoneController from '../../Form/HookForm/PhoneController';
import TextAreaController from '../../Form/HookForm/TextAreaController';
import AutocompleteMultipleController from '../../Form/HookForm/AutocompleteMultipleController';
import useCreateStore from '../../../hooks/useCreateStore';
import AddressAutoComplete from '../../Form/AddressAutoComplete';
import {useFetchSellerQuery} from '../../../services/sellerApi';
import {OptionProps} from '../../Form/Inputs/AutocompleteMultiple';

type CreateStoreFormProps = {
  form: string;
  closePopup: () => void;
};

const CreateStoreForm = ({form, closePopup}: CreateStoreFormProps) => {
  // Translations
  const intl = useIntl();
  const [sellers, setSellers] = useState<OptionProps[]>([]);
  const {data: sellerData, isSuccess: successSellerData} = useFetchSellerQuery({
    query: {pageSize: 100},
  });

  useEffect(() => {
    if (sellerData && successSellerData) {
      const {data} = sellerData;
      setSellers(
        data.map(seller => ({
          title: `${seller.firstName} ${seller.lastName}`,
          value: seller.id,
          email: seller.email,
        }))
      );
    }
  }, [sellerData, successSellerData]);

  const {
    handleSubmit,
    onSubmit,
    control,
    address,
    addressError,
    updateAddressError,
    updateAddressValue,
    isSuccess,
    setEnteredValue,
  } = useCreateStore();

  // close popup
  useEffect(() => {
    if (isSuccess) {
      closePopup();
    }
  }, [isSuccess]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id={form}>
      {/* Store name */}
      <InputController
        name="name"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.stores.store_name',
        })}
        required
      />
      {/* Store email address */}
      <InputController
        name="email"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.stores.store_email_address',
        })}
        required
      />
      {/* Address line 1 */}
      {/* Autocomplete suggested address */}
      <AddressAutoComplete
        name="address"
        label={intl.formatMessage({id: 'dashboard.users.address'})}
        setAddress={updateAddressValue}
        updateAddressError={updateAddressError}
        addressError={addressError}
        address={address}
        setEnteredValue={setEnteredValue}
        isSuccess={isSuccess}
        required
      />
      <Row className="gx-2">
        <Col lg={6}>
          {/* City */}
          <InputController
            name="city"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.stores.city',
            })}
            disabled
          />
        </Col>
        <Col lg={6}>
          {/* Postcode */}
          <InputController
            name="postCode"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.stores.postcode',
            })}
            disabled
          />
        </Col>
      </Row>
      {/* Phone */}
      <PhoneController
        name="phone"
        control={control}
        label={intl.formatMessage({id: 'dashboard.stores.phone'})}
        required
        phoneInputProps={{international: true}}
      />
      {/* Store description */}
      <TextAreaController
        name="description"
        control={control}
        label={intl.formatMessage({id: 'dashboard.stores.store_description'})}
      />
      {/* Link seller/s to store */}
      <AutocompleteMultipleController
        name="sellers"
        control={control}
        label={intl.formatMessage({id: 'dashboard.stores.link_sellers_store'})}
        options={sellers}
        className="mb-2"
        placeholder={intl.formatMessage(
          {
            id: 'dashboard.admin.users.details.linked_store_placeholder',
          },
          {name: 'seller'}
        )}
      />
    </Form>
  );
};

export default CreateStoreForm;
