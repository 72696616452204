import {useEffect, useState} from 'react';
import clsx from 'clsx';
import {FormattedMessage, useIntl} from 'react-intl';
import {Col, Row} from 'react-bootstrap';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Box, TableBody, TableCell} from '@material-ui/core';
import Button from '../../Common/Button';
import {ReactComponent as PlusIcon} from '../../../assets/images/plusRed.svg';
import CustomValueForm from '../Form/CustomValueForm';
import BillingSummary from './BillingSummary';
import IconButton from '../../Common/IconButton';
import {ReactComponent as CancelIcon} from '../../../assets/images/remove.svg';
import {InvoiceLineItemParams} from '../../../hooks/useStatementInvoice';
import {COUNTRY_LIST} from '../../../utils/country-list';
import moment from 'moment-timezone';
import {
  InvoiceLineItem,
  StatementInvoice,
} from '../../../types/StatementInvoice';
import {Statement, StatementStatusEnum} from '../../../types/Statement';

export type BillingDetailsProps = {
  statementId: number;
  deleteInvoiceLineItemRequest: (body: StatementLineItem) => void;
  createInvoiceLineItemRequest: (body: InvoiceLineItemParams) => void;
  data: StatementInvoice;
  isCreateSuccess: boolean;
};

export type StatementLineItem = {
  description: string;
  qty?: number | string;
  rate?: number | string;
  vat_rate?: string;
  vat?: string;
  total?: string;
  custom: boolean;
  id?: number;
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  table: {
    marginBottom: theme.spacing(1.5),
  },
  tableHead: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  tableRow: {
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      '&:not(:last-of-type)': {
        borderBottom: `2px solid ${theme.palette.divider}`,
        marginBottom: theme.spacing(0.5),
      },
    },
  },
  head: {
    backgroundColor: theme.palette.dark.dark,
    color: theme.palette.dark.contrastText,
    padding: theme.spacing(0.5, 1),
    textAlign: 'center',
    '&:first-of-type': {
      textAlign: 'start',
    },
    '&.description': {
      width: '30%',
    },
    '&.qty': {
      width: '25%',
    },
    '&.total': {
      width: '14%',
    },
    '&.actions': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    padding: theme.spacing(1.5, 1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
    '&:first-of-type': {
      textAlign: 'start',
    },
    '&.actions': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(0.8, 0),
      '&.actions': {
        borderBottom: 'none',
        '&:empty': {
          display: 'none',
        },
      },
    },
  },
  description: {
    maxWidth: '200px',
  },
}));

const BillingDetails = ({
  deleteInvoiceLineItemRequest,
  createInvoiceLineItemRequest,
  data,
  isCreateSuccess,
}: BillingDetailsProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const [addValueForm, setAddValueForm] = useState(false);

  const headers = [
    {
      name: 'description',
      title: intl.formatMessage({
        id: 'transaction_list.invoice_details.description',
      }),
    },
    {
      name: 'qty',
      title: intl.formatMessage({
        id: 'transaction_list.invoice_details.qty',
      }),
    },
    {
      name: 'rate',
      title: intl.formatMessage({
        id: 'transaction_list.invoice_details.rate',
      }),
    },
    {
      name: 'vat_rate',
      title: intl.formatMessage({
        id: 'transaction_list.invoice_details.vat_rate',
      }),
    },
    {
      name: 'vat',
      title: intl.formatMessage({
        id: 'transaction_list.invoice_details.vat',
      }),
    },
    {
      name: 'total',
      title: intl.formatMessage({
        id: 'transaction_list.invoice_details.total',
      }),
    },
  ];

  const getCustomLineItemRows = (
    lineItems: InvoiceLineItem[]
  ): StatementLineItem[] =>
    lineItems.map(lineItem => {
      return {
        description: lineItem.description,
        qty: '',
        rate: '',
        vat_rate: '',
        vat: '',
        total: '',
        custom: true,
        id: lineItem.id,
      };
    });

  const getStatementLineItemRows = (
    statement: Statement
  ): StatementLineItem[] => {
    const {totalCardFee, totalSellerFee} = statement;
    const CARD_VAT = Number(process.env.REACT_APP_VAT_RATE_CARD_FEE as string);
    const PLATFORM_VAT = Number(
      process.env.REACT_APP_VAT_RATE_PLATFORM_FEE as string
    );

    const cardVat = totalCardFee - totalCardFee / (1 + CARD_VAT);
    const platformVat = totalSellerFee - totalSellerFee / (1 + PLATFORM_VAT);
    return [
      {
        description: intl.formatMessage({
          id: 'transaction_list.invoice_details.card_handling_fee',
        }),
        qty: 1,
        rate: `£${(totalCardFee / (1 + CARD_VAT)).toFixed(2)}`,
        vat_rate: `${CARD_VAT * 100}%`,
        vat: `£${cardVat.toFixed(2)}`,
        total: `£${totalCardFee.toFixed(2)}`,
        custom: false,
      },
      {
        description: intl.formatMessage({
          id: 'transaction_list.invoice_details.platform_fee',
        }),
        qty: 1,
        rate: `£${(totalSellerFee / (1 + PLATFORM_VAT)).toFixed(2)}`,
        vat_rate: `${PLATFORM_VAT * 100}%`,
        vat: `£${platformVat.toFixed(2)}`,
        total: `£${totalSellerFee.toFixed(2)}`,
        custom: false,
      },
    ];
  };

  const companyDetails = {
    companyName: process.env.REACT_APP_COMPANY_NAME as string,
    firstLine1: process.env.REACT_APP_FIRST_LINE1 as string,
    city: process.env.REACT_APP_CITY as string,
    postcode: process.env.REACT_APP_POSTCODE as string,
    country: process.env.REACT_APP_COUNTRY as string,
    vatNumber: `${intl.formatMessage({
      id: 'transaction_list.invoice_details.vat_number',
    })}${process.env.REACT_APP_VAT_NUMBER as string}`,
    phone: `${intl.formatMessage({
      id: 'transaction_list.invoice_details.telephone',
    })}${process.env.REACT_APP_PHONE as string}`,
    email: `${intl.formatMessage({
      id: 'transaction_list.invoice_details.email',
    })}${process.env.REACT_APP_EMAIL as string}`,
  };

  const deleteStatementLineItem = (row: StatementLineItem) => {
    if (row?.id) {
      deleteInvoiceLineItemRequest(row);
    }
  };

  useEffect(() => {
    if (isCreateSuccess) {
      setAddValueForm(false);
    }
  }, [isCreateSuccess]);

  const createStatementLineItem = (row: any) => {
    if (row && data) {
      createInvoiceLineItemRequest({
        description: row.description,
        invoiceId: data.id,
      });
    }
  };

  const renderLineItem = (row: StatementLineItem, key: number | string) => (
    <TableRow key={key} component="div" className={classes.tableRow}>
      {headers.map(({name, title}, key: number) => (
        <TableCell key={key} className={classes.cell} component="div">
          <span className="d-lg-none me-auto font-weight-bold">{title}</span>
          <p className={`text-break ${classes.description}`}>
            {row[name as keyof typeof row] ?? ''}
          </p>
        </TableCell>
      ))}
      <TableCell className={clsx(classes.cell, 'actions')} component="div">
        {row.custom && (
          <IconButton size="sm2" variant="action" className="ms-auto">
            <CancelIcon onClick={() => deleteStatementLineItem(row)} />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {data?.statement?.store && (
        <>
          <div className="header pb-4">
            <Row className="align-items-center pb-2">
              <Col>
                <img src="/images/VittleApps.png" />
              </Col>
              <Col xs={6}>
                <Typography className="font-weight-bold">
                  {companyDetails.companyName}
                </Typography>
                <Typography>
                  {companyDetails.firstLine1}
                  <br />
                  {companyDetails.city}
                  <br />
                  {companyDetails.postcode}
                  <br />
                  {companyDetails.country}
                </Typography>
              </Col>
              <Col>
                <Typography className="text-muted">
                  {companyDetails.vatNumber}
                  <br />
                  {companyDetails.phone}
                  <br />
                  {companyDetails.email}
                </Typography>
              </Col>
            </Row>
            <Box className="pb-2" display="flex" justifyContent="flex-end">
              <Typography
                className={`text-uppercase font-weight-bold ${
                  data.statement.status === StatementStatusEnum.Completed
                    ? 'font-green'
                    : 'font-red'
                }`}
              >
                {data.statement.status}
              </Typography>
            </Box>
            <Divider />
          </div>
          <div className="billing-details">
            <Row className="align-items-center py-2">
              <Col>
                <Typography variant="h4">
                  {data.statement.store.storeName}
                </Typography>
              </Col>
              <Col lg="auto">
                <Typography variant="h4">
                  <FormattedMessage id="transaction_list.invoice_details.invoice" />
                  : {data.invoiceNumber}
                </Typography>
              </Col>
            </Row>
            <Row className="pb-3 gy-2">
              {data.statement.store.storeBillingAddress && (
                <Col>
                  <Typography>
                    {data.statement.store.storeBillingAddress.addressLine1}
                    <br />
                    {data.statement.store.storeBillingAddress.addressLine2 ??
                      null}
                    {data.statement.store.storeBillingAddress.addressLine2 && (
                      <br />
                    )}
                    {data.statement.store.storeBillingAddress.city}
                    <br />
                    {data.statement.store.storeBillingAddress.postCode}
                    <br />
                    {
                      COUNTRY_LIST.find(
                        c =>
                          c.code ===
                          data.statement.store.storeBillingAddress.country
                      )?.name
                    }
                  </Typography>
                </Col>
              )}
              <Col lg="auto">
                <Typography>
                  <FormattedMessage id="transaction_list.invoice_details.issue_date" />
                  :{' '}
                  <span className="font-weight-bolder">
                    {moment(data.issueDate).format('DD/MM/YYYY')}
                  </span>
                </Typography>
              </Col>
            </Row>
            <Table className={classes.table} component="div">
              <TableHead component="div" className={classes.tableHead}>
                <TableRow component="div">
                  {headers.map(({name, title}) => (
                    <TableCell
                      key={name}
                      className={clsx(classes.head, name)}
                      component="div"
                    >
                      <span className="font-weight-bold">{title}</span>
                    </TableCell>
                  ))}
                  <TableCell
                    component="div"
                    className={clsx(classes.head, 'actions')}
                  />
                </TableRow>
              </TableHead>
              <TableBody component="div">
                {getStatementLineItemRows(data.statement).map((row, key) =>
                  renderLineItem(row, key)
                )}
                {data.lineItems &&
                  getCustomLineItemRows(data.lineItems).map((row, key) =>
                    renderLineItem(row, key)
                  )}
              </TableBody>
            </Table>
            {addValueForm && (
              <CustomValueForm
                onCancelClick={() => setAddValueForm(false)}
                submit={createStatementLineItem}
              />
            )}
            {/* Add custom value */}
            <Button
              primary
              link
              small
              title={intl.formatMessage({
                id: 'transaction_list.invoice_details.add_custom_value',
              })}
              icon={<PlusIcon />}
              onClick={() => setAddValueForm(true)}
            />
            <div className="mt-4 mt-lg-vtl-55">
              <BillingSummary statement={data.statement} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BillingDetails;
