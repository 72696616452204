import {useState} from 'react';
import {ToggleButtonGroup, ToggleButton} from '@material-ui/lab';
import {Theme, withStyles} from '@material-ui/core/styles';
import {Stack} from 'react-bootstrap';

/**
 * Toggle buttons component
 */

type ItemType = {
  label: string;
  value: string;
  color: 'green' | 'error' | 'orange';
};

type ToggleButtonsType = {
  /**
   * Buttons list,
   * each button has props are defined on ItemType
   */
  items: ItemType[];
  /**
   * Button onClick handler
   */
  onChange: (v: string) => void;
  value: string;
};

const StyledToggleButton = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    fontWeight: 'normal',
    padding: theme.spacing(0.7, 0.8),
    borderRadius: `${theme.spacing(0.4)}px !important`,
    border: `1px solid ${theme.palette.background.default}`,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('lg')]: {
      minWidth: 95,
    },
    [theme.breakpoints.down('lg')]: {
      flex: '1 0',
    },
    '&.Mui-selected, &:hover, &.Mui-selected:hover': {
      // @ts-ignore
      color: ({color}: any) => theme.palette[color]?.main,
      // @ts-ignore
      backgroundColor: ({color}: any) => theme.palette[color]?.light,
      // @ts-ignore
      borderColor: ({color}: any) => theme.palette[color]?.main,
    },
  },
  label: {
    fontSize: 14,
    lineHeight: 1.2,
  },
}))(ToggleButton);

/**
 * Toggle buttons
 */
const ToggleButtons = ({items, onChange, value}: ToggleButtonsType) => {
  // Current value
  const [currentValue, setCurrentValue] = useState(value ?? items[0]?.value);

  // Value change handler
  const handleChange = (value: string) => {
    setCurrentValue(value);
    onChange(value);
  };

  return items ? (
    <ToggleButtonGroup
      aria-label="switcher"
      className="d-flex d-lg-inline-flex"
    >
      <Stack direction="horizontal" gap={2} className="flex-fill">
        {items.map(({label, color, value}: ItemType) => (
          <StyledToggleButton
            key={value}
            value={value}
            color={color === 'error' ? 'primary' : color}
            aria-label={label}
            selected={currentValue === value}
            onClick={() => handleChange(value)}
          >
            {label}
          </StyledToggleButton>
        ))}
      </Stack>
    </ToggleButtonGroup>
  ) : null;
};

export default ToggleButtons;
