/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {Control, useWatch} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Row, Col} from 'react-bootstrap';
import {SearchAddressResult} from '../../../../types/SearchAddressResult';
import AddressAutoComplete from '../../../Form/AddressAutoComplete';
import CurrencyController from '../../../Form/HookForm/CurrencyController';
import RadioGroupController from '../../../Form/HookForm/RadioGroupController';
import {Typography} from '@material-ui/core';
import RangeField from '../../../Form/Inputs/RangeField';
import {
  CreateDeliveryZoneForm,
  AddressType,
  MapZoneType,
} from '../../../../hooks/useCreateDeliveryZone';

type ZoneFormProps = {
  control: Control<CreateDeliveryZoneForm>;
  updateAddressValue: (values: SearchAddressResult) => void;
  updateAddressError: (message: string | null) => void;
  addressError: string | null;
  address?: SearchAddressResult;
  setEnteredValue: React.Dispatch<React.SetStateAction<string>>;
  range: number[];
  updateRange: (newValue: number[]) => void;
  clearAddress: boolean;
  zone: MapZoneType | null;
};

const ZoneForm = (props: ZoneFormProps) => {
  const {
    control,
    addressError,
    address,
    updateAddressError,
    updateAddressValue,
    setEnteredValue,
    range,
    updateRange,
    clearAddress,
    zone,
  } = props;
  const intl = useIntl();

  const addressType = useWatch({control, name: 'addressType'});

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    updateRange(newValue as number[]);
  };

  return (
    <div>
      <RadioGroupController
        name="addressType"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.delivery_tab.origin',
        })}
        options={[
          {
            label: intl.formatMessage({
              id: 'dashboard.delivery_tab.store_location',
            }),
            value: AddressType.Store,
          },
          {
            label: intl.formatMessage({
              id: 'dashboard.delivery_tab.address',
            }),
            value: AddressType.Address,
          },
        ]}
        isRow={false}
        formGroupClass="mb-3"
      />
      {addressType && addressType === AddressType.Address && (
        <>
          <AddressAutoComplete
            name="address"
            label={`${intl.formatMessage({id: 'dashboard.users.address'})}*`}
            setAddress={updateAddressValue}
            updateAddressError={updateAddressError}
            addressError={addressError}
            address={address}
            setEnteredValue={setEnteredValue}
            isSuccess={clearAddress}
          />
        </>
      )}
      <RangeField
        label={intl.formatMessage({
          id: 'dashboard.store_details.delivery_tab.range',
        })}
        required
        value={range}
        valueLabelDisplay="auto"
        color="primary"
        onChange={handleChange}
        min={0}
        max={10}
        step={0.01}
        disabled={!zone}
      />
      <Row className="gx-1 align-items-end">
        <Col xs>
          <CurrencyController
            name="rangeMin"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.delivery_tab.start_range',
            })}
            className="mb-0"
            prefixLabel={intl.formatMessage({
              id: 'dashboard.delivery_tab.miles',
            })}
            prefixRight
            disabled={!zone}
          />
        </Col>
        <Col xs="auto">
          <Typography className="text-muted mb-2">&mdash;</Typography>
        </Col>
        <Col xs>
          <CurrencyController
            name="rangeMax"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.delivery_tab.end_range',
            })}
            className="mb-0"
            prefixLabel={intl.formatMessage({
              id: 'dashboard.delivery_tab.miles',
            })}
            prefixRight
            disabled={!zone}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ZoneForm;
