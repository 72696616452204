import {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Control, useForm, useWatch} from 'react-hook-form';
import cx from 'clsx';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {Col, Row} from 'reactstrap';
import {Stack} from 'react-bootstrap';
import Button from '../../../Common/Button';
import {ReactComponent as AcceptIcon} from '../../../../assets/images/accept.svg';
import {ReactComponent as ClearIcon} from '../../../../assets/images/clear.svg';
import {ReactComponent as EditIcon} from '../../../../assets/images/editGreen.svg';
import IconButton from '../../../Common/IconButton';
import CheckboxController from '../../../Form/HookForm/CheckboxController';
import {ProductCategory} from '../../../../types/ProductCategory';
import {CheckedProductCategoryState} from './ScheduleItemsList';
import {isEmpty, isEqual} from 'lodash';
import {StoreScheduleItem} from '../../../../types/StoreScheduleItem';

/**
 * Product categories
 */

type CategoryItemProps = {
  item: ProductCategory;
  activeItem: number | boolean;
  control: Control<any>;
  handleEditClick: (category: ProductCategory) => void;
  selectedCount: number;
};

const CategoryItem = (props: CategoryItemProps) => {
  const {item, activeItem, control, handleEditClick, selectedCount} = props;
  const label = `${item.name} (${selectedCount}/${item.products.length})`;

  return (
    <Box
      bgcolor="background.default"
      borderRadius={8}
      className={cx('category-list-item px-vtl-15 py-1', {
        active: activeItem === item.id,
      })}
    >
      <CheckboxController
        name={`category-${item.id}`}
        size="default"
        control={control}
        value={item.id}
        label={label}
        classes={{
          formControlLabel: 'me-1',
        }}
        disabled={item.products.length === 0}
      />
      <IconButton
        size="xs"
        variant="action"
        onClick={() => handleEditClick(item)}
      >
        <EditIcon />
      </IconButton>
    </Box>
  );
};

type CurrentListState = {
  categoryId: number;
  isChecked: boolean;
};

type ProductCategoryListProps = {
  onEditClick: (category: ProductCategory) => void;
  updateCheckedProductCategories: (
    items: CheckedProductCategoryState[]
  ) => void;
  checkedProductCategories: CheckedProductCategoryState[];
  categories: ProductCategory[];
  selectedItem: {
    item: StoreScheduleItem;
    index: number;
    fieldId: string;
  } | null;
};

const ProductCategoryList = (props: ProductCategoryListProps) => {
  const {
    onEditClick,
    updateCheckedProductCategories,
    checkedProductCategories,
    categories,
    selectedItem,
  } = props;
  const intl = useIntl();
  const {control, setValue} = useForm();
  const [activeItem, setActiveItem] = useState<number | boolean>(false);
  const [currentCategoryList, setCurrentCategoryList] = useState<
    CurrentListState[]
  >([]);

  const categoryWatch = useWatch({control});

  // update checkedProductIds when a category is checked/unchecked
  const handleCategoryCheck = () => {
    const productCategoryIds = Object.entries(categoryWatch).map(
      ([key, value]) => {
        const numericKey = parseInt(key.split('-')[1]);
        return {
          id: numericKey,
          isChecked: value,
        };
      }
    );
    const copiedList = [...checkedProductCategories];
    const lists = copiedList.map(item => {
      const categoryItem = productCategoryIds.find(
        catItem => catItem.id === item.id
      );
      if (categoryItem) {
        return {
          ...item,
          isChecked: categoryItem.isChecked,
        };
      }
      return item;
    });
    setCurrentCategoryList(formListData(categoryWatch));
    updateCheckedProductCategories(lists);
  };

  // conver given data to type CurrentListState
  const formListData = (watchData: {[x: string]: any}): CurrentListState[] => {
    const result = Object.entries(watchData)
      .map(([key, value]) => {
        const id = parseInt(key.split('-')[1]);
        if (value === undefined) {
          return null;
        }
        return {
          categoryId: id,
          isChecked: value,
        };
      })
      .filter((item): item is CurrentListState => item !== null);
    return result;
  };

  // check if oldState of categories match the formState
  const isUnchanged = (
    watchData: {
      [x: string]: any;
    },
    lists: CurrentListState[]
  ) => {
    const convertedList: CurrentListState[] = formListData(watchData);
    const equal = isEqual(convertedList, lists);
    return equal;
  };

  // check/uncheck category
  useEffect(() => {
    if (
      categoryWatch &&
      !isEmpty(categoryWatch) &&
      !isUnchanged(categoryWatch, currentCategoryList)
    ) {
      handleCategoryCheck();
    }
  }, [categoryWatch]);

  // Get product categories with no products
  const categoriesWithNoProducts = (productCategories: ProductCategory[]) =>
    productCategories.filter(item => item.products.length > 0);

  // check/uncheck product categories
  const checkAllCategories = (
    productCategories: ProductCategory[],
    isChecked: boolean,
    checkedCategories?: CheckedProductCategoryState[]
  ) => {
    const filteredCategories = categoriesWithNoProducts(productCategories);
    const listData: CurrentListState[] = filteredCategories.map(item => {
      if (checkedCategories) {
        const findItem = checkedCategories.find(
          category => category.id === item.id
        );
        if (findItem) {
          setValue(`category-${item.id}`, findItem.isChecked);
          return {
            categoryId: item.id,
            isChecked: findItem.isChecked,
          };
        }
        return {
          categoryId: item.id,
          isChecked,
        };
      } else {
        setValue(`category-${item.id}`, isChecked);
        return {
          categoryId: item.id,
          isChecked,
        };
      }
    });

    setCurrentCategoryList(listData);
  };

  // check all categories on load
  useEffect(() => {
    if (categories && selectedItem) {
      checkAllCategories(categories, true, checkedProductCategories);
    }
  }, [categories, selectedItem, checkedProductCategories]);

  // click on a category
  const handleEditClick = (item: ProductCategory) => {
    setActiveItem(item.id);
    onEditClick(item);
  };

  // check all categories
  const selectAllAction = () => {
    checkAllCategories(categories, true);
    // set isChecked true
    const copiedProductCategories = [...checkedProductCategories];
    const items = copiedProductCategories.map(item => ({
      ...item,
      isChecked: true,
    }));
    updateCheckedProductCategories(items);
  };

  // uncheck all categories
  const clearSelectionAction = () => {
    checkAllCategories(categories, false);
    // set isChecked false
    const copiedProductCategories = [...checkedProductCategories];
    const items = copiedProductCategories.map(item => ({
      ...item,
      isChecked: false,
    }));
    updateCheckedProductCategories(items);
  };

  const getSelectedLength = (id: number): number => {
    const categoryItem = checkedProductCategories.find(item => item.id === id);
    if (!categoryItem) {
      return 0;
    }
    return categoryItem?.checkedProducts.length;
  };

  return (
    <>
      <Row className="align-items-center gy-3 mb-vtl-15">
        <Col>
          <Typography className="font-weight-bold">
            <FormattedMessage id="dashboard.store_details.menu_tab.product_categories" />
          </Typography>
        </Col>
        <Col lg="auto">
          <Stack
            direction="horizontal"
            gap={3}
            className="justify-content-between justify-content-lg-start"
          >
            {/* Select all */}
            <Button
              link
              title={intl.formatMessage({
                id: 'dashboard.store_details.menu_tab.select_all',
              })}
              className="details-action"
              icon={<AcceptIcon />}
              onClick={selectAllAction}
            />
            {/* Clear selection */}
            <Button
              link
              title={intl.formatMessage({
                id: 'dashboard.store_details.menu_tab.clear_selection',
              })}
              className="details-action"
              icon={<ClearIcon />}
              onClick={clearSelectionAction}
            />
          </Stack>
        </Col>
      </Row>
      <Row className="g-vtl-9">
        {categories.map(item => (
          <Col key={item.id} sm={6} xl={3}>
            <CategoryItem
              control={control}
              item={item}
              activeItem={activeItem}
              handleEditClick={handleEditClick}
              selectedCount={getSelectedLength(item.id)}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ProductCategoryList;
