import {useListDiscountQuery} from '../services/discountApi';
import {useIntl} from 'react-intl';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import * as urls from '../constants/urls';
import {formatErrorMessage, getUrlSearchParams} from '../utils/functions';
import {useEffect, useState} from 'react';
import {get, isEqual} from 'lodash';
import useDataTableState from './table/useDataTableState';
import {parseFilterSearchParams} from '../components/Form/DynamicFilter';

export default function useDiscountList() {
  const {storeId} = useParams();
  const [searchParams] = useSearchParams();
  const [filterValues, setFilterValues] = useState<any>({});
  const [q, setQ] = useState('');
  // filter, sort and search values state
  const tableState = useDataTableState({
    defaultValues: {
      orderDirection: 'DESC',
      orderBy: 'createdAt',
    },
    bindQueryParams: true,
  });

  useEffect(() => {
    const id = setTimeout(() => {
      const search = getUrlSearchParams(searchParams);
      const newFilterValues = parseFilterSearchParams(search);
      if (!isEqual(filterValues, newFilterValues)) {
        setFilterValues(newFilterValues);
      }
      const nextQ = search.get('q') ?? '';
      if (q !== nextQ) {
        setQ(nextQ);
      }
    }, 250);

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // Translations
  const intl = useIntl();
  // Router
  const navigate = useNavigate();

  // submit filter
  const applyFilter = () => {};

  // format array/object filters into comma separated strings
  const formatListValues = (
    value: string[] | {[key: string]: boolean}
  ): string | undefined => {
    if (Array.isArray(value) && value.length > 0) {
      return value.join(',');
    } else if (typeof value === 'object' && value !== null) {
      const trueKeys = Object.keys(value).filter(
        key => (value as {[key: string]: boolean})[key] === true
      );
      return trueKeys.join(',');
    } else {
      return undefined;
    }
  };

  // discount list data
  // discount list query param
  const {isSuccess, data, isFetching} = useListDiscountQuery(
    {
      query: {
        storeId,
        page: tableState.page,
        pageSize: tableState.pageSize,
        order: tableState.orderDirection,
        orderBy: tableState.orderBy,
        status: formatListValues(filterValues.status),
        type: formatListValues(filterValues.type),
        name: filterValues.name ?? undefined,
        code: filterValues.code ?? undefined,
        dateFilter: get(filterValues, 'createdAt.type') ?? undefined,
        startDate: get(filterValues, 'createdAt.from') ?? undefined,
        endDate: get(filterValues, 'createdAt.to') ?? undefined,
        startDateFilter: get(filterValues, 'startDateFilter.type') ?? undefined,
        validityStartDateStartDate:
          get(filterValues, 'startDateFilter.from') ?? undefined,
        validityStartDateEndDate:
          get(filterValues, 'startDateFilter.to') ?? undefined,
        endDateFilter: get(filterValues, 'endDateFilter.type') ?? undefined,
        validityEndDateStartDate:
          get(filterValues, 'endDateFilter.from') ?? undefined,
        validityEndDateEndDate:
          get(filterValues, 'endDateFilter.to') ?? undefined,
        search: q,
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  //Datatable Row onclick handler
  const handleRowClick = (itemId: string) => {
    if (!storeId) {
      return;
    }
    navigate(
      `${urls.DISCOUNT_DETAILS_PATH.replace(
        ':storeId',
        storeId?.toString()
      ).replace(':discountId', itemId.toString())}`
    );
  };

  return {
    isLoading: isFetching,
    isSuccess,
    data: data?.data,
    handleRowClick,
    meta: data?.meta,
    tableState,
    applyFilter,
  };
}
