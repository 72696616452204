import {FeedbackParams} from '../store/queries/common';
import {Meta} from '../types/Meta';
import {baseApi} from './baseApi';
import {App, AppDescription, AppStatus} from '../types/App';
import {TagDescription} from '@reduxjs/toolkit/query';
import {
  ThirdPartyIntegration,
  ThirdPartyIntegrationSecrets,
  ThirdPartyIntegrationServiceId,
} from '../types/ThirdPartyIntegration';
import {Build, BuildEnvEnum, BuildTypeEnum, PlatformEnum} from '../types/Build';
import {AppKey} from '../types/AppKey';
import {
  AuthProvider,
  AuthProviderIdEnum,
  AuthProviderSecrets,
} from '../types/AuthProvider';

export type ListAppResponse = {
  data: App[];
  meta: Meta;
};

export type ListAppQueryParameter = {
  query?: {
    pageSize?: number;
    page?: number;
    order?: 'ASC' | 'DESC';
    orderBy?: string;
    search?: string;
  };
} & FeedbackParams;

export type FetchAppDetailsParameter = {
  id: number;
} & FeedbackParams;

export type UpdateAppDetailsParameter = {
  id: number;
  body: {
    name?: string;
    description?: string;
    template?: string;
    supportUrl?: string;
    privacyPolicyUrl?: string;
    version?: string;
    packageName?: string;
    status?: AppStatus;
  };
} & FeedbackParams;

export type UpdateAppThemeParameter = {
  id: number;
  body: {
    primaryColor: string;
    secondaryColor: string;
    textColor: string;
  };
} & FeedbackParams;

export type UpdateAppAssetParameter = {
  appId: number;
  id: number;
  body: FormData;
} & FeedbackParams;

export type DeleteAppAssetParameter = {
  appId: number;
  id: number;
} & FeedbackParams;

export type UpdateAppDetailsResponse = {
  data: App;
};

type AppDetailsResponse = App;

type AppBuildsResponse = Build[];

export type AppParams = {
  body: {
    name: string;
    storeId: number;
    description: AppDescription;
  };
} & FeedbackParams;

export type AppBuildParams = {
  appId: number;
  body: {
    type: BuildTypeEnum;
    env: BuildEnvEnum;
    platform: PlatformEnum;
    appVersion: string;
    versionCode: number;
  };
} & FeedbackParams;

export type RetryAppBuildParams = {
  appId: number;
  buildId: number;
} & FeedbackParams;

export type FetchAppThirdPartyIntegrationParameter = {
  appId: number;
} & FeedbackParams;

export type FetchAppKeyParameter = {
  appId: number;
} & FeedbackParams;

export type UpdateAppThirdPartyIntegrationParameter = {
  appId: number;
  integrationId: number;
  body: {
    appId: number;
    serviceId: ThirdPartyIntegrationServiceId;
    secrets: ThirdPartyIntegrationSecrets;
  };
} & FeedbackParams;

export type ThirdPartyIntegrationResponse = ThirdPartyIntegration[];
export type AppKeyResponse = AppKey[];

export type UpdateAuthProviderParameter = {
  appId: number;
  authProviderId: number;
  body: {
    appId: number;
    providerId: AuthProviderIdEnum;
    secrets: AuthProviderSecrets;
  };
} & FeedbackParams;

export type AuthProviderResponse = AuthProvider[];

export type FetchAuthProviderParameter = {
  appId: number;
} & FeedbackParams;

export type UpdateAppKeyParameter = {
  appId: number;
  appKeyId: number;
  body: FormData;
} & FeedbackParams;

const onInvalidateApp = (
  result: UpdateAppDetailsResponse | undefined
): TagDescription<'App'>[] => (result ? ['App'] : []);

export const appApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchApps: builder.query<ListAppResponse, ListAppQueryParameter | void>({
      query: ({query}: ListAppQueryParameter) => ({
        url: '/admin/app',
        method: 'GET',
        params: {
          ...query,
        },
      }),
      providesTags: ['App'],
    }),
    fetchAppDetails: builder.query<
      AppDetailsResponse,
      FetchAppDetailsParameter
    >({
      query: ({id}: FetchAppDetailsParameter) => ({
        url: `/admin/app/${id}`,
        method: 'GET',
      }),
      providesTags: ['App'],
    }),
    fetchAppBuilds: builder.query<AppBuildsResponse, FetchAppDetailsParameter>({
      query: ({id}: FetchAppDetailsParameter) => ({
        url: `/admin/app/${id}/build`,
        method: 'GET',
      }),
      providesTags: ['Build'],
    }),
    updateAppDetails: builder.mutation<
      UpdateAppDetailsResponse,
      UpdateAppDetailsParameter
    >({
      query: ({id, body}: UpdateAppDetailsParameter) => ({
        url: `/admin/app/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: result => onInvalidateApp(result),
    }),
    updateAppTheme: builder.mutation<
      UpdateAppDetailsResponse,
      UpdateAppThemeParameter
    >({
      query: ({id, body}: UpdateAppThemeParameter) => ({
        url: `/admin/app/${id}/theme`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: result => onInvalidateApp(result),
    }),
    updateAppAsset: builder.mutation<
      UpdateAppDetailsResponse,
      UpdateAppAssetParameter
    >({
      query: ({id, appId, body}: UpdateAppAssetParameter) => ({
        url: `/admin/app/${appId}/asset/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: result => onInvalidateApp(result),
    }),
    deleteAppAsset: builder.mutation<
      UpdateAppDetailsResponse,
      DeleteAppAssetParameter
    >({
      query: ({id, appId}: DeleteAppAssetParameter) => ({
        url: `/admin/app/${appId}/asset/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => onInvalidateApp(result),
    }),
    createApp: builder.mutation<any, AppParams>({
      query: ({body}: AppParams) => ({
        url: '/admin/app',
        method: 'POST',
        body,
      }),
      invalidatesTags: result => onInvalidateApp(result),
    }),
    fetchThirdPartyIntegration: builder.query<
      ThirdPartyIntegrationResponse,
      FetchAppThirdPartyIntegrationParameter
    >({
      query: ({appId}: FetchAppThirdPartyIntegrationParameter) => ({
        url: `/admin/app/${appId}/integration`,
        method: 'GET',
      }),
      providesTags: ['AppIntegration'],
    }),
    updateAppThirdPartyIntegration: builder.mutation<
      ThirdPartyIntegration,
      UpdateAppThirdPartyIntegrationParameter
    >({
      query: ({
        appId,
        integrationId,
        body,
      }: UpdateAppThirdPartyIntegrationParameter) => ({
        url: `/admin/app/${appId}/integration/${integrationId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['AppIntegration'],
    }),
    fetchAuthProvider: builder.query<
      AuthProviderResponse,
      FetchAuthProviderParameter
    >({
      query: ({appId}: FetchAuthProviderParameter) => ({
        url: `/admin/app/${appId}/auth-provider`,
        method: 'GET',
      }),
      providesTags: ['AppAuthProvider'],
    }),
    updateAuthProvider: builder.mutation<
      AuthProvider,
      UpdateAuthProviderParameter
    >({
      query: ({appId, authProviderId, body}: UpdateAuthProviderParameter) => ({
        url: `/admin/app/${appId}/auth-provider/${authProviderId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['AppAuthProvider'],
    }),
    fetchAppKey: builder.query<AppKeyResponse, FetchAppKeyParameter>({
      query: ({appId}: FetchAppKeyParameter) => ({
        url: `/admin/app/${appId}/keystore`,
        method: 'GET',
      }),
      providesTags: ['AppKeystore'],
    }),
    updateAppKey: builder.mutation<AppKey, UpdateAppKeyParameter>({
      query: ({appId, appKeyId, body}: UpdateAppKeyParameter) => ({
        url: `/admin/app/${appId}/keystore/${appKeyId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['AppKeystore'],
    }),
    createAppBuild: builder.mutation<any, AppBuildParams>({
      query: ({appId, body}: AppBuildParams) => ({
        url: `/admin/app/${appId}/build`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Build'],
    }),
    retryAppBuild: builder.mutation<any, RetryAppBuildParams>({
      query: ({appId, buildId}: RetryAppBuildParams) => ({
        url: `/admin/app/${appId}/build/${buildId}/retry`,
        method: 'POST',
      }),
      invalidatesTags: ['Build'],
    }),
  }),
});

export const {
  useCreateAppMutation,
  useFetchAppsQuery,
  useFetchAppDetailsQuery,
  useUpdateAppDetailsMutation,
  useUpdateAppThirdPartyIntegrationMutation,
  useFetchThirdPartyIntegrationQuery,
  useFetchAppBuildsQuery,
  useUpdateAppThemeMutation,
  useUpdateAppAssetMutation,
  useDeleteAppAssetMutation,
  useFetchAuthProviderQuery,
  useUpdateAuthProviderMutation,
  useFetchAppKeyQuery,
  useUpdateAppKeyMutation,
  useCreateAppBuildMutation,
  useRetryAppBuildMutation,
} = appApi;
