import {useIntl} from 'react-intl';
import {useUpdateReviewMutation} from '../services/reviewApi';
import {ReviewStatus} from '../types/Review';
import {formatErrorMessage} from '../utils/functions';

export default function useReviewDetails() {
  const intl = useIntl();
  const [updateReview, {isSuccess}] = useUpdateReviewMutation();

  const updateReviewStatus = async (reviewId: number, status: ReviewStatus) => {
    updateReview({
      reviewId,
      body: {
        status,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () =>
        intl.formatMessage(
          {
            id: 'dashboard.store_details.reviews_tab.review_status_updated',
          },
          {
            status,
          }
        ),
    });
  };

  return {
    updateReviewStatus,
    isSuccess,
  };
}
