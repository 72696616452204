import React from 'react';
import {Typography} from '@material-ui/core';
import {FormattedMessage} from 'react-intl';
import {Stack} from 'react-bootstrap';
import IconButton from '../../Common/IconButton';
import IndicatorDefault from '../../Common/IndicatorDefault';
import {ReactComponent as AddIcon} from '../../../assets/images/addCard.svg';
import {ReactComponent as EditIcon} from '../../../assets/images/edit.svg';
import {ReactComponent as TrashIcon} from '../../../assets/images/trash.svg';
import {Address} from '../../../types/Address';
import {BuyerData} from '../../../types/BuyerData';

/**
 * Address Card item
 */
export type UserAddressItemProps = {
  userAddress: Address;
  onDelete: (address: Address) => void;
  onEdit: (address: Address) => void;
  onSetAsDefault: (address: Address) => void;
  user: BuyerData;
};

/**
 * Add address
 */
export const UserNewAddressItem = ({onClick}: any) => {
  return (
    <div className="card address-card add-new" onClick={onClick}>
      <div className="inner">
        <Stack className="align-items-center gap-2 gap-lg-4">
          <AddIcon />
          <Typography
            variant="h4"
            component="p"
            className="font-weight-regular"
          >
            <FormattedMessage id="dashboard.user_details.tab_address.add_address" />
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

/**
 * Address item of address list
 */
const UserAddressItem = ({
  userAddress,
  onDelete,
  onSetAsDefault,
  onEdit,
  user,
}: UserAddressItemProps) => {
  const {
    firstName,
    lastName,
    phone,
    isDefault = false,
    address,
    city,
    postCode,
    deliveryInstruction,
  } = userAddress;

  return (
    <div className="card details-card address-card">
      <div className="card-header">
        <IndicatorDefault
          isDefault={isDefault}
          onClick={() => onSetAsDefault(userAddress)}
        />
        <div className="btn-toolbar">
          {/* Edit button */}
          <IconButton
            size="xs"
            variant="action"
            onClick={() => onEdit(userAddress)}
          >
            <EditIcon />
          </IconButton>
          {/* Delete button */}
          <IconButton
            size="xs"
            variant="action"
            onClick={() => onDelete(userAddress)}
          >
            <TrashIcon />
          </IconButton>
        </div>
      </div>
      <div className="card-body">
        {/*Name*/}
        <div className="item-row">
          <Typography variant="subtitle2" className="text-muted pb-1">
            <FormattedMessage id="dashboard.user_details.tab_address.name" />
          </Typography>
          <Typography variant="body2">
            {firstName || user.firstName} {lastName || user.lastName}
          </Typography>
        </div>
        {/*Address values*/}
        <div className="item-row">
          <Typography variant="subtitle2" className="text-muted pb-1">
            <FormattedMessage id="dashboard.user_details.tab_address.address" />
          </Typography>
          {address && <Typography variant="body2">{address}</Typography>}

          {(city || postCode) && (
            <Typography variant="body2">
              {city} {postCode}
            </Typography>
          )}
        </div>
        {/* Phone number */}
        <div className="item-row">
          <Typography variant="subtitle2" className="text-muted pb-1">
            <FormattedMessage id="dashboard.user_details.tab_address.phone_number" />
          </Typography>
          <Typography variant="body2">{phone || user.phone}</Typography>
        </div>
        {/* Delivery instruction */}
        <div className="item-row pb-0">
          <Typography variant="subtitle2" className="text-muted pb-1">
            <FormattedMessage id="dashboard.user_details.tab_address.delivery_instruction" />
          </Typography>
          <Typography variant="body2">{deliveryInstruction}</Typography>
        </div>
      </div>
    </div>
  );
};

export default UserAddressItem;
