import {useIntl} from 'react-intl';
import {useEffect, useMemo} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {
  formatErrorMessage,
  isApiValidationError,
  mapErrorToFormFields,
} from '../utils/functions';
import {OptionProps} from '../components/Form/Inputs/AutocompleteSingle';
import {CustomError} from '../types/CustomError';
import {AppParams, useCreateAppMutation} from '../services/appApi';

type FormData = {
  name: string;
  store: OptionProps;
};

const useCreateApp = () => {
  // translations
  const intl = useIntl();

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    resetField,
    formState: {errors, submitCount},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  // disable submit button if theres error
  const disableButton = (): boolean => {
    let disable = false;
    if (Object.keys(errors).length > 0) {
      disable = true;
    }
    return disable;
  };

  const [
    createAppAccount,
    {isSuccess, isLoading, data, error: createAppError},
  ] = useCreateAppMutation();

  // submit request
  const onSubmit = async (formData: FormData) => {
    if (!formData.store) {
      setError('store', {
        type: 'manual',
        message: intl.formatMessage({id: 'validation.error.required_field'}),
      });
      return;
    }
    const storeId: string | number = formData.store.key;

    const data: AppParams = {
      body: {
        name: formData.name,
        storeId: Number(storeId),
        description: {
          'en-GB': '',
          'zh-CN': '',
        },
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.app_created_success_message',
        });
      },
    };
    createAppAccount(data);
  };

  // Format api validation errors to input field
  useEffect(() => {
    if (createAppError) {
      const typedUpdateError = createAppError as CustomError;
      if (isApiValidationError(typedUpdateError)) {
        mapErrorToFormFields(typedUpdateError.data.message, setError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAppError]);

  // clear form fields after successful creation of store
  useEffect(() => {
    if (isSuccess && data) {
      resetField('store');
      setValue('store', {
        key: '',
        title: '',
      });
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  return {
    onSubmit,
    isSuccess,
    isLoading,
    data,
    control,
    handleSubmit,
    submitCount,
    errors,
    disableButton,
  };
};

export default useCreateApp;
