import {Row, Col} from 'reactstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {Stack} from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import HintTooltip from '../Common/HintTooltip';
import IconButton from '../Common/IconButton';
import {ReactComponent as StoreIcon} from '../../assets/images/store.svg';
import {ReactComponent as AddIcon} from '../../assets/images/add.svg';
import {StoreTypeFormValues} from '../../hooks/useStoreType';
import {StoreType} from '../../types/StoreType';
import StoreTypeItem from './StoreTypeItem';
import {Control, UseFormHandleSubmit} from 'react-hook-form';
import InputController from '../Form/HookForm/InputController';

/**
 * App settings - Store list
 */

type StoreListProps = {
  storeTypes: StoreType[];
  onDelete: (id: number) => void;
  handleSubmit: UseFormHandleSubmit<StoreTypeFormValues>;
  createStoreType: (formValue: StoreTypeFormValues) => void;
  editStoreType: (name: string, id: number) => void;
  control: Control<StoreTypeFormValues, any>;
};

const StoreList = (props: StoreListProps) => {
  const {
    storeTypes,
    onDelete,
    handleSubmit,
    createStoreType,
    control,
    editStoreType,
  } = props;
  // Translations
  const intl = useIntl();

  return (
    <div className="px-3 ps-lg-vtl-35 pe-lg-4">
      <Stack direction="horizontal" gap={3}>
        <StoreIcon />
        <Typography className="font-weight-bold">
          <FormattedMessage id="dashboard.app_settings.store_list" />
        </Typography>
      </Stack>
      <Row className="mt-vtl-14">
        <Col lg={6}>
          <div className="ps-lg-vtl-35 d-flex align-items-center pt-2 pb-2 pb-lg-0">
            <Typography className="text-muted pe-1">
              <FormattedMessage id="dashboard.app_settings.store_type" />
            </Typography>
            <HintTooltip
              id="hint_store_list"
              content={intl.formatMessage({
                id: 'dashboard.app_settings.store_type_hint',
              })}
            />
          </div>
        </Col>
        <Col lg={6}>
          <Stack gap={2}>
            {storeTypes && storeTypes.length > 0
              ? storeTypes.map(type => (
                  <StoreTypeItem
                    key={type.id}
                    type={type}
                    onDelete={onDelete}
                    editStoreType={editStoreType}
                  />
                ))
              : null}
            <Stack direction="horizontal" className="gap-vtl-8">
              <InputController
                name="name"
                excludeLabel
                control={control}
                placeholder={intl.formatMessage({
                  id: 'dashboard.app_settings.store_type_placeholder',
                })}
                formGroupClass="mb-0 flex-fill"
              />
              <IconButton
                size="sm"
                variant="action"
                onClick={handleSubmit(createStoreType)}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Col>
      </Row>
    </div>
  );
};

export default StoreList;
