import {Price} from './Price';

export interface ProductOption {
  id: number;
  name: string;
  price: Price;
  sku: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum ProductOptionStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum PricePrefix {
  GBP = '£',
  USD = '$',
  EUR = '€',
}
