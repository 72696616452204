import {useIntl} from 'react-intl';
import {useEffect, useState} from 'react';
import {isEqual} from 'lodash';
import {formatErrorMessage, getUrlSearchParams} from '../utils/functions';
import {parseFilterSearchParams} from '../components/Form/DynamicFilter';
import useDataTableState from './table/useDataTableState';
import {useFetchAppsQuery} from '../services/appApi';
import {useNavigate, useSearchParams} from 'react-router-dom';
import * as urls from '../constants/urls';

export default function useAppList() {
  // Translations
  const intl = useIntl();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [filterValues, setFilterValues] = useState<any>({});
  const [q, setQ] = useState('');
  const tableState = useDataTableState({
    defaultValues: {
      orderDirection: 'ASC',
      orderBy: 'name',
    },
    bindQueryParams: true,
  });
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      const search = getUrlSearchParams(searchParams);
      const newFilterValues = parseFilterSearchParams(search);
      if (!isEqual(filterValues, newFilterValues)) {
        setFilterValues(newFilterValues);
      }
      const nextQ = search.get('q') ?? '';
      if (q !== nextQ) {
        setQ(nextQ);
      }
      setReady(true);
    }, 250);

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // app list data
  const {isSuccess, data, isFetching} = useFetchAppsQuery(
    {
      query: {
        page: tableState.page,
        pageSize: tableState.pageSize,
        order: tableState.orderDirection,
        orderBy: tableState.orderBy,
        search: q,
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
      showProgressDialog: false,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !ready,
    }
  );

  const handleRowClick = (itemId: number) => {
    navigate(
      `${urls.APP_DETAILS_PATH.replace(':appId', itemId.toString())}/general`
    );
  };

  return {
    isLoading: isFetching,
    isSuccess,
    data: data?.data,
    handleRowClick,
    meta: data?.meta,
    tableState,
  };
}
