import {useIntl} from 'react-intl';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {Row, Col, Container} from 'react-bootstrap';
import Modal from '../../../Widgets/Modal';
import ZoneForm from './ZoneForm';
import CurrencyController from '../../../Form/HookForm/CurrencyController';
import EditMapZone from './EditMapZone';
import useCreateDeliveryZone from '../../../../hooks/useCreateDeliveryZone';
import {DeliveryZone} from '../../../../types/DeliveryZone';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  box: {
    display: 'flex',
    minWidth: 'calc(100vw - 60px)',
    [theme.breakpoints.up('xl')]: {
      minWidth: 1107,
    },
  },
}));

export type EditZonePopupProps = {
  opened: boolean;
  selectedZone?: DeliveryZone;
  handleClose: () => void;
  showDeleteConfirmation: (value: boolean) => void;
  setSelectedZone: (zone: DeliveryZone) => void;
};

const EditZonePopup = (props: EditZonePopupProps) => {
  const {
    opened,
    selectedZone,
    showDeleteConfirmation,
    handleClose,
    setSelectedZone,
  } = props;

  const {
    range,
    control,
    address,
    updateAddressError,
    updateAddressValue,
    setEnteredValue,
    addressError,
    watch,
    handleSubmit,
    submit,
    clearAddress,
    isEdit,
    updateIsEdit,
    zone,
    updateRange,
    closeModalAndResetFields,
  } = useCreateDeliveryZone({
    closeModal: handleClose,
    selectedZone,
    isOpened: opened,
    setSelectedZone,
  });

  const handleDeleteOrClose = () => {
    if (selectedZone) {
      showDeleteConfirmation(true);
    } else {
      closeModalAndResetFields();
    }
  };

  const classes = useStyles();
  const intl = useIntl();
  const title = intl.formatMessage({
    id: !selectedZone
      ? 'dashboard.store_details.delivery_tab.add_delivery_zone'
      : 'dashboard.store_details.delivery_tab.edit_delivery_zone',
  });

  const addressWatch = watch('address');

  return (
    <Modal
      opened={opened}
      showCloseTop
      handleClose={handleDeleteOrClose}
      title={title}
      saveTitle={intl.formatMessage({
        id: !selectedZone ? 'actions.add' : 'actions.save',
      })}
      cancelTitle={intl.formatMessage({
        id: !selectedZone ? 'actions.cancel' : 'actions.delete',
      })}
      form="save-delivery-form"
      type="submit"
      onCloseIcon={closeModalAndResetFields}
    >
      <form id="save-delivery-form" onSubmit={handleSubmit(submit)}>
        <Box className={classes.box}>
          <Container fluid className="px-0">
            <Row className="gy-3 flex-column-reverse flex-lg-row">
              <Col xs={12} lg={4}>
                <div className="mb-fg">
                  <ZoneForm
                    control={control}
                    updateAddressValue={updateAddressValue}
                    updateAddressError={updateAddressError}
                    addressError={addressError}
                    address={address}
                    setEnteredValue={setEnteredValue}
                    range={range}
                    updateRange={updateRange}
                    clearAddress={clearAddress}
                    zone={zone}
                  />
                </div>
                <CurrencyController
                  name="minimumOrderPrice"
                  required
                  label={intl.formatMessage({
                    id: 'dashboard.store_details.delivery_tab.minimum_order',
                  })}
                  control={control}
                  className="mb-fg"
                  prefixLabel={intl.formatMessage({
                    id: 'units.pound',
                  })}
                />
                <CurrencyController
                  name="deliveryChargePrice"
                  required
                  label={intl.formatMessage({
                    id: 'dashboard.store_details.delivery_tab.delivery_charge',
                  })}
                  control={control}
                  className="mb-fg"
                  prefixLabel={intl.formatMessage({
                    id: 'units.pound',
                  })}
                />
                <CurrencyController
                  name="estimatedDeliveryTime"
                  required
                  label={intl.formatMessage({
                    id: 'dashboard.store_details.delivery_tab.estimated_delivery_time',
                  })}
                  control={control}
                  className="mb-0"
                  prefixLabel={intl.formatMessage({
                    id: 'actions.min',
                  })}
                  prefixRight
                  allowDecimals={false}
                />
              </Col>
              <Col xs={12} lg>
                <EditMapZone
                  setRanges={updateRange}
                  ranges={range}
                  isEdit={isEdit}
                  setIsEdit={updateIsEdit}
                  zone={zone}
                  address={addressWatch}
                />
              </Col>
            </Row>
          </Container>
        </Box>
      </form>
    </Modal>
  );
};

export default EditZonePopup;
