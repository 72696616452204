import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {SearchAddressResult} from '../types/SearchAddressResult';
import {useParams} from 'react-router-dom';
import {Store} from '../types/Store';
import {useGetStoreQuery} from '../services/storeApi';
import {
  DeliveryZoneRequestParameter,
  useCreateDeliveryZoneMutation,
  useUpdateDeliveryZoneMutation,
} from '../services/deliveryApi';
import {
  formatErrorMessage,
  isApiValidationError,
  isKeyMatching,
  mapErrorToFormFields,
} from '../utils/functions';
import {debounce, isEqual} from 'lodash';
import {DeliveryZone} from '../types/DeliveryZone';
import {Address} from '../types/Address';
import {CustomError} from '../types/CustomError';

export enum AddressType {
  Store = 'store',
  Address = 'address',
}

export type MapZoneType = {
  lat: number;
  lng: number;
  fromRange: number;
  toRange: number;
  isStore: boolean;
};

export type CreateDeliveryZoneForm = {
  rangeMin: number;
  rangeMax: number;
  minimumOrderPrice: number;
  deliveryChargePrice: number;
  estimatedDeliveryTime: number;
  addressType: AddressType;
  address: SearchAddressResult;
};

type UseCreateDeliveryZoneProps = {
  closeModal: () => void;
  selectedZone?: DeliveryZone;
  isOpened: boolean;
  setSelectedZone: (zone: DeliveryZone) => void;
};

const centerValue = 0.0001;

export default function useCreateDeliveryZone({
  closeModal,
  selectedZone,
  isOpened,
  setSelectedZone,
}: UseCreateDeliveryZoneProps) {
  // Translations
  const intl = useIntl();
  const {storeId} = useParams();
  const [storeDetails, setStoreDetails] = useState<Store>();
  const [range, setRange] = useState<number[]>([0, 0]);
  const [clearAddress, setClearAddress] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [zone, setZone] = useState<MapZoneType | null>(null);
  const [isEditLoad, setIsEditLoad] = useState(false);

  const updateIsEdit = (editable: boolean) => setIsEdit(editable);

  // add a point and marker on the map
  const addNewZone = (
    address: SearchAddressResult,
    fromRange: number,
    toRange: number,
    isStore: boolean
  ) => {
    setZone({
      lat: address.latitude,
      lng: address.longitude,
      fromRange,
      toRange,
      isStore,
    });
  };

  const {data, isSuccess} = useGetStoreQuery({
    id: storeId,
  });

  // set point on the map using address lat and lng
  const handleSetPoint = ({
    address,
    startRange = 0,
    endRange = 0,
    isStore,
  }: {
    address: SearchAddressResult;
    startRange?: number;
    endRange?: number;
    isStore: boolean;
  }) => {
    setValue('address', address);
    setValue('rangeMin', startRange);
    setValue('rangeMax', endRange);
    setRange([startRange, endRange]);
    addNewZone(address, startRange, endRange, isStore);
  };

  // get store address and return as Places api result
  const getStoreAddress = (address: Address): SearchAddressResult => {
    return {
      postCode: address.postCode,
      address: address.address as string,
      city: address.city as string,
      country: address?.country as string,
      latitude: address.latitude,
      longitude: address.longitude,
    };
  };

  // set store address as default location on map
  useEffect(() => {
    if (data && isSuccess) {
      setStoreDetails(data.store);
      handleSetPoint({
        address: getStoreAddress(data.store.address),
        isStore: true,
      });
    }
  }, [data, isSuccess]);

  // form fields validation
  const schema = useMemo(
    () =>
      yup.object().shape({
        rangeMin: yup
          .number()
          .typeError(intl.formatMessage({id: 'validation.error.required'}))
          .required(intl.formatMessage({id: 'validation.error.required'})),
        rangeMax: yup
          .number()
          .typeError(intl.formatMessage({id: 'validation.error.required'}))
          .required(intl.formatMessage({id: 'validation.error.required'})),
        minimumOrderPrice: yup
          .number()
          .min(
            0,
            intl.formatMessage(
              {id: 'validation.error.greater_than_zero'},
              {name: 'minimum order'}
            )
          )
          .max(
            9999999,
            intl.formatMessage({id: 'validation.error.max_price_exceed'})
          )
          .typeError(intl.formatMessage({id: 'validation.error.required'}))
          .required(intl.formatMessage({id: 'validation.error.required'})),
        deliveryChargePrice: yup
          .number()
          .max(
            9999999,
            intl.formatMessage({id: 'validation.error.max_price_exceed'})
          )
          .typeError(intl.formatMessage({id: 'validation.error.required'}))
          .required(intl.formatMessage({id: 'validation.error.required'})),
        estimatedDeliveryTime: yup
          .number()
          .moreThan(
            0,
            intl.formatMessage(
              {id: 'validation.error.greater_than_zero'},
              {name: 'estimated delivery time'}
            )
          )
          .typeError(intl.formatMessage({id: 'validation.error.required'}))
          .required(intl.formatMessage({id: 'validation.error.required'})),
      }),
    [intl]
  );

  const {control, handleSubmit, setValue, reset, watch, setError} =
    useForm<CreateDeliveryZoneForm>({
      mode: 'onTouched',
      resolver: yupResolver(schema),
      defaultValues: {
        addressType: AddressType.Store,
        rangeMax: 0,
        rangeMin: 0,
      },
    });

  const [address, setAddress] = useState<SearchAddressResult | undefined>();
  const addressTypeWatch = watch('addressType');

  // watch origin field
  useEffect(() => {
    if (
      addressTypeWatch &&
      addressTypeWatch === AddressType.Store &&
      storeDetails
    ) {
      // set store address as the origin point
      handleSetPoint({
        address: getStoreAddress(storeDetails.address),
        startRange: range[0],
        endRange: range[1],
        isStore: true,
      });
    }
    if (addressTypeWatch === AddressType.Address && !isEditLoad) {
      if (address) {
        handleSetPoint({
          address,
          startRange: range[0],
          endRange: range[1],
          isStore: false,
        });
      } else {
        setRange([0, 0]);
        setZone(null);
      }
    } else {
      setIsEditLoad(false);
    }
  }, [addressTypeWatch]);

  // handle validation for address
  const [addressError, setAddressError] = useState<string | null>(null);
  const [enteredValue, setEnteredValue] = useState<string>('');

  // update address state when user select an address suggestion
  const updateAddressValue = (values: SearchAddressResult) => {
    if (!values.postCode) {
      updateAddressError(
        intl.formatMessage({id: 'validation.error.uncompleted_address'})
      );
      return;
    }
    setAddress(values);
    // set picked address as the origin point
    handleSetPoint({
      address: values,
      startRange: range[0],
      endRange: range[1],
      isStore: false,
    });
    setAddressError(null);
  };

  // set error message for address
  const updateAddressError = (message: string | null) =>
    setAddressError(message);

  const startRangeWatch = watch('rangeMin');
  const endRangeWatch = watch('rangeMax');

  // sync range points with slider
  useEffect(() => {
    let start = range[0];
    let end = range[1];
    if (start > end) {
      end = range[0];
      start = range[1];
    }
    setValue('rangeMin', start, {shouldValidate: true});
    setValue('rangeMax', end, {shouldValidate: true});
    if (zone) {
      if (start === 0 && end > 0) {
        start = centerValue; // a minute value to show the inner circle
      }
      const updatedZone = {...zone, fromRange: start, toRange: end};
      setZone(updatedZone);
    }
  }, [range]);

  // set range and circle radius when slider is used
  const updateRange = (newValue: number[]) => {
    if (!isEdit) {
      setIsEdit(true);
    }
    let start = newValue[0];
    let end = newValue[1];
    if (start > end) {
      end = newValue[0];
      start = newValue[1];
    }
    setRange([start, end]);
  };

  const updateRangeDebounce = useCallback(debounce(updateRange, 1000), []);

  // sync range points with slider
  useEffect(() => {
    if (!isEqual(range, [Number(startRangeWatch), Number(endRangeWatch)])) {
      const start = Number(startRangeWatch);
      const end = Number(endRangeWatch);
      updateRangeDebounce([start, end]);
    }
  }, [startRangeWatch, endRangeWatch]);

  // on Edit mode, set default values
  useEffect(() => {
    if (selectedZone && isOpened) {
      const zoneAddress = getStoreAddress(selectedZone.address);
      const rangeMin = selectedZone.rangeMin;
      const rangeMax = selectedZone.rangeMax;
      setValue('address', zoneAddress);
      setValue('deliveryChargePrice', selectedZone.deliveryChargePrice);
      setValue('minimumOrderPrice', selectedZone.minimumOrderPrice);
      setValue('estimatedDeliveryTime', selectedZone.estimatedDeliveryTime);
      setValue('rangeMax', rangeMax);
      setValue('rangeMin', rangeMin);
      setRange([rangeMin, rangeMax]);
      let isStore: boolean;
      if (
        selectedZone.address.latitude !== storeDetails?.address.latitude ||
        selectedZone.address.longitude !== storeDetails.address.longitude
      ) {
        isStore = false;
        setValue('addressType', AddressType.Address);
        setAddress(zoneAddress);
        setIsEditLoad(true);
      } else {
        isStore = true;
        setValue('addressType', AddressType.Store);
      }
      addNewZone(
        zoneAddress,
        rangeMin > 0 ? rangeMin : centerValue,
        rangeMax,
        isStore
      );
    } else {
      resetFields();
      if (!data) {
        return;
      }
      handleSetPoint({
        address: getStoreAddress(data.store.address),
        isStore: true,
      });
    }
  }, [selectedZone, isOpened]);

  // create delivery zone api
  const [createDeliveryZone, {isSuccess: createSuccess, data: createdZone}] =
    useCreateDeliveryZoneMutation();

  // update delivery zone api
  const [updateDeliveryZone, {isSuccess: updateSuccess, error: updateError}] =
    useUpdateDeliveryZoneMutation();

  // save delivery zone
  const submit = (formValues: CreateDeliveryZoneForm) => {
    const data: DeliveryZoneRequestParameter = {
      body: {
        storeId,
        ...formValues,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: `messages.${selectedZone ? 'update_zone' : 'create_zone'}`,
        });
      },
    };
    if (selectedZone) {
      data.id = selectedZone.id;
      updateDeliveryZone(data);
    } else {
      createDeliveryZone(data);
    }
  };

  const resetFields = () => {
    reset({
      address: undefined,
      addressType: AddressType.Store,
      deliveryChargePrice: 0,
      minimumOrderPrice: 0,
      estimatedDeliveryTime: 0,
      rangeMax: 0,
      rangeMin: 0,
    });
  };

  const closeModalAndResetFields = () => {
    closeModal();
    setZone(null);
    resetFields();
    setRange([0, 0]);
    setClearAddress(true);
    setAddress(undefined);
    setIsEditLoad(false);
    if (data) {
      handleSetPoint({
        address: getStoreAddress(data.store.address),
        isStore: true,
      });
    }
  };

  // close modal after successful saving and reset form fields
  useEffect(() => {
    if (createSuccess || updateSuccess) {
      closeModalAndResetFields();
      if (createdZone) {
        setSelectedZone(createdZone.data);
      }
    }
  }, [createSuccess, updateSuccess]);

  useEffect(() => {
    if (updateError) {
      const typedUpdateError = updateError as CustomError;
      if (isApiValidationError(typedUpdateError)) {
        mapErrorToFormFields(typedUpdateError.data.message, setError);
        if (
          isKeyMatching(
            typedUpdateError.data.message as Object,
            'address.address'
          )
        ) {
          updateAddressError(
            intl.formatMessage({id: 'validation.error.invalid_address'})
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  return {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    updateAddressError,
    updateAddressValue,
    address,
    addressError,
    enteredValue,
    setEnteredValue,
    range,
    submit,
    clearAddress,
    isEdit,
    zone,
    updateIsEdit,
    updateRange,
    closeModalAndResetFields,
  };
}
