import {useForm} from 'react-hook-form';
import {joiResolver} from '@hookform/resolvers/joi';
import Joi from 'joi';
import CheckboxController from '../../../Form/HookForm/CheckboxController';
import {Button, Col, Row} from 'react-bootstrap';
import CheckboxGroupController from '../../../Form/HookForm/CheckboxGroupController';
import {checkboxGroupOptions, selectOptions} from './data';
import {DevTool} from '@hookform/devtools';
import InputController from '../../../Form/HookForm/InputController';
import PhoneController from '../../../Form/HookForm/PhoneController';
import SearchTextController from '../../../Form/HookForm/SearchTextController';
import SelectController from '../../../Form/HookForm/SelectController';
import TextAreaController from '../../../Form/HookForm/TextAreaController';
import MultiSelectController from '../../../Form/HookForm/MultiSelectController';
import DateBetweenController from '../../../Form/HookForm/DateBetweenController';
import DateSelectController, {
  DateSelectControllerValue,
} from '../../../Form/HookForm/DateSelectController';

type FormFields = {
  checkbox: boolean;
  checkboxGroup: string[];
  input: string;
  phone: string;
  searchText: string;
  select: string;
  textarea: string;
  multiSelect: string[];
  dateBetween?: {
    from?: string;
    to?: string;
  };
  dateSelect?: DateSelectControllerValue;
};

const HookFormPlayground = () => {
  const schema = Joi.object({
    checkbox: Joi.bool().required(),
    checkboxGroup: Joi.array().required().min(1),
    input: Joi.string().required(),
    phone: Joi.string().required(),
    searchText: Joi.string().required(),
    select: Joi.string().required(),
    textarea: Joi.string().required(),
    multiSelect: Joi.array().required().min(1),
    dateBetween: Joi.required(),
    dateSelect: Joi.required(),
  });

  const {control, handleSubmit} = useForm<FormFields>({
    resolver: joiResolver(schema),
  });

  const onSubmit = (values: FormFields) => {
    console.info({values});
  };

  return (
    <div>
      <h3>Hook Form</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6} className="mb-3">
            <CheckboxController
              label={CheckboxController.name}
              name="checkbox"
              control={control}
            />
          </Col>
          <Col md={6} className="mb-3">
            Checkbox group
            <CheckboxGroupController
              name="checkboxGroup"
              control={control}
              options={checkboxGroupOptions}
            />
          </Col>
          <Col md={6} className="mb-3">
            <InputController
              name="input"
              control={control}
              label={InputController.name}
            />
          </Col>
          <Col md={6} className="mb-3">
            <PhoneController
              name="phone"
              control={control}
              label={PhoneController.name}
            />
          </Col>
          <Col md={6} className="mb-3">
            <SelectController
              name="select"
              items={selectOptions}
              control={control}
              label={SelectController.name}
            />
          </Col>
          <Col md={6} className="mb-3">
            <TextAreaController
              name="textarea"
              control={control}
              label={TextAreaController.name}
            />
          </Col>
          <Col md={6} className="mb-3">
            <MultiSelectController
              name="multiSelect"
              control={control}
              label={MultiSelectController.name}
              options={selectOptions}
            />
          </Col>
          <Col md={6} className="mb-3">
            <DateBetweenController name="dateBetween" control={control} />
          </Col>
          <Col md={6} className="mb-3">
            <DateSelectController name="dateSelect" control={control} />
          </Col>
          <Col md={6} className="mb-3">
            <SearchTextController name="searchText" control={control} />
          </Col>
        </Row>
        <Button type="submit">Submit</Button>
        <DevTool control={control} />
      </form>
    </div>
  );
};

export default HookFormPlayground;
