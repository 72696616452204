import {TagDescription} from '@reduxjs/toolkit/dist/query';
import {FeedbackParams} from '../store/queries/common';
import {DeliveryType, Discount} from '../types/Discount';
import {DiscountEligibility} from '../types/DiscountEligibility';
import {DiscountUsage} from '../types/DiscountUsage';
import {DiscountValidity} from '../types/DiscountValidity';
import {Meta} from '../types/Meta';
import {DateFilter} from '../utils/date-filter';
import {baseApi} from './baseApi';

export type ListDiscountResponse = {
  data: Discount[];
  meta: Meta;
};

export type ListDiscountQueryParameter = {
  query: {
    storeId?: number | string;
    pageSize?: number;
    page?: number;
    order?: 'ASC' | 'DESC';
    orderBy?: string;
    search?: string;
    type?: string;
    status?: string;
    dateFilter?: DateFilter;
    startDate?: Date | string;
    endDate?: Date | string;
    startDateFilter?: DateFilter;
    validityStartDateStartDate?: Date | string;
    validityStartDateEndDate?: Date | string;
    endDateFilter?: DateFilter;
    validityEndDateEndDate?: Date | string;
    validityEndDateStartDate?: Date | string;
    name?: string;
    code?: string;
  };
} & FeedbackParams;

export type DiscountRequestParameter = {
  id?: number;
  body: {
    storeId?: number | string;
    name: string;
    type: string;
    amountType: string;
    amount: number;
    code?: string | null;
    description?: string;
    status?: string;
    isActive?: boolean;
    checkoutMessage: string;
    deliveryType: DeliveryType;
    usage: DiscountUsage;
    validity: DiscountValidity;
    eligibilities?: DiscountEligibility[];
  };
} & FeedbackParams;

export type DeleteDiscountParam = {
  id?: number | string;
} & FeedbackParams;

export type ShowDiscountParam = {
  id?: number | string;
} & FeedbackParams;

export type GetDiscountResponse = {
  discount: Discount;
};

const onInvalidate = (
  result: Discount | undefined
): TagDescription<'Discount'>[] => (result ? ['Discount'] : []);

export const discountApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    listDiscount: builder.query<
      ListDiscountResponse,
      ListDiscountQueryParameter
    >({
      query: ({query}: ListDiscountQueryParameter) => ({
        url: '/discount',
        method: 'GET',
        params: {
          ...query,
        },
      }),
      providesTags: ['Discount'],
    }),
    createDiscount: builder.mutation<any, DiscountRequestParameter>({
      query: ({body}: DiscountRequestParameter) => ({
        url: '/discount',
        method: 'POST',
        body,
      }),
      invalidatesTags: result => onInvalidate(result),
    }),
    deleteDiscount: builder.mutation<any, DeleteDiscountParam>({
      query: ({id}: DeleteDiscountParam) => ({
        url: `/discount/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => onInvalidate(result),
    }),
    getDiscount: builder.query<GetDiscountResponse, ShowDiscountParam>({
      query: ({id}: ShowDiscountParam) => ({
        url: `/discount/${id}`,
        method: 'GET',
      }),
      providesTags: ['Discount'],
    }),
    updateDiscount: builder.mutation<any, DiscountRequestParameter>({
      query: ({body, id}: DiscountRequestParameter) => ({
        url: `/discount/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Discount'],
    }),
  }),
});

export const {
  useListDiscountQuery,
  useCreateDiscountMutation,
  useDeleteDiscountMutation,
  useGetDiscountQuery,
  useUpdateDiscountMutation,
} = discountApi;
