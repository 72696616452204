import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Typography} from '@material-ui/core';
import {useLocation, useParams} from 'react-router-dom';
import StatusIndicator from '../Common/StatusIndicator';
import {TabType} from '../Common/Tabs';
import Avatar from '../Common/Avatar';
import Dropdown, {ItemType} from '../Common/Dropdown';
import TabWidget from '../Widgets/TabWidget';
import * as urls from '../../constants/urls';
import ProfileDetailsTab from './Buyer/ProfileDetailsTab';
import AddressDetailsTab from './Buyer/AddressDetailsTab';
import FinanceDetailsTab from './Buyer/FinanceDetailsTab';
import SaveFeature from '../Shared/SaveFeature';
import ConfirmationPopup from '../Shared/ConfirmationPopup';
import useUserAction from '../../hooks/useUserAction';
import useBuyer from '../../hooks/useBuyer';
import {getColor} from '../../utils/functions';
import {UserStatus} from '../../types/User';

/**
 * Buyer details
 */
const BuyerDetails = () => {
  const intl = useIntl();
  const {pathname} = useLocation();
  const {id} = useParams();

  const {
    headerDetails,
    userData,
    resetFormFields,
    control,
    handleSubmit,
    updateBuyerAccount,
    count,
  } = useBuyer({id});

  //Current path
  const path = urls.BUYER_DETAILS_PATH.replace(':id', id as string);

  // Active tab
  const [activeTab, setActiveTab] = useState(pathname);

  // suspend user hook
  const {
    open: openPopup,
    openConfirmationPopup,
    suspendUserAction,
    unsuspendUserAction,
    deleteUserAction,
  } = useUserAction({
    userId: id,
  });

  // List tabs
  const tabs: TabType[] = [
    {
      label: intl.formatMessage({
        id: 'dashboard.admin.users.buyer.tabs.profile',
      }),
      value: `${path}/profile`,
      children: (
        <ProfileDetailsTab
          id={id}
          form="update-buyer-form"
          handleSubmit={handleSubmit}
          control={control}
          userData={userData}
          updateBuyerAccount={updateBuyerAccount}
        />
      ),
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.admin.users.buyer.tabs.address',
      }),
      value: `${path}/address`,
      children: <AddressDetailsTab id={id} userData={userData} />,
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.admin.users.buyer.tabs.finance',
      }),
      value: `${path}/finance`,
      children: <FinanceDetailsTab id={id} />,
    },
  ];

  // switch active tab
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const actionItems: ItemType[] = [];

  if (userData?.user.status === UserStatus.Suspended) {
    actionItems.push({
      label: intl.formatMessage({
        id: 'dashboard.admin.users.actions.unsuspend_account',
      }),
      onClick: () => openConfirmationPopup('unsuspend', true),
    });
  } else {
    actionItems.push({
      label: intl.formatMessage({
        id: 'dashboard.admin.users.actions.suspend_account',
      }),
      onClick: () => openConfirmationPopup('suspend', true),
    });
  }

  actionItems.push({
    label: intl.formatMessage({
      id: 'dashboard.admin.users.actions.delete_account',
    }),
    onClick: () => openConfirmationPopup('delete', true),
  });

  // Header content
  const header = (
    <div className="container-fluid top-block bg-white border-0">
      <div className="details-header">
        <div className="row align-items-center title-block">
          <div className="col-lg-6">
            <div className="details-header-part d-flex flex-column flex-lg-row align-items-center align-items-lg-start justify-content-center justify-content-lg-start">
              <Avatar size="md" alt={headerDetails.initials} color="neutral" />
              <div className="information-part text-center text-lg-start">
                <div className="d-flex flex-column align-items-center align-items-lg-start">
                  <Typography variant="h3" className="font-weight-bolder">
                    {headerDetails.fullName}
                  </Typography>
                  <StatusIndicator
                    label={headerDetails.status}
                    color={userData ? getColor(userData.user.status) : 'green'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex justify-content-center justify-content-lg-end action-buttons">
              <Dropdown
                title={intl.formatMessage({id: 'actions.actions'})}
                // @ts-ignore
                items={actionItems}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <ConfirmationPopup
        opened={openPopup.suspend}
        handleClose={() => openConfirmationPopup('suspend', false)}
        onOk={suspendUserAction}
        description={intl.formatMessage({
          id: 'dashboard.users.messages.suspend_user_message',
        })}
      />
      <ConfirmationPopup
        opened={openPopup.unsuspend}
        handleClose={() => openConfirmationPopup('unsuspend', false)}
        onOk={unsuspendUserAction}
        description={intl.formatMessage({
          id: 'dashboard.users.messages.unsuspend_user_message',
        })}
      />
      <ConfirmationPopup
        opened={openPopup.delete}
        handleClose={() => openConfirmationPopup('delete', false)}
        onOk={deleteUserAction}
        description={intl.formatMessage({
          id: 'dashboard.users.messages.delete_user_message',
        })}
      />
      {count > 0 && (
        <SaveFeature
          onClose={resetFormFields}
          open
          keyType="buyer"
          num={count}
          type="submit"
          form="update-buyer-form"
        />
      )}

      <div className="details-page">
        {header}
        <TabWidget
          containerClass="container-fluid top-block bg-white"
          wrapperClass="details-header pt-0"
          tabs={tabs}
          value={activeTab}
          onChange={toggle}
        />
      </div>
    </>
  );
};

export default BuyerDetails;
