import {FormGroup} from 'reactstrap';
import {Controller} from 'react-hook-form';
import cx from 'clsx';
import HintTooltip from '../../Common/HintTooltip';
import ToggleButtons from '../../Common/ToggleButtons';

const ToggleButtonsController = (props: any) => {
  const {
    name,
    control,
    label,
    required,
    labelHintText,
    showLabelHint,
    className,
    items,
    ...custom
  } = props;

  return (
    <Controller
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <FormGroup className={cx(className ?? 'mb-3 mb-lg-fg', {error})}>
          {showLabelHint ? (
            <div className="label-with-hint d-flex">
              <span className="small-label pb-2 pe-1">
                {label}
                {required ? '*' : ''}
              </span>
              <HintTooltip id={`hint_${label}`} content={labelHintText} />
            </div>
          ) : (
            label && (
              <span className="small-label pb-2">
                {label}
                {required ? '*' : ''}
              </span>
            )
          )}
          <ToggleButtons
            value={value}
            items={items}
            onChange={onChange}
            {...custom}
          />
        </FormGroup>
      )}
      name={name}
      control={control}
    />
  );
};

export default ToggleButtonsController;
