import {FormControl, FormHelperText} from '@material-ui/core';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import MultiSelect, {MultiSelectProps} from '../../Common/MultiSelect';

export type MultiSelectControllerProps<T extends FieldValues> = Omit<
  MultiSelectProps,
  'name' | 'control' | 'value' | 'onChange'
> & {
  name: Path<T>;
  control: Control<T>;
};

const MultiSelectController = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  ...multiSelectProps
}: MultiSelectControllerProps<T>) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({field: {onChange, value}, fieldState: {error}}) => (
      <FormControl fullWidth {...{error: !!error}}>
        <MultiSelect
          {...multiSelectProps}
          name={name}
          values={Array.isArray(value) ? value : []}
          onChange={onChange}
        />
        {error ? (
          <FormHelperText>{String(error.message)}</FormHelperText>
        ) : null}
      </FormControl>
    )}
  />
);

export default MultiSelectController;
