import {useCallback, useState} from 'react';
import {CollapsibleFilterBlockProps} from '../../components/Widgets/FilterForm';

export type OpenedValue = number | string | null;

export type UseFilterCollapsibleStateReturn = {
  opened: OpenedValue;
  handleBlock: (id: OpenedValue) => {
    isOpen: CollapsibleFilterBlockProps['isOpen'];
    onToggle: CollapsibleFilterBlockProps['onToggle'];
  };
};

export default function useFilterCollapsibleState(
  defaultValue: OpenedValue = null
) {
  const [opened, setOpened] = useState<OpenedValue>(defaultValue);

  const handleBlock = useCallback(
    (id: OpenedValue) => ({
      isOpen: id !== null ? opened === id : false,
      onToggle: (open: boolean) => {
        setOpened(open ? id : null);
      },
    }),
    [opened]
  );

  return {
    opened,
    handleBlock,
  };
}
