import React from 'react';
import {withStyles, Theme} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

/**
 * Tabs item indicator component
 */

export type IndicatorValueProps = {
  variant: 'primary' | 'secondary';
  value: string;
  classes: any;
};

const IndicatorValue = withStyles((theme: Theme) => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    fontWeight: 'bold',
    height: 20,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '&.primary-light': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    '&.secondary-light': {
      backgroundColor: '#EDEDED',
      color: theme.palette.secondary.light,
    },
    [theme.breakpoints.down('md')]: {
      fontWeight: 'normal',
      minWidth: '40%',
      height: 23,
    },
  },
  label: {
    padding: 0,
  },
}))(({variant, value, classes}: IndicatorValueProps) => (
  <Chip
    classes={{
      root: classes.root,
      label: classes.label,
    }}
    className={variant === 'primary' ? 'primary-light' : 'secondary-light'}
    label={value}
  />
));

export default IndicatorValue;
