import {ReactNode, useEffect, useMemo, useState} from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import CustomIconButton, {CustomIconButtonProps} from '../../Common/IconButton';

export type FilterTriggerButtonProps = {
  containerSelector?: string;
  containerElement?: Element;
  children?: ReactNode;
} & Partial<CustomIconButtonProps>;

const FilterTriggerButton = ({
  containerElement,
  containerSelector,
  children,
  ...props
}: FilterTriggerButtonProps) => {
  const [container, setContainer] = useState<Element | null>(null);

  useEffect(() => {
    if (containerElement) {
      setContainer(containerElement);
    } else if (containerSelector) {
      const el = document.querySelector(containerSelector);
      setContainer(el);
    }
  }, [containerElement, containerSelector]);

  const button = useMemo(
    () => (
      <CustomIconButton {...props} className={clsx('m-0', props.className)}>
        {children}
      </CustomIconButton>
    ),
    [props, children]
  );

  if (container) {
    return ReactDOM.createPortal(button, container);
  }

  return button;
};

export default FilterTriggerButton;
