import {PlatformEnum} from './Build';

export enum AssetType {
  AppIcon = 'appIcon',
  SplashScreen = 'splashScreen',
  NotificationIcon = 'notificationIcon',
  AppLogo = 'appLogo',
}

export interface AssetDimensions {
  height: number;
  width: number;
}

export interface Asset {
  id: number;
  appId: number;
  platform: PlatformEnum;
  assetId: string;
  name: string;
  type: AssetType;
  path: string;
  size: number;
  mimeType: string;
  dimensions: AssetDimensions;
  createdAt: Date;
  updatedAt: Date;
}
