import {useEffect, useState} from 'react';
import {
  UpdateAppDetailsParameter,
  useFetchAppDetailsQuery,
  useUpdateAppDetailsMutation,
} from '../../services/appApi';
import useAppDetailsFormRequest, {
  AppGeneralFormValues,
} from './useAppDetailsFormRequest';
import {App} from '../../types/App';
import {unstable_usePrompt as usePrompt} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {formatErrorMessage} from '../../utils/functions';

type UseAppDetailsProps = {
  id: number;
};
const useAppDetails = (props: UseAppDetailsProps) => {
  const {id} = props;
  const intl = useIntl();
  const [count, setCount] = useState(0);
  const [initialValues, setInitialValues] = useState<AppGeneralFormValues>({
    name: '',
    store: '',
    version: '',
    packageName: '',
    description: '',
    template: '',
    supportUrl: '',
    privacyPolicyUrl: '',
  }); // Add this line

  const {data, isSuccess} = useFetchAppDetailsQuery(
    {id, showProgressDialog: true},
    {refetchOnMountOrArgChange: true, refetchOnReconnect: true}
  );

  const [update, {isSuccess: updateSuccess}] = useUpdateAppDetailsMutation();

  const {control, handleSubmit, setValue, reset, formState, watch} =
    useAppDetailsFormRequest();
  const formValuesWatch = watch();

  useEffect(() => {
    if (isSuccess && data) {
      handleSetValues(data);
      setInitialValues({
        name: data.name,
        store: data.store.name,
        version: data.version,
        packageName: data.packageName,
        description: data.description['en-GB'],
        template: data.template,
        supportUrl: data.supportUrl,
        privacyPolicyUrl: data.privacyPolicyUrl,
      });
    }
  }, [data, isSuccess]);

  // alert when user try to leave page when edited field has not been saved
  usePrompt({
    when: count > 0 && !updateSuccess,
    message: intl.formatMessage({id: 'dashboard.confirm_not_saved_message'}),
  });
  // alert when user try to reload when edited field has not been saved
  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent) => {
      if (count > 0 && !updateSuccess) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [count]);
  const countDirtyFields = () => {
    const dirtyFieldsCount = Object.keys(formState.dirtyFields).reduce(
      (count, fieldName) => {
        // Check if the current value is different from the initial value
        if (formValuesWatch[fieldName] !== initialValues[fieldName]) {
          return count + 1;
        }
        return count;
      },
      0
    );

    setCount(dirtyFieldsCount);
  };
  useEffect(() => {
    countDirtyFields();
  }, [formValuesWatch]);

  const handleSetValues = (data: App) => {
    setValue('name', data.name);
    setValue('store', data.store.name);
    setValue('version', data.version);
    setValue('packageName', data.packageName);
    setValue('description', data.description['en-GB']);
    setValue('template', data.template);
    setValue('supportUrl', data.supportUrl);
    setValue('privacyPolicyUrl', data.privacyPolicyUrl);
  };

  const cancelGeneralSave = () => {
    reset();
    if (data) {
      handleSetValues(data);
    }
  };

  const submit = (values: AppGeneralFormValues) => {
    const data: UpdateAppDetailsParameter = {
      id: id,
      body: {
        ...values,
      },
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatErrorMessage(error, intl);
      },
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.update_app_details',
          },
          {}
        );
      },
    };
    update(data);
  };

  return {
    data,
    isSuccess,
    control,
    count,
    handleSubmit,
    submit,
    cancelGeneralSave,
  };
};

export default useAppDetails;
