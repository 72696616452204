import {useEffect, useState} from 'react';
import {useUpdateAppThemeMutation} from '../../services/appApi';
import {Theme} from '../../types/Theme';
import useThemeFormRequest, {ThemeFormValues} from './useThemeFormRequest';
import {useIntl} from 'react-intl';
import {unstable_usePrompt as usePrompt} from 'react-router-dom';
import {formatErrorMessage} from '../../utils/functions';

type UseAppThemeProps = {
  appId: number;
  data: Theme;
};

const useAppTheme = (props: UseAppThemeProps) => {
  const {appId, data} = props;
  const intl = useIntl();
  const [count, setCount] = useState(0);
  const [initialValues, setInitialValues] = useState<ThemeFormValues>({
    primaryColor: '',
    secondaryColor: '',
    textColor: '',
  });

  const [updateTheme, {isSuccess: updateSuccess}] = useUpdateAppThemeMutation();
  const {control, handleSubmit, setValue, reset, formState, watch} =
    useThemeFormRequest();
  const formValuesWatch = watch();

  useEffect(() => {
    if (data) {
      handleSetValues(data);
      setInitialValues({
        primaryColor: data.colors.primaryColor,
        secondaryColor: data.colors.secondaryColor,
        textColor: data.colors.textColor,
      });
    }
  }, [data]);

  useEffect(() => {
    if (updateSuccess) {
      handleCancelSave();
    }
  }, [updateSuccess]);

  // alert when user try to leave page when edited field has not been saved
  usePrompt({
    when: count > 0 && !updateSuccess,
    message: intl.formatMessage({id: 'dashboard.confirm_not_saved_message'}),
  });
  // alert when user try to reload when edited field has not been saved
  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent) => {
      if (count > 0 && !updateSuccess) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [count]);
  const countDirtyFields = () => {
    const dirtyFieldsCount = Object.keys(formState.dirtyFields).reduce(
      (count, fieldName) => {
        // Check if the current value is different from the initial value
        if (formValuesWatch[fieldName] !== initialValues[fieldName]) {
          return count + 1;
        }
        return count;
      },
      0
    );

    setCount(dirtyFieldsCount);
  };
  useEffect(() => {
    countDirtyFields();
  }, [formValuesWatch]);

  const handleSetValues = (data: Theme) => {
    setValue('primaryColor', data.colors.primaryColor);
    setValue('secondaryColor', data.colors.secondaryColor);
    setValue('textColor', data.colors.textColor);
  };

  const handleCancelSave = () => {
    reset();
    if (data) {
      handleSetValues(data);
    }
  };

  const submit = (values: ThemeFormValues) => {
    updateTheme({
      id: appId,
      body: {
        ...values,
      },
      showProgressDialog: true,
      formatErrorMessage: error => {
        return formatErrorMessage(error, intl);
      },
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.update_theme',
          },
          {}
        );
      },
    });
  };
  return {
    count,
    control,
    handleSubmit,
    setValue,
    reset,
    formState,
    updateSuccess,
    submit,
    handleCancelSave,
  };
};

export default useAppTheme;
