import {makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, any>(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.divider,
      top: 0,
    },
    '& .MuiOutlinedInput-root:not(.Mui-error):hover, & .MuiOutlinedInput-root:not(.Mui-error).Mui-focused':
      {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.divider,
        },
      },
    '& .MuiInputBase-root': {
      '&.Mui-error': {
        backgroundColor: theme.palette.error.light,
      },
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(0.95, 1),
      fontSize: 14,
      borderRadius: 4,
      textAlign: ({addonPosition = 'left'}) =>
        addonPosition === 'start' ? 'right' : 'left',
      '&::placeholder': {
        color: theme.palette.secondary.light,
        opacity: 1,
      },
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 10,
    },
    '& .Mui-disabled': {
      backgroundColor: theme.palette.divider,
      color: theme.palette.text.primary,
    },
    '&.notes': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
        borderRadius: 8,
      },
      '& .MuiInputBase-input': {
        height: 23,
        padding: theme.spacing(2),
      },
    },
    '&.disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
      },
    },
    '& .MuiOutlinedInput-root + .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
      fontSize: 12,
    },
    '&.no-border': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: -6,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 10,
    },
    '& .MuiInputAdornment-positionStart > p': {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
    '& .MuiInputAdornment-positionEnd > p': {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0,
      '& .MuiOutlinedInput-input': {
        fontSize: 12,
      },
    },
    '&.autocomplete-search-field': {
      '& .MuiInputBase-input': {
        position: 'relative',
        zIndex: 10,
      },
    },
  },
}));

export default useStyles;
