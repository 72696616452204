import {createStyles, makeStyles, useTheme} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import clsx from 'clsx';

export type StatusColor =
  | 'green'
  | 'error'
  | 'orange'
  | 'yellow'
  | 'secondary'
  | 'danger'
  | 'warning';

export type StatusIndicatorProps = {
  /**
   * Status color
   */
  color: StatusColor;
  /**
   * Status text
   */
  label: string;
  /**
   * Additional class
   */
  className?: string;
};

// Status icon cancelled, failed, expired
const StatusIcon = ({color}: any) => (
  <SvgIcon
    viewBox="0 0 9 9"
    style={{
      fontSize: 9,
      color,
    }}
  >
    <g
      id="SW_Admin_User_Provider"
      transform="translate(-1181.000000, -372.000000)"
      fill="currentColor"
    >
      <g id="Group-2" transform="translate(1181.000000, 372.000000)">
        <circle id="Oval-Copy" fillOpacity="0.2" cx="4.5" cy="4.5" r="4.5" />
        <circle id="Oval-Copy" cx="4.5" cy="4.5" r="2.5" />
      </g>
    </g>
  </SvgIcon>
);

// Styles wrapper
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      '&.small': {
        '& h6': {
          fontSize: 12,
          paddingTop: 0,
        },
      },
    },
    label: {
      paddingLeft: 8,
      paddingTop: 2,
      textTransform: 'capitalize',
    },
  })
);

/**
 * This component render information about record state with color by status prop
 * It can be used for Payment & Shipment entities of order
 */
const StatusIndicator = ({label, color, className}: StatusIndicatorProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const themeColor = color === 'error' ? 'primary' : color;
  const statusColor = theme.palette[themeColor]?.main
    ? theme.palette[themeColor].main
    : theme.palette.secondary.main;

  return (
    <Box className={clsx(classes.root, className)}>
      <StatusIcon color={statusColor} />
      <Typography
        variant="h6"
        className={clsx(classes.label, 'text-truncate')}
        style={{color: statusColor}}
      >
        {label}
      </Typography>
    </Box>
  );
};

StatusIndicator.defaultProps = {
  className: '',
};

export default StatusIndicator;
