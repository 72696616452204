import {useIntl} from 'react-intl';
import {Form} from 'reactstrap';
import InputController from '../../Form/HookForm/InputController';
import PhoneController from '../../Form/HookForm/PhoneController';
import {useFetchStoresQuery} from '../../../services/storeApi';
import useCreateSellerAccount from '../../../hooks/useCreateSellerAccount';
import {useEffect} from 'react';
import AutocompleteMultipleController from '../../Form/HookForm/AutocompleteMultipleController';
import {OptionProps} from '../../Form/Inputs/AutocompleteMultiple';

type CreateSellerFormProps = {
  form: string;
  closePopup: () => void;
};

const CreateSellerForm = ({form, closePopup}: CreateSellerFormProps) => {
  // Translations
  const intl = useIntl();
  // fetch active stores
  const {data, isSuccess} = useFetchStoresQuery({query: {pageSize: 100}});

  // map stores to drop down select options
  let stores: OptionProps[] = [];
  if (data && isSuccess) {
    stores = data?.data.map(store => {
      return {
        title: store.name,
        value: store.id,
        email: store.email,
      };
    });
  }

  // create seller hook to save and validate seller basic input creation
  const {
    control,
    submitCount,
    handleSubmit,
    onSubmit,
    errors,
    isSuccess: isSuccessSellerCreated,
  } = useCreateSellerAccount();

  // close Add Seller Popup after successful creation
  useEffect(() => {
    if (isSuccessSellerCreated) {
      closePopup();
    }
  }, [isSuccessSellerCreated]);

  return (
    <Form
      validated={
        (submitCount > 0 && Object.keys(errors).length === 0) || undefined
      }
      onSubmit={handleSubmit(onSubmit)}
      id={form}
    >
      {/* First name */}
      <InputController
        name="firstName"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.user_details.first_name',
        })}
        required
      />
      {/* Last name */}
      <InputController
        name="lastName"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.user_details.last_name',
        })}
        required
      />
      {/* Email address */}
      <InputController
        name="email"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.users.email_address',
        })}
        required
      />
      {/* Password */}
      <InputController
        name="password"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.users.password',
        })}
        required
        isPassword
      />
      {/* Phone */}
      <PhoneController
        phoneInputProps={{international: true}}
        name="phone"
        control={control}
        label={intl.formatMessage({id: 'dashboard.users.phone'})}
        required
      />
      {/* Store list */}
      <AutocompleteMultipleController
        name="stores"
        control={control}
        className="mb-0"
        options={stores}
        label={intl.formatMessage({id: 'dashboard.users.link_store'})}
      />
    </Form>
  );
};

export default CreateSellerForm;
