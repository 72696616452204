import React, {ReactNode} from 'react';
import {useIntl} from 'react-intl';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {Drawer, makeStyles, Theme, Typography} from '@material-ui/core';
import {Close as CloseIcon} from '@material-ui/icons';
import Button from '../Common/Button';

export type AddPopupType = {
  /**
   * Default width for desktop mode
   */
  width: any;
  /**
   * Popup title
   */
  title: string;
  /**
   * Shown if TRUE
   */
  opened: boolean;
  /**
   * Inner content
   */
  children: ReactNode;
  onClose: () => void;
  /**
   * Shown for special tab opened
   */
  activeTab?: string;
  /**
   * Edit mode is active
   */
  edit?: boolean;
};

// Styles
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[3],
    overflow: 'hidden',
    zIndex: 1000,

    '&.MuiDrawer-paperAnchorDockedRight': {
      width: '15%',
      minWidth: 380,
      borderRadius: '40px 0 0 40px',
      paddingTop: 48,
      paddingBottom: 50,
      overflowY: 'hidden',
    },

    '&.MuiDrawer-paperAnchorBottom': {
      height: '75%',
      borderRadius: '46px 46px 0 0',
      paddingTop: 28,
      paddingBottom: 50,
    },
  },
}));

/**
 * Add popup component
 */
const AddPopup = ({
  width,
  title,
  opened,
  edit,
  children,
  onClose,
  activeTab,
}: AddPopupType) => {
  const intl = useIntl();
  const classes = useStyles();

  const submitTitle = edit ? 'actions.save' : 'actions.add';

  return (
    <Drawer
      anchor={isWidthUp('md', width) ? 'right' : 'bottom'}
      variant="persistent"
      elevation={0}
      classes={{
        paper: classes.paper,
      }}
      open={opened}
      onClose={onClose}
      SlideProps={{
        unmountOnExit: true,
      }}
    >
      <div className="d-flex flex-column flex-grow-1">
        {/* Block content */}
        <div className="filter-content flex-fill">
          {/* Popup header */}
          <div className="filter-header">
            {/* Close action button */}
            <Button
              link
              light
              title={intl.formatMessage({id: 'actions.close'})}
              onClick={onClose}
              icon={<CloseIcon style={{color: '#989898', fontSize: '24px'}} />}
            />
            {/* Popup title */}
            <div className="filter-header-title d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Typography variant="h5">{title}</Typography>
              </div>
            </div>
          </div>
          {/* Popup content */}
          <div className="form-content">{children}</div>
        </div>
        {/* Popup actions */}
        <div className="filter-bottom-actions d-flex align-items-center justify-content-center justify-content-lg-end">
          {/* Cancel */}
          <Button
            link
            title={intl.formatMessage({id: 'actions.cancel'})}
            onClick={onClose}
          />
          {/* Add */}
          <Button
            primary
            title={intl.formatMessage({id: submitTitle})}
            type="submit"
            form={`${activeTab}-form`}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default withWidth()(AddPopup);
