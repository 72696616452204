import Typography from '@material-ui/core/Typography';

type SummaryItemProps = {
  title: string;
  children: React.ReactNode;
};

const SummaryItem = ({title, children}: SummaryItemProps) => (
  <div className="mb-lg-1">
    <Typography variant="subtitle2" color="textSecondary" className="mb-1">
      {title}
    </Typography>
    <Typography variant="subtitle2" className="d-lg-none">
      {children}
    </Typography>
    <Typography className="d-none d-lg-block" style={{lineHeight: 1.42}}>
      {children}
    </Typography>
  </div>
);

export default SummaryItem;
