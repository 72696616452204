import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';

/**
 * Default state object with initial values.
 */
const initialState = {
  time: '',
};

export const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setField: (state, action: PayloadAction<{time: string}>) => {
      state.time = action.payload.time;
    },
    clearInputFields: () => {
      return initialState;
    },
  },
});

export const {setField, clearInputFields} = settingSlice.actions;

const countNonEmptyFields = (time: string) => {
  return time.length > 0 ? 1 : 0;
};

export const selectInputFields = (state: RootState) => ({
  time: state.settings.time,
  count: countNonEmptyFields(state.settings.time),
});

export default settingSlice.reducer;
