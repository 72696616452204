import {Menu} from '../types/Menu';
import {baseApi} from './baseApi';

export const listMenuApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchMenu: builder.query<Menu[], void>({
      query: () => ({
        url: '/menu',
        method: 'GET',
      }),
      providesTags: ['Menu'],
    }),
  }),
});

export const {useFetchMenuQuery} = listMenuApi;
