import {Col, Container, Form, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import InputController from '../../../Form/HookForm/InputController';
import {Control, UseFormHandleSubmit} from 'react-hook-form';
import {AppGeneralFormValues} from '../../../../hooks/app/useAppDetailsFormRequest';
import SelectController from '../../../Form/HookForm/SelectController';
import SaveFeature from '../../../Shared/SaveFeature';
import {App} from '../../../../types/App';
import {Build, PlatformEnum} from '../../../../types/Build';
import BuildItem from './BuildItem';
import {Typography} from '@material-ui/core';
import {ReactComponent as AppleIcon} from '../../../../assets/images/apple.svg';
import {ReactComponent as AndroidIcon} from '../../../../assets/images/android.svg';
import {
  formatErrorMessage,
  openExternalLink,
} from '../../../../utils/functions';
import {useRetryAppBuildMutation} from '../../../../services/appApi';

type GeneralTabProps = {
  control: Control<AppGeneralFormValues, any>;
  count: number;
  cancelSave: () => void;
  handleSubmit: UseFormHandleSubmit<AppGeneralFormValues>;
  updateAppDetails: (formValues: AppGeneralFormValues) => void;
  data?: App;
  buildsData?: Build[];
};

const GeneralTab = (props: GeneralTabProps) => {
  const {
    control,
    count,
    cancelSave,
    handleSubmit,
    updateAppDetails,
    buildsData,
    data,
  } = props;
  const intl = useIntl();

  const [triggerRetryBuild] = useRetryAppBuildMutation();

  const androidBuild = buildsData?.find(
    build => build.platform === PlatformEnum.Android
  );
  const iosBuild = buildsData?.find(
    build => build.platform === PlatformEnum.Ios
  );

  const retryBuild = (buildId: number) => {
    triggerRetryBuild({
      appId: data?.id as number,
      buildId,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'dashboard.app_details.general_tab.retry_build_success',
        });
        return message;
      },
    });
  };

  return (
    <>
      {count > 0 && (
        <SaveFeature
          onClose={cancelSave}
          open
          keyType="app"
          num={count}
          type="submit"
          form="update-app-form"
        />
      )}
      <Container fluid>
        <div className="content-block">
          <Row>
            <Col xxl={11}>
              <Row className="gx-vtl-15">
                <Col lg={3} className="me-4">
                  <Form
                    id="update-app-form"
                    onSubmit={handleSubmit(updateAppDetails)}
                  >
                    <div className="card details-card mb-3">
                      <div className="card-body">
                        <InputController
                          name="name"
                          required
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.general_tab.name',
                          })}
                        />
                        <InputController
                          name="store"
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.general_tab.store',
                          })}
                          control={control}
                          required
                          disabled
                        />
                        <InputController
                          name="version"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.general_tab.app_version',
                          })}
                          required
                        />
                        <InputController
                          name="packageName"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.general_tab.package_name',
                          })}
                          showLabelHint={true}
                          labelHintText={intl.formatMessage({
                            id: 'dashboard.app_details.general_tab.package_name_hint',
                          })}
                          required
                        />
                        <InputController
                          name="description"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.general_tab.description',
                          })}
                          minRows="5"
                          multiline
                        />
                        <SelectController
                          name="template"
                          required
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.general_tab.template',
                          })}
                          items={[
                            {
                              label: 'Default template',
                              value: 'defaultTemplate',
                            },
                          ]}
                        />
                        <InputController
                          name="supportUrl"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.general_tab.support_url',
                          })}
                          arrowLink={true}
                          onArrowClick={() => {
                            openExternalLink(data?.supportUrl ?? '');
                          }}
                        />
                        <InputController
                          name="privacyPolicyUrl"
                          required
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.general_tab.privacy_policy_url',
                          })}
                          arrowLink={true}
                          onArrowClick={() => {
                            openExternalLink(data?.privacyPolicyUrl ?? '');
                          }}
                        />
                      </div>
                    </div>
                  </Form>
                </Col>
                <Col>
                  <Row>
                    <Row>
                      <div className="d-flex align-items-center pb-2">
                        <AppleIcon
                          style={{width: 24, height: 24}}
                          className="me-2"
                        />
                        <Typography
                          style={{fontSize: '1.7rem'}}
                          className="font-weight-bold"
                        >
                          <FormattedMessage id="dashboard.app_details.general_tab.ios" />
                        </Typography>
                      </div>
                    </Row>
                    <Row>
                      {iosBuild ? (
                        <BuildItem
                          build={iosBuild}
                          platform={PlatformEnum.Ios}
                          packageName={data?.packageName ?? ''}
                          onRetryBuild={retryBuild}
                        />
                      ) : (
                        <div className="card details-card mb-3">
                          <div className="card-body">
                            <Typography style={{fontSize: '.9rem'}}>
                              <FormattedMessage id="dashboard.app_details.general_tab.no_build_available_yet" />
                            </Typography>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Row>
                  <Row>
                    <Row>
                      <div className="d-flex align-items-center pb-2">
                        <AndroidIcon
                          style={{width: 24, height: 24}}
                          className="me-2"
                        />
                        <Typography
                          style={{fontSize: '1.7rem'}}
                          className="font-weight-bold"
                        >
                          <FormattedMessage id="dashboard.app_details.general_tab.android" />
                        </Typography>
                      </div>
                    </Row>

                    <Row>
                      {androidBuild ? (
                        <BuildItem
                          build={androidBuild}
                          platform={PlatformEnum.Android}
                          packageName={data?.packageName ?? ''}
                          onRetryBuild={retryBuild}
                        />
                      ) : (
                        <div className="card details-card">
                          <div className="card-body">
                            <Typography style={{fontSize: '.9rem'}}>
                              <FormattedMessage id="dashboard.app_details.general_tab.no_build_available_yet" />
                            </Typography>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default GeneralTab;
