import {FormattedMessage, useIntl} from 'react-intl';
import {Col, Row, Stack} from 'react-bootstrap';
import {Divider, Typography} from '@material-ui/core';
import ProductItem from './ProductItem';
import Review from './Review';
import {Order, OrderStatusEnum} from '../../../types/Order';
import OrderStepper from './OrderStepper';
import {OrderFormValues} from '../../../hooks/order/useOrder';
import {Control} from 'react-hook-form';
import {ReactComponent as DeliveryTimeIcon} from '../../../assets/images/deliveryTime.svg';
import CurrencyController from '../../Form/HookForm/CurrencyController';
import IconButton from '../../Common/IconButton';
import {ReactComponent as PlusIcon} from '../../../assets/images/increment.svg';
import {ReactComponent as MinusIcon} from '../../../assets/images/decrement.svg';
import {OrderStatusHistory} from '../../../types/OrderStatusHistory';
import TimeInputController from '../../Form/HookForm/TimeInputController';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from '../../../store/slices/authSlice';
import {UserProfileEnum} from '../../../types/User';

export type OrderProps = {
  data: Order;
  control: Control<OrderFormValues>;
  isInMinutes: boolean;
  isDelivery: boolean;
  addTenMins: (s?: boolean) => void;
  subtractTenMins: (s?: boolean) => void;
  setValue: (name: string, value: string | number) => void;
  handleReviewStatusUpdate: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
};

const OrderDetail = ({
  data,
  control,
  isInMinutes,
  isDelivery,
  addTenMins,
  subtractTenMins,
  handleReviewStatusUpdate,
  setValue,
}: OrderProps) => {
  const intl = useIntl();
  const {user} = useSelector(selectUserAndToken);

  const label = isDelivery
    ? 'order_details.acceptOrder.delivery_time'
    : 'order_details.acceptOrder.collection_time';

  const reverseArray = [...data.orderStatusHistory].reverse();

  const printAccepted = () => {
    return (
      <form>
        <div className="mb-vtl-16 mb-lg-3">
          <Row className="gx-2 align-items-center">
            <Col xs="auto">
              <span className="d-inline-flex mt-1">
                <DeliveryTimeIcon />
              </span>
            </Col>
            <Col xs="auto" lg={3}>
              <Typography color="textSecondary">
                <FormattedMessage id={label} />
              </Typography>
            </Col>
            <Col xs lg={3}>
              {isInMinutes ? (
                <CurrencyController
                  name="scheduledTimeInMinutes"
                  control={control}
                  prefixLabel={intl.formatMessage({id: 'actions.min'})}
                  prefixRight
                  allowDecimals={false}
                  className="mb-0"
                  disabled={data.status !== OrderStatusEnum.Accepted}
                />
              ) : (
                <TimeInputController
                  name="scheduledTime"
                  control={control}
                  formGroupClass="mb-0"
                  setValue={setValue}
                  timeFormat="HH:mm"
                  use12Hours={false}
                  disabled={data.status !== OrderStatusEnum.Accepted}
                />
              )}
            </Col>
            {data.status === OrderStatusEnum.Accepted && (
              <>
                <Col xs="auto">
                  <IconButton
                    size="sm2"
                    variant="action"
                    onClick={() => subtractTenMins(true)}
                  >
                    <MinusIcon />
                  </IconButton>
                </Col>
                <Col xs="auto">
                  <IconButton
                    size="sm2"
                    variant="action"
                    onClick={() => addTenMins(true)}
                  >
                    <PlusIcon />
                  </IconButton>
                </Col>
              </>
            )}
          </Row>
        </div>
      </form>
    );
  };

  const printCancelled = () => {
    const cancelReason = reverseArray.find(
      (status: OrderStatusHistory) =>
        status.status === OrderStatusEnum.Cancelled
    )?.description;
    return (
      <div className="mb-vtl-16 mt-lg-4 mb-lg-3">
        <Typography variant="subtitle2" color="textSecondary" className="mb-1">
          <FormattedMessage id="order_details.cancelOrder.reason_cancelation" />
        </Typography>
        <Typography>{cancelReason}</Typography>
      </div>
    );
  };

  const printDeclined = () => {
    const declineReason = reverseArray.find(
      (status: OrderStatusHistory) => status.status === OrderStatusEnum.Declined
    )?.description;
    return (
      <div className="mb-vtl-16 mt-lg-4 mb-lg-3">
        <Typography variant="subtitle2" color="textSecondary" className="mb-1">
          <FormattedMessage id="order_details.declineOrder.reason_cancelation" />
        </Typography>
        <Typography>{declineReason}</Typography>
      </div>
    );
  };

  return (
    <div className="card details-card">
      <div className="card-body">
        <Typography variant="h4" className="mb-vtl-16 mb-lg-3 card-title">
          <FormattedMessage id="order_details.orderDetail" />
        </Typography>
        <OrderStepper data={data} />
        {[
          OrderStatusEnum.Accepted,
          OrderStatusEnum.OutForDelivery,
          OrderStatusEnum.ReadyForPickup,
        ].includes(data.status) && printAccepted()}
        {data.status === OrderStatusEnum.Cancelled && printCancelled()}
        {data.status === OrderStatusEnum.Declined && printDeclined()}
        {data.status === OrderStatusEnum.Completed && data.review && (
          <Review
            showReviewSwitch={user?.profileType === UserProfileEnum.Admin}
            data={data.review}
            handleReviewStatusUpdate={handleReviewStatusUpdate}
          />
        )}
        <Divider className="mb-vtl-16 mb-lg-3" />
        {data.orderProducts && (
          <Stack gap={3}>
            {data.orderProducts.map(product => (
              <ProductItem
                key={product.id}
                product={product}
                currency={data.currency}
              />
            ))}
          </Stack>
        )}
      </div>
    </div>
  );
};

export default OrderDetail;
