import {User} from '@sentry/react';
import {Order} from './Order';

export interface ReviewItem {
  id: number;
  rate: number;
  description: string;
  createdAt: string;
  updatedAt: string;
}
export enum ReviewStatus {
  Public = 'public',
  Private = 'private',
}

export interface Review {
  id: number;
  order: Partial<Order>;
  user: Partial<User>;
  status: ReviewStatus;
  rate: number;
  description: string;
  itemId: number;
  createdAt: Date;
}
