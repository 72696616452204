import {Typography} from '@material-ui/core';
import {Stack} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {Container, Row, Col} from 'reactstrap';
import Button from '../../Common/Button';
import {Order, OrderServiceType, OrderStatusEnum} from '../../../types/Order';
import {formatDateToMonth} from '../../../utils/functions';
import StatusIndicator, {StatusColor} from '../../Common/StatusIndicator';
import {ReactComponent as StripeIcon} from '../../../assets/images/stripe.svg';
import {ReactComponent as CalendarIcon} from '../../../assets/images/calendar.svg';
import {ReactComponent as DeliveryIcon} from '../../../assets/images/delivery.svg';
import {ReactComponent as AcceptIcon} from '../../../assets/images/apply.svg';
import {ReactComponent as DeclineIcon} from '../../../assets/images/cancel.svg';
import Dropdown, {ItemType} from '../../Common/Dropdown';
import {upperFirst} from 'lodash';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from '../../../store/slices/authSlice';
import {UserProfileEnum} from '../../../types/User';

type OrderHeaderProps = {
  data: Order;
  showAcceptPopup: () => void;
  showDeclinePopup: () => void;
  showCancelPopup: () => void;
  openConfirmationModel: (status: OrderStatusEnum) => void;
};
const OrderHeader = ({
  data,
  showAcceptPopup,
  showDeclinePopup,
  showCancelPopup,
  openConfirmationModel,
}: OrderHeaderProps) => {
  const intl = useIntl();
  const {user} = useSelector(selectUserAndToken);

  const statusColors: Record<OrderStatusEnum, StatusColor> = {
    [OrderStatusEnum.Pending]: 'secondary',
    [OrderStatusEnum.Accepted]: 'orange',
    [OrderStatusEnum.Declined]: 'secondary',
    [OrderStatusEnum.OutForDelivery]: 'yellow',
    [OrderStatusEnum.ReadyForPickup]: 'yellow',
    [OrderStatusEnum.Cancelled]: 'danger',
    [OrderStatusEnum.Completed]: 'green',
  };

  const isAdmin = user?.profileType === UserProfileEnum.Admin;
  const orderActions: ItemType[] = [];

  if (data.status === OrderStatusEnum.Accepted) {
    if (data.serviceType === OrderServiceType.delivery) {
      orderActions.push({
        id: 2,
        label: intl.formatMessage({
          id: 'order_details.actions.set_out_delivery',
        }),
        onClick: () => openConfirmationModel(OrderStatusEnum.OutForDelivery),
      });
    } else {
      orderActions.push({
        id: 3,
        label: intl.formatMessage({
          id: 'order_details.actions.set_ready_pickup',
        }),
        onClick: () => openConfirmationModel(OrderStatusEnum.ReadyForPickup),
      });
    }
  }
  if (
    [
      OrderStatusEnum.Accepted,
      OrderStatusEnum.OutForDelivery,
      OrderStatusEnum.ReadyForPickup,
    ].includes(data.status)
  ) {
    orderActions.push({
      id: 4,
      label: intl.formatMessage({id: 'order_details.actions.set_completed'}),
      onClick: () => openConfirmationModel(OrderStatusEnum.Completed),
    });
  }

  if (isAdmin) {
    if (
      ![
        OrderStatusEnum.Cancelled,
        OrderStatusEnum.Pending,
        OrderStatusEnum.Declined,
      ].includes(data.status)
    ) {
      orderActions.push({
        id: 1,
        label: intl.formatMessage({id: 'order_details.actions.cancel_order'}),
        onClick: () => showCancelPopup(),
      });
    }
  }

  const actions = (
    <Stack
      direction="horizontal"
      gap={3}
      className="pb-vtl-15 pb-lg-0 justify-content-center justify-content-lg-end"
    >
      {data.status === OrderStatusEnum.Pending && (
        <>
          <Button
            link
            icon={<AcceptIcon />}
            title={intl.formatMessage({id: 'actions.accept'})}
            green
            header
            onClick={showAcceptPopup}
          />
          <Button
            link
            icon={<DeclineIcon />}
            title={intl.formatMessage({id: 'actions.decline'})}
            primary
            header
            onClick={showDeclinePopup}
          />
        </>
      )}
      {orderActions.length > 0 && (
        <Dropdown
          title={intl.formatMessage({id: 'actions.actions'})}
          items={orderActions}
          menuClassName="bg-white"
        />
      )}
    </Stack>
  );

  const statusLabel = {
    [OrderStatusEnum.Pending]: intl.formatMessage({
      id: 'order_details.steps.pending',
    }),
    [OrderStatusEnum.Accepted]: intl.formatMessage({
      id: 'order_details.steps.accepted',
    }),
    [OrderStatusEnum.Declined]: intl.formatMessage({
      id: 'order_details.steps.declined',
    }),
    [OrderStatusEnum.OutForDelivery]: intl.formatMessage({
      id: 'order_details.steps.out_delivery',
    }),
    [OrderStatusEnum.ReadyForPickup]: intl.formatMessage({
      id: 'order_details.steps.ready_for_pickup',
    }),
    [OrderStatusEnum.Cancelled]: intl.formatMessage({
      id: 'order_details.steps.cancelled',
    }),
    [OrderStatusEnum.Completed]: intl.formatMessage({
      id: 'order_details.steps.completed',
    }),
  };

  return (
    <Container fluid className="top-block bg-white">
      <div className="details-header">
        <Row className="align-items-center title-block pb-lg-3">
          <Col lg={6}>
            <div className="details-header-part d-flex flex-column flex-lg-row align-items-center align-items-lg-start justify-content-center justify-content-lg-start">
              <div className="d-flex flex-column flex-lg-row align-items-center">
                <Typography
                  variant="h3"
                  className="font-weight-bolder mb-vtl-18 mb-lg-0 me-lg-2"
                >
                  {data?.store.name}
                </Typography>
                <StatusIndicator
                  label={statusLabel[data.status]}
                  color={statusColors[data.status]}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="d-none d-lg-block">
            {actions}
          </Col>
        </Row>
        <Row className="pb-vtl-8 pb-lg-vtl-45 align-items-center justify-content-center justify-content-lg-start gx-vtl-16 gx-lg-3 gy-2">
          <Col xs="auto">
            <Stack direction="horizontal" gap={2}>
              <StripeIcon />
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className="d-lg-none"
              >
                {data.reference}
              </Typography>
              <Typography color="textSecondary" className="d-none d-lg-block">
                <FormattedMessage
                  id="order_details.orderId"
                  values={{number: data.reference}}
                />
              </Typography>
            </Stack>
          </Col>
          <Col xs="auto">
            <Stack direction="horizontal" gap={2}>
              <CalendarIcon />
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className="d-lg-none"
              >
                {formatDateToMonth(data.createdAt)}
              </Typography>
              <Typography color="textSecondary" className="d-none d-lg-block">
                <FormattedMessage
                  id="order_details.orderPlaced"
                  values={{date: formatDateToMonth(data.createdAt)}}
                />
              </Typography>
            </Stack>
          </Col>
          <Col xs="auto">
            <Stack direction="horizontal" gap={2}>
              <DeliveryIcon />
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className="d-lg-none"
              >
                {upperFirst(data?.serviceType)}
              </Typography>
              <Typography color="textSecondary" className="d-none d-lg-block">
                <FormattedMessage
                  id="order_details.orderType"
                  values={{
                    type: upperFirst(data?.serviceType),
                  }}
                />
              </Typography>
            </Stack>
          </Col>
        </Row>
        <div className="d-lg-none">{actions}</div>
      </div>
    </Container>
  );
};

export default OrderHeader;
