import {Col, Container, Form, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {App} from '../../../../types/App';
import {ReactComponent as AppleIcon} from '../../../../assets/images/apple.svg';
import {ReactComponent as AndroidIcon} from '../../../../assets/images/android.svg';
import SaveFeature from '../../../Shared/SaveFeature';
import {Typography} from '@material-ui/core';
import useAppTheme from '../../../../hooks/app/useAppTheme';
import ColorPickerController from '../../../Form/HookForm/ColorPickerController';
import AssetItem from './AssetItem';
import {PlatformEnum} from '../../../../types/Build';
import {AssetType} from '../../../../types/Asset';
import DropzoneController from '../../../Form/HookForm/DropzoneController';
import useAppAsset from '../../../../hooks/app/useAppAsset';
import {useState} from 'react';
import ConfirmationPopup from '../../../Shared/ConfirmationPopup';

type ThemeTabProps = {
  data: App;
};

const ThemeTab = (props: ThemeTabProps) => {
  const {data} = props;
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  const closeConfirmationPopup = () => {
    setOpen(false);
  };

  const openConfirmationPopup = () => {
    setOpen(true);
  };

  const {control, count, handleSubmit, submit, handleCancelSave} = useAppTheme({
    appId: Number(data?.id),
    data: data!.theme!,
  });

  const findAsset = (platform: PlatformEnum, type: AssetType) =>
    data?.assets?.find(
      asset => asset.platform === platform && asset.type === type
    );

  const appleAppIcon = findAsset(PlatformEnum.Ios, AssetType.AppIcon);
  const appleSplashScreen = findAsset(PlatformEnum.Ios, AssetType.SplashScreen);

  const androidAppIcon = findAsset(PlatformEnum.Android, AssetType.AppIcon);
  const androidSplashScreen = findAsset(
    PlatformEnum.Android,
    AssetType.SplashScreen
  );
  const androidNotificationIcon = findAsset(
    PlatformEnum.Android,
    AssetType.NotificationIcon
  );

  const appLogo = findAsset(PlatformEnum.Common, AssetType.AppLogo);

  const {
    handleDeleteAsset,
    deleteSuccess,
    submit: submitAsset,
    handleSubmit: handleSubmitAsset,
    uploadStatus,
    control: controlAsset,
    setError,
    clearErrors,
    handleImageUpload,
    progress,
    startUpload,
    cancelUpload,
    validateLogoOptions,
  } = useAppAsset({
    appId: data.id,
    asset: appLogo!,
    closeConfirmationPopup,
  });

  return (
    <>
      <ConfirmationPopup
        opened={open}
        handleClose={closeConfirmationPopup}
        onOk={handleDeleteAsset}
        description={intl.formatMessage({
          id: 'dashboard.app_details.theme_tab.messages.delete_asset_confirmation',
        })}
      />
      {count > 0 && (
        <SaveFeature
          onClose={handleCancelSave}
          open
          keyType="app"
          num={count}
          type="submit"
          form="update-app-form"
        />
      )}
      <Container fluid>
        <div className="content-block">
          <Row>
            <Col xxl={11}>
              <Row className="gx-vtl-15">
                <Col lg={3} className="me-4">
                  <div className="card details-card mb-3">
                    <div className="card-body">
                      <div className="mb-2">
                        {appLogo && (
                          <Form
                            id={`update-asset-${appLogo.id}`}
                            onSubmit={handleSubmitAsset(submitAsset)}
                          >
                            <DropzoneController
                              label={intl.formatMessage({
                                id: 'dashboard.app_details.theme_tab.app_logo',
                              })}
                              name="image"
                              format="image"
                              showLabelHint={true}
                              labelHintText={intl.formatMessage({
                                id: 'dashboard.app_details.theme_tab.app_logo_asset_requirement',
                              })}
                              control={controlAsset}
                              canEdit={false}
                              canDelete={true}
                              canCancel={true}
                              cancelUpload={cancelUpload}
                              cannotUpload={false}
                              hasProgress={startUpload}
                              progress={progress}
                              uploadImage={handleImageUpload}
                              uploadSuccess={uploadStatus}
                              deleteImage={openConfirmationPopup}
                              deleteSuccess={deleteSuccess}
                              setError={setError}
                              clearError={clearErrors}
                              url={appLogo.path}
                              validFileOptions={validateLogoOptions}
                              fitImage={true}
                              isTallImage={false}
                            />
                          </Form>
                        )}
                      </div>
                      <Form
                        id="update-app-form"
                        onSubmit={handleSubmit(submit)}
                      >
                        <ColorPickerController
                          control={control}
                          name="primaryColor"
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.theme_tab.primary_color',
                          })}
                        />
                        <ColorPickerController
                          control={control}
                          name="secondaryColor"
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.theme_tab.secondary_color',
                          })}
                        />
                        <ColorPickerController
                          control={control}
                          name="textColor"
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.theme_tab.text_color',
                          })}
                        />
                      </Form>
                    </div>
                  </div>
                </Col>
                <Col className="d-grid gap-2">
                  <Row>
                    <Row>
                      <div className="d-flex align-items-center pb-2">
                        <AppleIcon
                          style={{width: 24, height: 24}}
                          className="me-2"
                        />
                        <Typography
                          style={{fontSize: '1.7rem'}}
                          className="font-weight-bold"
                        >
                          <FormattedMessage id="dashboard.app_details.general_tab.ios" />
                        </Typography>
                      </div>
                    </Row>
                    <Row>
                      <div className="card details-card">
                        <div className="card-body d-grid gap-3">
                          {appleAppIcon && (
                            <AssetItem
                              asset={appleAppIcon}
                              data={data}
                              label={intl.formatMessage({
                                id: 'dashboard.app_details.theme_tab.app_icon',
                              })}
                            />
                          )}
                          {appleSplashScreen && (
                            <AssetItem
                              asset={appleSplashScreen}
                              data={data}
                              label={intl.formatMessage({
                                id: 'dashboard.app_details.theme_tab.splash_screen',
                              })}
                            />
                          )}
                        </div>
                      </div>
                    </Row>
                  </Row>
                  <Row>
                    <Row>
                      <div className="d-flex align-items-center pb-2">
                        <AndroidIcon
                          style={{width: 24, height: 24}}
                          className="me-2"
                        />
                        <Typography
                          style={{fontSize: '1.7rem'}}
                          className="font-weight-bold"
                        >
                          <FormattedMessage id="dashboard.app_details.general_tab.android" />
                        </Typography>
                      </div>
                    </Row>

                    <Row>
                      <div className="card details-card">
                        <div className="card-body d-grid gap-3">
                          {androidAppIcon && (
                            <AssetItem
                              asset={androidAppIcon}
                              data={data}
                              label={intl.formatMessage({
                                id: 'dashboard.app_details.theme_tab.app_icon',
                              })}
                            />
                          )}
                          {androidSplashScreen && (
                            <AssetItem
                              asset={androidSplashScreen}
                              data={data}
                              label={intl.formatMessage({
                                id: 'dashboard.app_details.theme_tab.splash_screen',
                              })}
                            />
                          )}
                          {androidNotificationIcon && (
                            <AssetItem
                              asset={androidNotificationIcon}
                              data={data}
                              label={intl.formatMessage({
                                id: 'dashboard.app_details.theme_tab.notification_icon',
                              })}
                            />
                          )}
                        </div>
                      </div>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default ThemeTab;
