import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDropzone} from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import {
  UseFormSetError,
  UseFormClearErrors,
  FieldErrors,
} from 'react-hook-form';
import {ValidFileOptions} from '../../../../types/valid-file';
import {ReactComponent as BuildCertificateIcon} from '../../../../assets/images/buildCertificate.svg';
import {Stack} from 'react-bootstrap';

/**
 * App Key file dropzone component
 */

const AppKeyDropzone = ({
  onChange,
  validFileOptions,
  setError,
  clearError,
  name,
  accept,
  deleteSuccess,
}: {
  onChange: any;
  validFileOptions?: ValidFileOptions;
  setError?: UseFormSetError<any>;
  clearError?: UseFormClearErrors<any>;
  errors?: FieldErrors<any>;
  name: string;
  required?: boolean;
  accept: any;
  deleteSuccess?: boolean;
}) => {
  const intl = useIntl();
  const [file, setFile] = useState<File>();
  const onDrop = useCallback((acceptedFiles: any) => {
    onChange(acceptedFiles);
    setFile(acceptedFiles[0]);
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    accept,
    maxFiles: 1,
    onDrop,
    maxSize: validFileOptions ? validFileOptions.maxSize : undefined,
    validator: fileValidator,
  });

  function fileValidator(file: File) {
    if (!validFileOptions) {
      return null;
    }

    const maxSize = validFileOptions.maxSize;
    if (file.size > maxSize) {
      setError?.(`${name}`, {
        type: 'custom',
        message: intl.formatMessage({
          id: 'app_details.app_key.app_key_too_large',
        }),
      });
      return {
        code: 'file-too-large',
        message: intl.formatMessage({
          id: 'app_details.app_key.app_key_too_large',
        }),
      };
    } else if (!file.name.match(/\.(p8|keystore)$/)) {
      if (setError) {
        setError(`${name}`, {
          type: 'custom',
          message: intl.formatMessage({
            id: 'app_details.app_key.app_key_unsupported_extension',
          }),
        });
      }
      return {
        code: 'wrong-format',
        message: intl.formatMessage({
          id: 'app_details.app_key.app_key_unsupported_extension',
        }),
      };
    }
    clearError?.(`${name}`);
    return null;
  }

  useEffect(() => {
    if (deleteSuccess) {
      setFile(undefined);
    }
  }, [deleteSuccess]);

  return (
    <>
      <div className="dropzone-field" {...getRootProps()}>
        <input {...getInputProps(onChange)} />
        <Typography
          className="text-placeholder text-center"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'actions.import_app_key_placeholder',
            }),
          }}
        />
      </div>
      {file && (
        <Stack className="pt-3" gap={2} direction="horizontal">
          <BuildCertificateIcon />
          <Typography color="secondary">{file?.name}</Typography>
        </Stack>
      )}
    </>
  );
};

export default AppKeyDropzone;
