/* eslint-disable react/no-unused-prop-types */
import {Fragment, ReactNode, useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import {useIntl} from 'react-intl';
import {useMediaQuery} from 'react-responsive';
import {Stack} from 'react-bootstrap';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
  SvgIcon,
  Collapse,
} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {get, isArray} from 'lodash';
import {
  BREAKPOINTS,
  TABLE_DEFAULT_BREAKPOINT,
} from '../../constants/appConstants';
import DataTableHead, {HeadCellType} from './DataTable/DataTableHead';
import TablePagination from './DataTable/TablePagination';
import useStyles from './DataTable/useDataTableStyles';
import ProgressBar from '../Common/ProgressBar';
import theme from '../../constants/theme';
import {ParameterType} from '../../types/QueryParameter';
import IconButton from '../Common/IconButton';
import Dropdown, {ItemType} from '../Common/Dropdown';
import {ReactComponent as EllipsisIcon} from '../../assets/images/ellipsis.svg';

/**
 * Data Table (main component)
 */

type UploadRowType = {
  status: any | null | undefined;
  level: number;
  id: string | number;
  uploadLevel: number;
};

export type DataTableProps = {
  /**
   * TRUE is rows contain checkbox column, default true
   */
  canSelect?: boolean;
  /**
   * Table data is empty
   */
  empty?: boolean;
  /**
   * Table data is loading now
   */
  loading: boolean;
  /**
   * Bulk actions is shown when items selected
   */
  bulkActions?: boolean;
  /**
   * Parameters for bulk actions block
   */
  bulkProps?: any;
  /**
   * Table head description array
   */
  headCells: HeadCellType[];
  /**
   * Align cells top vertically
   */
  alignTop?: boolean;
  /**
   * Table data
   * contains array of objects with the same fields as headCells object
   */
  rows: any[];
  /**
   * Additional table rows
   * contains array of objects with the same fields as headCells object PLUS upload level value (max 100)
   */
  uploadRows?: UploadRowType[] | null;
  /**
   * Additional action button shown on mobile view
   */
  actionButton: ReactNode | any;
  /**
   * Addtional action on the bottom of table
   */
  actionBottom: ReactNode | any;
  /**
   * Row click action
   */
  onRowClick: (arg0: any, arg1: any) => void;
  /**
   * Current pagination page
   */
  currentPage: number;
  /**
   * Total items, used for pagination
   */
  total: number;
  /**
   * Main callback for the datatable change,
   * fired on pagination change (page, rowsPerPage) and on sorting change (sortBy, sortDirection)
   */
  onChange: (arg0: any) => void;
  /**
   * Default order, whether 'asc', 'desc'
   * asc is used as default value
   */
  defaultOrder?: 'asc' | 'desc';
  /**
   * row per page options
   */
  rowPerPageOptions?: number[];
  /**
   * Default order by column
   */
  defaultOrderBy?: string;
  /**
   * Default row per page value
   */
  defaultRowPerPage?: number;
  /**
   * Mobile view max width breakpoint
   */
  mobileBreakPoint?: number;
  selectedItems?: any[];
  onSelectedItemsChange?: (args0: any | any[]) => void;
  renderBulkActions?: () => void;
  isProcessingBulk?: boolean;
  isSelectedFn?: (row: any) => void;
  onSelect?: (row: any) => void | null;
  onSelectAll?: (isChecked: boolean) => void;
  showBulkActions?: boolean;
  selectedCount?: number;
  className?: string;
  handleParameterChange: (type: ParameterType, value: any) => void;
  hasPermission?: boolean;
  emptyDataMessage?: string;
  wrappedRow?: boolean;
  rowActions?: any[];
  SubTableRows?: (props: any) => any;
  getRowActions?: (row: any) => ItemType[];
  rowClickToggle?: boolean;
};

const UploadIcon = () => (
  <SvgIcon
    style={{
      fontSize: 15,
      margin: '-10px 0 0 10px',
    }}
    viewBox="0 0 15 15"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="SW_Admin_User_Recipient-View-Profile_KYC-Uploading"
        transform="translate(-475.000000, -960.000000)"
      >
        <g id="Group-2" transform="translate(475.000000, 960.000000)">
          <rect
            id="Rectangle-Copy-11"
            fill="#21B59B"
            x="0"
            y="0"
            width="15"
            height="15"
            rx="7.5"
          />
          <polygon
            id="Path"
            fill="#FFFFFF"
            fillRule="nonzero"
            points="4 8.25525064 6.73955109 11.000031 12 5.74478039 11.2447765 5 6.73955109 9.49999612 4.74476488 7.50522543"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

/**
 * Data Table Widget
 */
const DataTable = (props: DataTableProps) => {
  // styles
  const classes = useStyles();
  // rendering data props
  const {
    empty,
    loading,
    canSelect,
    //  bulkActions,
    //  bulkProps,
    className,
    rows,
    uploadRows,
    currentPage,
    total,
    alignTop,
    actionBottom,
    onChange,
    defaultOrder,
    rowPerPageOptions,
    defaultOrderBy,
    defaultRowPerPage,
    hasPermission = true,
    emptyDataMessage,
    wrappedRow,
    rowActions,
    SubTableRows,
    getRowActions,
    rowClickToggle,
  } = props;
  const {
    selectedItems,
    // onSelectedItemsChange,
    //  renderBulkActions,
    isProcessingBulk,
    isSelectedFn,
    onSelect,
    onSelectAll,
    //  showBulkActions,
    selectedCount,
    actionButton,
    onRowClick,
    handleParameterChange,
  } = props;
  const intl = useIntl();
  // order (asc/desc)
  const [order, setOrder] = useState(defaultOrder ?? 'asc');
  // order by column
  const [orderBy, setOrderBy] = useState(defaultOrderBy ?? '');
  // array of selected rows
  const [selected, setSelected] = useState<any>([]);
  // current page shown
  const [page, setPage] = useState<number>(currentPage ?? 1);
  // number of rows shown in one page
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowPerPage);
  // collapse is opened (mobile view)
  const [openedRow, setOpenedRow] = useState(null);
  // when bulk actions in progress we could see all content covered opacity
  const [bulkOpacity, setBulkOpacity] = useState(false);
  const isMobile = useMediaQuery({maxWidth: BREAKPOINTS.LG});
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const [wrappedHeadCells] = useState<any>(
    props.headCells.filter(item => item?.wrapped)
  );

  // Sort by column click handler
  const handleRequestSort = useCallback(
    (property: string) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      handleParameterChange('order', {
        order: isAsc ? 'DESC' : 'ASC',
        orderBy: property,
      });
      // reset page on sort change
      setPage(1);
    },
    [order, orderBy]
  );

  // Select All checkbox click handler
  const handleSelectAllClick = (event: any) => {
    if (onSelectAll) {
      onSelectAll(event?.target?.checked);
    } else {
      // select all rows
      if (event.target.checked) {
        const newSelecteds = rows.map((n: any) => n?.id);
        setSelected(newSelecteds);

        return;
      }
      // unselect all rows
      setSelected([]);
    }
  };

  // Update page when currentPage is changed from outside
  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const useHeadCells = (cells: any[], mobile: boolean) => {
    // Header cells
    let headCells = cells;

    // Update header cells ordering
    const handleWindowResize = () => {
      // Mobile ?
      if (mobile) {
        const newOrdered = cells.filter(item => !item.mobileFirst);
        const mobileFirst = cells.find(item => item.mobileFirst);
        if (mobileFirst) {
          newOrdered.unshift(mobileFirst);
        }
        if (newOrdered[0] && newOrdered[1]) {
          // newOrdered[0].disablePadding = true;
          newOrdered[1].disablePadding = false;
        }
        headCells = newOrdered;
      } else {
        headCells = cells;
      }
    };

    useEffect(() => {
      handleWindowResize();
    }, [cells, mobile]);

    return {headCells};
  };

  // Head cells
  const {headCells} = useHeadCells(
    props.headCells.filter(item => !item.wrapped),
    isMobile
  );

  /**
   * Row click handler
   * @param event
   * @param row
   */
  const handleClick = (event: any, row: any) => {
    // prevented expand/collapse on mobile view
    event.preventDefault();
    event.stopPropagation();

    if (onSelect) {
      onSelect(row);
    } else {
      const id = row?.id;
      // find current row in selected items
      const selectedIndex = selected.indexOf(id);
      // result
      let newSelected: any[] = [];

      // not found
      if (selectedIndex === -1) {
        // add new selected
        newSelected = newSelected.concat(selected, id);
        // found the selected
      } else if (selectedIndex === 0) {
        // in the beginning
        newSelected = newSelected.concat(selected.slice(1));
        // in the end
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        // in the middle
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      // update list of selected items
      setSelected(newSelected);
    }
  };

  // Change page
  const handleChangePage = (newPage: number) => {
    handleParameterChange('page', {page: newPage});
    setPage(newPage);
  };

  // Change rows per page select
  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(1);
    handleParameterChange('pageSize', {pageSize: value});
  };

  // Check if row is selected now
  const isSelected = useCallback(
    (row: any) =>
      isSelectedFn ? isSelectedFn(row) : selected.indexOf(row?.id) !== -1,
    [selected, isSelectedFn]
  );

  // Collapse/expand row click handler
  const handleCollapseClick = (evt: any, id: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    // already expanded
    if (openedRow === id) {
      setOpenedRow(null);
      // expand another row
    } else {
      setOpenedRow(id);
    }
  };

  // Row click handler
  const handleRowClick = (rowId: any, row: any, index: number) => {
    // run parent's callback
    if (typeof onRowClick === 'function') {
      onRowClick(rowId, row);
    }
    if (rowClickToggle) {
      toggleWrappedRow(index);
    }
  };

  // Call onChange when page, rowsPerPage, order and orderBy changed
  useEffect(() => {
    if (onChange) {
      onChange({
        sort: {
          by: orderBy,
          direction: order,
        },
        page,
        rowsPerPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    if (defaultRowPerPage && defaultRowPerPage !== rowsPerPage) {
      setRowsPerPage(defaultRowPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRowPerPage]);

  useEffect(() => {
    if (defaultOrder && defaultOrder !== order) {
      setOrder(defaultOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrder]);

  useEffect(() => {
    if (defaultOrderBy && defaultOrderBy !== orderBy) {
      setOrderBy(defaultOrderBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrderBy]);

  useEffect(() => {
    if (selectedItems !== undefined) {
      setSelected(selectedItems);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (isProcessingBulk !== undefined) {
      setBulkOpacity(isProcessingBulk);
    }
  }, [isProcessingBulk]);

  //Toggle state of expanded row
  const toggleWrappedRow = (rowIndex: number) => {
    const index = expandedRows.indexOf(rowIndex);
    const res: any = [...expandedRows];
    if (index === -1) {
      res.push(rowIndex);
    } else {
      res.splice(index, 1);
    }
    setExpandedRows(res);
  };

  const onClickWrappedRow = useCallback(
    (index: number) => (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      toggleWrappedRow(index);
    },
    [toggleWrappedRow]
  );

  const getWrappedRowContent = ({row, index}: any) => {
    const classNames = 'd-table-cell text-center with-wrapped-row';

    return (
      <TableCell
        className={clsx(classes.tableCell, classNames, {
          expanded: expandedRows.includes(index),
        })}
      >
        <Stack
          direction="horizontal"
          className="gap-vtl-15 gap-vtl-15 justify-content-lg-end pe-lg-vtl-12"
        >
          <IconButton
            variant="action"
            size="sm"
            className="d-none d-lg-block"
            onClick={onClickWrappedRow(index)}
          >
            {expandedRows.includes(index) ? (
              <ExpandLess className={classes.expandIcon} />
            ) : (
              <ExpandMore className={classes.expandIcon} />
            )}
          </IconButton>
          {!!rowActions?.length && (
            <Dropdown
              menuIcon={<EllipsisIcon />}
              items={rowActions}
              data={row}
            />
          )}
          {getRowActions && !!getRowActions(row).length && (
            <Dropdown
              menuIcon={<EllipsisIcon />}
              items={getRowActions(row)}
              data={row}
            />
          )}
        </Stack>
      </TableCell>
    );
  };

  // Data is loading at this moment
  // display only if data is empty
  if (loading) {
    return (
      <Paper className={classes.loadingWrapper} elevation={0}>
        <CircularProgress />
      </Paper>
    );
  }

  // Data is empty
  if (empty) {
    return (
      <Paper className={classes.emptyWrapper} elevation={0}>
        <Typography variant="h3" color="primary">
          {intl.formatMessage({id: 'dashboard.no_results'})}
        </Typography>
      </Paper>
    );
  }

  return (
    <div className={clsx(classes.root, className)}>
      {bulkOpacity && <div className="opacity-backdrop" />}
      <TableContainer className={classes.tableContainer}>
        {loading && (
          <Box className={classes.tableLoadingWrapper}>
            <CircularProgress />
          </Box>
        )}
        <Table
          className={clsx(classes.table, bulkOpacity && 'bulk-opacity')}
          aria-labelledby="tableTitle"
          aria-label="Data table"
        >
          <DataTableHead
            canSelect={canSelect ?? true}
            classes={classes}
            actionButton={actionButton}
            headCells={headCells}
            numSelected={selectedCount || selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          {/* It bulkActions is TRUE and one of them is ticked then it displays the bulk area */}
          {/* bulkActions */}
          {/* Table data */}
          <TableBody>
            {rows.map((row: any, index: number) => {
              // row is checked
              const isItemSelected = isSelected(row);
              // row id
              const labelId = `data-table-checkbox-${index}`;
              // row is uploading
              const itemUploaded = isArray(uploadRows)
                ? uploadRows.find((item: any) => item?.id === row?.id)
                : null;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`${row?.id}-${index}`}>
                  <TableRow
                    className={clsx(
                      classes.tableRow,
                      !!itemUploaded && 'hidden-last-cells',
                      !onRowClick && 'no-clickable-row',
                      itemUploaded && itemUploaded?.status,
                      row?.hasBorderLeft && 'border-left',
                      {expanded: wrappedRow && expandedRows.includes(index)}
                    )}
                    role={canSelect ? 'checkbox' : ''}
                    aria-checked={isItemSelected ? 'true' : 'false'}
                    tabIndex={-1}
                    selected={isItemSelected ?? false}
                    onClick={e => {
                      e.stopPropagation();
                      handleRowClick(row?.id, row, index);
                    }}
                  >
                    <>
                      {canSelect ? (
                        <TableCell
                          className={clsx(
                            classes.tableCell,
                            openedRow === row?.id && 'with-expanded-next-row'
                          )}
                          onClick={event => handleClick(event, row)}
                        ></TableCell>
                      ) : null}
                      {/* On mobile view show not collapsed fields */}
                      {headCells.map(
                        (
                          {
                            name,
                            // disablePadding,
                            numeric,
                            renderValue,
                            collapse,
                            mobileVisibleOnly,
                          }: HeadCellType,
                          key: number
                        ) => (
                          <TableCell
                            key={`cell_${name}`}
                            className={clsx(
                              {
                                'd-none d-lg-table-cell':
                                  collapse && !mobileVisibleOnly,
                                // collapsing part is opened
                                'bold with-expanded-next-row':
                                  openedRow === row.id && key === 0,
                                'marked-completed':
                                  !!itemUploaded &&
                                  key === 0 &&
                                  itemUploaded?.level === 100,
                                'py-0': row?.hasBorderLeft,
                                'valign-top': alignTop,
                              },
                              classes.tableCell
                            )}
                            component="td"
                            id={labelId}
                            scope="row"
                            // alignment
                            align={
                              numeric ||
                              (!collapse && key === headCells.length - 1)
                                ? 'right'
                                : 'left'
                            }
                            style={{
                              borderLeftColor: theme.palette.secondary.main,
                            }}
                          >
                            {typeof renderValue === 'function' ? (
                              renderValue(get(row, name), '', {
                                row,
                                rows,
                                uploadRows,
                              })
                            ) : (
                              // row is selected but not all rows are selected - PRIMARY color
                              <Typography
                                variant="body2"
                                color={
                                  isItemSelected &&
                                  key === 0 &&
                                  openedRow === row.id
                                    ? 'primary'
                                    : 'textPrimary'
                                }
                              >
                                {get(row, name)}
                              </Typography>
                            )}
                            {/* If it is uploaded item - show download completed icon */}
                            {!!itemUploaded &&
                              key === 0 &&
                              itemUploaded?.level === 100 && <UploadIcon />}
                          </TableCell>
                        )
                      )}
                      {/* Expand/Collapse icon button for wrappedRow table */}
                      {wrappedRow && getWrappedRowContent({row, index})}
                      {/* Expand/Collapse icon button (mobile only) */}
                      <TableCell
                        align="right"
                        className={clsx(
                          'd-table-cell d-lg-none ps-0',
                          classes.tableCell,
                          openedRow === row?.id && 'with-expanded-next-row'
                        )}
                        style={{width: '1%', cursor: 'pointer'}}
                        onClick={evt => handleCollapseClick(evt, row.id)}
                      >
                        <Box pl={0.5}>
                          {openedRow === row?.id ? (
                            <ExpandLess className={classes.expandIcon} />
                          ) : (
                            <ExpandMore className={classes.expandIcon} />
                          )}
                        </Box>
                      </TableCell>
                    </>
                  </TableRow>
                  {/*wrapped row*/}
                  {wrappedRow && expandedRows.includes(index) && (
                    <TableRow>
                      {!SubTableRows && (
                        <TableCell
                          colSpan={headCells.length + 1}
                          className={clsx(
                            classes.tableCell,
                            'subtable-wrapper'
                          )}
                        >
                          <TableBody className={classes.subtable}>
                            <TableRow
                              className={clsx(classes.wrappedRow, 'headers')}
                            >
                              {wrappedHeadCells.map(
                                (item: HeadCellType, key: number) => (
                                  <TableCell
                                    key={key}
                                    className={clsx(
                                      classes.tableCell,
                                      'wrapped-row-cell headers'
                                    )}
                                    colSpan={
                                      item?.colspan
                                        ? item?.colspan
                                        : key === headCells.length - 1
                                        ? 2
                                        : 1
                                    }
                                    style={{width: item?.width ?? 'auto'}}
                                  >
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {item.label}
                                    </Typography>
                                  </TableCell>
                                )
                              )}
                            </TableRow>
                            {row.subrows?.map((subrow: any, key: number) => (
                              <TableRow
                                key={key}
                                className={clsx(
                                  classes.tableRow,
                                  classes.wrappedRow,
                                  'cells'
                                )}
                              >
                                {wrappedHeadCells.map(
                                  (head: HeadCellType, key: number) => (
                                    <TableCell
                                      key={key}
                                      className={clsx(
                                        classes.tableCell,
                                        'wrapped-row-cell cells'
                                      )}
                                      colSpan={head?.colspan}
                                    >
                                      {typeof head?.renderValue ===
                                      'function' ? (
                                        head.renderValue(
                                          get(subrow, head.name),
                                          '',
                                          {
                                            subrow,
                                          }
                                        )
                                      ) : (
                                        <Typography variant="body2">
                                          {get(subrow, head.name)}
                                        </Typography>
                                      )}
                                    </TableCell>
                                  )
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </TableCell>
                      )}
                      <TableCell
                        colSpan={headCells.length + 1}
                        className={clsx(classes.tableCell, 'subtable-wrapper')}
                      >
                        {SubTableRows &&
                          wrappedRow &&
                          expandedRows.includes(index) && (
                            <SubTableRows statementId={row.id} />
                          )}
                      </TableCell>
                    </TableRow>
                  )}
                  {!!itemUploaded && (
                    <tr>
                      <td
                        colSpan={headCells.length + 1}
                        className={clsx(
                          classes.progressBarWrapper,
                          itemUploaded?.status
                        )}
                      >
                        <ProgressBar value={itemUploaded?.level} />
                      </td>
                    </tr>
                  )}
                  <TableRow
                    className={row?.hasBorderLeft && 'border-left collapsable'}
                  >
                    <TableCell
                      className={classes.collapsableTableCell}
                      colSpan={
                        headCells.filter(item => !item.collapse).length + 2
                      }
                      style={{
                        borderLeftColor: theme.palette.secondary.main,
                      }}
                    >
                      <Collapse
                        in={openedRow === row.id}
                        className={clsx(classes.collapseWrapper, 'd-lg-none')}
                        unmountOnExit
                      >
                        {/* Render collapsed fields (on mobile view) */}
                        {headCells.map(
                          ({
                            name,
                            label,
                            renderValue,
                            collapse,
                            mobileFirst,
                          }: HeadCellType) =>
                            collapse &&
                            !mobileFirst && (
                              <div
                                key={`cell_[${name}]`}
                                className={clsx(
                                  classes.collapsableRow,
                                  !canSelect && 'ps-3'
                                )}
                              >
                                <div className={classes.collapsableRowItem}>
                                  <Typography variant="subtitle1">
                                    <b>{label}</b>
                                  </Typography>
                                </div>
                                <div className={classes.collapsableRowItem}>
                                  {typeof renderValue === 'function' ? (
                                    renderValue(get(row, name), '', {
                                      row,
                                      rows,
                                      uploadRows,
                                    })
                                  ) : (
                                    <Typography variant="subtitle1">
                                      {get(row, name)}
                                    </Typography>
                                  )}
                                </div>
                              </div>
                            )
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
            {!rows?.length && emptyDataMessage && (
              <TableCell
                colSpan={headCells.length + 1}
                style={{textAlign: 'center'}}
              >
                {emptyDataMessage}
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {actionBottom && (
        <Box pt={3.2} mb={-0.5}>
          {actionBottom}
        </Box>
      )}
      {/* Pagination */}
      {hasPermission && (
        <TablePagination
          className={clsx(classes.tablePagination, 'pagination-row')}
          rowsPerPageOptions={rowPerPageOptions || []}
          count={total}
          rowsPerPage={rowsPerPage || 100}
          page={page}
          bulkOpacity={bulkOpacity}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

DataTable.defaultProps = {
  empty: false,
  rowPerPageOptions: [25, 50, 100],
  defaultOrder: 'asc',
  defaultOrderBy: '',
  defaultRowPerPage: 100,
  mobileBreakPoint: TABLE_DEFAULT_BREAKPOINT,
  isProcessingBulk: false,
  showBulkActions: false,
  bulkActions: false,
  bulkProps: {},
  className: '',
  canSelect: false,
  onSelect: null,
  uploadRows: [],
  selectedItems: [],
  onSelectedItemsChange: undefined,
  renderBulkActions: undefined,
  isSelectedFn: undefined,
  onSelectAll: undefined,
  selectedCount: 0,
  alignTop: false,
  actionButton: null,
  actionBottom: null,
  wrappedRow: false,
  rowActions: [],
};

export default DataTable;
