import {
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Typography,
} from '@material-ui/core';
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import cx from 'clsx';
import {useMediaQuery} from 'react-responsive';
import Stepper from '@material-ui/core/Stepper';
import {ReactComponent as CheckedIcon} from '../../assets/images/check.svg';
import {ReactComponent as UncheckedIcon} from '../../assets/images/uncheck.svg';
import {BREAKPOINTS} from '../../constants/appConstants';

export type StepItemProps = {
  label: string;
  time?: string;
};

export type StepperProps = {
  steps: StepItemProps[];
  activeStep: number;
};

const ConnectorHorizontal = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-100% + 28px)',
    right: 'calc(100% - 9px)',
  },
  line: {
    borderColor: '#989898',
    borderTopWidth: 1,
    borderRadius: 1,
  },
})(StepConnector);

const ConnectorVertical = withStyles({
  root: {
    paddingBottom: 0,
    marginLeft: 0,
    top: -19,
    left: 10,
  },
  line: {
    borderColor: '#EDEDED',
  },
})(StepConnector);

const CustomStepIcon = ({completed}: StepIconProps) => {
  return completed ? <CheckedIcon /> : <UncheckedIcon />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      margin: theme.spacing(2, -0.8),
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        margin: theme.spacing(1.6, 0, 0),
        '& .MuiStep-alternativeLabel': {
          width: '100%',
        },
      },
      '& .MuiStepLabel-horizontal': {
        alignItems: 'flex-start',
        '& .MuiStepLabel-alternativeLabel': {
          textAlign: 'start',
        },
        '&.MuiStepLabel-completed': {
          '& p': {
            color: theme.palette.dark.main,
          },
        },
      },
      '& .MuiStepLabel-vertical': {
        flexDirection: 'row',
        '& .MuiStepLabel-labelContainer': {
          display: 'flex',
          alignItems: 'center',
          marginLeft: theme.spacing(1.6),
          '& .MuiStepLabel-alternativeLabel': {
            width: '100%',
            marginTop: 0,
            textAlign: 'start',
            display: 'flex',
            justifyContent: 'space-between',
          },
        },
      },
      '& .MuiStep-vertical': {
        marginBottom: theme.spacing(1.6),
      },
      '& .MuiStepLabel-iconContainer': {
        zIndex: 100,
      },
    },
  })
);

const CustomStepper = ({steps, activeStep}: StepperProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery({maxWidth: BREAKPOINTS.LG});

  return (
    <Stepper
      alternativeLabel
      orientation={isMobile ? 'vertical' : 'horizontal'}
      activeStep={activeStep}
      classes={classes}
      connector={!isMobile ? <ConnectorHorizontal /> : <ConnectorVertical />}
    >
      {steps.map(({label, time}, key) => (
        <Step key={label}>
          <StepLabel StepIconComponent={CustomStepIcon}>
            <Typography
              color={key >= activeStep ? 'textSecondary' : 'textPrimary'}
              className={cx({
                'font-weight-bold': key === activeStep - 1,
                'mb-1': !isMobile,
              })}
            >
              {label}
            </Typography>
            {key < activeStep && (
              <Typography variant="subtitle2" color="textSecondary">
                {time}
              </Typography>
            )}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
