import {FormattedMessage, useIntl} from 'react-intl';
import cx from 'clsx';
import {Col, Row, Form} from 'reactstrap';
import {Stack} from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditLabel from './EditLabel';
import Button from '../../../Common/Button';
import {ReactComponent as PlusIcon} from '../../../../assets/images/plusRed.svg';
import {ReactComponent as RemoveIcon} from '../../../../assets/images/remove.svg';
import IconButton from '../../../Common/IconButton';
import Switch from '../../../Common/Switch';
import {ProductVariation} from '../../../../types/ProductVariation';
import {Control, UseFormHandleSubmit} from 'react-hook-form';
import useProductVariation, {
  ProductVariationFormValues,
} from '../../../../hooks/useProductVariation';
import {
  PricePrefix,
  ProductOption,
  ProductOptionStatus,
} from '../../../../types/ProductOption';
import {twoDecimalPlaces} from '../../../../utils/functions';
import CurrencyController from '../../../Form/HookForm/CurrencyController';
import useStyles from '../../../Common/useNewLineStyles';
import {useEffect, useState} from 'react';
import ErrorMessage from '../../../Common/ErrorMessage';
import {isMinLessThanOnOption} from './VariationList';

/**
 * Product Variation Details
 */

const VariationHeader = ({
  title,
  description,
  onEditClick,
  onDeleteClick,
}: any) => {
  const classes = useStyles();
  return (
    <div>
      <EditLabel
        onEdit={onEditClick}
        onDelete={onDeleteClick}
        className="pb-vtl-15"
      >
        <Typography variant="h5">{title}</Typography>
      </EditLabel>
      <Typography className={cx('text-muted2', {[classes.item]: true})}>
        {description}
      </Typography>
    </div>
  );
};

const OptionRow = ({item, onDelete, onEdit, onSwitchChange}: any) => {
  const intl = useIntl();
  const currency: keyof typeof PricePrefix = item.price.currency.toString();
  const [option, setOption] = useState<ProductOption>(item);

  const [status, setStatus] = useState<boolean>(
    item.status === ProductOptionStatus.active
  );

  useEffect(() => {
    setOption(item);
    setStatus(item.status === ProductOptionStatus.active);
  }, [item]);

  const handleEditClick = () => {
    onEdit(option);
  };
  const handleDeleteClick = () => {
    onDelete(option);
  };
  const handleSwitchChange = () => {
    const newStatus = status ? false : true;
    const newStatusString = status
      ? ProductOptionStatus.inactive
      : ProductOptionStatus.active;
    setStatus(newStatus);
    onSwitchChange(newStatusString, option);
  };

  return (
    <TableRow component="div">
      <TableCell
        component="div"
        role="button"
        onClick={handleEditClick}
        data-title={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.option.name',
        })}
      >
        <Typography className={cx({'text-muted2': !status})}>
          {option.name}
        </Typography>
      </TableCell>
      <TableCell
        component="div"
        role="button"
        onClick={handleEditClick}
        data-title={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.option.sku_id',
        })}
      >
        <Typography className={cx({'text-muted2': !status})}>
          {option.sku}
        </Typography>
      </TableCell>
      <TableCell
        component="div"
        role="button"
        onClick={handleEditClick}
        data-title={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.option.price',
        })}
      >
        <Typography className={cx({'text-muted2': !status})}>
          {PricePrefix[currency]}
          {twoDecimalPlaces(option.price.price)}
        </Typography>
      </TableCell>
      <TableCell
        component="div"
        className="actions"
        data-title={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.option.status',
        })}
      >
        <Stack
          direction="horizontal"
          gap={3}
          className="justify-content-between justify-content-lg-end"
        >
          <Switch
            name="optionEnable"
            id={option.id}
            small
            green
            onChange={handleSwitchChange}
            checked={status}
          />
          <IconButton size="xs" variant="action" onClick={handleDeleteClick}>
            <RemoveIcon />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
type ProductVariationProps = {
  variation: ProductVariation;
  onEdit: (variation: ProductVariation) => void;
  onDelete: (variation: ProductVariation) => void;
  onAddOption: (variation: ProductVariation) => void;
  onEditOption: (option: ProductOption) => void;
  onDeleteOption: (option: ProductOption, variation: ProductVariation) => void;
  control: Control<ProductVariationFormValues>;
  submit: (formData: ProductVariationFormValues) => void;
  onEditVariationChange: (
    formData: ProductVariationFormValues,
    variation: ProductVariation
  ) => void;
  onEditOptionChange: (status: string, option: ProductOption) => void;
  handleSubmit: UseFormHandleSubmit<ProductVariationFormValues>;
  optionsToUpdate: ProductOption[];
  variationsToUpdate: ProductVariation[];
};
const Variation = (props: ProductVariationProps) => {
  const {
    variation,
    optionsToUpdate,
    variationsToUpdate,
    onEdit,
    onDelete,
    onAddOption,
    onEditOption,
    onEditOptionChange,
    onDeleteOption,
    onEditVariationChange,
  } = props;
  const intl = useIntl();
  const [error, setError] = useState('');
  const {control, getValues} = useProductVariation({
    isEditMode: true,
    selectedVariation: variation,
    onUpdate: () => {},
  });

  // Edit variation
  const onEditClick = () => {
    onEdit(variation);
  };
  // Delete variation
  const onDeleteClick = () => {
    onDelete(variation);
  };
  // add option
  const onAddOptionClick = () => {
    onAddOption(variation);
  };

  // Edit option
  const onEditOptionClick = (item: ProductOption) => {
    onEditOption(item);
  };
  // switch option status
  const onSwitchChangeClick = (newStatus: string, item: ProductOption) => {
    onEditOptionChange(newStatus, item);
  };
  const handleInputChange = () => {
    onMinMaxChange(getValues());
  };
  const onMinMaxChange = (data: ProductVariationFormValues) => {
    onEditVariationChange(data, variation);
  };

  useEffect(() => {
    let updatedVariation = variationsToUpdate.find(v => v.id === variation.id);
    if (updatedVariation) {
      updatedVariation = {
        ...updatedVariation,
        options: variation.options,
      };
    }
    if (isMinLessThanOnOption(updatedVariation ?? variation, optionsToUpdate)) {
      setError(
        intl.formatMessage({
          id: 'dashboard.store_details.options_tab.min_less_than_product_on_error',
        })
      );
    } else {
      setError('');
    }
  }, [optionsToUpdate, variation, variationsToUpdate]);

  const printVariationsHeaders = () => (
    <TableHead component="div">
      <TableRow component="div">
        <TableCell component="div">
          <Typography variant="subtitle2" className="text-muted">
            <FormattedMessage id="dashboard.store_details.options_tab.option.name" />
          </Typography>
        </TableCell>
        <TableCell component="div">
          <Typography variant="subtitle2" className="text-muted">
            <FormattedMessage id="dashboard.store_details.options_tab.option.sku_id" />
          </Typography>
        </TableCell>
        <TableCell component="div">
          <Typography variant="subtitle2" className="text-muted">
            <FormattedMessage id="dashboard.store_details.options_tab.option.price" />
          </Typography>
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <Form id={variation.id.toString()} onChange={handleInputChange}>
        <div className="card details-card">
          <div className="card-body">
            <VariationHeader
              title={variation.name}
              description={variation.description}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
            <hr className="my-vtl-15 text-border" />
            <Typography variant="h5" className="mb-2">
              <FormattedMessage id="dashboard.store_details.options_tab.options" />
            </Typography>
            <Row className="g-2">
              <Col lg={6}>
                <CurrencyController
                  name="min"
                  control={control}
                  allowDecimals={false}
                  allowNegativeValue={false}
                  prefixLabel={
                    <Typography variant="subtitle2" className="text-muted">
                      {intl.formatMessage({
                        id: 'dashboard.store_details.options_tab.min',
                      })}
                    </Typography>
                  }
                />
              </Col>
              <Col lg={6}>
                <CurrencyController
                  name="max"
                  control={control}
                  allowDecimals={false}
                  allowNegativeValue={false}
                  prefixLabel={
                    <Typography variant="subtitle2" className="text-muted">
                      {intl.formatMessage({
                        id: 'dashboard.store_details.options_tab.max',
                      })}
                    </Typography>
                  }
                />
              </Col>
            </Row>
            {/* Option details */}
            <div
              className={cx({
                'pb-2':
                  !variation?.options ||
                  variation?.options!.length === 0 ||
                  error,
              })}
            >
              {variation?.options && variation?.options!.length > 0 && (
                <Table className="table-variations pt-1" component="div">
                  {printVariationsHeaders()}
                  <TableBody>
                    {variation.options.length > 0 &&
                      variation?.options?.map(item => (
                        <OptionRow
                          key={item.id}
                          item={item}
                          onDelete={onDeleteOption}
                          onEdit={onEditOptionClick}
                          onSwitchChange={onSwitchChangeClick}
                        />
                      ))}
                  </TableBody>
                </Table>
              )}
              {error && <ErrorMessage error={error} />}
            </div>
            <Button
              link
              small
              className="text-red"
              icon={<PlusIcon />}
              title={intl.formatMessage({
                id: 'dashboard.store_details.options_tab.new_option',
              })}
              onClick={onAddOptionClick}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default Variation;
