import {useLazyLogoutUserQuery} from '../services/logoutApi';
import {useDispatch} from 'react-redux';
import {logout} from '../store/slices/authSlice';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import * as urls from '../constants/urls';
import {clearMenu} from '../store/slices/menuSlice';

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutTrigger, {data, isSuccess}] = useLazyLogoutUserQuery();

  // logout user
  const logoutUser = async () => {
    localStorage.clear();
    await logoutTrigger();
    dispatch(logout());
    dispatch(clearMenu());
  };

  // reload page
  useEffect(() => {
    if (isSuccess) {
      // redirect to login
      navigate(urls.LOGIN_PATH);
    }
  }, [isSuccess]);

  return {
    logoutUser,
    isSuccess,
    data,
  };
};

export default useLogout;
