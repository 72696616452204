import {useState, ReactNode} from 'react';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {Col} from 'reactstrap';
import TextField, {CustomTextFieldProps} from '../Inputs/TextField';
import HintTooltip from '../../Common/HintTooltip';
import {ReactComponent as EyeOnIcon} from '../../../assets/images/eyeOn.svg';
import {ReactComponent as EyeOffIcon} from '../../../assets/images/eyeOff.svg';
import IconButton from '../../Common/IconButton';
import {ReactComponent as ArrowForwardIcon} from '../../../assets/images/arrowForward.svg';

export type InputControllerProps<T extends FieldValues> = Omit<
  CustomTextFieldProps,
  'control' | 'value' | 'onChange' | 'onBlur'
> & {
  name: Path<T>;
  control: Control<T>;
  inputClass?: string;
  excludeLabel?: boolean;
  label?: string;
  controlClass?: string;
  showLabelHint?: boolean;
  labelHintText?: string | ReactNode;
  required?: boolean;
  disabled?: boolean;
  formGroupClass?: string;
  // input: {onChange, value, name, onBlur, onFocus},
  errorHelperText?: boolean;
  isPassword?: boolean;
  isNumber?: boolean;
  prepend?: any;
  isPrepend?: boolean;
  arrowLink?: boolean;
  onArrowClick?: () => void;
  customAddon?: any;
};

const InputController = <T extends FieldValues>({
  name,
  control,
  disabled = false,
  excludeLabel = false,
  inputClass,
  label,
  controlClass = '',
  defaultValue = '',
  showLabelHint,
  labelHintText,
  required,
  formGroupClass,
  isPrepend,
  // input: {onChange, value, name, onBlur, onFocus},
  errorHelperText = true,
  isPassword,
  prepend,
  arrowLink,
  onArrowClick,
  customAddon,
  ...textFieldProps
}: InputControllerProps<T>) => {
  // Useed for show/hide password value
  const [hidden, setHidden] = useState(true);

  // Toggle password hidden state
  const toggleHidden = () => setHidden(!hidden);

  return (
    <Controller
      name={name}
      control={control}
      // @ts-ignore
      defaultValue={defaultValue}
      render={({field: {value, onChange, onBlur}, fieldState: {error}}) => {
        const touched = !!error;
        const onInputChange = (event: React.ChangeEvent) => {
          onChange(event);
        };

        return (
          <div
            className={clsx(
              'form-group',
              formGroupClass ?? 'mb-3 mb-lg-fg',
              {
                'form-group-invalid': touched && error,
              },
              {'row align-items-stretch gx-2': prepend}
            )}
          >
            {showLabelHint ? (
              <div className="label-with-hint d-flex">
                <span className="small-label pb-2 pe-1">
                  {label}
                  {required ? '*' : ''}
                </span>
                <HintTooltip id={`hint_${label}`} content={labelHintText} />
              </div>
            ) : (
              !excludeLabel && (
                <span className="small-label pb-2">
                  {label}
                  {required ? '*' : ''}
                </span>
              )
            )}
            {prepend && (
              <Col xs={5} lg={4}>
                <div className="bg-border px-2 rounded-sm d-flex align-items-center h-100">
                  <Typography className="text-truncate">{prepend}</Typography>
                </div>
              </Col>
            )}
            <div className={clsx({col: prepend})}>
              <TextField
                {...textFieldProps}
                className={clsx(controlClass, inputClass)}
                onChange={onInputChange}
                helperText={errorHelperText && touched ? error.message : null}
                error={touched && !!error}
                disabled={disabled}
                name={name}
                value={value}
                type={isPassword && hidden ? 'password' : 'text'}
                onBlur={onBlur}
                addon={
                  customAddon ? (
                    customAddon
                  ) : isPassword ? (
                    <IconButton
                      size="xs"
                      variant="action"
                      onClick={toggleHidden}
                    >
                      {hidden ? <EyeOnIcon /> : <EyeOffIcon />}
                    </IconButton>
                  ) : arrowLink ? (
                    <IconButton
                      size="xs"
                      variant="action"
                      onClick={onArrowClick}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  ) : null
                }
                addonPosition={isPrepend ? 'start' : 'end'}
              />
              {/* Show error here if errorHelperText is false */}
              {!errorHelperText && touched && error && (
                <Typography variant="subtitle2">
                  {String(error.message)}
                </Typography>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default InputController;
