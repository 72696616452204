import {useIntl} from 'react-intl';
import cx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {Stack} from 'react-bootstrap';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import Button from '../../../Common/Button';
import {ReactComponent as PlusIcon} from '../../../../assets/images/plusRed.svg';
import Drop from '../../../Widgets/DragDrop/Drop';
import Drag from '../../../Widgets/DragDrop/Drag';
import useDraggablePlaceholder from '../../../Widgets/DragDrop/useDraggablePlaceholder';
import {ProductOptionGroup} from '../../../../types/ProductOptionGroup';
import {onChange} from '../../../../utils/functions';

/**
 * Product option group
 */

export type OptionsProps = {
  optionGroups: ProductOptionGroup[];
  selectedGroup?: ProductOptionGroup;
  onSelect: (item: ProductOptionGroup) => void;
  onAddClick: () => void;
  orderOptionGroup: (
    draggableId: string,
    sourceIndex: number,
    destinationIndex: number
  ) => void;
};

const Options = (props: OptionsProps) => {
  const {optionGroups, selectedGroup, onSelect, onAddClick, orderOptionGroup} =
    props;
  const intl = useIntl();
  const {placeholderProps, onDragUpdate} = useDraggablePlaceholder();

  // Get the number of options in a group
  const getOptionsLength = (group: ProductOptionGroup): number => {
    return group.variations.reduce((length, variation) => {
      return length + variation.options.length;
    }, 0);
  };

  const onDragEnd = (res: DropResult) => {
    const {source, destination, draggableId} = res;
    if (!destination) {
      return;
    }
    // if item was dropped back in same position
    if (onChange(source, destination)) {
      return;
    }

    orderOptionGroup(draggableId, source.index, destination.index);
  };

  return (
    <div className="card details-card">
      <div className="card-body">
        <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
          <Drop
            droppableId="options-0"
            type="OPTIONS"
            placeholderProps={placeholderProps}
          >
            <Stack gap={2} className="pb-2">
              {optionGroups.map((item, index: number) => {
                return (
                  <div
                    key={item.id}
                    tabIndex={-1}
                    onClick={() => onSelect(item)}
                  >
                    <Drag
                      draggableId={item.id.toString()}
                      index={index}
                      className={cx('product-category draggable', {
                        active: selectedGroup?.id === item.id,
                      })}
                    >
                      <Typography>{`${item.name} (${getOptionsLength(
                        item
                      )})`}</Typography>
                    </Drag>
                  </div>
                );
              })}
            </Stack>
          </Drop>
        </DragDropContext>
        {/* Add */}
        <Button
          link
          small
          className="text-red"
          icon={<PlusIcon />}
          title={intl.formatMessage({id: 'actions.add'})}
          onClick={onAddClick}
        />
      </div>
    </div>
  );
};

export default Options;
