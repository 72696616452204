import {useState} from 'react';
import {Button, Form, InputGroup} from 'react-bootstrap';
import {useAppDispatch} from '../../../store/hooks';
import {enqueueMessage} from '../../../store/slices/appSlice';
import {ToastMessage} from '../../Common/feedback/ToastMessages';

const MessagesPlayground = () => {
  const variants: ToastMessage['variant'][] = [
    'success',
    'info',
    'warning',
    'danger',
  ];
  const [variant, setVariant] = useState<string>('success');
  const [title, setTitle] = useState<string>('Message title');
  const [body, setBody] = useState<string>('Hello world');
  const dispatch = useAppDispatch();

  return (
    <div>
      <h2>Messages</h2>
      <div>
        <InputGroup>
          <Form.Select
            aria-label="variant"
            value={variant}
            onChange={(e: any) => setVariant(e.target.value)}
          >
            {variants.map(variant => (
              <option
                key={variant}
                value={variant}
                selected={variant === variant}
              >
                {variant}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            aria-label="message"
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
          />
          <Form.Control
            aria-label="message"
            value={body}
            onChange={(e: any) => setBody(e.target.value)}
          />
          <Button
            variant="outline-secondary"
            onClick={() => {
              dispatch(
                enqueueMessage({
                  title,
                  body,
                  variant,
                })
              );
            }}
          >
            Send
          </Button>
        </InputGroup>
      </div>
    </div>
  );
};

export default MessagesPlayground;
