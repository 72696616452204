import React from 'react';
import {withStyles, Theme} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

/**
 * Dashboard top paper wrapper with shadow
 */
const TopPaper = withStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(6.5),
    marginBottom: theme.spacing(3.5),
    marginRight: -15,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#EDEDED',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0),
      paddingLeft: theme.spacing(1.2),
      paddingRight: theme.spacing(2.2),
      backgroundColor: 'transparent',
      borderBottomStyle: 'none',
      boxShadow: 'none',
    },
  },
}))(({classes, children}: any) => (
  <Paper classes={classes} square elevation={5}>
    {children}
  </Paper>
));

export default TopPaper;
