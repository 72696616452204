import {useIntl} from 'react-intl';
import {
  useOrderDetailsQuery,
  useUpdateOrderMutation,
} from '../../services/orderApi';
import {Order, OrderStatusEnum} from '../../types/Order';
import {formatErrorMessage} from '../../utils/functions';
import {useCallback, useEffect, useMemo, useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {debounce} from 'lodash';

type UseOrderDetailsProps = {
  id: number;
  closePopup?: () => void;
};

export type OrderFormValues = {
  description?: string;
  scheduledTime?: string;
  scheduledTimeInMinutes?: number;
};

export default function useOrderDetails({
  id,
  closePopup,
}: UseOrderDetailsProps) {
  const intl = useIntl();

  const [orderDetails, setOrderDetails] = useState<Order | undefined>();

  // yup validation
  const schema = useMemo(
    () =>
      yup.object().shape({
        scheduledTime: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        scheduledTimeInMinutes: yup
          .number()
          .typeError(
            intl.formatMessage({id: 'validation.error.required_field'})
          )
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        description: yup.string().trim(),
      }),
    [intl]
  );

  // form hook
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState,
    watch,
  } = useForm<OrderFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const scheduledTime = watch('scheduledTime');
  const scheduledTimeInMinutes = watch('scheduledTimeInMinutes');

  const {data, isSuccess, refetch} = useOrderDetailsQuery(
    {
      id,
      showProgressDialog: true,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      skip: !id,
    }
  );

  const [updateOrder, {isSuccess: isUpdateSuccess, data: updatedOrder}] =
    useUpdateOrderMutation();

  useEffect(() => {
    if (data && isSuccess) {
      setOrderDetails(data);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess && updatedOrder) {
      setOrderDetails(updatedOrder);
      closePopup && closePopup();
    }
  }, [isUpdateSuccess, updatedOrder]);

  useEffect(() => {
    if (orderDetails) {
      // Set Default Form Values
      setValue('scheduledTime', orderDetails.scheduledTime);
      setValue('scheduledTimeInMinutes', orderDetails.scheduledTimeInMinutes);
      setValue('description', orderDetails.store.orderRejectionNote);
    }
  }, [orderDetails]);

  useEffect(() => {
    if (
      scheduledTime &&
      orderDetails?.isPreOrder &&
      scheduledTime !== orderDetails?.scheduledTime &&
      orderDetails?.status !== OrderStatusEnum.Pending
    ) {
      updateScheduleTimeWithDebounce(scheduledTime);
    }

    if (
      scheduledTimeInMinutes &&
      !orderDetails?.isPreOrder &&
      scheduledTimeInMinutes !== orderDetails?.scheduledTimeInMinutes &&
      orderDetails?.status !== OrderStatusEnum.Pending
    ) {
      updateScheduleTimeWithDebounce(scheduledTimeInMinutes);
    }
  }, [scheduledTime, scheduledTimeInMinutes]);

  const updateOrderStatus = async (
    status: OrderStatusEnum,
    description?: string,
    scheduleTime?: string | number
  ) => {
    const statusMessages = {
      [OrderStatusEnum.Completed]:
        'order_details.actions_success.order_completed',
      [OrderStatusEnum.ReadyForPickup]:
        'order_details.actions_success.order_ready_pickup',
      [OrderStatusEnum.OutForDelivery]:
        'order_details.actions_success.order_out_delivery',
      [OrderStatusEnum.Cancelled]:
        'order_details.actions_success.order_cancelled',
      [OrderStatusEnum.Pending]: 'order_details.actions_success.order_pending',
      [OrderStatusEnum.Accepted]:
        'order_details.actions_success.order_accepted',
      [OrderStatusEnum.Declined]:
        'order_details.actions_success.order_declined',
    };

    const message = intl.formatMessage({
      id:
        statusMessages[status] || 'order_details.actions_success.order_updated',
    });

    let body = {};
    if (scheduleTime) {
      body = data?.isPreOrder
        ? {scheduledTime: scheduleTime as string}
        : {scheduledTimeInMinutes: scheduleTime as number};
    }

    updateOrder({
      id: id,
      body: {
        status: status,
        description: description,
        ...body,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => message,
    });
  };

  const updateOrderScheduleTime = async (scheduleTime: string | number) => {
    const body = data?.isPreOrder
      ? {scheduledTime: scheduleTime as string}
      : {scheduledTimeInMinutes: scheduleTime as number};

    updateOrder({
      id: id,
      body,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'order_details.actions_success.order_updated',
        });
        return message;
      },
    });
  };

  const onAccept = (formData: OrderFormValues) => {
    const scheduledTime = data?.isPreOrder
      ? formData.scheduledTime
      : formData.scheduledTimeInMinutes;
    updateOrderStatus(OrderStatusEnum.Accepted, undefined, scheduledTime);
  };

  const onDecline = (data: OrderFormValues) => {
    clearErrors('description');
    const description = data.description?.trim();
    if (!description) {
      setError('description', {
        type: 'required',
        message: intl.formatMessage({id: 'validation.error.required_field'}),
      });
      return;
    }
    updateOrderStatus(OrderStatusEnum.Declined, description);
  };

  const onCancel = (data: OrderFormValues) => {
    clearErrors('description');
    const description = data.description?.trim();
    if (!description) {
      setError('description', {
        type: 'required',
        message: intl.formatMessage({id: 'validation.error.required_field'}),
      });
      return;
    }
    updateOrderStatus(OrderStatusEnum.Cancelled, description);
  };

  const submit = (formData: OrderFormValues) => {
    const scheduledTime = data?.isPreOrder
      ? formData.scheduledTime
      : formData.scheduledTimeInMinutes;
    if (!scheduledTime) {
      return;
    }
    debounceUpdateScheduleTime(scheduledTime);
  };

  const addTenMins = (autoSave = false) => {
    const isPreOrder = data?.isPreOrder;
    if (!isPreOrder) {
      clearErrors('scheduledTimeInMinutes');
      const scheduledTimeInMinutes = getValues('scheduledTimeInMinutes');
      const newScheduledTimeInMinutes = Number(scheduledTimeInMinutes) + 10;
      setValue('scheduledTimeInMinutes', newScheduledTimeInMinutes);
      if (autoSave) {
        submit({scheduledTimeInMinutes: newScheduledTimeInMinutes});
      }
      return;
    }

    const scheduledTime = getValues('scheduledTime') as string;
    const time = scheduledTime.split(':');
    let hours = Number(time[0]);
    let minutes = Number(time[1]);
    minutes += 10;
    if (minutes >= 60) {
      minutes = minutes % 60;
      hours += 1;
    }

    if (hours >= 24) {
      hours = 0;
    }

    const newScheduledTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
    setValue('scheduledTime', newScheduledTime);
    if (autoSave) {
      submit({scheduledTime: newScheduledTime});
    }
  };

  const subtractTenMins = (autoSave = false) => {
    const isPreOrder = data?.isPreOrder;
    if (!isPreOrder) {
      clearErrors('scheduledTimeInMinutes');
      const scheduledTimeInMinutes = getValues('scheduledTimeInMinutes');
      let newScheduledTimeInMinutes = Number(scheduledTimeInMinutes) - 10;
      if (newScheduledTimeInMinutes < 0) {
        newScheduledTimeInMinutes = 0;
      }
      setValue('scheduledTimeInMinutes', newScheduledTimeInMinutes);
      if (autoSave) {
        submit({scheduledTimeInMinutes: newScheduledTimeInMinutes});
      }
      return;
    }

    const scheduledTime = getValues('scheduledTime') as string;
    const time = scheduledTime.split(':');
    let hours = Number(time[0]);
    let minutes = Number(time[1]);
    minutes -= 10;
    if (minutes < 0) {
      minutes = (minutes + 60) % 60;
      hours -= 1;
    }
    if (hours < 0) {
      hours = 23;
    }
    const newScheduledTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
    setValue('scheduledTime', newScheduledTime);
    if (autoSave) {
      submit({scheduledTime: newScheduledTime});
    }
  };

  const debounceUpdateScheduleTime = useCallback(
    debounce(updateOrderScheduleTime, 1000),
    []
  );

  const updateScheduleTimeWithDebounce = (value: string | number) => {
    clearErrors('scheduledTimeInMinutes');
    clearErrors('scheduledTime');
    const scheduledTime = value;
    if (!scheduledTime) {
      if (orderDetails?.isPreOrder) {
        setError('scheduledTime', {
          type: 'required',
          message: intl.formatMessage({id: 'validation.error.required_field'}),
        });
      } else {
        setError('scheduledTimeInMinutes', {
          type: 'required',
          message: intl.formatMessage({id: 'validation.error.required_field'}),
        });
      }

      return;
    }
    debounceUpdateScheduleTime(scheduledTime);
  };

  return {
    orderDetails,
    isSuccess,
    updateOrderStatus,
    updateOrderScheduleTime,
    control,
    formState,
    handleSubmit,
    submit,
    setValue,
    onAccept,
    onDecline,
    onCancel,
    addTenMins,
    subtractTenMins,
    refetch,
    updateScheduleTimeWithDebounce,
  };
}
