import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {useParams, useSearchParams} from 'react-router-dom';
import {
  OrderParam,
  ProductOrderParam,
  useDeleteProductMutation,
  useFetchProductQuery,
  useUpdateProductOrderMutation,
} from '../services/productCategoryApi';
import {DeleteParam} from '../services/storeApi';
import {Product} from '../types/Product';
import {ProductCategory} from '../types/ProductCategory';
import {formatErrorMessage, getUrlSearchParams} from '../utils/functions';

export type FilterActionsForm = {
  search?: string;
};

export type OrderState = 'asc' | 'desc';

type UseProductProps = {
  selectedCategory?: ProductCategory | null;
};

type PageState = {
  page: number;
  pageSize: number;
  order: OrderState;
  orderBy: string;
};

export default function useProduct({selectedCategory}: UseProductProps) {
  // Translations
  const intl = useIntl();
  const {storeId} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const size = searchParams.get('size');
  const order = searchParams.get('order');
  const by = searchParams.get('by');

  const [search, setSearch] = useState('');
  const [products, setProductsFn] = useState<Product[]>([]);
  const [isCustomList, setIsCustomList] = useState(false);

  const [pageState, setPageState] = useState<PageState>({
    page: page ? Number(page) : 1,
    pageSize: size ? Number(size) : 10,
    order: (order as OrderState) ?? ('asc' as OrderState),
    orderBy: by ?? 'name',
  });
  const {control, handleSubmit, resetField} = useForm<FilterActionsForm>({
    mode: 'onSubmit',
  });
  // Sort by column click handler
  const handleRequestSort = useCallback(
    (property: string) => {
      const isAsc = pageState.orderBy === property && pageState.order === 'asc';
      const newOrder = isAsc ? 'desc' : 'asc';
      setPageState(prevState => ({
        ...prevState,
        order: newOrder as OrderState,
        orderBy: property,
      }));
    },
    [pageState]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPageState(prevState => ({
        ...prevState,
        page,
      }));
    },
    [pageState]
  );

  const handlePageSizeChange = useCallback(
    (pageSize: number) => {
      setPageState(prevState => ({
        ...prevState,
        pageSize,
      }));
    },
    [pageState]
  );

  const setQueryParams = useCallback(
    (newPageState: PageState) => {
      const {page, pageSize, order, orderBy} = newPageState;
      const p = getUrlSearchParams(searchParams);
      p.set('page', String(page));
      p.set('size', String(pageSize));
      p.set('order', order);
      p.set('by', orderBy);
      setSearchParams(p);
    },
    [pageState]
  );

  useEffect(() => {
    setQueryParams(pageState);
  }, [pageState]);

  useEffect(() => {
    if (selectedCategory) {
      clearSearchField();
    }
  }, [selectedCategory]);

  const [updateProductOrder] = useUpdateProductOrderMutation();

  const setProducts = useCallback((products: Product[], custom = true) => {
    setProductsFn(products);
    setIsCustomList(custom);
  }, []);

  const {data, isSuccess, isFetching} = useFetchProductQuery(
    {
      query: {
        storeId,
        search,
        orderBy: selectedCategory ? 'rank' : pageState.orderBy,
        order: selectedCategory
          ? 'ASC'
          : pageState.order === 'asc'
          ? 'ASC'
          : 'DESC',
        page: pageState.page,
        pageSize: pageState.pageSize,
        categoryId: selectedCategory ? selectedCategory.id : undefined,
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    },
    {refetchOnReconnect: true, refetchOnMountOrArgChange: true}
  );

  // Init products
  useEffect(() => {
    if (data && isSuccess) {
      setProducts(data.data, false);
    }
  }, [data, isSuccess]);

  const orderProduct = (products: OrderParam[]) => {
    const data: ProductOrderParam = {
      categoryId: selectedCategory?.id,
      body: {
        products,
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    };
    updateProductOrder(data);
  };

  const submit = (value: FilterActionsForm) => {
    setSearch(value.search ?? '');
  };

  const clearSearchField = () => {
    setSearch('');
    resetField('search');
  };

  // delete product mutation
  const [deleteProductData, {isSuccess: deleteSuccess, data: deleteData}] =
    useDeleteProductMutation();

  // Delete product action
  const deleteProduct = (productId: number, name: string) => {
    const data: DeleteParam = {
      id: productId,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage(
          {
            id: 'messages.product_delete',
          },
          {name}
        );
        return message;
      },
    };
    deleteProductData(data);
  };

  useEffect(() => {
    if (deleteSuccess && deleteData) {
      const oldProducts = [...products];
      const newItems = oldProducts.filter(
        item => item.id !== deleteData.data.id
      );
      setProducts(newItems);
    }
  }, [deleteSuccess, deleteData]);

  return {
    data: data?.data,
    totalProducts: data?.total,
    meta: data?.meta,
    isSuccess,
    control,
    isFetching,
    orderBy: pageState.orderBy,
    order: pageState.order,
    orderProduct,
    handleSubmit,
    submit,
    clearSearchField,
    handleRequestSort,
    handlePageChange,
    handlePageSizeChange,
    products: isCustomList ? products : data?.data ?? [],
    setProducts,
    deleteProduct,
    deleteData,
    deleteSuccess,
  };
}
