import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import AutocompleteMultiple, {
  AutocompleteMultipleProps,
} from '../Inputs/AutocompleteMultiple';

export type AutocompleteMultipleControllerProps<T extends FieldValues> = Omit<
  AutocompleteMultipleProps,
  'name' | 'control' | 'value' | 'onChange'
> & {
  name: Path<T>;
  control: Control<T>;
  required?: boolean;
};

const AutocompleteMultipleController = <T extends FieldValues>({
  name,
  control,
  options,
  required,
  ...otherProps
}: AutocompleteMultipleControllerProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({field: {onChange, value}, fieldState: {error}}) => (
      <AutocompleteMultiple
        {...otherProps}
        name={name}
        values={Array.isArray(value) ? value : []}
        onChange={onChange}
        options={options}
        error={error?.message || ''}
        required={required}
      />
    )}
  />
);

export default AutocompleteMultipleController;
