import {useIntl} from 'react-intl';
import useDataTableState from './table/useDataTableState';
import {formatErrorMessage, getUrlSearchParams} from '../utils/functions';
import {useFetchStatementOrderQuery} from '../services/statementApi';
import {useEffect, useState} from 'react';
import {parseFilterSearchParams} from '../components/Form/DynamicFilter';
import {isEqual} from 'lodash';
import {setStatement, StatementSliceType} from '../store/slices/statementSlice';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

export type UseStatementOrderListFilterSearchProps = {
  statementId: number;
  statement: StatementSliceType | undefined;
  page: number | undefined;
};
const NUMBER_OF_PAGES = 10;

export default function useStatementOrderList(
  props: UseStatementOrderListFilterSearchProps
) {
  const {statementId, statement, page} = props;
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  // Translations
  const intl = useIntl();
  const [filterValues, setFilterValues] = useState<any>({});
  const [q, setQ] = useState('');
  const [ready, setReady] = useState(false);

  const tableState = useDataTableState({
    defaultValues: {
      orderDirection: 'DESC',
      orderBy: 'period',
      itemsPerPage: NUMBER_OF_PAGES,
    },
    bindQueryParams: false,
  });

  useEffect(() => {
    const id = setTimeout(() => {
      const search = getUrlSearchParams(searchParams);
      const newFilterValues = parseFilterSearchParams(search);
      if (!isEqual(filterValues, newFilterValues)) {
        setFilterValues(newFilterValues);
      }
      const nextQ = search.get('q') ?? '';
      if (q !== nextQ) {
        setQ(nextQ);
      }
      if (!statement || statement?.orderMeta.page !== page) {
        setReady(true);
      }
    }, 500);

    return () => {
      clearTimeout(id);
    };
  }, [searchParams, page]);

  // statement order list data
  const {isSuccess, data, isFetching, isLoading, isError} =
    useFetchStatementOrderQuery(
      {
        query: {
          page: page,
          pageSize: NUMBER_OF_PAGES,
          order: 'DESC',
          orderBy: 'orderDate',
          dateFilter: filterValues?.statementOrderDateFilter?.type ?? undefined,
          startDate: filterValues?.statementOrderDateFilter?.from ?? undefined,
          endDate: filterValues?.statementOrderDateFilter?.to ?? undefined,
        },
        statementId,
        formatErrorMessage: (error: any) => formatErrorMessage(error, intl),
      },
      {
        skip: !ready,
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(
        setStatement({
          orders: data.data,
          orderMeta: data.meta,
          id: statementId,
        })
      );
    }
  }, [isSuccess, data]);

  const handleRowClick = () => {};

  return {
    isLoading,
    isSuccess,
    data: data?.data,
    handleRowClick,
    meta: data?.meta,
    tableState,
    isFetching,
    isError,
    skip: !ready,
  };
}
