import {DiscountEligibility} from './DiscountEligibility';
import {DiscountUsage} from './DiscountUsage';
import {DiscountValidity} from './DiscountValidity';
import {Store} from './Store';

export enum DiscountType {
  OrderAmount = 'orderAmount',
  LoyaltyOffer = 'loyaltyOffer',
}

export enum DiscountAmountType {
  Percentage = 'percentage',
  Fixed = 'fixed',
}

export enum DiscountStatus {
  Active = 'active',
  Inactive = 'inactive',
  Expired = 'expired',
  Upcoming = 'upcoming',
}

export interface DeliveryType {
  collection: boolean;
  delivery: boolean;
}

export interface Discount {
  id: number;
  name: string;
  type: DiscountType;
  amountType: DiscountAmountType;
  amount: number;
  amountPercent: number;
  currency?: string;
  code?: string;
  description?: string;
  status: DiscountStatus;
  isActive: boolean;
  checkoutMessage: string;
  deliveryType: DeliveryType;
  store: Store;
  usage: DiscountUsage;
  validity: DiscountValidity;
  eligibilities: DiscountEligibility[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}
