import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {OptionProps} from '../../components/Form/Inputs/AutocompleteMultiple';
import {ProfileDetailsFormValues} from '../../hooks/useBuyer';
import {RootState} from '../store';

/**
 * Default state object with initial values.
 */
const initialState: ProfileDetailsFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  stores: [],
};

export const buyerSlice = createSlice({
  name: 'buyer',
  initialState,
  reducers: {
    setField: (
      state,
      action: PayloadAction<{
        key: keyof ProfileDetailsFormValues;
        value: string | OptionProps[];
      }>
    ) => {
      const {key, value} = action.payload;
      // @ts-ignore
      state[key] = value;
    },
    clearInputFields: state => {
      Object.keys(state).forEach(key => {
        if (key === 'stores') {
          state.stores = [];
        } else {
          // @ts-ignore
          state[key as keyof ProfileDetailsFormValues] = '';
        }
      });
    },
  },
});

export const {setField, clearInputFields} = buyerSlice.actions;
const buyerStateKeys = ['firstName', 'lastName', 'email', 'phone', 'stores'];

const countNonEmptyFields = (buyerState: ProfileDetailsFormValues) => {
  return buyerStateKeys.reduce((count, key) => {
    if (buyerState[key as keyof ProfileDetailsFormValues]?.length > 0) {
      count += 1;
    }
    return count;
  }, 0);
};
export const selectInputFields = (state: RootState) => ({
  firstName: state.buyer.firstName,
  lastName: state.buyer.lastName,
  email: state.buyer.email,
  phone: state.buyer.phone,
  count: countNonEmptyFields(state.buyer),
});

export default buyerSlice.reducer;
