import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Button from '../Common/Button';
import {Stack} from 'react-bootstrap';

/**
 * Save Feature
 */

export type SaveFeatureProps = {
  keyType: string;
  open: boolean;
  num: number;
  onClose: () => void;
  onSave?: (data: any) => void;
  form?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  saveButtonTitle?: string;
  description?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      left: 46,
      right: 50,
      bottom: 32,
      marginLeft: 240,
      zIndex: 1000,
      [theme.breakpoints.down('lg')]: {
        left: 25,
        right: 25,
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        left: 10,
        right: 10,
        bottom: 10,
      },
      '& .MuiSnackbarContent-root': {
        flexGrow: 1,
        padding: '15px 20px',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[3],
        borderRadius: 8,
        [theme.breakpoints.down('md')]: {
          alignItems: 'flex-start',
          flexDirection: 'column',
        },
        '& .MuiSnackbarContent-action': {
          marginRight: 0,
          [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            paddingLeft: 0,
            paddingTop: 10,
            width: '100%',
          },
          '& .MuiButtonBase-root': {
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          },
          '& .MuiButton-contained': {
            [theme.breakpoints.up('lg')]: {
              minWidth: 97,
            },
          },
        },
      },
    },
  })
);

const SaveFeature = ({
  keyType,
  num,
  open,
  onClose,
  onSave,
  type,
  form,
  saveButtonTitle,
  description,
}: SaveFeatureProps) => {
  const intl = useIntl();
  // open state
  const [isOpen, setIsOpen] = useState(open);

  const classes = useStyles();

  const handleSave = (data: any) => {
    const detailsContainer = document.getElementsByClassName('details-page')[0];
    if (detailsContainer) {
      detailsContainer.scrollTop = 0;
    }
    if (typeof onSave === 'function') {
      onSave(data);
    }
  };

  // Close popup
  const handleClose = () => {
    onClose();
    setIsOpen(false);
  };

  const actions = (
    <Stack
      direction="horizontal"
      gap={4}
      className="align-items-stretch align-items-lg-center w-100"
    >
      <Button
        link
        title={intl.formatMessage({id: 'actions.cancel'})}
        onClick={handleClose}
      />
      <Button
        primary
        title={saveButtonTitle ?? intl.formatMessage({id: 'actions.save'})}
        onClick={data => handleSave(data)}
        type={type}
        form={form}
      />
    </Stack>
  );

  return (
    <Snackbar
      key={keyType}
      disableWindowBlurListener
      classes={classes}
      open={isOpen}
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
    >
      <SnackbarContent
        message={
          description ??
          intl.formatMessage(
            {id: 'dashboard.num_chenged_popup_message'},
            {num: num}
          )
        }
        action={actions}
      />
    </Snackbar>
  );
};

export default SaveFeature;
