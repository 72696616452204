import {Button, Col, Row} from 'react-bootstrap';
import {Build, BuildStatusEnum, PlatformEnum} from '../../../../types/Build';
import {FormattedMessage} from 'react-intl';
import cx from 'clsx';
import {ReactComponent as RedDot} from '../../../../assets/images/red_dot.svg';
import {ReactComponent as GreenDot} from '../../../../assets/images/green_dot.svg';
import {ReactComponent as YellowDot} from '../../../../assets/images/yellow_dot.svg';
import {ReactComponent as RedHint} from '../../../../assets/images/red_hint.svg';
import HintTooltip from '../../../Common/HintTooltip';
import QRCode from 'react-qr-code';

type BuildItemProps = {
  build: Build;
  platform: PlatformEnum;
  packageName: string;
  onRetryBuild: (buildId: number) => void;
};
const BuildItem = (props: BuildItemProps) => {
  const {build, platform, packageName, onRetryBuild} = props;

  const downloadBuild = () => {
    window.open(build.buildFilePath, '_blank');
  };

  return (
    <div className="card details-card mb-3">
      <div className="card-body">
        <Col>
          <Row className="pb-2">
            <div
              className={cx(
                'd-flex justify-content-between align-items-center p-2 rounded-3',
                {
                  'build-ready': build.status === BuildStatusEnum.Ready,
                  'build-inprogress':
                    build.status === BuildStatusEnum.InProgress,
                  'build-failure': build.status === BuildStatusEnum.Failed,
                }
              )}
            >
              <p
                className={cx('build-text-size', {
                  'build-text-ready': build.status === BuildStatusEnum.Ready,
                  'build-text-inprogress':
                    build.status === BuildStatusEnum.InProgress,
                  'build-text-failure': build.status === BuildStatusEnum.Failed,
                })}
              >
                <FormattedMessage id="dashboard.app_details.general_tab.status" />
              </p>
              <div className="d-flex align-items-center">
                {build.status === BuildStatusEnum.Ready && <GreenDot />}
                {build.status === BuildStatusEnum.InProgress && <YellowDot />}
                {build.status === BuildStatusEnum.Failed && <RedDot />}
                <p
                  className={cx('ps-1 px-1 build-text-size', {
                    'build-text-ready': build.status === BuildStatusEnum.Ready,
                    'build-text-inprogress':
                      build.status === BuildStatusEnum.InProgress,
                    'build-text-failure':
                      build.status === BuildStatusEnum.Failed,
                  })}
                >
                  <FormattedMessage
                    id={`dashboard.app_details.general_tab.build_status.${build.status}`}
                  />
                </p>
                {build.status === BuildStatusEnum.Failed && (
                  <HintTooltip
                    id="111"
                    content={build.buildLog}
                    icon={<RedHint />}
                  />
                )}
              </div>
            </div>
          </Row>
          <Row className="pb-2">
            <div className="d-flex justify-content-between align-items-center bg-light bg-opacity-75 p-2 rounded-3">
              <p className="build-label-text">
                <FormattedMessage id="dashboard.app_details.general_tab.type" />
              </p>
              <p className="build-text-size">
                <FormattedMessage
                  id={`dashboard.app_details.general_tab.build_env.${build.env}`}
                />
              </p>
            </div>
          </Row>
          <Row className="pb-2">
            <div className="d-flex justify-content-between align-items-center bg-light bg-opacity-75 p-2 rounded-3">
              <p className="build-label-text">
                <FormattedMessage
                  id={
                    platform === PlatformEnum.Ios
                      ? 'dashboard.app_details.general_tab.build_number'
                      : 'dashboard.app_details.general_tab.version_code'
                  }
                />
              </p>
              <p className="build-text-size">
                <FormattedMessage
                  id="dashboard.app_details.general_tab.version"
                  values={{
                    version: build.appVersion,
                    versionCode: build.versionCode,
                  }}
                />
              </p>
            </div>
          </Row>
          <Row className="pb-2">
            <div className="d-flex justify-content-between align-items-center bg-light bg-opacity-75 p-2 rounded-3">
              <p className="build-label-text">
                <FormattedMessage
                  id={
                    platform === PlatformEnum.Ios
                      ? 'dashboard.app_details.general_tab.bundle_id'
                      : 'dashboard.app_details.general_tab.package'
                  }
                />
              </p>
              <p className="build-text-size">{packageName}</p>
            </div>
          </Row>
          {build.status === BuildStatusEnum.Ready && (
            <Row className="p-2">
              <div className="d-flex align-items-center">
                {build.buildFilePath && (
                  <div style={{padding: '20px', backgroundColor: '#fafafa'}}>
                    <QRCode
                      value={build.buildFilePath}
                      size={100}
                      id="QRCode"
                      style={{cursor: 'default'}}
                    />
                  </div>
                )}
                <Button
                  className="build-rounded-red-button mx-4"
                  onClick={downloadBuild}
                >
                  <FormattedMessage id="dashboard.app_details.general_tab.download" />
                </Button>
              </div>
            </Row>
          )}
          {build.status === BuildStatusEnum.Failed && (
            <Row className="p-2">
              <div className="d-flex align-items-center justify-content-end w-100">
                <Button
                  className="build-rounded-red-button"
                  onClick={() => onRetryBuild(build.id)}
                >
                  <FormattedMessage id="dashboard.app_details.general_tab.retry_build" />
                </Button>
              </div>
            </Row>
          )}
        </Col>
      </div>
    </div>
  );
};

export default BuildItem;
