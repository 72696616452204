import {useIntl} from 'react-intl';
import {Col, Row} from 'react-bootstrap';
import CurrencyController from '../../Form/HookForm/CurrencyController';
import {ReactComponent as PlusIcon} from '../../../assets/images/increment.svg';
import {ReactComponent as MinusIcon} from '../../../assets/images/decrement.svg';
import IconButton from '../../Common/IconButton';
import {Control, FormState} from 'react-hook-form';
import {OrderFormValues} from '../../../hooks/order/useOrder';
import TimeInputController from '../../Form/HookForm/TimeInputController';
import clsx from 'clsx';

type AcceptFormProps = {
  control: Control<OrderFormValues>;
  formState: FormState<OrderFormValues>;
  isInMinutes: boolean;
  isDelivery: boolean;
  addTenMins: () => void;
  subtractTenMins: () => void;
  setValue: (name: string, value: string | number) => void;
};
const AcceptForm = ({
  control,
  isDelivery,
  isInMinutes,
  addTenMins,
  subtractTenMins,
  setValue,
  formState,
}: AcceptFormProps) => {
  const intl = useIntl();

  const label = isDelivery
    ? 'order_details.acceptOrder.delivery_time'
    : 'order_details.acceptOrder.collection_time';

  const isFormError = !formState.isValid;
  return (
    <form>
      <Row className="gx-vtl-16 gx-lg-2 align-items-center mb-vtl-4 mb-lg-2">
        <Col className="d-flex align-items-center">
          <div className="flex-grow-1">
            {isInMinutes ? (
              <CurrencyController
                name="scheduledTimeInMinutes"
                control={control}
                label={intl.formatMessage({
                  id: label,
                })}
                prefixLabel={intl.formatMessage({id: 'actions.min'})}
                prefixRight
                allowDecimals={false}
                className="mb-0"
              />
            ) : (
              <TimeInputController
                label={intl.formatMessage({
                  id: 'dashboard.discount_details.start_time_gmt',
                })}
                formGroupClass="mb-0"
                name="scheduledTime"
                control={control}
                setValue={setValue}
                timeFormat="HH:mm"
                use12Hours={false}
              />
            )}
          </div>
          <div
            className={clsx(
              'd-flex align-items-center ms-2',
              !isFormError && 'mt-3'
            )}
          >
            <IconButton
              size="sm2"
              variant="action"
              onClick={() => subtractTenMins()}
            >
              <MinusIcon />
            </IconButton>
            <IconButton
              size="sm2"
              variant="action"
              className="ms-2"
              onClick={() => addTenMins()}
            >
              <PlusIcon />
            </IconButton>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default AcceptForm;
