import {
  AnyAction,
  createListenerMiddleware,
  ListenerEffectAPI,
  ListenerMiddlewareInstance,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import {get} from 'lodash';
import {exampleApi} from '../queries/exampleApi';
import {
  enqueueErrorMessage,
  enqueueMessage,
  hideProgressDialog,
  showProgressDialog,
} from '../slices/appSlice';
import {signInApi} from '../../services/signInApi';
import {sellerApi} from '../../services/sellerApi';
import {buyerApi} from '../../services/buyerApi';
import {userApi} from '../../services/userApi';
import {paymentApi} from '../../services/paymentApi';
import {storeApi} from '../../services/storeApi';
import {settingApi} from '../../services/settingApi';
import {productCategoryApi} from '../../services/productCategoryApi';
import {productOptionGroupApi} from '../../services/productOptionGroupApi';
import {scheduleApi} from '../../services/scheduleApi';
import {discountApi} from '../../services/discountApi';
import {deliveryApi} from '../../services/deliveryApi';
import {feeApi} from '../../services/feeApi';
import {orderApi} from '../../services/orderApi';
import {statementApi} from '../../services/statementApi';
import {reviewApi} from '../../services/reviewApi';
import {appApi} from '../../services/appApi';

/**
 * https://redux-toolkit.js.org/api/createListenerMiddleware
 * RTK Listener middleware, used to replace redux-saga
 */
const listenerMiddlewares: ListenerMiddlewareInstance[] = [];

export function showOrHideDialogIfPresent(action: any, show: boolean) {
  const progressDialog = get(
    action,
    'meta.arg.originalArgs.showProgressDialog',
    null
  );
  const aborted = get(action, 'meta.aborted', null);
  const condition = get(action, 'meta.condition', null);
  const shouldShow = !condition || (condition && aborted);
  if (progressDialog && shouldShow) {
    if (show) {
      return showProgressDialog();
    } else {
      return hideProgressDialog();
    }
  }

  return null;
}

const showProgressEffect = async (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >
) => {
  const res = showOrHideDialogIfPresent(action, true);
  if (res) {
    listenerApi.dispatch(res);
  }
};

const hideProgressEffect = async (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >
) => {
  const res = showOrHideDialogIfPresent(action, false);
  if (res) {
    listenerApi.dispatch(res);
  }
};

const showSuccessMessageEffect = async (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >
) => {
  const messages: string[] = [];
  const successMessage = get(
    action,
    'meta.arg.originalArgs.successMessage',
    null
  );
  const formatSuccessMessage = get(
    action,
    'meta.arg.originalArgs.formatSuccessMessage'
  );

  if (successMessage) {
    messages.push(successMessage);
  }

  if (formatSuccessMessage) {
    try {
      const errorData = get(action, 'payload', get(action, 'error', null));
      const formattedError = formatSuccessMessage(errorData);
      if (formattedError) {
        if (Array.isArray(formattedError)) {
          messages.push(...formattedError);
        } else {
          messages.push(formattedError);
        }
      }
    } catch (e) {
      console.warn('Unable to format success message', e);
    }
  }

  messages.forEach(msg => {
    listenerApi.dispatch(
      enqueueMessage({
        body: msg,
        variant: 'success',
      })
    );
  });
};

const showErrorMessageEffect = async (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >
) => {
  const errorMessage = get(action, 'meta.arg.originalArgs.errorMessage');
  const formatErrorMessage = get(
    action,
    'meta.arg.originalArgs.formatErrorMessage'
  );
  const messages: string[] = [];
  if (errorMessage) {
    messages.push(errorMessage);
  }
  if (formatErrorMessage) {
    try {
      const errorData = get(action, 'payload', get(action, 'error', null));
      const formattedError = formatErrorMessage(errorData);
      if (formattedError) {
        if (Array.isArray(formattedError)) {
          messages.push(...formattedError);
        } else {
          messages.push(formattedError);
        }
      }
    } catch (e) {
      console.warn('Unable to format error message', e);
    }
  }

  messages.forEach(msg => listenerApi.dispatch(enqueueErrorMessage(msg)));
};

[
  // exampleApi.endpoints.getPosts.matchPending,
  signInApi.endpoints.login.matchPending,
  sellerApi.endpoints.createSellerAccount.matchPending,
  sellerApi.endpoints.fetchSeller.matchPending,
  buyerApi.endpoints.createBuyerAccount.matchPending,
  buyerApi.endpoints.fetchBuyer.matchPending,
  userApi.endpoints.suspendAndUnspend.matchPending,
  userApi.endpoints.delete.matchPending,
  userApi.endpoints.getUser.matchPending,
  userApi.endpoints.updateBuyer.matchPending,
  userApi.endpoints.updatePassword.matchPending,
  userApi.endpoints.createAddress.matchPending,
  userApi.endpoints.updateAddress.matchPending,
  userApi.endpoints.deleteAddress.matchPending,
  paymentApi.endpoints.listCards.matchPending,
  paymentApi.endpoints.deleteCard.matchPending,
  paymentApi.endpoints.setDefaultCard.matchPending,
  storeApi.endpoints.createStore.matchPending,
  storeApi.endpoints.updateStore.matchPending,
  storeApi.endpoints.uploadImage.matchPending,
  storeApi.endpoints.deleteStoreImage.matchPending,
  storeApi.endpoints.fetchStoreTypes.matchPending,
  storeApi.endpoints.createStoreType.matchPending,
  storeApi.endpoints.updateStoreType.matchPending,
  storeApi.endpoints.deleteStoreType.matchPending,
  storeApi.endpoints.updateStoreStatus.matchPending,
  storeApi.endpoints.deleteStore.matchPending,
  storeApi.endpoints.updateBillingDetails.matchPending,
  settingApi.endpoints.getSetting.matchPending,
  settingApi.endpoints.saveSetting.matchPending,
  productCategoryApi.endpoints.createCategory.matchPending,
  productCategoryApi.endpoints.updateCategory.matchPending,
  productCategoryApi.endpoints.fetchCategory.matchPending,
  productCategoryApi.endpoints.deleteCategory.matchPending,
  productCategoryApi.endpoints.createProduct.matchPending,
  productCategoryApi.endpoints.fetchProduct.matchPending,
  productCategoryApi.endpoints.updateCategoryOrder.matchPending,
  productCategoryApi.endpoints.updateProductOrder.matchPending,
  productCategoryApi.endpoints.getProduct.matchPending,
  productCategoryApi.endpoints.updateProduct.matchPending,
  productCategoryApi.endpoints.editProduct.matchPending,
  productCategoryApi.endpoints.imageUpload.matchPending,
  productCategoryApi.endpoints.deleteImage.matchPending,
  productCategoryApi.endpoints.deleteProduct.matchPending,
  productOptionGroupApi.endpoints.fetchProductOptionGroup.matchPending,
  productOptionGroupApi.endpoints.createProductOptionGroup.matchPending,
  productOptionGroupApi.endpoints.updateProductOptionGroup.matchPending,
  productOptionGroupApi.endpoints.deleteProductOptionGroup.matchPending,
  productOptionGroupApi.endpoints.orderProductOptionGroup.matchPending,
  productOptionGroupApi.endpoints.createProductVariation.matchPending,
  productOptionGroupApi.endpoints.updateProductVariation.matchPending,
  productOptionGroupApi.endpoints.deleteProductVariation.matchPending,
  productOptionGroupApi.endpoints.createProductOption.matchPending,
  productOptionGroupApi.endpoints.updateProductOption.matchPending,
  productOptionGroupApi.endpoints.updateProductOptionStatus.matchPending,
  productOptionGroupApi.endpoints.deleteProductOption.matchPending,
  productCategoryApi.endpoints.importProduct.matchPending,
  scheduleApi.endpoints.updateScheduleStatus.matchPending,
  scheduleApi.endpoints.deleteScheduleItem.matchPending,
  scheduleApi.endpoints.updateScheduleItems.matchPending,
  scheduleApi.endpoints.fetchSchedules.matchPending,
  discountApi.endpoints.listDiscount.matchPending,
  discountApi.endpoints.createDiscount.matchPending,
  discountApi.endpoints.deleteDiscount.matchPending,
  discountApi.endpoints.getDiscount.matchPending,
  discountApi.endpoints.updateDiscount.matchPending,
  deliveryApi.endpoints.upsertDeliveryConfigTime.matchPending,
  deliveryApi.endpoints.fetchDeliveryZones.matchPending,
  deliveryApi.endpoints.createDeliveryZone.matchPending,
  deliveryApi.endpoints.updateDeliveryZone.matchPending,
  deliveryApi.endpoints.deleteDeliveryZone.matchPending,
  feeApi.endpoints.listFee.matchPending,
  feeApi.endpoints.createFee.matchPending,
  feeApi.endpoints.deleteFee.matchPending,
  feeApi.endpoints.updateFee.matchPending,
  orderApi.endpoints.orderDetails.matchPending,
  orderApi.endpoints.updateOrder.matchPending,
  statementApi.endpoints.fetchStatements.matchPending,
  statementApi.endpoints.fetchStatementOrder.matchPending,
  statementApi.endpoints.exportStatementCsv.matchPending,
  statementApi.endpoints.fetchStatementInvoice.matchPending,
  statementApi.endpoints.createInvoiceLineItem.matchPending,
  statementApi.endpoints.deleteInvoiceLineItem.matchPending,
  statementApi.endpoints.sendStatementInvoice.matchPending,
  statementApi.endpoints.updateStatement.matchPending,
  reviewApi.endpoints.updateReview.matchPending,
  appApi.endpoints.fetchApps.matchPending,
  appApi.endpoints.fetchAppDetails.matchPending,
  appApi.endpoints.fetchAppBuilds.matchPending,
  appApi.endpoints.updateAppDetails.matchPending,
  appApi.endpoints.updateAppTheme.matchPending,
  appApi.endpoints.updateAppAsset.matchPending,
  appApi.endpoints.deleteAppAsset.matchPending,
  appApi.endpoints.createApp.matchPending,
  appApi.endpoints.fetchThirdPartyIntegration.matchPending,
  appApi.endpoints.updateAppThirdPartyIntegration.matchPending,
  appApi.endpoints.fetchAppKey.matchPending,
  appApi.endpoints.updateAppKey.matchPending,
  appApi.endpoints.createAppBuild.matchPending,
  appApi.endpoints.fetchAuthProvider.matchPending,
  appApi.endpoints.updateAuthProvider.matchPending,
  appApi.endpoints.retryAppBuild.matchPending,
].forEach(matcher => {
  const listenerMiddleware = createListenerMiddleware();
  listenerMiddleware.startListening({
    matcher,
    effect: showProgressEffect,
  });
  listenerMiddlewares.push(listenerMiddleware);
});

const fulfilledMatchers = [
  exampleApi.endpoints.getPosts.matchFulfilled,
  signInApi.endpoints.login.matchFulfilled,
  sellerApi.endpoints.createSellerAccount.matchFulfilled,
  sellerApi.endpoints.fetchSeller.matchFulfilled,
  buyerApi.endpoints.createBuyerAccount.matchFulfilled,
  buyerApi.endpoints.fetchBuyer.matchFulfilled,
  userApi.endpoints.suspendAndUnspend.matchFulfilled,
  userApi.endpoints.delete.matchFulfilled,
  userApi.endpoints.getUser.matchFulfilled,
  userApi.endpoints.updateBuyer.matchFulfilled,
  userApi.endpoints.updatePassword.matchFulfilled,
  userApi.endpoints.createAddress.matchFulfilled,
  userApi.endpoints.updateAddress.matchFulfilled,
  userApi.endpoints.deleteAddress.matchFulfilled,
  paymentApi.endpoints.listCards.matchFulfilled,
  paymentApi.endpoints.deleteCard.matchFulfilled,
  paymentApi.endpoints.setDefaultCard.matchFulfilled,
  storeApi.endpoints.createStore.matchFulfilled,
  storeApi.endpoints.updateStore.matchFulfilled,
  storeApi.endpoints.uploadImage.matchFulfilled,
  storeApi.endpoints.deleteStoreImage.matchFulfilled,
  storeApi.endpoints.fetchStoreTypes.matchFulfilled,
  storeApi.endpoints.createStoreType.matchFulfilled,
  storeApi.endpoints.updateStoreType.matchFulfilled,
  storeApi.endpoints.deleteStoreType.matchFulfilled,
  storeApi.endpoints.updateStoreStatus.matchFulfilled,
  storeApi.endpoints.deleteStore.matchFulfilled,
  storeApi.endpoints.updateBillingDetails.matchFulfilled,
  settingApi.endpoints.getSetting.matchFulfilled,
  settingApi.endpoints.saveSetting.matchFulfilled,
  productCategoryApi.endpoints.createCategory.matchFulfilled,
  productCategoryApi.endpoints.updateCategory.matchFulfilled,
  productCategoryApi.endpoints.fetchCategory.matchFulfilled,
  productCategoryApi.endpoints.deleteCategory.matchFulfilled,
  productCategoryApi.endpoints.createProduct.matchFulfilled,
  productCategoryApi.endpoints.fetchProduct.matchFulfilled,
  productCategoryApi.endpoints.updateCategoryOrder.matchFulfilled,
  productCategoryApi.endpoints.updateProductOrder.matchFulfilled,
  productCategoryApi.endpoints.getProduct.matchFulfilled,
  productCategoryApi.endpoints.updateProduct.matchFulfilled,
  productCategoryApi.endpoints.editProduct.matchFulfilled,
  productCategoryApi.endpoints.imageUpload.matchFulfilled,
  productCategoryApi.endpoints.deleteImage.matchFulfilled,
  productCategoryApi.endpoints.deleteProduct.matchFulfilled,
  productOptionGroupApi.endpoints.fetchProductOptionGroup.matchFulfilled,
  productOptionGroupApi.endpoints.createProductOptionGroup.matchFulfilled,
  productOptionGroupApi.endpoints.updateProductOptionGroup.matchFulfilled,
  productOptionGroupApi.endpoints.deleteProductOptionGroup.matchFulfilled,
  productOptionGroupApi.endpoints.orderProductOptionGroup.matchFulfilled,
  productOptionGroupApi.endpoints.createProductVariation.matchFulfilled,
  productOptionGroupApi.endpoints.updateProductVariation.matchFulfilled,
  productOptionGroupApi.endpoints.deleteProductVariation.matchFulfilled,
  productOptionGroupApi.endpoints.createProductOption.matchFulfilled,
  productOptionGroupApi.endpoints.updateProductOption.matchFulfilled,
  productOptionGroupApi.endpoints.updateProductOptionStatus.matchFulfilled,
  productOptionGroupApi.endpoints.deleteProductOption.matchFulfilled,
  productCategoryApi.endpoints.importProduct.matchFulfilled,
  scheduleApi.endpoints.updateScheduleStatus.matchFulfilled,
  scheduleApi.endpoints.deleteScheduleItem.matchFulfilled,
  scheduleApi.endpoints.updateScheduleItems.matchFulfilled,
  scheduleApi.endpoints.fetchSchedules.matchFulfilled,
  discountApi.endpoints.listDiscount.matchFulfilled,
  discountApi.endpoints.createDiscount.matchFulfilled,
  discountApi.endpoints.deleteDiscount.matchFulfilled,
  discountApi.endpoints.getDiscount.matchFulfilled,
  discountApi.endpoints.updateDiscount.matchFulfilled,
  deliveryApi.endpoints.upsertDeliveryConfigTime.matchFulfilled,
  deliveryApi.endpoints.fetchDeliveryZones.matchFulfilled,
  deliveryApi.endpoints.createDeliveryZone.matchFulfilled,
  deliveryApi.endpoints.updateDeliveryZone.matchFulfilled,
  deliveryApi.endpoints.deleteDeliveryZone.matchFulfilled,
  feeApi.endpoints.listFee.matchFulfilled,
  feeApi.endpoints.createFee.matchFulfilled,
  feeApi.endpoints.deleteFee.matchFulfilled,
  feeApi.endpoints.updateFee.matchFulfilled,
  orderApi.endpoints.orderDetails.matchFulfilled,
  orderApi.endpoints.updateOrder.matchFulfilled,
  statementApi.endpoints.fetchStatements.matchFulfilled,
  statementApi.endpoints.fetchStatementOrder.matchFulfilled,
  statementApi.endpoints.exportStatementCsv.matchFulfilled,
  statementApi.endpoints.fetchStatementInvoice.matchFulfilled,
  statementApi.endpoints.createInvoiceLineItem.matchFulfilled,
  statementApi.endpoints.deleteInvoiceLineItem.matchFulfilled,
  statementApi.endpoints.sendStatementInvoice.matchFulfilled,
  statementApi.endpoints.updateStatement.matchFulfilled,
  reviewApi.endpoints.updateReview.matchFulfilled,
  appApi.endpoints.fetchApps.matchFulfilled,
  appApi.endpoints.fetchAppDetails.matchFulfilled,
  appApi.endpoints.fetchAppBuilds.matchFulfilled,
  appApi.endpoints.updateAppDetails.matchFulfilled,
  appApi.endpoints.updateAppTheme.matchFulfilled,
  appApi.endpoints.updateAppAsset.matchFulfilled,
  appApi.endpoints.deleteAppAsset.matchFulfilled,
  appApi.endpoints.createApp.matchFulfilled,
  appApi.endpoints.fetchThirdPartyIntegration.matchFulfilled,
  appApi.endpoints.updateAppThirdPartyIntegration.matchFulfilled,
  appApi.endpoints.fetchAppKey.matchFulfilled,
  appApi.endpoints.updateAppKey.matchFulfilled,
  appApi.endpoints.createAppBuild.matchFulfilled,
  appApi.endpoints.fetchAuthProvider.matchFulfilled,
  appApi.endpoints.updateAuthProvider.matchFulfilled,
  appApi.endpoints.retryAppBuild.matchFulfilled,
];

const rejectedMatchers = [
  exampleApi.endpoints.getPosts.matchRejected,
  signInApi.endpoints.login.matchRejected,
  sellerApi.endpoints.createSellerAccount.matchRejected,
  sellerApi.endpoints.fetchSeller.matchRejected,
  buyerApi.endpoints.createBuyerAccount.matchRejected,
  buyerApi.endpoints.fetchBuyer.matchRejected,
  userApi.endpoints.suspendAndUnspend.matchRejected,
  userApi.endpoints.delete.matchRejected,
  userApi.endpoints.getUser.matchRejected,
  userApi.endpoints.updateBuyer.matchRejected,
  userApi.endpoints.updatePassword.matchRejected,
  userApi.endpoints.createAddress.matchRejected,
  userApi.endpoints.updateAddress.matchRejected,
  userApi.endpoints.deleteAddress.matchRejected,
  paymentApi.endpoints.listCards.matchRejected,
  paymentApi.endpoints.deleteCard.matchRejected,
  paymentApi.endpoints.setDefaultCard.matchRejected,
  storeApi.endpoints.createStore.matchRejected,
  storeApi.endpoints.updateStore.matchRejected,
  storeApi.endpoints.uploadImage.matchRejected,
  storeApi.endpoints.deleteStoreImage.matchRejected,
  storeApi.endpoints.fetchStoreTypes.matchRejected,
  storeApi.endpoints.createStoreType.matchRejected,
  storeApi.endpoints.updateStoreType.matchRejected,
  storeApi.endpoints.deleteStoreType.matchRejected,
  storeApi.endpoints.updateStoreStatus.matchRejected,
  storeApi.endpoints.updateBillingDetails.matchRejected,
  storeApi.endpoints.deleteStore.matchRejected,
  settingApi.endpoints.getSetting.matchRejected,
  settingApi.endpoints.saveSetting.matchRejected,
  productCategoryApi.endpoints.createCategory.matchRejected,
  productCategoryApi.endpoints.updateCategory.matchRejected,
  productCategoryApi.endpoints.fetchCategory.matchRejected,
  productCategoryApi.endpoints.deleteCategory.matchRejected,
  productCategoryApi.endpoints.createProduct.matchRejected,
  productCategoryApi.endpoints.fetchProduct.matchRejected,
  productCategoryApi.endpoints.updateCategoryOrder.matchRejected,
  productCategoryApi.endpoints.updateProductOrder.matchRejected,
  productCategoryApi.endpoints.getProduct.matchRejected,
  productCategoryApi.endpoints.updateProduct.matchRejected,
  productCategoryApi.endpoints.editProduct.matchRejected,
  productCategoryApi.endpoints.imageUpload.matchRejected,
  productCategoryApi.endpoints.deleteImage.matchRejected,
  productCategoryApi.endpoints.deleteProduct.matchRejected,
  productOptionGroupApi.endpoints.fetchProductOptionGroup.matchRejected,
  productOptionGroupApi.endpoints.createProductOptionGroup.matchRejected,
  productOptionGroupApi.endpoints.updateProductOptionGroup.matchRejected,
  productOptionGroupApi.endpoints.deleteProductOptionGroup.matchRejected,
  productOptionGroupApi.endpoints.orderProductOptionGroup.matchRejected,
  productOptionGroupApi.endpoints.createProductVariation.matchRejected,
  productOptionGroupApi.endpoints.updateProductVariation.matchRejected,
  productOptionGroupApi.endpoints.deleteProductVariation.matchRejected,
  productOptionGroupApi.endpoints.createProductOption.matchRejected,
  productOptionGroupApi.endpoints.updateProductOption.matchRejected,
  productOptionGroupApi.endpoints.updateProductOptionStatus.matchRejected,
  productOptionGroupApi.endpoints.deleteProductOption.matchRejected,
  productCategoryApi.endpoints.importProduct.matchRejected,
  scheduleApi.endpoints.updateScheduleStatus.matchRejected,
  scheduleApi.endpoints.deleteScheduleItem.matchRejected,
  scheduleApi.endpoints.updateScheduleItems.matchRejected,
  scheduleApi.endpoints.fetchSchedules.matchRejected,
  discountApi.endpoints.listDiscount.matchRejected,
  discountApi.endpoints.createDiscount.matchRejected,
  discountApi.endpoints.deleteDiscount.matchRejected,
  discountApi.endpoints.getDiscount.matchRejected,
  discountApi.endpoints.updateDiscount.matchRejected,
  deliveryApi.endpoints.upsertDeliveryConfigTime.matchRejected,
  deliveryApi.endpoints.fetchDeliveryZones.matchRejected,
  deliveryApi.endpoints.createDeliveryZone.matchRejected,
  deliveryApi.endpoints.updateDeliveryZone.matchRejected,
  deliveryApi.endpoints.deleteDeliveryZone.matchRejected,
  feeApi.endpoints.listFee.matchRejected,
  feeApi.endpoints.createFee.matchRejected,
  feeApi.endpoints.deleteFee.matchRejected,
  feeApi.endpoints.updateFee.matchRejected,
  orderApi.endpoints.orderDetails.matchRejected,
  orderApi.endpoints.updateOrder.matchRejected,
  statementApi.endpoints.fetchStatements.matchRejected,
  statementApi.endpoints.fetchStatementOrder.matchRejected,
  statementApi.endpoints.exportStatementCsv.matchRejected,
  statementApi.endpoints.fetchStatementInvoice.matchRejected,
  statementApi.endpoints.createInvoiceLineItem.matchRejected,
  statementApi.endpoints.deleteInvoiceLineItem.matchRejected,
  statementApi.endpoints.sendStatementInvoice.matchRejected,
  statementApi.endpoints.updateStatement.matchRejected,
  reviewApi.endpoints.updateReview.matchRejected,
  appApi.endpoints.fetchApps.matchRejected,
  appApi.endpoints.fetchAppDetails.matchRejected,
  appApi.endpoints.fetchAppBuilds.matchRejected,
  appApi.endpoints.updateAppDetails.matchRejected,
  appApi.endpoints.updateAppTheme.matchRejected,
  appApi.endpoints.updateAppAsset.matchRejected,
  appApi.endpoints.deleteAppAsset.matchRejected,
  appApi.endpoints.createApp.matchRejected,
  appApi.endpoints.fetchThirdPartyIntegration.matchRejected,
  appApi.endpoints.updateAppThirdPartyIntegration.matchRejected,
  appApi.endpoints.fetchAppKey.matchRejected,
  appApi.endpoints.updateAppKey.matchRejected,
  appApi.endpoints.createAppBuild.matchRejected,
  appApi.endpoints.fetchAuthProvider.matchRejected,
  appApi.endpoints.updateAuthProvider.matchRejected,
  appApi.endpoints.retryAppBuild.matchRejected,
];

[...fulfilledMatchers, ...rejectedMatchers].forEach(matcher => {
  const listenerMiddleware = createListenerMiddleware();
  listenerMiddleware.startListening({
    matcher,
    effect: hideProgressEffect,
  });
  listenerMiddlewares.push(listenerMiddleware);
});

fulfilledMatchers.forEach(matcher => {
  const listenerMiddleware = createListenerMiddleware();
  listenerMiddleware.startListening({
    matcher,
    effect: showSuccessMessageEffect,
  });
  listenerMiddlewares.push(listenerMiddleware);
});

rejectedMatchers.forEach(matcher => {
  const listenerMiddleware = createListenerMiddleware();
  listenerMiddleware.startListening({
    matcher,
    effect: showErrorMessageEffect,
  });
  listenerMiddlewares.push(listenerMiddleware);
});

export default listenerMiddlewares;
