import {useIntl} from 'react-intl';
import {useEffect, useMemo, useState} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {
  formatErrorMessage,
  isApiValidationError,
  mapErrorToFormFields,
  phoneRegExp,
} from '../utils/functions';
import {ZipCode} from 'use-places-autocomplete';
import {OptionProps} from '../components/Form/Inputs/AutocompleteMultiple';
import {StoreParams, useCreateStoreMutation} from '../services/storeApi';
import {SearchAddressResult} from '../types/SearchAddressResult';
import {CustomError} from '../types/CustomError';

type FormData = {
  name: string;
  email: string;
  phone: string;
  city: string;
  postCode: ZipCode;
  description?: string;
  sellers: OptionProps[];
};

const useCreateStore = () => {
  // translations
  const intl = useIntl();
  const [address, setAddress] = useState<SearchAddressResult>({
    address: '',
    city: '',
    postCode: '',
    latitude: 0,
    longitude: 0,
    country: '',
  });

  // handle validation for address
  const [addressError, setAddressError] = useState<string | null>(null);
  const [enteredValue, setEnteredValue] = useState<string>('');

  // update address state when user select an address suggestion
  const updateAddressValue = (values: SearchAddressResult) => {
    setAddress(values);
    setAddressError(null);
  };

  // set error message for address
  const updateAddressError = (message: string | null) =>
    setAddressError(message);

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .email(intl.formatMessage({id: 'validation.error.invalid_email'})),
        name: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        phone: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .matches(
            phoneRegExp,
            intl.formatMessage({id: 'validation.error.invalid_phone'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    resetField,
    formState: {errors, submitCount},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  // validate phone number
  const validatePhoneNumber = (isValid: boolean) => {
    if (isValid) {
      clearErrors('phone');
    } else {
      setError('phone', {
        type: 'required',
        message: intl.formatMessage({id: 'validation.error.invalid_phone'}),
      });
    }
  };

  // disable submit button if theres error
  const disableButton = (): boolean => {
    let disable = false;
    if (Object.keys(errors).length > 0 || addressError) {
      disable = true;
    }
    return disable;
  };

  // auto fill city and postcode
  useEffect(() => {
    if (address.city || address.postCode) {
      setValue('city', address.city);
      setValue('postCode', address.postCode);
    }
  }, [address.city, address.postCode]);

  const [
    createStoreAccount,
    {isSuccess, isLoading, data, error: createStoreError},
  ] = useCreateStoreMutation();

  // submit request
  const onSubmit = async (formData: FormData) => {
    if (enteredValue.length > 0 && address.address.length === 0) {
      updateAddressError(
        intl.formatMessage({id: 'validation.error.required_city_postcode'})
      );
      return;
    }
    if (!enteredValue || address.address.length === 0) {
      updateAddressError(
        intl.formatMessage({id: 'validation.error.required_field'})
      );
      return;
    }
    let sellerIds: (string | number)[] = [];

    if (formData.sellers && formData.sellers.length > 0) {
      sellerIds = formData.sellers.map(seller => seller.value);
      // @ts-ignore
      delete formData.sellers;
    }

    const data: StoreParams = {
      body: {
        ...formData,
        sellers: sellerIds,
        address,
        paymentMethod: ['cash', 'online'],
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.store_created_success_message',
        });
      },
    };
    createStoreAccount(data);
  };

  // Format api validation errors to input field
  useEffect(() => {
    if (createStoreError) {
      const typedUpdateError = createStoreError as CustomError;
      if (isApiValidationError(typedUpdateError)) {
        mapErrorToFormFields(typedUpdateError.data.message, setError);
      }
    }
  }, [createStoreError]);

  // clear form fields after successful creation of store
  useEffect(() => {
    if (isSuccess && data) {
      setAddress({
        address: '',
        city: '',
        postCode: '',
        latitude: 0,
        longitude: 0,
        country: '',
      });
      resetField('sellers');
      reset();
    }
  }, [isSuccess, data]);

  return {
    onSubmit,
    isSuccess,
    isLoading,
    data,
    control,
    handleSubmit,
    submitCount,
    errors,
    validatePhoneNumber,
    disableButton,
    updateAddressValue,
    updateAddressError,
    addressError,
    address,
    setEnteredValue,
  };
};

export default useCreateStore;
