import {TextField, Typography} from '@material-ui/core';
import cx from 'clsx';
import {useEffect} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import {FormGroup} from 'reactstrap';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
  getDetails,
} from 'use-places-autocomplete';
import {SearchAddressResult} from '../../types/SearchAddressResult';
import useTextFieldStyles from '../Common/useTextFieldStyles';
import withGmaps from '../Gmaps/withGmaps';

type AutocompleteAddressProps = {
  name: string;
  label: string;
  setAddress: (values: SearchAddressResult) => void;
  updateAddressError: (message: string | null) => void;
  addressError: string | null;
  address?: SearchAddressResult;
  setEnteredValue: React.Dispatch<React.SetStateAction<string>>;
  isSuccess?: boolean;
  className?: string;
  required?: boolean;
  canEdit?: boolean;
};

const AddressAutoComplete = ({
  name,
  label,
  setAddress,
  updateAddressError,
  addressError,
  setEnteredValue,
  isSuccess,
  address,
  className = 'dropdown custom-dropdown autocomplete-field flex-fill mb-3 mb-lg-fg',
  required,
  canEdit = false,
}: AutocompleteAddressProps) => {
  // get places autocomplete data from the hook
  const {
    ready,
    value,
    setValue,
    suggestions: {status, data},
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'initMap',
    debounce: 300,
    requestOptions: {
      componentRestrictions: {
        country: 'uk',
      },
    },
  });

  // update the search adress input
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setEnteredValue(e.target.value);
    updateAddressError(null);
  };

  useEffect(() => {
    if (isSuccess) {
      setValue('');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (address) {
      setEnteredValue(address.address);
      setValue(address.address, false);
    }
  }, [address]);

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  // @ts-ignore
  const extractCity = addressComponts => {
    let city = '';
    // @ts-ignore
    addressComponts.forEach(place => {
      const types = place.types;
      const value = place.long_name;
      if (types.includes('locality')) {
        city = value;
      } else if (types.includes('postal_town')) {
        city = value;
      }
    });
    return city;
  };

  // @ts-ignore
  const extractCountry = addressComponts => {
    let country = '';
    // @ts-ignore
    addressComponts.forEach(place => {
      const types = place.types;
      const value = place.long_name;
      if (types.includes('country')) {
        country = value;
      }
    });
    return country;
  };

  // when the use selects any of the suggestion
  const handleSelect = async (suggestion: any) => {
    const {description, place_id} = suggestion;
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    // clear the suggestions
    clearSuggestions();
    // get the address full details
    const fullAddressDetails: any = await getDetails({placeId: place_id});

    // Get latitude and longitude via utility functions
    const results = await getGeocode({address: description});

    // Get latitude and longitude from the result of Geocode
    const {lat, lng} = getLatLng(results[0]);
    // Get the zip code
    const zipcode = getZipCode(results[0], false);
    const addressCompont = fullAddressDetails.address_components;
    const city = extractCity(addressCompont);

    // update the address input values
    const address: SearchAddressResult = {
      address: description,
      latitude: lat,
      longitude: lng,
      city,
      postCode: zipcode,
      country: extractCountry(addressCompont),
    };
    setAddress(address);
  };

  // List address suggestions
  const renderSuggestions = () => (
    <ul className="list-unstyled mb-0">
      {data.map(suggestion => {
        const {
          place_id,
          structured_formatting: {main_text, secondary_text},
        } = suggestion;

        return (
          <li
            key={place_id}
            className="dropdown-item"
            onClick={() => handleSelect(suggestion)}
          >
            <p className="text-wrap">
              <strong>{main_text}</strong> {secondary_text}
            </p>
          </li>
        );
      })}
    </ul>
  );

  const textFieldClasses = useTextFieldStyles({});

  return (
    <div ref={ref} className={className}>
      <FormGroup className={cx('mb-0', {'form-group-invalid': true})}>
        {/* Floating label */}
        <span className="small-label pb-2">
          {label} {required ? '*' : ''}
        </span>
        <TextField
          fullWidth
          color="secondary"
          variant="outlined"
          classes={textFieldClasses}
          name={name}
          value={value}
          onChange={handleInput}
          disabled={canEdit || !ready}
          error={!!addressError}
          autoComplete="off"
          required={required}
        />

        {/* Error */}
        {addressError && (
          <Typography variant="subtitle2" className="text-secondary mt-vtl-3">
            {addressError}
          </Typography>
        )}
      </FormGroup>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      <div className={cx('dropdown-menu', {'d-block': status === 'OK'})}>
        {renderSuggestions()}
      </div>
    </div>
  );
};

export default withGmaps(AddressAutoComplete);
