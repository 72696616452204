import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Stack} from 'react-bootstrap';
import {Container, Row, Col} from 'react-bootstrap';
import EditLabel from './OptionsTab/EditLabel';
import Options from './OptionsTab/Options';
import AddPopup from '../../Widgets/AddPopup';
import GroupForm from './OptionsTab/Form/GroupForm';
import {ProductOptionGroup} from '../../../types/ProductOptionGroup';
import useProductOptionGroup from '../../../hooks/useProductOptionGroup';
import ConfirmationPopup from '../../Shared/ConfirmationPopup';
import VariationList from './OptionsTab/VariationList';
import useStyles from '../../Common/useNewLineStyles';
import cx from 'clsx';

/**
 * Product options
 */

type OptionHeaderProps = {
  selectedGroup?: ProductOptionGroup;
  onEdit: () => void;
  onDelete: () => void;
};

const OptionHeader = ({selectedGroup, onEdit, onDelete}: OptionHeaderProps) => {
  const classes = useStyles();
  if (!selectedGroup) {
    return null;
  }
  return (
    <div className="pb-2 pb-lg-vtl-25">
      <EditLabel onEdit={onEdit} onDelete={onDelete} className="pb-2">
        <Typography variant="h4" className="font-weight-bolder">
          {selectedGroup?.name}
        </Typography>
      </EditLabel>
      <Typography className={cx('text-muted2', {[classes.item]: true})}>
        {selectedGroup?.description}
      </Typography>
    </div>
  );
};

const OptionsTab = () => {
  const intl = useIntl();
  const [active, setActive] = useState<ProductOptionGroup | null>();
  // Add group popup state
  const [addGroupPopup, setAddGroupPopup] = useState(false);
  // Edit group popup state
  const [editGroupPopup, setEditGroupPopup] = useState(false);
  // IsEdit state
  const [isEdit, setIsEdit] = useState(false);
  // Delete modal state
  const [openModal, setOpenModal] = useState(false);

  // update selected group
  const onChangeActive = (group: ProductOptionGroup) => {
    setActive(group);
  };

  const {
    optionGroups,
    isSuccess,
    control,
    handleSubmit,
    createSuccess,
    submit,
    reset,
    updateSuccess,
    deleteProductOptionGroup,
    deleteSuccess,
    orderProductOptionGroup,
    createdLoading,
    updatedLoading,
  } = useProductOptionGroup({
    selectedgroup: active,
    isEditMode: isEdit,
    onUpdate: onChangeActive,
  });

  // init option groups
  useEffect(() => {
    if (optionGroups && isSuccess) {
      if (!active) {
        setActive(optionGroups[0]);
      } else {
        setActive(optionGroups.find(group => group.id === active.id));
      }
    }
  }, [optionGroups, isSuccess]);

  //close modal
  useEffect(() => {
    if (createSuccess) {
      reset();
      setAddGroupPopup(false);
    }
    if (updateSuccess) {
      reset();
      setEditGroupPopup(false);
      setIsEdit(false);
    }
  }, [createSuccess, updateSuccess]);

  // handle delete success
  useEffect(() => {
    if (deleteSuccess) {
      setActive(optionGroups[0]);
    }
  }, [deleteSuccess]);

  // Open Edit Option group actions
  const onEditOption = () => {
    setIsEdit(true);
    setAddGroupPopup(false);
    setEditGroupPopup(true);
  };

  // Open Add Option group popup
  const onAddClick = () => {
    setIsEdit(false);
    setEditGroupPopup(false);
    setAddGroupPopup(true);
  };

  // Delete Option group
  const deleteOptionGroup = () => {
    setOpenModal(false);
    deleteProductOptionGroup(active?.id as number);
  };

  const closeAllGroupOptionPopup = () => {
    setEditGroupPopup(false);
    setAddGroupPopup(false);
  };

  const orderOptionGroup = (
    draggableId: string,
    sourceIndex: number,
    destinationIndex: number
  ) => {
    orderProductOptionGroup(draggableId, sourceIndex, destinationIndex);
  };

  return (
    <>
      {/* Delete category confirmation */}
      <ConfirmationPopup
        opened={openModal}
        handleClose={() => setOpenModal(false)}
        onOk={deleteOptionGroup}
        description={intl.formatMessage({
          id: 'messages.delete_message',
        })}
      />
      {/* Add group */}
      <AddPopup
        title={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.add_group',
        })}
        opened={addGroupPopup}
        onClose={() => setAddGroupPopup(false)}
        activeTab={createdLoading ? undefined : 'add-group'}
      >
        <GroupForm
          form="add-group-form"
          control={control}
          handleSubmit={handleSubmit}
          submit={submit}
        />
      </AddPopup>
      {/* Edit group */}
      <AddPopup
        edit
        title={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.edit_group',
        })}
        opened={editGroupPopup}
        onClose={() => setEditGroupPopup(false)}
        activeTab={updatedLoading ? undefined : 'edit-group'}
      >
        <GroupForm
          form="edit-group-form"
          control={control}
          handleSubmit={handleSubmit}
          submit={submit}
        />
      </AddPopup>

      <Container fluid className="options-menu">
        <div className="content-block">
          <Row className="g-3">
            <Col lg={4} xxl={3}>
              <Options
                optionGroups={optionGroups}
                selectedGroup={active!}
                onSelect={onChangeActive}
                onAddClick={onAddClick}
                orderOptionGroup={orderOptionGroup}
              />
            </Col>
            <Col lg={8} xxl>
              {active && (
                <OptionHeader
                  selectedGroup={active}
                  onEdit={onEditOption}
                  onDelete={() => {
                    closeAllGroupOptionPopup();
                    setOpenModal(true);
                  }}
                />
              )}
              <Stack className="gap-2 gap-lg-3">
                {active && (
                  <VariationList
                    activeGroup={active}
                    variations={active?.variations}
                    closeGroupOptionsPopUps={closeAllGroupOptionPopup}
                    isAddGroupPopUp={addGroupPopup}
                    isEditGroupPopUp={editGroupPopup}
                    isDeleteGroupModel={openModal}
                  />
                )}
              </Stack>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default OptionsTab;
