import {FormattedMessage, useIntl} from 'react-intl';
import {useMediaQuery} from 'react-responsive';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {DevTool} from '@hookform/devtools';
import useTitle from '../../hooks/useTitle';
import {Card, CardBody, Container, Row, Col, Form} from 'reactstrap';
import {Image} from 'react-bootstrap';
import {Typography} from '@material-ui/core';
import {BREAKPOINTS} from '../../constants/appConstants';
import Button from '../Common/Button';
import InputController from '../Form/HookForm/InputController';
import CheckboxController from '../Form/HookForm/CheckboxController';
import useLogin from '../../hooks/useLogin';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from '../../store/slices/authSlice';
import {useNavigate} from 'react-router-dom';
import * as urls from '../../constants/urls';

/**
 * Login page
 */
const Login = () => {
  // Translations
  const intl = useIntl();
  // get user
  const {isAuthenticated, user} = useSelector(selectUserAndToken);

  const {control, handleSubmit, onSubmit, submitCount, errors} = useLogin();
  // Page title
  const {intlTitle} = useTitle();
  const navigate = useNavigate();

  // check if user is loggedIn
  useEffect(() => {
    if (user || isAuthenticated) {
      navigate(urls.ORDERS_LIST_PATH);
    }
  }, [user, isAuthenticated]);

  const isMobile = useMediaQuery({maxWidth: BREAKPOINTS.LG});

  return (
    <HelmetProvider>
      <Helmet>
        <title>{intlTitle('login.title')}</title>
      </Helmet>
      <Container
        fluid
        className="d-flex align-items-center justify-content-center m-auto"
      >
        <Card className="vtl-login-card">
          <CardBody className="p-0">
            <Row className="gx-0 gy-5 flex-fill">
              <Col lg>
                <div className="pt-4 pt-lg-vtl-70 px-3 px-lg-vtl-50">
                  <div className="text-center pb-3 pb-lg-vtl-50">
                    <Image src="/images/MealMap.svg" />
                  </div>
                  <Typography variant="h3" className="text-center pb-fg">
                    <FormattedMessage id="login.title" />
                  </Typography>
                  <Form
                    validated={
                      (submitCount > 0 && Object.keys(errors).length === 0) ||
                      undefined
                    }
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {/* Email address */}
                    <InputController
                      name="username"
                      control={control}
                      label={intl.formatMessage({
                        id: 'login.email_address',
                      })}
                    />
                    {/* Password */}
                    <InputController
                      name="password"
                      control={control}
                      label={intl.formatMessage({
                        id: 'login.password',
                      })}
                      isPassword
                      formGroupClass="mb-1"
                    />
                    {/* Keep me signed in */}
                    <CheckboxController
                      name="rememberMe"
                      control={control}
                      label={
                        <Typography className="text-dark">
                          <FormattedMessage id="login.keepMeSigned" />
                        </Typography>
                      }
                      size="default"
                    />
                    <div className="mt-3 pt-lg-3">
                      <Button
                        type="submit"
                        className="w-100 justify-content-center"
                        primary
                        title={intl.formatMessage({id: 'login.signIn'})}
                      />
                    </div>
                  </Form>
                </div>
              </Col>
              <Col lg="auto">
                <Image
                  width={isMobile ? '100%' : 470}
                  height={isMobile ? 250 : 'inherit'}
                  fluid={!isMobile}
                  src="/images/ambitious-creative-co-rick-barrett-PN_7dCww6Fw-unsplash 1.png"
                  style={{objectFit: 'cover'}}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <DevTool control={control} />
      </Container>
    </HelmetProvider>
  );
};

export default Login;
