import React, {useState} from 'react';
import {Container, Row, Col} from 'reactstrap';
import FinanceDetailsItem from '../Items/FinanceDetailsItem';
import {
  useDeleteCardMutation,
  useListCardsQuery,
  useSetDefaultCardMutation,
} from '../../../services/paymentApi';
import ConfirmationPopup from '../../Shared/ConfirmationPopup';
import {useIntl} from 'react-intl';
/**
 * Buyer details - Finance tab
 */
type FinanceDetailsTabProps = {
  id?: number | string;
};
const FinanceDetailsTab = ({id}: FinanceDetailsTabProps) => {
  const intl = useIntl();
  const {data} = useListCardsQuery({buyerId: id});
  const [deleteConfirmPopup, setDeleteConfirmPopup] = useState<{
    open: boolean;
    cardId?: string;
  }>({
    open: false,
  });
  const [deleteCardMutation] = useDeleteCardMutation();
  const [setDefaultCardMutation] = useSetDefaultCardMutation();

  const onSetAsDefault = (cardId: string) => {
    setDefaultCardMutation({
      showProgressDialog: true,
      buyerId: id,
      body: {cardId},
      formatSuccessMessage: () =>
        intl.formatMessage({
          id: 'messages.buyer_default_card_updated_successfully',
        }),
    });
  };

  return (
    <Container>
      <ConfirmationPopup
        opened={deleteConfirmPopup.open}
        handleClose={() => setDeleteConfirmPopup({open: false})}
        onOk={() => {
          deleteCardMutation({
            showProgressDialog: true,
            buyerId: id,
            cardId: deleteConfirmPopup.cardId ?? '',
            formatSuccessMessage: () =>
              intl.formatMessage({
                id: 'messages.buyer_card_deleted_successfully',
              }),
          });
          setDeleteConfirmPopup({open: false});
        }}
        description={intl.formatMessage({
          id: 'dashboard.users.messages.confirm_message',
        })}
      />
      <div className="content-block cards-list">
        <Row className="g-3">
          {data?.cards.map(card => (
            <Col lg={4} key={card.id}>
              <FinanceDetailsItem
                onSetAsDefault={() => onSetAsDefault(card.id)}
                onDelete={() =>
                  setDeleteConfirmPopup({open: true, cardId: card.id})
                }
                card={card}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default FinanceDetailsTab;
