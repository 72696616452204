import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import AutocompleteSingle, {
  AutocompleteSingleProps,
} from '../Inputs/AutocompleteSingle';

export type AutocompleteSingleControllerProps<T extends FieldValues> = Omit<
  AutocompleteSingleProps,
  'name' | 'control' | 'value' | 'onChange'
> & {
  name: Path<T>;
  control: Control<T>;
  required?: boolean;
};

const AutocompleteSingleController = <T extends FieldValues>({
  name,
  control,
  options,
  required,
  ...otherProps
}: AutocompleteSingleControllerProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({field: {onChange, value}, fieldState: {error}}) => (
      <AutocompleteSingle
        {...otherProps}
        name={name}
        values={value}
        onChange={onChange}
        options={options}
        error={error?.message || ''}
        required={required}
      />
    )}
  />
);

export default AutocompleteSingleController;
