import {useEffect, useState} from 'react';
import {Collapse, Typography} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import cx from 'clsx';
import {FormattedMessage, useIntl} from 'react-intl';
import {Form} from 'reactstrap';
import {Container, Row, Col} from 'react-bootstrap';
import LinearProgress from '../../../Common/LinearProgress';
import Alert from '../../../Common/Alert';
import DropzoneController from '../../../Form/HookForm/DropzoneController';
import Modal from '../../../Widgets/Modal';
import Button from '../../../Common/Button';
import IconButton from '../../../Common/IconButton';
import {ReactComponent as CloseIcon} from '../../../../assets/images/close.svg';
import {ProductCategory} from '../../../../types/ProductCategory';
import {
  formatErrorMessage,
  validSpreadsheetOptions,
} from '../../../../utils/functions';
import {useImportProductMutation} from '../../../../services/productCategoryApi';

/**
 * Products import actions
 * It contains product import features
 */

type ImportFormValues = {
  file: string;
};

export type ProductsImportProps = {
  importFile: boolean;
  onClose: () => void;
  category?: ProductCategory | null;
  storeId?: string | number;
};

const ProductsImport = ({
  importFile,
  onClose,
  category,
  storeId,
}: ProductsImportProps) => {
  const intl = useIntl();
  const [importProduct, {isSuccess, data}] = useImportProductMutation();

  const {
    control,

    reset,
    setError,
    clearErrors,
    watch,
    formState: {errors},
  } = useForm<ImportFormValues>();

  const values = watch();
  // Choose file popup state
  const [open, setOpen] = useState(false);
  // Import started
  const [startImport, setStartImport] = useState(false);
  // import progress
  const [progress, setProgress] = useState(0);
  // Import ended
  const [endImport, setEndImport] = useState(false);
  // Show errors when import is ended
  const [showErrors, setShowErrors] = useState(false);

  const [importRequest, setImportRequest] = useState<any>();

  // Hide end import result whenever category changes
  useEffect(() => {
    setEndImport(false);
  }, [category]);

  useEffect(() => {
    setOpen(importFile);
  }, [importFile]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress: number) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [startImport]);

  // Import is stated
  const onImportStart = async () => {
    if (errors.file) {
      return;
    }
    const formData = new FormData();
    formData.append('storeId', String(storeId));
    formData.append('file', values.file);
    if (category) {
      formData.append('categoryId', String(category?.id));
    }
    onClose();
    setStartImport(true);
    setEndImport(false);

    const request = importProduct({
      body: formData,
      formatErrorMessage: error => formatErrorMessage(error, intl),
    });
    setImportRequest(request);
    await request;

    // switch to ended state
    setStartImport(false);
    setEndImport(true);
  };

  const onImportCancel = () => {
    importRequest.abort();
    setStartImport(false);
  };

  return (
    <>
      {/* Selector file modal */}
      <Modal
        opened={open}
        title={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.import_products.import_file_title',
        })}
        saveTitle={intl.formatMessage({id: 'actions.import'})}
        handleClose={() => {
          setOpen(false);
          onClose();
        }}
        onOk={onImportStart}
        saveButtonDisabled={!values.file}
      >
        <Form>
          <DropzoneController
            name="file"
            control={control}
            format="spreadsheet"
            className="fill-background mb-1"
            canEdit={false}
            validFileOptions={validSpreadsheetOptions}
            setError={setError}
            uploadSuccess={isSuccess}
            deleteImage={() => reset({file: ''})}
            clearError={clearErrors}
            errors={errors}
            required
          />
        </Form>
      </Modal>
      {/* Import was started */}
      {startImport && (
        <Alert withBackdrop>
          <Container fluid className="pt-3 mt-1 px-3">
            <Row className="align-items-center gy-2">
              <Col lg>
                <Typography variant="subtitle2" className="ms-lg-4">
                  <FormattedMessage id="dashboard.store_details.product_categories_tab.import_products.importing_products" />
                </Typography>
              </Col>
              <Col lg="auto">
                <Button
                  primary
                  mobileFullWidth
                  title={intl.formatMessage({id: 'actions.cancel'})}
                  className="ms-lg-auto"
                  onClick={() => onImportCancel()}
                />
              </Col>
            </Row>
          </Container>
          <LinearProgress
            value={progress}
            variant="determinate"
            className="mt-3"
          />
        </Alert>
      )}
      {/* Import ended */}
      {endImport && (
        <Alert>
          <Container fluid className="py-3 px-vtl-15 px-lg-vtl-50">
            <Row>
              <Col xs>
                <Typography variant="subtitle2">
                  <span>
                    <b>{data?.createdCount || 0}</b>{' '}
                    <FormattedMessage id="dashboard.store_details.product_categories_tab.import_products.products_successfully_imported" />
                  </span>
                  <span>
                    {' '}
                    <b>{data?.updatedCount || 0}</b>{' '}
                    <FormattedMessage id="dashboard.store_details.product_categories_tab.import_products.products_successfully_updated" />
                  </span>
                </Typography>

                <div className="d-flex align-items-center">
                  <Typography variant="subtitle2">
                    <b
                      className={cx({
                        'text-red': data?.errors && data?.errors.length > 0,
                      })}
                    >
                      {data?.errors.length || 0}
                    </b>{' '}
                    <span>
                      <FormattedMessage id="dashboard.store_details.product_categories_tab.import_products.products_cant_be_updated" />
                    </span>
                  </Typography>
                  {data?.errors && data?.errors.length > 0 && (
                    <div className="d-inline-flex ms-1" style={{marginTop: -1}}>
                      <Button
                        primary
                        link
                        small
                        noBold
                        title={intl.formatMessage({
                          id: showErrors ? 'actions.hide' : 'actions.show',
                        })}
                        onClick={() => setShowErrors(!showErrors)}
                      />
                    </div>
                  )}
                </div>
                <Collapse in={showErrors}>
                  <div id="collapsing-errors" className="mt-1">
                    {data?.errors.flat()?.map((e, index) => (
                      <Typography
                        variant="subtitle2"
                        key={index}
                        className="text-muted2"
                      >
                        {e}
                      </Typography>
                    ))}
                  </div>
                </Collapse>
              </Col>
              <Col xs="auto">
                <IconButton
                  variant="action"
                  size="xs"
                  onClick={() => {
                    setEndImport(false);
                    setShowErrors(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Col>
            </Row>
          </Container>
        </Alert>
      )}
    </>
  );
};

export default ProductsImport;
