import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useEffect, useMemo, useState} from 'react';
import {ProductCategory, ProductCategoryStatus} from '../types/ProductCategory';
import {
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  CreateCategoryParam,
  UpdateCategoryParam,
  useFetchCategoryQuery,
  useDeleteCategoryMutation,
  useUpdateCategoryOrderMutation,
  OrderParam,
  CategoryOrderParam,
} from '../services/productCategoryApi';
import {formatErrorMessage} from '../utils/functions';
import {ProductCategoryGroup} from '../types/ProductCategoryGroup';
import {DeleteParam} from '../services/storeApi';

export type ProductCategoryFormValues = {
  name: string;
  description: string;
  status?: string;
};

type UseProductCategoryProps = {
  selectedCategory?: ProductCategory | null;
  storeId?: number | string;
  editMode: boolean;
  onUpdate: (category: ProductCategory) => void;
};

type MovedItemType = {
  name: string;
  status: string;
};

export default function useProductCategory({
  storeId,
  selectedCategory,
  editMode,
  onUpdate,
}: UseProductCategoryProps) {
  // Translations
  const intl = useIntl();
  const isEdit = selectedCategory ? true : false;

  const formattedStatus: {[key: string]: string} = {
    on: 'On',
    off: 'Off',
    offForToday: 'Off Today',
    deleted: 'Deleted',
  };

  const productCategoryGroups: ProductCategoryGroup[] = [
    {
      id: ProductCategoryStatus.On,
      title: ProductCategoryStatus.On,
      color: 'green',
    },
    {
      id: ProductCategoryStatus.OffForToday,
      title: 'Off Today',
      color: 'orange',
    },
    {
      id: ProductCategoryStatus.Off,
      title: ProductCategoryStatus.Off,
      color: 'danger',
    },
  ];
  const [productCategoriesByStatus, setProductCategoriesByStatus] = useState<
    ProductCategoryGroup[]
  >(productCategoryGroups);

  const [showLoader, setShowLoader] = useState(true);

  const {data: fetchResult, isSuccess} = useFetchCategoryQuery(
    {
      id: storeId,
      showProgressDialog: showLoader,
      formatErrorMessage: error => formatErrorMessage(error, intl),
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      setShowLoader(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess && fetchResult) {
      const {data} = fetchResult;
      // Group categories by status
      const groups = productCategoryGroups.map(group => {
        group.items = data.filter(item => item.status === group.id);
        return group;
      });
      setProductCategoriesByStatus(groups);
    }
  }, [isSuccess, fetchResult]);

  const updateCategoryGroup = (
    categoryId: number,
    sourceGroupId: string,
    destinationGroupId: string,
    itemDestinationIndex: number
  ) => {
    const categories = [...productCategoriesByStatus];
    // Find the item with categoryId in the source group items array
    const indexDestinationGroup = productCategoriesByStatus.findIndex(
      obj => obj.id === destinationGroupId
    );
    const itemToMove = categories
      ?.find(group => group.id === sourceGroupId)
      ?.items?.find(item => item.id === categoryId);
    // Push the item to the new group items array with specified index/order
    categories[indexDestinationGroup].items?.splice(
      itemDestinationIndex,
      0,
      itemToMove!
    );

    // Remove the item from the old group items array
    const indexSourceGroup = productCategoriesByStatus.findIndex(
      group => group.id === sourceGroupId
    );
    categories[indexSourceGroup].items = categories[
      indexSourceGroup
    ].items?.filter(item => item.id !== categoryId);

    setProductCategoriesByStatus([...categories]);
    orderCategory(categories, {
      name: itemToMove?.name ?? '',
      status: destinationGroupId,
    });
  };

  const reorderCategory = (
    groupId: string,
    currentOrder: number,
    newOrder: number
  ) => {
    const categories = [...productCategoriesByStatus];
    const indexGroup = productCategoriesByStatus.findIndex(
      group => group.id === groupId
    );
    // Remove the category item from the items array
    const categoryItem = categories[indexGroup].items?.splice(
      currentOrder,
      1
    )[0];
    // Insert the category item at the specified new order
    categories[indexGroup].items?.splice(newOrder, 0, categoryItem!);
    setProductCategoriesByStatus([...categories]);
    orderCategory(categories);
  };

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        status: yup.string().when([], {
          is: () => isEdit,
          then: yup
            .string()
            .required(
              intl.formatMessage({id: 'validation.error.required_field'})
            ),
          otherwise: yup.string().nullable(),
        }),
      }),
    [intl]
  );

  const {control, handleSubmit, setValue, reset} =
    useForm<ProductCategoryFormValues>({
      mode: 'onTouched',
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    if (selectedCategory && editMode) {
      setValue('name', selectedCategory.name);
      setValue('description', selectedCategory.description ?? '');
      setValue('status', selectedCategory.status);
    }
  }, [selectedCategory, editMode]);

  const [
    createCategory,
    {isSuccess: createSuccess, data: createData, isLoading: createLoading},
  ] = useCreateCategoryMutation();
  const [updateCategory, {isSuccess: updateSuccess, isLoading: updateLoading}] =
    useUpdateCategoryMutation();

  const [removeCategory, {isSuccess: deleteSuccess}] =
    useDeleteCategoryMutation();

  const [updateCategoryOrder] = useUpdateCategoryOrderMutation();

  // select newly created category
  useEffect(() => {
    if (createSuccess && createData) {
      onUpdate(createData.data);
    }
  }, [createSuccess, createData]);

  const addCategory = (formValues: ProductCategoryFormValues) => {
    const data: CreateCategoryParam = {
      body: {
        storeId,
        ...formValues,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.create_category',
        });
      },
    };
    createCategory(data);
  };

  const editCategory = (formValues: ProductCategoryFormValues) => {
    const data: UpdateCategoryParam = {
      id: selectedCategory?.id,
      body: {
        ...formValues,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.update_category',
        });
      },
    };
    if (selectedCategory) {
      //@ts-ignore
      const newValues: ProductCategory = {...selectedCategory, ...formValues};
      onUpdate(newValues);
    }
    updateCategory(data);
  };

  const deleteCategory = (id: number) => {
    const data: DeleteParam = {
      id,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.delete_category',
          },
          {name: selectedCategory?.name ?? ''}
        );
      },
    };
    removeCategory(data);
  };

  const orderCategory = (
    updatedCategoriesByStatus: ProductCategoryGroup[],
    movedItem?: MovedItemType
  ) => {
    const categoryLists = updatedCategoriesByStatus.flatMap(group =>
      group?.items?.map((item: ProductCategory, index: number) => ({
        id: item.id,
        status: group.id,
        rank: index + 1,
      }))
    ) as OrderParam[];

    const categories: OrderParam[] = categoryLists.map(
      (item: OrderParam, index: number) => ({...item, rank: index + 1})
    );

    const data: CategoryOrderParam = {
      storeId,
      body: {
        categories,
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    };
    if (movedItem) {
      data.formatSuccessMessage = () => {
        return intl.formatMessage(
          {
            id: 'messages.custom_update_category',
          },
          {
            name: movedItem.name,
            status: formattedStatus[movedItem.status],
          }
        );
      };
    }
    updateCategoryOrder(data);
  };

  return {
    handleSubmit,
    control,
    addCategory,
    editCategory,
    updateSuccess,
    createSuccess,
    reset,
    productCategoriesByStatus,
    setProductCategoriesByStatus,
    productCategories: fetchResult?.data,
    deleteCategory,
    deleteSuccess,
    reorderCategory,
    updateCategoryGroup,
    createLoading,
    updateLoading,
  };
}
