import {DevTool} from '@hookform/devtools';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage, useIntl} from 'react-intl';
import {Col, Form, Row} from 'reactstrap';
import useAddress from '../../../hooks/useAddress';
import {Address} from '../../../types/Address';
import AddressAutoComplete from '../../Form/AddressAutoComplete';
import CheckboxController from '../../Form/HookForm/CheckboxController';
import InputController from '../../Form/HookForm/InputController';
import PhoneController from '../../Form/HookForm/PhoneController';
import TextAreaController from '../../Form/HookForm/TextAreaController';

/**
 * Address Form
 */
type AddressFormProps = {
  form: string;
  selectedAddress?: Address | null;
  userId?: number | string;
  closeAddressModal: () => void;
};

const AddressForm = ({
  form,
  selectedAddress,
  userId,
  closeAddressModal,
}: AddressFormProps) => {
  // Translations
  const intl = useIntl();
  const {
    handleSubmit,
    submit,
    control,
    updateAddressValue,
    updateAddressError,
    address,
    addressError,
    isSuccess,
    setEnteredValue,
  } = useAddress({selectedAddress, userId, closeAddressModal});

  return (
    <Form className="mb-2" onSubmit={handleSubmit(submit)} id={form}>
      {/* First name / Last name */}
      <Row className="gx-2">
        <Col>
          <InputController
            name="firstName"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.user_details.tab_address.first_name',
            })}
          />
        </Col>
        <Col>
          <InputController
            name="lastName"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.user_details.tab_address.last_name',
            })}
          />
        </Col>
      </Row>
      {/* Phone */}
      <PhoneController
        name="phone"
        control={control}
        label={intl.formatMessage({id: 'dashboard.users.phone'})}
        required
        phoneInputProps={{international: true}}
      />

      {/* Autocomplete suggested address */}
      <AddressAutoComplete
        name="address"
        label={`${intl.formatMessage({id: 'dashboard.users.address'})}*`}
        setAddress={updateAddressValue}
        updateAddressError={updateAddressError}
        addressError={addressError}
        address={address}
        setEnteredValue={setEnteredValue}
        isSuccess={isSuccess}
      />
      {/* City / Postcode */}
      <Row className="gx-2">
        <Col lg={6}>
          <InputController
            name="city"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.user_details.tab_address.city',
            })}
            disabled
          />
        </Col>
        <Col lg={6}>
          <InputController
            name="postCode"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.user_details.tab_address.post_code',
            })}
            disabled
          />
        </Col>
      </Row>
      {/* Delivery instruction */}
      <TextAreaController
        name="deliveryInstruction"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.user_details.tab_address.delivery_instruction',
        })}
        formGroupClass="mb-2"
      />
      {/* Set as default address */}
      <CheckboxController
        name="isDefault"
        control={control}
        label={
          <Typography className="text-dark">
            <FormattedMessage id="dashboard.user_details.tab_address.set_default_address" />
          </Typography>
        }
        size="default"
      />
      <DevTool control={control} />
    </Form>
  );
};

export default AddressForm;
