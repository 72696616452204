import {Control, UseFormHandleSubmit} from 'react-hook-form';
import {Col, Form, Row} from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import InputController from '../../../Form/HookForm/InputController';
import {FormattedMessage, useIntl} from 'react-intl';
import TextAreaController from '../../../Form/HookForm/TextAreaController';
import CurrencyController from '../../../Form/HookForm/CurrencyController';
import {ServiceFeeForm} from '../../../../hooks/fee/useServiceFeeFormRequest';

type EditFeeFormProps = {
  form: string;
  control: Control<ServiceFeeForm>;
  submit: (formData: ServiceFeeForm) => void;
  handleSubmit: UseFormHandleSubmit<ServiceFeeForm>;
};

const EditFeeForm = (props: EditFeeFormProps) => {
  const intl = useIntl();
  const {control, handleSubmit, form, submit} = props;
  return (
    <Form id={form} onSubmit={handleSubmit(submit)}>
      <InputController
        name="name"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.fee_tab.fee_name',
        })}
      />
      <TextAreaController
        name="description"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.fee_tab.description',
        })}
      />
      {/* Buyer fee */}
      <div className="form-group mb-3 mb-lg-fg">
        <span className="small-label">
          <FormattedMessage id="dashboard.store_details.fee_tab.buyer_fee" />
        </span>
        <Row className="align-items-center mb-2">
          <Col xs={6}>
            <Typography>
              <FormattedMessage id="dashboard.store_details.fee_tab.percentage" />
            </Typography>
          </Col>
          <Col>
            <CurrencyController
              name="buyerFeePercentage"
              control={control}
              prefixLabel={intl.formatMessage({id: 'units.percents'})}
              prefixRight
              className="mb-0"
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={6}>
            <Typography>
              <FormattedMessage id="dashboard.store_details.fee_tab.fixed" />
            </Typography>
          </Col>
          <Col>
            <CurrencyController
              name="buyerFeeFixed"
              control={control}
              prefixLabel={intl.formatMessage({id: 'units.pound'})}
              className="mb-0"
            />
          </Col>
        </Row>
      </div>
      {/* Seller fee */}
      <div className="form-group mb-3 mb-lg-fg">
        <span className="small-label">
          <FormattedMessage id="dashboard.store_details.fee_tab.seller_fee" />
        </span>
        <Row className="align-items-center mb-2">
          <Col xs={6}>
            <Typography>
              <FormattedMessage id="dashboard.store_details.fee_tab.percentage" />
            </Typography>
          </Col>
          <Col>
            <CurrencyController
              name="sellerFeePercentage"
              control={control}
              prefixLabel={intl.formatMessage({id: 'units.percents'})}
              prefixRight
              className="mb-0"
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={6}>
            <Typography>
              <FormattedMessage id="dashboard.store_details.fee_tab.fixed" />
            </Typography>
          </Col>
          <Col>
            <CurrencyController
              name="sellerFeeFixed"
              control={control}
              prefixLabel={intl.formatMessage({id: 'units.pound'})}
              className="mb-0"
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default EditFeeForm;
