import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {
  DeleteParam,
  UpdateStoreStatusParam,
  useDeleteStoreMutation,
  useUpdateStoreStatusMutation,
} from '../services/storeApi';
import {formatErrorMessage} from '../utils/functions';
import * as urls from '../constants/urls';

type ActionParam =
  | 'open'
  | 'onHold'
  | 'closeForToday'
  | 'unsuspend'
  | 'suspend'
  | 'delete';

export type UserStoreProps = {
  storeId?: number | string;
  action: ActionParam;
};

type StoreActionPopupState = {
  suspend: boolean;
  unsuspend: boolean;
  delete: boolean;
  open: boolean;
  onHold: boolean;
  closeForToday: boolean;
};

export default function useStoreAction({storeId, action}: UserStoreProps) {
  // translations
  const intl = useIntl();

  // react-router
  const navigate = useNavigate();

  // manage confirm popup for each action
  const [open, setOpen] = useState<StoreActionPopupState>({
    suspend: false,
    unsuspend: false,
    delete: false,
    open: false,
    onHold: false,
    closeForToday: false,
  });

  // open/close confirm popup
  const openConfirmationPopup = (action: ActionParam, isOpen: boolean) => {
    setOpen(prevState => ({
      ...prevState,
      [action]: isOpen,
    }));
  };

  const [updateStoreStatus] = useUpdateStoreStatusMutation();
  const [deleteStore, {isSuccess}] = useDeleteStoreMutation();

  // change store status
  const storeActionOperation = () => {
    setOpen({
      suspend: false,
      unsuspend: false,
      delete: false,
      open: false,
      onHold: false,
      closeForToday: false,
    });
    const updateStatusMapping: {
      [name: string]: string;
    } = {
      suspend: 'suspended',
      unsuspend: 'unsuspend',
      open: 'open',
      onHold: 'onHold',
      closeForToday: 'closedForToday',
    };
    const data: UpdateStoreStatusParam = {
      id: storeId,
      body: {
        status: updateStatusMapping[action],
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: data => {
        const message = data?.message;
        if (message === 'STATUS_SET_DIFFERENTLY_AS_PER_SCHEDULE') {
          return intl.formatMessage({
            id: 'messages.status_set_differently_as_per_schedule',
          });
        }
        return intl.formatMessage({
          id: 'messages.update_success_message',
        });
      },
    };
    updateStoreStatus(data);
  };

  // delete store
  const deleteStoreAction = () => {
    setOpen({
      suspend: false,
      unsuspend: false,
      delete: false,
      open: false,
      onHold: false,
      closeForToday: false,
    });
    const data: DeleteParam = {
      id: storeId,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'dashboard.store_details.actions.messages.delete_store_success_message',
        });
      },
    };
    deleteStore(data);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(urls.STORES_LIST_PATH);
    }
  }, [isSuccess]);

  return {
    openConfirmationPopup,
    open,
    storeActionOperation,
    deleteStoreAction,
  };
}
