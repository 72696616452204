import moment from 'moment';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  DiscountAmountType,
  DiscountStatus,
  DiscountType,
} from '../../types/Discount';
import {OptionProps} from '../../components/Form/Inputs/AutocompleteMultiple';
import {
  Condition,
  DiscountEligibilityType,
} from '../../types/DiscountEligibility';

export type RepeatType = {
  repeat: boolean;
  setEndDate: boolean;
};

type DeliveryType = {
  collection: boolean;
  delivery: boolean;
};

export enum UseDiscountCode {
  Yes = 'yes',
  No = 'no',
}
export type DiscountEligibilityCondition = {
  propertyName: string;
  condition: Condition;
  items: string | number | number[] | string[];
  entityName: string;
  secondValue?: string | number | number[] | string[];
};

export type DiscountEligibility = {
  eligibilityType: DiscountEligibilityType;
  eligibilityConditions: DiscountEligibilityCondition[];
};

export type DiscountForm = {
  name: string;
  type: string;
  amountType: string;
  amount: number;
  code: string;
  description?: string;
  isActive: string;
  checkoutMessage: string;
  deliveryType: DeliveryType;
  useDiscountCode: string;
  startDate: string | Date;
  startTime: string;
  endDate: string | null;
  endTime: string;
  repeatable: RepeatType;
  repeatableDays: OptionProps[];
  totalMinOrderAmount: number;
  totalMinOrderTimesReached: number;
  limitDiscountUsage: boolean;
  usageLimit: number;
  singleUse: boolean;
  blockPromotion: boolean;
  eligibilities: DiscountEligibility[];
};

export default function useDiscountFormRequest() {
  // translations
  const intl = useIntl();

  const deliveryTypeSchema = yup.object().shape({
    collection: yup.boolean(),
    delivery: yup.boolean(),
  });
  // schema valiation
  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
        type: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
        amountType: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
        amount: yup
          .number()
          .moreThan(
            0,
            intl.formatMessage(
              {id: 'validation.error.limit'},
              {name: 'discount amount'}
            )
          )
          .typeError(intl.formatMessage({id: 'validation.error.required'}))
          .required(intl.formatMessage({id: 'validation.error.required'}))
          .when('amountType', {
            is: (value: DiscountAmountType) =>
              value === DiscountAmountType.Percentage,
            then: yup
              .number()
              .typeError(intl.formatMessage({id: 'validation.error.required'}))
              .max(
                100,
                intl.formatMessage({id: 'validation.error.max_percentage'})
              ),
            otherwise: yup.number(),
          }),
        totalMinOrderAmount: yup
          .number()
          .moreThan(
            0,
            intl.formatMessage(
              {id: 'validation.error.zero_limit'},
              {name: 'total minimum order amount'}
            )
          )
          .typeError(intl.formatMessage({id: 'validation.error.required'}))
          .required(intl.formatMessage({id: 'validation.error.required'})),
        checkoutMessage: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
        startTime: yup.string(),
        endTime: yup.string(),
        startDate: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required'}))
          .test(
            'is-startDate-after-the-endDate',
            intl.formatMessage({id: 'validation.error.superior_start_date'}),
            function (value) {
              const {endTime, endDate, startTime} = this.parent;
              if (value && endDate) {
                const endDateTime = moment(
                  `${endDate} ${endTime ?? '00:00:00'}`,
                  'YYYY-MM-DD HH:mm:ss'
                );
                const startDateTime = moment(
                  `${value} ${startTime ?? '00:00:00'}`,
                  'YYYY-MM-DD HH:mm:ss'
                );
                if (endDateTime.isBefore(startDateTime)) {
                  return Promise.resolve(false);
                }
              }
              return Promise.resolve(true);
            }
          ),
        useDiscountCode: yup.string(),
        code: yup.string().when('useDiscountCode', {
          is: (value: string) => value === 'yes',
          then: yup
            .string()
            .trim()
            .required(intl.formatMessage({id: 'validation.error.required'})),
          otherwise: yup.string().nullable(),
        }),
        totalMinOrderTimesReached: yup.number().when('type', {
          is: (value: string) => value === DiscountType.LoyaltyOffer,
          then: yup
            .number()
            .moreThan(
              0,
              intl.formatMessage(
                {id: 'validation.error.zero_limit'},
                {name: 'number of times order amount should be reached'}
              )
            )
            .typeError(intl.formatMessage({id: 'validation.error.required'}))
            .required(intl.formatMessage({id: 'validation.error.required'})),
          otherwise: yup.number().nullable(),
        }),
        repeatable: yup.object(),
        repeatableDays: yup.array().when('repeatable', {
          is: (value: RepeatType) => value.repeat,
          then: yup
            .array()
            .min(1, intl.formatMessage({id: 'validation.error.required'}))
            .required(intl.formatMessage({id: 'validation.error.required'})),
          otherwise: yup.array().nullable(),
        }),
        endDate: yup
          .string()
          .when('repeatable', {
            is: (value: RepeatType) => value.setEndDate,
            then: yup
              .string()
              .typeError(intl.formatMessage({id: 'validation.error.required'}))
              .required(intl.formatMessage({id: 'validation.error.required'})),
            otherwise: yup.string().nullable(),
          })
          .test(
            'is-endDate-in-the-past',
            intl.formatMessage({id: 'validation.error.end_date_past'}),
            function (value) {
              const {endTime} = this.parent;
              if (value) {
                const today = moment();
                const dateTime = moment(
                  `${value} ${endTime ?? '00:00:00'}`,
                  'YYYY-MM-DD HH:mm:ss'
                );
                if (dateTime.isBefore(today)) {
                  return Promise.resolve(false);
                }
              }
              return Promise.resolve(true);
            }
          )
          .test(
            'is-endDate-before-the-startDate',
            intl.formatMessage({id: 'validation.error.superior_end_date'}),
            function (value) {
              const {endTime, startDate, startTime} = this.parent;

              if (value && startDate) {
                const endDateTime = moment(
                  `${value} ${endTime ?? '00:00:00'}`,
                  'YYYY-MM-DD HH:mm:ss'
                );
                const startDateTime = moment(
                  `${startDate} ${startTime ?? '00:00:00'}`,
                  'YYYY-MM-DD HH:mm:ss'
                );
                if (endDateTime.isBefore(startDateTime)) {
                  return Promise.resolve(false);
                }
                if (getFieldState('startDate').invalid) {
                  trigger('startDate');
                }
                return Promise.resolve(true);
              }
              return Promise.resolve(true);
            }
          ),
        limitDiscountUsage: yup.boolean(),
        usageLimit: yup.string().when(['type', 'limitDiscountUsage'], {
          is: (type: DiscountType, limitDiscountUsage: boolean) =>
            type && type === DiscountType.OrderAmount && limitDiscountUsage,
          then: yup
            .string()
            .typeError(intl.formatMessage({id: 'validation.error.required'}))
            .required(intl.formatMessage({id: 'validation.error.required'}))
            .test(
              'is-usageLimit-equal-zero',
              intl.formatMessage(
                {id: 'validation.error.limit'},
                {name: 'usage limit'}
              ),
              value => {
                if (Number(value) === 0) {
                  return Promise.resolve(false);
                }
                return Promise.resolve(true);
              }
            ),
          otherwise: yup.string().nullable(),
        }),
        eligibilities: yup.array().of(
          yup.object().shape({
            eligibilityType: yup.string().required(),
            eligibilityConditions: yup.array().of(
              yup.object().shape({
                propertyName: yup
                  .string()
                  .typeError(
                    intl.formatMessage({id: 'validation.error.required'})
                  )
                  .required(
                    intl.formatMessage({id: 'validation.error.required'})
                  ),
                condition: yup
                  .string()
                  .typeError(
                    intl.formatMessage({id: 'validation.error.required'})
                  )
                  .required(
                    intl.formatMessage({id: 'validation.error.required'})
                  ),
                entityName: yup.string(),
                items: yup.mixed().when(['propertyName', 'condition'], {
                  is: (propertyName: string, condition: string) => {
                    if (propertyName || condition) {
                      return true;
                    }
                    return false;
                  },
                  then: yup
                    .mixed()
                    .transform(value => (!value ? undefined : value))
                    .when('condition', {
                      is: (condition: Condition) =>
                        condition === 'is_one_of' ||
                        condition === 'is_not_one_of',
                      then: yup
                        .array()
                        .transform(value => (!value ? [] : value))
                        .min(
                          1,
                          intl.formatMessage({
                            id: 'validation.error.required',
                          })
                        )
                        .required(
                          intl.formatMessage({id: 'validation.error.required'})
                        )
                        .test(
                          'is-one-item-at-least',
                          intl.formatMessage({id: 'validation.error.required'}),
                          value => {
                            if (value && value.length === 0) {
                              return Promise.resolve(false);
                            }
                            return Promise.resolve(true);
                          }
                        ),
                      otherwise: yup
                        .mixed()
                        .when(['propertyName', 'condition'], {
                          is: (propertyName: string, condition: Condition) =>
                            propertyName === 'price' &&
                            condition === 'is_less_than',
                          then: yup
                            .string()
                            .typeError(
                              intl.formatMessage({
                                id: 'validation.error.required',
                              })
                            )
                            .required(
                              intl.formatMessage({
                                id: 'validation.error.required',
                              })
                            )
                            .test(
                              'is-price-less-than-0',
                              intl.formatMessage({
                                id: 'validation.error.value_is_zero',
                              }),
                              value => {
                                if (Number(value) === 0) {
                                  return Promise.resolve(false);
                                }
                                return Promise.resolve(true);
                              }
                            ),
                          otherwise: yup.mixed().when('condition', {
                            is: (condition: Condition) =>
                              condition === 'is_between' ||
                              condition === 'is_not_between',
                            then: yup.mixed().when('propertyName', {
                              is: (propertyName: string) =>
                                propertyName === 'price',
                              then: yup.mixed().test(
                                'is-price-less-than-second-value',
                                intl.formatMessage({
                                  id: 'validation.error.start_is_large_than_end',
                                }),
                                (value, context) => {
                                  const {secondValue} = context.parent;
                                  if (
                                    Number(value) === 0 ||
                                    Number(secondValue) === 0
                                  ) {
                                    return Promise.resolve(false);
                                  }
                                  if (Number(value) > Number(secondValue)) {
                                    return Promise.resolve(false);
                                  }
                                  return Promise.resolve(true);
                                }
                              ),
                              otherwise: yup.mixed().when('propertyName', {
                                is: (propertyName: string) =>
                                  propertyName === 'createdAt',
                                then: yup.mixed().test(
                                  'is-startDate-before-the-endDate',
                                  intl.formatMessage({
                                    id: 'validation.error.superior_start_date_no_time',
                                  }),
                                  (value, context) => {
                                    const {secondValue} = context.parent;
                                    if (
                                      moment(value).isAfter(moment(secondValue))
                                    ) {
                                      return Promise.resolve(false);
                                    }
                                    return Promise.resolve(true);
                                  }
                                ),
                                otherwise: yup.mixed().nullable(),
                              }),
                            }),
                          }),
                        }),
                    })
                    .typeError(
                      intl.formatMessage({
                        id: 'validation.error.required',
                      })
                    )
                    .required(
                      intl.formatMessage({
                        id: 'validation.error.required',
                      })
                    ),
                  otherwise: yup.mixed().nullable(),
                }),
                secondValue: yup.mixed().when('condition', {
                  is: (condition: Condition) =>
                    condition === 'is_between' ||
                    condition === 'is_not_between',
                  then: yup
                    .mixed()
                    .transform(value => (!value ? undefined : value))
                    .typeError(
                      intl.formatMessage({id: 'validation.error.required'})
                    )
                    .required(
                      intl.formatMessage({id: 'validation.error.required'})
                    ),
                  otherwise: yup.mixed().nullable(),
                }),
              })
            ),
          })
        ),
        deliveryType: deliveryTypeSchema.test(
          'is_one_delivery_type_true',
          intl.formatMessage({id: 'validation.error.required'}),
          value => {
            const {collection, delivery} = value;
            if (!collection && !delivery) {
              return Promise.resolve(false); // At least one delivery type is true
            }
            return Promise.resolve(true);
          }
        ),
      }),
    [intl]
  );

  // form hook initialization
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    trigger,
    clearErrors,
    formState,
    reset,
    setError,
    resetField,
    register,
    unregister,
  } = useForm<DiscountForm>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      isActive: DiscountStatus.Active,
      useDiscountCode: UseDiscountCode.No,
      repeatable: {repeat: false, setEndDate: false},
      amountType: DiscountAmountType.Fixed,
      deliveryType: {
        collection: true,
        delivery: true,
      },
      eligibilities: [],
      startDate: moment().format('YYYY-MM-DD'),
      startTime: moment().format('hh:mm A'),
      endTime: moment().format('hh:mm A'),
      endDate: null,
      type: DiscountType.OrderAmount,
    },
  });

  return {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    trigger,
    clearErrors,
    formState,
    reset,
    setError,
    resetField,
    register,
    unregister,
  };
}
