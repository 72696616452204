import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import {RootState} from '../store/store';
import {logout, refreshToken, TokenArgs} from '../store/slices/authSlice';
import {Mutex} from 'async-mutex';
import {CustomError} from '../types/CustomError';

const mutex = new Mutex();

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, {getState}) => {
    const token = (getState() as RootState).auth.accessToken;
    // If we have a token set in state, let's assume that we should be passing it.
    // Some queries could use custom authorization
    if (token && !headers.get('authorization')) {
      headers.set('authorization', token);
    }

    return headers;
  },
});
// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError | CustomError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it

  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        // try to get a new token
        const refreshResult = await baseQuery(
          {
            url: '/auth/refresh',
            headers: {
              authorization: (api.getState() as RootState).auth.refreshToken,
            },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          // store the new token
          api.dispatch(refreshToken(refreshResult.data as TokenArgs));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  if (
    result.error &&
    result.error.status === 403 &&
    (result.error.data as any).message === 'SUSPENDED_USER'
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        api.dispatch(logout());
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
    }
  }
  return result;
};
