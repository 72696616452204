import {Container, Nav, NavItem, NavLink} from 'reactstrap';
import {useState} from 'react';
import ThirdPartyIntegrationTab from './ThirdPartyIntegrationTab';
import {useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';
import AppKeyTab from './AppKeyTab';

const AppSettingsTab = () => {
  const THIRD_PARTY_TAB = 'THIRD_PARTY_TAB';
  const APP_KEYS_TAB = 'APP_KEYS_TAB';
  const {appId} = useParams();
  const intl = useIntl();

  const [toggle, setToggle] = useState(THIRD_PARTY_TAB);

  return (
    <div role="tabpanel">
      <Container fluid>
        <div className="content-block">
          <div className="d-flex align-items-center justify-content-start">
            <Nav pills fill className="d-flex mb-4">
              <NavItem>
                <NavLink
                  active={toggle === THIRD_PARTY_TAB}
                  onClick={() => setToggle(THIRD_PARTY_TAB)}
                >
                  {intl.formatMessage({
                    id: 'dashboard.app_details.app_settings.tabs.third_party',
                  })}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={toggle === APP_KEYS_TAB}
                  onClick={() => setToggle(APP_KEYS_TAB)}
                >
                  {intl.formatMessage({
                    id: 'dashboard.app_details.app_settings.tabs.app_keys',
                  })}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          {toggle === THIRD_PARTY_TAB && (
            <ThirdPartyIntegrationTab appId={appId ?? ''} />
          )}
          {toggle === APP_KEYS_TAB && <AppKeyTab appId={appId ?? ''} />}
        </div>
      </Container>
    </div>
  );
};

export default AppSettingsTab;
