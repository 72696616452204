import {useIntl} from 'react-intl';
import useAppAsset from '../../../../hooks/app/useAppAsset';
import {App} from '../../../../types/App';
import {Asset, AssetType} from '../../../../types/Asset';
import {Form} from 'react-bootstrap';
import DropzoneController from '../../../Form/HookForm/DropzoneController';
import ConfirmationPopup from '../../../Shared/ConfirmationPopup';
import {useState} from 'react';

type AssetItemProps = {
  data: App;
  asset: Asset;
  label: string;
};

const AssetItem = (props: AssetItemProps) => {
  const intl = useIntl();
  const {asset, data, label} = props;

  const [open, setOpen] = useState(false);

  const closeConfirmationPopup = () => {
    setOpen(false);
  };

  const openConfirmationPopup = () => {
    setOpen(true);
  };

  const {
    handleDeleteAsset,
    deleteSuccess,
    submit,
    handleSubmit,
    uploadStatus,
    control,
    setError,
    clearErrors,
    validAssetOptions,
    handleImageUpload,
    progress,
    startUpload,
    cancelUpload,
  } = useAppAsset({
    appId: data.id,
    asset: asset,
    closeConfirmationPopup,
  });

  const imageLabelHint =
    asset.type === AssetType.SplashScreen
      ? intl.formatMessage({
          id: 'dashboard.app_details.theme_tab.splash_asset_requirement',
        })
      : intl.formatMessage({
          id: 'dashboard.app_details.theme_tab.icon_asset_requirement',
        });

  return (
    <div>
      <ConfirmationPopup
        opened={open}
        handleClose={closeConfirmationPopup}
        onOk={handleDeleteAsset}
        description={intl.formatMessage({
          id: 'dashboard.app_details.theme_tab.messages.delete_asset_confirmation',
        })}
      />
      <Form id={`update-asset-${asset.id}`} onSubmit={handleSubmit(submit)}>
        <DropzoneController
          name="image"
          format="image"
          showLabelHint={true}
          labelHintText={imageLabelHint}
          control={control}
          canEdit={false}
          canDelete={true}
          canCancel={true}
          cancelUpload={cancelUpload}
          cannotUpload={false}
          hasProgress={startUpload}
          progress={progress}
          uploadImage={handleImageUpload}
          uploadSuccess={uploadStatus}
          deleteImage={openConfirmationPopup}
          deleteSuccess={deleteSuccess}
          setError={setError}
          clearError={clearErrors}
          url={asset.path}
          label={label}
          validFileOptions={validAssetOptions}
          fitImage={true}
          isTallImage={asset.type === AssetType.SplashScreen}
        />
      </Form>
    </div>
  );
};

export default AssetItem;
