import {useEffect, useState} from 'react';
import {
  countDirtyFields,
  formatErrorMessage,
  getDirtyValues,
} from '../utils/functions';
import useFeeFormRequest, {FeeForm} from './fee/useFeeFormRequest';
import {ServiceFeeForm} from './fee/useServiceFeeFormRequest';
import {Fee, FeeType} from '../types/Fee';
import useServiceFee from './useServiceFee';
import {
  UpdateBillingDetailsQueryParam,
  useUpdateBillingDetailsMutation,
} from '../services/storeApi';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {Store} from '../types/Store';
import {BillingDetails} from '../types/BillingDetails';

type UseFeeProps = {
  cardFeeData?: Fee | null;
  storeData: Store;
};

export default function useFee({cardFeeData, storeData}: UseFeeProps) {
  const intl = useIntl();
  const params = useParams();
  const [feeId, setFeeId] = useState<undefined | number>();
  const [storeId, setStoreId] = useState<undefined | number>();
  const [count, setCount] = useState(0);

  const {
    serviceFeeSubmit,
    updateSuccess: isCardUpdatedFeeSuccess,
    createSuccess: isCardCreatedFeeSuccess,
  } = useServiceFee({
    selectedFee: cardFeeData,
  });

  const [updateBillingDetails, {isSuccess: isUpdateSuccess}] =
    useUpdateBillingDetailsMutation();

  const {
    watch,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: {dirtyFields},
  } = useFeeFormRequest();
  const formValuesWatch = watch();

  useEffect(() => {
    if (storeData?.billingDetails) {
      handleBillingDetailsValue(storeData?.billingDetails);
    }
  }, [storeData]);

  useEffect(() => {
    if (isUpdateSuccess || isCardUpdatedFeeSuccess || isCardCreatedFeeSuccess) {
      resetFormField();
    }
  }, [isUpdateSuccess, isCardUpdatedFeeSuccess, isCardCreatedFeeSuccess]);

  useEffect(() => {
    if (cardFeeData) {
      handleCardFeeValue(cardFeeData);
      setFeeId(cardFeeData.id);
    }
  }, [cardFeeData]);

  useEffect(() => {
    if (params.storeId) {
      setStoreId(Number(params.storeId) ?? undefined);
    }
  }, [params]);

  useEffect(() => {
    if (formValuesWatch) {
      setCount(countDirtyFields(dirtyFields));
    }
  }, [formValuesWatch]);

  const handleBillingDetailsValue = (billingDetails: BillingDetails) => {
    const {
      addressLine1,
      addressLine2,
      postCode,
      city,
      companyName,
      vatNumber,
      country,
    } = billingDetails;
    setValue('addressLine1', addressLine1);
    setValue('addressLine2', addressLine2 ?? '');
    setValue('city', city);
    setValue('postCode', postCode);
    setValue('companyName', companyName);
    setValue('vatNumber', vatNumber);
    setValue('country', country);
  };

  const handleCardFeeValue = (fee: Fee) => {
    const {sellerFeeFixed, sellerFeePercentage} = fee;
    setValue('cardFeeFixed', sellerFeeFixed);
    setValue('cardFeePercentage', sellerFeePercentage);
  };

  const isCardFeeUpdated = (formValues: FeeForm) => {
    const dirtyValues = getDirtyValues(dirtyFields, formValues);
    return ['cardFeeFixed', 'cardFeePercentage'].some(
      key => key in dirtyValues
    );
  };

  const isBillingDetailsUpdated = (formValues: FeeForm) => {
    const dirtyValues = getDirtyValues(dirtyFields, formValues);
    return [
      'addressLine1',
      'addressLine2',
      'postCode',
      'city',
      'companyName',
      'vatNumber',
      'country',
    ].some(key => key in dirtyValues);
  };

  // reset form field
  const resetFormField = () => {
    let data = {};
    if (storeData?.billingDetails && cardFeeData) {
      data = {
        ...storeData.billingDetails,
        ...cardFeeData,
      };
    } else if (storeData?.billingDetails) {
      data = {
        ...storeData.billingDetails,
        cardFeeFixed: '',
        cardFeePercentage: '',
      };
    } else if (cardFeeData) {
      const {sellerFeeFixed, sellerFeePercentage} = cardFeeData;

      data = {
        cardFeeFixed: sellerFeeFixed,
        cardFeePercentage: sellerFeePercentage,
        addressLine1: '',
        addressLine2: '',
        postCode: '',
        city: '',
        companyName: '',
        vatNumber: '',
        country: '',
      };
    }
    reset(data);
  };

  const feeAndBillingSubmit = (formValues: FeeForm) => {
    const {
      cardFeeFixed,
      cardFeePercentage,
      addressLine1,
      addressLine2,
      postCode,
      city,
      companyName,
      vatNumber,
      country,
    } = formValues;
    const cardFee: ServiceFeeForm = {
      name: 'Card fees',
      buyerFeePercentage: 0,
      buyerFeeFixed: 0,
      sellerFeePercentage: cardFeePercentage,
      sellerFeeFixed: cardFeeFixed,
      description: 'Card fees',
      type: FeeType.Card,
    };
    if (isCardFeeUpdated(formValues)) {
      serviceFeeSubmit(cardFee);
    }
    if (isBillingDetailsUpdated(formValues)) {
      const bandDetailsData: UpdateBillingDetailsQueryParam = {
        id: storeId,
        body: {
          addressLine1,
          addressLine2,
          city,
          postCode,
          companyName,
          vatNumber,
          country,
        },
        showProgressDialog: true,
        formatErrorMessage: error => {
          return formatErrorMessage(error, intl);
        },
        formatSuccessMessage: () => {
          return intl.formatMessage(
            {
              id: 'messages.update_fee_billing_details',
            },
            {}
          );
        },
      };
      updateBillingDetails(bandDetailsData);
    }
  };

  return {
    control,
    handleSubmit,
    resetFormField,
    feeAndBillingSubmit,
    feeId,
    count,
  };
}
