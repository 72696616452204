import {LinearProgress, Theme, withStyles} from '@material-ui/core';

export default withStyles((theme: Theme) => ({
  root: {
    height: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.green.light,
  },
  bar: {
    backgroundColor: theme.palette.green.main,
  },
}))(LinearProgress);
