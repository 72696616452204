import {FeedbackParams} from '../store/queries/common';
import {PaymentCard} from '../types/PaymentCard';
import {baseApi} from './baseApi';

export type ListCardsParams = {
  buyerId?: number | string;
} & FeedbackParams;

export type SetDefaultCardParams = {
  buyerId?: number | string;
  body: {
    cardId: string;
  };
} & FeedbackParams;

export type DeleteCardParams = {
  buyerId?: number | string;
  cardId: string;
} & FeedbackParams;

export type SuccessResponse = {
  message: string;
};

export const paymentApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    listCards: builder.query<{cards: PaymentCard[]}, ListCardsParams>({
      query: ({buyerId}: ListCardsParams) => ({
        url: `/payment/buyer/${buyerId}/cards`,
        method: 'GET',
      }),
      providesTags: ['Cards'],
    }),
    setDefaultCard: builder.mutation<SuccessResponse, SetDefaultCardParams>({
      query: ({buyerId, body}: SetDefaultCardParams) => ({
        url: `/payment/buyer/${buyerId}/cards/default`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Cards'],
    }),
    deleteCard: builder.mutation<SuccessResponse, DeleteCardParams>({
      query: ({buyerId, cardId}: DeleteCardParams) => ({
        url: `/payment/buyer/${buyerId}/cards/${cardId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Cards'],
    }),
  }),
});

export const {
  useListCardsQuery,
  useSetDefaultCardMutation,
  useDeleteCardMutation,
} = paymentApi;
