import {useFetchBuyerQuery} from '../services/buyerApi';
import {useIntl} from 'react-intl';
import {useNavigate, useSearchParams} from 'react-router-dom';
import * as urls from '../constants/urls';
import {
  formatErrorMessage,
  getUrlSearchParams,
  parseStatusTypeFilter,
} from '../utils/functions';
import {useEffect, useState} from 'react';
import {OptionProps} from '../components/Form/Inputs/AutocompleteMultiple';
import {get, isEqual} from 'lodash';
import useDataTableState from './table/useDataTableState';
import {parseFilterSearchParams} from '../components/Form/DynamicFilter';

export default function useBuyerList() {
  const [searchParams] = useSearchParams();
  const [filterValues, setFilterValues] = useState<any>({});
  const [q, setQ] = useState('');
  const tableState = useDataTableState({
    defaultValues: {
      orderDirection: 'ASC',
      orderBy: 'fullName',
    },
    bindQueryParams: true,
  });

  useEffect(() => {
    const id = setTimeout(() => {
      const search = getUrlSearchParams(searchParams);
      const newFilterValues = parseFilterSearchParams(search);
      if (!isEqual(filterValues, newFilterValues)) {
        setFilterValues(newFilterValues);
      }
      const nextQ = search.get('q') ?? '';
      if (q !== nextQ) {
        setQ(nextQ);
      }
    }, 250);

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // Translations
  const intl = useIntl();
  // Router
  const navigate = useNavigate();

  // seller list data
  const {isSuccess, data, isFetching} = useFetchBuyerQuery(
    {
      query: {
        page: tableState.page,
        pageSize: tableState.pageSize,
        order: tableState.orderDirection,
        orderBy: tableState.orderBy,
        location:
          Array.isArray(filterValues.location) &&
          filterValues.location.length > 0
            ? filterValues.location
                .map((item: OptionProps) => item.value)
                .join(',')
            : undefined,
        status: filterValues?.status
          ? parseStatusTypeFilter(filterValues.status)
          : undefined,
        dateFilter: get(filterValues, 'createdAt.type') ?? undefined,
        startDate: get(filterValues, 'createdAt.from') ?? undefined,
        endDate: get(filterValues, 'createdAt.to') ?? undefined,
        search: q,
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  //Datatable Row onclick handler
  const handleRowClick = (itemId: number) => {
    navigate(
      `${urls.BUYER_DETAILS_PATH.replace(':id', itemId.toString())}/profile`
    );
  };

  return {
    isLoading: isFetching,
    isSuccess,
    data: data?.data,
    handleRowClick,
    meta: data?.meta,
    tableState,
  };
}
