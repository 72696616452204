import {useIntl} from 'react-intl';
import {formatErrorMessage} from '../utils/functions';
import {
  useCreateInvoiceLineItemMutation,
  useDeleteInvoiceLineItemMutation,
  useFetchStatementInvoiceQuery,
} from '../services/statementApi';
import {StatementLineItem} from '../components/Transactions/Details/BillingDetails';

export type UseStatementInvoiceProps = {
  statementId: number;
};

export type InvoiceLineItemParams = {
  description: string;
  invoiceId: number;
};

export default function useStatementInvoice(props: UseStatementInvoiceProps) {
  const {statementId} = props;
  // Translations
  const intl = useIntl();

  // Statement invoice data
  const {isSuccess, data, isFetching, isLoading, isError} =
    useFetchStatementInvoiceQuery(
      {
        statementId,
        formatErrorMessage: error => formatErrorMessage(error, intl),
        showProgressDialog: true,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  // create invoice line item api
  const [createInvoiceLineItem, {isSuccess: isCreateSuccess}] =
    useCreateInvoiceLineItemMutation();

  // delete invoice line item api
  const [deleteInvoiceLineItem] = useDeleteInvoiceLineItemMutation();

  // delete invoice line item request
  const createInvoiceLineItemRequest = (body: InvoiceLineItemParams) => {
    createInvoiceLineItem({
      body,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
    });
  };

  // delete invoice line item request
  const deleteInvoiceLineItemRequest = ({
    id,
    description,
  }: StatementLineItem) => {
    deleteInvoiceLineItem({
      id,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.delete_line_item',
          },
          {
            description,
          }
        );
      },
    });
  };

  return {
    createInvoiceLineItemRequest,
    deleteInvoiceLineItemRequest,
    isCreateSuccess,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    data,
  };
}
