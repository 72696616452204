import {Stack} from 'react-bootstrap';
import {Typography} from '@material-ui/core';
import {Rating} from '@material-ui/lab';
import Switch from '../../Common/Switch';
import {useMediaQuery} from 'react-responsive';
import {BREAKPOINTS} from '../../../constants/appConstants';
import {ReactComponent as StarEmptyIcon} from '../../../assets/images/starEmpty.svg';
import {ReactComponent as StarIcon} from '../../../assets/images/starFilled.svg';
import {FormattedReview} from '../../../types/Order';
import {ReviewStatus} from '../../../types/Review';
import {useIntl} from 'react-intl';

type ReviewProps = {
  data: FormattedReview;
  handleReviewStatusUpdate: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  showReviewSwitch?: boolean;
};
const Review = ({
  data,
  handleReviewStatusUpdate,
  showReviewSwitch = false,
}: ReviewProps) => {
  const isMobile = useMediaQuery({maxWidth: BREAKPOINTS.LG});
  const textVariant = isMobile ? 'subtitle2' : 'body1';
  const intl = useIntl();

  return (
    <div className="mb-vtl-16 mb-lg-3">
      <div className="mb-2">
        <Rating
          name="rating"
          defaultValue={data.rate}
          readOnly
          icon={<StarIcon />}
          emptyIcon={<StarEmptyIcon />}
        />
      </div>
      <div className="mb-vtl-16 mb-lg-vtl-15">
        <Typography variant={textVariant}>{data.description ?? ''}</Typography>
      </div>
      {showReviewSwitch && (
        <Stack direction="horizontal" gap={2}>
          <Switch
            green
            checked={data.status === ReviewStatus.Public}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleReviewStatusUpdate(event);
            }}
          />
          <Typography variant={textVariant}>
            {intl.formatMessage({
              id: 'dashboard.store_details.reviews_tab.accounted_total_score',
            })}
          </Typography>
        </Stack>
      )}
    </div>
  );
};

export default Review;
