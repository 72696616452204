import {useIntl} from 'react-intl';
import {Form} from 'reactstrap';
import DropzoneController from '../../../../Form/HookForm/DropzoneController';
import InputController from '../../../../Form/HookForm/InputController';
import TextAreaController from '../../../../Form/HookForm/TextAreaController';
import CurrencyController from '../../../../Form/HookForm/CurrencyController';
import useCreateProduct from '../../../../../hooks/useCreateProduct';
import {validImageOptions} from '../../../../../utils/functions';
import {ProductCategory} from '../../../../../types/ProductCategory';
import {useEffect, useState} from 'react';

/**
 * Create product form
 */

type CreateProductFormProps = {
  form: string;
  closePopup: () => void;
  selectedCategory: ProductCategory;
};

const CreateProductForm = (props: CreateProductFormProps) => {
  // Translations
  const intl = useIntl();
  const {form, selectedCategory, closePopup} = props;
  const [isDeleted, setIsDeleted] = useState(false);

  const {
    control,
    handleSubmit,
    submit,
    createSuccess,
    resetFields,
    setError,
    reset,
    clearErrors,
    errors,
    isLoading,
  } = useCreateProduct({categoryId: selectedCategory?.id as number});

  useEffect(() => {
    if (createSuccess) {
      setIsDeleted(false);
      resetFields();
      closePopup();
    }
  }, [createSuccess]);

  const deleteImage = () => {
    setIsDeleted(true);
    reset({image: ''});
  };

  return (
    <Form id={isLoading ? undefined : form} onSubmit={handleSubmit(submit)}>
      {/* Product image */}
      <DropzoneController
        name="image"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.product_details.productImage',
        })}
        labelHintText={intl.formatMessage({
          id: 'dashboard.store_details.general_tab.store_image_hint',
        })}
        canDelete
        canEdit={false}
        showLabelHint
        className="mb-3 mb-lg-fg"
        validFileOptions={validImageOptions}
        uploadSuccess={createSuccess}
        deleteSuccess={isDeleted}
        setError={setError}
        deleteImage={deleteImage}
        clearError={clearErrors}
        errors={errors}
      />
      {/* Product name (English) */}
      <InputController
        name="name"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.product_details.enProductName',
        })}
        required
      />
      {/* Product name (Chinese) */}
      <InputController
        name="chineseName"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.product_details.chProductName',
        })}
      />
      {/* Description */}
      <TextAreaController
        name="description"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.product_details.description',
        })}
      />
      {/* Price */}
      <CurrencyController
        name="price"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.product_details.price',
        })}
        required
      />
    </Form>
  );
};

export default CreateProductForm;
