import {ReactNode} from 'react';
import {FormControl} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import {
  Controller,
  Control,
  Path,
  FieldValues,
  useWatch,
} from 'react-hook-form';
import TimePicker from 'rc-time-picker';
import {ReactComponent as ClockIcon} from '../../../assets/images/clock.svg';
import {ReactComponent as ArrowUpIcon} from '../../../assets/images/arrowUp.svg';
import {ReactComponent as ArrowDownIcon} from '../../../assets/images/arrowDown.svg';

export type TimeInputControllerProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  setValue: (name: string, value: string) => void;
  inputClass?: string;
  excludeLabel?: boolean;
  label?: string;
  controlClass?: string;
  showLabelHint?: boolean;
  labelHintText?: string | ReactNode;
  formGroupClass?: string;
  disabled?: boolean;
  use12Hours?: boolean;
  timeFormat?: string;
};

const TimeInputController = <T extends FieldValues>({
  name,
  control,
  label,
  formGroupClass,
  excludeLabel,
  disabled,
  use12Hours = true,
  timeFormat,
  setValue,
}: TimeInputControllerProps<T>) => {
  const format = timeFormat ?? 'hh:mm A';
  const now = moment().hour(0).minute(0);
  const current = useWatch({control, name: name});

  const upHours = (current: string) => {
    const newValue = moment(current, format).add(1, 'hours');
    setValue(name, newValue.format(format));
  };

  const downHours = (current: string) => {
    const newValue = moment(current, format).subtract(1, 'hours');
    setValue(name, newValue.format(format));
  };

  const upMinutes = (current: string) => {
    const newValue = moment(current, format).add(1, 'minutes');
    setValue(name, newValue.format(format));
  };

  const downMinutes = (current: string) => {
    const newValue = moment(current, format).subtract(1, 'minutes');
    setValue(name, newValue.format(format));
  };

  const controls = () => {
    return (
      <>
        <div className="controls hours">
          <button className="up" onClick={() => upHours(current)}>
            <ArrowUpIcon />
          </button>
          <button className="down" onClick={() => downHours(current)}>
            <ArrowDownIcon />
          </button>
        </div>
        <div className="controls minutes">
          <button className="up" onClick={() => upMinutes(current)}>
            <ArrowUpIcon />
          </button>
          <button className="down" onClick={() => downMinutes(current)}>
            <ArrowDownIcon />
          </button>
        </div>
      </>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {value, onChange}, fieldState: {error}}) => {
        const handleChange = (value: any) => {
          onChange(value.format(format));
        };

        return (
          <FormControl
            className={clsx(
              'form-group d-flex',
              formGroupClass ? formGroupClass : 'mb-3 mb-lg-fg'
            )}
            error={!!error}
          >
            {label && !excludeLabel && (
              <span className="small-label pb-2">{label}</span>
            )}
            <TimePicker
              showSecond={false}
              placement="bottomLeft"
              value={value ? moment(value, format) : now}
              className="vtl-timepicker"
              popupClassName="vtl-timepicker-popup"
              onChange={handleChange}
              format={format}
              use12Hours={use12Hours}
              inputReadOnly
              inputIcon={<ClockIcon />}
              addon={controls}
              disabled={disabled}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default TimeInputController;
