export function drawCircle(
  point: google.maps.LatLng,
  radius: number,
  dir: number
) {
  const d2r = Math.PI / 180; // degrees to radians
  const r2d = 180 / Math.PI; // radians to degrees
  const earthRadius = 3963; // 3963 is the radius of the earth in miles

  const points = 256;

  // find the radius in lat/lon
  const rlat = (radius / earthRadius) * r2d;
  const rlng = rlat / Math.cos(point.lat() * d2r);
  let start, end;

  const extp = [];
  if (dir === 1) {
    start = 0;
    end = points + 1;
  } else {
    start = points + 1;
    end = 0;
  }
  for (let i = start; dir === 1 ? i < end : i > end; i = i + dir) {
    const theta = Math.PI * (i / (points / 2));
    const ey = point.lng() + rlng * Math.cos(theta); // center a + radius x * cos(theta)
    const ex = point.lat() + rlat * Math.sin(theta); // center b + radius y * sin(theta)
    extp.push(new google.maps.LatLng(ex, ey));
  }
  return extp;
}
