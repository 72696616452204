import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Form} from 'reactstrap';
import InputController from '../../Form/HookForm/InputController';
import useCreateApp from '../../../hooks/useCreateApp';
import {OptionProps} from '../../Form/Inputs/AutocompleteSingle';
import {useFetchStoresQuery} from '../../../services/storeApi';
import AutocompleteSingleController from '../../Form/HookForm/AutocompleteSingleController';

type CreateAppFormProps = {
  form: string;
  closePopup: () => void;
};

const CreateAppForm = ({form, closePopup}: CreateAppFormProps) => {
  // Translations
  const intl = useIntl();
  const [stores, setStores] = useState<OptionProps[]>([]);

  // fetch active stores
  const {data: storeData, isSuccess: successStoreData} = useFetchStoresQuery({
    query: {pageSize: 100},
  });

  useEffect(() => {
    if (storeData && successStoreData) {
      const {data} = storeData;
      setStores(
        data.map(store => ({
          title: store.name,
          key: store.id,
        }))
      );
    }
  }, [storeData, successStoreData]);

  const {handleSubmit, onSubmit, control, isSuccess} = useCreateApp();

  // close popup
  useEffect(() => {
    if (isSuccess) {
      closePopup();
    }
  }, [isSuccess]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id={form}>
      {/* App name */}
      <InputController
        name="name"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.apps.name',
        })}
        required
      />
      {/* Link app to store */}
      <AutocompleteSingleController
        name="store"
        control={control}
        label={intl.formatMessage({id: 'dashboard.apps.mealmap_store'})}
        options={stores}
        required
        className="mb-2"
        placeholder={intl.formatMessage({
          id: 'dashboard.apps.enter_store_name',
        })}
      />
    </Form>
  );
};

export default CreateAppForm;
