import {useEffect, useState} from 'react';
import useProductVariation, {
  ProductVariationFormValues,
} from '../../../../hooks/useProductVariation';
import {ProductVariation} from '../../../../types/ProductVariation';
import AddPopup from '../../../Widgets/AddPopup';
import VariationForm from './Form/VariationForm';
import Variation from './Variation';
import {ProductOptionGroup} from '../../../../types/ProductOptionGroup';
import {useIntl} from 'react-intl';
import Button from '../../../Common/Button';
import useProductOption from '../../../../hooks/useProductOption';
import OptionForm from './Form/OptionForm';
import ConfirmationPopup from '../../../Shared/ConfirmationPopup';
import {
  ProductOption,
  ProductOptionStatus,
} from '../../../../types/ProductOption';
import SaveFeature from '../../../Shared/SaveFeature';
import {cloneDeep} from 'lodash';
type ProductVariationProps = {
  variations: ProductVariation[];
  activeGroup: ProductOptionGroup;
  isAddGroupPopUp: boolean;
  isEditGroupPopUp: boolean;
  isDeleteGroupModel: boolean;
  closeGroupOptionsPopUps: () => void;
};

export type VarianceErrorState = {
  variationId: number;
  isError: boolean;
};

export const isMinLessThanOnOption = (
  variation: ProductVariation,
  updatedOptions: ProductOption[]
) => {
  const varianceOptions: ProductOption[] = [];
  variation.options.forEach(option => {
    const updatedOption = updatedOptions.find(o => o.id === option.id);
    if (updatedOption) {
      varianceOptions.push({
        ...option,
        status: updatedOption.status,
      });
    } else {
      varianceOptions.push(option);
    }
  });
  const totalOnOptions = varianceOptions.filter(
    o => o.status === ProductOptionStatus.active
  ).length;

  return !!variation.min && variation.min > totalOnOptions;
};

const VariationList = (props: ProductVariationProps) => {
  const {
    variations,
    activeGroup,
    closeGroupOptionsPopUps,
    isAddGroupPopUp,
    isEditGroupPopUp,
    isDeleteGroupModel,
  } = props;
  const intl = useIntl();

  const [active, setActive] = useState<ProductVariation | null>();
  const [variationsList, setVariationsList] = useState<
    ProductVariation[] | null
  >();
  const [isMultiUpdate, setMultiUpdate] = useState(false);
  // Add variation popup state
  const [addVariationPopup, setAddVariationPopup] = useState(false);
  // Is Variation Edit state
  const [isVariationEdit, setIsVariationEdit] = useState(false);
  // Edit popup shown state
  const [editVariationPopup, setEditVariationPopup] = useState(false);
  const [activeOption, setActiveOption] = useState<ProductOption | null>();
  // Add option popup state
  const [addOptionPopup, setAddOptionPopup] = useState(false);
  // Edit option popup state
  const [editOptionPopup, setEditOptionPopup] = useState(false);
  // IsEdit option state
  const [isOptionEdit, setIsOptionEdit] = useState(false);
  // Delete variation modal state
  const [openVariationDeleteModal, setVariationDeleteModal] = useState(false);
  // Delete option modal state
  const [openOptionDeleteModal, setOptionDeleteModal] = useState(false);

  // update selected variation
  const onChangeActive = (variationToActive: ProductVariation) => {
    setActive(variationToActive);
  };

  const {
    control,
    handleSubmit,
    submit,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    deleteProductVariation,
    reset,
    createdLoading,
    updatedLoading,
    variationsToUpdate,
    resetUpdatedVariations,
    addingNewVariationUpdate,
    updateEditedVariations,
    removeFromUpdatesIfExits,
    updateFetchedVariationsWithEditDirtyState,
  } = useProductVariation({
    optionGroupId: activeGroup?.id,
    isEditMode: isVariationEdit,
    selectedVariation: active,
    onUpdate: onChangeActive,
  });
  const {
    control: optionControl,
    handleSubmit: handleOptionSubmit,
    submit: optionSubmit,
    createSuccess: createOptionSuccess,
    updateSuccess: updateOptionSuccess,
    deleteSuccess: deleteOptionSuccess,
    resetOptionsFields,
    deleteProductOption,
    createdLoading: createOptionLoading,
    updatedLoading: updateOptionLoading,
    optionsToUpdate,
    resetUpdatedOptions,
    addingNewOptionUpdate,
    updateEditedOptions,
    removeFromUpdatesIfExits: removeOptionFromUpdatesIfExits,
    updateFetchedOptionsWithEditDirtyState,
  } = useProductOption({
    selectedOption: activeOption,
    isEditMode: isOptionEdit,
    variationId: active?.id,
  });
  //close modal
  useEffect(() => {
    if (createSuccess) {
      reset();
      setAddVariationPopup(false);
    }
    if (updateSuccess) {
      reset();
      setEditVariationPopup(false);
      setIsVariationEdit(false);
    }
    if (createOptionSuccess) {
      resetOptionsFields();
      setAddOptionPopup(false);
    }
    if (updateOptionSuccess) {
      resetOptionsFields();
      setEditOptionPopup(false);
      setIsOptionEdit(false);
    }
  }, [createSuccess, updateSuccess, createOptionSuccess, updateOptionSuccess]);

  // handle delete success
  useEffect(() => {
    if (deleteOptionSuccess) {
      setActiveOption(null);
      closeAllOptionsPopUps();
    }
    if (deleteSuccess) {
      setActive(null);
      closeAllVariationsPopUps();
    }
  }, [deleteSuccess, deleteOptionSuccess]);

  useEffect(() => {
    if (variationsToUpdate.length > 0 || optionsToUpdate.length > 0) {
      const newVariations = updateFetchedVariationsWithEditDirtyState(
        variations,
        updateFetchedOptionsWithEditDirtyState
      );
      setVariationsList(newVariations);
    } else {
      setVariationsList(variations);
    }
  }, [variations]);

  useEffect(() => {
    if (variations && variations?.length > 0) {
      if (
        variationsToUpdate.length === 0 &&
        !isMultiUpdate &&
        optionsToUpdate.length === 0
      ) {
        const newVariations = cloneDeep(variations);
        setVariationsList(newVariations);
        setMultiUpdate(false);
      }
    }
  }, [variationsToUpdate.length, optionsToUpdate.length]);

  useEffect(() => {
    if (isAddGroupPopUp || isEditGroupPopUp || isDeleteGroupModel) {
      closeAllOptionsPopUps();
      closeAllVariationsPopUps();
    }
  }, [isAddGroupPopUp, isEditGroupPopUp, isDeleteGroupModel]);

  const openVariationAddPopup = () => {
    reset();
    closeAllGroupOptionsPopUps();
    closeAllOptionsPopUps();
    setEditVariationPopup(false);
    setIsVariationEdit(false);
    setAddVariationPopup(true);
  };
  // Open Edit Variation popup
  const onEditVariationClick = (editVariation: ProductVariation) => {
    if (!isVariationEdit) {
      reset();
    }
    closeAllGroupOptionsPopUps();
    closeAllOptionsPopUps();
    setAddVariationPopup(false);
    onChangeActive(editVariation);
    setIsVariationEdit(true);
    setEditVariationPopup(true);
  };
  // Open Edit Variation Min And Max
  const onEditVariationChange = (
    formData: ProductVariationFormValues,
    editVariation: ProductVariation
  ) => {
    setMultiUpdate(true);
    addingNewVariationUpdate(formData, editVariation);
  };

  const cancelMultiUpdate = () => {
    resetUpdatedVariations();
    resetUpdatedOptions();
    setMultiUpdate(false);
  };

  // Open Edit Option Status
  const onEditOptionChange = (newStatus: string, editOption: ProductOption) => {
    setMultiUpdate(true);
    addingNewOptionUpdate(newStatus, editOption);
  };

  // Add option
  const onAddOptionClick = (activeVariation: ProductVariation) => {
    resetOptionsFields();
    closeAllVariationsPopUps();
    closeAllGroupOptionsPopUps();
    setEditOptionPopup(false);
    onChangeActive(activeVariation);
    setAddOptionPopup(true);
    setIsOptionEdit(false);
  };
  // Edit option
  const onEditOptionClick = (item: ProductOption) => {
    if (!isOptionEdit) {
      resetOptionsFields();
    }
    closeAllVariationsPopUps();
    closeAllGroupOptionsPopUps();
    setAddOptionPopup(false);
    setActiveOption(item);
    setIsOptionEdit(true);
    setEditOptionPopup(true);
  };

  // Delete Variation Modal
  const onDeleteVariationClick = (item: ProductVariation) => {
    closeAllVariationsPopUps();
    onChangeActive(item);
    setVariationDeleteModal(true);
  };
  // Delete Variation
  const deleteVariation = () => {
    setVariationDeleteModal(false);
    removeFromUpdatesIfExits(active!);
    removeOptionFromUpdatesIfExits(active!.options!);
    deleteProductVariation(active?.id as number);
  };
  // Delete option
  const onDeleteOptionClick = (item: ProductOption) => {
    closeAllOptionsPopUps();
    setActiveOption(item);
    setOptionDeleteModal(true);
  };

  // Delete Option
  const deleteOption = () => {
    setOptionDeleteModal(false);
    removeOptionFromUpdatesIfExits([activeOption!]);
    deleteProductOption(activeOption?.id as number);
  };

  const onConfirmationSaveClicked = () => {
    const isError = variations.find(variation => {
      let updatedVariation = variationsToUpdate.find(
        vu => vu.id === variation.id
      );
      if (updatedVariation) {
        updatedVariation = {
          ...updatedVariation,
          options: variation.options,
        };
      }
      return isMinLessThanOnOption(
        updatedVariation ?? variation,
        optionsToUpdate
      );
    });
    if (!isError) {
      updateEditedVariations();
      updateEditedOptions();
    }
  };

  const closeAllGroupOptionsPopUps = () => {
    closeGroupOptionsPopUps();
  };
  const closeAllOptionsPopUps = () => {
    setAddOptionPopup(false);
    setEditOptionPopup(false);
  };
  const closeAllVariationsPopUps = () => {
    setAddVariationPopup(false);
    setEditVariationPopup(false);
  };

  return (
    <>
      {((variationsToUpdate && variationsToUpdate?.length > 0) ||
        (optionsToUpdate && optionsToUpdate.length > 0)) && (
        <SaveFeature
          onClose={cancelMultiUpdate}
          open
          keyType="setting"
          num={variationsToUpdate?.length + optionsToUpdate?.length}
          type="submit"
          form="update-multi-property"
          onSave={onConfirmationSaveClicked}
        />
      )}
      {/* Delete variation confirmation */}
      <ConfirmationPopup
        opened={openVariationDeleteModal}
        handleClose={() => setVariationDeleteModal(false)}
        onOk={deleteVariation}
        description={intl.formatMessage({
          id: 'messages.delete_message',
        })}
      />
      {/* Add variation */}
      <AddPopup
        title={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.add_variation',
        })}
        opened={addVariationPopup}
        onClose={() => {
          setAddVariationPopup(false);
        }}
        activeTab={createdLoading ? undefined : 'add-variation'}
      >
        <VariationForm
          form="add-variation-form"
          control={control}
          handleSubmit={handleSubmit}
          submit={submit}
        />
      </AddPopup>
      {/* Edit variation popup */}
      <AddPopup
        edit
        title={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.edit_variation',
        })}
        opened={editVariationPopup}
        onClose={() => {
          setIsVariationEdit(false);
          setEditVariationPopup(false);
        }}
        activeTab={updatedLoading ? undefined : 'edit-variation'}
      >
        <VariationForm
          form="edit-variation-form"
          control={control}
          handleSubmit={handleSubmit}
          submit={submit}
        />
      </AddPopup>
      {/* Delete Option confirmation */}
      <ConfirmationPopup
        opened={openOptionDeleteModal}
        handleClose={() => setOptionDeleteModal(false)}
        onOk={deleteOption}
        description={intl.formatMessage({
          id: 'messages.delete_message',
        })}
      />
      {/* Add option popup  */}
      <AddPopup
        title={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.add_option',
        })}
        opened={addOptionPopup}
        onClose={() => setAddOptionPopup(false)}
        activeTab={createOptionLoading ? undefined : 'add-option'}
      >
        <OptionForm
          form="add-option-form"
          control={optionControl}
          handleSubmit={handleOptionSubmit}
          submit={optionSubmit}
        />
      </AddPopup>
      {/* Edit option popup */}
      <AddPopup
        edit
        title={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.edit_option',
        })}
        opened={editOptionPopup}
        onClose={() => {
          setIsOptionEdit(false);
          setEditOptionPopup(false);
        }}
        activeTab={updateOptionLoading ? undefined : 'edit-option'}
      >
        <OptionForm
          form="edit-option-form"
          control={optionControl}
          handleSubmit={handleOptionSubmit}
          submit={optionSubmit}
        />
      </AddPopup>
      {variationsList &&
        variationsList.length > 0 &&
        variationsList.map(item => (
          <Variation
            key={item.id}
            variation={item}
            onEdit={onEditVariationClick}
            onDelete={onDeleteVariationClick}
            control={control}
            handleSubmit={handleSubmit}
            submit={submit}
            onAddOption={onAddOptionClick}
            onEditOption={onEditOptionClick}
            onDeleteOption={onDeleteOptionClick}
            onEditVariationChange={onEditVariationChange}
            onEditOptionChange={onEditOptionChange}
            optionsToUpdate={optionsToUpdate}
            variationsToUpdate={variationsToUpdate}
          />
        ))}
      <div className="mt-2 mb-2 mt-lg-3 mb-lg-4">
        <Button
          primary
          outlined
          mobileFullWidth
          className="action-btn"
          title={intl.formatMessage({
            id: 'dashboard.store_details.options_tab.add_variation',
          })}
          onClick={openVariationAddPopup}
        />
      </div>
    </>
  );
};

export default VariationList;
