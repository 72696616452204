import React, {Fragment, useCallback, useEffect, useState, useRef} from 'react';
import {
  GoogleMap,
  GoogleMapProps,
  Circle,
  Marker,
} from '@react-google-maps/api';
import withGmaps from '../../../Gmaps/withGmaps';
import {Box, Card, Typography} from '@material-ui/core';
import IconButton from '../../../Common/IconButton';
import {ReactComponent as MapEditIcon} from '../../../../assets/images/mapEditMode.svg';
import {ReactComponent as MapNavIcon} from '../../../../assets/images/mapNavMode.svg';
import {metersToMiles, milesToMeters} from '../../../../utils/functions';
import {SearchAddressResult} from '../../../../types/SearchAddressResult';
import {MapZoneType} from '../../../../hooks/useCreateDeliveryZone';
import {FormattedMessage} from 'react-intl';
import {Container} from 'reactstrap';
import MarkerIcon from '../../../../assets/images/mapMarker.svg';
import RestaurantIcon from '../../../../assets/images/restaurant.svg';

export type EditMapZoneProps = {
  ranges: number[];
  setRanges: (values: number[]) => void;
  zone: MapZoneType | null;
  isEdit: boolean;
  setIsEdit: (editable: boolean) => void;
  address?: SearchAddressResult;
} & Omit<GoogleMapProps, 'onClick' | 'onMouseMove' | 'onLoad' | 'onUnmount'>;

const EditMapZone = ({
  zone,
  setRanges,
  ranges,
  isEdit,
  setIsEdit,
  address,
  ...googleMapProps
}: EditMapZoneProps) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [circle, setCircle] = useState<google.maps.Circle>();
  const [innerCircle, setInnerCircle] = useState<google.maps.Circle>();
  const circleRef = useRef<Circle>();

  const mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap'],
    },
    disableDefaultUI: true,
  };

  const onLoad = useCallback((map: google.maps.Map) => {
    if (address) {
      const bound = new window.google.maps.LatLngBounds({
        lat: address.latitude,
        lng: address.longitude,
      });
      const center = new google.maps.LatLng({
        lat: address.latitude,
        lng: address.longitude,
      });
      map.fitBounds(bound);
      map.setCenter(center);
    }
    map.setZoom(15);
    setMap(map);
  }, []);

  useEffect(() => {
    if (zone) {
      if (map && address) {
        const target = new google.maps.LatLng(zone.lat, zone.lng);
        const duration = 2000;
        const easing = (t: number) =>
          t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        const start =
          map.getCenter() ||
          new google.maps.LatLng({
            lat: address.latitude,
            lng: address.longitude,
          });

        const animateStep = (timestamp: number) => {
          const elapsed = timestamp - startTimestamp;
          const progress = easing(Math.min(1, elapsed / duration));
          const lat = start!.lat() + (target.lat() - start!.lat()) * progress;
          const lng = start!.lng() + (target.lng() - start!.lng()) * progress;
          map.panTo({lat, lng});
          map.setZoom(15); // Set the zoom level to a fixed value
          if (circle && circle.getRadius() > 0) {
            const bounds = circle.getBounds();
            map.fitBounds(bounds as google.maps.LatLngBounds);
          }

          if (elapsed < duration) {
            requestAnimationFrame(animateStep);
          }
        };

        const startTimestamp = performance.now();
        requestAnimationFrame(animateStep);
      }
    }
  }, [zone, map]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleRadiusChanged = () => {
    if (circle) {
      const radiusValue = metersToMiles(circle?.getRadius());
      const oldRange = [...ranges];
      oldRange[1] = radiusValue;
      setRanges(oldRange);
    }
  };

  const handleInnerRadiusChanged = () => {
    if (innerCircle) {
      const radiusValue = metersToMiles(innerCircle?.getRadius());
      const oldRange = [...ranges];
      oldRange[0] = radiusValue;
      setRanges(oldRange);
    }
  };

  const handleCircleChanged = (data: google.maps.Circle) => setCircle(data);

  const handleCenterChange = () => {
    if (circle && zone) {
      const newLatLng = circle?.getCenter()?.toJSON();
      if (zone?.lat !== newLatLng?.lat && zone?.lng !== newLatLng?.lng) {
        circle.setCenter({lat: zone?.lat, lng: zone?.lng});
      }
    }
  };

  const handleInnerCenterChange = () => {
    if (innerCircle && zone) {
      const newLatLng = innerCircle?.getCenter()?.toJSON();
      if (zone?.lat !== newLatLng?.lat && zone?.lng !== newLatLng?.lng) {
        innerCircle.setCenter({lat: zone?.lat, lng: zone?.lng});
      }
    }
  };

  return (
    <Card elevation={0} style={{borderRadius: 8}}>
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '62vh',
        }}
        options={mapOptions}
        {...googleMapProps}
        onLoad={map => onLoad(map)}
        onUnmount={onUnmount}
      >
        {map && (
          <>
            <div
              className="map-mode-wrapper"
              style={{
                position: 'absolute',
                top: 20,
                left: 20,
                zIndex: 1,
              }}
            >
              <IconButton
                variant="filled"
                className={isEdit ? 'bg-red' : 'bg-white'}
                onClick={() => setIsEdit(true)}
                disabled={isEdit}
              >
                <MapEditIcon style={{color: isEdit ? 'white' : '#34384B'}} />
              </IconButton>
              <IconButton
                variant="filled"
                className={!isEdit ? 'bg-red' : 'bg-white'}
                onClick={() => setIsEdit(false)}
                disabled={!isEdit}
              >
                <MapNavIcon style={{color: !isEdit ? 'white' : '#34384B'}} />
              </IconButton>
            </div>
            {isEdit && (
              <div
                className="map-mode-wrapper bg-dark text-white rounded-3"
                style={{
                  position: 'absolute',
                  top: 20,
                  right: 20,
                  zIndex: 1,
                }}
              >
                <Box py={1} className="vtl-info-window">
                  <Container className="px-2">
                    <Typography
                      variant="subtitle2"
                      className="font-weight-bold mb-vtl-3"
                    >
                      <FormattedMessage id="dashboard.store_details.delivery_tab.scroll" />
                    </Typography>
                  </Container>
                </Box>
              </div>
            )}
          </>
        )}
        {/* Child components, such as markers, info windows, etc. */}
        {zone && (
          <Fragment>
            <Marker
              position={{
                lat: zone.lat,
                lng: zone.lng,
              }}
              draggable={false}
              icon={{
                url: zone.isStore ? RestaurantIcon : MarkerIcon,
                scaledSize: new google.maps.Size(30, 45),
              }}
            />
            <Circle
              ref={ref => {
                if (ref) {
                  circleRef.current = ref;
                }
              }}
              onLoad={handleCircleChanged}
              onUnmount={circle => {
                if (circle) {
                  setCircle(undefined);
                }
              }}
              radius={milesToMeters(Number(zone.toRange))}
              editable={isEdit}
              options={{
                fillColor: '#FF9C00',
                strokeColor: '#ff0000',
                draggable: false,
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
              center={{
                lat: zone.lat,
                lng: zone.lng,
              }}
              onCenterChanged={handleCenterChange}
              onRadiusChanged={handleRadiusChanged}
            />
            {zone.fromRange && zone.fromRange > 0 && (
              <Circle
                onLoad={c => setInnerCircle(c)}
                onUnmount={c => {
                  if (c) {
                    setInnerCircle(undefined);
                  }
                }}
                radius={milesToMeters(Number(zone.fromRange))}
                editable={isEdit}
                draggable={false}
                options={{
                  fillColor: '#fff',
                  strokeColor: '#ff0000',
                  draggable: false,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillOpacity: null,
                }}
                center={{
                  lat: zone.lat,
                  lng: zone.lng,
                }}
                onRadiusChanged={handleInnerRadiusChanged}
                onCenterChanged={handleInnerCenterChange}
              />
            )}
          </Fragment>
        )}
      </GoogleMap>
    </Card>
  );
};

export default React.memo(withGmaps(EditMapZone));
