import React, {useState} from 'react';
import {StoreType} from '../../types/StoreType';
import {Stack} from 'react-bootstrap';
import TextField from '../Form/Inputs/TextField';
import IconButton from '../Common/IconButton';
import {ReactComponent as RemoveIcon} from '../../assets/images/remove.svg';

type StoreTypeItemProps = {
  type: StoreType;
  onDelete: (id: number) => void;
  editStoreType: (name: string, id: number) => void;
};

const StoreTypeItem = ({type, onDelete, editStoreType}: StoreTypeItemProps) => {
  const [name, setName] = useState(type.name);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  return (
    <Stack direction="horizontal" className="gap-vtl-8">
      <TextField
        value={name}
        onChange={changeHandler}
        onBlur={e => {
          if (e.target.value.trim() !== type.name) {
            editStoreType(e.target.value, type.id);
          }
        }}
        className="mb-0 flex-fill"
      />
      <IconButton size="sm" variant="action" onClick={() => onDelete(type.id)}>
        <RemoveIcon />
      </IconButton>
    </Stack>
  );
};

export default StoreTypeItem;
