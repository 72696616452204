import React, {useState, useCallback} from 'react';
import {GoogleMap, Marker} from '@react-google-maps/api';
import withGmaps from '../Gmaps/withGmaps';

/**
 * Map View component
 * https://react-google-maps-api-docs.netlify.app
 */

type MapViewProps = {
  lat: number;
  lng: number;
  title: string;
};

const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: 120,
};

const MapView = ({lat, lng, title}: MapViewProps) => {
  const center = {
    lat,
    lng,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onLoad = useCallback((map: any) => {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      onLoad={() => onLoad(map)}
      onUnmount={onUnmount}
      options={{keyboardShortcuts: false}}
    >
      <Marker
        icon={{
          url: '/images/marker.svg',
        }}
        position={center}
        title={title}
      />
    </GoogleMap>
  );
};

export default React.memo(withGmaps(MapView));
