export const COLOR_STATUS = {
  active: 'green',
  suspended: 'secondary',
  pending: 'orange',
  deleted: 'error',
  open: 'green',
  unsuspended: 'green',
  onHold: 'secondary',
  closed: 'error',
  closedForToday: 'orange',
  preOrderAvailable: 'orange',
  enabled: 'green',
  disabled: 'secondary',
};
