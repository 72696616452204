import {yupResolver} from '@hookform/resolvers/yup';
import {useMemo, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {
  CreateUpdateVariationParam,
  useCreateProductVariationMutation,
  useDeleteProductVariationMutation,
  useUpdateProductVariationMutation,
} from '../services/productOptionGroupApi';
import {ProductVariation} from '../types/ProductVariation';
import {formatErrorMessage} from '../utils/functions';
import * as yup from 'yup';
import {DeleteParam} from '../services/storeApi';
import {ProductOption} from '../types/ProductOption';

export type ProductVariationFormValues = {
  name: string;
  description?: string;
  min?: number;
  max?: number;
};

type UseProductVariationProps = {
  selectedVariation?: ProductVariation | null;
  optionGroupId?: number | string;
  isEditMode: boolean;
  onUpdate: (variation: ProductVariation) => void;
};

export default function useProductVariation({
  optionGroupId,
  selectedVariation,
  isEditMode,
  onUpdate,
}: UseProductVariationProps) {
  // Translations
  const intl = useIntl();

  const [variationsToUpdate, setVariationsToUpdate] = useState<
    ProductVariation[]
  >([]);

  // yup validation
  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        min: yup
          .number()
          .when([], {
            is: () => !isEditMode,
            then: yup.number().nullable(),
            otherwise: yup
              .number()
              .typeError(
                intl.formatMessage({id: 'validation.error.required_field'})
              )
              .required(
                intl.formatMessage({id: 'validation.error.required_field'})
              ),
          })
          .test(
            'is-min-greater-than-max',
            intl.formatMessage({id: 'validation.error.min_greater_than_max'}),
            function (value) {
              const {max} = this.parent;
              if (max && value && value > max) {
                setError('max', {
                  message: intl.formatMessage({
                    id: 'validation.error.max_less_than_min',
                  }),
                });
                return Promise.resolve(false);
              }
              if (
                getFieldState('max').invalid &&
                max >= value! &&
                Number(max) !== 0
              ) {
                trigger('max');
              }
              return Promise.resolve(true);
            }
          ),
        max: yup
          .mixed()
          .test(
            'is-max-a-number',
            intl.formatMessage({id: 'validation.error.number'}),
            value => {
              if (value && isNaN(value)) {
                return Promise.resolve(false);
              }
              return Promise.resolve(true);
            }
          )
          .test(
            'is-max-equal-zero',
            intl.formatMessage({id: 'validation.error.max_is_zero'}),
            value => {
              if (Number(value) === 0) {
                return Promise.resolve(false);
              }
              return Promise.resolve(true);
            }
          )
          .test(
            'is-max-less-than-zero',
            intl.formatMessage(
              {id: 'validation.error.non_negative_number'},
              {value: 1}
            ),
            value => {
              if (value && value < 0) {
                return Promise.resolve(false);
              }
              return Promise.resolve(true);
            }
          )
          .test(
            'is-max-less-than-min',
            intl.formatMessage({id: 'validation.error.max_less_than_min'}),
            function (value) {
              const {min} = this.parent;
              if (value && value < min) {
                setError('min', {
                  message: intl.formatMessage({
                    id: 'validation.error.min_greater_than_max',
                  }),
                });
                return Promise.resolve(false);
              }
              if (min && getFieldState('min').invalid) {
                trigger('min');
              }
              return Promise.resolve(true);
            }
          ),
      }),
    [intl]
  );
  // form hook
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    getFieldState,
    trigger,
  } = useForm<ProductVariationFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  // prefill fields on update
  useEffect(() => {
    if (selectedVariation && isEditMode) {
      setValue('name', selectedVariation.name);
      setValue('description', selectedVariation.description ?? '');
      setValue('min', selectedVariation.min ?? 1);
      setValue('max', selectedVariation.max ?? undefined);
    } else {
      reset();
    }
  }, [selectedVariation, isEditMode]);

  // create Product variation
  const [
    createVariation,
    {isSuccess: createSuccess, isLoading: createdLoading},
  ] = useCreateProductVariationMutation();

  // Edit Product variation
  const [
    updateVariation,
    {isSuccess: updateSuccess, isLoading: updatedLoading},
  ] = useUpdateProductVariationMutation();

  // Delete Product Variation
  const [deleteVariation, {isSuccess: deleteSuccess}] =
    useDeleteProductVariationMutation();

  const submit = (formValues: ProductVariationFormValues) => {
    const maxValue = selectedVariation?.max ? selectedVariation.max : null;

    const data: CreateUpdateVariationParam = {
      body: {
        ...formValues,
        optionGroupId,
        max: maxValue,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = isEditMode
          ? intl.formatMessage(
              {
                id: 'messages.custom_update_variation',
              },
              {
                name: selectedVariation?.name,
              }
            )
          : intl.formatMessage(
              {
                id: 'messages.custom_create_variation',
              },
              {
                name: formValues?.name,
              }
            );
        return message;
      },
    };
    if (isEditMode && selectedVariation) {
      data.id = selectedVariation.id;
      // update variation
      const newVariationData: any = {
        ...selectedVariation,
        ...formValues,
        max: maxValue,
      };
      onUpdate(newVariationData);
      updateVariation(data);
    } else {
      //create variation
      createVariation(data);
    }
  };
  const addingNewVariationUpdate = (
    formValues: ProductVariationFormValues,
    editVariation: ProductVariation
  ) => {
    const updateValues: any = {...formValues};
    const {id} = editVariation ?? {};

    const min =
      !updateValues.min && updateValues.min !== 0
        ? NaN
        : Number(updateValues.min);
    const max =
      !updateValues.max && updateValues.max !== 0
        ? null
        : Number(updateValues.max);

    if (
      isNaN(min) ||
      min < 0 ||
      (max !== null && (isNaN(max) || max <= 0 || max < min))
    ) {
      setVariationsToUpdate(variationsToUpdate.filter(v => v.id !== id));
      return;
    }
    updateValues.min = min;
    updateValues.max = max;

    const newVariationData: ProductVariation = {
      ...editVariation,
      ...updateValues,
    };

    const findVariationIndex = variationsToUpdate.findIndex(v => v.id === id);

    if (isUpdatedVariationDirty(newVariationData, editVariation)) {
      if (findVariationIndex >= 0) {
        const newArrayOfVariations = [...variationsToUpdate];
        newArrayOfVariations[findVariationIndex] = newVariationData;
        setVariationsToUpdate(newArrayOfVariations);
      } else {
        setVariationsToUpdate([...variationsToUpdate, newVariationData]);
      }
    } else {
      setVariationsToUpdate(variationsToUpdate.filter(v => v.id !== id));
    }
  };

  const isUpdatedVariationDirty = (
    variation: ProductVariation,
    editVariation: ProductVariation
  ) => {
    return (
      variation.min !== editVariation?.min ||
      variation.max !== editVariation?.max
    );
  };

  const resetUpdatedVariations = () => {
    setVariationsToUpdate([]);
  };

  const updateEditedVariations = () => {
    variationsToUpdate.forEach(item => {
      updateVariation({
        id: item.id,
        body: {
          ...item,
        },
        showProgressDialog: false,
        formatErrorMessage: error => formatErrorMessage(error, intl),
        formatSuccessMessage: () => {
          const message = intl.formatMessage(
            {
              id: 'messages.custom_update_variation',
            },
            {
              name: item.name,
            }
          );
          return message;
        },
      });
    });
    resetUpdatedVariations();
  };

  const removeFromUpdatesIfExits = (item: ProductVariation) => {
    setVariationsToUpdate(variationsToUpdate.filter(v => v.id !== item.id));
  };

  // Delete Product variation
  const deleteProductVariation = (id: number) => {
    const data: DeleteParam = {
      id,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.custom_delete_variation',
          },
          {name: selectedVariation?.name ?? ''}
        );
      },
    };
    deleteVariation(data);
  };

  /**
   * The code will receive the latest variation data fetched and examine if any of it needs to be updated
   * due to a dirty state. If there are any updates to be made,
   *  the code will merge them with the current dirty state.
   */
  const updateFetchedVariationsWithEditDirtyState = (
    items: ProductVariation[],
    updateFetchedOptionsWithEditDirtyState: (
      items: ProductOption[]
    ) => ProductOption[]
  ) => {
    const newVariations: ProductVariation[] = [];
    for (const item of items) {
      const findVariationIndex = variationsToUpdate.findIndex(
        v => v.id === item.id
      );
      if (findVariationIndex < 0) {
        newVariations.push({
          ...item,
          options: updateFetchedOptionsWithEditDirtyState(item.options),
        });
      } else {
        const newItem = {
          ...item,
          min: variationsToUpdate[findVariationIndex].min,
          max: variationsToUpdate[findVariationIndex].max,
          options: updateFetchedOptionsWithEditDirtyState(item.options),
        };
        newVariations.push(newItem);
      }
    }
    return newVariations;
  };

  return {
    getValues,
    handleSubmit,
    control,
    submit,
    createSuccess,
    reset,
    updateSuccess,
    deleteProductVariation,
    deleteSuccess,
    createdLoading,
    updatedLoading,
    variationsToUpdate,
    addingNewVariationUpdate,
    resetUpdatedVariations,
    updateEditedVariations,
    removeFromUpdatesIfExits,
    updateFetchedVariationsWithEditDirtyState,
  };
}
