import {useIntl} from 'react-intl';
import {Control, UseFormHandleSubmit} from 'react-hook-form';
import {CircularProgress, Paper} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import cx from 'clsx';
import {Stack} from 'react-bootstrap';
import {ReactComponent as ArrowIcon} from '../../../../assets/images/arrow.svg';
import {Product} from '../../../../types/Product';
import {FilterActionsForm, OrderState} from '../../../../hooks/useProduct';
import {ProductCategory} from '../../../../types/ProductCategory';
import {ProductMetaType} from '../../../../services/productCategoryApi';
import Drop from '../../../Widgets/DragDrop/Drop';
import Drag from '../../../Widgets/DragDrop/Drag';
import useDraggablePlaceholder from '../../../Widgets/DragDrop/useDraggablePlaceholder';
import useStyles from '../../../Widgets/DataTable/useDataTableStyles';
import TablePagination from '../../../Widgets/DataTable/TablePagination';
import FilterActions from './FilterActions';
import ProductListRow from './ProductListRow';

/**
 * Category products list
 */

export type ProductsListProps = {
  products: Product[];
  meta?: ProductMetaType;
  isSelectedCategory: boolean;
  control: Control<FilterActionsForm, any>;
  loading: boolean;
  order: OrderState;
  orderBy: string;
  categories: ProductCategory[];
  selectedProduct: Product;
  setSelectedProduct: (product: Product) => void;
  handleSubmit: UseFormHandleSubmit<FilterActionsForm>;
  submit: (value: FilterActionsForm) => void;
  onClear: () => void;
  onImport: () => void;
  handleSort: (property: string) => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  updateProductList: (product: Product) => void;
  deleteProduct: (productId: number, name: string) => void;
  gotoProductDetailsPage: (id: string) => void;
};

const ProductsList = ({
  products,
  meta,
  isSelectedCategory,
  control,
  order,
  orderBy,
  categories,
  selectedProduct,
  setSelectedProduct,
  handleSubmit,
  submit,
  onClear,
  onImport,
  loading,
  handleSort,
  handlePageChange,
  handlePageSizeChange,
  updateProductList,
  deleteProduct,
  gotoProductDetailsPage,
}: ProductsListProps) => {
  const intl = useIntl();
  // styles
  const classes = useStyles();

  const columns = [
    {
      field: 'name',
      title: intl.formatMessage({
        id: 'dashboard.store_details.product_categories_tab.product_name',
      }),
      mobileShown: true,
      sortable: true,
    },
    {
      field: 'description',
      title: intl.formatMessage({
        id: 'dashboard.store_details.product_categories_tab.description',
      }),
      mobileShown: false,
      sortable: true,
    },
    {
      field: 'price',
      title: intl.formatMessage({
        id: 'dashboard.store_details.product_categories_tab.price',
      }),
      mobileShown: false,
      sortable: true,
    },
    {
      field: 'status',
      title: intl.formatMessage({
        id: 'dashboard.store_details.product_categories_tab.status',
      }),
      mobileShown: true,
      sortable: true,
    },
    {
      field: 'actions',
      empty: true,
      mobileShown: true,
      sortable: false,
    },
  ];

  const {placeholderProps} = useDraggablePlaceholder();

  const spinner = (
    <Paper className={classes.loadingWrapper} elevation={0}>
      <CircularProgress />
    </Paper>
  );

  return (
    <>
      {!isSelectedCategory && (
        <FilterActions
          control={control}
          handleSubmit={handleSubmit}
          submit={submit}
          onClear={onClear}
          onImport={onImport}
        />
      )}
      <Table className="products-list">
        {!isSelectedCategory && (
          <TableHead component="div">
            <TableRow component="div">
              {columns.map(headCell => (
                <TableCell
                  key={headCell.field}
                  component="div"
                  className={cx(headCell.field, {
                    'd-none d-lg-table-cell': !headCell.mobileShown,
                  })}
                  sortDirection={orderBy === headCell.field ? order : false}
                >
                  {!headCell.empty &&
                    (headCell.sortable ? (
                      <TableSortLabel
                        className="font-weight-bold"
                        IconComponent={props => <ArrowIcon {...props} />}
                        onClick={() => handleSort(headCell.field)}
                        direction={order}
                        active={orderBy === headCell.field}
                      >
                        {headCell.title}
                      </TableSortLabel>
                    ) : (
                      <TableSortLabel className="color-disabled">
                        {headCell.title}
                      </TableSortLabel>
                    ))}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        {loading ? (
          <div className="d-table-caption">{spinner}</div>
        ) : (
          <TableBody component="div">
            {isSelectedCategory ? (
              <Drop
                droppableId="product"
                type="PRODUCT"
                placeholderProps={placeholderProps}
              >
                <Stack gap={2} className="pb-2">
                  {products.map((item: Product, index: number) => (
                    <Drag
                      key={item.id}
                      draggableId={item.id.toString()}
                      index={index}
                      className={cx('draggable d-table', {
                        active: selectedProduct?.id === item.id,
                      })}
                    >
                      <ProductListRow
                        item={item}
                        isSelectedCategory={isSelectedCategory}
                        categories={categories}
                        onSelect={setSelectedProduct}
                        updateProductList={updateProductList}
                        deleteProduct={deleteProduct}
                        gotoProductDetailsPage={gotoProductDetailsPage}
                      />
                    </Drag>
                  ))}
                </Stack>
              </Drop>
            ) : (
              products.map((item: Product) => (
                <ProductListRow
                  key={item.id}
                  item={item}
                  isSelectedCategory={isSelectedCategory}
                  categories={categories}
                  onSelect={setSelectedProduct}
                  updateProductList={updateProductList}
                  deleteProduct={deleteProduct}
                  gotoProductDetailsPage={gotoProductDetailsPage}
                />
              ))
            )}
          </TableBody>
        )}
      </Table>
      {meta && (
        <TablePagination
          className={cx(classes.tablePagination, 'pagination-row')}
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={meta.itemCount}
          rowsPerPage={meta.pageSize}
          page={meta.page}
          bulkOpacity={true}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePageSizeChange}
        />
      )}
    </>
  );
};

export default ProductsList;
