import {Control, UseFormHandleSubmit} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Form} from 'reactstrap';
import {ProductCategoryFormValues} from '../../../../../hooks/useProductCategory';
import InputController from '../../../../Form/HookForm/InputController';
import TextAreaController from '../../../../Form/HookForm/TextAreaController';

/**
 * Add new category form
 */

type CreateCategoryFormProps = {
  form: string;
  control: Control<ProductCategoryFormValues>;
  submit: (formData: ProductCategoryFormValues) => void;
  handleSubmit: UseFormHandleSubmit<ProductCategoryFormValues>;
};

const CreateCategoryForm = (props: CreateCategoryFormProps) => {
  // Translations
  const intl = useIntl();
  const {control, handleSubmit, submit, form} = props;

  return (
    <Form id={form} onSubmit={handleSubmit(submit)}>
      {/* Category name */}
      <InputController
        name="name"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.edit_category.category_name',
        })}
        required
      />
      {/* Description */}
      <TextAreaController
        name="description"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.edit_category.description',
        })}
      />
    </Form>
  );
};

export default CreateCategoryForm;
