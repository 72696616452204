import {useEffect, useState} from 'react';
import clsx from 'clsx';
import {Theme, makeStyles} from '@material-ui/core';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IndicatorValue from './IndicatorValue';

/**
 * Tabs component
 */

export type TabType = {
  /**
   * Tab label
   */
  label: string;
  /**
   * Tab icon
   */
  icon?: any;
  /**
   * Tab value
   */
  value: any;
  /**
   * Indicator value
   */
  number?: any;
  /**
   * Disabled
   */
  disabled?: boolean;
  children?: any;
};

export type CustomTabProps = {
  /**
   * Custom class name
   */
  className?: string;
  /**
   * TRUE if indicator values shown
   */
  indicators: boolean;
  /**
   * Selected tab value
   */
  value: any;
  /**
   * Tab items
   */
  tabs: TabType[];
  /**
   * Current tab change handler
   */
  onChange: (value: any) => void;
  /**
   * Screen width
   */
  width: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginBottom: 23,
      marginLeft: -16,
      marginRight: -16,
      '& .MuiTabs-scroller': {
        overflowX: 'auto !important',
      },
    },
  },
  indicator: {
    height: 3,
  },
  tab: {
    textAlign: 'left',
    color: theme.palette.secondary.light,
    padding: '11px 0 13px 0',
    minWidth: 130,
    marginRight: 40,
    '&.Mui-selected': {
      outline: 'none',
    },
    '&.disabled': {
      pointerEvents: 'none',
    },
    '&.default': {
      minWidth: 65,
      padding: '3px 0px 0 0px',
      marginRight: 20,
      [theme.breakpoints.down('md')]: {
        marginLeft: 5,
        marginRight: 5,
      },
    },
    '&.Mui-selected .MuiTab-wrapper': {
      fontFamily: 'Averta Semibold',
      fontWeight: 'bold !important',
      alignItems: 'center',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 16px',
      padding: '15px 0 9px 0',
      fontSize: 14,
      '&.Mui-selected .MuiTab-wrapper': {
        fontWeight: 'normal',
      },
      '&.default': {
        minWidth: 100,
      },
    },
  },
  wrapper: {
    fontWeight: 'normal',
    lineHeight: 1.1,
  },
  labelIcon: {
    minHeight: 'unset',
    '& .MuiTab-wrapper': {
      flexDirection: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    '& .MuiChip-root': {
      display: 'inline-flex',
      marginLeft: theme.spacing(1),
      marginBottom: '0 !important',
    },
  },
}));

const CustomTabs = ({
  className,
  tabs,
  value,
  indicators = true,
  onChange,
  width,
}: CustomTabProps) => {
  const classes = useStyles();
  // current selected tab
  const [currentValue, setCurrentValue] = useState(value);

  // Tab change handler
  const handleChange = (e: object, newValue: any) => {
    setCurrentValue(newValue);
    onChange(newValue);
  };

  // Updage currentValue when value is changed from outside
  // Without it, the component doesn't work properly
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  // eslint-disable-next-line spaced-comment
  //Triggering a window resize event for align indicator
  // see here: https://github.com/mui-org/material-ui/issues/9337
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, []);

  return (
    <Tabs
      className={className}
      classes={{
        root: classes.root,
        indicator: classes.indicator,
      }}
      variant={isWidthUp('md', width) ? 'standard' : 'fullWidth'}
      value={currentValue}
      textColor="primary"
      indicatorColor="primary"
      onChange={handleChange}
    >
      {/* Render tab element */}
      {tabs.map((item: TabType) => (
        <Tab
          classes={{
            root: clsx(
              classes.tab,
              !indicators && 'default',
              item.disabled && 'disabled'
            ),
            wrapper: classes.wrapper,
            labelIcon: classes.labelIcon,
          }}
          key={`tab_${item.label}`}
          label={item.label}
          icon={
            indicators ? (
              <IndicatorValue
                value={item.number.toString()}
                variant={item.value === value ? 'primary' : 'secondary'}
              />
            ) : (
              ''
            )
          }
          value={item.value}
        />
      ))}
    </Tabs>
  );
};

CustomTabs.defaultProps = {
  className: '',
};

export default withWidth()(CustomTabs);
