import {Container} from 'react-bootstrap';
import MessagesPlayground from './playground/MessagesPlayground';
import ProgressPlayground from './playground/ProgressPlayground';
import QueryPlayground from './playground/QueryPlayground';

const Playground = () => {
  return (
    <Container fluid>
      <h1 className="mb-3">Playground</h1>
      <div className="mb-3">
        <MessagesPlayground />
      </div>
      <div className="mb-3">
        <ProgressPlayground />
      </div>
      <div className="mb-3">
        <QueryPlayground />
      </div>
    </Container>
  );
};

export default Playground;
