import React from 'react';
import {Col, Row} from 'reactstrap';
import {Stack} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import {ReactComponent as IconAdd} from '../../assets/images/add.svg';
import DataTable from '../Widgets/DataTable';
import useBuyerList from '../../hooks/useBuyerList';
import useBuyerFilter from '../../hooks/user/useBuyerFilter';
import DynamicFilter from '../Form/DynamicFilter';
import KeywordSearch from '../Form/KeywordSearch';
import {BuyerData} from '../../types/BuyerData';
import StatusIndicator from '../Common/StatusIndicator';
import {HeadCellType} from '../Widgets/DataTable/DataTableHead';
import {capitalizeFirstLetter} from '../../utils/functions';

/**
 * Buyer List
 */
const BuyerList = ({onAddClick}: any) => {
  const {items} = useBuyerFilter();
  const intl = useIntl();

  const {isLoading, data, handleRowClick, meta, tableState} = useBuyerList();

  // status indicator
  const statusColor: any = {
    active: 'green',
    suspended: 'secondary',
    closed: 'error',
    delete: 'error',
    pending: 'orange',
  };

  const renderFullName = (val: any, cn: any, rowData: any) => {
    const {row}: {row: BuyerData} = rowData;

    return (
      <div className="py-vtl-11">
        <Stack gap={1}>
          <p>
            {row?.firstName} {row?.lastName}
          </p>
        </Stack>
      </div>
    );
  };

  const renderEmail = (a: any, b: any, rowData: any) => {
    const {row}: {row: BuyerData} = rowData;

    return (
      <div className="py-vtl-11">
        <Stack gap={1}>
          <p>{row?.email}</p>
        </Stack>
      </div>
    );
  };

  const renderLocation = (a: any, b: any, rowData: any) => {
    const {row}: {row: BuyerData} = rowData;
    return (
      <div className="py-vtl-11">
        <Stack gap={1}>
          {row?.addresses
            ?.filter(address => address.isDefault)
            .map(address => (
              <p key={address.id}>{address?.city}</p>
            ))}
        </Stack>
      </div>
    );
  };

  const renderStatus = (a: any, b: any, rowData: any) => {
    const {row}: {row: BuyerData} = rowData;

    return (
      <Stack gap={1}>
        <StatusIndicator
          label={capitalizeFirstLetter(row.status?.toLocaleLowerCase())}
          color={statusColor[row.status]}
        />
      </Stack>
    );
  };

  // Header cells items
  const HeaderCells: HeadCellType[] = [
    {
      name: 'fullName',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.users.full_name'}),
      collapse: false,
      width: '33%',
      renderValue: (row: any, a, b) => renderFullName(row, a, b),
      sortable: true,
    },
    {
      name: 'location',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.users.user_location'}),
      collapse: true,
      width: '25%',
      renderValue: (row: any, a, b) => renderLocation(row, a, b),
    },
    {
      name: 'email',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.users.email_address'}),
      collapse: true,
      width: '30%',
      renderValue: (row: any, a, b) => renderEmail(row, a, b),
      sortable: true,
    },
    {
      name: 'status',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.users.status'}),
      collapse: false,
      renderValue: (row: any, a, b) => renderStatus(row, a, b),
    },
  ];

  return (
    <div className="dashboard-content">
      <Row className="align-items-center justify-content-between search-row">
        <Col className="col-xl-5 col-lg-8 d-flex">
          {/* Search */}
          <KeywordSearch
            placeholder={intl.formatMessage({
              id: 'actions.searchNameEmail',
            })}
            resetPageOnChange
          />
        </Col>
        {/* Button toolbar */}
        <Col className="col-auto d-lg-block ps-0 ps-lg-2">
          <Stack direction="horizontal" gap={2}>
            <DynamicFilter items={items} bindQueryParams resetPageOnFilter />
          </Stack>
        </Col>
      </Row>
      {/* Data table */}
      <DataTable
        className="user-list buyer"
        empty={false}
        canSelect={false}
        loading={isLoading}
        onRowClick={handleRowClick}
        headCells={HeaderCells}
        actionButton={{
          variant: 'action',
          size: 'sm',
          children: <IconAdd />,
          onClick: onAddClick,
        }}
        rows={data ?? []}
        onChange={() => {}}
        total={meta ? meta.itemCount : 100}
        handleParameterChange={tableState.handleDataTableParamChange}
        defaultOrder={tableState.orderDirection.toLowerCase() as any}
        defaultOrderBy={tableState.orderBy}
        defaultRowPerPage={tableState.pageSize}
        currentPage={tableState.page}
      />
    </div>
  );
};

export default BuyerList;
