import {Product} from './Product';
import {Store} from './Store';

export enum ProductCategoryStatus {
  On = 'on',
  Off = 'off',
  OffForToday = 'offForToday',
  deleted = 'deleted',
}

export interface ProductCategory {
  id: number;
  name: string;
  description: string;
  status: ProductCategoryStatus;
  rank: number;
  store: Store;
  products: Product[];
  createdAt: Date;
  updatedAt: Date;
}
