import {FormattedMessage} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Col, Row, Stack} from 'react-bootstrap';
import Avatar from '../../Common/Avatar';
import ArrowLink from '../../Common/ArrowLink';
import {OrderUser} from '../../../types/OrderUser';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from '../../../store/slices/authSlice';
import {UserProfileEnum} from '../../../types/User';

type BuyerInformationProps = {
  data: OrderUser;
};

const BuyerInformation = ({data}: BuyerInformationProps) => {
  const {user} = useSelector(selectUserAndToken);
  const isAdmin = user?.profileType === UserProfileEnum.Admin;
  return (
    <div className="card details-card">
      <div className="card-body">
        <Typography variant="h4" className="mb-vtl-16 mb-lg-3 card-title">
          <FormattedMessage id="order_details.buyerInformation" />
        </Typography>
        <Row className="gx-vtl-22 gx-lg-2">
          <Col xs="auto">
            <Avatar
              alt={`${data.firstName.charAt(0).toUpperCase()}${data.lastName
                .charAt(0)
                .toUpperCase()}`}
              size="xs"
              color="neutral"
            />
          </Col>
          <Col>
            <Stack gap={1}>
              {data.userId && isAdmin ? (
                <ArrowLink
                  title={`${data.firstName} ${data.lastName}`}
                  url={`/admin/users/buyer/${data.userId}/profile`}
                />
              ) : (
                <Typography>
                  {data.firstName} {data.lastName}
                </Typography>
              )}
              <Typography>{data.email}</Typography>
              <Typography>{data.phone}</Typography>
            </Stack>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BuyerInformation;
