import {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {Helmet} from 'react-helmet';
import {FormattedMessage, useIntl} from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import {Container, Row, Col} from 'reactstrap';
import {Stack} from 'react-bootstrap';
import {ReactComponent as IconAdd} from '../../assets/images/add.svg';
import useTitle from '../../hooks/useTitle';
import Button from '../Common/Button';
import DataTable from '../Widgets/DataTable';
import AddPopup from '../Widgets/AddPopup';
import CreateStoreForm from './Form/CreateStoreForm';
import useStoreList from '../../hooks/useStoreList';
import PermissionContainer from '../Common/Permission/PermissionContainer';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from '../../store/slices/authSlice';
import DynamicFilter from '../Form/DynamicFilter';
import useStoreFilter from '../../hooks/store/userStoreFilter';
import KeywordSearch from '../Form/KeywordSearch';
import {HeadCellType} from '../Widgets/DataTable/DataTableHead';
import StatusIndicator from '../Common/StatusIndicator';
import {StoreStatusValue} from '../../utils/status';
import {Store} from '../../types/Store';

/** Store list */
const StoreList = () => {
  // Translations
  const intl = useIntl();
  // Page title
  const {intlTitle} = useTitle();

  // Add user popup
  const [addPopup, setAddPopup] = useState(false);
  const {user} = useSelector(selectUserAndToken);

  const {isLoading, data, handleRowClick, meta, tableState} = useStoreList();

  // close Add Seller Popup after successful creation
  const closePopup = () => {
    setAddPopup(false);
  };

  // status indicator
  const statusColor: any = {
    active: 'green',
    open: 'green',
    suspended: 'secondary',
    onHold: 'secondary',
    closed: 'error',
    delete: 'error',
    closedForToday: 'orange',
    preOrderAvailable: 'orange',
  };

  const renderFullName = (val: any, cn: any, rowData: any) => {
    const {row}: {row: Store} = rowData;

    return (
      <div className="py-vtl-11">
        <Stack gap={1}>
          <p>{row?.name}</p>
        </Stack>
      </div>
    );
  };

  const renderEmail = (a: any, b: any, rowData: any) => {
    const {row}: {row: Store} = rowData;

    return (
      <div className="py-vtl-11">
        <Stack gap={1}>
          <p>{row?.email}</p>
        </Stack>
      </div>
    );
  };

  const renderLocation = (a: any, b: any, rowData: any) => {
    const {row}: {row: Store} = rowData;
    return (
      <div className="py-vtl-11">
        <Stack gap={1}>
          <p>
            {row.address?.postCode}, {row.address?.city}
          </p>
        </Stack>
      </div>
    );
  };

  const renderStatus = (a: any, b: any, rowData: any) => {
    const {row}: {row: Store} = rowData;

    return (
      <Stack gap={1}>
        <StatusIndicator
          label={StoreStatusValue[row.status]}
          color={statusColor[row.status]}
        />
      </Stack>
    );
  };

  // Header cells items
  const HeaderCells: HeadCellType[] = [
    {
      name: 'name',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.stores.store_name'}),
      collapse: false,
      width: '33%',
      renderValue: (row: any, a, b) => renderFullName(row, a, b),
      sortable: true,
    },
    {
      name: 'location',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.stores.location'}),
      collapse: true,
      width: '25%',
      renderValue: (row: any, a, b) => renderLocation(row, a, b),
    },
    {
      name: 'email',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.stores.email_address'}),
      collapse: true,
      width: '30%',
      renderValue: (row: any, a, b) => renderEmail(row, a, b),
      sortable: true,
    },
    {
      name: 'status',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'dashboard.stores.status'}),
      collapse: false,
      renderValue: (row: any, a, b) => renderStatus(row, a, b),
    },
  ];

  // Top Header
  const header = (
    <>
      {/* bigger padding bottom if tabs not displayed */}
      <div className="row align-items-center header-row pb-4">
        <div className="col-lg-6 col-sm-12 text-center text-lg-start">
          {/* Store list */}
          <Typography variant="h3" className="font-weight-bolder">
            <FormattedMessage id="dashboard.stores.title" />
          </Typography>
        </div>
        <PermissionContainer permissions={['create-store']}>
          <div className="col-lg-6 col-sm-12 text-center text-lg-end d-none d-lg-block">
            {/* Add Store */}
            <Button
              primary
              title={intl.formatMessage({
                id: 'dashboard.stores.add_store',
              })}
              className="dashboard-btn"
              icon={<AddIcon />}
              onClick={() => setAddPopup(true)}
            />
          </div>
        </PermissionContainer>
      </div>
    </>
  );

  const {items} = useStoreFilter();

  return (
    <>
      <PermissionContainer permissions={['filter-store']}>
        <AddPopup
          opened={addPopup}
          onClose={() => setAddPopup(false)}
          title={intl.formatMessage({id: 'dashboard.stores.add_store'})}
          activeTab="store"
        >
          <CreateStoreForm form="store-form" closePopup={closePopup} />
        </AddPopup>
      </PermissionContainer>
      <Helmet>
        <title>{intlTitle('dashboard.stores.title')}</title>
      </Helmet>
      <div className="dashboard-wrapper">
        <Container fluid>
          {header}
          {/* Filters */}
          <PermissionContainer permissions={['filter-store']}>
            <Row className="align-items-center justify-content-between search-row py-lg-2">
              <Col className="col-xl-5 col-lg-8 d-flex">
                {/* Search */}
                <KeywordSearch
                  placeholder={intl.formatMessage({
                    id: 'actions.searchNameEmail',
                  })}
                  resetPageOnChange
                />
              </Col>
              {/* Button toolbar */}
              <Col className="col-auto d-lg-block ps-0 ps-lg-2">
                <Stack direction="horizontal" gap={2}>
                  <DynamicFilter
                    items={items}
                    bindQueryParams
                    resetPageOnFilter
                  />
                </Stack>
              </Col>
            </Row>
          </PermissionContainer>
          {/* Table */}
          <DataTable
            className="store-list pt-lg-2"
            empty={false}
            loading={isLoading}
            onRowClick={handleRowClick}
            headCells={HeaderCells}
            actionButton={{
              variant: 'action',
              size: 'sm',
              children: <IconAdd />,
              onClick: () => setAddPopup(true),
            }}
            rows={data ?? []}
            onChange={() => {}}
            total={meta ? meta.itemCount : 100}
            handleParameterChange={tableState.handleDataTableParamChange}
            defaultOrder={tableState.orderDirection.toLowerCase() as any}
            defaultOrderBy={tableState.orderBy}
            defaultRowPerPage={tableState.pageSize}
            currentPage={tableState.page}
            hasPermission={user && user.profileType === 'seller' ? false : true}
          />
        </Container>
      </div>
    </>
  );
};

export default StoreList;
