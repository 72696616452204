import {Permission} from './Permission';
import {ProfileType} from './ProfileType';

export enum UserStatus {
  Pending = 'pending',
  Active = 'active',
  Suspended = 'suspended',
  Deleted = 'deleted',
}

export interface User {
  id: number;
  username?: string;
  email: string;
  firstName: string;
  lastName: string;
  profileType: ProfileType;
  lastLoginAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  avatarUrl?: string;
  phone?: string;
  refreshToken?: string;
  status: UserStatus;
  permissions: Permission[];
}

export enum UserProfileEnum {
  Admin = 'admin',
  Buyer = 'buyer',
  Seller = 'seller',
}
