import {FormGroup} from 'reactstrap';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  ControllerProps,
  UseFormSetError,
  UseFormClearErrors,
  FieldErrors,
} from 'react-hook-form';
import cx from 'clsx';
import HintTooltip from '../../Common/HintTooltip';
import ImageDropzone from './DropzoneController/ImageDropzone';
import FileDropzone from './DropzoneController/FileDropzone';
import {ValidFileOptions} from '../../../types/valid-file';
import AppKeyDropzone from './DropzoneController/AppKeyDropzone';

/**
 * Dropzone controller with preview
 */

export type DropzoneControllerProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  showLabelHint?: boolean;
  label?: string;
  required?: boolean;
  labelHintText?: string;
  className?: string;
  qr?: boolean;
  format?: 'image' | 'spreadsheet' | 'appKey';
  sizes?: any;
  fitImage?: boolean;
  isTallImage?: boolean;
  compact?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  canCancel?: boolean;
  url?: string;
  uploadImage?: (imageData: File) => void;
  deleteImage?: () => void;
  cancelUpload?: () => void;
  uploadSuccess?: boolean;
  deleteSuccess?: boolean;
  cannotUpload?: boolean;
  hasProgress?: boolean;
  progress?: number;
  validFileOptions?: ValidFileOptions;
  setError?: UseFormSetError<any>;
  clearError?: UseFormClearErrors<any>;
  errors?: FieldErrors<any>;
  accept?: any;
} & Omit<ControllerProps<T>, 'name' | 'render' | 'control'>;

const DropzoneController = <T extends FieldValues>({
  name,
  control,
  showLabelHint,
  label,
  required,
  labelHintText,
  className,
  qr,
  format = 'image',
  sizes,
  compact,
  canEdit,
  canDelete,
  url,
  uploadImage,
  deleteImage,
  uploadSuccess,
  deleteSuccess,
  validFileOptions,
  setError,
  clearError,
  errors,
  accept,
  cannotUpload,
  hasProgress,
  progress,
  canCancel,
  cancelUpload,
  fitImage,
  isTallImage,
}: DropzoneControllerProps<T>) => {
  return (
    <Controller
      render={({field: {onChange}, fieldState: {error}}) => (
        <FormGroup className={cx('dropzone', className)}>
          {showLabelHint ? (
            <div className="label-with-hint d-flex">
              <span className="small-label pb-2 pe-1">
                {label}
                {required ? '*' : ''}
              </span>
              <HintTooltip id={`hint_${label}`} content={labelHintText} />
            </div>
          ) : (
            label && (
              <span className="small-label pb-2">
                {label}
                {required ? '*' : ''}
              </span>
            )
          )}
          {format === 'image' && (
            <ImageDropzone
              sizes={sizes}
              qr={qr}
              compact={compact}
              canEdit={canEdit}
              canDelete={canDelete}
              label={label}
              onChange={(e: any) => onChange(e[0])}
              url={url}
              uploadImage={uploadImage}
              name={name}
              deleteImage={deleteImage}
              uploadSuccess={uploadSuccess}
              deleteSuccess={deleteSuccess}
              validFileOptions={validFileOptions}
              setError={setError}
              clearError={clearError}
              errors={errors}
              cannotUpload={cannotUpload}
              hasProgress={hasProgress}
              progress={progress}
              canCancel={canCancel}
              cancelUpload={cancelUpload}
              fitImage={fitImage}
              isTallImage={isTallImage}
            />
          )}
          {format === 'spreadsheet' && (
            <FileDropzone
              validFileOptions={validFileOptions}
              onChange={(e: any) => onChange(e[0])}
              name={name}
              errors={errors}
              setError={setError}
              clearError={clearError}
            />
          )}
          {format === 'appKey' && (
            <AppKeyDropzone
              validFileOptions={validFileOptions}
              onChange={(e: any) => onChange(e[0])}
              name={name}
              errors={errors}
              setError={setError}
              clearError={clearError}
              accept={accept}
              deleteSuccess={deleteSuccess}
            />
          )}

          {error && (
            <span style={{color: '#FCA800'}} className="error">
              {error.message}
            </span>
          )}
        </FormGroup>
      )}
      name={name}
      control={control}
    />
  );
};

export default DropzoneController;
