import {yupResolver} from '@hookform/resolvers/yup';
import {useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import * as yup from 'yup';

export type AppGeneralFormValues = {
  [key: string]: any;
  name: string;
  store: string;
  version: string;
  packageName: string;
  description: string;
  template: string;
  supportUrl: string;
  privacyPolicyUrl: string;
};

const useAppDetailsFormRequest = () => {
  const intl = useIntl();

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        store: yup
          .string()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        version: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        packageName: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        description: yup.string().trim(),
        template: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        supportUrl: yup.string().trim(),
        privacyPolicyUrl: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    formState,
  } = useForm<AppGeneralFormValues>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  return {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    formState,
  };
};

export default useAppDetailsFormRequest;
