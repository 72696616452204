import React from 'react';
import {useIntl} from 'react-intl';
import useDeliveryConfig from '../../../../hooks/useDeliveryConfig';
import CurrencyController from '../../../Form/HookForm/CurrencyController';
import SaveFeature from '../../../Shared/SaveFeature';

const DeliveryConfigForm = () => {
  const intl = useIntl();
  const {control, count, cancelSave, handleSubmit, submit} =
    useDeliveryConfig();
  return (
    <>
      {count > 0 && (
        <SaveFeature
          onClose={cancelSave}
          open
          keyType="delivery-config"
          num={count}
          type="submit"
          form="upsert-delivery-config-form"
        />
      )}
      <form id="upsert-delivery-config-form" onSubmit={handleSubmit(submit)}>
        <CurrencyController
          name="defaultCollectionTime"
          control={control}
          className="mb-0"
          prefixLabel={intl.formatMessage({
            id: 'actions.min',
          })}
          prefixRight
          allowDecimals={false}
        />
      </form>
    </>
  );
};

export default DeliveryConfigForm;
