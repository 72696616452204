import {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDropzone} from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import {
  UseFormSetError,
  UseFormClearErrors,
  FieldErrors,
} from 'react-hook-form';
import {ValidFileOptions} from '../../../../types/valid-file';
import {ReactComponent as SpreadsheetIcon} from '../../../../assets/images/spreadsheet.svg';
import {Stack} from 'react-bootstrap';

/**
 * XLS file dropzone component
 */

const FileDropzone = ({
  onChange,
  validFileOptions,
  setError,
  clearError,
  name,
}: {
  onChange: any;
  validFileOptions?: ValidFileOptions;
  setError?: UseFormSetError<any>;
  clearError?: UseFormClearErrors<any>;
  errors?: FieldErrors<any>;
  name: string;
  required?: boolean;
}) => {
  const intl = useIntl();
  const [file, setFile] = useState<File>();
  const onDrop = useCallback((acceptedFiles: any) => {
    onChange(acceptedFiles);
    setFile(acceptedFiles[0]);
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'application/xls': ['.xls', '.xlsx', '.csv'],
    },
    maxFiles: 1,
    onDrop,
    maxSize: validFileOptions ? validFileOptions.maxSize : undefined,
    validator: fileValidator,
  });

  function fileValidator(file: File) {
    if (!validFileOptions) {
      return null;
    }
    const maxSize = validFileOptions.maxSize;
    const allowedTypes = validFileOptions.type;
    if (file.size > maxSize) {
      setError?.(`${name}`, {
        type: 'custom',
        message: 'The image is too large. It needs to be under 10Mb.',
      });
      return {
        code: 'file-too-large',
        message: 'The image is too large. It needs to be under 10Mb.',
      };
    }

    if (!allowedTypes.includes(file.type)) {
      setError?.(`${name}`, {
        type: 'custom',
        message: 'Wrong format. Only xls, xlsx, csv are accepted.',
      });
      return {
        code: 'wrong-format',
        message: 'Wrong format. Only xls, xlsx, csv are accepted.',
      };
    }
    clearError?.(`${name}`);
    return null;
  }

  return (
    <>
      <div className="dropzone-field" {...getRootProps()}>
        <input {...getInputProps(onChange)} />
        <Typography
          className="text-placeholder text-center"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({id: 'actions.import_file_placeholder'}),
          }}
        />
      </div>
      {file && (
        <Stack className="pt-3" gap={2} direction="horizontal">
          <SpreadsheetIcon />
          <Typography color="secondary">{file?.name}</Typography>
        </Stack>
      )}
    </>
  );
};

export default FileDropzone;
