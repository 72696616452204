import {createStyles, makeStyles, Theme} from '@material-ui/core';

/* Data Table styles */
const useDataTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 20,
      '& .MuiTableContainer-root': {
        // overflow: 'hidden',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
        paddingTop: 2,
      },

      '&.product-list': {
        '& .pagination-row': {
          marginTop: 34,
        },
      },
    },
    loadingWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '15vh',
      background: 'none',
    },
    emptyWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '50vh',
    },
    table: {
      borderCollapse: 'collapse',
      maxWidth: '100%',
    },
    tableRow: {
      '&.Mui-selected': {
        backgroundColor: theme.palette.background.paper,
      },
      '&.expanded': {
        '& td': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
    subtable: {
      tableLayout: 'fixed',
      display: 'inline-table',
      width: '100%',
    },
    paginationSubtable: {
      '&.MuiTableFooter-root': {
        display: 'block',
        paddingTop: theme.spacing(0.75),
        backgroundColor: '#F5F6F7',
        borderRadius: theme.spacing(0, 0, 0.8, 0.8),
        '& .pagination-row': {
          margin: 0,
          borderTop: '1px solid rgba(154, 165, 177, 0.50)',
          padding: theme.spacing(1.5, 2),
        },
      },
    },
    progressBarWrapper: {
      borderBottom: 'none',
      '& .MuiLinearProgress-root': {
        marginTop: -3,
        marginLeft: -5,
        marginRight: -5,
      },
      [theme.breakpoints.down('md')]: {
        '& .MuiLinearProgress-root': {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    collapsableTableCell: {
      borderBottom: 'none',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      },
    },
    headCell: {
      fontFamily: 'Averta Semibold',
      fontSize: 14,
      borderBottom: 'none',
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      paddingLeft: 20,
      lineHeight: 1.2,
      paddingBottom: 10,
      [theme.breakpoints.down('lg')]: {
        width: '1%',
      },
      '&.MuiTableCell-paddingCheckbox': {
        paddingLeft: 5,
        [theme.breakpoints.down('md')]: {
          width: '1%',
        },
      },
      '&.MuiTableCell-paddingNone': {
        paddingLeft: '0 !important',
        [theme.breakpoints.down('lg')]: {
          width: 'inherit',
        },
      },
      '&.action-wrapper': {
        paddingRight: 0,
        [theme.breakpoints.down('md')]: {
          width: '1%',
        },
      },
      '& .MuiTableSortLabel-icon': {
        color: `${theme.palette.text.primary} !important`,
        fontSize: '15px !important',
        marginLeft: '8px !important',
        marginRight: '8px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontWeight: 'normal',
        height: 56,
        '& > .MuiTableSortLabel-root': {
          '&.color-disabled': {
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    wrappedRow: {},
    tableCell: {
      borderCollapse: 'unset',
      borderColor: theme.palette.background.default,
      borderWidth: 0,
      backgroundColor: theme.palette.background.paper,
      height: 50,
      paddingLeft: 20,
      '&.MuiTableCell-paddingCheckbox': {
        paddingLeft: 5,
        paddingTop: 9,
        verticalAlign: 'text-top',
        [theme.breakpoints.down('md')]: {
          width: '1%',
        },
      },
      '&.wrapped-row-cell': {
        paddingTop: 5,
        paddingBottom: 5,
        position: 'relative',
        '&:first-child': {
          paddingLeft: 30,
        },
        '&.headers': {
          borderRadius: 0,
          paddingBottom: 0,
          backgroundColor: '#eceef0',
          '&:first-child': {
            borderTopLeftRadius: theme.spacing(0.8),
          },
          '&:last-child': {
            borderTopRightRadius: theme.spacing(0.8),
          },
        },
        '&.cells': {
          borderRadius: 0,
          paddingTop: 7.5,
          paddingBottom: 7.5,
          backgroundColor: '#F5F6F7',
          height: '40px',
        },
      },
      '&.subtable-wrapper': {
        padding: theme.spacing(0, 2, 2, 2),
        borderRadius: `${theme.spacing(0, 0, 1, 1)} !important`,
        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(1),
        },
      },
      '&.bulk-actions': {
        paddingTop: 21,
        paddingBottom: 21,

        '&:first-child': {
          paddingLeft: 55,
        },
        '&:last-child': {
          paddingRight: 24,
        },
        [theme.breakpoints.down('lg')]: {
          paddingBottom: 12,
          '&:first-child': {
            paddingLeft: 16,
          },
        },
      },
      '&:first-child': {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
      '&:nth-child(2)': {
        cursor: 'pointer',
      },
      '&:nth-last-child(2)': {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
      [theme.breakpoints.down('lg')]: {
        '&:nth-last-child(2)': {
          paddingRight: 6,
        },
      },
      [theme.breakpoints.down('md')]: {
        borderWidth: 0,
        '&:first-child': {
          borderRadius: 0,
        },
        '&:nth-last-child(2)': {
          borderRadius: 0,
          paddingRight: 0,
          width: '1%',
        },
        '&:last-child': {
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
          paddingRight: '10px !important',
        },
        '&.bold': {
          '& > p': {
            fontWeight: 'bold',
          },
        },
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tablePagination: {
      marginTop: 35,
      marginBottom: 5,
    },
    collapseWrapper: {
      padding: '0 20px 16px 0',
      backgroundColor: theme.palette.background.paper,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      boxShadow: theme.shadows[2],
      '&.bulk': {
        paddingLeft: 16,
        paddingBottom: 24,
      },
    },
    collapsableRow: {
      display: 'flex',
      paddingLeft: 45,
    },
    collapsableRowItem: {
      flexBasis: '50%',
      overflow: 'hidden',
      fontSize: 11,
      '& h6': {
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 11,
        paddingTop: 0,
      },
    },
    expandIcon: {
      color: theme.palette.text.primary,
      fontSize: 23,
    },
    tableContainer: {
      position: 'relative',
    },
    tableLoadingWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    subTableLoadingWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export default useDataTableStyles;
