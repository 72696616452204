import {FeedbackParams} from '../store/queries/common';
import {Setting, SettingData} from '../types/Setting';
import {baseApi} from './baseApi';

export type SettingResponse = {
  data: SettingData;
};

export type GetSettingParam = {
  slug: string;
} & FeedbackParams;

export type SaveSettingParams = {
  body: {
    setting: Setting;
    slug: string;
  };
} & FeedbackParams;

export const settingApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getSetting: builder.query<SettingResponse, GetSettingParam>({
      query: ({slug}: GetSettingParam) => ({
        url: `/setting/${slug}`,
        method: 'GET',
      }),
      providesTags: ['Setting'],
    }),
    saveSetting: builder.mutation<SettingResponse, SaveSettingParams>({
      query: ({body}: SaveSettingParams) => ({
        url: '/setting',
        method: 'POST',
        body,
      }),
      // @ts-ignore
      invalidatesTags: result => (result ? ['Setting'] : ['Nothing']),
    }),
  }),
});

export const {useGetSettingQuery, useSaveSettingMutation} = settingApi;
