import {useIntl} from 'react-intl';
import {ReactComponent as EllipsisIcon} from '../../../../assets/images/ellipsis.svg';
import Dropdown, {ItemType} from '../../../Common/Dropdown';
import {Product} from '../../../../types/Product';
import {ProductCategory} from '../../../../types/ProductCategory';
import {useState} from 'react';
import ConfirmationPopup from '../../../Shared/ConfirmationPopup';
import {FormState} from './ProductListRow';

/**
 * Product menu actions
 */

type ProductMenuActionsProps = {
  product: Product;
  categories: ProductCategory[];
  updateProduct: (text: string | number, name: keyof FormState) => void;
  deleteProduct: (productId: number, name: string) => void;
  gotoProductDetailsPage: (id: string) => void;
};

const ProductMenuActions = ({
  product,
  categories,
  updateProduct,
  deleteProduct,
  gotoProductDetailsPage,
}: ProductMenuActionsProps) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  // Delete product action
  const deleteProductData = () => {
    setOpen(false);
    deleteProduct(product.id, product.name);
  };

  const menuActions: ItemType[] = [
    {
      label: intl.formatMessage({
        id: 'dashboard.store_details.product_categories_tab.menu_actions.goToProduct',
      }),
      onClick: () => gotoProductDetailsPage(product.id.toString()),
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.store_details.product_categories_tab.menu_actions.moveCategory',
      }),
      submenu: categories?.map(item => ({
        id: item.id,
        label: item.name,
        onClick: () => updateProduct(item.id, 'categoryId'),
      })),
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.store_details.product_categories_tab.menu_actions.deleteProduct',
      }),
      onClick: () => setOpen(true),
    },
  ];

  return (
    <>
      {/* Delete product confirmation */}
      <ConfirmationPopup
        opened={open}
        handleClose={() => setOpen(false)}
        onOk={deleteProductData}
        description={intl.formatMessage({
          id: 'messages.delete_message',
        })}
      />
      <Dropdown
        menuIcon={
          <div>
            <EllipsisIcon />
          </div>
        }
        items={menuActions}
        value={product?.category?.id}
      />
    </>
  );
};

export default ProductMenuActions;
