import {Theme, withStyles} from '@material-ui/core';
import cx from 'clsx';
import Alert from '@material-ui/lab/Alert';

export default withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.8),
    marginBottom: theme.spacing(0.8),
    padding: theme.spacing(0),
    overflow: 'hidden',
    '&.backdrop': {
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        opacity: 0.6,
        zIndex: 1,
        [theme.breakpoints.up('lg')]: {
          left: 240,
        },
      },
    },
    '& .MuiAlert-message': {
      position: 'relative',
      flex: '1 0',
      padding: theme.spacing(0),
      zIndex: 2,
    },
    '& .MuiAlert-icon': {
      display: 'none',
    },
  },
}))(({classes, children, withBackdrop}: any) => (
  <Alert classes={classes} className={cx({backdrop: withBackdrop})}>
    {children}
  </Alert>
));
