import {get, isEmpty} from 'lodash';
import moment from 'moment-timezone';
import {IntlShape} from 'react-intl';
import {Color} from '../types/Color';
import {ValidFileOptions} from '../types/valid-file';
import {COLOR_STATUS} from './color';
import BigNumber from 'bignumber.js';

/**
 * Flatted messages for react-intl
 * input {"a":{"b":"c"}}
 * output {"a.b":"c"}
 * @param {*} nestedMessages
 * @param {*} prefix
 */
export const flattenMessages = (nestedMessages: any, prefix = '') => {
  if (nestedMessages === null) {
    return {};
  }

  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, {[prefixedKey]: value});
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

/**
 * Turn an Object into Query String Parameters
 * input {"a": 1, "b": 2, "c": 3, }
 * output "a=1&b=2&c=3"
 * @param {*} queries
 */
export const getURIQuery = (queries: any) => {
  if (isEmpty(queries)) {
    return '';
  }

  return Object.keys(queries)
    .map(
      key => `${encodeURIComponent(key)}=${encodeURIComponent(queries[key])}`
    )
    .join('&');
};

/**
 * Checks whether a supplied link URI refers to an external host.
 * Accepts arbitrary types, but will return false unless the URI is a string and points to a host outside the frontend.
 * @param uri The URI to check
 * @returns
 */
export const isExternalURI = (uri: any) => {
  const frontendDomain = process.env.NEXT_PUBLIC_FRONTEND_URL;
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const absoluteURL = new URL(uri, `https://${frontendDomain}`).href;

  if (frontendDomain !== undefined && typeof uri === 'string') {
    return !(absoluteURL.split(/[/?#]/)[2] === frontendDomain);
  }
  return false;
};

export const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

/**
 * Map server error to form field
 * @param {any} error
 * @param {any} setError
 * @returns {void}
 */
export const mapErrorToFormFields = (error: any, setError: any) => {
  for (const [key, value] of Object.entries(error)) {
    //@ts-ignore. @ts-expect-error
    setError(`${key}`, {type: 'custom', message: value[0]});
  }
};

/**
 * Capitalize first letter
 * @param {string} word
 * @returns {string}
 */
export const capitalizeFirstLetter = (word: string): string => {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : '';
};

/**
 * Drag-and-drop helper functions
 * @param {any} data
 * @returns {any}
 */
export const clone = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export function timeMask(value: string) {
  const chars = value.split('');
  const hours = [/[0-2]/, chars[0] === '2' ? /[0-3]/ : /[0-9]/];

  const minutes = [/[0-5]/, /[0-9]/];

  return [...hours, ':', ...minutes];
}

/**
 * Format Date to timezone
 * @param {Date} date
 * @returns {string}
 */
export const formatDate = (date: Date): string => {
  return moment.tz(date, 'GMT').format('MMM DD, YYYY - HH:mm (z)');
};

/**
 * Format date to 23/12/2023 12:09 AM
 * @param {Date} date
 * @returns {string}
 */
export const formatDateToMonthAndTime = (date: Date): string => {
  return moment(date).format('DD/MM/YYYY hh:mm A');
};

/**
 * Format date to 23 Mon 2023 14:00
 * @param {Date} date
 * @returns {string}
 */
export const formatDateToMonth = (date: Date): string => {
  return moment(date).format('DD MMM YYYY hh:mm A');
};

/**
 * Get color by COLOR_STATUS
 * @param {keyof typeof COLOR_STATUS} status
 * @returns {Color}
 */
export const getColor = (status: keyof typeof COLOR_STATUS): Color => {
  return COLOR_STATUS[status] as Color;
};

/**
 * Remove blank space between names
 * @param {string} value
 * @returns {string}
 */
export const trimString = (value: string): string =>
  value.replace(/\s/g, '').toLocaleLowerCase();

/**
 * Compare array length
 * @param {any[]} arr1
 * @param {any[]} arr2
 * @returns {boolean}
 */
export const compareArrayLengths = (arr1: any[], arr2: any[]): boolean => {
  return arr1.length !== arr2.length;
};

/**
 * Open link in new tab
 * @param {string} url
 * @returns {void}
 */
export const openInNewTab = (url: string) => {
  if (!url) {
    return;
  }
  window.open(url, '_blank');
};

/**
 * Open external link in new tab
 * @param {string} url
 * @returns {void}
 */
export const openExternalLink = (url: string) => {
  if (!url) {
    return;
  }
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url;
  }
  window.open(url, '_blank');
};

/**
 * Format error message
 * @param {any} error
 * @param {IntlShape} intl
 * @returns {string}
 */
export const formatErrorMessage = (error: any, intl: IntlShape): string => {
  let errorMessage = '';
  // Validation messages are handled inside form components
  if (error?.data?.error === 'Validation Error') {
    return errorMessage;
  }
  // For normal backend errors that have messages in the app
  if (error?.data?.message) {
    errorMessage = intl.formatMessage({
      id: `error.${error.data.message}`,
    });
    // For unknown and server errors
  } else {
    errorMessage = intl.formatMessage({id: 'error.server_error'});
  }

  return errorMessage;
};

/**
 * Remove seconds from time string
 *
 * @param {string} timeString
 * @returns {string}
 */
export const getTimeWithoutSeconds = (timeString: string) => {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes] = timeString.split(':');

  // Return the time without seconds
  return `${hours}:${minutes}`;
};

export const validImageOptions: ValidFileOptions = {
  type: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/tiff'],
  maxSize: 1024 * 1024 * 3,
};

export const validSpreadsheetOptions: ValidFileOptions = {
  type: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.csv',
    'text/csv',
    'application/vnd.ms-excel',
    'application/csv',
    'text/x-csv',
    'application/x-csv',
    'text/comma-separated-values',
    'text/x-comma-separated-values',
  ],
  maxSize: 1024 * 1024 * 10,
};

export const validAppKeyOptions: ValidFileOptions = {
  type: ['application/pkcs8', 'application/java-keystore', 'keystore', 'p8'],
  maxSize: 1024 * 1024 * 0.5,
};

/**
 * Get form active fields
 * @param {[key: string]: any} values
 * @returns {string[]}
 */
export const getFormActiveFields = (values: {[key: string]: any}): string[] => {
  if (typeof values !== 'object') {
    return [];
  }

  const res: string[] = [];

  Object.keys(values)
    .filter(k => values[k] !== null && values[k] !== undefined)
    .forEach(field => {
      const value = get(values, field);
      let active = false;
      if (Array.isArray(value)) {
        active = value.length > 0;
      }
      if (typeof values === 'object') {
        if (
          ['IsEqual', 'IsBetween', 'LessThan', 'GreaterThan'].includes(
            value.type
          )
        ) {
          active =
            Object.keys(value).filter(v => v !== 'type' && value[v]).length > 0;
        } else {
          active =
            Object.values(value).filter(v => v !== null && v !== undefined)
              .length > 0;
        }
      } else {
        active = value !== null && value !== undefined;
      }

      if (active) {
        res.push(field);
      }
    });

  return res;
};

/**
 * Parse URL to get parameters
 * @param {URLSearchParams} searchParams
 * @returns {URLSearchParams}
 */
export const getUrlSearchParams = (
  searchParams: URLSearchParams
): URLSearchParams => {
  try {
    const windowSearchParams = new URLSearchParams(window.location.search);

    if (searchParams.toString() !== windowSearchParams.toString()) {
      return windowSearchParams;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return new URLSearchParams(searchParams.toString());
};

// validate url
export const urlRegex = /^[a-zA-Z0-9~_\-?=&/.]+$/;

//Validate a url with/without http(s)
export const urlRegexWithHttp =
  /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9~_\-?=&/.]+$|^$/;

//Validate a url with/without http(s)
export const urlHttpRegex =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

// validate price as number
export const isNumber = (input: any) => {
  const numberRegex = /^\d+(\.\d+)?$/;
  return numberRegex.test(input);
};

/**
 * Check if destination or source changes for draggable list
 * @param {any} source
 * @param {any} destination
 * @returns {boolean}
 */
export const onChange = (source: any, destination: any): boolean => {
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return true;
  }
  return false;
};

/**
 * Set to two decimal places for decimals with ending zero
 * Using BigNumber ensure the numbers are precise and avoid unexpected rounding
 *
 * @param {number} amount
 * @returns {string}
 */
export const twoDecimalPlaces = (amount: number): string => {
  // Check if the number is an integer
  if (Number.isInteger(amount)) {
    return amount.toFixed(2);
  }

  // For decimal numbers
  BigNumber.config({ROUNDING_MODE: BigNumber.ROUND_DOWN});
  const bigNumber = new BigNumber(amount);
  return bigNumber.toFixed(2);
};

/**
 * Format Date in format {23 Nov 2023}
 *
 * @param {Date} date
 * @returns {string}
 */
export const formatDateInMonth = (date: Date) =>
  moment.tz(date, 'GMT').format('D MMM YYYY');

/**
 * combine Date & Time in format {23-11-2023} {00:00:00}
 *
 * @param {string} date
 * @param {string} time
 * @returns {Date}
 */
export const combineDateTime = (date: string, time: string): Date => {
  const dateTime = moment
    .tz(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss', 'GMT')
    .format();
  return new Date(dateTime);
};

/**
 * Convert meters to miles
 * @param {number} meters
 * @returns {number}
 */
export const metersToMiles = (meters: number): number => {
  const conversionFactor = 0.000621371192;
  const miles = meters * conversionFactor;
  return Number(miles.toFixed(2));
};

/**
 * Convert miles to meters
 * @param {number} miles
 * @returns {number}
 */
export const milesToMeters = (miles: number): number => {
  const conversionFactor = 1609.344;
  const meters = miles * conversionFactor;
  return Number(meters.toFixed(2));
};

export const allowedStoreUrlPatterns = /^[0-9a-z-]+$/;

/**
 * Check key exists in object
 * @param {Object} obj
 * @param {string} targetKey
 * @returns {boolean}
 */
export const isKeyMatching = (obj: Object, targetKey: string): boolean => {
  for (const key in obj) {
    if (key === targetKey) {
      return true; // Key matches the target string
    }
  }
  return false; // Key not found
};

/**
 * Check API validation error
 * @param {data} error
 * @returns {boolean}
 */
export const isApiValidationError = (error: any): boolean => {
  if ('data' in error && error?.data?.error === 'Validation Error') {
    return true;
  }
  return false;
};

/**
 * Count dirty field for `userForm`
 * @param {data} any
 * @returns {number}
 */
export const countDirtyFields = (data: any): number => {
  let fieldsCount = 0;
  if (Array.isArray(data)) {
    for (const item of data) {
      fieldsCount += countDirtyFields(item);
    }
  } else if (typeof data === 'object' && data !== null) {
    for (const key in data) {
      if (
        key === 'itemsValues' ||
        key === 'value' ||
        (key === 'secondValue' && Array.isArray(data['items']))
      ) {
        continue;
      }
      if (
        key === 'items' &&
        Array.isArray(data[key]) &&
        data[key].includes(true)
      ) {
        fieldsCount++;
      } else if (data[key] === true) {
        fieldsCount++;
      } else {
        fieldsCount += countDirtyFields(data[key]);
      }
    }
  }
  return fieldsCount;
};

/**
 * Get dirty field values for `userForm`
 * @param {DirtyFields} dirtyFields
 * @param {Values} values
 * @returns {Partial<typeof values>}
 */
export const getDirtyValues = <
  DirtyFields extends Record<string, unknown>,
  Values extends Record<keyof DirtyFields, unknown>
>(
  dirtyFields: DirtyFields,
  values: Values
): Partial<typeof values> => {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) {
      return prev;
    }

    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues(
              dirtyFields[key] as DirtyFields,
              values[key] as Values
            )
          : values[key],
    };
  }, {});

  return dirtyValues;
};

/**
 * Construct full address using addressLine2
 * @param {string} fullAddress
 * @param {string | undefined} postCode
 * @param {string | undefined} addressLine2
 * @returns {string}
 */
export function getFullAddress(
  fullAddress: string,
  postCode?: string,
  addressLine2?: string
): string {
  let addressParts = fullAddress.split(',').map(part => part.trim());

  if (addressLine2) {
    addressParts.push(addressLine2.trim());
  }
  if (postCode) {
    const trimmedPostCode = postCode.trim();
    addressParts = addressParts.map(part => {
      if (part.includes(trimmedPostCode)) {
        return part.replace(trimmedPostCode, '').trim();
      }
      return part;
    });
    addressParts = addressParts.filter(part => part !== '');
    addressParts.push(trimmedPostCode);
  }

  return addressParts.join(', ');
}

/**
 * Compare two objects {o1} and {o2}
 *
 * @param {any} o1
 * @param {any} o2
 * @returns {boolean}
 */
export function objectsEqual(o1: any, o2: any) {
  return (
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every(p => o1[p] === o2[p])
  );
}

/**
 * Compare two object arrays {a1} and {a2}
 *
 * @param {any[]} a1
 * @param {any[]} a2
 * @returns {boolean}
 */
export function arrayObjectsEqual(a1: any[], a2: any[]): boolean {
  return (
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))
  );
}

/**
 * Format period {startDate} and {endDate} in format {02-25 Feb 2024}
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {string}
 */
export function getCombinedDate(startDate: Date, endDate: Date) {
  const year = startDate.getFullYear();
  const month = startDate.toLocaleString('en-US', {month: 'short'});
  const startDay = startDate.getDate();
  const endDay = endDate.getDate();
  return `${startDay}-${endDay} ${month} ${year}`;
}

/**
 * Parse status filter
 *
 * @param {string[] | FilterObject[]} deliveryValues
 * @param {Date} endDate
 * @returns {string | undefined}
 */
export function parseStatusTypeFilter(
  deliveryValues:
    | string[]
    | {
        [key: string]: number;
      }[]
): string | undefined {
  if (!deliveryValues) {
    return undefined;
  } else if (deliveryValues.length && typeof deliveryValues[0] === 'string') {
    return deliveryValues.map(key => key).join(',');
  }
  return Object.entries(deliveryValues)
    .map(key => (key[1] ? key[0] : ''))
    .join(',');
}

/**
 * Get buffer from file
 *
 * @param {File} file
 * @returns {Promise<Buffer>}
 */
export function getBufferFromFile(file: File): Promise<Buffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const buffer = Buffer.from(reader.result as ArrayBuffer);
      resolve(buffer);
    };
    reader.onerror = () => {
      reject(new Error('Failed to read file'));
    };
    reader.readAsArrayBuffer(file);
  });
}
