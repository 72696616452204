import {FeedbackParams} from '../store/queries/common';
import {Address} from '../types/Address';
import {User, UserStatus} from '../types/User';
import {UserData} from '../types/UserData';
import {baseApi} from './baseApi';

export type GetMeParams = {
  token: string;
} & FeedbackParams;

export type UserActionParam = {
  id?: number | string;
  body?: {
    status: UserStatus;
  };
} & FeedbackParams;

export type UpdateBuyerParam = {
  id?: number | string;
  body: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    stores?: (string | number)[];
  };
} & FeedbackParams;

export type UpdatePasswordParam = {
  id?: number | string;
  body: {
    password: string;
    passwordConfirm: string;
  };
} & FeedbackParams;

export type GetUserResponse = {
  user: UserData;
  totalOrders?: number;
};

export type AddressParam = {
  id?: number | string;
  body?: {
    userId?: number | string;
  } & Address;
} & FeedbackParams;

export type AddressResponse = {
  user: User;
} & Address;

const onInvalidate = (result: any) =>
  result ? ['Buyer', 'Seller'] : ['Nothing'];

export const userApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    me: builder.query<User, GetMeParams>({
      query: () => ({
        url: '/auth/user',
        method: 'GET',
      }),
      providesTags: ['CurrentUser'],
    }),
    getUser: builder.query<GetUserResponse, UserActionParam>({
      query: ({id}: UserActionParam) => ({
        url: `/user/${id}`,
        method: 'GET',
      }),
      providesTags: ['Buyer'],
    }),
    suspendAndUnspend: builder.mutation<GetUserResponse, UserActionParam>({
      query: ({id, body}: UserActionParam) => ({
        url: `/user/${id}/status`,
        method: 'PUT',
        body,
      }),
      // @ts-ignore
      invalidatesTags: result => onInvalidate(result),
    }),
    updateBuyer: builder.mutation<GetUserResponse, UpdateBuyerParam>({
      query: ({id, body}: UpdateBuyerParam) => ({
        url: `/user/${id}`,
        method: 'PUT',
        body,
      }),
      // @ts-ignore
      invalidatesTags: result => onInvalidate(result),
    }),
    updatePassword: builder.mutation<GetUserResponse, UpdatePasswordParam>({
      query: ({id, body}: UpdatePasswordParam) => ({
        url: `/user/${id}/password`,
        method: 'PUT',
        body,
      }),
    }),
    delete: builder.mutation<any, UserActionParam>({
      query: ({id}: UserActionParam) => ({
        url: `/user/${id}`,
        method: 'DELETE',
      }),
    }),
    createAddress: builder.mutation<AddressResponse, AddressParam>({
      query: ({body}: AddressParam) => ({
        url: '/admin/address',
        method: 'POST',
        body,
      }),
      // @ts-ignore
      invalidatesTags: result => onInvalidate(result),
    }),
    updateAddress: builder.mutation<AddressResponse, AddressParam>({
      query: ({id, body}: AddressParam) => ({
        url: `/admin/address/${id}`,
        method: 'PUT',
        body,
      }),
      // @ts-ignore
      invalidatesTags: result => onInvalidate(result),
    }),
    deleteAddress: builder.mutation<any, AddressParam>({
      query: ({id, body}: AddressParam) => ({
        url: `/admin/address/${id}`,
        method: 'DELETE',
        body,
      }),
      // @ts-ignore
      invalidatesTags: result => onInvalidate(result),
    }),
  }),
});

export const {
  useMeQuery,
  useDeleteMutation,
  useSuspendAndUnspendMutation,
  useGetUserQuery,
  useUpdateBuyerMutation,
  useUpdatePasswordMutation,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
} = userApi;
