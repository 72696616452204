import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {ReactComponent as PlusIcon} from '../../../../assets/images/plusRed.svg';
import Button from '../../../Common/Button';
import {Control, UseFormSetValue, useFieldArray} from 'react-hook-form';
import {StoreScheduleItem} from '../../../../types/StoreScheduleItem';
import {MenuScheduleFormValues} from '../../../../hooks/useScheduleManagement';
import {ProductCategory} from '../../../../types/ProductCategory';
import {useParams} from 'react-router-dom';
import {useFetchCategoryQuery} from '../../../../services/productCategoryApi';
import ScheduleItem from './ScheduleItem';
import {StoreSchedule} from '../../../../types/StoreSchedule';
import {CategoryCountState} from './SchedulesList';

/**
 * Schedule items list
 */

type ScheduleItemsListProps = {
  control: Control<MenuScheduleFormValues, any>;
  setValue: UseFormSetValue<MenuScheduleFormValues>;
  deleteScheduleItem: (id: string | number) => void;
  scheduleIndex: number;
  updateCount: (lists: CategoryCountState[]) => void;
  savedData: StoreSchedule[];
  countLists: CategoryCountState[];
  updateIsResetting: (reset: boolean) => void;
  isResetting: boolean;
  updateIsDeleting: (deleting: boolean) => void;
};

export type CheckedProductCategoryState = {
  id: number;
  checkedProducts: number[];
  isChecked: boolean;
};

const ScheduleItemsList = ({
  control,
  deleteScheduleItem,
  scheduleIndex,
  updateCount,
  savedData,
  countLists,
  isResetting,
  updateIsResetting,
  updateIsDeleting,
}: ScheduleItemsListProps) => {
  const intl = useIntl();
  const {storeId} = useParams();

  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const {data, isSuccess} = useFetchCategoryQuery({
    id: storeId,
  });

  const getCheckedProductIds = (lists: CheckedProductCategoryState[]) => {
    const items: number[] = [];
    const filteredList = lists.filter(item => item.isChecked);
    filteredList.forEach(item => {
      items.push(...item.checkedProducts);
    });
    return items;
  };

  const {fields, append, remove, update} = useFieldArray({
    control,
    name: `schedules.${scheduleIndex}.items`, // unique name for Field Array
    keyName: 'fieldId',
  });

  const formatCategoryToCheckedState = (lists: ProductCategory[]) => {
    const result: CheckedProductCategoryState[] = lists.map(item => {
      const products = item.products.map(prod => prod.id);
      return {
        id: item.id,
        checkedProducts: products,
        isChecked: products.length > 0,
      };
    });
    return result;
  };

  // check all categories on load
  useEffect(() => {
    if (data && isSuccess) {
      setCategories(data.data);
    }
  }, [data, isSuccess]);

  const generatNewCheckProductIds = (lists: ProductCategory[]) => {
    const result = formatCategoryToCheckedState(lists);
    return getCheckedProductIds(result);
  };

  const generatNewCheckCategoryIds = (lists: ProductCategory[]) => {
    const categoryIds = lists
      .filter(item => item.products.length > 0)
      .map(item => item.id);
    return categoryIds;
  };

  const onAddClick = () => {
    append({
      title: intl.formatMessage({
        id: 'dashboard.store_details.menu_tab.new_schedule',
      }),
      openingTime: '08:00',
      closingTime: '12:00',
      saved: false,
      services: {collection: true, delivery: true},
      products: generatNewCheckProductIds(categories),
      categories: generatNewCheckCategoryIds(categories),
    });
  };

  const onEdit = (value: StoreScheduleItem, itemIndex: number) => {
    update(itemIndex, value);
  };

  const onDelete = (index: number) => {
    remove(index);
  };

  return (
    <>
      <div>
        {fields.map((item, index) => (
          <ScheduleItem
            key={index}
            item={item}
            fields={fields}
            index={index}
            categories={categories}
            onEdit={onEdit}
            onDelete={onDelete}
            scheduleIndex={scheduleIndex}
            control={control}
            deleteScheduleItem={deleteScheduleItem}
            savedData={savedData}
            updateCount={updateCount}
            countLists={countLists}
            isResetting={isResetting}
            updateIsResetting={updateIsResetting}
            updateIsDeleting={updateIsDeleting}
          />
        ))}
        <Button
          link
          small
          className="text-danger"
          title={intl.formatMessage({
            id: 'dashboard.store_details.menu_tab.add_schedule',
          })}
          icon={<PlusIcon />}
          onClick={onAddClick}
        />
      </div>
    </>
  );
};

export default ScheduleItemsList;
