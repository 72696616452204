export interface Payment {
  id: number;
  status: PaymentStatus;
  amount: number;
  details: PaymentDetails;
  paymentMethod: PaymentMethodType;
  createdAt: Date;
  updatedAt: Date;
}

export interface PaymentDetails {
  paymentIntentId: string;
  brand?: string;
  last4Digit?: string;
  expMonth?: number;
  expYear?: number;
  billingAddress?: Record<string, string | null>;
}

export enum PaymentMethodType {
  Cash = 'cash',
  Online = 'online',
  ApplePay = 'applePay',
  GooglePay = 'googlePay',
}

export enum PaymentStatus {
  Pending = 'Pending',
  Failed = 'Failed',
  Expired = 'Expired',
  Declined = 'Declined',
  Paid = 'Paid',
}
