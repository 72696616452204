import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import cx from 'clsx';
import {Row, Col, Stack} from 'react-bootstrap';
import IconButton from '../../../../Common/IconButton';
import {ReactComponent as EditIcon} from '../../../../../assets/images/editGreen.svg';
import {ReactComponent as RestaurantIcon} from '../../../../../assets/images/restaurant.svg';
import {ReactComponent as MapMarker} from '../../../../../assets/images/mapMarker.svg';
import Dropdown, {ItemType} from '../../../../Common/Dropdown';
import StatusIndicator, {StatusColor} from '../../../../Common/StatusIndicator';
import {DeliveryZone} from '../../../../../types/DeliveryZone';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import {DeliveryZoneStatus} from '../../../../../utils/status';

type DeliveryZoneWidgetProps = {
  zone: DeliveryZone;
  selected?: DeliveryZone;
  onClick: (zone: DeliveryZone) => void;
  onEditClick: () => void;
  onStatusChange: (zone: DeliveryZone, status: DeliveryZoneStatus) => void;
};

export type DeliveryZoneWidgetRef = {
  scrollIntoView: () => void;
};

const DeliveryZoneWidget = forwardRef<
  DeliveryZoneWidgetRef,
  DeliveryZoneWidgetProps
>((props, ref) => {
  const intl = useIntl();
  const {zone, selected, onClick, onEditClick, onStatusChange} = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const formattedStatus = {
    on: 'on',
    off: 'off',
    offForToday: 'off_today',
  };

  const scheduleStatus: ItemType[] = [
    {
      id: DeliveryZoneStatus.on,
      label: intl.formatMessage({
        id: 'status.on',
      }),
      onClick: () => {
        handleZoneStatusChange(DeliveryZoneStatus.on);
      },
    },
    {
      id: DeliveryZoneStatus.offForToday,
      label: intl.formatMessage({
        id: 'status.off_today',
      }),
      onClick: () => {
        handleZoneStatusChange(DeliveryZoneStatus.offForToday);
      },
    },
    {
      id: DeliveryZoneStatus.off,
      label: intl.formatMessage({
        id: 'status.off',
      }),
      onClick: () => {
        handleZoneStatusChange(DeliveryZoneStatus.off);
      },
    },
  ];

  const getStatusColor = (status: string): StatusColor => {
    let color: StatusColor = 'green';
    if (status === 'offForToday') {
      color = 'orange';
    } else if (status === 'off') {
      color = 'danger';
    }
    return color;
  };

  const handleOnZoneClick = () => {
    onClick(zone);
  };

  const handleZoneStatusChange = (status: DeliveryZoneStatus) => {
    onStatusChange(zone, status);
  };

  const handleOnEditClick = () => {
    onClick(zone);
    onEditClick();
  };

  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    },
  }));

  return (
    <div
      className={cx('card details-card', {
        'border border-red': selected?.id === zone.id,
      })}
      ref={selected?.id === zone.id ? containerRef : null}
    >
      <div className="card-body p-3" onClick={handleOnZoneClick}>
        <Row className="align-items-start pb-3">
          <Col>
            <Stack direction="horizontal" gap={2}>
              {zone.isMainZone ? <RestaurantIcon /> : <MapMarker />}
              <Typography variant="h5">{zone.address.postCode}</Typography>
            </Stack>
          </Col>
          <Col xs="auto">
            <IconButton size="xs" variant="action" onClick={handleOnEditClick}>
              <EditIcon />
            </IconButton>
          </Col>
        </Row>
        <Dropdown
          className="pb-2"
          items={scheduleStatus}
          value={zone.status}
          title={
            <StatusIndicator
              label={intl.formatMessage({
                id: `status.${formattedStatus[zone.status]}`,
              })}
              color={getStatusColor(zone.status) as StatusColor}
            />
          }
        />
        <Stack gap={2}>
          <Row>
            <Col>
              <Typography variant="subtitle2" className="text-muted">
                <FormattedMessage id="dashboard.store_details.delivery_tab.range" />
              </Typography>
            </Col>
            <Col xs="auto">
              <Typography variant="subtitle2">
                {zone.rangeMin}-{zone.rangeMax} miles
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography variant="subtitle2" className="text-muted">
                <FormattedMessage id="dashboard.store_details.delivery_tab.minimum_order" />
              </Typography>
            </Col>
            <Col xs="auto">
              <Typography variant="subtitle2">
                £{zone.minimumOrderPrice}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography variant="subtitle2" className="text-muted">
                <FormattedMessage id="dashboard.store_details.delivery_tab.delivery_charge" />
              </Typography>
            </Col>
            <Col xs="auto">
              <Typography variant="subtitle2">
                £{zone.deliveryChargePrice}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography variant="subtitle2" className="text-muted">
                <FormattedMessage id="dashboard.store_details.delivery_tab.estimated_delivery_time" />
              </Typography>
            </Col>
            <Col xs="auto">
              <Typography variant="subtitle2">
                {zone.estimatedDeliveryTime} min
              </Typography>
            </Col>
          </Row>
        </Stack>
      </div>
    </div>
  );
});
DeliveryZoneWidget.displayName = 'DeliveryZoneWidget';

export default DeliveryZoneWidget;
