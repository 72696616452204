import {useMemo} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import SearchTextField, {SearchTextFieldProps} from '../Common/SearchTextField';

export type KeywordSearchProps = Omit<
  SearchTextFieldProps,
  'value' | 'onChange'
> & {
  parseSearchParamsOnNavigate?: (
    searchParams: URLSearchParams
  ) => URLSearchParams;
  resetPageOnChange?: boolean;
};

/**
 * Search form
 */
const KeywordSearch = ({
  parseSearchParamsOnNavigate,
  resetPageOnChange,
  ...searchProps
}: KeywordSearchProps) => {
  const [searchParams] = useSearchParams();
  const value = useMemo(() => searchParams.get('q') ?? '', [searchParams]);
  const navigate = useNavigate();

  const handleChange = (a: any) => {
    let p = new URLSearchParams(searchParams.toString());
    p.set('q', a.target.value);

    if (parseSearchParamsOnNavigate) {
      p = parseSearchParamsOnNavigate(p);
    } else if (resetPageOnChange) {
      p.set('page', '1');
    }

    navigate(`?${p.toString()}`);
  };

  return (
    <SearchTextField {...searchProps} value={value} onChange={handleChange} />
  );
};

export default KeywordSearch;
