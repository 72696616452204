import clsx from 'clsx';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

/**
 * Styled switch component
 */

export type CustomSwitchProps = {
  /**
   * If true, the component is checked.
   */
  checked: boolean;
  /**
   * If true the element is disabled
   */
  disabled: boolean;
  /**
   * the width should be 48 instead 40
   */
  width48?: boolean;
  /**
   * TRUE is size is small
   */
  small?: boolean;
  /**
   * Color variant is green
   */
  green?: boolean;
  /**
   * Small on mobile
   */
  mobileSmall?: boolean;
  /**
   * Change checked state callback
   */
  onChange: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    borderRadius: 20,
    '&.width48': {
      width: 48,
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translate(28px) !important',
      },
    },
    '&.MuiSwitch-sizeSmall': {
      width: 30,
      height: 16,
      borderRadius: 12,
    },
    '&.mobile-small': {
      [theme.breakpoints.down('md')]: {
        width: 30,
        height: 16,
        borderRadius: 12,
      },
    },
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&.small': {
      padding: 2,
      '&$checked': {
        transform: 'translateX(14px)',
      },
    },
    '&.mobile-small': {
      [theme.breakpoints.down('md')]: {
        padding: 2,
        '&$checked': {
          transform: 'translateX(15px)',
        },
      },
    },
    '&.green': {
      '&$checked': {
        '& + $track': {
          backgroundColor: theme.palette.green.main,
        },
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
    '&.small': {
      width: 12,
      height: 12,
    },
    '&.mobile-small': {
      [theme.breakpoints.down('md')]: {
        width: 12,
        height: 12,
      },
    },
  },
  track: {
    borderRadius: 20 / 2,
    backgroundColor: '#F0F0F0',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}));

const CustomSwitch = ({
  disabled,
  width48,
  small,
  green,
  mobileSmall,
  onChange,
  checked,
}: CustomSwitchProps & any) => {
  const classes = useStyles();
  return (
    <Switch
      size={small ? 'small' : 'medium'}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: clsx(classes.root, {width48}, {'mobile-small': mobileSmall}),
        switchBase: clsx(
          classes.switchBase,
          {small},
          {green},
          {'mobile-small': mobileSmall}
        ),
        thumb: clsx(
          classes.thumb,
          {small},
          {'mobile-small': mobileSmall},
          {'d-none': disabled}
        ),
        track: classes.track,
        checked: classes.checked,
      }}
      onChange={onChange}
      checked={checked}
    />
  );
};

CustomSwitch.defaultProps = {
  small: false,
  green: false,
  width48: false,
  mobileSmall: false,
};

export default CustomSwitch;
