import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {LoginParams, useLoginMutation} from '../services/signInApi';
import {login, LoginArgs} from '../store/slices/authSlice';
import {useNavigate} from 'react-router-dom';
import * as urls from '../constants/urls';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {formatErrorMessage} from '../utils/functions';
import useSidebarMenu from './useSidebarMenu';

type FormData = {
  username: string;
  password: string;
  rememberMe?: boolean;
};

const useLogin = () => {
  // translations
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // executes google invisible recaptcha and returns a token
  const {executeRecaptcha} = useGoogleReCaptcha();
  const {refetchMenu} = useSidebarMenu();

  // validate form fields
  const schema = useMemo(
    () =>
      yup.object().shape({
        username: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required'}))
          .email(intl.formatMessage({id: 'validation.error.invalid_email'})),
        password: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
      }),
    [intl]
  );

  // keeps remember me state
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: {errors, submitCount},
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  // login rtk mutation
  const [loginMutation, {isSuccess, isLoading, data}] = useLoginMutation();

  const onSubmit = async (formData: FormData) => {
    setRememberMe(formData.rememberMe ?? false);
    //@ts-ignore
    const gReCaptchaToken = await executeRecaptcha('login');

    const data: LoginParams = {
      body: {
        ...formData,
        captcha: gReCaptchaToken,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
    };
    loginMutation(data);
  };

  // handle login with email api success
  useEffect(() => {
    if (isSuccess && data) {
      const args: LoginArgs = {
        user: data.user,
        refreshToken: data.tokens.refreshToken,
        accessToken: data.tokens.accessToken,
        rememberMe,
      };
      dispatch(login(args));
      navigate(urls.ORDERS_LIST_PATH);
      refetchMenu();
    }
  }, [isSuccess, data]);

  return {
    onSubmit,
    isSuccess,
    isLoading,
    data,
    control,
    handleSubmit,
    submitCount,
    errors,
  };
};

export default useLogin;
