import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useFormState,
} from 'react-hook-form';
import CustomCheckbox, {CustomCheckboxProps} from '../../Common/Checkbox';

export type CheckboxControllerProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  size: CustomCheckboxProps['size'];
  variant: CustomCheckboxProps['variant'];
  classes?: {formControlLabel: string};
} & Omit<FormControlLabelProps, 'control' | 'name' | 'classes'>;

const CheckboxController = <T extends FieldValues>({
  control,
  name,
  size,
  variant,
  classes = {formControlLabel: 'checkbox'},
  ...formControlLabelProps
}: CheckboxControllerProps<T>) => {
  const {errors} = useFormState({control, name});
  const touched = !!errors[name];
  const error = errors[name];

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {value, ref, ...restInput}}) => (
        <FormControl
          className="form-group checkbox-group"
          error={touched && !!error}
          innerRef={ref}
        >
          <FormControlLabel
            className={clsx(classes.formControlLabel)}
            control={
              <CustomCheckbox
                size={size}
                variant={variant}
                checked={!!value}
                {...restInput}
              />
            }
            {...formControlLabelProps}
          />
          {touched && error ? (
            <FormHelperText>{String(error.message)}</FormHelperText>
          ) : null}
        </FormControl>
      )}
    />
  );
};

CheckboxController.defaultProps = {
  classes: {formControlLabel: 'checkbox'},
  size: 'small',
  variant: 'primary',
};

export default CheckboxController;
