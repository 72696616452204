import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {IntlProvider} from 'react-intl';
import {PersistGate} from 'redux-persist/integration/react';
import enMessages from './translations/messages.en.json';
import './assets/scss/style.scss';
import {flattenMessages} from './utils/functions';
import App from './App';
import store, {persistor} from './store/store';
import reportWebVitals from './reportWebVitals';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import * as Sentry from '@sentry/react';
import './index.css';

// Locale
const appLocale = 'en';

// Translations
const messages = {
  en: enMessages,
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  tracePropagationTargets: [process.env.REACT_APP_API_URL as string],
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// google recaptcha sitekey
const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

root.render(
  // react-beautiful-dnd cannot to work on strict mode
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <IntlProvider
        locale={appLocale}
        defaultLocale="en"
        messages={flattenMessages(messages[appLocale])}
      >
        <GoogleReCaptchaProvider
          reCaptchaKey={siteKey}
          useRecaptchaNet={true}
          language={appLocale}
          scriptProps={{
            async: false,
            defer: false,
            appendTo: 'head',
            nonce: undefined,
          }}
        >
          <App />
        </GoogleReCaptchaProvider>
      </IntlProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
