import {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  UpdateBuyerParam,
  useGetUserQuery,
  useUpdateBuyerMutation,
} from '../services/userApi';
import {useIntl} from 'react-intl';
import {
  capitalizeFirstLetter,
  formatErrorMessage,
  isApiValidationError,
  mapErrorToFormFields,
  phoneRegExp,
} from '../utils/functions';
import {OptionProps} from '../components/Form/Inputs/AutocompleteMultiple';
import {Store} from '../types/Store';
import {unstable_usePrompt as usePrompt} from 'react-router-dom';
import {UserData} from '../types/UserData';
import {CustomError} from '../types/CustomError';

export type ProfileDetailsFormValues = {
  [key: string]: any;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  stores: OptionProps[];
};
export type Color = 'green' | 'error' | 'orange' | 'secondary';

type HeaderDetails = {
  fullName: string;
  initials: string;
  status: string;
};

type UseBuyerProps = {
  id?: string;
  linkedStores?: Store[];
  showSave?: boolean;
};

const useBuyer = ({id, linkedStores, showSave}: UseBuyerProps) => {
  // translations
  const intl = useIntl();
  const [count, setCount] = useState(0);
  const [initialValues, setInitialValues] = useState<ProfileDetailsFormValues>({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    stores: [],
  }); // Add this line

  const {
    data: userData,
    isFetching,
    isSuccess,
  } = useGetUserQuery(
    {
      id,
      showProgressDialog: true,
    },
    {refetchOnMountOrArgChange: true}
  );

  const [
    updateBuyerDetails,
    {isSuccess: updateSuccess, error: updateBuyerError},
  ] = useUpdateBuyerMutation();

  const [headerDetails, setHeaderDetails] = useState<HeaderDetails>({
    fullName: '',
    initials: '',
    status: '',
  });

  // alert user when they try to reload when edited field has not been saved
  usePrompt({
    when: (count > 0 || showSave) as boolean,
    message: intl.formatMessage({id: 'dashboard.confirm_not_saved_message'}),
  });
  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent) => {
      if (count > 0 || showSave) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [count, showSave]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .email(intl.formatMessage({id: 'validation.error.invalid_email'})),
        firstName: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        lastName: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        phone: yup
          .string()
          .required(intl.formatMessage({id: 'validation.error.required_field'}))
          .matches(
            phoneRegExp,
            intl.formatMessage({id: 'validation.error.invalid_phone'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    reset,
    formState: {errors, submitCount, dirtyFields},
  } = useForm<ProfileDetailsFormValues>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
    defaultValues: {
      firstName: userData?.user.firstName ?? '',
      lastName: userData?.user.lastName ?? '',
      email: userData?.user.email ?? '',
      phone: userData?.user.phone ?? '',
    },
  });
  const formValuesWatch = watch();

  const countDirtyFields = () => {
    const dirtyFieldsCount = Object.keys(dirtyFields).reduce(
      (count, fieldName) => {
        // Check if the current value is different from the initial value
        if (formValuesWatch[fieldName] !== initialValues[fieldName]) {
          return count + 1;
        }
        return count;
      },
      0
    );

    setCount(dirtyFieldsCount);
  };
  useEffect(() => {
    countDirtyFields();
  }, [formValuesWatch]);

  const handleSetValue = (user: UserData) => {
    setValue('firstName', user?.firstName ?? '');
    setValue('lastName', user?.lastName ?? '');
    setValue('email', user?.email ?? '');
    setValue('phone', user?.phone ?? '');
  };

  // updates input field if data has already been filled
  useEffect(() => {
    if (!userData || !isSuccess) {
      return;
    }

    const {user} = userData;

    const status = capitalizeFirstLetter(user.status);
    let fullName = '';
    let initials = '';
    handleSetValue(user);
    setInitialValues({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      stores: [],
    });
    if (user.firstName && user.lastName) {
      fullName = `${user.firstName} ${user.lastName}`;
      initials = `${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}`;
    }
    setHeaderDetails({fullName, initials, status});
  }, [isSuccess, userData, setValue, setHeaderDetails]);

  const resetFormFields = () => {
    if (userData) {
      reset({stores: []});
      handleSetValue(userData?.user);
    }
  };

  const updateBuyerAccount = (formValues: ProfileDetailsFormValues) => {
    let upatedLinkedStores: (string | number)[] = [];
    if (
      (linkedStores && linkedStores.length > 0) ||
      (formValues.stores && formValues.stores.length > 0)
    ) {
      const newLinkedStores = formValues.stores
        ? formValues.stores.map((item: OptionProps) => item.value)
        : [];
      const oldLinkedStores =
        linkedStores && linkedStores.length > 0
          ? linkedStores?.map(store => store.id)
          : [];
      upatedLinkedStores = [...oldLinkedStores, ...newLinkedStores];
      // @ts-ignore
      delete formValues.stores;
    }
    const data: UpdateBuyerParam = {
      id,
      body: {
        ...formValues,
        stores: upatedLinkedStores,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'dashboard.users.messages.update_user_success_message',
        });
        return message;
      },
    };
    updateBuyerDetails(data);
  };

  useEffect(() => {
    if (updateBuyerError) {
      handleSetValue(userData?.user as UserData);
      const typedUpdateError = updateBuyerError as CustomError;
      if (isApiValidationError(typedUpdateError)) {
        mapErrorToFormFields(typedUpdateError.data.message, setError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBuyerError]);

  useEffect(() => {
    if (updateSuccess) {
      reset({stores: []});
    }
  }, [updateSuccess]);

  return {
    userData,
    isFetching,
    isSuccess,
    control,
    handleSubmit,
    headerDetails,
    clearErrors,
    setError,
    errors,
    count,
    submitCount,
    resetFormFields,
    reset,
    updateBuyerAccount,
  };
};

export default useBuyer;
