import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Container, Row, Col, Stack} from 'react-bootstrap';
import {Form} from 'reactstrap';
import StatusIndicator from '../../../Common/StatusIndicator';
import Chip from '../../../Common/Chip';
import Button from '../../../Common/Button';
import DropzoneController from '../../../Form/HookForm/DropzoneController';
import SelectController from '../../../Form/HookForm/SelectController';
import ToggleButtonsController from '../../../Form/HookForm/ToggleButtonsController';
import InputController from '../../../Form/HookForm/InputController';
import TextAreaController from '../../../Form/HookForm/TextAreaController';
import AutocompleteMultipleController from '../../../Form/HookForm/AutocompleteMultipleController';
import useProductDetail from '../../../../hooks/useProductDetail';
import {validImageOptions} from '../../../../utils/functions';
import {ProductStatus} from '../../../../types/Product';
import {ProductOptionGroup} from '../../../../types/ProductOptionGroup';
import {OptionProps} from '../../../Form/Inputs/AutocompleteMultiple';
import {useEffect, useState} from 'react';
import {useFetchProductOptionGroupQuery} from '../../../../services/productOptionGroupApi';
import ListWithDelete from '../../../Common/ListWithDelete';
import * as urls from '../../../../constants/urls';
import {useFetchCategoryQuery} from '../../../../services/productCategoryApi';
import {SeletItemType} from '../../../Common/Select';
import ConfirmationPopup from '../../../Shared/ConfirmationPopup';
import SaveFeature from '../../../Shared/SaveFeature';
import CurrencyController from '../../../Form/HookForm/CurrencyController';
import {ReactComponent as ArrowBackward} from '../../../../assets/images/arrowBackward.svg';
import {useLocation, useNavigate} from 'react-router-dom';

/**
 * Product details page
 */

const ProductDetails = () => {
  // Translations
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  // go back to categories page
  const goBack = (id: number) => {
    navigate(urls.STORE_DETAILS_PATH.replace(':storeId', `${id}/categories`), {
      state: {category: location?.state?.category},
    });
  };

  // status indicator
  const statusColor: any = {
    on: 'green',
    off: 'error',
    deleted: 'error',
    offForToday: 'orange',
  };

  const formattedStatus = {
    on: 'On',
    off: 'Off',
    offForToday: 'Off Today',
    deleted: 'Deleted',
  };

  const [options, setOptions] = useState<OptionProps[]>([]);
  const [categories, setCategories] = useState<SeletItemType[]>([]);
  const [linkedOptionGroups, setLinkedOptionGroups] = useState<
    ProductOptionGroup[]
  >([]);
  const [storeId, setStoreId] = useState<number>();
  const [open, setOpen] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  // delete Image popup action
  const openConfirmationPopup = () => setOpen(true);
  const closeConfirmationPopup = () => setOpen(false);

  // delete product popup action
  const openDeleteModal = () => setOpenDelete(true);
  const closeDeleteModal = () => setOpenDelete(false);

  const {data, isSuccess} = useFetchProductOptionGroupQuery(
    {
      storeId,
    },
    {
      skip: !storeId,
    }
  );

  const {data: categoryData, isSuccess: fetchCategorySuccess} =
    useFetchCategoryQuery(
      {id: storeId},
      {
        skip: !storeId,
      }
    );

  const goBackToCategories = () => {
    storeId && goBack(storeId);
  };

  const {
    control,
    handleSubmit,
    submit,
    product,
    uploadImage,
    deleteImage,
    uploadSuccess,
    deleteSuccess,
    setError,
    clearErrors,
    errors,
    count,
    clearFields,
    deleteProductData,
  } = useProductDetail({
    showSave,
    linkedOptions: linkedOptionGroups,
    goBack: goBackToCategories,
  });

  // set options dropdown
  useEffect(() => {
    if (!data || !isSuccess || !product) {
      return;
    }
    // map data to drop down select options
    const filteredData = data?.data.filter(
      option => !product.optionGroups.some(item => item.id === option.id)
    );
    setOptions(
      filteredData.map(item => ({
        title: item.name,
        value: item.id,
      }))
    );
  }, [product, data, isSuccess]);

  // set linked product options
  useEffect(() => {
    if (product) {
      setLinkedOptionGroups(product.optionGroups);
      setStoreId(product.store.id);
    }
    if (product && product?.status === ProductStatus.deleted) {
      goBack(product.store.id);
    }
  }, [product]);

  // set categories dropdown
  useEffect(() => {
    if (categoryData && fetchCategorySuccess) {
      setCategories(
        categoryData.data.map(item => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [categoryData, fetchCategorySuccess]);

  // delete Product Image
  const deleteProductImage = () => {
    closeConfirmationPopup();
    deleteImage();
  };

  // delete Product
  const deleteProduct = () => {
    closeDeleteModal();
    deleteProductData(product?.name as string);
  };

  // unlink option from product
  const removeLinkedOption = (id: number) => {
    if (!product) {
      return;
    }
    setLinkedOptionGroups(prevstate =>
      prevstate.filter(item => item.id !== id)
    );
  };

  // show save when a linked option is removed
  useEffect(() => {
    if (product) {
      if (product.optionGroups.length !== linkedOptionGroups.length) {
        setShowSave(true);
      } else {
        setShowSave(false);
      }
    }
  }, [product, linkedOptionGroups]);

  const numCounter = count + (showSave ? 1 : 0);

  // cancel save
  const cancelSave = () => {
    clearFields();
    if (product) {
      setLinkedOptionGroups(product.optionGroups);
    }
  };

  // Header content
  const header = (
    <div className="container-fluid top-block border-0">
      <div className="details-header">
        <div className="row align-items-start title-block pb-0 pb-lg-vtl-35">
          <div className="col-lg-6">
            <div className="details-header-part d-flex flex-column flex-lg-row align-items-center align-items-lg-start justify-content-center justify-content-lg-start">
              <div className="information-part text-center text-lg-start ps-0">
                <div className="d-flex flex-column flex-lg-row align-items-center mb-2">
                  <Typography variant="h3" className="me-2">
                    <span className="font-weight-bolder">{product?.name}</span>{' '}
                    {product?.chineseName && (
                      <span className="font-weight-regular">
                        {product?.chineseName}
                      </span>
                    )}
                  </Typography>
                  {product && (
                    <StatusIndicator
                      label={formattedStatus[product.status]}
                      color={statusColor[product.status]}
                    />
                  )}
                </div>
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="justify-content-center justify-content-lg-start"
                >
                  <Typography className="text-muted2">
                    {product?.sku}
                  </Typography>
                  {product?.category && (
                    <Chip label={product?.category?.name} />
                  )}
                </Stack>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex justify-content-center justify-content-lg-end action-buttons">
              <Button
                className="details-action"
                link
                title={intl.formatMessage({
                  id: 'dashboard.store_details.product_categories_tab.menu_actions.deleteProduct',
                })}
                onClick={openDeleteModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="details-page">
      {header}
      {product && numCounter > 0 && (
        <SaveFeature
          onClose={cancelSave}
          open
          keyType="product"
          num={numCounter}
          type="submit"
          form="update-product-form"
        />
      )}
      {/* Delete image confirmation */}
      <ConfirmationPopup
        opened={open}
        handleClose={closeConfirmationPopup}
        onOk={deleteProductImage}
        description={intl.formatMessage({
          id: 'messages.delete_message',
        })}
      />
      {/* Delete product confirmation */}
      <ConfirmationPopup
        opened={openDelete}
        handleClose={closeDeleteModal}
        onOk={deleteProduct}
        description={intl.formatMessage({
          id: 'messages.delete_message',
        })}
      />
      <Form id="update-product-form" onSubmit={handleSubmit(submit)}>
        <Container className="ms-lg-0">
          <div className="content-block pt-lg-0">
            <Button
              link
              icon={<ArrowBackward style={{marginTop: 3, marginRight: 4}} />}
              title={intl.formatMessage({
                id: 'dashboard.store_details.product_categories_tab.product_details.product_back',
              })}
              onClick={storeId ? () => goBack(storeId) : undefined}
              className="mb-3"
            />
            <Row className="g-3">
              <Col xl={5} xxl={4}>
                <div className="card details-card">
                  <div className="card-body">
                    {/* Store Image */}
                    <DropzoneController
                      name="image"
                      className="mb-3 mb-lg-fg"
                      control={control}
                      label={intl.formatMessage({
                        id: 'dashboard.store_details.product_categories_tab.product_details.productImage',
                      })}
                      labelHintText={intl.formatMessage({
                        id: 'dashboard.store_details.general_tab.store_image_hint',
                      })}
                      showLabelHint
                      canEdit
                      url={product?.image}
                      uploadImage={uploadImage}
                      deleteImage={openConfirmationPopup}
                      uploadSuccess={uploadSuccess}
                      deleteSuccess={deleteSuccess}
                      validFileOptions={validImageOptions}
                      setError={setError}
                      clearError={clearErrors}
                      errors={errors}
                    />
                    {/* Category */}
                    <SelectController
                      name="categoryId"
                      className="mb-0"
                      control={control}
                      label={intl.formatMessage({
                        id: 'dashboard.store_details.product_categories_tab.product_details.category',
                      })}
                      items={categories}
                      variant="dropdown"
                    />
                  </div>
                </div>
              </Col>
              <Col xl={7} xxl={7}>
                <div className="card details-card mb-3">
                  <div className="card-body">
                    <ToggleButtonsController
                      name="status"
                      control={control}
                      label={intl.formatMessage({
                        id: 'dashboard.store_details.product_categories_tab.product_details.productStatus',
                      })}
                      items={[
                        {
                          label: intl.formatMessage({
                            id: 'dashboard.store_details.product_categories_tab.product_details.statuses.on',
                          }),
                          value: ProductStatus.On,
                          color: 'green',
                        },
                        {
                          label: intl.formatMessage({
                            id: 'dashboard.store_details.product_categories_tab.product_details.statuses.offToday',
                          }),
                          value: ProductStatus.OffForToday,
                          color: 'orange',
                        },
                        {
                          label: intl.formatMessage({
                            id: 'dashboard.store_details.product_categories_tab.product_details.statuses.off',
                          }),
                          value: ProductStatus.Off,
                          color: 'error',
                        },
                      ]}
                    />
                    <Row className="gx-2">
                      <Col lg={6}>
                        <InputController
                          name="name"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.store_details.product_categories_tab.product_details.enProductName',
                          })}
                          required
                        />
                      </Col>
                      <Col lg={6}>
                        <InputController
                          name="chineseName"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.store_details.product_categories_tab.product_details.chProductName',
                          })}
                        />
                      </Col>
                    </Row>
                    <Row className="gx-2">
                      <Col lg={6}>
                        <CurrencyController
                          name="price"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.store_details.product_categories_tab.product_details.price',
                          })}
                          required
                        />
                      </Col>
                      <Col lg={6}>
                        <InputController
                          name="sku"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.store_details.product_categories_tab.product_details.sku',
                          })}
                          inputClass="bg-paper no-border"
                          disabled
                        />
                      </Col>
                    </Row>
                    <TextAreaController
                      name="description"
                      control={control}
                      label={intl.formatMessage({
                        id: 'dashboard.store_details.product_categories_tab.product_details.description',
                      })}
                      rows={4}
                    />
                    <InputController
                      name="externalId"
                      control={control}
                      label={intl.formatMessage({
                        id: 'dashboard.store_details.product_categories_tab.product_details.externalId',
                      })}
                      formGroupClass="mb-0"
                    />
                  </div>
                </div>
                <div className="card details-card">
                  <div className="card-body">
                    <Row>
                      <Col lg={6}>
                        <Typography variant="h5" component="p" className="mb-3">
                          <FormattedMessage id="dashboard.store_details.product_categories_tab.product_details.productOptions" />
                        </Typography>
                        <AutocompleteMultipleController
                          name="optionGroups"
                          control={control}
                          options={options}
                          className="mb-2"
                          placeholder={intl.formatMessage({
                            id: 'dashboard.store_details.product_categories_tab.product_details.productOptionsPlaceholder',
                          })}
                        />
                        {linkedOptionGroups.length > 0 &&
                          linkedOptionGroups.map(option => (
                            <ListWithDelete
                              key={option.id}
                              name={option.name}
                              id={option.id}
                              onDelete={() => removeLinkedOption(option.id)}
                              url={urls.STORE_DETAILS_PATH.replace(
                                ':storeId',
                                `${product?.store?.id}/options`
                              )}
                              isExternalLink={true}
                            />
                          ))}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Form>
    </div>
  );
};

export default ProductDetails;
