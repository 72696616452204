import {FeedbackParams} from '../store/queries/common';
import {Product} from '../types/Product';
import {ProductCategory} from '../types/ProductCategory';
import {DeleteParam} from './storeApi';
import {baseApi} from './baseApi';

export type CreateCategoryParam = {
  body: {
    name: string;
    storeId?: string | number;
    description?: string;
  };
} & FeedbackParams;

export type CreateProductParam = {
  body: FormData;
} & FeedbackParams;

export type ImportProductParam = {
  body: FormData;
} & FeedbackParams;

export type UpdateCategoryParam = {
  id?: string | number;
  body: {
    name: string;
    description?: string;
    status?: string;
  };
} & FeedbackParams;

export type FetchCategoryParam = {
  id?: string | number;
  query?: {
    pageSize?: number;
    page?: number;
    search?: string;
    searchType?: string;
    orderBy?: string;
    order?: 'ASC' | 'DESC';
    uniqueId?: string;
  };
} & FeedbackParams;

export type FetchCategoryResponse = {
  data: ProductCategory[];
};

export type FetchProductParam = {
  query: {
    storeId?: string | number;
    categoryId?: string | number;
    pageSize?: number;
    page?: number;
    search?: string;
    searchType?: string;
    orderBy?: string;
    order?: 'ASC' | 'DESC';
    uniqueId?: string;
  };
} & FeedbackParams;

export type ProductMetaType = {
  itemCount: number;
  pageCount: number;
  page: number;
  pageSize: number;
};

export type FetchProductResponse = {
  data: Product[];
  meta: ProductMetaType;
  total: number;
};

export type FindProductResponse = {
  data: Product;
};

export type ImportProductResponse = {
  errors: string[][];
  createdCount: number;
  updatedCount: number;
};

export type OrderParam = {
  id: number;
  status?: string;
  rank: number;
};

export type CategoryOrderParam = {
  storeId?: string | number;
  body: {
    categories: OrderParam[];
  };
} & FeedbackParams;

export type ProductOrderParam = {
  categoryId?: string | number;
  body: {
    products: OrderParam[];
  };
} & FeedbackParams;

export type UpdateProductParam = {
  id?: string | number;
  body: {
    name?: string;
    price?: number;
    status?: string;
    description?: string;
    externalId?: string;
    categoryId?: number;
    chineseName?: string;
    optionGroups?: (string | number)[];
  };
} & FeedbackParams;

export type UploadProductImageParam = {
  id?: number | string;
  body: FormData;
} & FeedbackParams;

const onInvalidateCategory = (result: any) =>
  result ? ['ProductCategory'] : ['Nothing'];
const onInvalidateProduct = (result: any) =>
  result ? ['Product'] : ['Nothing'];
const onInvalidateProductCategory = (result: any) =>
  result ? ['ProductCategory', 'Product'] : ['Nothing'];

export const productCategoryApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchCategory: builder.query<FetchCategoryResponse, FetchCategoryParam>({
      query: ({id, query}: FetchCategoryParam) => ({
        url: `/product/category/${id}`,
        params: {
          ...query,
        },
        method: 'GET',
      }),
      providesTags: ['ProductCategory'],
    }),
    createCategory: builder.mutation<any, CreateCategoryParam>({
      query: ({body}: CreateCategoryParam) => ({
        url: '/product/category',
        method: 'POST',
        body,
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateCategory(result),
    }),
    updateCategory: builder.mutation<any, UpdateCategoryParam>({
      query: ({id, body}: UpdateCategoryParam) => ({
        url: `/product/category/${id}`,
        method: 'PUT',
        body,
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateCategory(result),
    }),
    deleteCategory: builder.mutation<any, DeleteParam>({
      query: ({id}: DeleteParam) => ({
        url: `/product/category/${id}`,
        method: 'DELETE',
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateProductCategory(result),
    }),
    createProduct: builder.mutation<any, CreateProductParam>({
      query: ({body}: CreateProductParam) => ({
        url: '/product',
        method: 'POST',
        body,
      }),
      //@ts-ignore,
      invalidatesTags: result => onInvalidateProductCategory(result),
    }),
    fetchProduct: builder.query<FetchProductResponse, FetchProductParam>({
      query: ({query}: FetchProductParam) => ({
        url: '/product',
        method: 'GET',
        params: {
          ...query,
        },
      }),
      providesTags: ['Product'],
    }),
    updateCategoryOrder: builder.mutation<any, CategoryOrderParam>({
      query: ({storeId, body}: CategoryOrderParam) => ({
        url: `/product/category/${storeId}/order`,
        method: 'PUT',
        body,
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateCategory(result),
    }),
    getProduct: builder.query<FindProductResponse, FetchCategoryParam>({
      query: ({id}: FetchCategoryParam) => ({
        url: `/product/${id}`,
        method: 'GET',
      }),
      providesTags: ['Product'],
    }),
    updateProduct: builder.mutation<any, UpdateProductParam>({
      query: ({id, body}: UpdateProductParam) => ({
        url: `/product/${id}`,
        method: 'PUT',
        body,
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateProduct(result),
    }),
    editProduct: builder.mutation<any, UpdateProductParam>({
      query: ({id, body}: UpdateProductParam) => ({
        url: `/product/${id}`,
        method: 'PUT',
        body,
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateCategory(result),
    }),
    imageUpload: builder.mutation<FindProductResponse, UploadProductImageParam>(
      {
        query: ({id, body}: UploadProductImageParam) => ({
          url: `/product/${id}/upload-image`,
          method: 'PUT',
          body,
        }),
        //@ts-ignore
        invalidatesTags: result => onInvalidateProduct(result),
      }
    ),
    deleteImage: builder.mutation<FindProductResponse, DeleteParam>({
      query: ({id}: DeleteParam) => ({
        url: `/product/${id}/delete-image`,
        method: 'DELETE',
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateProduct(result),
    }),
    deleteProduct: builder.mutation<any, DeleteParam>({
      query: ({id}: DeleteParam) => ({
        url: `/product/${id}`,
        method: 'DELETE',
      }),
      //@ts-ignore
      invalidatesTags: result => onInvalidateProductCategory(result),
    }),
    updateProductOrder: builder.mutation<any, ProductOrderParam>({
      query: ({categoryId, body}: ProductOrderParam) => ({
        url: `/product/${categoryId}/order`,
        method: 'PUT',
        body,
      }),
    }),
    importProduct: builder.mutation<ImportProductResponse, ImportProductParam>({
      query: ({body}: ImportProductParam) => ({
        url: '/product/import',
        method: 'POST',
        body,
      }),
      //@ts-ignore,
      invalidatesTags: result => onInvalidateProductCategory(result),
    }),
  }),
});

export const {
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useFetchCategoryQuery,
  useDeleteCategoryMutation,
  useCreateProductMutation,
  useFetchProductQuery,
  useUpdateCategoryOrderMutation,
  useUpdateProductOrderMutation,
  useGetProductQuery,
  useUpdateProductMutation,
  useImageUploadMutation,
  useDeleteImageMutation,
  useDeleteProductMutation,
  useImportProductMutation,
  useEditProductMutation,
} = productCategoryApi;
