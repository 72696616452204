/* eslint-disable no-use-before-define */
import {useEffect, useState, useRef} from 'react';
import {useIntl} from 'react-intl';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {isEmpty, isEqual} from 'lodash';
import {Stack} from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '../../Common/Checkbox';
import Paper from '@material-ui/core/Paper';
import cx from 'clsx';
import Button from '../../Common/Button';
import Chip from '../../Common/Chip';
import {FormGroup} from 'reactstrap';
import HintTooltip from '../../Common/HintTooltip';
import textFieldStyles from '../../Common/useTextFieldStyles';

export type OptionProps = {
  title: string;
  email?: string;
  value: string | number;
};

export type AutocompleteMultipleProps = {
  className?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  options?: OptionProps[];
  defaultOptions?: OptionProps[];
  canEdit?: boolean;
};

/* Styles */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiTextField-root': {
      '& .MuiInputBase-formControl': {
        padding: '4px 5px',
        '& .MuiAutocomplete-input': {
          padding: '4px 5px',
          textAlign: 'start',
        },
      },
    },
  },
  popper: {
    boxShadow: 'none',
    marginTop: 10,
    '& .MuiPaper-root': {
      boxShadow: theme.shadows[7],
    },
  },
  listbox: {
    maxHeight: 256,
    paddingTop: 10,
    paddingBottom: 0,
  },
  option: {
    padding: '0 20px',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'inherit',
    },
    '&[data-focus="true"]': {
      backgroundColor: 'inherit',
    },
    '& .MuiCheckbox-root': {
      marginLeft: -10,
      marginTop: -5,
    },
    '& .titles': {
      '&.single': {
        marginTop: -4,
      },
      '& > p': {
        lineHeight: 1.2,
      },
    },
  },
  tag: {
    margin: 0,
  },
}));

const AutocompleteMultiple = ({
  id = 'id-autocomplete',
  className = 'mb-3 mb-lg-fg',
  showLabelHint,
  label,
  error = '',
  required,
  labelHintText,
  values,
  onChange,
  options,
  placeholder,
  canEdit = true,
}: any) => {
  const intl = useIntl();
  //open state
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  //values
  const [currentValues, setCurrentValues] = useState<any>(values);
  //text field styles
  const textFieldClasses = textFieldStyles({});
  //search popup field
  const inputRef = useRef<HTMLInputElement>(null);

  // sync currentValues and values
  useEffect(() => {
    if (!isEqual(values, currentValues)) {
      setCurrentValues(values);
    }
  }, [values]);

  // Done/Cancel click handler
  const handleBtnClick = (e: any) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  // Prevent input blur when interacting with the combobox
  const handleMouseDown = (event: any) => {
    if (event.target.getAttribute('id') !== id) {
      event.preventDefault();
    }
  };

  // Focus the input when interacting with the combobox
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <FormGroup className={className}>
      {showLabelHint ? (
        <div className="label-with-hint d-flex">
          <span className="small-label pb-2 pe-1">
            {label}
            {required ? '*' : ''}
          </span>
          <HintTooltip id={`hint_${label}`} content={labelHintText} />
        </div>
      ) : (
        label && (
          <span className="small-label pb-2">
            {label}
            {required ? '*' : ''}
          </span>
        )
      )}
      <Autocomplete
        id={id}
        disabled={!canEdit}
        multiple
        classes={classes}
        value={currentValues}
        open={isOpen}
        openOnFocus
        options={options}
        disableClearable
        disableCloseOnSelect
        popupIcon={null}
        getOptionLabel={(option: OptionProps) => option.title}
        renderOption={(option, {selected}) => (
          <Stack
            direction="horizontal"
            gap={0}
            className={cx(
              'border-bottom py-1 w-100',
              option.email ? 'align-items-start' : 'align-items-center'
            )}
          >
            <Checkbox checked={selected} />
            <div
              className={cx(
                'titles',
                {'mt-vtl-3': option.email},
                {single: !option.email}
              )}
            >
              <Typography>{option.title}</Typography>
              {option.email && (
                <Typography variant="subtitle2" className="text-muted">
                  {option.email}
                </Typography>
              )}
            </div>
          </Stack>
        )}
        renderInput={params => (
          <TextField
            error={!!error}
            color="secondary"
            variant="outlined"
            classes={textFieldClasses}
            placeholder={isEmpty(values) ? placeholder : ''}
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              width: '100%',
            }}
            helperText={error}
          />
        )}
        renderTags={(values, getTagProps) => (
          // show selected items inside field area
          <Stack direction="horizontal" gap={1} className="flex-wrap">
            {values.map((item, index) => (
              <Chip key={index} label={item.title} {...getTagProps({index})} />
            ))}
          </Stack>
        )}
        PaperComponent={props => (
          <Paper
            autoFocus={false}
            className={cx({'d-none': !isOpen})}
            onMouseDown={handleMouseDown}
            onClick={handleClick}
          >
            {/* eslint-disable-next-line react/prop-types */}
            {props.children}
            <div className="px-3 pb-3 pt-vtl-15">
              <Stack
                gap={3}
                direction="horizontal"
                className="justify-content-end"
              >
                <Button
                  link
                  title={intl.formatMessage({id: 'actions.cancel'})}
                  onClick={handleBtnClick}
                />
                <Button
                  link
                  primary
                  title={intl.formatMessage({id: 'actions.done'})}
                  onClick={handleBtnClick}
                />
              </Stack>
            </div>
          </Paper>
        )}
        // Toggle open state
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        // onChange
        onChange={(event, value) => {
          setCurrentValues(value);
          onChange(value);
        }}
      />
    </FormGroup>
  );
};

export default AutocompleteMultiple;
