import {withStyles, InputBase} from '@material-ui/core';

// Base input styles
const BaseInput = withStyles(theme => ({
  root: {},
  input: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    borderRadius: 10,
    fontSize: 12,
    padding: theme.spacing(1),
    height: theme.spacing(1.8),
    boxShadow: theme.shadows[4],
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 10,
    },
  },
}))(InputBase);

export default BaseInput;
