import {Outlet, useLocation} from 'react-router-dom';
import * as urls from '../../constants/urls';
import MenuLeft from '../Navbar/MenuLeft';
import MobileDrawer from '../Navbar/MobileDrawer';

const RouteLayout = () => {
  const location = useLocation();

  return (
    <section className="react-container d-flex flex-column flex-lg-row">
      {location.pathname !== urls.LOGIN_PATH && (
        <>
          <MenuLeft />
          <MobileDrawer />
        </>
      )}
      <Outlet />
    </section>
  );
};

export default RouteLayout;
