/* eslint-disable react/require-default-props */
import {ElementType, HTMLProps, ReactNode} from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import {
  Col,
  ColProps,
  Form,
  FormGroupProps,
  FormLabelProps,
} from 'react-bootstrap';
import {FeedbackProps} from 'react-bootstrap/Feedback';
import {
  Control,
  Controller,
  ControllerProps,
  FieldValues,
  Path,
} from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import {CountrySelect} from './PhoneController/CountrySelect';

const DEFAULT_COUNTRY = 'GB';

export type PhoneControllerProps<T extends FieldValues> = {
  /** Field name used in hook-form */
  name: Path<T>;
  /** Control label */
  label?: string | ReactNode;
  /** Control from `useForm()` hook */
  control: Control<T>;
  /** Props given to bootstrap `Form.Group` component */
  formGroupProps?: FormGroupProps;
  /** Props given to `HTMLDivElement` that wrap `Form.Label` component */
  labelWrapperProps?: HTMLProps<HTMLDivElement>;
  /** Props given to bootstrap `Form.Control.Feeback` component */
  feedbackProps?: FeedbackProps;
  /** Props given to bootstrap `Form.Label` component */
  formLabelProps?: FormLabelProps;
  /** Whether the field is required or not */
  required?: boolean;
  /** The component content, like <PhoneController>children is here</PhoneController> */
  children?: string | ReactNode | ReactNode[];
  phoneInputProps?: any;
  onChange?: (newValue: any) => void;
  selectWrapperComponent?: ElementType;
  canEdit?: boolean;
} & Omit<ControllerProps<T>, 'name' | 'render' | 'control'>;

export const SelectWrapperComponent = ({children, ...props}: ColProps) => (
  <Col xs={2} lg={3} {...props} className={clsx(props.className, 'me-1')}>
    {children}
  </Col>
);

const PhoneController = <T extends FieldValues>({
  name,
  label,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  formGroupProps,
  // labelWrapperProps,
  // feedbackProps,
  // formLabelProps,
  // labelWrapperProps,
  // feedbackProps,
  // formLabelProps,
  required,
  phoneInputProps,
  children,
  onChange: onChangeProps,
  selectWrapperComponent: SelectWrapper = SelectWrapperComponent,
  canEdit = false,
}: PhoneControllerProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({field: {value, onChange, onBlur}, fieldState: {error}}) => {
        const handleChange = (newValue: any) => {
          onChange(newValue ?? '');
          if (onChangeProps) {
            onChangeProps(newValue);
          }
        };

        return (
          <Form.Group
            {...formGroupProps}
            className={clsx(
              'form-group-phone-input form-group mb-3 mb-lg-fg',
              formGroupProps?.className,
              {
                required,
              },
              {
                error,
              }
            )}
          >
            <span className="small-label pb-2 pe-1">
              {label}
              {required ? '*' : ''}
            </span>
            <PhoneInput
              defaultCountry={DEFAULT_COUNTRY}
              value={value ?? ''}
              onChange={handleChange}
              onBlur={onBlur}
              className="app-phone-input row gx-0 align-items-center-start"
              countrySelectComponent={props => (
                <CountrySelect {...props} disabled={canEdit} />
              )}
              {...phoneInputProps}
              countrySelectProps={{
                wrapperComponent: SelectWrapper,
              }}
              numberInputProps={{
                className: 'form-control ms-1',
              }}
              addInternationalOption={false}
              disabled={canEdit}
            />

            {/* Render children here */}
            {children}
            {error && (
              <Typography
                variant="subtitle2"
                className="text-secondary mt-vtl-3"
              >
                {String(error.message)}
              </Typography>
            )}
          </Form.Group>
        );
      }}
    />
  );
};

PhoneController.defaultProps = {
  label: undefined,
};

export default PhoneController;
