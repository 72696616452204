import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Container} from 'reactstrap';
import {Typography} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import useTitle from '../../hooks/useTitle';
import Button from '../Common/Button';
import TopPaper from '../Common/TopPaper';
import Tabs, {TabType} from '../Common/Tabs';
import BuyerList from './BuyerList';
import SellerList from './SellerList';
import AddPopup from '../Widgets/AddPopup';
import CreateBuyerForm from './Form/CreateBuyerForm';
import CreateSellerForm from './Form/CreateSellerForm';
import {useNavigate, useMatch} from 'react-router-dom';
import * as urls from '../../constants/urls';
import {get} from 'lodash';

/** Users lists */
const UserList = () => {
  // Translations
  const intl = useIntl();
  const match = useMatch(urls.USERS_LIST_TAB_PATH);
  const navigate = useNavigate();
  // Page title
  const {intlTitle} = useTitle();
  const defaultTab = 'buyer';
  // get activeTab from routematch
  const getTab = (m: any, defaultTab: string) =>
    get(m, 'params[tab]', defaultTab ?? (tabs.length > 1 ? 'seller' : null));
  // List tabs
  const tabs: TabType[] = [
    {
      label: intl.formatMessage({id: 'dashboard.users.buyer'}),
      value: 'buyer',
    },
    {
      label: intl.formatMessage({id: 'dashboard.users.seller'}),
      value: 'seller',
    },
  ];

  // Active tab
  const [activeTab, setActiveTab] = useState(getTab(match, defaultTab));

  // Add user popup
  const [addPopup, setAddPopup] = useState('');

  // switch active tab
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setAddPopup('');
      navigate(urls.USERS_LIST_TAB_PATH.replace(':tab', tab));
    }
  };

  // set current tab on load
  useEffect(() => {
    const active = getTab(match, defaultTab);
    if (active !== activeTab) {
      setActiveTab(active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  // Top Header
  const header = (
    <>
      {/* bigger padding bottom if tabs not displayed */}
      <div className={clsx('row align-items-center header-row')}>
        <div className="col-lg-6 col-sm-12 text-center text-lg-start">
          {/* User list */}
          <Typography variant="h3" className="font-weight-bolder">
            <FormattedMessage id="dashboard.users.user_list" />
          </Typography>
        </div>
        <div className="col-lg-6 col-sm-12 text-center text-lg-end d-none d-lg-block">
          {/* Add User */}
          <Button
            primary
            title={intl.formatMessage({
              id: `dashboard.users.add_${activeTab}`,
            })}
            className="dashboard-btn"
            icon={<AddIcon />}
            onClick={() => setAddPopup(activeTab)}
          />
        </div>
      </div>
      {/* Display tabs */}
      {tabs.length > 1 && (
        <div className="row">
          <div className="col">
            {/* Tabs */}
            <Tabs
              className="mb-0"
              indicators={false}
              value={activeTab}
              tabs={tabs}
              onChange={toggle}
            />
          </div>
        </div>
      )}
    </>
  );

  // close Add Seller Popup after successful creation
  const closePopup = () => {
    setAddPopup('');
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{intlTitle('dashboard.users.user_list_title')}</title>
      </Helmet>
      <AddPopup
        opened={addPopup !== ''}
        onClose={closePopup}
        title={intl.formatMessage({id: `dashboard.users.add_${activeTab}`})}
        activeTab={activeTab}
      >
        {activeTab === 'buyer' && (
          <CreateBuyerForm form={`${activeTab}-form`} closePopup={closePopup} />
        )}
        {activeTab === 'seller' && (
          <CreateSellerForm
            form={`${activeTab}-form`}
            closePopup={closePopup}
          />
        )}
      </AddPopup>
      <div className="dashboard-wrapper tabs-contained">
        <Container fluid>
          <TopPaper>{header}</TopPaper>
          {activeTab === 'buyer' && (
            <BuyerList onAddClick={() => setAddPopup(activeTab)} />
          )}
          {activeTab === 'seller' && (
            <SellerList onAddClick={() => setAddPopup(activeTab)} />
          )}
        </Container>
      </div>
    </HelmetProvider>
  );
};

export default UserList;
