import {useCallback, useEffect, useMemo, useState} from 'react';
import {Product} from '../../types/Product';
import {ProductCategory} from '../../types/ProductCategory';
import {ProductOptionGroup} from '../../types/ProductOptionGroup';
import {useIntl} from 'react-intl';
import {formatErrorMessage} from '../../utils/functions';
import {
  useFetchCategoryQuery,
  useFetchProductQuery,
} from '../../services/productCategoryApi';
import {useFetchProductOptionGroupQuery} from '../../services/productOptionGroupApi';
import {useFetchBuyerQuery} from '../../services/buyerApi';
import {BuyerData} from '../../types/BuyerData';
import {
  DiscountAttributeType,
  discountAttributes,
} from '../../types/DiscountEligibilityAttribute';
import {
  DiscountEligibilityType,
  EligibilityItem,
} from '../../types/DiscountEligibility';
import {Control, useFieldArray} from 'react-hook-form';
import {DiscountForm} from './useDiscountFormRequest';

type DiscountCriteriaProps = {
  storeId?: number;
  criteria: DiscountEligibilityType;
  value: EligibilityItem;
  showProgressDialog?: boolean;
  groupIndex: number;
  index: number;
  control: Control<DiscountForm, any>;
};

export type AutoCompleteDataType =
  | Product[]
  | BuyerData[]
  | ProductCategory[]
  | ProductOptionGroup[];

export default function useDiscountCriteria(props: DiscountCriteriaProps) {
  const {showProgressDialog, storeId, value, criteria, groupIndex, control} =
    props;

  const intl = useIntl();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  // selected attribute for eligibility ( product_name ... etc )
  const [selectedAttribute, setSelectedAttribute] =
    useState<DiscountAttributeType | null>();

  // eligibility value
  const [eligibilityValue, setEligibilityValue] =
    useState<EligibilityItem>(value);

  const [entity, setEntity] = useState<string>();
  const [search, setSearch] = useState<string>('');

  // auto complete options based on selected attribute entity name
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<
    Record<string, any>[]
  >([]);

  // complete data to store data to retrieve selected items
  const [historyAutoCompleteOption, setHistoryAutoCompleteOptions] = useState<
    Record<string, any>[]
  >([]);

  const {update} = useFieldArray({
    control,
    name: `eligibilities.${groupIndex}.eligibilityConditions`,
  });

  const memoizedValue = useMemo(
    () => value,
    [value.propertyName, value.entityName, value.condition, value.items]
  );

  useEffect(() => {
    if (memoizedValue) {
      formatEligibilityValue(memoizedValue);
    }
  }, [value.propertyName, value.entityName, value.condition, value.items]);

  const formatEligibilityValue = useCallback(
    (value: EligibilityItem) => {
      const {entityName, propertyName, condition, items} = value;
      const findAttribute = discountAttributes[criteria].find(
        item =>
          item.propertyName === propertyName && item.entityName === entityName
      ) as DiscountAttributeType;
      if (!findAttribute) {
        return;
      }
      setSelectedAttribute(findAttribute);
      if (entityName !== entity) {
        setEntity(entityName);
      }
      setEligibilityValue({
        entityName,
        propertyName,
        condition,
        items,
        value: findAttribute?.value,
      });
    },
    [criteria, entity]
  );

  const getProductSearchType = useMemo(
    () => (productEligibilityValue: EligibilityItem) => {
      return `${productEligibilityValue.entityName}.${productEligibilityValue.propertyName}`;
    },
    [eligibilityValue.entityName, eligibilityValue.propertyName]
  );

  const queryKey = useMemo(
    () => Math.random().toFixed(3).toString(),
    [entity, search]
  );

  const {data: products, isSuccess: isProductSuccess} = useFetchProductQuery(
    {
      query: {
        storeId,
        orderBy: 'name',
        order: 'ASC',
        search: search && entity === 'product' ? search : '',
        searchType:
          search && entity === 'product'
            ? getProductSearchType(eligibilityValue)
            : '',
        uniqueId: queryKey,
      },
      showProgressDialog: (showProgressDialog || !!search) ?? false,
      formatErrorMessage: error => formatErrorMessage(error, intl),
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      skip: !entity || entity !== 'product',
    }
  );

  const {data: productCategories, isSuccess: isProductCategorySuccess} =
    useFetchCategoryQuery(
      {
        id: storeId,
        query: {
          orderBy: 'name',
          order: 'ASC',
          search: search && entity === 'product_category' ? search : '',
          uniqueId: queryKey,
        },
        showProgressDialog: (showProgressDialog || !!search) ?? false,
        formatErrorMessage: error => formatErrorMessage(error, intl),
      },
      {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        skip: !entity || entity !== 'product_category',
      }
    );

  const {data: optionGroups, isSuccess: isProductOptionGroupSuccess} =
    useFetchProductOptionGroupQuery(
      {
        storeId,
        query: {
          orderBy: 'name',
          order: 'ASC',
          search: search && entity === 'product_option_group' ? search : '',
          uniqueId: queryKey,
        },
        showProgressDialog: (showProgressDialog || !!search) ?? false,
        formatErrorMessage: error => formatErrorMessage(error, intl),
      },
      {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        skip: !entity || entity !== 'product_option_group',
      }
    );

  const {data: users, isSuccess: isUserSuccess} = useFetchBuyerQuery(
    {
      query: {
        search: search && entity === 'user' ? search : '',
        uniqueId: queryKey,
      },
      showProgressDialog: (showProgressDialog || !!search) ?? false,
      formatErrorMessage: error => formatErrorMessage(error, intl),
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
      skip: !entity || entity !== 'user',
    }
  );

  useEffect(() => {
    if (entity === 'product' && isProductSuccess) {
      const productData =
        products?.data?.map(item => ({
          ...item,
          entityName: entity,
        })) ?? [];
      setIsSuccess(isProductSuccess);
      setAutoCompleteOptions(productData);
      setHistoryAutoCompleteOptions([
        ...historyAutoCompleteOption,
        ...productData,
      ]);
    }
    if (entity === 'product_category' && isProductCategorySuccess) {
      const categoryData =
        productCategories?.data?.map(item => ({
          ...item,
          entityName: entity,
        })) ?? [];
      setIsSuccess(isProductCategorySuccess);
      setAutoCompleteOptions(categoryData);
      setHistoryAutoCompleteOptions([
        ...historyAutoCompleteOption,
        ...categoryData,
      ]);
    }
    if (entity === 'product_option_group' && isProductOptionGroupSuccess) {
      const optionGroupData =
        optionGroups?.data?.map(item => ({
          ...item,
          entityName: entity,
        })) ?? [];
      setIsSuccess(isProductOptionGroupSuccess);
      setAutoCompleteOptions(optionGroupData);
      setHistoryAutoCompleteOptions([
        ...historyAutoCompleteOption,
        ...optionGroupData,
      ]);
    }
    if (entity === 'user' && isUserSuccess) {
      const userData =
        users?.data?.map(item => ({
          ...item,
          entityName: entity,
        })) ?? [];
      setIsSuccess(isUserSuccess);
      setAutoCompleteOptions(userData);
      setHistoryAutoCompleteOptions([
        ...historyAutoCompleteOption,
        ...userData,
      ]);
    }
  }, [
    isProductSuccess,
    isProductCategorySuccess,
    isProductOptionGroupSuccess,
    isUserSuccess,
  ]);

  return {
    isSuccess,
    selectedAttribute,
    setSelectedAttribute,
    entityName: entity,
    setEntityName: setEntity,
    search,
    setSearch,
    autoCompleteOptions,
    setAutoCompleteOptions,
    historyAutoCompleteOption,
    setHistoryAutoCompleteOptions,
    eligibilityValue,
    setEligibilityValue,
    update,
  };
}
