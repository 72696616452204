import React from 'react';
import clsx from 'clsx';
import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Select from '../../Common/Select';
import Pagination from '../../Common/Pagination';

/**
 * Table Pagination row
 * contains Rows per page selector and pagination with current page input field
 */
export type TablePaginationProps = {
  /**
   * Additional class name
   */
  className: string;
  /**
   * Show number of rows options
   */
  rowsPerPageOptions: number[];
  /**
   * Number of rows
   */
  count: number;
  /**
   * Current selected rows per page
   */
  rowsPerPage: number;
  /**
   * Current page
   */
  page: number;
  /**
   *
   */
  bulkOpacity: boolean;
  /**
   * Change current page handler
   */
  onChangePage: (page: number) => void;
  /**
   * Rows per page change handler
   */
  onChangeRowsPerPage: (pages: number) => void;

  /**
   * Hide select rows per page
   */
  hideRowsPerPage?: boolean;
};

const TablePagination = (props: TablePaginationProps) => {
  const {
    className,
    rowsPerPageOptions,
    bulkOpacity,
    count,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    hideRowsPerPage,
  } = props;
  // Translations
  const intl = useIntl();

  return (
    <div
      className={clsx(
        'row align-items-center justify-content-between',
        className
      )}
    >
      {/* Rows per page */}
      <div className="col-lg-6 d-none d-lg-block">
        {hideRowsPerPage ? (
          <div className="d-flex align-items-center">
            <Typography>
              <FormattedMessage id="actions.showing" />
              {` ${rowsPerPage} `}
              <FormattedMessage id="actions.rows_per_page" />
            </Typography>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <span>
              <FormattedMessage id="actions.show" />
            </span>

            <Select
              value={rowsPerPage.toString()}
              items={rowsPerPageOptions.map(number => ({
                label: number.toString(),
                value: number,
              }))}
              onChange={onChangeRowsPerPage}
            />
            <span>
              <FormattedMessage id="actions.rows_per_page" />
            </span>
          </div>
        )}
      </div>
      {/* Pagination */}
      <div className="col-lg-auto col-sm-12">
        <Pagination
          disabled={bulkOpacity}
          pages={Math.ceil(count / rowsPerPage)}
          current={+page}
          textPrev={intl.formatMessage({id: 'actions.prev'})}
          textNext={intl.formatMessage({id: 'actions.next'})}
          onChangePage={onChangePage}
        />
      </div>
    </div>
  );
};

export default TablePagination;
