import {useTheme} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import cx from 'clsx';
import {useEffect, useState} from 'react';
import {Image, Stack} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {Col, Row} from 'reactstrap';
// @ts-ignore
import {Draggable} from 'react-drag-and-drop';
import {ReactComponent as DragIcon} from '../../../../assets/images/drag.svg';
import {
  UpdateProductParam,
  useEditProductMutation,
} from '../../../../services/productCategoryApi';
import {Product, ProductStatus} from '../../../../types/Product';
import {ProductCategory} from '../../../../types/ProductCategory';
import {
  formatErrorMessage,
  twoDecimalPlaces,
} from '../../../../utils/functions';
import BlankProductImage from '../../../Common/BlankProductImage';
import ContentEditable from '../../../Common/ContentEditable';
import Dropdown, {ItemType} from '../../../Common/Dropdown';
import IconButton from '../../../Common/IconButton';
import StatusIndicator from '../../../Common/StatusIndicator';
import ProductMenuActions from './ProductMenuActions';

export type FormState = {
  name: string;
  price: number;
  description: string;
  chineseName?: string;
  status: 'on' | 'off' | 'offForToday' | 'deleted';
  categoryId?: number;
};

export type ContentState = Omit<FormState, 'categoryId'>;

export type ProductListRowProps = {
  item: Product;
  isSelectedCategory: boolean;
  categories: ProductCategory[];
  onSelect: (product: Product) => void;
  updateProductList: (product: Product) => void;
  deleteProduct: (productId: number, name: string) => void;
  gotoProductDetailsPage: (id: string) => void;
};

const ProductListRow = ({
  item,
  isSelectedCategory,
  categories,
  onSelect,
  updateProductList,
  deleteProduct,
  gotoProductDetailsPage,
}: ProductListRowProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const [isDragging, setIsDragging] = useState(false);
  const [contentState, setContentState] = useState<ContentState>({
    description: item?.description ?? '',
    name: item.name,
    price: item.price?.price ?? 0,
    status: item.status,
    chineseName: item?.chineseName ?? '',
  });

  const onInputChange = (text: string | number, type: keyof ContentState) => {
    setContentState({
      ...contentState,
      [type]: text,
    });
  };

  // update product mutation
  const [updateProduct, {data, isSuccess}] = useEditProductMutation();
  useEffect(() => {
    if (data && isSuccess) {
      updateProductList(data.data);
    }
  }, [data, isSuccess]);

  // update quick edit
  const onChangeHandler = (text: string | number, type: string) => {
    if (type !== 'categoryId') {
      onInputChange(text, type as keyof ContentState);
    }
    if (item[type as keyof Product] === text) {
      return;
    }
    const productData: FormState = {
      name: item.name,
      chineseName: item.chineseName ?? undefined,
      description: item?.description,
      price: item.price.price,
      status: item.status,
      categoryId: item?.category?.id,
    };
    const data: UpdateProductParam = {
      id: item.id,
      body: {
        ...productData,
        [type]: type === 'price' ? parseFloat(text as string) : text,
      },
      showProgressDialog: false,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message =
          type === 'categoryId'
            ? intl.formatMessage(
                {
                  id: 'messages.custom_update_product',
                },
                {
                  product_name: item.name,
                  category_name: categories.find(item => item.id === text)
                    ?.name,
                }
              )
            : intl.formatMessage({
                id: 'messages.update_success_message',
              });
        return message;
      },
    };
    updateProduct(data);
  };

  // Status list
  const statuses: ItemType[] = [
    {
      id: ProductStatus.On,
      label: intl.formatMessage({id: 'status.on'}),
      onClick: () => onChangeHandler(ProductStatus.On, 'status'),
    },
    {
      id: ProductStatus.OffForToday,
      label: intl.formatMessage({id: 'status.off_today'}),
      onClick: () => onChangeHandler(ProductStatus.OffForToday, 'status'),
    },
    {
      id: ProductStatus.Off,
      label: intl.formatMessage({id: 'status.off'}),
      onClick: () => onChangeHandler(ProductStatus.Off, 'status'),
    },
  ];

  // Mobile hidden fields open state
  const [open, setOpen] = useState(false);
  // status indicator
  const statusColor: any = {
    on: 'green',
    off: 'error',
    offForToday: 'orange',
  };

  const formattedStatus = {
    on: 'On',
    off: 'Off',
    offForToday: 'Off Today',
    deleted: 'Deleted',
  };

  // Row wrapper
  const DraggableWrapper = ({item, children}: any) =>
    isSelectedCategory ? (
      <Draggable
        type="product"
        data={item.id}
        className={cx('MuiTableRow-root', {
          'disable-bottom-borders': open,
          'is-dragging': isDragging,
        })}
        role="row"
        onDragStart={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
      >
        {children}
      </Draggable>
    ) : (
      <div className="MuiTableRow-root">{children}</div>
    );

  return (
    <>
      <DraggableWrapper item={item}>
        <TableCell
          component="div"
          className={cx('names')}
          onClick={() => onSelect(item)}
        >
          <Stack direction="horizontal" gap={2}>
            {isSelectedCategory && (
              <span className="px-lg-2 text-border">
                <DragIcon
                  style={{
                    color: isDragging
                      ? theme.palette.green.main
                      : theme.palette.divider,
                  }}
                />
              </span>
            )}
            {item.image ? (
              <Image
                src={item.image}
                className="preview rounded-sm"
                onClick={() => gotoProductDetailsPage(item.id.toString())}
                style={{cursor: 'pointer'}}
              />
            ) : (
              <BlankProductImage
                clickable
                onClick={() => gotoProductDetailsPage(item.id.toString())}
              />
            )}
            <div>
              <ContentEditable
                content={contentState.name}
                onChangeContent={onChangeHandler}
                name="name"
                className="font-weight-bold"
              />
              {item.chineseName && (
                <ContentEditable
                  content={contentState.chineseName ?? ''}
                  onChangeContent={onChangeHandler}
                  name="chineseName"
                />
              )}
            </div>
          </Stack>
        </TableCell>
        <TableCell className="d-none d-lg-table-cell" component="div">
          {item.description && (
            <ContentEditable
              content={contentState.description}
              onChangeContent={onChangeHandler}
              name="description"
              typography={{variant: 'subtitle2'}}
              maxLines={2}
            />
          )}
        </TableCell>
        <TableCell
          className="d-none d-lg-table-cell"
          component="div"
          style={{width: '12%', minWidth: 70}}
        >
          <ContentEditable
            content={twoDecimalPlaces(contentState.price) || '0.00'}
            onChangeContent={onChangeHandler}
            name="price"
            inputType="currency"
          />
        </TableCell>
        <TableCell component="div" style={{width: '12%'}}>
          {/* Change status */}
          <Dropdown
            items={statuses}
            value={contentState.status}
            title={
              <StatusIndicator
                label={formattedStatus[contentState.status]}
                color={statusColor[contentState.status]}
              />
            }
          />
        </TableCell>
        <TableCell component="div" style={{width: 70}}>
          <div className="d-flex align-items-center">
            {/* Actions menu */}
            <div className="px-2 px-lg-3">
              <ProductMenuActions
                product={item}
                categories={categories}
                updateProduct={onChangeHandler}
                deleteProduct={deleteProduct}
                gotoProductDetailsPage={gotoProductDetailsPage}
              />
            </div>
            {/* Mobile dropdown */}
            <div className="d-lg-none px-1">
              <IconButton
                variant="action"
                size="xs"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </div>
          </div>
        </TableCell>
      </DraggableWrapper>
      <TableRow className="d-lg-none">
        <TableCell className="p-0 rounded-0 bg-transparent" colSpan={4}>
          <Collapse in={open} unmountOnExit>
            <div className="hidden-cells ps-4 pe-1 py-2">
              <Row className="pb-1">
                <Col xs={5}>
                  <Typography variant="subtitle2" className="font-weight-bold">
                    <FormattedMessage id="dashboard.store_details.product_categories_tab.description" />
                  </Typography>
                </Col>
                <Col>
                  {item.description && (
                    <ContentEditable
                      content={contentState.description}
                      onChangeContent={onChangeHandler}
                      name="description"
                      maxLines={3}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={5}>
                  <Typography variant="subtitle2" className="font-weight-bold">
                    <FormattedMessage id="dashboard.store_details.product_categories_tab.price" />
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="subtitle2">
                    <ContentEditable
                      content={contentState.price || 0.0}
                      onChangeContent={onChangeHandler}
                      name="price"
                      inputType="currency"
                    />
                  </Typography>
                </Col>
              </Row>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProductListRow;
