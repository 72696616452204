import {useIntl} from 'react-intl';
import {
  useLazyExportStatementCsvQuery,
  useSendStatementInvoiceMutation,
  useUpdateStatementMutation,
} from '../../services/statementApi';
import {StatementStatusEnum} from '../../types/Statement';
import {formatErrorMessage} from '../../utils/functions';

export type UseStatementDetailsProps = {
  id: number;
};

export default function useStatementDetails() {
  const intl = useIntl();

  const [updateOrder] = useUpdateStatementMutation();

  const [exportStatement] = useLazyExportStatementCsvQuery();

  const [sendInvoiceStatement] = useSendStatementInvoiceMutation();

  const updateStatementStatus = async (
    statementId: number,
    status: StatementStatusEnum
  ) => {
    updateOrder({
      statementId,
      body: {
        status: status,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () =>
        intl.formatMessage(
          {
            id: 'transaction_list.statement_status_updated',
          },
          {
            status,
          }
        ),
    });
  };

  const emailStatementInvoice = async (statementId: number) => {
    sendInvoiceStatement({
      statementId,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () =>
        intl.formatMessage({
          id: 'transaction_list.statement_invoice_sent',
        }),
    });
  };

  return {
    updateStatementStatus,
    emailStatementInvoice,
    exportStatement,
  };
}
