import {useIntl} from 'react-intl';
import {Container, Row, Col, Form} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import StatusIndicator from '../../../Common/StatusIndicator';
import Button from '../../../Common/Button';
import RadioGroupController from '../../../Form/HookForm/RadioGroupController';
import InputController from '../../../Form/HookForm/InputController';
import TextAreaController from '../../../Form/HookForm/TextAreaController';
import DiscountValidations from './Validation';
import UsageLimits from './UsageLimits';
import Summary from './Summary';
import ConditionsSetup from '../../../Widgets/ConditionsSetup/ConditionsSetup';
import useDiscount from '../../../../hooks/useDiscount';
import SaveFeature from '../../../Shared/SaveFeature';
import ConfirmationPopup from '../../../Shared/ConfirmationPopup';
import {useState} from 'react';
import {Discount} from '../../../../types/Discount';
import {capitalizeFirstLetter} from '../../../../utils/functions';
import {UseDiscountCode} from '../../../../hooks/discount/useDiscountFormRequest';
import {DiscountEligibilityType} from '../../../../types/DiscountEligibility';

type DiscountHeaderProps = {
  onDelete: () => void;
  discount: Discount;
};

const DiscountHeader = ({onDelete, discount}: DiscountHeaderProps) => {
  const intl = useIntl();
  // status indicator
  const statusColor: {
    [key: string]: 'green' | 'error' | 'orange' | 'secondary' | 'danger';
  } = {
    active: 'green',
    expired: 'secondary',
    inactive: 'error',
    upcoming: 'orange',
  };

  return (
    <Container fluid className="top-block border-0">
      <div className="details-header">
        <Row>
          <Col lg>
            <div className="details-header-part text-center text-lg-start">
              <div className="d-flex flex-column align-items-center align-items-lg-start">
                <Typography variant="h3" className="font-weight-bolder">
                  {discount.name}
                </Typography>
                <StatusIndicator
                  label={capitalizeFirstLetter(
                    discount.status.toLocaleLowerCase()
                  )}
                  color={statusColor[discount.status]}
                  className="mt-vtl-3"
                />
              </div>
            </div>
          </Col>
          <Col lg="auto">
            <div className="text-center text-lg-start pt-vtl-15 pt-lg-0">
              <Button
                link
                title={intl.formatMessage({
                  id: 'dashboard.discount_details.delete_discount',
                })}
                className="details-action"
                onClick={onDelete}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const DiscountDetails = () => {
  const intl = useIntl();

  // useDiscount form hook and available methods
  const {
    control,
    handleSubmit,
    submit,
    discountId,
    resetFormField,
    count,
    deleteDiscountRequest,
    discount,
    formValuesWatch,
    defaultRepeatedDays,
    setValue,
  } = useDiscount();

  // watch useDiscountCode field
  const {useDiscountCode, repeatable, type} = formValuesWatch;

  // delete modal popup state
  const [openModal, setOpenModal] = useState(false);

  const openDeleteModal = () => setOpenModal(true);

  const deleteDiscount = () => {
    setOpenModal(false);
    deleteDiscountRequest();
  };

  // cancel save
  const cancelSave = () => {
    resetFormField();
  };

  const setFormValue = (name: string, value: any) => {
    setValue(name as any, value, {shouldDirty: true});
  };

  return (
    <div className="details-page">
      {discountId && discount ? (
        <DiscountHeader discount={discount} onDelete={openDeleteModal} />
      ) : null}
      {/* Delete discount confirmation */}
      <ConfirmationPopup
        opened={openModal}
        handleClose={() => setOpenModal(false)}
        onOk={deleteDiscount}
        description={intl.formatMessage({
          id: 'messages.delete_message',
        })}
      />
      <div role="tabpanel" className="h-auto">
        <Container fluid>
          {count > 0 && (
            <SaveFeature
              onClose={cancelSave}
              open
              keyType="discount"
              num={count}
              type="submit"
              form="create-discount-form"
              saveButtonTitle={
                discount ? undefined : intl.formatMessage({id: 'actions.add'})
              }
              description={
                discount
                  ? undefined
                  : intl.formatMessage(
                      {id: 'dashboard.num_added_popup_message'},
                      {num: count}
                    )
              }
            />
          )}
          <Form id="create-discount-form" onSubmit={handleSubmit(submit)}>
            <div className="content-block">
              <Row className="g-3">
                <Col lg={8}>
                  <div className="card details-card mb-3">
                    <div className="card-body">
                      {/* Status */}
                      {discountId && (
                        <RadioGroupController
                          name="isActive"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.discount_details.status',
                          })}
                          options={[
                            {
                              label: intl.formatMessage({
                                id: 'dashboard.discount_details.active',
                              }),
                              value: 'active',
                            },
                            {
                              label: intl.formatMessage({
                                id: 'dashboard.discount_details.inactive',
                              }),
                              value: 'inactive',
                            },
                          ]}
                        />
                      )}
                      {/* Name */}
                      <InputController
                        name="name"
                        control={control}
                        label={intl.formatMessage({
                          id: 'dashboard.discount_details.name',
                        })}
                      />
                      {/* Discount code */}
                      <RadioGroupController
                        name="useDiscountCode"
                        control={control}
                        label={intl.formatMessage({
                          id: 'dashboard.discount_details.discountCode',
                        })}
                        options={[
                          {
                            label: intl.formatMessage({
                              id: 'actions.yes',
                            }),
                            value: UseDiscountCode.Yes,
                          },
                          {
                            label: intl.formatMessage({
                              id: 'actions.no',
                            }),
                            value: UseDiscountCode.No,
                          },
                        ]}
                      />
                      {/* Code */}
                      {useDiscountCode &&
                        useDiscountCode === UseDiscountCode.Yes && (
                          <InputController
                            name="code"
                            control={control}
                            label={intl.formatMessage({
                              id: 'dashboard.discount_details.code',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'dashboard.discount_details.code',
                            })}
                          />
                        )}
                      {/* Description */}
                      <TextAreaController
                        name="description"
                        control={control}
                        label={intl.formatMessage({
                          id: 'dashboard.discount_details.description',
                        })}
                        showLabelHint
                        labelHintText={intl.formatMessage({
                          id: 'dashboard.discount_details.description_tooltip',
                        })}
                        rows={2}
                      />
                      {/* Message checkout */}
                      <TextAreaController
                        name="checkoutMessage"
                        control={control}
                        label={intl.formatMessage({
                          id: 'dashboard.discount_details.message_checkout',
                        })}
                        showLabelHint
                        labelHintText={intl.formatMessage({
                          id: 'dashboard.discount_details.message_checkout_tooltip',
                        })}
                        rows={2}
                      />
                    </div>
                  </div>
                  <DiscountValidations
                    control={control}
                    setValue={setFormValue}
                    discountRepeat={repeatable}
                    type={type}
                    defaultDays={defaultRepeatedDays}
                    isEdit={!!discountId}
                  />
                  <UsageLimits control={control} />
                  <ConditionsSetup
                    title={intl.formatMessage({
                      id: 'dashboard.discount_details.product_eligibility',
                    })}
                    noConditionText={intl.formatMessage({
                      id: 'dashboard.discount_details.product_no_condition',
                    })}
                    criteria={DiscountEligibilityType.Product}
                    control={control}
                  />
                  <ConditionsSetup
                    title={intl.formatMessage({
                      id: 'dashboard.discount_details.buyer_eligibility',
                    })}
                    noConditionText={intl.formatMessage({
                      id: 'dashboard.discount_details.buyer_no_condition',
                    })}
                    criteria={DiscountEligibilityType.Buyer}
                    control={control}
                  />
                </Col>
                <Col lg={4}>
                  <Summary control={control} />
                </Col>
              </Row>
            </div>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default DiscountDetails;
