import {useState} from 'react';
import {Alert, Button, Form, Spinner} from 'react-bootstrap';
import {useGetPostsQuery} from '../../../store/queries/exampleApi';

const QueryPlayground = () => {
  const [count, setcount] = useState(0);
  const [showProgressDialog, setShowProgressDialog] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const {data, isSuccess, isLoading, isError} = useGetPostsQuery(
    {
      showProgressDialog,
      successMessage: showSuccessMessage
        ? successMessage || 'Query success message'
        : undefined,
      params: {
        count,
      },
    },
    {
      skip: count < 1,
    }
  );

  const handleClick = () => {
    setcount(count + 1);
  };

  return (
    <div>
      <h2>RTK Query</h2>
      <div>
        <Form.Control
          value={successMessage}
          onChange={(e: any) => setSuccessMessage(e.target.value)}
          placeholder="Success message"
        />
        <Form.Check
          type="checkbox"
          label="Show Progress"
          checked={showProgressDialog}
          onChange={(e: any) => setShowProgressDialog(e.target.checked)}
        />
        <Form.Check
          type="checkbox"
          label="Show Success Message"
          checked={showSuccessMessage}
          onChange={(e: any) => setShowSuccessMessage(e.target.checked)}
        />
        <div className="d-flex align-items-center">
          <Button onClick={handleClick}>Get data</Button>
          {isLoading && <Spinner className="ms-2" />}
        </div>
      </div>
      <div className="mt-2">
        {isSuccess && !isLoading && (
          <div className="bg-dark text-white rounded p-2">
            <pre
              className="text-wrap"
              style={{maxHeight: 200, overflowX: 'hidden'}}
            >
              {JSON.stringify(data)}
            </pre>
          </div>
        )}
        {isError && <Alert variant="danger">Error</Alert>}
      </div>
    </div>
  );
};

export default QueryPlayground;
