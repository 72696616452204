import {useIntl} from 'react-intl';
import {Row, Col} from 'reactstrap';
import Box from '@material-ui/core/Box';
import {Stack} from 'react-bootstrap';
import {ReactComponent as PlusRedIcon} from '../../../assets/images/plusRed.svg';
import {ReactComponent as CrossGrayIcon} from '../../../assets/images/crossGray.svg';
import Button from '../../Common/Button';
import Criteria from './Criteria';
import {DiscountAttributeType} from '../../../types/DiscountEligibilityAttribute';
import {Control, useFieldArray} from 'react-hook-form';
import {DiscountForm} from '../../../hooks/discount/useDiscountFormRequest';
import {DiscountEligibilityType} from '../../../types/DiscountEligibility';

export type ConditionsGroupProps = {
  values: DiscountAttributeType[];
  criteria: DiscountEligibilityType;
  groupIndex: number;
  control: Control<DiscountForm, any>;
  handleGroupDelete: (index: number) => void;
};

const ConditionsGroup = (props: ConditionsGroupProps) => {
  const {values, groupIndex, control, criteria, handleGroupDelete} = props;
  const intl = useIntl();

  const {
    fields: conditions,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `eligibilities.${groupIndex}.eligibilityConditions`,
  });

  const handleNewConditionClick = () => {
    append({
      condition: 'is_equal_to',
      entityName: '',
      items: [],
      propertyName: '',
    });
  };

  const deleteGroup = () => {
    handleGroupDelete(groupIndex);
  };

  const handleConditionDelete = (index: number) => {
    remove(index);
  };

  return (
    <div className="conditions-group">
      <Box bgcolor="background.default" p={1.5} borderRadius={4}>
        <Stack className="gap-2">
          {conditions &&
            conditions.map((item, index: number) => (
              <Criteria
                key={item.id}
                index={index}
                criteria={criteria}
                groupIndex={groupIndex}
                value={item}
                attributes={values}
                control={control}
                handleConditionDelete={handleConditionDelete}
              />
            ))}
          {/* Actions */}
          <Row className="g-2">
            <Col lg={3}>
              <Button
                link
                small
                primary
                title={intl.formatMessage({id: 'conditions.new_condition'})}
                icon={<PlusRedIcon />}
                onClick={handleNewConditionClick}
              />
            </Col>
            <Col lg={9}>
              <Button
                link
                small
                secondary
                title={intl.formatMessage({id: 'conditions.delete_group'})}
                icon={<CrossGrayIcon />}
                onClick={deleteGroup}
              />
            </Col>
          </Row>
        </Stack>
      </Box>
    </div>
  );
};

export default ConditionsGroup;
