import {Col, Container, Row, Form} from 'reactstrap';
import {Stack} from 'react-bootstrap';
import GoogleAuthentication from './GoogleAuthentication';
import FacebookAuthentication from './FacebookAuthentication';
import GoogleMap from './GoogleMap';
import useUpdateAppThirdPartyIntegration from '../../../../hooks/useUpdateAppThirdPartyIntegration';
import SaveFeature from '../../../Shared/SaveFeature';
import {unstable_usePrompt as usePrompt} from 'react-router-dom';
import {useIntl} from 'react-intl';

type ThirdPartyIntegrationTabProps = {
  appId: string;
};

const ThirdPartyIntegrationTab = ({appId}: ThirdPartyIntegrationTabProps) => {
  const intl = useIntl();

  const {handleSubmit, onSubmit, control, resetFormValues, count} =
    useUpdateAppThirdPartyIntegration({
      appId: Number(appId),
    });

  // alert user when they try to reload when edited field has not been saved
  usePrompt({
    when: count > 0,
    message: intl.formatMessage({id: 'dashboard.confirm_not_saved_message'}),
  });

  return (
    <div role="tabpanel">
      {count > 0 && (
        <SaveFeature
          onClose={resetFormValues}
          open
          keyType="app"
          num={count}
          type="submit"
          form="third-party-settings"
        />
      )}
      <Container fluid>
        <div>
          <Row className="g-3">
            <Col lg={7} xxl={6}>
              <Form onSubmit={handleSubmit(onSubmit)} id="third-party-settings">
                <Stack className="gap-vtl-16 gap-3">
                  <GoogleMap control={control} />
                  <GoogleAuthentication control={control} />
                  <FacebookAuthentication control={control} />
                </Stack>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ThirdPartyIntegrationTab;
