import {useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useParams, unstable_usePrompt as usePrompt} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  UpdateProductParam,
  UploadProductImageParam,
  useDeleteImageMutation,
  useDeleteProductMutation,
  useGetProductQuery,
  useUpdateProductMutation,
  useImageUploadMutation,
} from '../services/productCategoryApi';
import {Product} from '../types/Product';
import {
  formatErrorMessage,
  isApiValidationError,
  mapErrorToFormFields,
  twoDecimalPlaces,
  validImageOptions,
} from '../utils/functions';
import {DeleteParam} from '../services/storeApi';
import {useSelector} from 'react-redux';
import {
  clearInputFields,
  getNonEmptyFields,
  setField,
} from '../store/slices/productSlice';
import {useDispatch} from 'react-redux';
import {OptionProps} from '../components/Form/Inputs/AutocompleteMultiple';
import {ProductOptionGroup} from '../types/ProductOptionGroup';
import {CustomError} from '../types/CustomError';

export type ProductDetailsFormValues = {
  image: string;
  categoryId: number;
  name: string;
  chineseName: string;
  price: string;
  sku: string;
  status: string;
  description: string;
  externalId?: string;
  optionGroups: OptionProps[];
};

type UseProductDetailProps = {
  linkedOptions: ProductOptionGroup[];
  showSave: boolean;
  goBack: () => void;
};

export default function useProductDetail({
  showSave,
  linkedOptions,
  goBack,
}: UseProductDetailProps) {
  // Translations
  const intl = useIntl();
  // param id
  const {productId} = useParams();
  // redux dispatch
  const dispatch = useDispatch();

  // count non empty fields
  const {count} = useSelector(getNonEmptyFields);

  //clear redux field
  const clearReduxFields = () => {
    dispatch(clearInputFields());
  };

  // Fetch product data
  const {data, isSuccess, isError} = useGetProductQuery(
    {
      id: productId,
      showProgressDialog: true,
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  // alert user when they try to reload when edited field has not been saved
  usePrompt({
    when: (count > 0 || (showSave && !isError)) as boolean,
    message: intl.formatMessage({id: 'dashboard.confirm_not_saved_message'}),
  });
  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent) => {
      if (isError) {
        return;
      }
      if (count > 0 || showSave) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [count, showSave, isError]);

  // product detail form hook
  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
        price: yup
          .number()
          .max(
            9999999,
            intl.formatMessage({id: 'validation.error.max_price_exceed'})
          )
          .typeError(intl.formatMessage({id: 'validation.error.number'}))
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    reset,
    formState: {errors, dirtyFields, touchedFields},
  } = useForm<ProductDetailsFormValues>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
    defaultValues: {
      status: data?.data.status,
    },
  });

  // fill field values
  const handleSetValue = (product: Product) => {
    setValue('price', twoDecimalPlaces(product?.price?.price) ?? '0.00');
    setValue('name', product?.name ?? '');
    setValue('chineseName', product?.chineseName ?? '');
    setValue('description', product?.description ?? '');
    setValue('sku', product?.sku ?? '');
    setValue('status', product?.status ?? '');
    setValue('externalId', product?.externalId ?? '');
    setValue('image', product?.image ?? '');
    setValue('categoryId', product?.category?.id ?? '');
  };

  // set fields on load
  useEffect(() => {
    if (!isSuccess || !data) {
      return;
    }
    clearReduxFields();
    handleSetValue(data.data);
  }, [isSuccess, data]);

  // check if a field change
  const handleFieldChange = (
    name: keyof ProductDetailsFormValues,
    value: number | OptionProps[] | string
  ) => {
    if (!data) {
      return;
    }
    const {data: product} = data;
    const defaultValue =
      name === 'categoryId' ? product?.category?.id : product[name];
    if (defaultValue === value) {
      dispatch(setField({key: name, value: 0}));
    } else {
      dispatch(setField({key: name, value: 1}));
    }
  };

  const fieldWatch = watch();
  const watchFields = [
    'categoryId',
    'name',
    'chineseName',
    'price',
    'description',
    'externalId',
  ];

  // update redux for touched fields
  useEffect(() => {
    const watchFunctions = watchFields.reduce((acc, field) => {
      return {
        ...acc,
        [field]: watch(field as keyof ProductDetailsFormValues),
      };
    }, {});

    watchFields.forEach(field => {
      // @ts-ignore
      const value = watchFunctions[field];
      // @ts-ignore
      if (touchedFields[field] || dirtyFields[field]) {
        handleFieldChange(field as keyof ProductDetailsFormValues, value);
      }
    });
  }, [handleFieldChange, fieldWatch, touchedFields, dirtyFields]);

  const statusWatch = watch('status');
  const optionGroupsWatch = watch('optionGroups');

  // watch for options and status field
  useEffect(() => {
    if (data) {
      if (optionGroupsWatch) {
        dispatch(
          setField({
            key: 'optionGroups',
            value: optionGroupsWatch.length > 0 ? 1 : 0,
          })
        );
      }
      if (statusWatch) {
        handleFieldChange('status', statusWatch);
      }
    }
  }, [optionGroupsWatch, data, statusWatch]);

  // upload image mutation
  const [uploadProductImage, {isSuccess: uploadSuccess}] =
    useImageUploadMutation();

  // delete image mutation
  const [deleteProductImage, {isSuccess: deleteSuccess}] =
    useDeleteImageMutation();

  // update product
  const [updateProduct, {error: updateError, isSuccess: updateSuccess}] =
    useUpdateProductMutation();

  // delete product
  const [deleteProduct, {isSuccess: deleteProductSuccess}] =
    useDeleteProductMutation();

  // upload Image to server
  const uploadImage = (imageData: File) => {
    if (
      !validImageOptions.type.includes(imageData.type) ||
      imageData.size > validImageOptions.maxSize
    ) {
      return;
    }
    const fd = new FormData();
    fd.append('image', imageData);
    const data: UploadProductImageParam = {
      id: productId,
      body: fd,
      showProgressDialog: true,
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'messages.image_upload',
        });
        return message;
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    };
    uploadProductImage(data);
  };

  // delete Image from server
  const deleteImage = () => {
    const data: DeleteParam = {
      id: productId,
      showProgressDialog: true,
      formatSuccessMessage: () => {
        const message = intl.formatMessage({
          id: 'messages.image_delete',
        });
        return message;
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    };
    deleteProductImage(data);
  };

  // update product details
  const submit = (formValue: ProductDetailsFormValues) => {
    // merged linked options
    let updatedLinkedOptionGroups: (string | number)[] = [];
    if (
      (linkedOptions && linkedOptions.length > 0) ||
      (formValue.optionGroups && formValue.optionGroups.length > 0)
    ) {
      const newLinkedOptionGroups = formValue.optionGroups
        ? formValue.optionGroups.map((item: OptionProps) => item.value)
        : [];
      const oldLinkedOptionGroups =
        linkedOptions && linkedOptions.length > 0
          ? linkedOptions?.map(store => store.id)
          : [];
      updatedLinkedOptionGroups = [
        ...oldLinkedOptionGroups,
        ...newLinkedOptionGroups,
      ];
      // @ts-ignore
      delete formValue.options;
    }
    const data: UpdateProductParam = {
      id: productId,
      body: {
        ...formValue,
        price: Number(formValue.price),
        externalId: formValue.externalId ? formValue.externalId : undefined,
        optionGroups: updatedLinkedOptionGroups,
      },
      showProgressDialog: true,
      formatErrorMessage: error => {
        if (error.data.message === 'EXISTING_PRODUCT_ID') {
          setError('externalId', {
            type: 'custom',
            message: intl.formatMessage({
              id: `error.${error?.data?.message}`,
            }),
          });
          return '';
        } else {
          return formatErrorMessage(error, intl);
        }
      },
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.update_success_message',
        });
      },
    };
    reset({optionGroups: []});
    updateProduct(data);
  };

  // delete product
  const deleteProductData = (name: string) => {
    const data: DeleteParam = {
      id: productId,
      showProgressDialog: true,
      formatSuccessMessage: () => {
        const message = intl.formatMessage(
          {
            id: 'messages.product_delete',
          },
          {
            name,
          }
        );
        return message;
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    };
    deleteProduct(data);
  };

  useEffect(() => {
    if (deleteProductSuccess) {
      goBack();
    }
  }, [deleteProductSuccess]);

  // clear fields
  const clearFields = () => {
    clearReduxFields();
    if (data) {
      reset({optionGroups: []});
      handleSetValue(data.data);
    }
  };

  useEffect(() => {
    clearReduxFields();
  }, []);

  useEffect(() => {
    if (updateError) {
      handleSetValue(data?.data as Product);
      const typedUpdateError = updateError as CustomError;
      if (isApiValidationError(typedUpdateError)) {
        mapErrorToFormFields(typedUpdateError.data.message, setError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  useEffect(() => {
    if (updateSuccess) {
      clearReduxFields();
      clearFields();
    }
  }, [updateSuccess, dispatch]);

  return {
    product: data?.data,
    isSuccess,
    control,
    handleSubmit,
    setError,
    clearErrors,
    submit,
    uploadImage,
    deleteImage,
    uploadSuccess,
    deleteSuccess,
    errors,
    count,
    clearFields,
    deleteProductData,
  };
}
