import {Theme, makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import {ReactNode} from 'react';

export type CustomIconButtonProps = {
  id?: string;
  /**
   * Icon (SVG)
   */
  children: ReactNode;
  /**
   * Button size
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'sm2';
  /**
   * Fill variant
   */
  variant?: 'filled' | 'default' | 'action';
  /**
   * TRUE if button is disabled
   */
  disabled?: boolean;
  /**
   * Right side number
   */
  numberLabel?: number | undefined;
  /**
   * Additional style class
   */
  className?: string;
  /**
   * onClick callback
   */
  onClick?: (event: any) => void;
  /**
   * onHover callback
   */
  onHover?: (event: any) => void;
  /**
   * onMouseLeave callback
   */
  onMouseLeave?: (event: any) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    minWidth: theme.spacing(4),
    height: theme.spacing(4),
    padding: 7,
    transition: 'background 300ms, color 300s',
    filter: 'brightness(100%)',

    '&:focus': {
      outline: 'none',
    },

    // fill variant default
    '&.default': {
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        '& svg path': {
          fill: theme.palette.secondary.contrastText,
        },
      },
    },
    // fill variant filled
    '&.filled': {
      '&:hover': {
        backgroundColor: 'inherit',
        filter: 'brightness(90%)',
      },
    },
    // fill variant action
    '&.action': {
      backgroundColor: 'transparent',
      '&:hover': {
        filter: 'none',
      },
    },

    '& .number-label-item': {
      padding: theme.spacing(0.6),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.paper,
      fontSize: 14,
      fontWeight: 'bold',
      borderRadius: 10,
      marginLeft: 10,
      marginRight: -3,
      height: 32,
      minWidth: 32,
      justifyContent: 'center',
    },

    [theme.breakpoints.down('md')]: {
      borderRadius: '50%',
      boxShadow: theme.shadows[2],
      width: 42,
      height: 42,
    },
    // size variant small
    '&.small': {
      width: 25,
      height: 25,
      minWidth: 'unset',
      padding: 0,
      boxShadow: theme.shadows[0],
      '& > svg': {
        width: 25,
        height: 25,
      },
    },
    '&.small20': {
      width: 20,
      height: 20,
      minWidth: 'unset',
      padding: 0,
      '& > svg': {
        width: 20,
        height: 20,
      },
    },
    '&.extra-small': {
      width: 16,
      height: 16,
      padding: 0,
      minWidth: 'unset',
      boxShadow: theme.shadows[0],
      transition: 'none',
      margin: 0,
      backgroundColor: 'transparent',
      '& > svg': {
        width: 16,
        height: 16,
        margin: 'auto',
      },
    },
  },
  label: {
    '& > svg': {
      width: 26,
      margin: 'auto',
    },
  },
}));

/**
 * Icon button
 */
const CustomIconButton = ({
  children,
  size,
  variant,
  disabled = false,
  numberLabel,
  className,
  onClick,
  onHover,
  onMouseLeave,
}: CustomIconButtonProps) => {
  const classes = useStyles();
  return (
    <IconButton
      color="secondary"
      classes={{
        root: clsx(
          classes.root,
          size === 'sm' && 'small',
          size === 'sm2' && 'small20',
          size === 'xs' && 'extra-small',
          variant,
          numberLabel && 'with-number-label'
        ),
        label: classes.label,
      }}
      disabled={disabled}
      className={clsx(className && className)}
      onClick={(e: any) => {
        e?.stopPropagation();
        if (typeof onClick === 'function') {
          onClick(e);
        }
      }}
      onMouseEnter={onHover}
      onMouseLeave={onMouseLeave}
    >
      {children}
      {numberLabel && numberLabel > 0 ? (
        <span className="number-label-item d-none d-lg-inline-flex">
          {numberLabel}
        </span>
      ) : null}
    </IconButton>
  );
};

CustomIconButton.defaultProps = {
  id: '',
  disabled: false,
  size: 'md',
  variant: 'default',
  numberLabel: undefined,
  className: '',
  onClick: () => {},
  onHover: () => {},
  onMouseLeave: () => {},
};

export default CustomIconButton;
