import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Image, Stack} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import cx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconButton from '../../../Common/IconButton';
import Modal from '../../../Widgets/Modal';
import {ReactComponent as EditIcon} from '../../../../assets/images/editWhite.svg';
import {ReactComponent as TrashIcon} from '../../../../assets/images/delete.svg';
import DropzoneController from '../DropzoneController';

type ImageFormValues = {
  image: any;
  label: string;
};

type PreviewProps = {
  canEdit?: boolean;
  compact?: boolean;
  src: string;
  sizes?: any;
  label?: string;
  onLoad: () => void;
  onDelete?: () => void;
  uploadImage?: (imageData: File) => void;
  validFileOptions: any;
  canDelete?: boolean;
  shouldFitImage?: boolean;
  isTallImage?: boolean;
};

const Preview = (props: PreviewProps) => {
  const {
    canEdit,
    compact,
    src,
    sizes,
    label,
    onLoad,
    onDelete,
    uploadImage,
    validFileOptions,
    canDelete = true,
    shouldFitImage = false,
    isTallImage = false,
  } = props;

  // Translations
  const intl = useIntl();

  const {
    control,
    watch,
    setError,
    clearErrors,
    formState: {errors},
  } = useForm<ImageFormValues>();
  const submit = () => {
    const formData = watch();
    if (
      !validFileOptions.type.includes(formData?.image?.type) ||
      formData?.image?.size > validFileOptions.maxSize
    ) {
      return;
    }
    uploadImage && uploadImage(formData.image);
    setImagePopup(false);
  };

  // Edit product image popup state
  const [imagePopup, setImagePopup] = useState(false);

  return (
    <>
      <Modal
        opened={imagePopup}
        handleClose={() => setImagePopup(false)}
        title={intl.formatMessage(
          {
            id: 'edit_image_popup.title',
          },
          {label: label?.toLowerCase() ?? 'image'}
        )}
        headerClassName="pb-lg-2"
        onOk={submit}
      >
        <>
          <Typography className="mb-3">
            <FormattedMessage id="edit_image_popup.message" />
          </Typography>
          <DropzoneController
            name="image"
            className="fill-background mb-1"
            control={control}
            setError={setError}
            validFileOptions={validFileOptions}
            clearError={clearErrors}
            errors={errors}
            canDelete={false}
          />
        </>
      </Modal>
      <div
        className={cx(
          'preview-wrapper',
          {editable: canDelete},
          {'longer-preview': isTallImage}
        )}
      >
        {canDelete && (
          <Stack direction="horizontal" gap={2} className="actions">
            {canEdit && (
              <IconButton
                variant="action"
                size="sm"
                onClick={() => setImagePopup(true)}
              >
                <EditIcon />
              </IconButton>
            )}
            <IconButton variant="action" size="sm" onClick={onDelete}>
              <TrashIcon />
            </IconButton>
          </Stack>
        )}
        <Image
          fluid
          className={cx(
            'preview',
            {compact},
            {'image-fit': shouldFitImage},
            {'preview-border': shouldFitImage},
            {'img-tall-fit': isTallImage}
          )}
          src={src}
          style={sizes ? sizes : {}}
          onLoad={onLoad}
        />
      </div>
    </>
  );
};

export default Preview;
