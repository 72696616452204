import {FC, ReactNode} from 'react';
import cx from 'clsx';
import {Droppable, DroppableProps} from 'react-beautiful-dnd';

export interface IDrop extends Omit<DroppableProps, 'children'> {
  children: ReactNode;
  className?: string;
  placeholderProps?: any;
}

const Drop: FC<IDrop> = ({children, className, placeholderProps, ...props}) => {
  return (
    <Droppable {...props}>
      {(provided, snapshot) => (
        <div
          {...provided.innerRef}
          ref={provided.innerRef}
          className={cx(className, 'droppable', {
            'is-dragging': snapshot.isDraggingOver,
          })}
        >
          {children}
          {provided.placeholder}
          <div
            className="custom-paceholder"
            style={{
              position: 'absolute',
              top: placeholderProps.clientY,
              left: placeholderProps.clientX,
              height: placeholderProps.clientHeight,
              width: placeholderProps.clientWidth,
            }}
          />
        </div>
      )}
    </Droppable>
  );
};

export default Drop;
