import {Button, Stack} from 'react-bootstrap';
import {Control, UseFormHandleSubmit} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Col, Form, Row} from 'reactstrap';
import {ReactComponent as ImportIcon} from '../../../../assets/images/import.svg';
import {FilterActionsForm} from '../../../../hooks/useProduct';
import IconButton from '../../../Common/IconButton';
import SearchForm from '../../../Form/SearchForm';

/**
 * Product list filter, bulk actions
 */

type FilterActionsProps = {
  control: Control<FilterActionsForm, any>;
  handleSubmit: UseFormHandleSubmit<FilterActionsForm>;
  submit: (value: FilterActionsForm) => void;
  onClear?: () => void;
  onImport: () => void;
};

const FilterActions = ({
  control,
  handleSubmit,
  submit,
  onClear,
  onImport,
}: FilterActionsProps) => {
  const intl = useIntl();
  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Row className="align-items-stretch justify-content-lg-between py-2 pt-lg-0 gx-2 pb-lg-vtl-22">
        <Col xs lg={6}>
          <SearchForm
            control={control}
            placeholder={intl.formatMessage({
              id: 'dashboard.store_details.product_categories_tab.searchProduct',
            })}
            onClear={onClear}
          />
        </Col>
        <Col xs="auto" className="d-lg-none">
          <IconButton size="md" className="text-success" onClick={onImport}>
            <ImportIcon />
          </IconButton>
        </Col>
        <Col lg="auto" className="d-none d-lg-block">
          <Button
            variant="outline-success"
            className="h-100 w-100 px-0 rounded-sm"
            onClick={onImport}
          >
            <Stack
              direction="horizontal"
              gap={2}
              className="px-3 justify-content-center"
            >
              <ImportIcon />
              <span>
                {intl.formatMessage({
                  id: 'dashboard.store_details.product_categories_tab.import',
                })}
              </span>
            </Stack>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterActions;
