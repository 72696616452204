import Stepper, {StepItemProps} from '../../Widgets/Stepper';
import {useIntl} from 'react-intl';
import {Order, OrderServiceType, OrderStatusEnum} from '../../../types/Order';
import {formatDateToMonthAndTime} from '../../../utils/functions';

type OrderStepperProps = {
  data: Order;
};
const OrderStepper = ({data}: OrderStepperProps) => {
  const intl = useIntl();

  const orderStatus = data.status;
  const serviceType = data.serviceType;
  const orderStatusHistory = [...data.orderStatusHistory].reverse();

  const getSteps = (): StepItemProps[] => {
    const steps: StepItemProps[] = [
      {
        label: intl.formatMessage({id: 'order_details.steps.pending'}),
        time: formatDateToMonthAndTime(
          orderStatusHistory.find(
            status => status.status === OrderStatusEnum.Pending
          )?.createdAt ?? orderStatusHistory[0].createdAt
        ),
      },
    ];
    if (orderStatus === OrderStatusEnum.Declined) {
      steps.push({
        label: intl.formatMessage({id: 'order_details.steps.declined'}),
        time: formatDateToMonthAndTime(
          orderStatusHistory.find(
            status => status.status === OrderStatusEnum.Declined
          )?.createdAt ?? orderStatusHistory[0].createdAt
        ),
      });
      return steps;
    }
    if (orderStatus === OrderStatusEnum.Cancelled) {
      steps.push({
        label: intl.formatMessage({id: 'order_details.steps.cancelled'}),
        time: formatDateToMonthAndTime(
          orderStatusHistory.find(
            status => status.status === OrderStatusEnum.Cancelled
          )?.createdAt ?? orderStatusHistory[0].createdAt
        ),
      });
      return steps;
    }
    if (
      [
        OrderStatusEnum.Pending,
        OrderStatusEnum.Accepted,
        OrderStatusEnum.OutForDelivery,
        OrderStatusEnum.ReadyForPickup,
        OrderStatusEnum.Completed,
      ].includes(orderStatus)
    ) {
      steps.push({
        label: intl.formatMessage({id: 'order_details.steps.accepted'}),
        time: formatDateToMonthAndTime(
          orderStatusHistory.find(
            status => status.status === OrderStatusEnum.Accepted
          )?.createdAt ?? orderStatusHistory[0].createdAt
        ),
      });

      if (serviceType === OrderServiceType.delivery) {
        steps.push({
          label: intl.formatMessage({id: 'order_details.steps.out_delivery'}),
          time: formatDateToMonthAndTime(
            orderStatusHistory.find(
              status => status.status === OrderStatusEnum.OutForDelivery
            )?.createdAt ?? orderStatusHistory[0].createdAt
          ),
        });
      } else if (serviceType === OrderServiceType.collection) {
        steps.push({
          label: intl.formatMessage({
            id: 'order_details.steps.ready_for_pickup',
          }),
          time: formatDateToMonthAndTime(
            orderStatusHistory.find(
              status => status.status === OrderStatusEnum.ReadyForPickup
            )?.createdAt ?? orderStatusHistory[0].createdAt
          ),
        });
      }
      steps.push({
        label: intl.formatMessage({id: 'order_details.steps.completed'}),
        time: formatDateToMonthAndTime(
          orderStatusHistory.find(
            status => status.status === OrderStatusEnum.Completed
          )?.createdAt ?? orderStatusHistory[0].createdAt
        ),
      });
    }
    return steps;
  };

  const getActiveStep = () => {
    if (orderStatus === OrderStatusEnum.Declined) {
      return 2;
    }
    if (orderStatus === OrderStatusEnum.Cancelled) {
      return 2;
    }
    if (orderStatus === OrderStatusEnum.Pending) {
      return 1;
    }
    if (orderStatus === OrderStatusEnum.Accepted) {
      return 2;
    }
    if (orderStatus === OrderStatusEnum.OutForDelivery) {
      return 3;
    }
    if (orderStatus === OrderStatusEnum.ReadyForPickup) {
      return 3;
    }
    if (orderStatus === OrderStatusEnum.Completed) {
      return 4;
    }
    return 1;
  };

  const steps: StepItemProps[] = getSteps();
  const activeStep = getActiveStep();
  return <Stepper steps={steps} activeStep={activeStep} />;
};

export default OrderStepper;
