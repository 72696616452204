/* eslint-disable no-use-before-define */
import {useEffect, useState} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {isEmpty, isEqual} from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {FormGroup} from 'reactstrap';
import HintTooltip from '../../Common/HintTooltip';
import textFieldStyles from '../../Common/useTextFieldStyles';

export type OptionProps = {
  title: string;
  key: string | number;
};

export type AutocompleteSingleProps = {
  className?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  options?: OptionProps[];
  defaultOptions?: OptionProps[];
  canEdit?: boolean;
  showLabelHint?: boolean;
  required?: boolean;
  labelHintText?: string;
  onChange?: (value: OptionProps | string) => void;
};

/* Styles */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiTextField-root': {
      '& .MuiInputBase-formControl': {
        padding: '4px 5px',
        '& .MuiAutocomplete-input': {
          padding: '4px 5px',
          textAlign: 'start',
        },
      },
    },
  },
  popper: {
    boxShadow: 'none',
    marginTop: 10,
    '& .MuiPaper-root': {
      boxShadow: theme.shadows[7],
    },
  },
  listbox: {
    maxHeight: 256,
    paddingTop: 10,
    paddingBottom: 0,
  },
  option: {
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'inherit',
    },
    '&[data-focus="true"]': {
      backgroundColor: 'inherit',
    },
    '& .MuiCheckbox-root': {
      marginLeft: -10,
      marginTop: -5,
    },
    '& .titles': {
      '&.single': {
        marginTop: 4,
      },
      '& > p': {
        lineHeight: 1.2,
      },
    },
  },
  tag: {
    margin: 0,
  },
}));

const AutocompleteSingle = ({
  id = 'id-autocomplete',
  className = 'mb-3 mb-lg-fg',
  showLabelHint,
  label,
  error = '',
  required,
  labelHintText,
  value,
  onChange,
  options,
  placeholder,
}: any) => {
  //values
  const defaultValue = {title: '', key: ''};
  const [currentValue, setCurrentValue] = useState<any>(defaultValue);
  //text field styles
  const textFieldClasses = textFieldStyles({});
  const classes = useStyles();

  // sync currentValues and values
  useEffect(() => {
    if (!isEqual(value, currentValue)) {
      if (!value) {
        setCurrentValue(defaultValue);
      } else {
        setCurrentValue(value);
      }
    }
  }, [value]);

  return (
    <FormGroup className={className}>
      {showLabelHint ? (
        <div className="label-with-hint d-flex">
          <span className="small-label pb-2 pe-1">
            {label}
            {required ? '*' : ''}
          </span>
          <HintTooltip id={`hint_${label}`} content={labelHintText} />
        </div>
      ) : (
        label && (
          <span className="small-label pb-2">
            {label}
            {required ? '*' : ''}
          </span>
        )
      )}
      <Autocomplete
        value={currentValue}
        onChange={(event, value) => {
          if (value) {
            setCurrentValue(value);
            onChange(value);
          }
        }}
        classes={classes}
        selectOnFocus
        clearOnBlur
        id={id}
        options={options}
        getOptionLabel={(option: OptionProps) => option.title}
        renderOption={props => <li {...props}>{props.title}</li>}
        freeSolo
        renderInput={params => (
          <TextField
            error={!!error}
            color="secondary"
            variant="outlined"
            classes={textFieldClasses}
            placeholder={isEmpty(currentValue) ? placeholder : ''}
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              width: '100%',
            }}
            helperText={error}
          />
        )}
      />
    </FormGroup>
  );
};

export default AutocompleteSingle;
