import {UseDataTableStateReturn} from '../table/useDataTableState';
import {getUrlSearchParams} from '../../utils/functions';
import {useEffect, useState} from 'react';
import {isEqual} from 'lodash';
import {parseFilterSearchParams} from '../../components/Form/DynamicFilter';
import {
  ListOrderResponse,
  useLazyFetchOrdersQuery,
} from '../../services/orderApi';
import {getOrderListQuery} from './useOrderList';

export type UseOrderListFilterSearchProps = {
  searchParams: any;
  tabName: string;
};

export default function useLazyOrderList(props: UseOrderListFilterSearchProps) {
  const {searchParams, tabName} = props;
  const [q, setQ] = useState('');

  const [filterValues, setFilterValues] = useState<any>();
  const [orderListData, setOrderListData] = useState<ListOrderResponse>();

  const [orderListTrigger, {data}] = useLazyFetchOrdersQuery();
  useEffect(() => {
    const id = setTimeout(() => {
      const search = getUrlSearchParams(searchParams);
      const newFilterValues = parseFilterSearchParams(search);
      if (!isEqual(filterValues, newFilterValues)) {
        setFilterValues(newFilterValues);
      }
      const nextQ = search.get('q') ?? '';
      if (q !== nextQ) {
        setQ(nextQ);
      }
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }, [searchParams]);

  useEffect(() => {
    if (filterValues) {
      orderListTrigger({
        query: getOrderListQuery(
          {
            orderDirection: 'ASC',
            orderBy: 'orderDate',
            page: 1,
            pageSize: 5,
          } as UseDataTableStateReturn,
          filterValues,
          tabName,
          q
        ),
      });
    }
  }, [filterValues, q]);

  useEffect(() => {
    if (data) {
      setOrderListData(data);
    }
  }, [data]);

  return {
    meta: orderListData?.meta,
    data: orderListData?.data,
  };
}
