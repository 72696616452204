import {FormattedMessage} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Col, Row} from 'react-bootstrap';
import {Statement} from '../../../types/Statement';
import FormattedCurrency from '../../Shared/FormattedCurrency';

export type BillingSummaryProps = {
  statement: Statement;
};

const BillingSummary = ({statement}: BillingSummaryProps) => {
  const {totalCardFee, totalSellerFee} = statement;
  const totalAmount = totalCardFee + totalSellerFee;
  const totalVat =
    totalCardFee /
      (1 + Number(process.env.REACT_APP_VAT_RATE_CARD_FEE as string)) +
    totalSellerFee /
      (1 + Number(process.env.REACT_APP_VAT_RATE_PLATFORM_FEE as string));

  return (
    <Row className="justify-content-lg-end">
      <Col xl={3}>
        <Row className="mb-1">
          <Col xs={6}>
            <Typography className="font-weight-bold text-nowrap text-xl-end">
              <FormattedMessage id="transaction_list.invoice_details.net" />:
            </Typography>
          </Col>
          <Col xs={6} className="text-end">
            <Typography className="font-weight-bold">
              £{totalVat.toFixed(2)}
            </Typography>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={6}>
            <Typography className="font-weight-bold text-nowrap text-xl-end">
              <FormattedMessage id="transaction_list.invoice_details.vat" />{' '}
              @20%:
            </Typography>
          </Col>
          <Col xs={6} className="text-end">
            <Typography className="font-weight-bold">
              <FormattedCurrency
                value={totalCardFee + totalSellerFee - totalVat}
                currency={statement.currency}
              />
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Typography
              className="font-weight-bolder text-nowrap text-xl-end"
              variant="h4"
            >
              <FormattedMessage id="transaction_list.invoice_details.total" />:
            </Typography>
          </Col>
          <Col xs={6}>
            <Typography className="font-weight-bolder text-end" variant="h4">
              <FormattedCurrency
                value={totalAmount}
                currency={statement.currency}
              />
            </Typography>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BillingSummary;
