import {Container} from 'react-bootstrap';
import HookFormPlayground from './form/HookFormPlayground';

const FormPlayground = () => {
  return (
    <Container fluid>
      <h1>Form playground</h1>
      <HookFormPlayground />
    </Container>
  );
};

export default FormPlayground;
