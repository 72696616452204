import {ComponentProps, ElementType} from 'react';
import {useAppSelector} from '../../store/hooks';
import {selectGmapsLoaded} from '../../store/slices/appSlice';

/**
 * This HOC will only render `Component` if gmaps script is loaded
 */
export default function withGmaps(Component: ElementType) {
  // eslint-disable-next-line react/display-name
  return (props: ComponentProps<typeof Component>) => {
    const loaded = useAppSelector(selectGmapsLoaded);

    if (!loaded || !window.google) {
      return null;
    }

    return <Component {...props} />;
  };
}
