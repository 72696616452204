import cx from 'clsx';
import {Typography} from '@material-ui/core';
import {Stack} from 'react-bootstrap';
import {ReactComponent as MastercardIcon} from '../../assets/images/mastercard.svg';
import {ReactComponent as VisaIcon} from '../../assets/images/visa.svg';
import {ReactComponent as AmericanExpressIcon} from '../../assets/images/american-express.svg';
import {ReactComponent as DiscoverIcon} from '../../assets/images/discover.svg';
import {ReactComponent as DinersIcon} from '../../assets/images/diners-club.svg';
import {ReactComponent as UnionpayIcon} from '../../assets/images/union-pay.svg';
import {ReactComponent as CardIcon} from '../../assets/images/creditcard.svg';
import {ReactComponent as GooglePayIcon} from '../../assets/images/google-pay.svg';
import {ReactComponent as ApplePayIcon} from '../../assets/images/apple-pay.svg';

export type CardNumberProps = {
  type: string;
  number: string;
  className?: string;
};

const CardNumber = (props: CardNumberProps) => {
  const {type, number, className} = props;

  const getCardIcon = () => {
    switch (type) {
      case 'mastercard':
        return <MastercardIcon style={{width: 24, height: 19}} />;
      case 'visa':
        return <VisaIcon style={{width: 24, height: 19}} />;
      case 'amex':
        return <AmericanExpressIcon style={{width: 24, height: 19}} />;
      case 'discover':
        return <DiscoverIcon style={{width: 24, height: 19}} />;
      case 'diners':
        return <DinersIcon style={{width: 24, height: 19}} />;
      case 'unionpay':
        return <UnionpayIcon style={{width: 24, height: 19}} />;
      case 'googlePay':
        return <GooglePayIcon style={{width: 24, height: 19}} />;
      case 'applePay':
        return <ApplePayIcon style={{width: 24, height: 19}} />;
      default:
        return <CardIcon style={{width: 24, height: 19}} />;
    }
  };

  return (
    <div className={cx(className)}>
      <Stack direction="horizontal" gap={1}>
        {getCardIcon()}
        <Typography>{`****${number}`}</Typography>
      </Stack>
    </div>
  );
};

export default CardNumber;
