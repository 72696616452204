import {Col, Row, Form, Container} from 'reactstrap';
import useUpdateAppKey from '../../../../hooks/useUpdateAppKey';
import SaveFeature from '../../../Shared/SaveFeature';
import {ReactComponent as AppleIcon} from '../../../../assets/images/apple.svg';
import {ReactComponent as AndroidIcon} from '../../../../assets/images/android.svg';
import {Typography} from '@material-ui/core';
import {FormattedMessage, useIntl} from 'react-intl';
import InputController from '../../../Form/HookForm/InputController';
import DropzoneController from '../../../Form/HookForm/DropzoneController';
import {validAppKeyOptions} from '../../../../utils/functions';
import {ReactComponent as BuildCertificateIcon} from '../../../../assets/images/buildCertificate.svg';
import {Stack} from 'react-bootstrap';
import {unstable_usePrompt as usePrompt} from 'react-router-dom';

type AppKeyTabProps = {
  appId: string;
};

const AppKeyTab = ({appId}: AppKeyTabProps) => {
  const {
    control,
    resetForm,
    count,
    setError,
    clearErrors,
    errors,
    handleSubmit,
    onSubmit,
    authKeyFileName,
    storeFileName,
    isIosDropReset,
    isAndroidDropReset,
  } = useUpdateAppKey({
    appId: Number(appId),
  });
  const intl = useIntl();

  // alert user when they try to reload when edited field has not been saved
  usePrompt({
    when: count > 0,
    message: intl.formatMessage({id: 'dashboard.confirm_not_saved_message'}),
  });

  return (
    <div role="tabpanel">
      {count > 0 && (
        <SaveFeature
          onClose={resetForm}
          open
          keyType="app"
          num={count}
          type="submit"
          form="third-party-settings"
        />
      )}
      <Container fluid>
        <div>
          <Row className="g-3">
            <Form onSubmit={handleSubmit(onSubmit)} id="third-party-settings">
              <Col lg={7} xxl={6} className="d-grid gap-3">
                <Row>
                  <Row>
                    <div className="d-flex align-items-center pb-3">
                      <AppleIcon
                        style={{width: 36, height: 36}}
                        className="me-1"
                      />
                      <Typography
                        style={{fontSize: '1.5rem'}}
                        className="font-weight-bold"
                      >
                        <FormattedMessage id="dashboard.app_details.general_tab.ios" />
                      </Typography>
                    </div>
                  </Row>
                  <Row>
                    <div className="card details-card">
                      <div className="card-body">
                        <InputController
                          name="teamId"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.app_key.team_id',
                          })}
                        />
                        <InputController
                          name="authKeyId"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.app_key.auth_key_id',
                          })}
                        />
                        <InputController
                          name="authKeyIssuerId"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.app_key.auth_key_issuer_id',
                          })}
                        />
                        <Stack className="mb-1" gap={2} direction="horizontal">
                          <BuildCertificateIcon />
                          <Typography color="secondary">
                            {authKeyFileName ??
                              intl.formatMessage({
                                id: 'dashboard.app_details.app_key.auth_key_file_not_exist',
                              })}
                          </Typography>
                        </Stack>
                        <DropzoneController
                          name="authKeyFileData"
                          control={control}
                          format="appKey"
                          className="fill-background mb-1"
                          canEdit={false}
                          validFileOptions={validAppKeyOptions}
                          setError={setError}
                          clearError={clearErrors}
                          errors={errors}
                          accept={{
                            'application/pkcs8': ['.p8'],
                          }}
                          deleteSuccess={isIosDropReset}
                        />
                      </div>
                    </div>
                  </Row>
                </Row>
                <Row>
                  <Row>
                    <div className="d-flex align-items-center pb-2">
                      <AndroidIcon
                        style={{width: 36, height: 36}}
                        className="me-1"
                      />
                      <Typography
                        style={{fontSize: '1.5rem'}}
                        className="font-weight-bold"
                      >
                        <FormattedMessage id="dashboard.app_details.general_tab.android" />
                      </Typography>
                    </div>
                  </Row>

                  <Row>
                    <div className="card details-card">
                      <div className="card-body">
                        <InputController
                          name="keyAlias"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.app_key.alias',
                          })}
                          inputProps={{
                            autocomplete: 'new-password',
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                        />
                        <InputController
                          name="storePassword"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.app_key.store_password',
                          })}
                          isPassword
                          inputProps={{
                            autocomplete: 'new-password',
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                        />
                        <InputController
                          name="keyPassword"
                          control={control}
                          label={intl.formatMessage({
                            id: 'dashboard.app_details.app_key.key_password',
                          })}
                          isPassword
                          inputProps={{
                            autocomplete: 'new-password',
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                        />
                        <Stack className="mb-1" gap={2} direction="horizontal">
                          <BuildCertificateIcon />
                          <Typography color="secondary">
                            {storeFileName
                              ? storeFileName
                              : intl.formatMessage({
                                  id: 'dashboard.app_details.app_key.store_file_not_exist',
                                })}
                          </Typography>
                        </Stack>
                        <DropzoneController
                          name="storeFileData"
                          control={control}
                          format="appKey"
                          className="fill-background mb-1"
                          canEdit={false}
                          validFileOptions={validAppKeyOptions}
                          setError={setError}
                          clearError={clearErrors}
                          errors={errors}
                          accept={{
                            'application/java-keystore': ['.keystore'],
                          }}
                          deleteSuccess={isAndroidDropReset}
                        />
                      </div>
                    </div>
                  </Row>
                </Row>
              </Col>
            </Form>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default AppKeyTab;
