import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  CreateUpdateOptionGroupParam,
  ProductGroupOrderParam,
  useCreateProductOptionGroupMutation,
  useDeleteProductOptionGroupMutation,
  useFetchProductOptionGroupQuery,
  useOrderProductOptionGroupMutation,
  useUpdateProductOptionGroupMutation,
} from '../services/productOptionGroupApi';
import {formatErrorMessage} from '../utils/functions';
import {useEffect, useMemo, useState} from 'react';
import {ProductOptionGroup} from '../types/ProductOptionGroup';
import {DeleteParam} from '../services/storeApi';
import {OrderParam} from '../services/productCategoryApi';

export type ProductOptionGroupFormValues = {
  name: string;
  description?: string;
};

type UseProductOptionGroupProps = {
  selectedgroup?: ProductOptionGroup | null;
  isEditMode: boolean;
  onUpdate: (group: ProductOptionGroup) => void;
};

export default function useProductOptionGroup({
  selectedgroup,
  isEditMode,
  onUpdate,
}: UseProductOptionGroupProps) {
  // Translations
  const intl = useIntl();
  const {storeId} = useParams();

  // Groups state
  const [optionGroups, setOptionGroups] = useState<ProductOptionGroup[]>([]);

  // show spinner
  const [showSpinner, setShowSpinner] = useState(true);

  // List product option groups
  const {data: group, isSuccess} = useFetchProductOptionGroupQuery(
    {
      storeId,
      showProgressDialog: showSpinner,
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );

  // init option groups
  useEffect(() => {
    if (group && isSuccess) {
      setOptionGroups(group.data);
      setShowSpinner(false);
    }
  }, [group, isSuccess]);

  // yup validation
  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
      }),
    [intl]
  );

  // form hook
  const {control, handleSubmit, reset, setValue} =
    useForm<ProductOptionGroupFormValues>({
      mode: 'onTouched',
      resolver: yupResolver(schema),
    });

  // prefill fields on update
  useEffect(() => {
    if (selectedgroup && isEditMode) {
      setValue('name', selectedgroup.name);
      setValue('description', selectedgroup.description ?? '');
    } else {
      reset();
    }
  }, [selectedgroup, isEditMode]);

  // create Product Option Group
  const [
    createOptionGroup,
    {isSuccess: createSuccess, data: createdData, isLoading: createdLoading},
  ] = useCreateProductOptionGroupMutation();

  // Edit Product Option Group
  const [
    updateOptionGroup,
    {isSuccess: updateSuccess, isLoading: updatedLoading},
  ] = useUpdateProductOptionGroupMutation();

  // Delete Product Option Group
  const [deleteOptionGroup, {isSuccess: deleteSuccess}] =
    useDeleteProductOptionGroupMutation();

  // Order Product Option Group
  const [orderOptionGroup] = useOrderProductOptionGroupMutation();

  // Add/ Edit Option Group
  const submit = (formValues: ProductOptionGroupFormValues) => {
    const data: CreateUpdateOptionGroupParam = {
      body: {
        ...formValues,
        storeId,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        const message = isEditMode
          ? intl.formatMessage(
              {
                id: 'messages.custom_update_option_group',
              },
              {
                name: selectedgroup?.name,
              }
            )
          : intl.formatMessage(
              {
                id: 'messages.custom_create_option_group',
              },
              {
                name: formValues?.name,
              }
            );
        return message;
      },
    };
    if (isEditMode && selectedgroup) {
      data.id = selectedgroup.id;
      // update option group
      const group: ProductOptionGroup = {
        ...selectedgroup,
        ...formValues,
      };
      onUpdate(group);
      updateOptionGroup(data);
    } else {
      //create option group
      createOptionGroup(data);
    }
  };

  useEffect(() => {
    if (createSuccess && createdData) {
      onUpdate(createdData.data);
    }
  }, [createSuccess, createdData]);

  // Delete Product option Group
  const deleteProductOptionGroup = (id: number) => {
    const data: DeleteParam = {
      id,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.custom_delete_option_group',
          },
          {name: selectedgroup?.name ?? ''}
        );
      },
    };
    deleteOptionGroup(data);
  };

  // reorder option groups
  const reorder = (
    list: ProductOptionGroup[],
    startIndex: number,
    endIndex: number
  ): ProductOptionGroup[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const reArrangeProductOptionGroup = (
    draggableId: string,
    sourceIndex: number,
    destinationIndex: number
  ) => {
    // reorder item
    const items = reorder(optionGroups, sourceIndex, destinationIndex);

    // find draggedItem and destinationItem
    const destinationItem = optionGroups[destinationIndex];
    const draggedItem = optionGroups.find(
      item => item.id === Number(draggableId)
    );
    if (!draggedItem || !destinationItem) {
      return;
    }
    const newItems = items.map((item, index: number) => ({
      ...item,
      rank: index + 1,
    }));
    setOptionGroups(newItems);
    setTimeout(() => {
      orderProductOptionGroup(newItems);
    }, 1000);
  };

  // Order product option group
  const orderProductOptionGroup = (items: ProductOptionGroup[]) => {
    const groups: OrderParam[] = items.map(item => ({
      id: item.id,
      rank: item.rank,
    }));
    const data: ProductGroupOrderParam = {
      storeId,
      body: {
        groups,
      },
      formatErrorMessage: error => formatErrorMessage(error, intl),
    };
    orderOptionGroup(data);
  };

  return {
    optionGroups,
    isSuccess,
    handleSubmit,
    control,
    submit,
    createSuccess,
    reset,
    updateSuccess,
    deleteProductOptionGroup,
    deleteSuccess,
    orderProductOptionGroup: reArrangeProductOptionGroup,
    createdLoading,
    updatedLoading,
  };
}
