import {useEffect, useState} from 'react';
import {useFetchAppBuildsQuery} from '../../services/appApi';
import {BuildStatusEnum} from '../../types/Build';

type UseAppBuildProps = {
  appId: number;
  id?: number;
};
const useAppBuild = (props: UseAppBuildProps) => {
  const {appId} = props;

  const [pollingInterval, setPollingInterval] = useState<number>(0);

  const {data, isSuccess} = useFetchAppBuildsQuery(
    {id: appId, showProgressDialog: true},
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      pollingInterval: pollingInterval,
    }
  );

  useEffect(() => {
    if (data && data.length > 0) {
      if (data.some(item => item.status === BuildStatusEnum.InProgress)) {
        setPollingInterval(60 * 1000);
      } else {
        setPollingInterval(0);
      }
    }
  }, [data]);

  return {data, isSuccess};
};

export default useAppBuild;
