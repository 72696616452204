import clsx from 'clsx';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import {FormControl, Typography} from '@material-ui/core';
import DatePicker from '../Inputs/DatePicker';
import moment from 'moment';

export type DatePickerControllerProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  dateFormat?: string;
  label?: string;
  placeholder?: string;
  excludeLabel?: boolean;
  formGroupClass?: string;
  displayFormat?: string;
};

const DatePickerController = <T extends FieldValues>({
  name,
  label,
  placeholder,
  excludeLabel,
  control,
  displayFormat,
  formGroupClass,
}: DatePickerControllerProps<T>) => {
  // Format date from iFormat to fFormat
  const formatDate = (
    date: string,
    iFormat?: string,
    fFormat?: string,
    defaultValue = null
  ) => {
    const m = moment(date, iFormat);

    return m.isValid() ? m.format(fFormat) : defaultValue;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {value, onChange}, fieldState: {error}}) => {
        const touched = !!error;
        const handleChange = (val: any) => {
          const formatedDate = formatDate(val);
          onChange(formatedDate);
        };

        return (
          <FormControl
            className={clsx(
              'form-group d-flex',
              formGroupClass ? formGroupClass : 'mb-3 mb-lg-fg',
              {
                'form-group-invalid': touched && error,
              }
            )}
            error={!!error}
          >
            {label && !excludeLabel && (
              <span className="small-label pb-2">{label}</span>
            )}
            <DatePicker
              value={value}
              format={displayFormat}
              placeholder={placeholder}
              onChange={handleChange}
              fullWidth
            />
            {/* Show error here if errorHelperText is false */}
            {touched && error && (
              <Typography variant="subtitle2">
                {String(error.message)}
              </Typography>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default DatePickerController;
