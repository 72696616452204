import {useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {
  ScheduleItemParams,
  UpdateScheduleStatusParam,
  useDeleteScheduleItemMutation,
  useFetchSchedulesQuery,
  useUpdateScheduleItemsMutation,
  useUpdateScheduleStatusMutation,
} from '../services/scheduleApi';
import {formatErrorMessage} from '../utils/functions';
import {StoreSchedule} from '../types/StoreSchedule';
import {useEffect, useMemo, useState} from 'react';
import {unstable_usePrompt as usePrompt, useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {StoreScheduleItem} from '../types/StoreScheduleItem';
import {CategoryCountState} from '../components/Stores/Details/MenuTab/SchedulesList';
import moment from 'moment';

export type MenuScheduleFormValues = {
  schedules: StoreSchedule[];
};

type UseScheduleManagementProps = {
  categoryCount: CategoryCountState[];
  updateCount: (lists: CategoryCountState[]) => void;
};

export default function useScheduleManagement({
  categoryCount,
  updateCount,
}: UseScheduleManagementProps) {
  const intl = useIntl();
  const {storeId} = useParams();
  const {data, isSuccess} = useFetchSchedulesQuery(
    {storeId, showProgressDialog: true},
    {refetchOnMountOrArgChange: true}
  );
  const [count, setCount] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [scheduleList, setScheduleList] = useState<StoreSchedule[]>([]);

  const updateIsDeleting = (deleting: boolean) => {
    setIsDeleting(deleting);
  };

  useEffect(() => {
    if (data && isSuccess) {
      setIsDeleting(false);
      setScheduleList(data);
    }
  }, [isSuccess, data]);

  const serviceTypeSchema = yup.object().shape({
    collection: yup.boolean(),
    delivery: yup.boolean(),
  });
  // yup validation
  const schema = useMemo(
    () =>
      yup.object().shape({
        schedules: yup.array().of(
          yup.object().shape({
            items: yup.array().of(
              yup.object().shape({
                title: yup
                  .string()
                  .trim()
                  .required(
                    intl.formatMessage({id: 'validation.error.required_field'})
                  ),
                services: serviceTypeSchema.test(
                  'is_one_service_type_true',
                  intl.formatMessage({id: 'validation.error.service_required'}),
                  value => {
                    const {collection, delivery} = value;
                    if (!collection && !delivery) {
                      return Promise.resolve(false); // At least one service type is true
                    }
                    return Promise.resolve(true);
                  }
                ),
                openingTime: yup
                  .string()
                  .required(
                    intl.formatMessage({id: 'validation.error.required_field'})
                  )
                  .test(
                    'is-openingTime-greater-than-closingTime',
                    intl.formatMessage({id: 'validation.error.opening_time'}),
                    function (value) {
                      const {closingTime} = this.parent;
                      if (closingTime && value) {
                        const openTime = moment({
                          h: Number(value?.split(':')[0]),
                          m: Number(value?.split(':')[1]),
                        });
                        const closeTime = moment({
                          h: Number(closingTime?.split(':')[0]),
                          m: Number(closingTime?.split(':')[1]),
                        });

                        if (closeTime.isBefore(openTime)) {
                          return Promise.resolve(false);
                        }
                        const field = this.path.replace(
                          'openingTime',
                          'closingTime'
                        ) as `schedules.${number}.items.${number}`;

                        if (
                          getFieldState(field).invalid &&
                          closeTime.isBefore(openTime)
                        ) {
                          trigger(field);
                        }
                        clearErrors(field);
                      }
                      return Promise.resolve(true);
                    }
                  ),
                closingTime: yup
                  .string()
                  .required(
                    intl.formatMessage({id: 'validation.error.required_field'})
                  )
                  .test(
                    'is-closingTime-less-than-openingTime',
                    intl.formatMessage({id: 'validation.error.closing_time'}),
                    function (value) {
                      const {openingTime} = this.parent;
                      if (openingTime && value) {
                        const closeTime = moment({
                          h: Number(value?.split(':')[0]),
                          m: Number(value?.split(':')[1]),
                        });
                        const openTime = moment({
                          h: Number(openingTime?.split(':')[0]),
                          m: Number(openingTime?.split(':')[1]),
                        });
                        if (
                          closeTime.isBefore(openTime) ||
                          closeTime.isSame(openTime)
                        ) {
                          return Promise.resolve(false);
                        }
                        const field = this.path.replace(
                          'closingTime',
                          'openingTime'
                        ) as `schedules.${number}.items.${number}`;
                        if (
                          getFieldState(field).invalid &&
                          (closeTime.isBefore(openTime) ||
                            closeTime.isSame(openTime))
                        ) {
                          trigger(field);
                        }
                        clearErrors(field);
                        return Promise.resolve(true);
                      }
                      return Promise.resolve(true);
                    }
                  ),
              })
            ),
          })
        ),
      }),
    [intl]
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState,
    reset,
    watch,
    trigger,
    getFieldState,
    clearErrors,
  } = useForm<MenuScheduleFormValues>({
    defaultValues: {
      schedules: data,
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const [updateItems] = useUpdateScheduleItemsMutation();
  const [deleteItem] = useDeleteScheduleItemMutation();
  const [updateScheduleStatus] = useUpdateScheduleStatusMutation();
  const getTotalCategoryCount = (lists: CategoryCountState[]) => {
    return lists.reduce((sum, item) => {
      return sum + item.count;
    }, 0);
  };
  // alert user when they try to reload when edited field has not been saved
  usePrompt({
    when: (count > 0 || getTotalCategoryCount(categoryCount) > 0) as boolean,
    message: intl.formatMessage({id: 'dashboard.confirm_not_saved_message'}),
  });

  // Save schedule items changes
  const submit = (formValue: MenuScheduleFormValues) => {
    formValue.schedules.forEach((schedule, index) => {
      if (isScheduleDirty(index)) {
        updateScheduleItems({id: schedule.id, body: {items: schedule.items}});
      }
    });
  };

  // Check if a given schedule is changed
  const isScheduleDirty = (scheduleIndex: number): boolean => {
    const dirtyFields = formState.dirtyFields.schedules as any;
    return dirtyFields[scheduleIndex]?.items.some((item: StoreScheduleItem) => {
      return Object.values(item).some(v => v === true);
    });
  };

  // Count changed schedule item fields
  const countDirtyFields = () => {
    let fieldsCount = 0;
    const schedules = formState.dirtyFields.schedules as any;
    schedules?.forEach((schedule: {items: StoreScheduleItem[]}) => {
      schedule?.items.map((item: any) =>
        Object.keys(item).map(key => {
          // skip fieldId as it is internal react form hook field
          if (key !== 'fieldId' && item[key] === true) {
            fieldsCount++;
          }
        })
      );
    });
    return fieldsCount;
  };

  const formValuesWatch = watch();

  // Count changed fields
  useEffect(() => {
    if (!isDeleting) {
      setCount(countDirtyFields());
    }
  }, [formValuesWatch]);

  // Reset form when data has changed
  useEffect(() => {
    resetForm();
  }, [data]);

  // Reset form by setting data from api
  const resetForm = () => {
    reset({schedules: data});
    updateCount([]);
  };

  const toggleScheduleStatus = (id: number, status: string) => {
    const data: UpdateScheduleStatusParam = {
      id,
      body: {
        status,
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.update_schedule',
        });
      },
    };
    updateScheduleStatus(data);
  };

  const updateScheduleItems = (params: ScheduleItemParams) => {
    updateItems({
      ...params,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.update_success_message',
        });
      },
    });
  };

  const deleteScheduleItem = (id: number | string) => {
    deleteItem({
      id,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.delete_schedule_item',
        });
      },
    });
  };

  return {
    control,
    handleSubmit,
    submit,
    setValue,
    toggleScheduleStatus,
    deleteScheduleItem,
    data,
    isSuccess,
    count,
    resetForm,
    scheduleList,
    updateIsDeleting,
  };
}
