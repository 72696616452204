import {useJsApiLoader, Libraries} from '@react-google-maps/api';
import {useEffect} from 'react';
import {useAppDispatch} from '../../store/hooks';
import {setGmapsLoaded} from '../../store/slices/appSlice';

/**
 * This component is used to load Gmaps script once and update redux state
 * accordingly.
 * Make sure to only render it once in the app component tree
 */

const libraries: Libraries = ['places'];

const GmapsScriptLoader = () => {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? '',
    libraries,
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setGmapsLoaded(isLoaded));
  }, [isLoaded, dispatch]);

  return null;
};

export default GmapsScriptLoader;
