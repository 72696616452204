import {ReactNode} from 'react';
import {Stack} from 'react-bootstrap';
import {ReactComponent as EditIcon} from '../../../../assets/images/editGreen.svg';
import {ReactComponent as TrashIcon} from '../../../../assets/images/trashRed.svg';
import IconButton from '../../../Common/IconButton';

/**
 * Editable label block with icons toolbar
 */

export type EditLabelProps = {
  children: ReactNode | string;
  onEdit: () => void;
  onDelete: () => void;
  className?: string;
};

const EditLabel = ({
  children,
  onEdit,
  onDelete,
  className = '',
}: EditLabelProps) => {
  return (
    <Stack direction="horizontal" gap={2} className={className}>
      <div>{children}</div>
      <IconButton size="xs" variant="action" onClick={onEdit}>
        <EditIcon />
      </IconButton>
      <IconButton size="xs" variant="action" onClick={onDelete}>
        <TrashIcon />
      </IconButton>
    </Stack>
  );
};

export default EditLabel;
