import {ReactNode, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {Stack} from 'react-bootstrap';
import {ReactComponent as CloseIcon} from '../../assets/images/close.svg';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '../Common/Button';
import IconButton from '../Common/IconButton';

export type CustomModalProps = {
  /**
   * TRUE if modal is opened
   */
  opened: boolean;
  /**
   * Close modal callback
   */
  handleClose: () => void;
  onOk?: () => void;
  /**
   * Modal title
   */
  title: string;
  /**
   * Container width
   */
  width?: number;
  /**
   * Inner modal content
   */
  children: ReactNode;
  /**
   * Additional class
   */
  className?: string;
  /**
   * Do not show Save button
   */
  noSaveBtn?: boolean;
  /**
   * Do not show Cancel button
   */
  noCancelBtn?: boolean;
  /**
   * Do not use maximum height of content
   */
  noMaxHeight?: boolean;
  /**
   * Show close button on the modal top
   */
  showCloseTop?: boolean;
  /**
   * Cancel btn title
   * Cancel is default
   */
  cancelTitle?: string;
  /**
   * Save button title
   * Save by default
   */
  saveTitle?: string;
  /**
   * Whether the modal is empty or not
   * Empty means the user will have to provider dialog-header, dialog-content and dialog-footer
   * The default class for them are respectively .dialog-header, .dialog-content, .dialog-footer
   * Note that if the modal is empty, title, cancelTitle and other content won't be used
   */
  emptyModal?: boolean;
  headerClassName?: string;
  form?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;

  saveButtonDisabled?: boolean;
  onCloseIcon?: () => void;
};

const buttonStyles = (theme: Theme) => ({
  width: 97,
  justifyContent: 'center !important',
  '&.MuiButton-text': {
    width: 'auto',
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
  '&.MuiButtonBase-root': {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
});

//Modal styles
const useStyles = makeStyles<Theme, CustomModalProps>((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: 'rgba(40,43,62,0.5) !important',
  },
  paper: {
    position: 'absolute',
    minWidth: 328,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    margin: theme.spacing(2),
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.spacing(2)}px)`,
      margin: '0 auto',
    },

    '& .dialog-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      '& .modal-close-btn': {
        margin: '4px 0 0 0',
        paddingBottom: 12,
        '& .MuiSvgIcon-root': {
          width: 'auto',
        },
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5),
      },
    },
    '& .dialog-content': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      maxHeight: '65vh',
      overflow: 'hidden auto',
      '&.h-auto': {
        maxHeight: 'unset',
        overflow: 'unset',
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
    },
    '& .dialog-footer': {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      '& .dialog-button': buttonStyles(theme),
    },
  },
  button: buttonStyles(theme),
}));

/**
 * Modal widget wrapper
 */
const CustomModal = (props: CustomModalProps) => {
  const {
    title,
    opened,
    onOk,
    handleClose,
    children,
    className = '',
    noCancelBtn = false,
    noSaveBtn = false,
    noMaxHeight = false,
    showCloseTop = false,
    cancelTitle,
    saveTitle,
    emptyModal = false,
    headerClassName = '',
    form,
    type,
    saveButtonDisabled,
    onCloseIcon,
  } = props;
  //Translations
  const intl = useIntl();

  const classes = useStyles(props);

  /* Scrolling main content fix */
  useEffect(() => {
    const body = window.document.body;
    //dialog shown
    if (opened) {
      //disable body scrolling
      body.classList.add('position-fixed');
    } else {
      //enable body scrolling
      body.classList.remove('position-fixed');
    }
  }, [opened]);

  return (
    <Modal
      className={clsx(classes.modal, className)}
      disableAutoFocus
      open={opened}
      onClose={onCloseIcon ? onCloseIcon : handleClose}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      <>
        {emptyModal ? (
          children
        ) : (
          <div className={classes.paper}>
            {/* Title */}
            <div className={clsx('dialog-header', headerClassName)}>
              <Typography className="d-none d-lg-block" variant="h4">
                {title}
              </Typography>
              <Typography className="d-lg-none" variant="h5">
                {title}
              </Typography>
              {showCloseTop && (
                <IconButton
                  size="xs"
                  variant="filled"
                  onClick={onCloseIcon ? onCloseIcon : handleClose}
                  className="modal-close-btn"
                >
                  <CloseIcon />
                </IconButton>
              )}
            </div>
            {/* Modal content */}
            <div className={clsx('dialog-content', noMaxHeight && 'h-auto')}>
              {children}
            </div>
            {/* Actions buttons */}
            <div className="dialog-footer">
              <Stack
                direction="horizontal"
                gap={4}
                className="justify-content-between justify-content-lg-end"
              >
                {/*Cancel*/}
                {!noCancelBtn && (
                  <Button
                    link
                    className={clsx(classes.button)}
                    title={
                      cancelTitle ?? intl.formatMessage({id: 'actions.cancel'})
                    }
                    onClick={handleClose}
                  />
                )}
                {/*Save*/}
                {!noSaveBtn && (
                  <Button
                    primary
                    className={classes.button}
                    title={
                      saveTitle ?? intl.formatMessage({id: 'actions.save'})
                    }
                    onClick={() => (onOk ? onOk() : undefined)}
                    disabled={saveButtonDisabled}
                    form={form}
                    type={type}
                  />
                )}
              </Stack>
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};

export default CustomModal;
