import {useIntl} from 'react-intl';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Modal from '../../Widgets/Modal';
import BillingDetails from './BillingDetails';
import {SendInvoiceStatementType} from '../StatementList';
import useStatementInvoice from '../../../hooks/useStatementInvoice';

export type InvoicePopupProps = {
  opened: boolean;
  handleClose: () => void;
  statementId: number;
  onSendStatementInvoice: (data: SendInvoiceStatementType) => void;
};

const modalStyles = makeStyles<Theme>((theme: Theme) => ({
  modal: {
    '& .dialog-header': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& .dialog-content': {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(1),
      },
      maxHeight: '580px',
    },
    '& .dialog-footer': {
      borderTop: `1px solid ${theme.palette.divider}`,
      paddingTop: theme.spacing(3),
      '& .MuiButton-containedPrimary': {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
          width: 180,
        },
      },
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(2),
      },
    },
  },
  paper: {
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(0.4),
    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.05)',
    [theme.breakpoints.up('lg')]: {
      minWidth: 790,
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1.5),
    },
  },
}));

const InvoicePopup = ({
  opened,
  handleClose,
  statementId,
  onSendStatementInvoice,
}: InvoicePopupProps) => {
  const intl = useIntl();
  const classes = modalStyles();

  const {
    data,
    deleteInvoiceLineItemRequest,
    createInvoiceLineItemRequest,
    isCreateSuccess,
  } = useStatementInvoice({statementId});

  return (
    <>
      {data && (
        <Modal
          opened={opened}
          noCancelBtn
          showCloseTop
          className={classes.modal}
          handleClose={handleClose}
          title={intl.formatMessage({
            id: 'transaction_list.invoice_details.title',
          })}
          saveTitle={intl.formatMessage({
            id: 'transaction_list.invoice_details.send_email',
          })}
          onOk={() => onSendStatementInvoice({statementId})}
        >
          <Paper className={`${classes.paper} pb-4 pt-4`}>
            <BillingDetails
              statementId={statementId}
              data={data}
              deleteInvoiceLineItemRequest={deleteInvoiceLineItemRequest}
              createInvoiceLineItemRequest={createInvoiceLineItemRequest}
              isCreateSuccess={isCreateSuccess}
            />
          </Paper>
        </Modal>
      )}
    </>
  );
};

export default InvoicePopup;
