import {Control} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {Typography, Box} from '@material-ui/core';
import {Form, Row, Col} from 'reactstrap';
import {Stack, Image} from 'react-bootstrap';
import Button from '../../../Common/Button';
import {ReactComponent as AcceptIcon} from '../../../../assets/images/accept.svg';
import {ReactComponent as ClearIcon} from '../../../../assets/images/clear.svg';
import CheckboxController from '../../../Form/HookForm/CheckboxController';
import {Fragment} from 'react';
import {ProductCategory} from '../../../../types/ProductCategory';
import {Product} from '../../../../types/Product';
import FormattedCurrency from '../../../Shared/FormattedCurrency';
import BlankProductImage from '../../../Common/BlankProductImage';

/**
 * Choose products form
 */

type ChooseProductsFormHeaderProp = {
  selectedProducts: string[];
  category?: ProductCategory;
  selectAll: () => void;
  clearSelection: () => void;
};

const ChooseProductsFormHeader = (props: ChooseProductsFormHeaderProp) => {
  const intl = useIntl();
  const {selectedProducts, category, selectAll, clearSelection} = props;
  return (
    <div className="pt-lg-3 pb-3 pb-lg-4">
      <Row className="gx-1 pb-vtl-15">
        <Col>
          <Typography variant="h5">
            <FormattedMessage id="actions.edit" />
          </Typography>
        </Col>
        <Col xs="auto">
          <Typography variant="h5" color="primary">
            {selectedProducts.length}/{category?.products.length} selected
          </Typography>
        </Col>
      </Row>
      <Stack
        direction="horizontal"
        gap={3}
        className="justify-content-between justify-content-lg-start"
      >
        {/* Select all */}
        <Button
          link
          title={intl.formatMessage({
            id: 'dashboard.store_details.menu_tab.select_all',
          })}
          className="details-action"
          icon={<AcceptIcon />}
          onClick={selectAll}
        />
        {/* Clear selection */}
        <Button
          link
          title={intl.formatMessage({
            id: 'dashboard.store_details.menu_tab.clear_selection',
          })}
          className="details-action"
          icon={<ClearIcon />}
          onClick={clearSelection}
        />
      </Stack>
    </div>
  );
};

type ListProductItemCheckBoxProp = {
  item: Product;
  control: Control<any>;
};

const ListProductItemCheckBox = (props: ListProductItemCheckBoxProp) => {
  const {item, control} = props;
  return (
    <Row className="gx-2">
      <Col xs>
        <CheckboxController
          name={`product-${item.id}`}
          size="default"
          control={control}
          value={item.id}
          label={
            <Stack
              direction="horizontal"
              gap={2}
              className="align-items-start ms-vtl-3"
            >
              {item?.image ? (
                <Image
                  alt={item?.name}
                  fluid
                  src={item.image}
                  width={35}
                  height={35}
                  className="rounded-sm"
                />
              ) : (
                <BlankProductImage />
              )}
              <Box pt={0.2}>
                <Typography className="font-weight-bold">
                  {item.name}
                </Typography>
                <Typography>{item?.chineseName}</Typography>
              </Box>
            </Stack>
          }
          classes={{
            formControlLabel: 'me-1',
          }}
        />
      </Col>
      <Col xs="auto">
        <Typography>
          <FormattedCurrency value={item.price.price} currency="GBP" />
        </Typography>
      </Col>
    </Row>
  );
};

type ChooseProductsFormProp = {
  category?: ProductCategory;
  control: Control<any>;
  products: Product[];
  checkedProducts: string[];
  selectAllProduct: () => void;
  clearAllSelection: () => void;
};

const ChooseProductsForm = (props: ChooseProductsFormProp) => {
  const {
    category,
    control,
    products,
    selectAllProduct,
    clearAllSelection,
    checkedProducts,
  } = props;

  return (
    <Form>
      <ChooseProductsFormHeader
        category={category}
        selectedProducts={checkedProducts}
        selectAll={selectAllProduct}
        clearSelection={clearAllSelection}
      />
      <Stack className="gap-2 gap-lg-4">
        {products.map(item => (
          <Fragment key={item.id}>
            <ListProductItemCheckBox item={item} control={control} />
          </Fragment>
        ))}
      </Stack>
    </Form>
  );
};

export default ChooseProductsForm;
