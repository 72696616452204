import {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {DynamicFilterProps} from '../../components/Form/DynamicFilter';
import {OrderServiceTypeStatus} from '../../utils/status';
import {useFetchStoresQuery} from '../../services/storeApi';
import {selectUserAndToken} from '../../store/slices/authSlice';
import {useSelector} from 'react-redux';
import {UserProfileEnum} from '../../types/User';

export type UseOrderFilterReturn = {
  items: DynamicFilterProps<any>['items'];
  sellerStoresLength: number;
};

export default function useOrderFilter(): UseOrderFilterReturn {
  const intl = useIntl();
  const [sellerStoresLength, setSellerStoresLength] = useState(0);
  const {user} = useSelector(selectUserAndToken);

  const {data: userStoreList, isSuccess: isUsetStoreListSuccess} =
    useFetchStoresQuery(
      {
        query: {pageSize: 5},
      },
      {
        refetchOnMountOrArgChange: true,
        skip: false,
      }
    );

  useEffect(() => {
    if (user) {
      if (user.profileType === UserProfileEnum.Admin) {
        setSellerStoresLength(2); // for admin set default to more then 1
      } else if (userStoreList && isUsetStoreListSuccess) {
        if (userStoreList.data.length) {
          setSellerStoresLength(userStoreList.data.length);
        } else {
          setSellerStoresLength(0);
        }
      }
    }
  }, [userStoreList, isUsetStoreListSuccess, user]);

  const items = useMemo(() => {
    const res: UseOrderFilterReturn['items'] = [];

    res.push({
      name: 'buyer',
      label: intl.formatMessage({id: 'order_list.filters.buyer'}),
      type: 'text',
    });

    if (sellerStoresLength > 1) {
      res.push({
        name: 'store',
        label: intl.formatMessage({id: 'order_list.filters.store'}),
        type: 'text',
      });
    }

    res.push({
      name: 'delivery',
      label: intl.formatMessage({id: 'order_list.filters.delivery'}),
      type: 'checkbox-group',
      checkboxSize: 'default',
      formControlLabelClass: 'checkbox-filter-row',
      options: [
        {
          label: intl.formatMessage({
            id: 'dashboard.store_details.menu_tab.collection',
          }),
          value: OrderServiceTypeStatus.Collection,
        },
        {
          label: intl.formatMessage({
            id: 'dashboard.store_details.menu_tab.delivery',
          }),
          value: OrderServiceTypeStatus.Delivery,
        },
      ],
    });

    res.push({
      name: 'createdAt',
      label: intl.formatMessage({id: 'order_list.filters.date'}),
      type: 'date',
    });

    return res;
  }, [sellerStoresLength]);

  return {items, sellerStoresLength};
}
