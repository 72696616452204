import {useIntl} from 'react-intl';
import {Col, Container, Row, Stack} from 'react-bootstrap';
import KeywordSearch from '../../Form/KeywordSearch';
import useReviewsList from '../../../hooks/useReviewsList';
import DataTable from '../../Widgets/DataTable';
import {useParams} from 'react-router-dom';
import {selectUserAndToken} from '../../../store/slices/authSlice';
import {useSelector} from 'react-redux';
import {formatDateInMonth} from '../../../utils/functions';
import {Typography} from '@material-ui/core';
import ArrowLink from '../../Common/ArrowLink';
import {Review, ReviewStatus} from '../../../types/Review';
import useReviewDetails from '../../../hooks/useReviewDetails';
import {ReactComponent as RatingIcon} from '../../../assets/images/rating.svg';
import * as urls from '../../../constants/urls';
import Switch from '../../Common/Switch';
import {HeadCellType} from '../../Widgets/DataTable/DataTableHead';
import {useMemo} from 'react';
import {UserProfileEnum} from '../../../types/User';

const ReviewsTab = () => {
  const intl = useIntl();
  const {storeId} = useParams();

  const {user} = useSelector(selectUserAndToken);
  const {updateReviewStatus} = useReviewDetails();

  const {isSuccess, data, meta, tableState, isLoading, handleRowClick} =
    useReviewsList({
      storeId: Number(storeId),
    });

  const handleReviewUpdateStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: Review
  ) => {
    const newStatus = event.target.checked
      ? ReviewStatus.Public
      : ReviewStatus.Private;
    updateReviewStatus(row.id, newStatus);
  };

  const renderDate = (a: any, b: any, rowData: any) => {
    const {row} = rowData;
    return (
      <div className="pb-2 pb-lg-0">
        <Typography>{formatDateInMonth(row.createdAt)}</Typography>
      </div>
    );
  };

  const renderOrder = (a: any, b: any, rowData: any) => {
    const {row} = rowData;
    return (
      <div className="pb-2 pb-lg-0">
        <ArrowLink
          title={row.order.reference}
          url={urls.ORDER_DETAILS_PATH.replace(':orderId', row.order.id)}
        />
      </div>
    );
  };

  const renderBuyer = (a: any, b: any, rowData: any) => {
    const {row} = rowData;
    const fullName = row.user.firstName + ' ' + row.user.lastName;
    if (!row.user.id) {
      return (
        <div className="pb-2 pb-lg-0">
          <Typography>{fullName}</Typography>
        </div>
      );
    }
    return (
      <div className="pb-2 pb-lg-0">
        <ArrowLink
          title={fullName}
          url={urls.BUYER_PROFILE_DETAILS_PATH.replace(':id', row.user.id)}
        />
      </div>
    );
  };

  const renderDescription = (a: any, b: any, rowData: any) => {
    const {row} = rowData;
    return (
      <div className="pb-2 py-lg-vtl-11">
        <Typography className="d-lg-none">{row.description}</Typography>
        <div className="ellipsis-3 d-none d-lg-block">
          <Typography>{row.description}</Typography>
        </div>
      </div>
    );
  };

  const renderScore = (a: any, b: any, rowData: any) => {
    const {row} = rowData;
    return (
      <Stack direction="horizontal" gap={2}>
        <RatingIcon />
        <Typography>{row.rate}</Typography>
      </Stack>
    );
  };

  const renderTotal = (a: any, b: any, rowData: any) => {
    const {row} = rowData;
    return (
      <div className="d-flex">
        <Switch
          green
          mobileSmall
          checked={row.status === ReviewStatus.Public}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleReviewUpdateStatusChange(event, row);
          }}
        />
      </div>
    );
  };

  // Header cells items
  const HeaderCells = useMemo(() => {
    const res: HeadCellType[] = [
      {
        name: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({
          id: 'dashboard.store_details.reviews_tab.date',
        }),
        sortable: true,
        renderValue: (row: any, a, b) => renderDate(row, a, b),
        collapse: true,
        width: '12%',
      },
      {
        name: 'orderId',
        sortable: true,
        numeric: false,
        disablePadding: false,
        label: intl.formatMessage({
          id: 'dashboard.store_details.reviews_tab.order_id',
        }),
        renderValue: (row: any, a, b) => renderOrder(row, a, b),
        collapse: false,
        width: '12%',
      },
      {
        name: 'buyerName',
        numeric: false,
        sortable: true,
        disablePadding: false,
        label: intl.formatMessage({
          id: 'dashboard.store_details.reviews_tab.buyer_name',
        }),
        renderValue: (row: any, a, b) => renderBuyer(row, a, b),
        collapse: false,
        width: '12%',
      },
      {
        name: 'rate',
        numeric: false,
        sortable: true,
        disablePadding: false,
        label: intl.formatMessage({
          id: 'dashboard.store_details.reviews_tab.score',
        }),
        collapse: false,
        renderValue: (row: any, a, b) => renderScore(row, a, b),
        width: '12%',
      },
      {
        name: 'description',
        numeric: false,
        disablePadding: false,
        sortable: true,
        label: intl.formatMessage({
          id: 'dashboard.store_details.reviews_tab.description',
        }),
        collapse: true,
        renderValue: (row: any, a, b) => renderDescription(row, a, b),
        width: '27%',
      },
    ];

    if (user?.profileType === UserProfileEnum.Admin) {
      res.push({
        name: 'status',
        numeric: false,
        disablePadding: false,
        sortable: true,
        label: intl.formatMessage({
          id: 'dashboard.store_details.reviews_tab.accounted_total_score',
        }),
        collapse: true,
        renderValue: (row: any, a, b) => renderTotal(row, a, b),
        width: '25%',
      });
    }

    return res;
  }, [user]);

  return (
    <Container fluid>
      <div className="content-block">
        <Row className="search-row">
          <Col lg={5}>
            <KeywordSearch
              placeholder={intl.formatMessage({id: 'actions.search'})}
            />
          </Col>
        </Row>
        <Row>
          <Col xxl={12}>
            {/* Table */}
            {isLoading || (isSuccess && data?.length) ? (
              <DataTable
                headCells={HeaderCells}
                rows={data ?? []}
                handleParameterChange={tableState.handleDataTableParamChange}
                defaultOrder={tableState.orderDirection.toLowerCase() as any}
                defaultOrderBy={tableState.orderBy}
                defaultRowPerPage={tableState.pageSize}
                currentPage={tableState.page}
                onRowClick={handleRowClick}
                onChange={() => {}}
                loading={isLoading}
                total={meta ? meta.itemCount : 100}
              />
            ) : (
              <div className="text-center">
                {intl.formatMessage({
                  id: 'dashboard.store_details.reviews_tab.no_data',
                })}
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default ReviewsTab;
