import {Typography} from '@material-ui/core';
import React from 'react';

const ErrorMessage = ({error}: {error: string}) => {
  return (
    <Typography style={{color: '#FCA800'}} variant="subtitle2">
      {String(error)}
    </Typography>
  );
};

export default ErrorMessage;
