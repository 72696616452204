import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Box from '@material-ui/core/Box';
import {Col, Row} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ProductCategoryList from './ProductCategoryList';
import ScheduleItemTitle from './ScheduleItemTitle';
import {ReactComponent as ExpandIcon} from '../../../../assets/images/expand.svg';
import TimeRangeController from '../../../Form/HookForm/TimeRangeController';
import CheckboxGroupController from '../../../Form/HookForm/CheckboxGroupController';
import {MenuScheduleFormValues} from '../../../../hooks/useScheduleManagement';
import {StoreScheduleItem} from '../../../../types/StoreScheduleItem';
import {Control, FieldArrayWithId, useWatch} from 'react-hook-form';
import {useScheduleItemsListStyles} from './useStyles';
import {ProductCategory} from '../../../../types/ProductCategory';
import {CheckedProductCategoryState} from './ScheduleItemsList';
import ChooseProductsPopup from './ChooseProductsPopup';
import ConfirmationPopup from '../../../Shared/ConfirmationPopup';
import {isEmpty, isEqual} from 'lodash';
import {StoreSchedule} from '../../../../types/StoreSchedule';
import {CategoryCountState} from './SchedulesList';

type ScheduleItemState = {
  item: StoreScheduleItem;
  index: number;
  fieldId: string;
};

type ScheduleItemProps = {
  control: Control<MenuScheduleFormValues, any>;
  item: FieldArrayWithId<
    MenuScheduleFormValues,
    `schedules.${number}.items`,
    'fieldId'
  >;
  fields: FieldArrayWithId<
    MenuScheduleFormValues,
    `schedules.${number}.items`,
    'fieldId'
  >[];
  index: number;
  scheduleIndex: number;
  onDelete: (itemIndex: number) => void;
  onEdit: (value: StoreScheduleItem, itemIndex: number) => void;
  categories: ProductCategory[];
  deleteScheduleItem: (id: string | number) => void;
  savedData: StoreSchedule[];
  updateCount: (lists: CategoryCountState[]) => void;
  countLists: CategoryCountState[];
  updateIsResetting: (reset: boolean) => void;
  isResetting: boolean;
  updateIsDeleting: (deleting: boolean) => void;
};

const ScheduleItem = (props: ScheduleItemProps) => {
  const intl = useIntl();
  const scheduleItemsListStyles = useScheduleItemsListStyles();
  const {
    control,
    item,
    index,
    onDelete,
    onEdit,
    scheduleIndex,
    categories,
    fields,
    deleteScheduleItem,
    savedData,
    updateCount,
    countLists,
    isResetting,
    updateIsResetting,
    updateIsDeleting,
  } = props;
  const [expanded, setExpanded] = useState<number | false>(false);
  const [selectedItem, setSelectedItem] = useState<ScheduleItemState | null>(
    null
  );

  const [checkedProductCategories, setCheckedProductCategories] = useState<
    CheckedProductCategoryState[]
  >([]);

  const [chooseProductsPopup, setChooseProductsPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<ProductCategory>();
  // Delete confirmation popup
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false);
  const [savedProductIds, setSavedProductIds] = useState<number[]>([]);

  // it gets the productIds to be saved in the schedule item
  const getCheckedProductIds = (lists: CheckedProductCategoryState[]) => {
    const items: number[] = [];
    lists.forEach(item => {
      items.push(...item.checkedProducts);
    });
    return items;
  };

  // it gets the productIds to be saved in the schedule item
  const getCheckedCategoryIds = (lists: CheckedProductCategoryState[]) => {
    return lists.filter(item => item.isChecked).map(item => item.id);
  };

  // this method updates count and show save bar if a category is acted upon
  const compareOldProductIdsAndUpdateCount = (
    productIds: number[],
    categoryIds: number[]
  ) => {
    const copiedSavedData = [...savedData];
    let scheduleItem: StoreScheduleItem | undefined;
    copiedSavedData.forEach(schedule => {
      const changedItem = schedule.items.find(
        (item: StoreScheduleItem) => item.id === selectedItem?.item.id
      );
      if (changedItem) {
        scheduleItem = changedItem;
      }
    });
    if (scheduleItem) {
      const scheduleItemProductIds = Array.isArray(scheduleItem.products)
        ? scheduleItem.products.map(product =>
            typeof product === 'number' ? product : product.id
          )
        : [];
      const scheduleItemCatergoryIds = Array.isArray(scheduleItem.categories)
        ? scheduleItem.categories.map(product =>
            typeof product === 'number' ? product : product.id
          )
        : [];
      const copiedCountLists = [...countLists];
      const filteredProductIds = scheduleItemProductIds.filter(item =>
        savedProductIds.includes(item)
      );
      if (
        !isEqual(productIds.sort(), filteredProductIds.sort()) ||
        !isEqual(categoryIds.sort(), scheduleItemCatergoryIds.sort())
      ) {
        const categoryItem = copiedCountLists.find(
          item => item.itemId === selectedItem?.item.id
        );
        const categoryItemIndex = copiedCountLists.findIndex(
          item => item.itemId === selectedItem?.item.id
        );
        if (categoryItem) {
          categoryItem.count = 1;
          copiedCountLists[categoryItemIndex] = categoryItem;
          updateCount(copiedCountLists);
        } else {
          const newCountItem: CategoryCountState = {
            itemId: selectedItem?.item.id as number,
            count: 1,
          };
          updateCount([...copiedCountLists, newCountItem]);
        }
      } else {
        const newLists = copiedCountLists.filter(
          item => item.itemId !== selectedItem?.item.id
        );
        updateCount(newLists);
      }
    }
  };

  // update the field array with selected products
  const updateCheckedProductCategories = (
    items: CheckedProductCategoryState[]
  ) => {
    setCheckedProductCategories(items);
    const products = getCheckedProductIds(items);
    const categoryIds = getCheckedCategoryIds(items);
    if (!selectedItem) {
      return;
    }
    onEdit(
      {...selectedItem?.item, products, categories: categoryIds},
      selectedItem?.index
    );
    if (selectedItem.item.createdAt) {
      compareOldProductIdsAndUpdateCount(products, categoryIds);
    }
  };

  const onCategoryClick = (category: ProductCategory) => {
    setSelectedCategory(category);
    setChooseProductsPopup(true);
  };

  const openDeleteConfirmation = (
    item: StoreScheduleItem,
    index: number,
    fieldId: string
  ) => {
    setSelectedItem({item, index, fieldId});
    setDeleteConfirmationPopup(true);
  };

  const onDeleteItem = () => {
    setDeleteConfirmationPopup(false);
    updateIsDeleting(true);
    // Remove from array field
    onDelete(selectedItem?.index as number);
    // // Remove the item from api when saved
    if (selectedItem?.item.saved !== false) {
      deleteScheduleItem(selectedItem?.item.id as string);
    }
  };

  // It returns the category and products to select given the saved products
  const getProductCategories = (
    categoryLists: ProductCategory[],
    productIds: number[],
    categoryIds: number[]
  ): CheckedProductCategoryState[] => {
    const updatedCategories = categoryLists.map(category => {
      const filteredProducts = category.products.filter(product =>
        productIds.includes(product.id)
      );
      return {...category, products: filteredProducts};
    });
    const savedProducts: CheckedProductCategoryState[] = updatedCategories.map(
      item => {
        return {
          id: item.id,
          checkedProducts: item.products.map(item => item.id),
          isChecked: categoryIds.includes(item.id) && item.products.length > 0,
        };
      }
    );
    const oldSavedProductIds = getCheckedProductIds(savedProducts);
    setSavedProductIds(oldSavedProductIds);
    const comparedData: CheckedProductCategoryState[] = categoryLists
      .map(item => {
        const savedItem = savedProducts.find(saved => saved.id === item.id);
        if (!savedItem || item.products.length === 0) {
          return null;
        }
        return {
          ...savedItem,
          checkedProducts:
            savedItem.checkedProducts.length > 0
              ? savedItem.checkedProducts
              : [],
        };
      })
      .filter((item): item is CheckedProductCategoryState => item !== null);

    return comparedData;
  };

  // loads saved checked product and categories
  useEffect(() => {
    if (selectedItem) {
      const activeField = fields.find(
        item => item.fieldId === selectedItem?.fieldId
      );
      if (!activeField) {
        return;
      }

      const catList = getProductCategories(
        categories,
        activeField?.products as number[],
        activeField.categories as number[]
      );
      setCheckedProductCategories(catList);
    }
  }, [selectedItem, categories]);

  // reset category form
  useEffect(() => {
    if (isResetting) {
      setExpanded(false);
      updateIsResetting(false);
    }
  }, [isResetting]);

  // watch all the schedules fields for changes
  const watchedValue = useWatch<MenuScheduleFormValues>({control});

  // update selectedItem.item state when a schedule item field changes
  // this value is used to update the useFieldArray for a schedule item
  useEffect(() => {
    if (
      watchedValue &&
      !isEmpty(watchedValue) &&
      watchedValue.schedules &&
      watchedValue.schedules.length > 0
    ) {
      // get the current schedule day being edited
      const currentScheduleItems = watchedValue.schedules[scheduleIndex];
      if (
        currentScheduleItems &&
        currentScheduleItems.items &&
        currentScheduleItems.items.length > 0
      ) {
        // get the value of the scheduleItem in the items of the schedule
        const currentSchduleItem = currentScheduleItems.items[
          index
        ] as Partial<StoreScheduleItem>;
        if (currentSchduleItem) {
          // merge old state of selectedItem.item with the new updated item
          const scheduleItem: StoreScheduleItem = {
            ...(selectedItem?.item as StoreScheduleItem),
            ...currentSchduleItem,
          };
          // set selectedItem.item state
          setSelectedItem({
            ...(selectedItem as ScheduleItemState),
            item: scheduleItem,
          });
        }
      }
    }
  }, [watchedValue]);

  const handleExpandClick =
    (value: StoreScheduleItem, itemIndex: number, fieldId: string) =>
    (event: any, isExpanded: boolean) => {
      setExpanded(isExpanded ? itemIndex : false);
      setSelectedItem({
        item: value,
        index: itemIndex,
        fieldId,
      });
    };

  return (
    <>
      <Accordion
        key={index}
        square
        expanded={expanded === index}
        className={scheduleItemsListStyles.root}
        onChange={handleExpandClick(item, index, item.fieldId)}
      >
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <ScheduleItemTitle
            item={item}
            isExpanded={expanded === index}
            onDelete={() => openDeleteConfirmation(item, index, item.fieldId)}
            onEdit={item => onEdit(item, index)}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Row className="g-vtl-9">
            <Col lg={6}>
              <Typography className="font-weight-bold mb-vtl-15">
                <FormattedMessage id="dashboard.store_details.menu_tab.hours" />
              </Typography>
              <Row className="g-2 align-items-center">
                <Col lg={6}>
                  <TimeRangeController
                    control={control}
                    name={`schedules.${scheduleIndex}.items.${index}.openingTime`}
                    label={intl.formatMessage({
                      id: 'dashboard.store_details.menu_tab.opening',
                    })}
                  />
                </Col>
                <Col lg={6}>
                  <TimeRangeController
                    control={control}
                    name={`schedules.${scheduleIndex}.items.${index}.closingTime`}
                    label={intl.formatMessage({
                      id: 'dashboard.store_details.menu_tab.closing',
                    })}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Typography className="font-weight-bold mb-vtl-15">
                <FormattedMessage id="dashboard.store_details.menu_tab.service" />
              </Typography>
              <Box
                bgcolor="background.default"
                className="rounded-sm px-1 py-1"
              >
                <CheckboxGroupController
                  control={control}
                  name={`schedules.${scheduleIndex}.items.${index}.services`}
                  checkboxSize="default"
                  formGroupClass="d-flex mb-0"
                  groupClassName="row gx-2 gx-xl-4"
                  formControlLabelClass="col-6 col-xl-auto mx-0"
                  options={[
                    {
                      label: intl.formatMessage({
                        id: 'dashboard.store_details.menu_tab.collection',
                      }),
                      value: 'collection',
                    },
                    {
                      label: intl.formatMessage({
                        id: 'dashboard.store_details.menu_tab.delivery',
                      }),
                      value: 'delivery',
                    },
                  ]}
                />
              </Box>
            </Col>
          </Row>
          <hr className="text-border" />
          <ProductCategoryList
            onEditClick={onCategoryClick}
            updateCheckedProductCategories={updateCheckedProductCategories}
            checkedProductCategories={checkedProductCategories}
            categories={categories}
            selectedItem={selectedItem}
          />
        </AccordionDetails>
      </Accordion>
      <ChooseProductsPopup
        opened={chooseProductsPopup}
        onClose={() => setChooseProductsPopup(false)}
        category={selectedCategory}
        updateCheckedProductCategories={updateCheckedProductCategories}
        checkedProductCategories={checkedProductCategories}
        title={selectedItem?.item?.title as string}
      />

      <ConfirmationPopup
        opened={deleteConfirmationPopup}
        handleClose={() => {
          setDeleteConfirmationPopup(false);
          updateIsDeleting(false);
        }}
        onOk={onDeleteItem}
      />
    </>
  );
};

export default ScheduleItem;
