import {ReactNode} from 'react';
import {FormGroup} from 'react-bootstrap';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import cx from 'clsx';
import HintTooltip from '../../Common/HintTooltip';
import CustomSelect, {
  CustomSelectProps,
  SeletItemType,
} from '../../Common/Select';
import ErrorMessage from '../../Common/ErrorMessage';

export type SelectControllerProps<T extends FieldValues> = Omit<
  CustomSelectProps,
  'name' | 'control' | 'label' | 'items' | 'onChange' | 'value'
> & {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  excludeLabel?: boolean;
  showLabelHint?: boolean;
  labelHintText?: string | ReactNode;
  labelField?: string;
  valueField?: string;
  items: SeletItemType[];
  customOnChange?: (val: any) => void;
  required?: boolean;
  className?: string;
};

const SelectController = <T extends FieldValues>({
  name,
  control,
  label,
  excludeLabel,
  showLabelHint,
  labelHintText,
  labelField = 'label',
  valueField = 'value',
  items,
  customOnChange,
  required,
  className,
  ...custom
}: SelectControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      // @ts-ignore
      defaultValue={''}
      render={({field: {onChange, value}, fieldState: {error}}) => {
        const extra = {label};
        if (excludeLabel) {
          delete extra.label;
        }

        const onSelectChange = (val: string) => {
          onChange(val);
          if (customOnChange) {
            customOnChange(val);
          }
        };

        return (
          <FormGroup className={cx('form-group', className ?? 'mb-3 mb-lg-fg')}>
            {showLabelHint ? (
              <div className="label-with-hint d-flex">
                <span className="small-label pb-2 pe-1">
                  {label}
                  {required ? '*' : ''}
                </span>
                <HintTooltip id={`hint_${label}`} content={labelHintText} />
              </div>
            ) : (
              <span className="small-label pb-2">
                {label}
                {required ? '*' : ''}
              </span>
            )}
            <CustomSelect
              {...custom}
              value={value}
              onChange={onSelectChange}
              formControlProps={{error: !!error}}
              items={items.map((item: any) => ({
                ...item,
                label: item[labelField],
                value: item[valueField],
              }))}
              extraChild={
                error ? <ErrorMessage error={String(error.message)} /> : null
              }
              outlined
              {...extra}
            />
          </FormGroup>
        );
      }}
    />
  );
};

export default SelectController;
