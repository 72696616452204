import {useIntl} from 'react-intl';
import TextAreaController from '../../Form/HookForm/TextAreaController';
import {Control} from 'react-hook-form';
import {OrderFormValues} from '../../../hooks/order/useOrder';

type CancelFormProps = {
  control: Control<OrderFormValues>;
};
const CancelForm = ({control}: CancelFormProps) => {
  const intl = useIntl();

  return (
    <form>
      <TextAreaController
        name="description"
        control={control}
        label={intl.formatMessage({
          id: 'order_details.cancelOrder.reason_cancelation',
        })}
        formGroupClass="mb-vtl-4 mb-lg-2"
        rows={3}
      />
    </form>
  );
};

export default CancelForm;
