import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {ParameterType, QueryParameterType} from '../../types/QueryParameter';
import {getUrlSearchParams} from '../../utils/functions';

export type OrderDirection = 'DESC' | 'ASC';

export type DataTableParamChangeFn = (
  type: ParameterType,
  newValue: QueryParameterType
) => void;

export type UseDataTableStateReturn = {
  page: number;
  pageSize: number;
  orderBy: string;
  orderDirection: OrderDirection;
  order: string | string[];
  handleDataTableParamChange: DataTableParamChangeFn;
};

export type OrderMap = {[key: string]: string[]};

export type UseDataTableStateParams = {
  orderMap?: OrderMap;
  bindQueryParams?: boolean;
  defaultValues?: Partial<StateValue>;
};

type StateValue = {
  page: number;
  itemsPerPage: number;
  orderBy: string;
  orderDirection: OrderDirection;
};

export const getStates = ({
  searchParams,
  defaultValues,
  bindQueryParams,
}: {
  searchParams: URLSearchParams;
  defaultValues: StateValue;
  bindQueryParams?: boolean;
}): StateValue => {
  if (!bindQueryParams) {
    return defaultValues;
  }
  const res = {...defaultValues};

  const page = searchParams.get('page');
  if (page && !Number.isNaN(+page)) {
    res.page = Math.max(1, +page);
  }

  const itemsPerPage = searchParams.get('itemsPerPage');
  if (itemsPerPage && !Number.isNaN(+itemsPerPage)) {
    res.itemsPerPage = Math.max(1, +itemsPerPage);
  }

  const order = searchParams.get('order');
  if (order) {
    const desc = order.startsWith('-');
    res.orderDirection = desc ? 'DESC' : 'ASC';
    if (desc) {
      res.orderBy = order.substring(1);
    } else {
      res.orderBy = order;
    }
  }
  return res;
};

const defaultStateValues: StateValue = {
  page: 1,
  itemsPerPage: 100,
  orderBy: 'createdAt',
  orderDirection: 'DESC',
};

export default function useDataTableState({
  orderMap,
  bindQueryParams,
  defaultValues: initialValues,
}: UseDataTableStateParams = {}): UseDataTableStateReturn {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultValues = useMemo(
    () => ({
      ...defaultStateValues,
      ...initialValues,
    }),
    []
  );
  const initialState = getStates({
    searchParams,
    defaultValues,
    bindQueryParams,
  });

  const [page, setPage] = useState<number>(initialState.page);
  const [pageSize, setPageSize] = useState<number>(initialState.itemsPerPage);
  const [orderBy, setOrderBy] = useState<string>(initialState.orderBy);
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(
    initialState.orderDirection
  );

  useEffect(() => {
    const id = setTimeout(() => {
      if (bindQueryParams) {
        const nextStates = getStates({
          searchParams: getUrlSearchParams(searchParams),
          defaultValues,
          bindQueryParams,
        });
        if (nextStates.page && nextStates.page !== page) {
          setPage(nextStates.page);
        }
        if (nextStates.itemsPerPage && nextStates.itemsPerPage !== pageSize) {
          setPageSize(nextStates.itemsPerPage);
        }
        if (nextStates.orderBy && nextStates.orderBy !== orderBy) {
          setOrderBy(nextStates.orderBy);
        }
        if (
          nextStates.orderDirection &&
          nextStates.orderDirection !== orderDirection
        ) {
          setOrderDirection(nextStates.orderDirection);
        }
      }
    }, 250);

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const updateSearchParamas = useCallback(
    (values: Partial<StateValue> = {}) => {
      const params = getUrlSearchParams(searchParams);
      if (values.page) {
        params.set('page', String(values.page));
      }
      if (values.itemsPerPage) {
        params.set('itemsPerPage', String(values.itemsPerPage));
      }
      if (values.orderBy && values.orderDirection) {
        const val = `${values.orderDirection === 'DESC' ? '-' : ''}${
          values.orderBy
        }`;
        params.set('order', val);
      }
      setSearchParams(params);
    },
    [searchParams, setSearchParams]
  );

  const handleDataTableParamChange: DataTableParamChangeFn = useCallback(
    (type, newValue) => {
      if (type === 'order') {
        setOrderBy(newValue.orderBy ?? defaultValues.orderBy);
        setOrderDirection(newValue.order ?? defaultValues.orderDirection);
        setPage(1);
        updateSearchParamas({
          orderBy: newValue.orderBy ?? defaultValues.orderBy,
          orderDirection: newValue.order ?? defaultValues.orderDirection,
          page: 1,
          itemsPerPage: pageSize,
        });
      } else if (type === 'page') {
        setPage(newValue.page ?? 1);
        updateSearchParamas({page: newValue.page ?? defaultValues.page});
      } else if (type === 'pageSize' && bindQueryParams) {
        setPageSize(newValue.pageSize ?? defaultValues.itemsPerPage);
        setPage(1);
        updateSearchParamas({
          itemsPerPage: newValue.pageSize ?? defaultValues.itemsPerPage,
          page: 1,
        });
      }
    },
    [updateSearchParamas, pageSize]
  );

  const order = useMemo(() => {
    const prefix = orderDirection === 'DESC' ? '-' : '';

    if (orderBy && orderMap) {
      const fields = orderMap[orderBy];
      if (fields && fields.length > 0) {
        return fields.map(field => `${prefix}${field}`);
      }
    }

    return `${prefix}${orderBy}`;
  }, [orderBy, orderDirection, orderMap]);

  return {
    page,
    pageSize,
    order,
    orderBy,
    orderDirection,
    handleDataTableParamChange,
  };
}
