import {useIntl} from 'react-intl';
import {FieldValues, useForm} from 'react-hook-form';
import {Col, Form, Row, Stack} from 'react-bootstrap';
import InputController from '../../Form/HookForm/InputController';
import IconButton from '../../Common/IconButton';
import {ReactComponent as ApplyIcon} from '../../../assets/images/apply2.svg';
import {ReactComponent as CancelIcon} from '../../../assets/images/remove.svg';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useMemo} from 'react';

export type CustomValueFormProps = {
  onCancelClick: () => void;
  submit: (data: FieldValues) => void;
};

type FormData = {
  description: string;
};

const CustomValueForm = ({onCancelClick, submit}: CustomValueFormProps) => {
  const intl = useIntl();

  // validate form fields
  const schema = useMemo(
    () =>
      yup.object().shape({
        description: yup
          .string()
          .required(
            intl.formatMessage({id: 'validation.error.required_field'})
          ),
      }),
    [intl]
  );

  const {control, handleSubmit, reset} = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  const submitForm = (data: FieldValues) => {
    reset();
    submit(data);
  };

  return (
    <div className="pb-lg-vtl-15">
      <Form>
        <Row className="g-2 align-items-center">
          <Col lg={4}>
            <InputController
              name="description"
              control={control}
              required
              placeholder={intl.formatMessage({
                id: 'transaction_list.custom_value.description_placeholder',
              })}
              formGroupClass="mb-0"
              excludeLabel
            />
          </Col>
          <Col lg="auto">
            <Stack
              direction="horizontal"
              className="gap-2 gap-lg-1 justify-content-end"
            >
              <IconButton
                size="sm2"
                variant="action"
                onClick={handleSubmit(submitForm)}
              >
                <ApplyIcon />
              </IconButton>
              <IconButton size="sm2" variant="action" onClick={onCancelClick}>
                <CancelIcon />
              </IconButton>
            </Stack>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CustomValueForm;
