import {useIntl} from 'react-intl';
import {Typography} from '@material-ui/core';
import {Stack} from 'react-bootstrap';
import {ReactComponent as GoogleMapIcon} from '../../../../assets/images/googleMap.svg';
import InputController from '../../../Form/HookForm/InputController';
import {Control} from 'react-hook-form';
import {ThirdPartyIntegrationFormData} from '../../../../hooks/useUpdateAppThirdPartyIntegration';

export type GoogleMapProps = {
  control: Control<ThirdPartyIntegrationFormData, any>;
};

const GoogleMap = ({control}: GoogleMapProps) => {
  const intl = useIntl();

  return (
    <div className="card details-card">
      <div className="card-body">
        <Stack className="gap-2 gap-lg-vtl-15">
          <Stack direction="horizontal" gap={2}>
            <GoogleMapIcon />
            <Typography variant="h5">
              {intl.formatMessage({
                id: 'dashboard.app_details.app_settings.google_map.title',
              })}
            </Typography>
          </Stack>
          <InputController
            name="googleMapsApiKey"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.app_details.app_settings.google_map.key_label',
            })}
            required
          />
        </Stack>
      </div>
    </div>
  );
};

export default GoogleMap;
