import React, {useCallback, useEffect, useState} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {Col, Row} from 'reactstrap';
import Box from '@material-ui/core/Box';
import Select from '../../Common/Select';
import {ReactComponent as RemoveIcon} from '../../../assets/images/remove.svg';
import IconButton from '../../Common/IconButton';
import CustomAutocompletePopup from '../../Form/Inputs/CustomAutocompletePopup';
import {
  Condition,
  DiscountEligibilityType,
  EligibilityItem,
} from '../../../types/DiscountEligibility';
import {DiscountAttributeType} from '../../../types/DiscountEligibilityAttribute';
import {DiscountForm} from '../../../hooks/discount/useDiscountFormRequest';
import {Control} from 'react-hook-form';
import useDiscountCriteria from '../../../hooks/discount/useDiscountCriteria';
import {useParams} from 'react-router-dom';
import CurrencyController from '../../Form/HookForm/CurrencyController';
import DatePickerController from '../../Form/HookForm/DatePickerController';
import moment from 'moment';
import {Typography} from '@material-ui/core';
import {debounce} from 'lodash';
import {
  setField,
  getDiscountEligibilitySearchField,
} from '../../../store/slices/discountSlice';
import {useDispatch, useSelector} from 'react-redux';
import {AutocompleteInputChangeReason} from '@material-ui/lab';

export type CriteriaProps = {
  value: EligibilityItem;
  criteria: DiscountEligibilityType;
  attributes: DiscountAttributeType[];
  index: number;
  groupIndex: number;
  control: Control<DiscountForm, any>;
  handleConditionDelete: (index: number) => void;
};

type AutoCompleteOptionType = {
  title: string;
  value: string;
  eligibilityValue?: string;
};

const Criteria = (props: CriteriaProps) => {
  const {
    value,
    attributes,
    index,
    groupIndex,
    control,
    criteria,
    handleConditionDelete,
  } = props;

  const intl = useIntl();
  const params = useParams();
  const storeId = Number(params.storeId) ?? undefined;
  const dispatch = useDispatch();

  const [attributeError, setAttributeError] = useState<string>('');

  const {
    autoCompleteOptions,
    historyAutoCompleteOption,
    selectedAttribute,
    setEntityName,
    setSelectedAttribute,
    eligibilityValue,
    setEligibilityValue,
    update,
    setSearch,
  } = useDiscountCriteria({
    storeId,
    value,
    criteria,
    index,
    groupIndex,
    control,
  });

  const isNumeric = selectedAttribute?.conditionType === 'numeric' || false;
  const isString = selectedAttribute?.conditionType === 'string' || false;
  const isDate = selectedAttribute?.conditionType === 'date' || false;
  const buyerSearchField = useSelector(getDiscountEligibilitySearchField);
  const setSearchDebounce = useCallback(debounce(setSearch, 500), []);

  const isTwoInputFields = ['is_between', 'is_not_between'].includes(
    eligibilityValue.condition
  );

  useEffect(() => {
    setAttributeError('');
    if (!eligibilityValue.propertyName) {
      setAttributeError(
        intl.formatMessage({
          id: 'validation.error.required',
        })
      );
    }
  }, [eligibilityValue.propertyName]);

  useEffect(() => {
    dispatch(
      setField({
        key: 'discountEligibilitySearch',
        value: '',
      })
    );
  }, []);

  // set selected attribute based on selected attribute value will set the entity name and property name
  const setAttribute = useCallback(
    (value: string) => {
      const findAttribute = attributes.find(
        item => item.value === value
      ) as DiscountAttributeType;
      setSelectedAttribute(findAttribute || null);
      setEntityName(findAttribute?.entityName || '');
      const itemsDefaultValue =
        findAttribute?.conditionType === 'date'
          ? moment().format('YYYY-MM-DD')
          : ['is_one_of', 'is_not_one_of'].includes(findAttribute.conditions[0])
          ? []
          : '';
      const newEligibilityValue = {
        propertyName: findAttribute.propertyName,
        entityName: findAttribute.entityName,
        condition: findAttribute.conditions[0] as Condition,
        items: itemsDefaultValue,
        value: findAttribute.value,
      };
      setEligibilityValue(newEligibilityValue);
      update(index, newEligibilityValue);
    },
    [attributes, eligibilityValue]
  );

  // set condition based on selected attribute conditions
  const setCondition = useCallback(
    (value: string) => {
      const updateEligibilityValue = {
        ...eligibilityValue,
        condition: value as Condition,
        items: ['is_one_of', 'is_not_one_of'].includes(value)
          ? []
          : eligibilityValue.items,
      };
      setEligibilityValue(updateEligibilityValue);
      update(index, updateEligibilityValue);
    },
    [eligibilityValue]
  );

  // set items based on selected attribute conditions ( this is for multi select auto complete)
  const setItems = useCallback(
    (values: AutoCompleteOptionType[]) => {
      const existingEligibilityValue = values.find(
        value => value.eligibilityValue === eligibilityValue.value
      )?.eligibilityValue;

      // If discount is not new and eligibility value is already exists then set the items based on existing eligibility value
      if (params.discountId !== 'new' && existingEligibilityValue) {
        values = values.map(item => {
          return {
            ...item,
            eligibilityValue: existingEligibilityValue,
          };
        });
      }

      const itemsIds = values
        .filter(item => item.eligibilityValue === eligibilityValue.value)
        .map(item => Number(item.value));
      const updateEligibilityValue = {
        ...eligibilityValue,
        items: itemsIds,
      };
      setEligibilityValue(updateEligibilityValue);
      update(index, updateEligibilityValue);
    },
    [eligibilityValue]
  );

  // get selected value from auto complete options based on id
  const getSelectedValue = (id: number, entityName?: string) => {
    // If discount has existing eligibility values then show the existing values in auto complete options
    // If discount is new then show the auto complete options based on selected attribute
    const allData = value.itemsValues?.length
      ? [
          ...value.itemsValues.map(item => {
            return {
              id: item.id,
              entityName: value.entityName,
              ...item,
            };
          }),
          ...historyAutoCompleteOption,
        ]
      : [...historyAutoCompleteOption];
    return allData
      .filter(item => item.entityName === entityName)
      .find(item => item.id === Number(id)) as Record<string, any>;
  };

  // delete condition based on index
  const deleteCondition = () => {
    handleConditionDelete(index);
  };

  const searchTextInput = (
    autoCompleteValue: string,
    autoCompleteReason: AutocompleteInputChangeReason
  ) => {
    if (autoCompleteReason === 'input') {
      dispatch(
        setField({
          key: 'discountEligibilitySearch',
          value: autoCompleteValue || '',
        })
      );
      setSearchDebounce(autoCompleteValue ?? '');
    }
  };

  return (
    <Row className="g-2 align-items-start">
      <Col lg={3}>
        <Select
          outlined
          items={attributes.map(item => {
            return {
              label: intl.formatMessage({
                id: `dashboard.discount_details.eligibility.${item.value}`,
              }),
              value: item.value,
            };
          })}
          value={eligibilityValue?.value || ''}
          variant="default"
          onChange={value => setAttribute(value)}
        />
        {attributeError && (
          <Typography variant="subtitle2" color="error">
            {attributeError}
          </Typography>
        )}
      </Col>
      <Col lg>
        <Row className="g-2">
          <Col lg={4}>
            <Select
              outlined
              items={
                selectedAttribute
                  ? selectedAttribute?.conditions.map(item => {
                      return {
                        label: intl.formatMessage({
                          id: `dashboard.discount_details.eligibility.${item}`,
                        }),
                        value: item,
                      };
                    })
                  : []
              }
              value={eligibilityValue?.condition || ''}
              variant="default"
              onChange={value => setCondition(value)}
            />
            {attributeError && (
              <Typography variant="subtitle2" color="error">
                {attributeError}
              </Typography>
            )}
          </Col>
          {/* if condition type is numeric show numbers input */}
          {selectedAttribute && isNumeric && (
            <Col lg={isTwoInputFields ? 4 : 8}>
              <CurrencyController
                name={`eligibilities.${groupIndex}.eligibilityConditions.${index}.items`}
                control={control}
                prefixLabel={
                  selectedAttribute.propertyName !== 'price' ? '' : '£'
                }
                allowNegativeValue={false}
                allowDecimals={
                  selectedAttribute.propertyName !== 'price' ? false : true
                }
                className="mb-0"
              />
            </Col>
          )}
          {/* if condition type is numeric and is between show another numbers input */}
          {selectedAttribute && isNumeric && isTwoInputFields && (
            <Col lg={4}>
              <CurrencyController
                name={`eligibilities.${groupIndex}.eligibilityConditions.${index}.secondValue`}
                control={control}
                prefixLabel={
                  selectedAttribute.propertyName !== 'price' ? '' : '£'
                }
                allowNegativeValue={false}
                allowDecimals={
                  selectedAttribute.propertyName !== 'price' ? false : true
                }
                className="mb-0"
              />
            </Col>
          )}
          {/* if condition type is date show date input */}
          {selectedAttribute && isDate && (
            <Col lg={isTwoInputFields ? 4 : 8}>
              <DatePickerController
                control={control}
                name={`eligibilities.${groupIndex}.eligibilityConditions.${index}.items`}
                placeholder={intl.formatMessage({
                  id: 'dashboard.discount_details.start_date',
                })}
              />
            </Col>
          )}
          {/* if condition type is date and is between show another date input */}
          {selectedAttribute && isDate && isTwoInputFields && (
            <Col lg={3}>
              <DatePickerController
                control={control}
                name={`eligibilities.${groupIndex}.eligibilityConditions.${index}.secondValue`}
                placeholder={intl.formatMessage({
                  id: 'dashboard.discount_details.end_date',
                })}
              />
            </Col>
          )}
          {/* if condition type is string show auto complete multi select input */}
          {selectedAttribute && isString && (
            <Col lg={8}>
              <CustomAutocompletePopup
                options={
                  selectedAttribute
                    ? autoCompleteOptions!.map((item: Record<string, any>) => {
                        return {
                          id: item.id,
                          title:
                            item[`${selectedAttribute.propertyName}`] || '',
                          value: item.id.toString(),
                          eligibilityValue: eligibilityValue.value,
                        };
                      })
                    : []
                }
                values={
                  Array.isArray(eligibilityValue.items) &&
                  eligibilityValue.items.length
                    ? eligibilityValue.items.map(item => {
                        return {
                          id: Number(item),
                          title:
                            getSelectedValue(
                              Number(item),
                              eligibilityValue.entityName
                            )[`${eligibilityValue.propertyName.toString()}`] ||
                            '',
                          value: item.toString(),
                          eligibilityValue: eligibilityValue.value,
                        };
                      })
                    : []
                }
                onInputChange={searchTextInput}
                onChange={values => setItems(values)}
                inputValue={buyerSearchField}
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col xs="auto">
        <Box
          px={1}
          py={0.6}
          borderRadius={4}
          height={34}
          marginTop={0.1}
          bgcolor="divider"
        >
          <FormattedMessage id="conditions.and" />
        </Box>
      </Col>
      <Col xs="auto" className="ms-auto">
        <Box mt={0.5}>
          <IconButton size="sm2" variant="filled" onClick={deleteCondition}>
            <RemoveIcon />
          </IconButton>
        </Box>
      </Col>
    </Row>
  );
};

export default React.memo(Criteria);
