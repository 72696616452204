import moment from 'moment';
import {useIntl} from 'react-intl';
import useStatementOrderList, {
  UseStatementOrderListFilterSearchProps,
} from '../../hooks/useStatementOrderList';
import SubDataTable from '../Widgets/SubDataTable';
import {StatementOrder} from '../../types/Statement';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectStatements} from '../../store/slices/statementSlice';
import {Meta} from '../../types/Meta';
import {Typography} from '@material-ui/core';
import ArrowLink from '../Common/ArrowLink';
import {HeadCellType} from '../Widgets/DataTable/DataTableHead';
import FormattedCurrency from '../Shared/FormattedCurrency';

const StatementOrderList = (props: UseStatementOrderListFilterSearchProps) => {
  const statements = useSelector(selectStatements);

  const {statementId} = props;

  const statement = statements?.find(s => s.id === statementId);

  const [orders, setOrders] = useState<StatementOrder[]>(
    statement?.orders ?? []
  );
  const [meta, setMeta] = useState<Meta>();
  const intl = useIntl();
  const [page, setPage] = useState<number | undefined>();

  // select the statements from store
  const {isLoading, handleRowClick, tableState, skip} = useStatementOrderList({
    statementId,
    page,
    statement,
  });

  const getFormattedOrderData = (statementOrders: StatementOrder[]) => {
    return statementOrders.map(statementOrder => ({
      order: {
        id: statementOrder.id,
        reference: statementOrder.reference,
      },
      createdDate: statementOrder.createdAt,
      totalAmount: statementOrder.totalAmount,
      paymentMethod: statementOrder.paymentMethod,
      cardFee: statementOrder.cardFee,
      sellerFee: statementOrder.sellerFee,
    }));
  };

  useEffect(() => {
    if (statement) {
      setOrders(statement?.orders ?? []);
      setMeta(statement.orderMeta);
    }
  }, [statement]);

  const onParameterChange = (type: string, value: any) => {
    if (type === 'page' && value[type]) {
      setPage(value[type]);
    }
  };

  const renderDate = (val: any) => {
    const date = moment(val);
    return <Typography>{date.format('DD MMM YYYY')}</Typography>;
  };

  const renderOrderLink = (val: any) => {
    const {id, reference} = val;
    return (
      <ArrowLink
        className="text-dark"
        isExternalLink
        title={reference}
        url={`/admin/orders/${id}`}
      />
    );
  };
  const currency = orders?.length ? orders[0].currency : 'GBP';

  const renderNumberCell = (val: any) => (
    <div>
      <FormattedCurrency value={val.toFixed(2)} currency={currency} />
    </div>
  );

  const renderPaymentMethod = (val: any) => {
    return (
      <div>
        <Typography>
          {intl.formatMessage({
            id: `order_details.paymentMethods.${val}`,
          })}
        </Typography>
      </div>
    );
  };

  const HeaderCells: HeadCellType[] = [
    {
      name: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'transaction_list.subtable.order_date'}),
      renderValue: (row: any) => renderDate(row),
      wrapped: true,
    },
    {
      name: 'order',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'transaction_list.subtable.order_id'}),
      renderValue: (row: any) => renderOrderLink(row),
      wrapped: true,
      width: '15%',
    },
    {
      name: 'totalAmount',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'transaction_list.subtable.order_total'}),
      renderValue: (row: any) => renderNumberCell(row),
      wrapped: true,
    },
    {
      name: 'paymentMethod',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'transaction_list.subtable.payment_method',
      }),
      renderValue: (row: any) => renderPaymentMethod(row),
      wrapped: true,
    },
    {
      name: 'cardFee',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'transaction_list.card_fee',
      }),
      renderValue: (row: any) => renderNumberCell(row),
      wrapped: true,
    },
    {
      name: 'sellerFee',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({
        id: 'transaction_list.seller_fee',
      }),
      renderValue: (row: any) => renderNumberCell(row),
      wrapped: true,
      colspan: 4,
    },
  ];

  return (
    <>
      <SubDataTable
        headCells={HeaderCells}
        rows={getFormattedOrderData(orders)}
        loading={isLoading || skip}
        empty={false}
        currentPage={page}
        onChange={() => {}}
        onRowClick={handleRowClick}
        total={meta ? meta.itemCount : 10}
        handleParameterChange={onParameterChange}
        defaultOrder={tableState.orderDirection.toLowerCase() as any}
        defaultOrderBy={tableState.orderBy}
        defaultRowPerPage={tableState.pageSize}
      />
    </>
  );
};

export default StatementOrderList;
