import {useState} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import useTitle from '../../hooks/useTitle';
import {HelmetProvider} from 'react-helmet-async';
import {Helmet} from 'react-helmet';
import {Container, Row, Col} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import Button from '../Common/Button';
import AddIcon from '@material-ui/icons/Add';
import DataTable from '../Widgets/DataTable';
import KeywordSearch from '../Form/KeywordSearch';
import {ReactComponent as IconAdd} from '../../assets/images/add.svg';
import AddPopup from '../Widgets/AddPopup';
import useAppList from '../../hooks/useAppList';
import CreateAppForm from './Form/CreateAppForm';
import {Image, Stack} from 'react-bootstrap';
import BlankAppIconImage from '../Common/BlankAppIconImage';
import {AssetType} from '../../types/Asset';
import {App} from '../../types/App';
import ArrowLink from '../Common/ArrowLink';
import {HeadCellType} from '../Widgets/DataTable/DataTableHead';

const AppList = () => {
  const intl = useIntl();
  // Page title
  const {intlTitle} = useTitle();

  const [addPopup, setAddPopup] = useState(false);
  const {isLoading, data, handleRowClick, meta, tableState} = useAppList();

  const renderAppName = (a: any, b: any, rowData: any) => {
    const {row}: {row: App} = rowData;
    const {assets} = row;
    const appIcon = assets?.find(a => a.type === AssetType.AppIcon);
    return (
      <div className="py-vtl-11">
        <Stack direction="horizontal" gap={2}>
          {appIcon?.path ? (
            <Image
              src={appIcon?.path}
              className="preview rounded-sm"
              onClick={() => handleRowClick(row.id)}
              style={{cursor: 'pointer'}}
            />
          ) : (
            <BlankAppIconImage
              clickable
              onClick={() => handleRowClick(row.id)}
            />
          )}
          <p>{row.name}</p>
        </Stack>
      </div>
    );
  };

  const renderAppVersion = (a: any, b: any, rowData: any) => {
    const {row}: {row: App} = rowData;
    return (
      <div className="py-vtl-11">
        <Stack gap={1}>
          <p>{row.version}</p>
        </Stack>
      </div>
    );
  };

  const renderStore = (a: any, b: any, rowData: any) => {
    const {row}: {row: App} = rowData;

    return (
      <div className="py-vtl-11">
        <ArrowLink
          className="text-dark"
          isExternalLink
          title={row.store.name}
          url={`stores/${row.store.id}/general`}
        />
      </div>
    );
  };

  // Header cells items
  const HeaderCells: HeadCellType[] = [
    {
      name: 'name',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'app_list.app_name'}),
      collapse: false,
      width: '33%',
      renderValue: (row: any, a, b) => renderAppName(row, a, b),
      sortable: true,
    },
    {
      name: 'store',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'app_list.store'}),
      collapse: false,
      width: '33%',
      renderValue: (row: any, a, b) => renderStore(row, a, b),
      sortable: true,
    },
    {
      name: 'version',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({id: 'app_list.app_version'}),
      collapse: true,
      width: '33%',
      renderValue: (row: any, a, b) => renderAppVersion(row, a, b),
      sortable: true,
    },
  ];

  // Top Header
  const header = (
    <>
      {/* bigger padding bottom if tabs not displayed */}
      <div className="row align-items-center header-row pb-4">
        <div className="col-lg-6 col-sm-12 text-center text-lg-start">
          {/* App list */}
          <Typography variant="h3" className="font-weight-bolder">
            <FormattedMessage id="app_list.header" />
          </Typography>
        </div>
        <div className="col-lg-6 col-sm-12 text-center text-lg-end d-none d-lg-block">
          {/* Add App */}
          <Button
            primary
            title={intl.formatMessage({
              id: 'app_list.add_app',
            })}
            className="dashboard-btn"
            icon={<AddIcon />}
            onClick={() => setAddPopup(true)}
          />
        </div>
      </div>
    </>
  );

  // close Add App Popup after successful creation
  const closePopup = () => {
    setAddPopup(false);
  };

  return (
    <>
      <AddPopup
        opened={addPopup}
        onClose={() => setAddPopup(false)}
        title={intl.formatMessage({id: 'app_list.add_app'})}
        activeTab="app"
      >
        <CreateAppForm form="app-form" closePopup={closePopup} />
      </AddPopup>
      <HelmetProvider>
        <Helmet>
          <title>{intlTitle('app_list.header')}</title>
        </Helmet>
        <div className="dashboard-wrapper">
          <Container fluid>
            {header}
            {/* Filters */}
            <Row className="align-items-center justify-content-between search-row py-lg-2">
              <Col className="col-xl-5 col-lg-8 d-flex">
                {/* Search */}
                <KeywordSearch
                  placeholder={intl.formatMessage({
                    id: 'app_list.search_placeholder',
                  })}
                  resetPageOnChange
                />
              </Col>
            </Row>
            {/* Table */}
            <DataTable
              className="app-list pt-lg-2"
              empty={false}
              loading={isLoading}
              onRowClick={handleRowClick}
              headCells={HeaderCells}
              actionButton={{
                variant: 'action',
                size: 'sm',
                children: <IconAdd />,
                onClick: () => setAddPopup(true),
              }}
              rows={data ?? []}
              onChange={() => {}}
              total={meta ? meta.itemCount : 100}
              handleParameterChange={tableState.handleDataTableParamChange}
              defaultOrder={tableState.orderDirection.toLowerCase() as any}
              defaultOrderBy={tableState.orderBy}
              defaultRowPerPage={tableState.pageSize}
              currentPage={tableState.page}
              emptyDataMessage={intl.formatMessage({
                id: 'app_list.no_app_message',
              })}
            />
          </Container>
        </div>
      </HelmetProvider>
    </>
  );
};

export default AppList;
