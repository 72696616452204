import useDataTableState from './table/useDataTableState';

export default function usePaymentList() {
  const tableState = useDataTableState({
    defaultValues: {
      orderDirection: 'ASC',
      orderBy: 'dateTime',
    },
    bindQueryParams: true,
  });

  const handleRowClick = () => {};

  const data = [
    {
      id: '000',
      date: new Date('2023-12-01'),
      order_id: '234567',
      type: 'Payment',
      buyer_store: {
        buyer: {id: 6356, name: 'Kayan Mcconnell'},
        store: {id: 1234, name: 'Beijing Bowl'},
      },
      amount_stripeid: {
        amount: '£100',
        stripeid: '123456789012345',
      },
      status: {
        label: 'Pending',
        status: 'pending',
      },
    },
    {
      id: '001',
      date: new Date('2023-12-02'),
      order_id: '234567',
      type: 'Refund',
      buyer_store: {
        buyer: {id: 6356, name: 'Mujtaba Mccullough'},
        store: {id: 1234, name: 'The Sichuan Basin'},
      },
      amount_stripeid: {
        amount: '£100',
        stripeid: '123456789012345',
      },
      status: {
        label: 'Processing',
        status: 'warning',
      },
    },
    {
      id: '002',
      date: new Date('2023-12-03'),
      order_id: '234567',
      type: 'Payment',
      buyer_store: {
        buyer: {id: 6356, name: 'Mujtaba Mccullough'},
        store: {id: 1234, name: 'The Sichuan Basin'},
      },
      amount_stripeid: {
        amount: '£100',
        stripeid: '123456789012345',
      },
      status: {
        label: 'Cancelled',
        status: 'cancelled',
      },
    },
    {
      id: '003',
      date: new Date('2023-12-04'),
      order_id: '234567',
      type: 'Payment',
      buyer_store: {
        buyer: {id: 6356, name: 'Mujtaba Mccullough'},
        store: {id: 1234, name: 'The Sichuan Basin'},
      },
      amount_stripeid: {
        amount: '£100',
        stripeid: '123456789012345',
      },
      status: {
        label: 'Completed',
        status: 'completed',
      },
    },
  ];

  return {data, handleRowClick, tableState};
}
