import {useState} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import useTitle from '../../hooks/useTitle';
import {HelmetProvider} from 'react-helmet-async';
import {Helmet} from 'react-helmet';
import {Container} from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import TopPaper from '../Common/TopPaper';
import Tabs, {TabType} from '../Common/Tabs';
import StatementList from './StatementList';
import PaymentList from './PaymentList';

const TransactionList = () => {
  const intl = useIntl();
  // Page title
  const {intlTitle} = useTitle();
  // List tabs
  const tabs: TabType[] = [
    {
      label: intl.formatMessage({id: 'transaction_list.statement'}),
      value: 'statement',
    },
    {
      label: intl.formatMessage({id: 'transaction_list.payment'}),
      value: 'payment',
    },
  ];

  const [activeTab, setActiveTab] = useState('statement');

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // Top Header
  const header = (
    <>
      <div className="header-row">
        <div className="text-center text-lg-start">
          <Typography variant="h3">
            <FormattedMessage id="transaction_list.header" />
          </Typography>
        </div>
      </div>
      {/* Tabs */}
      <Tabs
        className="mb-0"
        indicators={false}
        value={activeTab}
        tabs={tabs}
        onChange={toggle}
      />
    </>
  );

  return (
    <HelmetProvider>
      <Helmet>
        <title>{intlTitle('transaction_list.header')}</title>
      </Helmet>
      <div className="dashboard-wrapper tabs-contained">
        <Container fluid>
          <TopPaper>{header}</TopPaper>
          {activeTab === 'statement' && <StatementList />}
          {activeTab === 'payment' && <PaymentList />}
        </Container>
      </div>
    </HelmetProvider>
  );
};

export default TransactionList;
