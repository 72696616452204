import {FeedbackParams} from '../store/queries/common';
import {Meta} from '../types/Meta';
import {SellerData} from '../types/SellerData';
import {DateFilter} from '../utils/date-filter';
import {baseApi} from './baseApi';

export type ListSellerResponse = {
  data: SellerData[];
  meta: Meta;
};

export type ListSellerQueryParameter = {
  query: {
    pageSize?: number;
    page?: number;
    order?: 'ASC' | 'DESC';
    orderBy?: string;
    search?: string;
    location?: string;
    status?: string;
    dateFilter?: DateFilter;
    startDate?: Date | string;
    endDate?: Date | string;
  };
} & FeedbackParams;

export type SellerParams = {
  body: {
    email: string;
    firstName: string;
    lastName: string;
    password?: string;
    phone: string;
    stores: (string | number)[];
  };
} & FeedbackParams;

export const sellerApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchSeller: builder.query<ListSellerResponse, ListSellerQueryParameter>({
      query: ({query}: ListSellerQueryParameter) => ({
        url: '/user/list/seller',
        method: 'GET',
        params: {
          ...query,
        },
      }),
      providesTags: ['Seller'],
    }),
    createSellerAccount: builder.mutation<any, SellerParams>({
      query: ({body}: SellerParams) => ({
        url: '/user/seller',
        method: 'POST',
        body,
      }),
      // @ts-ignore
      invalidatesTags: result => (result ? ['Seller'] : ['Nothing']),
    }),
  }),
});

export const {useFetchSellerQuery, useCreateSellerAccountMutation} = sellerApi;
