import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {DynamicFilterProps} from '../../components/Form/DynamicFilter';
import {StatementStatus} from '../../utils/status';
import {StatementStatusEnum} from '../../types/Statement';

export type UseStatementFilterReturn = {
  items: DynamicFilterProps<any>['items'];
};

export default function useStatementFilter(): UseStatementFilterReturn {
  const intl = useIntl();

  const items = useMemo(() => {
    const res: UseStatementFilterReturn['items'] = [
      {
        name: 'storeName',
        label: intl.formatMessage({id: 'transaction_list.store_name'}),
        type: 'text',
      },
      {
        name: 'period',
        label: intl.formatMessage({id: 'transaction_list.period'}),
        type: 'date',
      },
      {
        name: 'status',
        label: intl.formatMessage({id: 'transaction_list.status'}),
        type: 'checkbox-group',
        checkboxSize: 'default',
        formControlLabelClass: 'checkbox-filter-row',
        options: [
          {
            label: intl.formatMessage({
              id: `dashboard.status.${StatementStatus.Pending}`,
            }),
            value: StatementStatusEnum.Pending,
          },
          {
            label: intl.formatMessage({
              id: `dashboard.status.${StatementStatus.Completed}`,
            }),
            value: StatementStatusEnum.Completed,
          },
        ],
      },
      {
        name: 'statementOrderDateFilter',
        label: intl.formatMessage({id: 'transaction_list.subtable.order_date'}),
        type: 'date',
      },
    ];

    return res;
  }, []);

  return {items};
}
