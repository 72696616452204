import {Control, UseFormHandleSubmit} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Form} from 'reactstrap';
import InputController from '../../../../Form/HookForm/InputController';
import TextAreaController from '../../../../Form/HookForm/TextAreaController';
import {ProductVariationFormValues} from '../../../../../hooks/useProductVariation';

/**
 * Variation form
 */

type VariationFormProps = {
  form: string;
  control: Control<ProductVariationFormValues>;
  submit: (formData: ProductVariationFormValues) => void;
  handleSubmit: UseFormHandleSubmit<ProductVariationFormValues>;
};

const VariationForm = (props: VariationFormProps) => {
  // Translations
  const intl = useIntl();

  const {control, handleSubmit, submit, form} = props;

  return (
    <Form id={form} onSubmit={handleSubmit(submit)}>
      {/* Variation name */}
      <InputController
        name="name"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.variation_name',
        })}
        required
      />
      {/* Description */}
      <TextAreaController
        name="description"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.description',
        })}
      />
    </Form>
  );
};

export default VariationForm;
