import {FeedbackParams} from '../store/queries/common';
import {Address} from '../types/Address';
import {BuyerData} from '../types/BuyerData';
import {Meta} from '../types/Meta';
import {DateFilter} from '../utils/date-filter';
import {baseApi} from './baseApi';

export type ListBuyerResponse = {
  data: BuyerData[];
  meta: Meta;
};

export type ListBuyerQueryParameter = {
  query: {
    pageSize?: number;
    page?: number;
    order?: 'ASC' | 'DESC';
    orderBy?: string;
    search?: string;
    location?: string;
    status?: string;
    dateFilter?: DateFilter;
    startDate?: Date | string;
    endDate?: Date | string;
    uniqueId?: string;
  };
} & FeedbackParams;

export type BuyerParams = {
  body: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phone: string;
    enableNotification: boolean;
    addresses: Address[];
  };
} & FeedbackParams;

export const buyerApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchBuyer: builder.query<ListBuyerResponse, ListBuyerQueryParameter>({
      query: ({query}: ListBuyerQueryParameter) => ({
        url: '/user/list/buyer',
        method: 'GET',
        params: {
          ...query,
        },
      }),
      providesTags: ['Buyer'],
    }),
    createBuyerAccount: builder.mutation<any, BuyerParams>({
      query: ({body}: BuyerParams) => ({
        url: '/user/buyer',
        method: 'POST',
        body,
      }),
      // @ts-ignore
      invalidatesTags: result => (result ? ['Buyer'] : ['Nothing']),
    }),
  }),
});

export const {
  useFetchBuyerQuery,
  useLazyFetchBuyerQuery,
  useCreateBuyerAccountMutation,
} = buyerApi;
