import {useIntl} from 'react-intl';
import {Control, useWatch} from 'react-hook-form';
import {Row, Col} from 'reactstrap';
import RadioGroupController from '../../../Form/HookForm/RadioGroupController';
import SelectController from '../../../Form/HookForm/SelectController';
import CheckboxGroupController from '../../../Form/HookForm/CheckboxGroupController';
import CurrencyController from '../../../Form/HookForm/CurrencyController';
import {DiscountAmountType, DiscountType} from '../../../../types/Discount';
import {DiscountForm} from '../../../../hooks/discount/useDiscountFormRequest';

type SummaryProps = {
  control: Control<DiscountForm>;
};

const Summary = ({control}: SummaryProps) => {
  const intl = useIntl();
  const discountAmountType = useWatch({control, name: 'amountType'});

  return (
    <>
      <div className="card details-card">
        <div className="card-body">
          {/* Discount type */}
          <RadioGroupController
            name="type"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.discount_details.discount_type',
            })}
            options={[
              {
                label: intl.formatMessage({
                  id: 'dashboard.discount_details.order_amount',
                }),
                value: DiscountType.OrderAmount,
              },
              {
                label: intl.formatMessage({
                  id: 'dashboard.discount_details.loyalty_offer',
                }),
                value: DiscountType.LoyaltyOffer,
              },
            ]}
            showLabelHint
            isRow={false}
            labelHintText={intl.formatMessage({
              id: 'dashboard.discount_details.discount_type_tooltip',
            })}
            formGroupClass="mb-3"
          />
          {/* Discount amount */}
          <Row className="gx-2">
            <Col md={6} xl={8}>
              <SelectController
                name="amountType"
                control={control}
                label={intl.formatMessage({
                  id: 'dashboard.discount_details.discount_amount',
                })}
                items={[
                  {
                    label: intl.formatMessage({
                      id: 'dashboard.discount_details.percentage',
                    }),
                    value: DiscountAmountType.Percentage,
                  },
                  {
                    label: intl.formatMessage({
                      id: 'dashboard.discount_details.fixed',
                    }),
                    value: DiscountAmountType.Fixed,
                  },
                ]}
                variant="dropdown"
              />
            </Col>
            <Col>
              <CurrencyController
                className="mb-lg-fg"
                name="amount"
                control={control}
                prefixLabel={
                  discountAmountType === DiscountAmountType.Fixed
                    ? intl.formatMessage({id: 'units.pound'})
                    : intl.formatMessage({id: 'units.percents'})
                }
                prefixRight={
                  discountAmountType === DiscountAmountType.Percentage
                }
                label="&nbsp;"
              />
            </Col>
          </Row>
          <CheckboxGroupController
            name="deliveryType"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.discount_details.apply_to',
            })}
            options={[
              {
                label: intl.formatMessage({
                  id: 'dashboard.discount_details.delivery',
                }),
                value: 'delivery',
              },
              {
                label: intl.formatMessage({
                  id: 'dashboard.discount_details.collection',
                }),
                value: 'collection',
              },
            ]}
            checkboxSize="default"
            formGroupClass="mb-0"
            groupClassName="d-flex flex-column"
          />
        </div>
      </div>
    </>
  );
};

export default Summary;
