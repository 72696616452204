import {
  Drawer,
  isWidthUp,
  makeStyles,
  Theme,
  Typography,
  withWidth,
} from '@material-ui/core';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import {useEffect, useMemo, useState} from 'react';
import {Close as CloseIcon} from '@material-ui/icons';
import {DeepPartial, FieldValues, FormProvider, useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {DevTool} from '@hookform/devtools';
import clsx from 'clsx';
import {useSearchParams} from 'react-router-dom';
import {isEqual} from 'lodash';
import {ReactComponent as FilterIcon} from '../../assets/images/filter.svg';
import CustomButton from '../Common/Button';
import FilterItem, {FilterItemOptions} from './Filter/FilterItem';
import useFilterCollapsibleState from '../../hooks/filter/useFilterCollapsibleState';
import {getFormActiveFields, getUrlSearchParams} from '../../utils/functions';
import FilterTriggerButton, {
  FilterTriggerButtonProps,
} from './Filter/FilterTriggerButton';

// Filter styles
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[3],
    minWidth: '380px !important',
    zIndex: 1000,

    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 100%',
    },

    '&.MuiDrawer-paperAnchorDockedRight': {
      width: '15%',
      minWidth: 250,
      borderRadius: '40px 0 0 40px',
      paddingTop: 47,
      paddingBottom: 50,
    },

    '&.MuiDrawer-paperAnchorBottom': {
      height: '75%',
      borderRadius: '46px 46px 0 0',
      paddingTop: 28,
      paddingBottom: 50,
    },
  },
}));

export type DynamicFilterProps<T extends FieldValues> = {
  width: Breakpoint;
  items: FilterItemOptions<T>[];
  onFilter?: (values?: T) => void;
  triggerButtonProps?: Omit<
    FilterTriggerButtonProps,
    'className' | 'numberLabel' | 'onClick'
  >;
  bindQueryParams?: boolean;
  parseSearchParamsOnNavigate?: (
    searchParams: URLSearchParams
  ) => URLSearchParams;
  resetPageOnFilter?: boolean;
};

export const parseFilterSearchParams = <T extends FieldValues>(
  searchParams?: URLSearchParams
): DeepPartial<T> | undefined => {
  if (!searchParams) {
    return undefined;
  }

  try {
    return JSON.parse(searchParams.get('filters') ?? '{}') as DeepPartial<T>;
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return undefined;
};

export const buildResetValue = <T extends FieldValues>(
  items: FilterItemOptions<T>[]
): DeepPartial<T> => {
  const res: DeepPartial<T> = {} as DeepPartial<T>;

  (items ?? []).forEach((item: FilterItemOptions<T>) => {
    let value: any;
    if (item.type) {
      if (['autocomplete', 'text'].includes(item.type)) {
        value = '';
      } else if (
        ['autocomplete-multiple', 'checkbox-group'].includes(item.type)
      ) {
        value = [];
      } else if (['date'].includes(item.type)) {
        value = {};
      } else if (['text'].includes(item.type)) {
        value = '';
      } else if (item.type === 'select') {
        value = item.multiple ? [] : '';
      }
    }
    res[item.name] = value;
  });

  return res;
};

export const DynamicFilter = <T extends FieldValues>({
  width,
  items,
  onFilter,
  triggerButtonProps,
  bindQueryParams,
  parseSearchParamsOnNavigate,
  resetPageOnFilter,
}: DynamicFilterProps<T>) => {
  const [open, setOpen] = useState<boolean>(false);
  const intl = useIntl();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const form = useForm<T>({
    defaultValues: (() => {
      if (!bindQueryParams) {
        return undefined;
      }
      return parseFilterSearchParams(getUrlSearchParams(searchParams));
    })() as DeepPartial<T>,
  });

  const onClose = () => setOpen(false);

  const applyFilters = () => {
    if (bindQueryParams) {
      form.handleSubmit(vals => {
        let p = getUrlSearchParams(searchParams);
        p.set('filters', JSON.stringify(vals));
        if (parseSearchParamsOnNavigate) {
          p = parseSearchParamsOnNavigate(p);
        } else if (resetPageOnFilter) {
          p.set('page', '1');
        }
        setSearchParams(p);
      })();
    }
    if (onFilter) {
      form.handleSubmit(onFilter)();
    }
    setOpen(false);
  };

  const clearFilters = () => {
    form.reset(buildResetValue(items));
    if (bindQueryParams) {
      let p = getUrlSearchParams(searchParams);
      p.delete('filters');
      if (parseSearchParamsOnNavigate) {
        p = parseSearchParamsOnNavigate(p);
      }
      setSearchParams(p);
    }
    if (onFilter) {
      onFilter();
    }
    setOpen(false);
  };

  const values = form.watch();

  const activeFields = useMemo(
    () => getFormActiveFields(values ?? {}),
    [values]
  );

  const {handleBlock} = useFilterCollapsibleState();

  useEffect(() => {
    const filters = parseFilterSearchParams(getUrlSearchParams(searchParams));
    if (!isEqual(filters, values)) {
      const emptyValues = buildResetValue(items);
      form.reset({
        ...emptyValues,
        ...filters,
      } as DeepPartial<T>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <FormProvider {...form}>
      <FilterTriggerButton
        {...triggerButtonProps}
        className="m-0"
        numberLabel={activeFields.length}
        onClick={() => setOpen(true)}
      >
        <FilterIcon />
      </FilterTriggerButton>
      <Drawer
        anchor={isWidthUp('lg', width) ? 'right' : 'bottom'}
        variant="persistent"
        elevation={0}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        onClose={onClose}
      >
        {/* Filter block content */}
        <div className="filter-content">
          {/* Filter header */}
          <div className="filter-header border-bottom">
            {/* Close action button */}
            <CustomButton
              link
              light
              title={intl.formatMessage({id: 'actions.close'})}
              onClick={onClose}
              icon={<CloseIcon style={{color: '#989898', fontSize: '24px'}} />}
              // form={form}
              // type="submit"
            />
            {/* Filter title */}
            <div className="filter-header-title d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <FilterIcon />
                <Typography variant="h5">
                  <FormattedMessage id="actions.filter" />
                </Typography>
              </div>
              <div
                className={clsx(
                  'filter-counter-indicator text-center bg-s2z-secondary',
                  {invisible: activeFields.length < 1}
                )}
              >
                {activeFields.length}
              </div>
            </div>
          </div>
          {Array.isArray(items) &&
            items.map((item, index) => (
              <FilterItem
                key={item.name}
                {...item}
                collapsibleFilterBlockProps={{
                  ...handleBlock(index),
                  active: activeFields.includes(item.name),
                  // active: true,
                }}
              />
            ))}
        </div>
        {/* Filter actions */}
        <div className="filter-bottom-actions d-flex align-items-center justify-content-center justify-content-lg-end">
          {/* Clear */}
          <CustomButton
            link
            title={intl.formatMessage({id: 'actions.clear'})}
            onClick={clearFilters}
          />
          {/* Apply */}
          <CustomButton
            primary
            title={intl.formatMessage({id: 'actions.apply'})}
            onClick={applyFilters}
            // form={form}
            type="submit"
          />
        </div>
        <DevTool placement="top-left" control={form.control} />
      </Drawer>
    </FormProvider>
  );
};

export default withWidth()(DynamicFilter);
