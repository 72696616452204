import {ReactNode} from 'react';
import {makeStyles, Theme} from '@material-ui/core';
import RSC from 'react-scrollbars-custom';

export type ScrollbarProps = {
  height?: number | string;
  gap?: number;
  children: ReactNode;
  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  track: {
    width: `${theme.spacing(0.5)}px !important`,
    backgroundColor: `${theme.palette.background.paper} !important`,
    '& .ScrollbarsCustom-Thumb': {
      backgroundColor: `${theme.palette.divider} !important`,
      borderRadius: '0 !important',
    },
  },
}));

const Scrollbar = ({
  children,
  height = '55vh',
  gap = 30,
  disabled = false,
}: ScrollbarProps) => {
  const classes = useStyles();

  return disabled ? (
    <>{children}</>
  ) : (
    <div style={{height: height, marginRight: gap * -1}}>
      <RSC
        noScrollX
        trackYProps={{
          renderer: (props: any) => {
            const {elementRef, ...restProps} = props;
            return (
              <span {...restProps} ref={elementRef} className={classes.track} />
            );
          },
        }}
      >
        <div style={{paddingRight: gap - 10}}>{children}</div>
      </RSC>
    </div>
  );
};

export default Scrollbar;
