import {createTheme} from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    blue: Palette['primary'];
    green: Palette['secondary'];
    orange: Palette['secondary'];
    pink: Palette['secondary'];
    dark: Palette['secondary'];
    danger: Palette['secondary'];
    yellow: Palette['secondary'];
  }
  interface PaletteOptions {
    blue: PaletteOptions['primary'];
    green: Palette['secondary'];
    orange: PaletteOptions['secondary'];
    pink: Palette['secondary'];
    dark: Palette['secondary'];
    danger: Palette['secondary'];
    yellow: Palette['secondary'];
  }
}

/** App theme */
const theme = createTheme({
  palette: {
    background: {
      paper: '#ffffff',
      default: '#fafafa',
    },
    divider: '#EDEDED',
    text: {
      primary: '#282B3E',
      secondary: '#989898',
    },
    primary: {
      main: '#FF0000',
      dark: '#BA1100',
      light: '#ffe6e6',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#282B3E',
      light: '#B8B8B8',
    },
    error: {
      main: '#FCA800',
      dark: '#BA1100',
      light: '#FCA8001A',
    },
    green: {
      main: '#21B59B',
      light: '#21B59B1A',
      dark: '#21B59B',
      contrastText: '#ffffff',
    },
    orange: {
      main: '#FF8145',
      light: '#fff6e6',
    },
    blue: {
      main: '#3F51B5',
      contrastText: '#ffffff',
    },
    pink: {
      main: '#F263A8',
      light: '#F263A8',
      dark: '#F263A8',
      contrastText: '#ffffff',
    },
    dark: {
      main: '#313131',
      light: '#F7F7F7',
      dark: '#313131',
      contrastText: '#ffffff',
    },
    danger: {
      main: '#EE2737',
      light: '#EE27370D',
      dark: '#BA1100',
      contrastText: '#ffffff',
    },
    yellow: {
      main: '#FFA415',
      light: '#FFF6E6',
      dark: '#FFA415',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Averta Regular',
    fontSize: 14,
    button: {
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: 15,
    },
    h3: {
      fontSize: 25,
      fontFamily: 'Averta Semibold',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 20,
      fontFamily: 'Averta Semibold',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: 15,
      fontFamily: 'Averta Semibold',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: 14,
      lineHeight: 1.28,
      fontWeight: 400,
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.1,
    },
    subtitle2: {
      fontSize: 12,
      lineHeight: 'normal',
    },
    caption: {
      fontSize: 16,
      lineHeight: 1.2,
    },
  },
  spacing: 10,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

theme.shadows[1] = '0 5px 10px 0 rgba(40,43,62,0.2)';
theme.shadows[2] = '0 3px 6px 0 rgba(0,0,0,0.04)';
theme.shadows[3] = '0 0 30px 5px rgba(0,0,0,0.08)';
theme.shadows[4] = '0 0 20px 0 rgba(0,0,0,0.05)';
theme.shadows[5] = '0 5px 10px 0 rgba(14,19,30,0.02)';
theme.shadows[6] = '0 0 10px 0 rgba(0,0,0,0.2)';
theme.shadows[7] = '0px 5px 30px rgba(0, 0, 0, 0.08)';

// eslint-disable-next-line spaced-comment
//Responsive font sizes
// Headers
theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
    fontWeight: 'bold',
  },
};

// Body text
theme.typography.body1 = {
  ...theme.typography.body1,
  [theme.breakpoints.down('md')]: {
    fontSize: 13,
  },
};

export const PICKER_ROOT_STYLES = {
  '& .MuiFormLabel-root.Mui-error': {
    color: theme.palette.secondary.light,
  },
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: 0,
    '& + .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#EDEDED !important',
    top: 0,
  },
  '& .MuiInputBase-input': {
    padding: 10,
    fontSize: 14,
    '&::placeholder': {
      color: theme.palette.secondary.light,
      opacity: 1,
    },
  },
};

export default theme;
