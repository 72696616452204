/* eslint-disable react/no-unused-prop-types */
import {ReactNode, useEffect, useState} from 'react';
import clsx from 'clsx';
import {useIntl} from 'react-intl';
import {
  Box,
  CircularProgress,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableFooter,
} from '@material-ui/core';
import {get} from 'lodash';
import {
  BREAKPOINTS,
  TABLE_DEFAULT_BREAKPOINT,
} from '../../constants/appConstants';
import {HeadCellType} from './DataTable/DataTableHead';
import TablePagination from './DataTable/TablePagination';
import useStyles from './DataTable/useDataTableStyles';
import {ParameterType} from '../../types/QueryParameter';
import {arrayObjectsEqual} from '../../utils/functions';
import {useMediaQuery} from 'react-responsive';

/**
 * Data Table (main component)
 */

type UploadRowType = {
  status: any | null | undefined;
  level: number;
  id: string | number;
  uploadLevel: number;
};

export type DataTableProps = {
  /**
   * TRUE is rows contain checkbox column, default true
   */
  canSelect?: boolean;
  /**
   * Table data is empty
   */
  empty?: boolean;
  /**
   * Table data is loading now
   */
  loading: boolean;
  /**
   * Bulk actions is shown when items selected
   */
  bulkActions?: boolean;
  /**
   * Parameters for bulk actions block
   */
  bulkProps?: any;
  /**
   * Table head description array
   */
  headCells: HeadCellType[];
  /**
   * Align cells top vertically
   */
  alignTop?: boolean;
  /**
   * Table data
   * contains array of objects with the same fields as headCells object
   */
  rows: any[];
  /**
   * Additional table rows
   * contains array of objects with the same fields as headCells object PLUS upload level value (max 100)
   */
  uploadRows?: UploadRowType[] | null;
  /**
   * Additional action button shown on mobile view
   */
  actionButton: ReactNode | any;
  /**
   * Addtional action on the bottom of table
   */
  actionBottom: ReactNode | any;
  /**
   * Row click action
   */
  onRowClick: (arg0: any, arg1: any) => void;
  /**
   * Current pagination page
   */
  currentPage?: number;
  /**
   * Total items, used for pagination
   */
  total: number;
  /**
   * Main callback for the datatable change,
   * fired on pagination change (page, rowsPerPage) and on sorting change (sortBy, sortDirection)
   */
  onChange: (arg0: any) => void;
  /**
   * Default order, whether 'asc', 'desc'
   * asc is used as default value
   */
  defaultOrder?: 'asc' | 'desc';
  /**
   * row per page options
   */
  rowPerPageOptions?: number[];
  /**
   * Default order by column
   */
  defaultOrderBy?: string;
  /**
   * Default row per page value
   */
  defaultRowPerPage?: number;
  /**
   * Mobile view max width breakpoint
   */
  mobileBreakPoint?: number;
  selectedItems?: any[];
  onSelectedItemsChange?: (args0: any | any[]) => void;
  renderBulkActions?: () => void;
  isProcessingBulk?: boolean;
  isSelectedFn?: (row: any) => void;
  onSelect?: (row: any) => void | null;
  onSelectAll?: (isChecked: boolean) => void;
  showBulkActions?: boolean;
  selectedCount?: number;
  className?: string;
  handleParameterChange: (type: ParameterType, value: any) => void;
  hasPermission?: boolean;
  wrappedRow?: boolean;
  subTableRowsWrapped?: boolean;
  rowActions?: any[];
  emptyDataMessage?: string;
  SubTableRows?: any;
};

/**
 * Data Table Widget
 */
const SubDataTable = (props: DataTableProps) => {
  // styles
  const classes = useStyles();
  // rendering data props
  const {
    empty,
    loading,
    rows,
    currentPage,
    total,
    rowPerPageOptions,
    defaultRowPerPage,
  } = props;
  const {isProcessingBulk, handleParameterChange} = props;
  const intl = useIntl();
  // array of selected rows
  // current page shown
  const [page, setPage] = useState<number>(currentPage ?? 1);
  // number of rows shown in one page
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowPerPage);
  // when bulk actions in progress we could see all content covered opacity
  const [bulkOpacity, setBulkOpacity] = useState(false);
  const [wrappedHeadCells] = useState<any>(
    props.headCells.filter(item => item?.wrapped)
  );
  const [currentRows, setCurrentRows] = useState<any[]>([]);
  const isMobile = useMediaQuery({maxWidth: BREAKPOINTS.LG});

  // Update current rows
  useEffect(() => {
    if (rows && !arrayObjectsEqual(rows, currentRows)) {
      setCurrentRows(rows);
    }
  }, [rows]);

  // Change page
  const handleChangePage = (newPage: number) => {
    handleParameterChange('page', {page: newPage});
    setPage(newPage);
  };

  useEffect(() => {
    if (defaultRowPerPage && defaultRowPerPage !== rowsPerPage) {
      setRowsPerPage(defaultRowPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRowPerPage]);

  useEffect(() => {
    if (isProcessingBulk !== undefined) {
      setBulkOpacity(isProcessingBulk);
    }
  }, [isProcessingBulk]);

  // Data is loading at this moment
  // display only if data is empty
  if (loading) {
    return (
      <Paper className={classes.loadingWrapper} elevation={0}>
        <CircularProgress />
      </Paper>
    );
  }

  // Data is empty
  if (empty) {
    return (
      <Paper className={classes.emptyWrapper} elevation={0}>
        <Typography variant="h3" color="primary">
          {intl.formatMessage({id: 'dashboard.no_results'})}
        </Typography>
      </Paper>
    );
  }

  return (
    <>
      {!!currentRows?.length && (
        <>
          {loading && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <>
              <TableBody className={classes.subtable}>
                <TableRow className={clsx(classes.wrappedRow, 'headers')}>
                  {wrappedHeadCells.map((item: HeadCellType, key: number) => (
                    <TableCell
                      key={key}
                      className={clsx(
                        classes.tableCell,
                        'wrapped-row-cell headers'
                      )}
                      colSpan={item?.colspan && !isMobile ? item?.colspan : 1}
                      style={{width: item?.width ?? 'auto'}}
                    >
                      <Typography variant="body2" color="textSecondary">
                        {item.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
                {currentRows?.map((subrow: any, key: number) => (
                  <TableRow
                    key={key}
                    className={clsx(
                      classes.tableRow,
                      classes.wrappedRow,
                      'cells'
                    )}
                  >
                    {wrappedHeadCells.map((head: HeadCellType, key: number) => (
                      <TableCell
                        key={key}
                        className={clsx(
                          classes.tableCell,
                          'wrapped-row-cell cells'
                        )}
                        colSpan={!isMobile ? head?.colspan : 1}
                      >
                        {typeof head?.renderValue === 'function' ? (
                          head.renderValue(get(subrow, head.name), '', {
                            subrow,
                          })
                        ) : (
                          <Typography variant="body2">
                            {get(subrow, head.name)}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter className={classes.paginationSubtable}>
                <TablePagination
                  className={clsx(classes.tablePagination, 'pagination-row')}
                  rowsPerPageOptions={rowPerPageOptions || []}
                  count={total}
                  rowsPerPage={rowsPerPage || 10}
                  page={page}
                  hideRowsPerPage
                  bulkOpacity={bulkOpacity}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={() => {}}
                />
              </TableFooter>
            </>
          )}
        </>
      )}
      {currentRows && !currentRows.length && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <span>
            {' '}
            {intl.formatMessage({
              id: 'order_list.no_order_message',
            })}
          </span>
        </Box>
      )}
    </>
  );
};

SubDataTable.defaultProps = {
  empty: false,
  rowPerPageOptions: [10],
  defaultOrder: 'asc',
  defaultOrderBy: '',
  defaultRowPerPage: 10,
  mobileBreakPoint: TABLE_DEFAULT_BREAKPOINT,
  isProcessingBulk: false,
  showBulkActions: false,
  bulkActions: false,
  bulkProps: {},
  className: '',
  canSelect: false,
  onSelect: null,
  uploadRows: [],
  selectedItems: [],
  onSelectedItemsChange: undefined,
  renderBulkActions: undefined,
  isSelectedFn: undefined,
  onSelectAll: undefined,
  selectedCount: 0,
  alignTop: false,
  actionButton: null,
  actionBottom: null,
  wrappedRow: false,
  rowActions: [],
};

export default SubDataTable;
