import {FeedbackParams} from '../store/queries/common';
import {Order, OrderStatusEnum} from '../types/Order';
import {baseApi} from './baseApi';
import {Meta} from '../types/Meta';
import {DateFilter} from '../utils/date-filter';

export type OrderDetailsRequestParameter = {
  id: number;
} & FeedbackParams;

export type OrderUpdateRequestParameter = {
  id: number;
  body: {
    status?: OrderStatusEnum;
    scheduledTimeInMinutes?: number;
    scheduledTime?: string;
    description?: string;
  };
} & FeedbackParams;

export type ListOrderResponse = {
  data: Order[];
  meta: Meta;
};

export type ListOrderQueryParameter = {
  query?: {
    pageSize?: number;
    page?: number;
    orderBy?: string;
    order?: 'ASC' | 'DESC';
    status?: string;
    storeName?: string;
    buyerName?: string;
    deliveryType?: string;
    dateFilter?: DateFilter;
    startDate?: Date | string;
    endDate?: Date | string;
    search?: string;
  };
} & FeedbackParams;

export const orderApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    orderDetails: builder.query<Order, OrderDetailsRequestParameter>({
      query: ({id}: OrderDetailsRequestParameter) => ({
        url: `/admin/order/${id}`,
        method: 'GET',
      }),
    }),
    updateOrder: builder.mutation<Order, OrderUpdateRequestParameter>({
      query: ({id, body}: OrderUpdateRequestParameter) => ({
        url: `/admin/order/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    fetchOrders: builder.query<
      ListOrderResponse,
      ListOrderQueryParameter | void
    >({
      query: ({query}: ListOrderQueryParameter) => ({
        url: '/admin/order',
        method: 'GET',
        params: {
          ...query,
        },
      }),
      providesTags: ['Order'],
    }),
  }),
});

export const {
  useFetchOrdersQuery,
  useLazyFetchOrdersQuery,
  useOrderDetailsQuery,
  useUpdateOrderMutation,
} = orderApi;
