import {Control, UseFormHandleSubmit} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Form} from 'reactstrap';
import {ProductOptionGroupFormValues} from '../../../../../hooks/useProductOptionGroup';
import InputController from '../../../../Form/HookForm/InputController';
import TextAreaController from '../../../../Form/HookForm/TextAreaController';

/**
 * Group form
 */

type GroupFormProps = {
  form: string;
  control: Control<ProductOptionGroupFormValues>;
  submit: (formData: ProductOptionGroupFormValues) => void;
  handleSubmit: UseFormHandleSubmit<ProductOptionGroupFormValues>;
};

const GroupForm = (props: GroupFormProps) => {
  // Translations
  const intl = useIntl();
  const {control, handleSubmit, submit, form} = props;

  return (
    <Form id={form} onSubmit={handleSubmit(submit)}>
      {/* Group name */}
      <InputController
        name="name"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.group_name',
        })}
        required
      />
      {/* Description */}
      <TextAreaController
        name="description"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.options_tab.description',
        })}
      />
    </Form>
  );
};

export default GroupForm;
