import {useMemo} from 'react';
import {FieldValues, Path, useFormContext} from 'react-hook-form';
import {
  CollapsibleFilterBlock,
  CollapsibleFilterBlockProps,
} from '../../Widgets/FilterForm';
import AutocompleteMultipleController, {
  AutocompleteMultipleControllerProps,
} from '../HookForm/AutocompleteMultipleController';
import CheckboxGroupController, {
  CheckboxGroupControllerProps,
} from '../HookForm/CheckboxGroupController';
import DateSelectController, {
  DateSelectControllerProps,
} from '../HookForm/DateSelectController';
import InputController, {
  InputControllerProps,
} from '../HookForm/InputController';
import SelectController, {
  SelectControllerProps,
} from '../HookForm/SelectController';

export type FilterItemOptions<T extends FieldValues> = Partial<
  DateSelectControllerProps<T>
> &
  Partial<Omit<AutocompleteMultipleControllerProps<T>, 'options'>> &
  Partial<Omit<SelectControllerProps<T>, 'items'>> &
  Partial<Omit<AutocompleteMultipleControllerProps<T>, 'options'>> &
  Partial<Omit<CheckboxGroupControllerProps<T>, 'options'>> & {
    type?:
      | 'text'
      | 'date'
      | 'select'
      | 'autocomplete-multiple'
      | 'checkbox-group';
    name: Path<T>;
    options?: any[];
    items?: any;
    label: string;
    collapsibleFilterBlockProps?: Partial<CollapsibleFilterBlockProps>;
  };

export type FilterItemProps<T extends FieldValues> = FilterItemOptions<T> & {};

const FilterItem = <T extends FieldValues>({
  type = 'text',
  name,
  options,
  items,
  collapsibleFilterBlockProps,
  label,
  ...props
}: FilterItemProps<T>) => {
  const {control} = useFormContext();

  const item = useMemo(() => {
    if (type === 'date') {
      return (
        <DateSelectController
          {...(props as Partial<DateSelectControllerProps<T>>)}
          control={control}
          name={name}
        />
      );
    }

    if (type === 'autocomplete-multiple') {
      return (
        <AutocompleteMultipleController
          {...(props as Partial<AutocompleteMultipleControllerProps<T>>)}
          control={control}
          name={name}
          {...(options
            ? {
                options:
                  options as AutocompleteMultipleControllerProps<T>['options'],
              }
            : {})}
        />
      );
    }

    if (type === 'select') {
      return (
        <SelectController
          {...(props as Partial<SelectControllerProps<T>>)}
          control={control}
          name={name}
          items={items as SelectControllerProps<T>['items']}
        />
      );
    }

    if (type === 'checkbox-group') {
      return (
        <CheckboxGroupController
          {...(props as Partial<CheckboxGroupControllerProps<T>>)}
          control={control}
          name={name}
          options={options as CheckboxGroupControllerProps<T>['options']}
          formGroupClass="mb-0"
        />
      );
    }

    if (type === 'text') {
      return (
        <InputController
          {...(props as Partial<InputControllerProps<T>>)}
          control={control}
          name={name}
          formGroupClass="mb-0"
        />
      );
    }

    return (
      <div>
        Not implemented {type} - {name}
      </div>
    );
  }, [control, items, name, options, type]);

  return (
    <CollapsibleFilterBlock
      {...collapsibleFilterBlockProps}
      isOpen={collapsibleFilterBlockProps?.isOpen || false}
      onToggle={collapsibleFilterBlockProps?.onToggle || (() => {})}
      title={label}
    >
      {item}
    </CollapsibleFilterBlock>
  );
};

export default FilterItem;
