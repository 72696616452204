import {createStyles, makeStyles, Theme} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import {FormGroup, Col} from 'reactstrap';
// @ts-ignore
import {TimePicker} from 'react-ios-time-picker';
import Typography from '@material-ui/core/Typography';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import {timeMask} from '../../../utils/functions';

/**
 * Time picker
 */

type TimeMaskProps = {
  inputRef: (ref: HTMLInputElement | null) => void;
};

export const TimeMask = (props: TimeMaskProps) => {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={timeMask}
      placeholderChar={'0'}
      showMask
    />
  );
};

export type TimeFieldProps = Omit<TextFieldProps, 'onChange'> & {
  onChange: (value: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGroup: {
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 0,
    },
    field: {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          padding: 0,
        },
        '& .MuiInputBase-input': {
          padding: theme.spacing(0.5, 0),
        },
      },
    },
  })
);

const TimeField = (props: TimeFieldProps) => {
  const {label, value, onChange} = props;
  const classes = useStyles();

  return (
    <FormGroup row className={classes.formGroup}>
      <Col xs="auto" xl={6}>
        <Typography className="text-disabled">{label}</Typography>
      </Col>
      <Col xs xl={6} className="d-none d-lg-block">
        <TextField
          fullWidth
          variant="outlined"
          color="secondary"
          className={classes.field}
          inputProps={{}}
          value={value}
          onChange={e => onChange(e.target?.value || '')}
          InputProps={{
            inputComponent: TimeMask as any,
          }}
        />
      </Col>
      <Col xs="auto" className="d-lg-none mt-0">
        {/* Mobile time picker see more about: https://github.com/MEddarhri/react-ios-time-picker */}
        <TimePicker value="00:00" placeHolder="" />
      </Col>
    </FormGroup>
  );
};

export default TimeField;
