export enum DiscountEligibilityType {
  Buyer = 'buyer',
  Product = 'product',
}

type NumericCondition =
  | 'is_equal_to'
  | 'is_not_equal_to'
  | 'is_less_than'
  | 'is_greater_than'
  | 'is_between'
  | 'is_not_between';

type StringCondition = 'is_one_of' | 'is_not_one_of';

type DateCondition =
  | 'is_equal_to'
  | 'is_not_equal_to'
  | 'is_after'
  | 'is_before'
  | 'is_between'
  | 'is_not_between';

type BooleanCondition = 'is_selected' | 'is_unselected';

export type Condition =
  | NumericCondition
  | StringCondition
  | DateCondition
  | BooleanCondition;

export interface EligibilityItem {
  propertyName: string;
  value?: string;
  condition: Condition;
  items: string | number | number[] | string[];
  itemsValues?: Record<string, any>[];
  entityName: string;
}

export interface DiscountEligibility {
  id?: number;
  eligibilityType: DiscountEligibilityType;
  eligibilityConditions: EligibilityItem[];
}
