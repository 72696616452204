import {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {Statement, StatementStatusEnum} from '../../types/Statement';
import {ItemType} from '../../components/Common/Dropdown';
import {
  SendInvoiceStatementType,
  StatementListItemType,
} from '../../components/Transactions/StatementList';
import useStatementDetails from './useStatementDetails';
import {formatErrorMessage} from '../../utils/functions';

export default function useStatementFilter() {
  const intl = useIntl();
  const [invoicePopupStatementId, setInvoicePopupStatementId] = useState(0);

  const {updateStatementStatus, exportStatement, emailStatementInvoice} =
    useStatementDetails();

  const getNextStatus = (status: StatementStatusEnum | undefined) => {
    return status === StatementStatusEnum.Pending
      ? StatementStatusEnum.Completed
      : StatementStatusEnum.Pending;
  };

  /**
   * Format period {startDate} and {endDate} in format {02-25 Feb 2024}
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @returns {string}
   */
  const getCombinedDate = (startDate: Date, endDate: Date) => {
    const year = startDate.getFullYear();
    const month = startDate.toLocaleString('en-US', {month: 'short'});
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    return `${startDay}-${endDay} ${month} ${year}`;
  };

  const sendInvoiceStatement = ({
    statement,
    statementId,
  }: SendInvoiceStatementType) => {
    if (statement?.id) {
      emailStatementInvoice(statement.id);
    } else if (statementId) {
      emailStatementInvoice(statementId);
    }
  };

  const exportCsv = async (statement: StatementListItemType | undefined) => {
    if (statement?.id) {
      await exportStatement({
        statementId: statement.id,
        showProgressDialog: true,
        formatErrorMessage: error => formatErrorMessage(error, intl),
        formatSuccessMessage: () =>
          intl.formatMessage({
            id: 'transaction_list.statement_exported',
          }),
      }).then(csvData => {
        if (csvData?.data) {
          const url = URL.createObjectURL(csvData.data as Blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${getCombinedDate(
            statement.period.from,
            statement.period.to
          )}.xlsx`;
          link.click();
          // Cleanup the link element after download
          URL.revokeObjectURL(url);
        }
      });
    }
  };

  const showInvoice = (statement: StatementListItemType | undefined) => {
    if (statement?.id) {
      setInvoicePopupStatementId(statement.id);
    }
  };

  const updateStatus = async (statement: StatementListItemType) => {
    if (statement?.id) {
      const newStatus = getNextStatus(statement.status);
      await updateStatementStatus(statement.id, newStatus);
    }
  };

  const items = useMemo(() => {
    const getRowActions = (statement: Statement): ItemType[] => {
      return [
        {
          label: intl.formatMessage({
            id: `transaction_list.actions.set_as_${getNextStatus(
              statement.status
            ).toLowerCase()}`,
          }),
          onClick: (data: StatementListItemType) => updateStatus(data),
        },
        {
          label: intl.formatMessage({
            id: 'transaction_list.actions.send_invoice_statement',
          }),
          onClick: (data: StatementListItemType) =>
            sendInvoiceStatement({statement: data}),
        },
        {
          label: intl.formatMessage({
            id: 'transaction_list.actions.see_invoice',
          }),
          onClick: (data: StatementListItemType) => showInvoice(data),
        },
        {
          label: intl.formatMessage({
            id: 'transaction_list.actions.export_statement',
          }),
          onClick: (data: StatementListItemType) => exportCsv(data),
        },
      ];
    };

    return getRowActions;
  }, []);

  return {
    getRowActions: items,
    setInvoicePopupStatementId,
    invoicePopupStatementId,
    sendInvoiceStatement,
  };
}
