import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {DynamicFilterProps} from '../../components/Form/DynamicFilter';
import {DiscountStatus, DiscountType} from '../../types/Discount';

export type UseDiscountFilterReturn = {
  items: DynamicFilterProps<any>['items'];
};

export default function useDiscountFilter(): UseDiscountFilterReturn {
  const intl = useIntl();
  // Filter Items
  const items = useMemo(() => {
    const res: UseDiscountFilterReturn['items'] = [];

    // filter form for discount creation date
    res.push({
      name: 'createdAt',
      type: 'date',
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.creation_date',
      }),
    });

    // filter form for discount start date
    res.push({
      name: 'startDateFilter',
      type: 'date',
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.startDate',
      }),
    });

    // filter form for discount end date
    res.push({
      name: 'endDateFilter',
      type: 'date',
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.endDate',
      }),
    });

    // filter form for discount name filter
    res.push({
      name: 'name',
      type: 'text',
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.discountName',
      }),
    });

    // filter form for discount code filter
    res.push({
      name: 'code',
      type: 'text',
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.discountCode',
      }),
    });

    // filter form for discount type filter
    res.push({
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.discountType',
      }),
      type: 'checkbox-group',
      name: 'type',
      checkboxSize: 'default',
      formControlLabelClass: 'checkbox-filter-row',
      options: [
        {
          label: intl.formatMessage({
            id: 'dashboard.store_details.discount_tab.discount_type.orderAmount',
          }),
          value: DiscountType.OrderAmount,
        },
        {
          label: intl.formatMessage({
            id: 'dashboard.store_details.discount_tab.discount_type.loyaltyOffer',
          }),
          value: DiscountType.LoyaltyOffer,
        },
      ],
    });

    // filter form for discount status filter
    res.push({
      label: intl.formatMessage({
        id: 'dashboard.store_details.discount_tab.status',
      }),
      type: 'checkbox-group',
      name: 'status',
      checkboxSize: 'default',
      formControlLabelClass: 'checkbox-filter-row',
      options: [
        {
          label: intl.formatMessage({
            id: 'dashboard.store_details.discount_tab.status_action.active',
          }),
          value: DiscountStatus.Active,
        },
        {
          label: intl.formatMessage({
            id: 'dashboard.store_details.discount_tab.status_action.inactive',
          }),
          value: DiscountStatus.Inactive,
        },
        {
          label: intl.formatMessage({
            id: 'dashboard.store_details.discount_tab.status_action.upcoming',
          }),
          value: DiscountStatus.Upcoming,
        },
        {
          label: intl.formatMessage({
            id: 'dashboard.store_details.discount_tab.status_action.expired',
          }),
          value: DiscountStatus.Expired,
        },
      ],
    });

    return res;
  }, []);

  return {items};
}
