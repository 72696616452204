import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';

/**
 * Default state object with initial values.
 */
type DiscountSliceState = {
  discountEligibilitySearch: string;
};
const initialState: DiscountSliceState = {
  discountEligibilitySearch: '',
};

export const discountSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    setField: (
      state,
      action: PayloadAction<{
        key: keyof DiscountSliceState;
        value: string;
      }>
    ) => {
      const {key, value} = action.payload;
      state[key] = value;
    },
    clearInputFields: () => {
      return initialState;
    },
  },
});

export const {setField, clearInputFields} = discountSlice.actions;

export const getDiscountEligibilitySearchField = (state: RootState) =>
  state.discounts.discountEligibilitySearch;

export default discountSlice.reducer;
