import {Typography, Box} from '@material-ui/core';
import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import TimeField from '../Inputs/TimeField';

export type TimeRangeControllerProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  label?: string;
};

const TimeRangeController = <T extends FieldValues>({
  name,
  control,
  label,
}: TimeRangeControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({field: {value, onChange}, fieldState: {error}}) => {
        const touched = !!error;
        const handleTimeChange = (val: string) => {
          onChange(val);
        };

        return (
          <Box
            bgcolor="background.default"
            px={1.5}
            py={1}
            className="rounded-sm"
          >
            <TimeField
              name={name}
              label={label}
              value={value}
              onChange={(value: string) => handleTimeChange(value)}
              id="time"
            />

            {/* Show error here if errorHelperText is false */}
            {touched && error && (
              <Typography
                variant="subtitle2"
                className="text-secondary mt-vtl-3"
              >
                {String(error.message)}
              </Typography>
            )}
          </Box>
        );
      }}
    />
  );
};

export default TimeRangeController;
