import {FeedbackParams} from '../store/queries/common';
import {Meta} from '../types/Meta';
import {
  Statement,
  StatementOrder,
  StatementStatusEnum,
} from '../types/Statement';
import {StatementInvoice} from '../types/StatementInvoice';
import {DateFilter} from '../utils/date-filter';
import {baseApi} from './baseApi';

export type ListStatementResponse = {
  data: Statement[];
  meta: Meta;
};

export type ListStatementOrderResponse = {
  data: StatementOrder[];
  meta: Meta;
};

export type ListStatementQueryParameter = {
  query?: {
    pageSize?: number;
    page?: number;
    order?: 'ASC' | 'DESC';
    orderBy?: string;
    search?: string;
    location?: string;
    status?: string;
    dateFilter?: DateFilter;
    startDate?: Date | string;
    endDate?: Date | string;
    storeName?: string;
    statementOrderDateFilter?: DateFilter;
    statementOrderStartDate?: Date | string;
    statementOrderEndDate?: Date | string;
  };
} & FeedbackParams;

export type ListStatementOrderQueryParameter = {
  query?: {
    pageSize?: number;
    page?: number;
    order?: string;
    orderBy?: string;
    dateFilter?: DateFilter;
    startDate?: Date | string;
    endDate?: Date | string;
  };
  statementId: number;
} & FeedbackParams;

export type ExportStatementQueryParameter = {
  statementId: number;
} & FeedbackParams;

export type GetStatementInvoiceQueryParameter = {
  statementId: number;
} & FeedbackParams;

export type UpdateStatementStatusQueryParameter = {
  statementId: number;
  body: {
    status: StatementStatusEnum;
  };
} & FeedbackParams;

export type InvoiceLineItemRequestParameter = {
  body: {
    description: string;
    invoiceId: number;
  };
} & FeedbackParams;

export type DeleteLineItemParam = {
  id?: number;
} & FeedbackParams;

export type SendStatementInvoiceParam = {
  statementId?: number;
} & FeedbackParams;

export const statementApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    fetchStatements: builder.query<
      ListStatementResponse,
      ListStatementQueryParameter | void
    >({
      query: ({query}: ListStatementQueryParameter) => ({
        url: '/admin/statement',
        method: 'GET',
        params: {
          ...query,
        },
      }),
      providesTags: ['Statement'],
    }),
    fetchStatementOrder: builder.query<
      ListStatementOrderResponse,
      ListStatementOrderQueryParameter | void
    >({
      query: ({query, statementId}: ListStatementOrderQueryParameter) => ({
        url: `/admin/statement/${statementId}/order`,
        method: 'GET',
        params: {
          ...query,
        },
      }),
      providesTags: ['StatementOrder'],
    }),
    exportStatementCsv: builder.query<
      Blob | Object,
      ExportStatementQueryParameter | void
    >({
      query: ({statementId}: ExportStatementQueryParameter) => ({
        url: `/admin/statement/${statementId}/export`,
        method: 'GET',
        params: {},
        responseHandler: response => {
          if (response.status === 200) {
            return response.blob();
          } else {
            return response.json();
          }
        },
      }),
    }),
    fetchStatementInvoice: builder.query<
      StatementInvoice,
      GetStatementInvoiceQueryParameter | void
    >({
      query: ({statementId}: GetStatementInvoiceQueryParameter) => ({
        url: `/admin/statement/${statementId}/invoice`,
        method: 'GET',
        params: {},
      }),
      providesTags: ['StatementInvoice'],
    }),
    updateStatement: builder.mutation<
      Statement,
      UpdateStatementStatusQueryParameter | void
    >({
      query: ({statementId, body}: UpdateStatementStatusQueryParameter) => ({
        url: `/admin/statement/${statementId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Statement'],
    }),
    createInvoiceLineItem: builder.mutation<
      any,
      InvoiceLineItemRequestParameter
    >({
      query: ({body}: InvoiceLineItemRequestParameter) => ({
        url: '/admin/statement/line-item',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['StatementInvoice'],
    }),
    deleteInvoiceLineItem: builder.mutation<any, DeleteLineItemParam>({
      query: ({id}: DeleteLineItemParam) => ({
        url: `/admin/statement/line-item/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['StatementInvoice'],
    }),
    sendStatementInvoice: builder.mutation<any, SendStatementInvoiceParam>({
      query: ({statementId}: SendStatementInvoiceParam) => ({
        url: `/admin/statement/${statementId}/send-invoice`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useFetchStatementsQuery,
  useFetchStatementOrderQuery,
  useExportStatementCsvQuery,
  useLazyExportStatementCsvQuery,
  useFetchStatementInvoiceQuery,
  useUpdateStatementMutation,
  useCreateInvoiceLineItemMutation,
  useDeleteInvoiceLineItemMutation,
  useSendStatementInvoiceMutation,
} = statementApi;
