import {Control, UseFormHandleSubmit} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Form} from 'reactstrap';
import {ProductCategoryFormValues} from '../../../../../hooks/useProductCategory';
import {ProductCategoryStatus} from '../../../../../types/ProductCategory';
import StatusIndicator from '../../../../Common/StatusIndicator';
import InputController from '../../../../Form/HookForm/InputController';
import SelectController from '../../../../Form/HookForm/SelectController';
import TextAreaController from '../../../../Form/HookForm/TextAreaController';

type EditCategoryFormProps = {
  form: string;
  control: Control<ProductCategoryFormValues>;
  submit: (formData: ProductCategoryFormValues) => void;
  handleSubmit: UseFormHandleSubmit<ProductCategoryFormValues>;
};

const EditCategoryForm = (props: EditCategoryFormProps) => {
  const {control, handleSubmit, form, submit} = props;
  // Translations
  const intl = useIntl();

  return (
    <Form id={form} onSubmit={handleSubmit(submit)}>
      {/* Status */}
      <SelectController
        name="status"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.edit_category.status',
        })}
        items={[
          {label: 'On', color: 'green', value: ProductCategoryStatus.On},
          {
            label: 'Off today',
            color: 'orange',
            value: ProductCategoryStatus.OffForToday,
          },
          {label: 'Off', color: 'error', value: ProductCategoryStatus.Off},
        ]}
        renderSelectItem={({label, color}: any) => (
          <StatusIndicator label={label} color={color} />
        )}
        required
      />
      {/* Category name */}
      <InputController
        name="name"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.edit_category.category_name',
        })}
        required
      />
      {/* Description */}
      <TextAreaController
        name="description"
        control={control}
        label={intl.formatMessage({
          id: 'dashboard.store_details.product_categories_tab.edit_category.description',
        })}
      />
    </Form>
  );
};

export default EditCategoryForm;
