import {useIntl} from 'react-intl';
import useDataTableState, {
  UseDataTableStateReturn,
} from '../table/useDataTableState';

import {formatErrorMessage, getUrlSearchParams} from '../../utils/functions';
import {useEffect, useState} from 'react';
import {isEqual} from 'lodash';
import {parseFilterSearchParams} from '../../components/Form/DynamicFilter';
import {useFetchOrdersQuery} from '../../services/orderApi';
import {useNavigate} from 'react-router-dom';
import * as urls from '../../constants/urls';
import {OrderStatusEnum, OrderStatusRequestEnum} from '../../types/Order';

export type UseOrderListFilterSearchProps = {
  searchParams: any;
  activeTab: string;
};

interface FilterObject {
  [key: string]: number;
}

const parseDeliveryTypeFilter = (
  deliveryValues: string[] | FilterObject[]
): string | undefined => {
  if (!deliveryValues) {
    return undefined;
  } else if (deliveryValues.length && typeof deliveryValues[0] === 'string') {
    return deliveryValues.map(key => key).join(',');
  }
  return Object.entries(deliveryValues)
    .map(key => (key[1] ? key[0] : ''))
    .join(',');
};

export const getOrderStatus = (tabName: string) => {
  if (tabName === OrderStatusRequestEnum.InProgress) {
    return `${OrderStatusEnum.Accepted},${OrderStatusEnum.OutForDelivery},${OrderStatusEnum.ReadyForPickup}`;
  } else if (tabName === OrderStatusRequestEnum.Cancelled) {
    return `${OrderStatusEnum.Declined},${OrderStatusEnum.Cancelled}`;
  } else if (tabName === OrderStatusRequestEnum.Pending) {
    return `${OrderStatusEnum.Pending}`;
  } else if (tabName === OrderStatusRequestEnum.Completed) {
    return `${OrderStatusEnum.Completed}`;
  }
  return '';
};

export const getOrderListQuery = (
  tableState: UseDataTableStateReturn,
  filterValues: any,
  activeTab: string,
  q: string
) => {
  return {
    page: tableState.page,
    pageSize: tableState.pageSize,
    order: tableState.orderDirection,
    orderBy: tableState.orderBy,
    deliveryType: filterValues?.delivery
      ? parseDeliveryTypeFilter(filterValues.delivery)
      : undefined,
    status: getOrderStatus(activeTab),
    dateFilter: filterValues?.createdAt?.type
      ? filterValues.createdAt.type
      : undefined,
    startDate: filterValues?.createdAt?.from
      ? filterValues.createdAt.from
      : undefined,
    endDate: filterValues?.createdAt?.to
      ? filterValues.createdAt.to
      : undefined,
    storeName: filterValues.store ? filterValues.store : undefined,
    buyerName: filterValues.buyer ? filterValues.buyer : undefined,
    buyerId: filterValues.buyerId ? filterValues.buyerId : undefined,
    search: q,
  };
};

export default function useOrderList(props: UseOrderListFilterSearchProps) {
  const {searchParams, activeTab} = props;

  const intl = useIntl();
  const navigate = useNavigate();

  const tableState = useDataTableState({
    defaultValues: {
      orderDirection: 'DESC',
      orderBy: 'orderDate',
    },
    bindQueryParams: true,
  });

  const [ready, setReady] = useState(false);
  const [q, setQ] = useState('');

  const [filterValues, setFilterValues] = useState<any>({});

  useEffect(() => {
    const id = setTimeout(() => {
      const search = getUrlSearchParams(searchParams);
      const newFilterValues = parseFilterSearchParams(search);
      if (!isEqual(filterValues, newFilterValues)) {
        setFilterValues(newFilterValues);
      }
      const nextQ = search.get('q') ?? '';
      if (q !== nextQ) {
        setQ(nextQ);
      }

      setReady(true);
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }, [searchParams]);

  const {isSuccess, data, isFetching} = useFetchOrdersQuery(
    {
      query: getOrderListQuery(tableState, filterValues, activeTab, q),
      formatErrorMessage: error => formatErrorMessage(error, intl),
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !ready,
    }
  );

  const handleRowClick = (itemId: number) => {
    navigate(
      `${urls.ORDER_DETAILS_PATH.replace(':orderId', itemId.toString())}`
    );
  };

  return {
    isLoading: isFetching,
    isSuccess,
    meta: data?.meta,
    data: data?.data,
    handleRowClick,
    tableState,
  };
}
