import {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {Col, Container, Form, Row} from 'react-bootstrap';
import CurrencyController from '../../Form/HookForm/CurrencyController';
import InputController from '../../Form/HookForm/InputController';
import ServiceFee from './Fee/ServiceFee';
import Button from '../../Common/Button';
import SelectController from '../../Form/HookForm/SelectController';
import AddPopup from '../../Widgets/AddPopup';
import EditFeeForm from './Fee/EditFeeForm';
import useFeeList from '../../../hooks/useFeeList';
import {FeeType, Fee} from '../../../types/Fee';
import useServiceFee from '../../../hooks/useServiceFee';
import useFee from '../../../hooks/useFee';
import SaveFeature from '../../Shared/SaveFeature';
import {countryNameAndLabelList} from '../../../utils/country-list';
import {Store} from '../../../types/Store';

type FeeTabProps = {
  storeData: Store;
};

const FeeTab = (props: FeeTabProps) => {
  const intl = useIntl();
  const [addPopup, setAddPopup] = useState(false);
  const {data} = useFeeList();
  const {storeData} = props;
  const cardFeeData = data?.find((f: Fee) => f.type === FeeType.Card);
  const serviceFeeData = data?.filter((f: Fee) => f.type === FeeType.Service);

  const {
    handleSubmit: serviceFeeHandleSubmit,
    control: serviceFeeControl,
    serviceFeeSubmit,
    resetFormField: resetServiceFeeForm,
    createLoading: serviceFeeCreateLoading,
    createSuccess: serviceFeeCreateSuccess,
  } = useServiceFee({});
  const closeAddPopup = () => {
    setAddPopup(false);
    resetServiceFeeForm();
  };

  const {
    control: feeControl,
    handleSubmit: feeAndBillingHandleSubmit,
    feeAndBillingSubmit,
    resetFormField: resetFeeForm,
    count,
  } = useFee({cardFeeData, storeData});
  const cancelSave = () => {
    resetFeeForm();
  };

  // close modal on save success
  useEffect(() => {
    if (serviceFeeCreateSuccess) {
      closeAddPopup();
    }
  }, [serviceFeeCreateSuccess]);

  return (
    <>
      {count > 0 && (
        <SaveFeature
          onClose={cancelSave}
          open
          keyType="fee"
          num={count}
          type="submit"
          form="update-fee-form"
        />
      )}
      <AddPopup
        opened={addPopup}
        onClose={closeAddPopup}
        title={intl.formatMessage({
          id: 'dashboard.store_details.fee_tab.add_fee',
        })}
        activeTab={serviceFeeCreateLoading ? undefined : 'add-fee'}
      >
        <EditFeeForm
          form="add-fee-form"
          control={serviceFeeControl}
          handleSubmit={serviceFeeHandleSubmit}
          submit={serviceFeeSubmit}
        />
      </AddPopup>
      <Container fluid>
        <div className="content-block">
          <Row>
            <Col xxl={11}>
              <Row className="gx-vtl-15">
                <Col lg={4}>
                  <Form
                    id="update-fee-form"
                    onSubmit={feeAndBillingHandleSubmit(feeAndBillingSubmit)}
                  >
                    <div className="card details-card mb-3">
                      <div className="card-body">
                        <Typography variant="h5" className="mb-3">
                          <FormattedMessage id="dashboard.store_details.fee_tab.card_fee" />
                        </Typography>
                        <CurrencyController
                          name="cardFeePercentage"
                          required
                          control={feeControl}
                          defaultValue={cardFeeData?.sellerFeePercentage}
                          label={intl.formatMessage({
                            id: 'dashboard.store_details.fee_tab.percentage',
                          })}
                          prefixLabel={intl.formatMessage({
                            id: 'units.percents',
                          })}
                          prefixRight
                          className="mb-3 mb-lg-fg"
                        />
                        <CurrencyController
                          name="cardFeeFixed"
                          required
                          control={feeControl}
                          defaultValue={cardFeeData?.sellerFeeFixed}
                          label={intl.formatMessage({
                            id: 'dashboard.store_details.fee_tab.fixed',
                          })}
                          prefixLabel={intl.formatMessage({
                            id: 'units.pound',
                          })}
                          className="mb-0"
                        />
                      </div>
                    </div>
                    <div className="card details-card mb-3">
                      <div className="card-body">
                        <Typography variant="h5" className="mb-3">
                          <FormattedMessage id="dashboard.store_details.fee_tab.billing_details" />
                        </Typography>
                        <InputController
                          name="companyName"
                          required
                          control={feeControl}
                          label={intl.formatMessage({
                            id: 'dashboard.store_details.fee_tab.company_name',
                          })}
                        />
                        <InputController
                          name="addressLine1"
                          label={intl.formatMessage({
                            id: 'dashboard.store_details.fee_tab.address_1',
                          })}
                          control={feeControl}
                          required
                        />
                        <InputController
                          name="addressLine2"
                          control={feeControl}
                          label={intl.formatMessage({
                            id: 'dashboard.store_details.fee_tab.address_2',
                          })}
                        />
                        <Row className="gx-2 mb-3 mb-lg-fg">
                          <Col>
                            <InputController
                              name="city"
                              required
                              control={feeControl}
                              label={intl.formatMessage({
                                id: 'dashboard.store_details.general_tab.city',
                              })}
                              formGroupClass="mb-0"
                            />
                          </Col>
                          <Col>
                            <InputController
                              name="postCode"
                              required
                              control={feeControl}
                              label={intl.formatMessage({
                                id: 'dashboard.store_details.general_tab.postcode',
                              })}
                              formGroupClass="mb-0"
                            />
                          </Col>
                        </Row>
                        <SelectController
                          name="country"
                          required
                          control={feeControl}
                          label={intl.formatMessage({
                            id: 'dashboard.store_details.fee_tab.country',
                          })}
                          items={countryNameAndLabelList()}
                        />
                        <InputController
                          name="vatNumber"
                          required
                          control={feeControl}
                          label={intl.formatMessage({
                            id: 'dashboard.store_details.fee_tab.vat_number',
                          })}
                          formGroupClass="mb-0"
                        />
                      </div>
                    </div>
                  </Form>
                </Col>
                <Col>
                  {serviceFeeData &&
                    serviceFeeData.map((fee: Fee) => (
                      <ServiceFee
                        key={fee.id}
                        fee={fee}
                        className="mb-3"
                        title={fee.name}
                      />
                    ))}
                  <Row>
                    <Col md={5} xl={3}>
                      <Button
                        primary
                        outlined
                        title={intl.formatMessage({
                          id: 'dashboard.store_details.fee_tab.add_fee',
                        })}
                        className="w-100"
                        onClick={() => setAddPopup(true)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default FeeTab;
