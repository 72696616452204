import axios from 'axios';
import {refreshToken, logout} from '../store/slices/authSlice';
import {Mutex} from 'async-mutex';
import store from '../store/store';

const mutex = new Mutex();

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(config => {
  const state = store.getState();
  const token = state.auth.accessToken;
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    // i want to check if the axios is cancelled as it will have no error response
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const release = await mutex.acquire();
      try {
        const state = store.getState();
        const refreshResult = await axiosInstance.get('/auth/refresh', {
          headers: {
            authorization: `${state.auth.refreshToken}`,
          },
        });
        if (refreshResult.status === 200) {
          store.dispatch(refreshToken(refreshResult.data));
          axiosInstance.defaults.headers.common[
            'authorization'
          ] = `${refreshResult.data.accessToken}`;
          return axiosInstance(originalRequest);
        } else {
          store.dispatch(logout());
        }
      } finally {
        release();
      }
    }
    if (
      error.response.status === 403 &&
      error.response.data.message === 'SUSPENDED_USER'
    ) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
