import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {RouterProvider} from 'react-router-dom';
import AppRoutes from './components/Routes/Routes';
import theme from './constants/theme';
import AppToastMessages from './components/global/feedback/AppToastMessages';
import AppProgressDialog from './components/global/feedback/AppProgressDialog';
import GmapsScriptLoader from './components/Gmaps/GmapsScriptLoader';
import * as Sentry from '@sentry/react';
import ErrorPage from './components/Error/Error';
import {useSelector} from 'react-redux';
import {selectUserAndToken} from './store/slices/authSlice';

const ErrorFallback = <ErrorPage />;

function App() {
  const {user} = useSelector(selectUserAndToken);
  if (user) {
    Sentry.setUser({
      id: user.id,
      username: user.firstName
        ? user.firstName + ' ' + user.lastName
        : user.username,
      email: user.email,
    });
  }
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppToastMessages />
          <GmapsScriptLoader />
          <AppProgressDialog />
          <RouterProvider router={AppRoutes} />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}
export default Sentry.withProfiler(App);
