import {useEffect, useState} from 'react';
import {
  UpdateStoreParam,
  useGetStoreQuery,
  useUpdateStoreMutation,
} from '../services/storeApi';
import {StoreStatusValue} from '../utils/status';
import {useIntl} from 'react-intl';
import {
  formatErrorMessage,
  isApiValidationError,
  isKeyMatching,
  mapErrorToFormFields,
  trimString,
} from '../utils/functions';
import {Store, AppUrl} from '../types/Store';
import {OptionProps} from '../components/Form/Inputs/AutocompleteMultiple';
import {useDispatch} from 'react-redux';
import {
  setField,
  clearInputFields,
  getNonEmptyFields,
} from '../store/slices/storeSlice';
import {useSelector} from 'react-redux';
import {SellerData} from '../types/SellerData';
import {unstable_usePrompt as usePrompt, useNavigate} from 'react-router-dom';
import {enqueueErrorMessage} from '../store/slices/appSlice';
import {isEqual} from 'lodash';
import {StoreType} from '../types/StoreType';
import {SearchAddressResult} from '../types/SearchAddressResult';
import useStoreDetailFormRequest, {
  GeneralFormValues,
} from './store/useStoreDetailFormRequest';
import {CustomError} from '../types/CustomError';

type UseStoreDetailProps = {
  id?: number | string;
  linkedSellers: SellerData[];
  showSave: boolean;
};

type HeaderDetails = {
  name: string;
  status: string;
};

export default function useStoreDetails({
  id,
  linkedSellers,
  showSave,
}: UseStoreDetailProps) {
  // translations
  const intl = useIntl();

  const dispatch = useDispatch();

  // react-router
  const navigate = useNavigate();

  // clear redux field
  const clearReduxFields = () => {
    dispatch(clearInputFields());
  };

  const {count} = useSelector(getNonEmptyFields);
  const {
    data,
    isSuccess,
    isError,
    error: fetchStoreError,
  } = useGetStoreQuery(
    {
      id,
      showProgressDialog: true,
    },
    {refetchOnMountOrArgChange: true, refetchOnReconnect: true}
  );

  useEffect(() => {
    clearReduxFields();
    if (isError && fetchStoreError) {
      let errorMessage = '';
      // @ts-ignore
      const message = fetchStoreError?.data?.message;
      // @ts-ignore
      const statusCode = fetchStoreError?.data?.statusCode;
      if (statusCode === 403) {
        errorMessage = intl.formatMessage({
          id: `error.${message}`,
        });
      } else if (statusCode === 404) {
        errorMessage = intl.formatMessage({
          id: `error.${message}`,
        });
      }
      dispatch(enqueueErrorMessage(errorMessage));
      setTimeout(() => {
        // navigate back
        navigate(-1);
      }, 1000);
    }
  }, [isError, fetchStoreError]);

  // alert user when they try to reload when edited field has not been saved
  usePrompt({
    when: (count > 0 || (showSave && !isError)) as boolean,
    message: intl.formatMessage({id: 'dashboard.confirm_not_saved_message'}),
  });
  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent) => {
      if (isError) {
        return;
      }
      if (count > 0 || showSave) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [count, showSave, isError]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    formState,
  } = useStoreDetailFormRequest();
  const {touchedFields, dirtyFields, errors} = formState;

  const [headerDetails, setHeaderDetails] = useState<HeaderDetails>({
    name: '',
    status: '',
  });

  const [address, setAddress] = useState<SearchAddressResult>({
    address: '',
    city: '',
    postCode: '',
    latitude: 0,
    longitude: 0,
    country: '',
  });

  // handle validation for address
  const [addressError, setAddressError] = useState<string | null>(null);
  const [enteredValue, setEnteredValue] = useState<string>('');
  const [isAddressEmpty, setIsAddressEmpty] = useState<boolean>(false);

  // update address state when user select an address suggestion
  const updateAddressValue = (values: SearchAddressResult) => {
    setAddress(values);
    const postCode = values?.postCode?.toString() as string;
    // set field to address
    if (data?.store?.address?.postCode !== postCode) {
      dispatch(
        setField({
          key: 'postCode',
          value: 1,
        })
      );
    }
    setAddressError(null);
    if (!values.city || !values.postCode) {
      setIsAddressEmpty(true);
    } else {
      setIsAddressEmpty(false);
    }
  };

  // set error message for address
  const updateAddressError = (message: string | null) =>
    setAddressError(message);

  const handleSetValue = (store: Store) => {
    let defaultNote = store.orderRejectionNote;
    if (defaultNote === 'UNABLE_TO_FULFILL_ORDER') {
      defaultNote = intl.formatMessage({
        id: `general.${defaultNote}`,
      });
    }
    setValue('name', store?.name ?? '');
    setValue('email', store?.email ?? '');
    setValue('phone', store?.phone ?? '');
    setValue('description', store?.description ?? '');
    setValue('externalStoreUrl', store?.externalStoreUrl ?? '');
    setValue('news', store?.news ?? '');
    setValue('image', store?.image ?? '');
    setValue('orderRejectionNote', defaultNote);
    setValue(
      'paymentMethod',
      Array.isArray(store.paymentMethod) ? store.paymentMethod : []
    );
    setValue('storeUrl', store?.storeUrl ? trimString(store?.storeUrl) : '');
    setValue('city', store?.address?.city ?? '');
    setValue('postCode', store?.address?.postCode ?? '');
    const defaultStoreTypes =
      store.storeTypes.length > 0
        ? store.storeTypes.map(item => ({
            title: item.name,
            value: item.id,
          }))
        : [];
    setValue('storeTypes', defaultStoreTypes);

    if (store.appUrl && store.appUrl.length > 0) {
      const googleAppUrl = store?.appUrl?.find(
        item => item.name === 'googleAppUrl'
      );
      const appleAppUrl = store?.appUrl?.find(
        item => item.name === 'appleAppUrl'
      );
      setValue('googleAppUrl', googleAppUrl ? googleAppUrl.url : '');
      setValue('appleAppUrl', appleAppUrl ? appleAppUrl.url : '');
    } else {
      setValue('googleAppUrl', '');
      setValue('appleAppUrl', '');
    }
  };

  const handleFieldChange = (
    name: keyof GeneralFormValues,
    value: string | OptionProps[] | string[]
  ) => {
    const defaultNote = intl.formatMessage({
      id: 'general.UNABLE_TO_FULFILL_ORDER',
    });
    const defaultValue =
      // @ts-ignore
      data?.store[name as Store] === 'UNABLE_TO_FULFILL_ORDER'
        ? defaultNote
        : // @ts-ignore
          data?.store[name as Store];
    if (name === 'paymentMethod') {
      let defaultPaymentMethod: string[] = [];
      if (data?.store?.paymentMethod) {
        const storePaymentMethods = Array.isArray(data.store.paymentMethod)
          ? data.store.paymentMethod
          : [];
        defaultPaymentMethod = [...storePaymentMethods];
      }
      const currentValues = [...(value as string[])];
      const defaultValues = [...defaultPaymentMethod];
      if (isEqual(defaultValues.sort(), currentValues.sort())) {
        dispatch(setField({key: name, value: 0}));
      } else {
        dispatch(setField({key: name, value: 1}));
      }
    } else if (name === 'storeTypes') {
      const defaultStoreTypes = defaultValue.map(
        (item: StoreType) => item.name
      );
      const currentValues = (value as OptionProps[]).map(
        (item: OptionProps) => item.title
      );
      if (isEqual(defaultStoreTypes.sort(), currentValues.sort())) {
        dispatch(setField({key: name, value: 0}));
      } else {
        dispatch(setField({key: name, value: 1}));
      }
    } else if (name === 'appleAppUrl') {
      let defaultAppleUrl = data?.store?.appUrl
        ? data.store.appUrl.find(item => item.name === 'appleAppUrl')
        : null;
      if (!defaultAppleUrl) {
        defaultAppleUrl = {
          name: 'appleAppUrl',
          url: '',
        };
      }
      if (isEqual(defaultAppleUrl?.url, value)) {
        dispatch(setField({key: name, value: 0}));
      } else {
        dispatch(setField({key: name, value: 1}));
      }
    } else if (name === 'googleAppUrl') {
      let defaulGoogleUrl = data?.store?.appUrl
        ? data?.store?.appUrl.find(item => item.name === 'googleAppUrl')
        : null;
      if (!defaulGoogleUrl) {
        defaulGoogleUrl = {
          name: 'googleAppUrl',
          url: '',
        };
      }
      if (isEqual(defaulGoogleUrl?.url, value)) {
        dispatch(setField({key: name, value: 0}));
      } else {
        dispatch(setField({key: name, value: 1}));
      }
    } else {
      if (defaultValue === value || (!defaultValue && value.length === 0)) {
        dispatch(setField({key: name, value: 0}));
      } else {
        dispatch(setField({key: name, value: 1}));
      }
    }
  };
  const storeTypesWatch = watch('storeTypes');
  const sellersWatch = watch('sellers');

  // auto fill city and postcode
  useEffect(() => {
    if (address.city || address.postCode) {
      setValue('city', address.city);
      setValue('postCode', address.postCode as string);
    }
  }, [address.city, address.postCode]);

  const fieldWatch = watch();
  const watchFields = [
    'image',
    'news',
    'name',
    'paymentMethod',
    'orderRejectionNote',
    'email',
    'phone',
    'storeUrl',
    'externalStoreUrl',
    'description',
    'city',
    'postCode',
    'googleAppUrl',
    'appleAppUrl',
  ];

  // update redux for touched fields
  useEffect(() => {
    const watchFunctions = watchFields.reduce((acc, field) => {
      return {
        ...acc,
        [field]: watch(field as keyof GeneralFormValues),
      };
    }, {});

    watchFields.forEach(field => {
      // @ts-ignore
      const value = watchFunctions[field];
      // @ts-ignore
      if (touchedFields[field] || dirtyFields[field]) {
        handleFieldChange(field as keyof GeneralFormValues, value);
      }
    });
  }, [handleFieldChange, fieldWatch, touchedFields, dirtyFields]);

  // watch for sellers and store types field
  useEffect(() => {
    if (data) {
      if (storeTypesWatch && data) {
        handleFieldChange('storeTypes', storeTypesWatch);
      }
      if (sellersWatch) {
        dispatch(
          setField({key: 'sellers', value: sellersWatch.length > 0 ? 1 : 0})
        );
      }
    }
  }, [storeTypesWatch, sellersWatch, data]);

  // updates input field if data has already been filled
  useEffect(() => {
    if (!data || !isSuccess) {
      return;
    }
    clearReduxFields();
    const {store} = data;
    handleSetValue(store);
    if (store.address) {
      setAddress({
        ...address,
        address: store.address?.address || '',
        longitude: store.address.longitude,
        latitude: store.address.latitude,
        country: store.address?.country || '',
        city: store.address?.city || '',
        postCode: store.address?.postCode || '',
      });
    }
    const status = StoreStatusValue[store.status];
    let storeName = '';
    if (store.name) {
      storeName = store.name;
    }
    setHeaderDetails({name: storeName, status});
  }, [isSuccess, data, setHeaderDetails, setValue]);

  const [updateStore, {isSuccess: updateSuccess, error: updateError}] =
    useUpdateStoreMutation();

  const submitStoreDetail = (formData: GeneralFormValues) => {
    // merged linked sellers
    let upatedLinkedSellers: (string | number)[] = [];
    if (
      (linkedSellers && linkedSellers.length > 0) ||
      (formData.sellers && formData.sellers.length > 0)
    ) {
      const newLinkedSellers = formData.sellers
        ? formData.sellers.map((item: OptionProps) => item.value)
        : [];
      const oldLinkedSellers =
        linkedSellers && linkedSellers.length > 0
          ? linkedSellers?.map(store => store.id)
          : [];
      upatedLinkedSellers = [...oldLinkedSellers, ...newLinkedSellers];
      // @ts-ignore
      delete formData.sellers;
    }
    // format Google & Apple url
    const appUrl: AppUrl[] = [];
    if (formData.googleAppUrl || formData.appleAppUrl) {
      if (formData.googleAppUrl) {
        appUrl.push({name: 'googleAppUrl', url: formData.googleAppUrl});
      }

      if (formData.appleAppUrl) {
        appUrl.push({name: 'appleAppUrl', url: formData.appleAppUrl});
      }
    }
    const data: UpdateStoreParam = {
      id,
      body: {
        ...formData,
        address,
        appUrl,
        sellers: upatedLinkedSellers,
        storeTypes:
          formData.storeTypes && formData.storeTypes.length > 0
            ? formData.storeTypes.map(store => store.value)
            : [],
        paymentMethod: formData.paymentMethod ? formData.paymentMethod : [],
      },
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage({
          id: 'messages.update_success_message',
        });
      },
    };
    reset({sellers: []});
    updateStore(data);
  };

  const clearFields = () => {
    clearReduxFields();
    if (data?.store) {
      reset({sellers: []});
      handleSetValue(data.store);
    }
  };

  useEffect(() => {
    clearReduxFields();
  }, []);

  useEffect(() => {
    if (updateError) {
      handleSetValue(data?.store as Store);
      const typedUpdateError = updateError as CustomError;
      if (isApiValidationError(typedUpdateError)) {
        mapErrorToFormFields(typedUpdateError.data.message, setError);
        if (
          isKeyMatching(
            typedUpdateError.data.message as Object,
            'address.address'
          )
        ) {
          updateAddressError(
            intl.formatMessage({id: 'validation.error.invalid_address'})
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  useEffect(() => {
    if (updateSuccess) {
      clearReduxFields();
      clearFields();
    }
  }, [updateSuccess, dispatch]);

  const resetImageField = () => {
    reset({image: ''});
  };

  return {
    data: data?.store,
    headerDetails,
    handleSubmit,
    submitStoreDetail,
    control,
    updateAddressError,
    updateAddressValue,
    isAddressEmpty,
    address,
    addressError,
    setEnteredValue,
    isSuccess: updateSuccess,
    enteredValue,
    count,
    clearFields,
    watch,
    resetImageField,
    setError,
    clearErrors,
    errors,
  };
}
