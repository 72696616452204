import {ChangeEvent, ReactNode, useRef, useState} from 'react';
import {
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from '@material-ui/core';
import {Controller, Control, Path, FieldValues} from 'react-hook-form';
import {CustomTextFieldProps} from '../Inputs/TextField';
import {ReactComponent as EditIcon} from '../../../assets/images/edit.svg';
import {HexColorPicker} from 'react-colorful';
import cx from 'clsx';
import useTextFieldStyles from '../../Common/useTextFieldStyles';

export type ColorPickerControllerProps<T extends FieldValues> = Omit<
  CustomTextFieldProps,
  'control' | 'value' | 'onChange' | 'onBlur'
> & {
  name: Path<T>;
  control: Control<T>;
  inputClass?: string;
  excludeLabel?: boolean;
  label?: string;
  controlClass?: string;
  showLabelHint?: boolean;
  labelHintText?: string | ReactNode;
  required?: boolean;
  disabled?: boolean;
  formGroupClass?: string;
  errorHelperText?: boolean;
};

const ColorPickerController = <T extends FieldValues>(
  props: ColorPickerControllerProps<T>
) => {
  const {
    name,
    control,
    disabled = false,
    inputClass,
    label,
    controlClass = '',
    formGroupClass,
    errorHelperText = true,
    ...textFieldProps
  } = props;
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);
  const classes = useTextFieldStyles(props);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({field: {value, onChange, onBlur}, fieldState: {error}}) => {
        const touched = !!error;
        const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
          let newValue = event.target.value.toLowerCase();
          if (newValue[0] !== '#') {
            newValue = '#' + newValue;
          }
          if (newValue[1] === '#') {
            newValue = newValue.slice(1);
          }
          if (newValue.length > 7) {
            newValue = newValue.slice(0, 7);
          }
          onChange(newValue);
        };

        const onPickerChange = (value: string) => {
          onChange(value.toLowerCase());
        };

        return (
          <div
            className={cx('form-group', formGroupClass ?? 'mb-3 mb-lg-fg', {
              'form-group-invalid': touched && error,
            })}
          >
            <Popover
              open={open}
              anchorEl={anchorEl.current}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <HexColorPicker
                color={value}
                onChange={onPickerChange}
                className="overflow-hidden"
              />
            </Popover>
            <span className="small-label pb-2">{label}</span>

            <div>
              <TextField
                color="secondary"
                variant="outlined"
                fullWidth
                {...textFieldProps}
                className={cx(controlClass, inputClass)}
                onChange={onInputChange}
                classes={classes}
                helperText={errorHelperText && touched ? error.message : null}
                error={touched && !!error}
                disabled={disabled}
                name={name}
                value={value}
                type={'text'}
                onBlur={onBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div
                        className="rounded-1 "
                        style={{
                          width: '15px',
                          height: '15px',
                          backgroundColor: value,
                          border: '1px solid #b8b8b8',
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditIcon
                        onClick={handleClick}
                        ref={anchorEl}
                        style={{cursor: 'pointer'}}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              {/* Show error here if errorHelperText is false */}
              {!errorHelperText && touched && error && (
                <Typography variant="subtitle2">
                  {String(error.message)}
                </Typography>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default ColorPickerController;
