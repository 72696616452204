import {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import clsx from 'clsx';
import {Stack} from 'react-bootstrap';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Autocomplete, {
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/Autocomplete';
import ButtonBase from '@material-ui/core/ButtonBase';
import Chip from '../../Common/Chip';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import textFieldStyles from '../../Common/useTextFieldStyles';
import {ReactComponent as SearchIcon} from '../../../assets/images/search.svg';
import Button from '../../Common/Button';
import Checkbox from '../../Common/Checkbox';
import Typography from '@material-ui/core/Typography';
import {uniqBy} from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'start',
      width: '100%',
      border: `1px solid ${theme.palette.divider}`,
      textAlign: 'left',
      minHeight: 35,
      padding: '2.5px 11.5px',
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      '&.invalid': {
        borderColor: theme.palette.error.main,
        backgroundColor: theme.palette.error.light,
      },
    },
    chip: {
      margin: '2.5px',
      overflow: 'hidden',
      maxWidth: 'calc(100% - 45px)',
      '& .MuiChip-label': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    popper: {
      boxShadow: '0px 5px 30px 0px rgba(0, 0, 0, 0.08)',
      borderRadius: 8,
      zIndex: 1,
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up('lg')]: {
        minWidth: 308,
      },
      '& .MuiAutocomplete-noOptions': {
        padding: '10px 0 0 0',
      },
    },
    paper: {
      boxShadow: 'none',
      margin: '5px 0 5px 20px',
      backgroundColor: 'transparent',
    },
    listbox: {
      padding: '0',
      maxHeight: 251,
    },
    option: {
      position: 'relative',
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: '0 10px 0 0',
      marginLeft: -10,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: 'transparent',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        left: 10,
        bottom: 0,
        right: 10,
        height: 1,
        backgroundColor: theme.palette.divider,
      },
    },
    popperDisablePortal: {
      position: 'relative',
      width: 'auto !important',
    },
  })
);

interface OptionType {
  id: number;
  title: string;
  value: string;
  eligibilityValue?: string;
}

export type CustomAutocompletePopupProps = {
  options: OptionType[];
  values: OptionType[];
  inputValue?: string;
  onChange: (values: OptionType[]) => void;
  onInputChange: (value: string, reason: AutocompleteInputChangeReason) => void;
};
const CustomAutocompletePopup = (props: CustomAutocompletePopupProps) => {
  const {options, values, onChange, inputValue, onInputChange} = props;
  const intl = useIntl();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chips, setChips] = useState<OptionType[]>([]);
  const [pendingValue, setPendingValue] = useState<OptionType[]>([]);
  const [error, setError] = useState<string>('');
  //text field styles
  const textFieldClasses = textFieldStyles({});

  const buttonBaseRef = useRef<HTMLButtonElement>(null);

  const open = Boolean(anchorEl);

  const id = open ? 'custom-autocomplete' : undefined;

  useEffect(() => {
    setError('');

    if (values) {
      setPendingValue(uniqBy([...values, ...pendingValue], 'id'));
    }
    if (values.length === 0) {
      setError(
        intl.formatMessage({
          id: 'validation.error.required',
        })
      );
    }

    setViewChips();
  }, [values]);

  function setViewChips() {
    setChips(() => []);
    const buttonBaseWidth = buttonBaseRef?.current?.offsetWidth || 0;
    const chipsToView = [];
    let currentWidth = 0;
    for (const item of values) {
      const chipWidth = item.title.length * 10 + 50;
      if (currentWidth + chipWidth < buttonBaseWidth) {
        currentWidth += chipWidth;
        chipsToView.push(item);
      }
    }
    setChips(chipsToView);
  }
  // Fieled click handler
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setPendingValue(values);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (
    _event: React.ChangeEvent<{}>,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === 'toggleInput') {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
  };

  const handleDelete = (removed: number) => {
    onChange(values.filter((item: OptionType) => item.id !== removed));
  };

  const handleDone = () => {
    onChange(pendingValue);
    doClosePopup();
  };

  function handleOptionClick(option: OptionType) {
    const newValue = pendingValue.find(value => value.id === option.id)
      ? pendingValue.filter(value => value.id !== option.id)
      : [...pendingValue, option];

    setPendingValue(newValue);
  }

  function handleInputChange(
    event: ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) {
    onInputChange(value, reason);
  }

  const doClosePopup = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FormControl
        className={clsx('form-group d-flex', {
          'form-group-invalid': error,
        })}
      >
        <ButtonBase
          id="autocomplete-button-base"
          disableRipple
          className={clsx(classes.button, {
            invalid: error,
          })}
          aria-describedby={id}
          onClick={handleClick}
          ref={buttonBaseRef}
        >
          {(chips.length &&
            chips.map(chip => (
              <Chip
                key={chip.id}
                className={classes.chip}
                label={chip.title}
                onDelete={() => handleDelete(chip.id)}
              />
            ))) ||
            ''}
          {(values.length > chips.length && (
            <Chip
              key={1}
              className={classes.chip}
              label={`+ ${values.length - chips.length}`}
            />
          )) ||
            ''}
        </ButtonBase>
        {error && (
          <Typography variant="subtitle2" color="error">
            {error}
          </Typography>
        )}
      </FormControl>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0, 10',
          },
        }}
        className={classes.popper}
        style={{width: anchorEl?.clientWidth}}
      >
        <Autocomplete
          fullWidth
          open
          onClose={handleClose}
          multiple
          classes={{
            paper: classes.paper,
            listbox: classes.listbox,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={pendingValue}
          disableCloseOnSelect
          disablePortal
          blurOnSelect={false}
          renderTags={() => null}
          renderOption={option => (
            <div
              onClick={() => {
                handleOptionClick(option);
              }}
            >
              <Stack direction="horizontal" gap={0}>
                <Checkbox
                  checked={
                    pendingValue.find(value => value.id === option.id) !==
                    undefined
                  }
                />
                <div className="titles single">
                  <Typography>{option.title}</Typography>
                </div>
              </Stack>
            </div>
          )}
          options={options}
          getOptionLabel={option => option.title}
          inputValue={inputValue ?? ''}
          onInputChange={handleInputChange}
          renderInput={params => (
            <FormControl fullWidth className="p-3 pb-0">
              <TextField
                ref={params.InputProps.ref}
                autoFocus
                color="secondary"
                variant="outlined"
                type="search"
                classes={textFieldClasses}
                className="autocomplete-search-field"
                placeholder={intl.formatMessage({id: 'actions.search'})}
                inputProps={{
                  ...params.inputProps,
                  type: 'text',
                }}
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
              />
            </FormControl>
          )}
        />
        <div className="px-3 pb-3 pt-2">
          <Stack gap={3} direction="horizontal" className="justify-content-end">
            <Button
              link
              title={intl.formatMessage({id: 'actions.cancel'})}
              onClick={doClosePopup}
            />
            <Button
              link
              primary
              title={intl.formatMessage({id: 'actions.done'})}
              onClick={handleDone}
            />
          </Stack>
        </div>
      </Popper>
    </>
  );
};

export default CustomAutocompletePopup;
