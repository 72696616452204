export enum ThirdPartyIntegrationServiceId {
  GoogleMap = 'googleMap',
}

export enum ThirdPartyIntegrationService {
  GoogleMap = 'GoogleMap',
}

export interface ThirdPartyIntegrationSecrets {
  apiKey: string;
}

export interface ThirdPartyIntegration {
  id: number;
  appId: number;
  serviceId: ThirdPartyIntegrationServiceId;
  serviceName: ThirdPartyIntegrationService;
  secrets: ThirdPartyIntegrationSecrets;
  createdAt: Date;
  updatedAt: Date;
}
