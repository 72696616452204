import {useIntl} from 'react-intl';
import {Typography} from '@material-ui/core';
import {Stack} from 'react-bootstrap';
import {ReactComponent as FacebookAuthenticationIcon} from '../../../../assets/images/facebookAuthentication.svg';
import InputController from '../../../Form/HookForm/InputController';
import {Control} from 'react-hook-form';
import {ThirdPartyIntegrationFormData} from '../../../../hooks/useUpdateAppThirdPartyIntegration';

export type FacebookAuthenticationProps = {
  control: Control<ThirdPartyIntegrationFormData, any>;
};

const FacebookAuthentication = ({control}: FacebookAuthenticationProps) => {
  const intl = useIntl();

  return (
    <div className="card details-card mb-4">
      <div className="card-body">
        <Stack className="gap-2 gap-lg-vtl-15">
          <Stack direction="horizontal" gap={2}>
            <FacebookAuthenticationIcon />
            <Typography variant="h5">
              {intl.formatMessage({
                id: 'dashboard.app_details.app_settings.facebook_authentication.title',
              })}
            </Typography>
          </Stack>
          <InputController
            name="facebookAuthenticationClient"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.app_details.app_settings.facebook_authentication.app_id',
            })}
            required
          />
          <InputController
            name="facebookAuthenticationToken"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.app_details.app_settings.facebook_authentication.client_token',
            })}
            required
          />
          <InputController
            name="facebookAuthenticationClientSecret"
            control={control}
            label={intl.formatMessage({
              id: 'dashboard.app_details.app_settings.facebook_authentication.client_secret',
            })}
            required
          />
        </Stack>
      </div>
    </div>
  );
};

export default FacebookAuthentication;
