import {Slider, withStyles} from '@material-ui/core';

const CustomSlider = withStyles({
  track: {
    height: 5,
  },
  rail: {
    height: 5,
    backgroundColor: '#F5F5F7',
  },
  thumb: {
    marginTop: -3,
  },
})(Slider);

export default CustomSlider;
