import {ElementType, Fragment, useCallback} from 'react';
import CustomSelect from '../../../Common/Select';
import {getCountryCallingCode} from 'react-phone-number-input';
import {Stack} from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';
import {Typography} from '@material-ui/core';

export type CountrySelectProps = {
  /**
   * A two-letter country code.
   * Example: "US", "RU", etc.
   */
  value?: string;

  /**
   * A function of `value: string`.
   * Updates the `value` property.
   */
  onChange?: any;

  // `<select/>` options.
  options: {
    value: string;
    label: string;
    divider: boolean;
  }[];

  wrapperComponent?: ElementType;
  disabled: boolean;
  [key: string]: any;
};

export const CountrySelect = ({
  value,
  onChange,
  options,
  wrapperComponent: WrapperComponent = Fragment,
  disabled,
  ...rest
}: CountrySelectProps) => {
  const onChange_ = useCallback(
    (value: any) => onChange(value === 'ZZ' ? undefined : value),
    [onChange]
  );

  // "ZZ" means "International".
  // (HTML requires each `<option/>` have some string `value`).
  return (
    <WrapperComponent>
      <CustomSelect
        value={value || 'ZZ'}
        onChange={onChange_}
        outlined
        disabled={disabled}
        {...rest}
        items={options.map(({value: countryCode, label: countryName}: any) => ({
          // label: `${countryCode} - ${label} - ${divider}`,
          label: countryCode ? `+${getCountryCallingCode(countryCode)}` : '',
          value: countryCode ?? 'ZZ',
          name: countryName ?? 'International',
        }))}
        renderSelectItem={(option: any) => (
          <Stack key={value} direction="horizontal" gap={1}>
            <ReactCountryFlag
              svg
              className="country-flag"
              style={{width: 22, height: 15}}
              countryCode={option.value}
            />
            <Typography>{option.name}</Typography>
          </Stack>
        )}
      />
    </WrapperComponent>
  );
};
