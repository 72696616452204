import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {FeeType} from '../../types/Fee';

export type ServiceFeeForm = {
  name: string;
  buyerFeePercentage: number | string;
  buyerFeeFixed: number | string;
  sellerFeePercentage: number | string;
  sellerFeeFixed: number | string;
  description: string;
  type?: FeeType;
};

export default function useServiceFeeFormRequest() {
  // translations
  const intl = useIntl();

  // schema valiation
  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
        description: yup
          .string()
          .trim()
          .required(intl.formatMessage({id: 'validation.error.required'})),
        buyerFeeFixed: yup
          .number()
          .typeError(intl.formatMessage({id: 'validation.error.required'}))
          .required(intl.formatMessage({id: 'validation.error.required'})),
        buyerFeePercentage: yup
          .number()
          .max(100, intl.formatMessage({id: 'validation.error.max_percentage'}))
          .typeError(intl.formatMessage({id: 'validation.error.required'}))
          .required(intl.formatMessage({id: 'validation.error.required'})),
        sellerFeeFixed: yup
          .number()
          .typeError(intl.formatMessage({id: 'validation.error.required'}))
          .required(intl.formatMessage({id: 'validation.error.required'})),
        sellerFeePercentage: yup
          .number()
          .max(100, intl.formatMessage({id: 'validation.error.max_percentage'}))
          .typeError(intl.formatMessage({id: 'validation.error.required'}))
          .required(intl.formatMessage({id: 'validation.error.required'})),
      }),
    [intl]
  );

  // form hook initialization
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    trigger,
    clearErrors,
    formState,
    reset,
    setError,
    resetField,
    register,
    unregister,
  } = useForm<ServiceFeeForm>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  return {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    trigger,
    clearErrors,
    formState,
    reset,
    setError,
    resetField,
    register,
    unregister,
  };
}
