import {useEffect, useState} from 'react';
import cx from 'clsx';
import {FormattedMessage, useIntl} from 'react-intl';
import {Theme, createStyles, makeStyles, withStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {Col, Row, Stack} from 'react-bootstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '../../../Common/IconButton';
import {ReactComponent as EditIcon} from '../../../../assets/images/editGreen.svg';
import {ReactComponent as DeleteIcon} from '../../../../assets/images/trashRed.svg';
import EditPopup from '../../../Widgets/AddPopup';
import EditFeeForm from './EditFeeForm';
import {Fee, ServiceFeeType} from '../../../../types/Fee';
import useServiceFee from '../../../../hooks/useServiceFee';
import ConfirmationPopup from '../../../Shared/ConfirmationPopup';

export type ServiceFeeProps = {
  title: string;
  className?: string;
  fee: Fee;
};

const StyledCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.secondary.light,
      fontSize: 12,
      lineHeight: 'normal',
      padding: theme.spacing(0.7, 1.5, 0.2),
      '&:nth-of-type(1)': {
        [theme.breakpoints.up('lg')]: {
          width: '30%',
        },
      },
      '&:nth-of-type(2)': {
        [theme.breakpoints.up('lg')]: {
          width: '30%',
        },
      },
    },
    body: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(1.5),
      '&:first-of-type': {
        borderRadius: '.5rem 0 0 .5rem',
      },
      '&:last-of-type': {
        borderRadius: '0 .5rem .5rem 0',
      },
    },
  })
)(TableCell);

const useStyles = makeStyles({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 0.5rem',
  },
});

const ServiceFee = ({title, fee, className}: ServiceFeeProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const [editPopup, setEditPopup] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const openDeleteModal = () => setDeleteModal(true);
  const data = [
    {
      type: ServiceFeeType.buyer,
      percentage: fee.buyerFeePercentage,
      fixed: fee.buyerFeeFixed,
    },
    {
      type: ServiceFeeType.seller,
      percentage: fee.sellerFeePercentage,
      fixed: fee.sellerFeeFixed,
    },
  ];

  const {
    handleSubmit,
    control,
    serviceFeeSubmit,
    deleteFeeRequest,
    resetFormField,
    updateSuccess,
    updateLoading,
  } = useServiceFee({
    selectedFee: fee,
  });
  const closeEditPopup = () => {
    setEditPopup(false);
    resetFormField();
  };

  // close modal on save success
  useEffect(() => {
    if (updateSuccess) {
      closeEditPopup();
    }
  }, [updateSuccess]);

  return (
    <>
      <ConfirmationPopup
        opened={deleteModal}
        handleClose={() => setDeleteModal(false)}
        onOk={deleteFeeRequest}
        description={intl.formatMessage({
          id: 'messages.delete_message',
        })}
      />
      <EditPopup
        opened={editPopup}
        title={intl.formatMessage({
          id: 'dashboard.store_details.fee_tab.edit_fee',
        })}
        edit
        onClose={closeEditPopup}
        activeTab={updateLoading ? undefined : `edit-fee${fee.id}`}
      >
        <EditFeeForm
          form={`edit-fee${fee.id}-form`}
          control={control}
          handleSubmit={handleSubmit}
          submit={serviceFeeSubmit}
        />
      </EditPopup>
      <div className={cx('card details-card', className)}>
        <div className="card-body pb-vtl-22">
          <Row className="align-items-center pb-vtl-15">
            <Col>
              <Typography variant="h5">{title}</Typography>
            </Col>
            <Col xs="auto">
              <Stack direction="horizontal" gap={2}>
                <IconButton
                  size="xs"
                  variant="action"
                  onClick={() => setEditPopup(true)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="xs"
                  variant="action"
                  onClick={openDeleteModal}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Col>
          </Row>
          <Typography color="textSecondary">
            {fee.description ||
              'Short description lorem ipsum dolor sit amet consectetur adipiscing elit'}
          </Typography>
          <Divider className="mt-vtl-15" />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledCell>
                  <FormattedMessage id="dashboard.store_details.fee_tab.fee_type" />
                </StyledCell>
                <StyledCell>
                  <FormattedMessage id="dashboard.store_details.fee_tab.percentage" />
                </StyledCell>
                <StyledCell>
                  <FormattedMessage id="dashboard.store_details.fee_tab.fixed" />
                </StyledCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(row => (
                <TableRow key={row.type}>
                  <StyledCell>{row.type}</StyledCell>
                  <StyledCell>{`${row.percentage}%`}</StyledCell>
                  <StyledCell>{`${intl.formatMessage({id: 'units.pound'})}${
                    row.fixed
                  }`}</StyledCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default ServiceFee;
