import clsx from 'clsx';
import {ReactNode} from 'react';
import {Droppable, DroppableProps} from 'react-beautiful-dnd';
import {ProductCategory} from '../../../../types/ProductCategory';
import Drag from '../../../Widgets/DragDrop/Drag';

export type GroupItemProps = {
  category: ProductCategory;
  selectedCategory?: ProductCategory;
  onSelect: (category: ProductCategory) => void;
  index: number;
  children?: ReactNode;
  className?: string;
} & Omit<DroppableProps, 'children'>;

const GroupItem = ({
  category,
  selectedCategory,
  onSelect,
  index,
  children,
  className,
  ...props
}: GroupItemProps) => (
  <Drag
    draggableId={`category-${category.id}`}
    index={index}
    className={clsx('product-category draggable', className, {
      active: selectedCategory?.id === category.id,
    })}
  >
    <Droppable {...props} type="PRODUCT">
      {(provided, snapshot) => (
        <div
          className={clsx('category-droppable-container', {
            'snapshot-is-dragging-over': snapshot.isDraggingOver,
          })}
          ref={provided.innerRef}
          tabIndex={-1}
          onClick={() => onSelect(category)}
        >
          {children}
        </div>
      )}
    </Droppable>
  </Drag>
);

export default GroupItem;
